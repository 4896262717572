<div class="container-fluid">


    <div class="col-lg-12 col-md-12">
        <div class="row ">
            <div class="col-lg-2 col-md-2 selectType_label_div_mv">
                <label class="font_bold font-small" for="selectType">{{'HOME.Selecttype'| translate}}</label>
            </div>
            <div class="col-lg-2 col-md-2 nynas_updates_dropdown_div_mv">
                <select class="selectDropdown form-control nynas_updates_dropdown_mv" [(ngModel)]="selectTypeToSearch">
                    <option *ngFor="let select of selectType" [value]="select">
                        <!-- {{ select }} -->
                        {{ 'HOME.' + getStringWithoutSpaces(select) | translate }}
                    </option>
                </select>
            </div>
            <div class="col-lg-1 find_button_div_mv">
                <button type="button" class="btn filter-button find_button_mv" for="filter"
                    (click)="filterUpdatess(dataSource.data, selectTypeToSearch)">
                    <label class="font-bold font-small" for="filter">{{'HOME.Find'| translate}}</label></button>
            </div>

        </div>



        <div class="col-lg-12 col-md-12">
            <div class="container_main_table ">
                <div [hidden]="displayTable" class="no-records-message pt-3 pl-2">
                    {{'HOME.Norecordsfound'| translate}}
                </div>
                <div [hidden]="!displayTable">
                    <div [hidden]="displayTable" class="no-records-message pt-3 pl-2">
                        {{'HOME.Norecordsfound'| translate}}
                    </div>
                    <div [hidden]="!displayTable">
                        <div class="horizontal_scrollbar_div_nynas_updates_mv ">
                            <table mat-table [dataSource]="dataSource" matSort class="horizontal_scrollbar ">

                                <ng-container matColumnDef="NYNAS_UPDATE_DATE">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        [ngClass]="'mat-table-column-header mat-column-Date mat-table-column-header-text'">
                                        {{'HOME.Date'| translate}}
                                    </th>
                                    <!-- <td mat-cell *matCellDef="let element" [ngClass]="'mat-column-Date mat-table-column-row-text'">
                                        {{element.NYNAS_UPDATE_DATE}}</td> -->

                                    <td mat-cell *matCellDef="let element"
                                        [ngClass]="'mat-column-Date mat-table-column-row-text'">
                                        {{ getDateObject(element.NYNAS_UPDATE_DATE)['date'] }}-{{'HOME.' + this.getDateObject(element.NYNAS_UPDATE_DATE)['month'] | translate}}-{{ getDateObject(element.NYNAS_UPDATE_DATE)['year'] }}
                                    </td>

                                </ng-container>

                                <ng-container matColumnDef="TYPE">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        class="mat_column_Type_mv"
                                        [ngClass]="'mat-table-column-header mat-column-Typ mat-table-column-header-text'">
                                        {{'HOME.Type1'| translate}}
                                    </th>
                                    <td mat-cell *matCellDef="let element"
                                        class="mat_column_Type_mv"
                                        [ngClass]="'mat-column-Type mat-table-column-row-text'">
                                        <!-- {{ element.TYPE }}  -->
                                        {{ 'HOME.' + getStringWithoutSpaces(element.TYPE) | translate }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="TITLE">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        [ngClass]="'mat-table-column-header mat-column-Title mat-table-column-header-text'">
                                        {{'HOME.Title'| translate}}</th>
                                    <!-- <th mat-header-cell *matHeaderCellDef> CustomerName </th> -->
                                    <td mat-cell *matCellDef="let element"
                                        [ngClass]="'mat-column-customer-Title mat-table-column-row-text'">
                                        {{ element.TITLE }} </td>
                                </ng-container>

                                <!-- mat-sort-header -->
                                <ng-container matColumnDef="MESSAGE">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        [ngClass]="'mat-table-column-header mat-column-user-Message mat-table-column-header-text'">
                                        {{'HOME.Message'| translate}} </th>
                                    <!-- <th mat-header-cell *matHeaderCellDef> UserRole </th> -->
                                    <td mat-cell *matCellDef="let element"
                                        [ngClass]="'mat-column-user-Message mat-table-column-row-text'">
                                        {{element.MESSAGE}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Download">
                                    <th mat-header-cell *matHeaderCellDef
                                        [ngClass]="'mat-table-column-header mat-column-user-Download mat-table-column-header-text'">
                                    </th>
                                    <!-- <th mat-header-cell *matHeaderCellDef> UserRole </th> -->
                                    <!-- <td mat-cell *matCellDef="let element" [ngClass]="'mat-column-user-Download mat-table-column-row-text download-button'">
                                    <span class='pdf-content' (click)='downloadPdf(element.DOCUMENT)'>
                                        <i class="fa fa-download" aria-hidden="true"></i>
                                    </span>
                                </td> -->

                                    <!-- <td mat-cell *matCellDef="let element"
                                        [ngClass]="'mat-column-user-Message mat-table-column-row-text'">
                                        {{'HOME.View'| translate}} </td> -->

                                    <td mat-cell *matCellDef="let element">

                                        <a class='pdf-content'
                                            (click)='downloadPdf(element.DOUCUMENT_URL, element.STORAGE_TYPE)'>

                                            <ng-container *ngIf='element.DOUCUMENT_URL!=""'><span
                                                    class='font-vsmall font-bold'>
                                                    {{'HOME.View'| translate}}

                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf='element.DOUCUMENT_URL==""'><span
                                                    class='font-vsmall font-bold'>
                                                    NA

                                                </span>
                                            </ng-container>
                                        </a>
                                    </td>

                                </ng-container>


                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                        <mat-paginator   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"   showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>