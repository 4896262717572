<div class="container-fluid sidepadding-none">

  <div class='col-lg-12'>
    <div class='row'>

      <div class="col-lg-1 mt-10 align-right">
        <label class="font-bold font-small" for="countryType"> {{'HOME.Country'| translate}}

        </label>
      </div>
      <!-- <div class="col-lg-4">     
          <mat-form-field appearance="outline">
            <mat-select disableOptionCentering [(value)]="countrySearch" panelClass="select-card">
              <mat-select-trigger>
                <span class="flag-icon flag-icon-{{countrySearch | lowercase}} flag-size"></span>{{countryList[countrySearch]}}
              </mat-select-trigger>
              <mat-option *ngFor="let country of countryList | keyvalue" [value]="country.key">
                <span class="flag-icon flag-icon-{{ country.key | lowercase}} flag-size"></span>{{country.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div> -->

      <div class="col-lg-4" style="padding-top: 10px;">
        <select class="countrySelect" id="CountryCodeId" [(ngModel)]='countryCode' (change)=getOrderingProcedure()>
          <option *ngFor="let country of countryList" [ngValue]="country.CountryID">
            <!-- {{country.CountryName}} -->
            {{'HOME.' + country.CountryName | translate}}
          </option>
        </select>
        <!-- <mat-form-field appearance="outline">
            <mat-select disableOptionCentering [(value)]="countrySearch">
              <mat-option *ngFor="let country of countryList" [value]="country.CountryID">
               {{country.CountryName}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
      </div>

      <!-- <div class="col-lg-4">

        <mat-form-field appearance="outline">
          <mat-select disableOptionCentering [(value)]="countrySearch" panelClass="select-card">
            <mat-select-trigger>
              <span
                class="flag-icon flag-icon-{{countrySearch | lowercase}} flag-size"></span>{{countryList[countrySearch]}}
            </mat-select-trigger>
            <mat-option *ngFor="let country of countryList | keyvalue" [value]="country.key">
              <span class="flag-icon flag-icon-{{ country.key | lowercase}} flag-size"></span>{{country.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>
  </div>

  <div class="col-lg-12 mt-25">

    <span class="bold-font">
      {{'HOME.Warehousecontactinfo'| translate}}

    </span>
    <div class="underline-data"></div>
    <div class="mt-2" [hidden]="this.dispTable" style="font-size:small">
        {{'HOME.NoData'| translate}}
      </div>

    <!-- Ordering procedure depot details table to be shown for Finland country  -->
    <div class="col-lg-12" [hidden]="!this.dispTable" *ngIf="countryCode === 'FIN'">
      <div class="horizontal_scrollbar_div">
      <table class="table table-striped  borderless horizontal_scrollbar ">

        <thead class="header-color">
          <tr>
            <th class="table-header mat_column_depot mat_column_depot_MV"> {{'HOME.Depot'| translate}}
            </th>

            <!-- Hide contact number and email for Finland logged in users -->
            <th class="table-header col-2 mat_column_primaryContact" *ngIf='countryCode !== "FIN"'>
              {{'HOME.Contactnumber'| translate}}
            </th>
            <th class="table-header mat_column_Email" *ngIf='countryCode !== "FIN"'> {{'HOME.Email'| translate}}
            </th>

            <!-- Commented below code, kept for future reference -->
            <!-- <th class="table-header mat_column_OrderingInstructions"> {{'HOME.Orderinginstructions'| translate}}
            </th> -->

          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let warehouseInfo of orderingProcedureCollection">
            <ng-container>
              <td class="table-data mat_column_depot">
                <div class="font-bold"> {{warehouseInfo.DepotName}}</div>
                {{warehouseInfo.AddressLine1}}<br>
                <div *ngIf='warehouseInfo.AddressLine2!=" "'>{{warehouseInfo.AddressLine2}}<br>
                </div>
               {{warehouseInfo.PinCode}}  {{warehouseInfo.DepotName}}
              </td>

              <!-- Hide contact number and email for Finland logged in users -->
              <td class="table-data col-2 mat_column_primaryContact" *ngIf='countryCode !== "FIN"'>
                <div *ngIf='warehouseInfo.PrimaryContact'>
                  {{warehouseInfo.PrimaryContact}}<br>
                </div>
                <div>
                  ({{'HOME.warehouseInfo.primaryContact.label'|translate}})<br>
                </div>
                <div *ngIf='warehouseInfo.SecondaryContact'>
                  {{warehouseInfo.SecondaryContact}}<br>
                </div>
                <div>
                  ({{'HOME.warehouseInfo.secondaryContact.label'|translate}})<br>
                </div>
              </td>
              <td class="table-data mat_column_Email" *ngIf='countryCode !== "FIN"'>
                {{warehouseInfo.Email}}
              </td>

              <!-- Commented below code, kept for future reference -->
              <!-- <td class="table-data mat_column_OrderingInstructions">
                {{warehouseInfo.OrderingInstructions}}
              </td> -->

            </ng-container>

          </tr>
        </tbody>
      </table>
    </div>
    </div>

    <!-- Ordering procedure depot details table to be shown for Norway   -->
    <div class="col-lg-12" [hidden]="!this.dispTable" *ngIf="countryCode === 'NOR'">
      <div class="horizontal_scrollbar_div">
        <table class="table table-striped  borderless horizontal_scrollbar ">
          <thead class="header-color">
            <tr>
              <!-- <th class="table-header mat_column_depot_type mat_column_depot_MV empty-column-header">
              </th> -->
              <th class="table-header mat_column_depot mat_column_depot_MV"> {{'HOME.Depot'| translate}}
              </th>
              <th class="table-header col-2 mat_column_primaryContact">
                {{'HOME.Contactnumber'| translate}}
              </th>
              <th class="table-header mat_column_Email"> {{'HOME.Email'|
                translate}}
              </th>
              <th class="table-header mat_column_period"> {{'HOME.Period'| translate}}
              </th>
              <th class="table-header mat_column_OrderingInstructions"> {{'HOME.Orderinginstructions'| translate}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let warehouseInfo of orderingProcedureCollection; index as i; even as isEven">
              <ng-container>
                <!-- <td *ngIf="isEven" class="table-data mat_column_depot_type vertical-text" rowspan="2">
                  BITUMEN OG PMB
                </td> -->
                <td class="table-data mat_column_depot">
                  <div class="font-bold"> {{warehouseInfo.DepotName}}{{warehouseInfo.AddressLine1}}</div>
                  <div *ngFor="let AddressLine2Data of warehouseInfo.AddressLine2Details">
                    {{AddressLine2Data}}
                  </div>
                  <div>{{warehouseInfo.State}} {{warehouseInfo.PinCode}} {{warehouseInfo.City}}</div>
                  <div class="font-bold" *ngIf="warehouseInfo.ORDER_TELEPHONE">Beställningstelefon:
                  </div>
                  <div *ngFor="let contactPerson of warehouseInfo.contactPersons">{{ contactPerson }}</div>
                  <div class="font-bold" *ngIf="warehouseInfo.SUPPORT_TELEPHONE">Supporttelefon:
                  </div>
                </td>
                <td class="table-data mat_column_primaryContact">
                  <div>&nbsp;</div>
                  <div *ngFor="let AddressLine2Data of warehouseInfo.AddressLine2Details">&nbsp;
                  </div>
                  <div *ngIf='warehouseInfo.PrimaryContact'>{{warehouseInfo.PrimaryContact}}</div>
                  <div *ngIf='!warehouseInfo.PrimaryContact'>&nbsp;</div>
                  <div *ngIf="warehouseInfo.ORDER_TELEPHONE">{{ warehouseInfo.ORDER_TELEPHONE }}
                  </div>
                  <div *ngIf='warehouseInfo.contactNumbers'>
                    <div *ngFor="let contactNumber of warehouseInfo.contactNumbers">{{ contactNumber }}</div>
                  </div>
                  <div *ngIf='!warehouseInfo.contactNumbers && warehouseInfo.contactPersons'>
                    <div *ngFor="let contactPerson of warehouseInfo.contactPersons">&nbsp;</div>
                  </div>
                  <div *ngIf="warehouseInfo.SUPPORT_TELEPHONE">{{ warehouseInfo.SUPPORT_TELEPHONE }}
                  </div>
                </td>
                <td class="table-data mat_column_Email">
                  <div>{{warehouseInfo.Email}}</div>
                  <div *ngFor="let AddressLine2Data of warehouseInfo.AddressLine2Details">&nbsp;
                  </div>
                  <div *ngIf='warehouseInfo.PrimaryContact'>&nbsp;</div>
                  <div *ngIf='!warehouseInfo.PrimaryContact'>&nbsp;</div>
                  <div *ngIf="warehouseInfo.ORDER_TELEPHONE">&nbsp;
                  </div>
                  <div *ngIf='warehouseInfo.contactPersonEmails'>
                    <div *ngFor="let contactPersonEmail of warehouseInfo.contactPersonEmails">{{ contactPersonEmail }}</div>
                  </div>
                  <div *ngIf='!warehouseInfo.contactNumbers && warehouseInfo.contactPersons'>
                    <div *ngFor="let contactPerson of warehouseInfo.contactPersons">&nbsp;</div>
                  </div>
                  <div *ngIf="warehouseInfo.SUPPORT_TELEPHONE">&nbsp;
                  </div>
                </td>
                <td class="table-data mat_column_period">
                  {{warehouseInfo.PERIOD}}
                </td>
                <td class="table-data mat_column_OrderingInstructions">
                  <span [innerHtml]="warehouseInfo.OrderingInstructions"></span>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Ordering procedure depot details table to be shown for Sweden   -->
    <div class="col-lg-12" [hidden]="!this.dispTable" *ngIf="countryCode === 'SWE'">
      <div class="horizontal_scrollbar_div">
        <table class="table table-striped  borderless horizontal_scrollbar ">
          <thead class="header-color">
            <tr>
              <th class="table-header mat_column_depot mat_column_depot_MV"> {{'HOME.Depot'| translate}}
              </th>
              <th class="table-header col-2 mat_column_primaryContact">
                {{'HOME.Contactnumber'| translate}}
              </th>
              <th class="table-header mat_column_Email"> {{'HOME.Email'|
                translate}}
              </th>
              <th class="table-header mat_column_OrderingInstructions"> {{'HOME.Orderinginstructions'| translate}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let warehouseInfo of orderingProcedureCollection">
              <ng-container>
                <td class="table-data mat_column_depot">
                  <div class="font-bold"> {{warehouseInfo.DepotName}}{{warehouseInfo.AddressLine1}}</div>
                  <div *ngFor="let AddressLine2Data of warehouseInfo.AddressLine2Details" class="font-bold">
                    {{AddressLine2Data}}
                  </div>
                  <div class="font-bold">{{warehouseInfo.State}} {{warehouseInfo.PinCode}} {{warehouseInfo.City}}</div>
                  <div class="font-bold" *ngIf="warehouseInfo.ORDER_TELEPHONE">Beställningstelefon:
                  </div>
                  <div *ngFor="let contactPerson of warehouseInfo.contactPersons">{{ contactPerson }}</div>
                  <div class="font-bold" *ngIf="warehouseInfo.SUPPORT_TELEPHONE">Supporttelefon:
                  </div>
                </td>
                <td class="table-data mat_column_depot">
                  <div class="font-bold">&nbsp;</div>
                  <div *ngFor="let AddressLine2Data of warehouseInfo.AddressLine2Details" class="font-bold">&nbsp;
                  </div>
                  <div *ngIf='warehouseInfo.PrimaryContact'>{{warehouseInfo.PrimaryContact}}</div>
                  <div *ngIf='!warehouseInfo.PrimaryContact'>&nbsp;</div>
                  <div *ngIf="warehouseInfo.ORDER_TELEPHONE">{{ warehouseInfo.ORDER_TELEPHONE }}
                  </div>
                  <div *ngIf='warehouseInfo.contactNumbers'>
                    <div *ngFor="let contactNumber of warehouseInfo.contactNumbers">{{ contactNumber }}</div>
                  </div>
                  <div *ngIf='!warehouseInfo.contactNumbers && warehouseInfo.contactPersons'>
                    <div *ngFor="let contactPerson of warehouseInfo.contactPersons">&nbsp;</div>
                  </div>
                  <div *ngIf="warehouseInfo.SUPPORT_TELEPHONE">{{ warehouseInfo.SUPPORT_TELEPHONE }}
                  </div>
                </td>
                <td class="table-data mat_column_Email">
                  {{warehouseInfo.Email}}
                </td>
                <td class="table-data mat_column_OrderingInstructions">
                  {{warehouseInfo.OrderingInstructions}}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Ordering procedure depot details table to be shown for Denmark   -->
    <div class="col-lg-12" [hidden]="!this.dispTable" *ngIf="countryCode === 'DNK'">
      <div class="horizontal_scrollbar_div">
        <table class="table table-striped  borderless horizontal_scrollbar ">
          <thead class="header-color">
            <tr>
              <th class="table-header mat_column_depot mat_column_depot_MV">{{'HOME.Depot'| translate}}</th>
              <th class="table-header col-2 mat_column_primaryContact">Telefon</th>
              <th class="table-header mat_column_Email">E-mail</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let warehouseInfo of orderingProcedureCollection; index as i; even as isEven">
              <ng-container>
                <td class="table-data mat_column_depot">
                  <div class="font-bold">{{warehouseInfo.DepotName}}</div>
                  <div class="font-bold">{{warehouseInfo.AddressLine2}}</div>
                  <div class="font-bold">{{warehouseInfo.PinCode}} {{warehouseInfo.City}}</div>
                  <div>&nbsp;</div>
                  <div *ngIf="warehouseInfo.depotChef">Depot chef</div>
                  <div *ngIf="warehouseInfo.depotTechnician">Depot tekniker</div>
                  <div>&nbsp;</div>
                  <div class="font-bold">Bestillingsmail:</div>
                  <div class="font-bold">Bestillingstelefon:</div>
                </td>
                <td class="table-data mat_column_primaryContact">
                  <div class="font-bold">{{warehouseInfo.CONTACT_NUMBER}}</div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <div *ngIf="warehouseInfo.depotChef">{{ warehouseInfo.depotChef }}</div>
                  <div *ngIf="warehouseInfo.depotTechnician">{{ warehouseInfo.depotTechnician }}</div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <div class="font-bold">{{ warehouseInfo.ORDER_TELEPHONE }}</div>
                </td>
                <td class="table-data mat_column_Email">
                  <div><a href="mailto:{{warehouseInfo.Email}}">{{warehouseInfo.Email}}</a></div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <div *ngIf="warehouseInfo.depotChef">&nbsp;</div>
                  <div *ngIf="warehouseInfo.depotTechnician">&nbsp;</div>
                  <div>&nbsp;</div>
                  <div><a href="mailto:{{warehouseInfo.Email}}">{{warehouseInfo.Email}}</a></div>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <span class="bold-font1" *ngIf="countryCode === 'FIN' ">
      {{'HOME.GeneralInstructions'| translate}}

    </span>
    <div class="underline-data" *ngIf="countryCode === 'FIN' "></div>
  
  <!-- Commented old code of Finland Ordering procedure instructions details, kept for future reference   -->
  <!-- <div *ngIf="countryCode === 'FIN'">
    <ng-container>
    

      <div class='mt-10'> 
       <span class="genInstruSpanBold">Tilausmenettely </span>
       <span class="genInstruSpanBold">Viikkotilaus </span>
       <span class="genInstruSpanNorm">
        Koko jakelukauden on voimassa viikkotilausmenettely kaikkien tuotteiden osalta.  

        Viikkotilaukset on ilmoitettava toimitusta edeltävän viikon torstaihin mennessä Nynasin asiakasportaalin kautta.  
       </span>
       <span class="genInstruSpanBold">
        Kuljetukset  
       </span>
       <span class="genInstruSpanNorm">
        Kuljetus tilataan omalta kuljetusliikkeeltä. Toimitusten minimikoko on nuppikuorma.  

        Kuljetusliikkeiden ajoneuvojen ja kuljettajien tulee täyttää Neste Oyj:n ja/tai Wibax Tank Oy:n kulloinkin asettamat autokalusto- ja kuljettajavaatimukset. Kuljetusliike antaa tiedot käyttämistään ajoneuvoista ja kuljettajista terminaalille. 
       </span>
       <span class="genInstruSpanBold">
        Jakelukatkot 
       </span>
       <span class="genInstruSpanNorm">
        Jakelukatkoista tuotteittain ja terminaaleittain ilmoitetaan tekstiviestillä.  
       </span>
      </div>
      
    
  </ng-container>
  </div> -->

  <!-- Ordering procedure instructions details to be shown for Finland   -->
  <div *ngIf="countryCode === 'FIN'">
    <ng-container>
      <div class='mt-10'>
        <span class="genInstruSpanNorm">
          Naantalissa jakelu toimii 24/7-periaatteella. Laivapurkujen, tuote-erien korjausten ja niitä seuraavien
          analyysitietojen hyväksymisen väliseksi ajaksi jakelu keskeytetään tarvittaessa.
        </span>
        <br>
        <span class="genInstruSpanNorm">
          Jakelukatkoista tuotteittain ja terminaaleittain ilmoitetaan tekstiviestillä. Nynasilla on oikeus 24 tunnin
          varoitusajalla keskeyttää jakelu huoltotoimenpiteiden tai vastaavien toimintojen ajaksi.
        </span>
        <span class="genInstruSpanBold">Kuljetukset</span>
        <span class="genInstruSpanNorm">
          Kuljetukset tilataan omalta kuljetusliikkeeltä. Toimitusten minimikoko on nuppikuorma.
        </span>
        <span class="genInstruSpanNorm">
          Kuljetusliikkeiden ajoneuvojen ja kuljettajien tulee täyttää Neste Oyj:n 
          kulloinkin asettamat autokalusto- ja kuljettajavaatimukset. Kuljetusliike antaa tiedot käyttämistään
          ajoneuvoista ja kuljettajista terminaalille.
        </span>
        <span class="genInstruSpanBold">
          Viikkotilaus
        </span>
        <span class="genInstruSpanNorm">
          Koko jakelukauden on voimassa viikkotilausmenettely kaikkien tuotteiden osalta. Viikkotilaukset tehdään Nynasin
          asiakasportaalissa aina neljäksi viikoksi kerrallaan. Tilausta voi päivittää noutoa edeltävän viikon torstaihin
          saakka.
        </span>
      </div>
    </ng-container>
  </div>

  <!-- //For Sweden -->
  <span class="bold-font1" *ngIf="countryCode === 'SWE' ">
    {{'HOME.GeneralInstructions'| translate}}
  </span>
  <div class="underline-data" *ngIf="countryCode === 'SWE' "></div>

<!-- Commented old code of Sweden Ordering procedure depot details table, kept for future reference   -->  
<!-- <div *ngIf="countryCode === 'SWE'">
  <ng-container>
  

    <div class='mt-10'> 
     <span class="genInstruSpanBold">Tilausmenettely </span>
     <span class="genInstruSpanBold">Utlastning: vardagar 07.00-16.00 </span>
     <span class="genInstruSpanBold">Utlastning depåer : </span>
     <span class="genInstruSpanBold">ÖPPETTIDER</span>
     <span class="genInstruSpanNorm">Nynas samtliga svenska depåer är bemannade vardagar 07.00 – 16.00.</span>
     <span class="genInstruSpanBold">Stängt: </span>
     <span class="genInstruSpanNorm">
      Skärtorsdag, Långfredag, Annandag påsk, Första maj,Kristi Himmelsfärd, Dagen efter Kristi Himmelsfärd, Pingstafton,Pingstdagen, Nationaldagen, Midsommarafton, Midsommardagen,
      Dagen före Alla helgons Dag.
     </span>
     <span class="genInstruSpanBold">Vid tidskritiska transporter</span>
     <span class="genInstruSpanNorm">
      Lastning bör ske dagtid eller inom ordinarie beredskapstider.
      Om detta ej är möjligt kan extra beredskap avtalas i möjligaste mån.
      Meddela depåpersonal direkt när behovet uppstår.
     </span>
     <span class="genInstruSpanBold">Inför helgkörningar</span>
     <span class="genInstruSpanNorm">Om möjligt lasta upp bilen under ordinarie arbetstid i anslutning till helgen.</span>
      <span class="genInstruSpanBold">BEREDSKAP</span>
      <span class="genInstruSpanNorm">Beredskapen är till för att avhjälpa tekniska fel.Beställningar och andra administrativa ärenden hänvisas till ordinarie arbetstid.</span>
      <span class="genInstruSpanBold">Beredskapsperioden</span>
      <span class="genInstruSpanNorm">är 2020-04-27 – 2020-07-10 samt 2020-07-27 – 2020-10-23.
      Depåtekniker har beredskap from 18.00 söndagar to m fredagar 07.00.(Ingen beredskap under ovan nämnda helgdagar)</span>
      <span class="genInstruSpanBold">Telefonsupport söndagar 14.00 – 18.00.</span>
      <span class="genInstruSpanNorm">Depåteknikern hanterar endast frågor som kan hanteras genom
        uppkoppling till berörd depå.
        Tekniska fel, som av säkerhetsskäl måste hanteras lokalt samt frågor som
        kräver åtgärder på plats, måste invänta lokal beredskapshavare under tider
        enligt ovan.
        Angivna leveranstider i Leveransguiden gäller under förutsättning att
        rullande 4-veckors prognoser löpande lämnas in för respektive depå.</span>
      </div>
    </ng-container>
    </div> -->

    <!-- Ordering procedure instructions details to be shown for Finland   -->
    <div *ngIf="countryCode === 'SWE'">
      <ng-container>
        <div class='mt-10'>
          <span class="genInstruSpanBold">Utlastning depåer : </span>
    
          <span class="genInstruSpanBold">Öppettider</span>
          <span class="genInstruSpanNorm">Nynas samtliga svenska depåer är bemannade </span>
          <span class="generalInstructionsBoldFont">vardagar 07.00 – 16.00</span>
          <span class="genInstruSpanBold">Stängt:</span>
          <span class="genInstruSpanNorm">Skärtorsdag, Långfredag, Annandag påsk, Första maj,
            Kristi Himmelsfärd, Dagen efter Kristi Himmelsfärd, Pingstafton,
            Pingstdagen, Nationaldagen, Midsommarafton, Midsommardagen,
            Dagen före Alla helgons Dag.</span>
    
          <span class="genInstruSpanBold">Vid tidskritiska transporter</span>
          <span class="genInstruSpanNorm">Lastning bör ske dagtid eller inom ordinarie supporttider.
            Om detta ej är möjligt kan extra supporttillgänglighet avtalas i möjligaste
            mån. Meddela depåpersonal direkt när behovet uppstår.</span>
    
          <span class="genInstruSpanBold">Inför helgkörningar</span>
          <span class="genInstruSpanNorm">Om möjligt lasta upp bilen under ordinarie arbetstid i anslutning
            till helgen.</span>
    
          <span class="genInstruSpanBold">Support</span>

          <!-- Commented below code, kept for future reference -->
          <!-- <span class="genInstruSpanNorm">Supporten är till för att avhjälpa tekniska fel i mån av möjlighet.
            Beställningar och andra administrativa ärenden hänvisas till ordinarie
            arbetstid. Supportperioden är </span>
          <span class="generalInstructionsBoldFont">2021-04-26</span>
          <span class="genInstruSpanNorm"> till </span>
          <span class="generalInstructionsBoldFont">2021-07-09</span>
          <span class="genInstruSpanNorm"> samt </span>
          <span class="generalInstructionsBoldFont">2021-07-26</span>
          <span class="genInstruSpanNorm"> till </span>
          <span class="generalInstructionsBoldFont">2021-10-22. </span>
          <span class="genInstruSpanNorm">Support fr.o.m. </span>
          <span class="generalInstructionsBoldFont">14:00 söndagar</span>
          <span class="genInstruSpanNorm"> i mån av möjlighet t.o.m. </span>
          <span class="generalInstructionsBoldFont">fredagar 07:00, </span>
          <span class="genInstruSpanNorm">förutom ovan nämnda helgdagar.</span> -->

          <span class="genInstruSpanNorm">Supporten är till för att avhjälpa tekniska fel i mån av möjlighet.
            Beställningar och andra administrativa ärenden hänvisas till ordinarie
            arbetstid.</span>
          <br>
          <span class="genInstruSpanNorm">Supportperioden är från </span>
          <span class="generalInstructionsBoldFont">maj</span>
          <span class="genInstruSpanNorm"> till </span>
          <span class="generalInstructionsBoldFont">oktober.</span>
          <span class="genInstruSpanNorm"> Support fr.o.m. </span>
          <span class="generalInstructionsBoldFont">14:00 söndagar</span>
          <span class="genInstruSpanNorm"> i mån av möjlighet t.o.m. </span>
          <span class="generalInstructionsBoldFont">fredagar 07:00,</span>
          <span class="genInstruSpanNorm"> förutom ovan nämnda helgdagar.</span>

        </div>
      </ng-container>
    </div>

       <!-- //For Sweden End-->

      <!-- for Norway -->
      <span class="bold-font1" *ngIf="countryCode === 'NOR'">
        {{'HOME.GeneralInstructions'| translate}}
      </span>
      <div class="underline-data" *ngIf="countryCode === 'NOR'"></div>
    
    <div *ngIf="countryCode === 'NOR'">
      <ng-container>
      
    
        <div class='mt-10'> 
         <!-- <span class="genInstruSpanBold">Tilausmenettely </span> -->
         <span class="genInstruSpanBold">Bestilling PMB og Emulsjon Drammen og Gøteborg</span>
         <span class="genInstruSpanNorm">Alle PMB bestillinger sendes på bestillingsskjema på epost til: pmb@nynas.com og
          depot.goteborg@nynas.com med kopi til transportselskapet minimum 5 dager før levering.
          Alle emulsjons bestillinger sendes skriftlig på epost til: depot.goteborg@nynas.com
          med kopi til transportselskapet minimum 5 dager før levering.
          Nygen 910 bestilles skriftlig til depot.drammen@nynas.com</span>
         <span class="genInstruSpanBold">Bestilling PMB Holmsund</span>
         <span class="genInstruSpanNorm">
          Alle PMB bestillinger sendes på bestillingsskjema på epost til: depot.umea@nynas.com
          med kopi til transportselskapet minimum 5 dager før levering.
         </span>
          </div>
        </ng-container>
        </div>

       <!-- for Norway End -->
   
        <!-- For Denmark start -->
        <span class="bold-font1" *ngIf="countryCode === 'DNK'">
          {{'HOME.GeneralInstructions'| translate}}
        </span>
        <div class="underline-data" *ngIf="countryCode === 'DNK'"></div>
        <div *ngIf="countryCode === 'DNK'">
          <ng-container>
            <div class='mt-10'>
              <span class="genInstruSpanBold">Udlæsning depoter</span>
              <span class="genInstruSpanBold pt-4">Åbningstider</span>
              <span class="genInstruSpanNorm">Nynas danske depoter er bemandet i følgende tidsrum:</span>
        
              <table cellspacing="0" cellpadding="0">
                <tr>
                  <td class="depot-column">
                    <span class="genInstruSpanNorm font-bold">Århus:</span>
                  </td>
                  <td class="period-column">
                    <span class="genInstruSpanNorm">1. april til 31. oktober</span>
                  </td>
                  <td>
                    <span class="genInstruSpanNorm font-bold">kl. 6.00 - 16.00</span>
                  </td>
                </tr>
                <tr>
                  <td class="depot-column">
                    <span class="genInstruSpanNorm font-bold"></span>
                  </td>
                  <td class="period-column">
                    <span class="genInstruSpanNorm">1. november til 31. marts </span>
                  </td>
                  <td>
                    <span class="genInstruSpanNorm font-bold">kl. 7.00 - 16.00</span>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td class="depot-column">
                    <span class="genInstruSpanNorm font-bold">Køge:</span>
                  </td>
                  <td class="period-column">
                    <span class="genInstruSpanNorm">Alle hverdage</span>
                  </td>
                  <td>
                    <span class="genInstruSpanNorm font-bold">kl. 7.00 – 15.00</span>
                  </td>
                </tr>
              </table>
              <span class="genInstruSpanBold pt-3">Lukkedage:</span>
              <span class="genInstruSpanNorm">Skærtorsdag, Langfredag, 2. påskedag, Kristi Himmelsfartsdag. Dagen efter Krist
                Himmelfartsdag,
                Pinsedag, 2. Pinsedag, Juledagene 24.-25.-26. december.
              </span>
              <span class="genInstruSpanBold pt-3">Ved akut opstået behov for afhentning</span>
              <span class="genInstruSpanNorm">Lastning bør som udgangspunkt ske indenfor ordinær bemandingstid.
                Ved behov for afhentning udenfor dette tidsrum og i weekender kan der afhentes
                ved forudgående aftale med depotpersonalet.
              </span>
              <span class="genInstruSpanBold pt-3">Support</span>
              <span class="genInstruSpanNorm">Supporten er til for at afhjælpe tekniske fejl såvidt muligt.</span>
              <br>
              <span class="genInstruSpanNorm pt-2">
                Ved behov for teknisk support udenfor normal bemandingstid se kontakter herunder.
              </span>
              <table cellspacing="0" cellpadding="0">
                <tr>
                  <td class="bold-depot-name-column">
                    <span class="genInstruSpanNorm font-bold">Århus:</span>
                  </td>
                  <td>
                    <span class="genInstruSpanNorm font-bold">+45 8613 7244</span>
                  </td>
                </tr>
                <tr>
                  <td class="bold-depot-name-column">
                    <span class="genInstruSpanNorm font-bold">Køge:</span>
                  </td>
                  <td>
                    <span class="genInstruSpanNorm font-bold">+45 5665 5050</span>
                  </td>
                </tr>
              </table>
              <br>
              <span class="genInstruSpanNorm pt-6">
                Bestilling og andre administrative anliggender henvises til den almindelige bemandingstid.
              </span>
              <span class="genInstruSpanBold pt-3">Bestilling:</span>
              <span class="genInstruSpanNorm">Ordrer afgives den foregående dag på bestillingsmail</span>
              <table cellspacing="0" cellpadding="0">
                <tr>
                  <td class="depot-name-column">
                    <span class="genInstruSpanNorm">Århus</span>
                  </td>
                  <td>
                    <span class="genInstruSpanNorm">
                      <a href="mailto:depot.aarhus@nynas.com">depot.aarhus@nynas.com</a>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="depot-name-column">
                    <span class="genInstruSpanNorm">Køge</span>
                  </td>
                  <td>
                    <span class="genInstruSpanNorm">
                      <a href="mailto:depot.koge@nynas.com">depot.koge@nynas.com</a>
                    </span>
                  </td>
                </tr>
              </table>
              <br>
              <span class="genInstruSpanNorm pt-6">Bemærk: Blandinger skal bestilles 2-5 dage før afhentning.</span>
            </div>
          </ng-container>
        </div>
        <!-- For Denmark end -->
       
