import { Pipe, PipeTransform } from '@angular/core';
import { sample } from 'rxjs/operators';

@Pipe({
  name: 'nynasUpdatesFilter'
})
export class NynasUpdatesFilterPipe implements PipeTransform {
  //, productSubtype:string,depot:string Condition &&(!productSubtype)&&(!depot)
  transform(value: any, type: string): any {
    if ((!type || type == 'All' || type == 'undefined')) {
         
          return value;
        }
        else {
          return value.filter(value => (value.TYPE.includes(type)));
        }
      }
     

}
