import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardNavComponent }   from './dashboard-nav/dashboard-nav.component';
import { LoginComponent } from './login/login.component';
import { ManageUsersComponent }   from './user-management/manage-users/manage-users.component';
import { AddUserComponent }   from './user-management/add-user/add-user.component';
import { ManageUserGroupComponent }   from './user-management/manage-user-group/manage-user-group.component';
import { AddUserGroupComponent }   from './user-management/add-user-group/add-user-group.component';
import { ProductInformationComponent }   from './product-management/product-information/product-information.component';
import { HomeComponent } from './home/home.component';
import { DeliveryGuideComponent }   from './product-management/delivery-guide/delivery-guide.component';
import { OrderingProcedureComponent }   from './order-management/ordering-procedure/ordering-procedure.component';
import { BitumenIndexComponent } from './price-management/bitumen-index/bitumen-index.component';

import { PriceAuthorsComponent } from './price-management/price-authors/price-authors.component';
import { HistoryComponent }   from './order-management/history/history.component';
import { PendingForecastComponent }   from './order-management/pending-forecast/pending-forecast.component';
import { ForecastEntryComponent }   from './order-management/forecast-entry/forecast-entry.component';
import { AddDeliverySiteComponent } from './my-company/add-delivery-site/add-delivery-site.component';
import { FormsModule, ReactiveFormsModule } from'@angular/forms';
import { HelpComponent } from './help/help.component';
import { CompanyDetailsComponent } from './my-company/company-details/company-details.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import { SettingsComponent } from './settings/settings.component';

import {  PortalUsageComponent } from './reports/portal-usage/portal-usage.component';
import {  SalesReportComponent } from './reports/sales-report/sales-report.component';

import {  ForecastactualComponent} from './reports/forecastactual/forecastactual.component';
import { ModifyDeliverySiteComponent } from './my-company/modify-delivery-site/modify-delivery-site.component';
import { NynasUpdatesComponent } from './nynas-updates/nynas-updates.component';

import { UdspMappingComponent } from './udsp-mapping/udsp-mapping.component';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DeliveryGuideAdminComponent } from './delivery-guide-admin/delivery-guide-admin.component';
import {ContactUsAdminComponent} from './contact-us-admin/contact-us-admin.component';
import { BitumenIndexAdminComponent } from './bitumen-index-admin/bitumen-index-admin.component';
import { CustomerContractComponent } from './customer-contract/customer-contract.component';
import { AddExchangeRateAdminComponent } from './add-exchange-rate-admin/add-exchange-rate-admin.component';
import { AuthGuard } from './guards/auth-guard.service';
import { RoleGuard } from './guards/role-guard.service';
import { PriceDataComponent } from './price-management/price-data/price-data.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateService} from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { PriceNotationAdminComponent } from './price-notation-admin/price-notation-admin.component';
import { AddPriceIndexComponent } from './add-price-index/add-price-index.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { GuestViewComponent } from './guest-view/guest-view.component';
import { AddDeliverySiteSwedenComponent } from './my-company/add-delivery-site-sweden/add-delivery-site-sweden.component';
import { ModifyDeliverySiteSwedenComponent } from './my-company/modify-delivery-site-sweden/modify-delivery-site-sweden.component'
import { ForecastactualswedenComponent } from './reports/forecastactualsweden/forecastactualsweden.component';
import { ForecastactualfinlandComponent } from './reports/forecastactualfinland/forecastactualfinland.component';
import { AddProductPriceComponent } from './add-product-price/add-product-price.component';
import { ForecastactualweeklyswedenComponent } from './reports/forecastactualweeklysweden/forecastactualweeklysweden.component';
import { ForecastactualmonthlyswedenComponent } from './reports/forecastactualmonthlysweden/forecastactualmonthlysweden.component';
import { LoginHistoryComponent } from './login-history/login-history.component';
import { WeeklyReportComponent } from './reports/weekly-report/weekly-report.component';
import { MonthlyReportComponent } from './reports/monthly-report/monthly-report.component';
import { DailyReportsComponent } from './reports/daily-reports/daily-reports.component';
import { OrderEntryComponent } from './order-management/order-entry/order-entry.component';
import { OrderTrackingComponent } from './order-management/order-tracking/order-tracking.component';
import { ContractualVolumeReportComponent } from './reports/contractual-volume-report/contractual-volume-report.component';
import { ProductDocumentationComponent } from './product-management/product-documentation/product-documentation.component';
import { ShippedDeliveriesService } from './reports/shipped-deliveries/shipped-deliveries.service';
import { ShippedDeliveriesComponent } from './reports/shipped-deliveries/shipped-deliveries.component';

import { UnlockOrderComponent } from './order-management/unlock-order/unlock-order.component';
import { AddEditDeliverySiteComponent } from './my-company/add-edit-delivery-site/add-edit-delivery-site.component';
import { DeliveryAnalysisComponent } from './reports/delivery-analysis/delivery-analysis.component';

const routes: Routes= [
  //add other routing path here
    { path:'', redirectTo:'/login', pathMatch:'full' },
    { path:'login', component:LoginComponent },
    { path:'help', component:HelpComponent, canActivate: [AuthGuard, RoleGuard] },
    { path:'contactus', component:ContactUsComponent, canActivate: [AuthGuard, RoleGuard] },
    { path:'nynas-updates', component:NynasUpdatesComponent, canActivate: [AuthGuard, RoleGuard] },

    { path:'udsp-mapping', component:UdspMappingComponent, canActivate: [AuthGuard, RoleGuard] },
    
    { path:'pagenotfound', component: PageNotFoundComponent, canActivate: [AuthGuard, RoleGuard] },
    {
      path: 'app', component: DashboardNavComponent
  },
  {
   path: 'app', component: DashboardNavComponent,
   children: [
     { path: '', redirectTo: 'home', pathMatch: 'full' },
     { path: 'home', component: HomeComponent, canActivate: [AuthGuard, RoleGuard] },
     { path: 'nynas-updates', component: NynasUpdatesComponent },
     
     { path: 'udsp-mapping', component: UdspMappingComponent },

     { path: 'my-profile', component: AddUserComponent },
     { path: 'contactus', component: ContactUsComponent },
    
     { path: 'product-management',children :[
         { path: 'product-information', component: ProductInformationComponent },
         { path: 'product-documentation', component: ProductDocumentationComponent },
         { path: 'delivery-guide', component: DeliveryGuideComponent }
     ]
   },
   { path: 'price-management',children :[
     { path: '', redirectTo: 'price-data', pathMatch: 'full' },
     { path: 'price-data', component: PriceDataComponent },
     { path: 'price-authors', component: PriceAuthorsComponent },
     { path: 'bitumen-index', component: BitumenIndexComponent }
  ]
  },
     {
       path: 'my-company', children: [
         { path: '', redirectTo: 'company-details', pathMatch: 'full' },
         { path: 'company-details', component: CompanyDetailsComponent },
         { path: 'add-delivery-site', component: AddDeliverySiteComponent },
         { path: 'add-delivery-site-sweden', component: AddDeliverySiteSwedenComponent },
         { path: 'modify-delivery-site', component: ModifyDeliverySiteComponent },
         { path: 'modify-delivery-site-sweden', component: ModifyDeliverySiteSwedenComponent },
         { path: 'add-edit-delivery-site', component: AddEditDeliverySiteComponent }
       ]
     },
  { path: 'reports',children :[
  { path: '', redirectTo: 'forecastvsactual', pathMatch: 'full' },
  { path: 'forecastvsactual', component: ForecastactualComponent },  
  { path: 'forecastvsactualfinland', component: ForecastactualfinlandComponent },
  // { path: 'forecastvsactualsweden', component: ForecastactualswedenComponent },
  { path: 'forecastvsactualweekly', component: ForecastactualweeklyswedenComponent },
  { path: 'forecastvsactualmonthly', component: ForecastactualmonthlyswedenComponent },
  { path: 'forecastvsactualnorway', component: ForecastactualswedenComponent },
  { path: 'portalusage', component: PortalUsageComponent},
  { path: 'salesreport', component: SalesReportComponent},
  { path: 'weekly-reports', component: WeeklyReportComponent},
  { path: 'monthly-reports', component: MonthlyReportComponent},
  { path: 'daily-reports', component: DailyReportsComponent},
  { path: 'contractual-volume-reports', component: ContractualVolumeReportComponent},
  { path: 'shipped-deliveries', component: ShippedDeliveriesComponent},
  { path: 'delivery-analysis', component: DeliveryAnalysisComponent},
  ]
  }, 
  { path: 'user-management',children :[
  
  { path: '', redirectTo: 'manage-users', pathMatch: 'full' },
  { path: 'manage-users', component: ManageUsersComponent },
     { path: 'add-user', component: AddUserComponent },
     { path: 'manage-user-group', component: ManageUserGroupComponent },
     { path: 'add-user-group', component: AddUserGroupComponent }
  ]
  },
  { path: 'order-management',children :[
  { path: 'forecast-entry', component: ForecastEntryComponent },
     { path: 'history', component: HistoryComponent },
     { path: 'pending-forecast', component: PendingForecastComponent },
     { path: 'ordering-procedure', component: OrderingProcedureComponent },
     {path: 'order-entry', component: OrderEntryComponent},
     {path: 'order-tracking', component: OrderTrackingComponent},
     {path: 'unlock-order', component: UnlockOrderComponent},
  ]
  },
  {
    path: 'configuration', children: [
      { path: 'delivery-guide', component: DeliveryGuideAdminComponent },
      { path: 'customer-contract', component: CustomerContractComponent },
   //   { path: 'price-notation-admin', component: PriceNotationAdminComponent },
      { path: 'delivery-guide-admin', component: DeliveryGuideAdminComponent },
      { path: 'add-price-index', component: AddPriceIndexComponent },
      { path: 'add-product-price', component: AddProductPriceComponent },
      { path: 'add-exchange-rate-admin', component: AddExchangeRateAdminComponent },
      { path: 'contact-us-admin', component: ContactUsAdminComponent},
      { path: 'upload-document', component: UploadDocumentComponent},
      { path: 'bitumen-index-admin', component: BitumenIndexAdminComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'guest-view', component: GuestViewComponent },
      { path: 'login-history', component: LoginHistoryComponent }
    ]
  },
   ]
  
  }
  
  ];
  
  
  
   
  @NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }),
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  TranslateModule
    ],
  exports: [RouterModule]
  })
  export class AppRoutingModule {
  }
  
