<div class="container-fluid">
    <form [formGroup]="priceIndexForm">
        <div class="row pl-3 no-gutters">
            <div class="col-md-2">
                <div class="form-group">
                    <label for="month">
                        <span class="input-text-header"> {{'HOME.Month'| translate}}</span><span class="mandatory-field pl-1">*</span>
                    </label>
                    <select class="form-control input-text addPriceIndexSelect month-dropdown-width"
                        formControlName="month" [ngClass]="{ 'is-invalid': submitted && fields.month.errors }"
                        id="Month" [attr.disabled]="isPriceIndexFormEdit">
                        <option value=""> {{'HOME.Selectmonth'| translate}}</option>
                        <!-- <option *ngFor="let month of monthList" [value]="month">
                            {{ month }}
                        </option> -->

                        <!-- <option value="January"> {{'HOME.JANUARY'| translate}} </option>
                        <option value="February"> {{'HOME.FEBRUARY'| translate}} </option>
                        <option value="March"> {{'HOME.MARCH'| translate}} </option>
                        <option value="April"> {{'HOME.APRIL'| translate}} </option>
                        <option value="May"> {{'HOME.MAY'| translate}} </option>
                        <option value="June"> {{'HOME.JUNE'| translate}} </option>
                        <option value="July"> {{'HOME.JULY'| translate}} </option>
                        <option value="August"> {{'HOME.AUGUST'| translate}} </option>
                        <option value="September"> {{'HOME.SEPTEMBER'| translate}} </option>
                        <option value="October"> {{'HOME.OCTOBER'| translate}} </option>
                        <option value="November"> {{'HOME.NOVEMBER'| translate}} </option>
                        <option value="December"> {{'HOME.DECEMBER'| translate}} </option> -->

                        <option value="JAN"> {{'HOME.JAN'| translate}} </option>
                        <option value="FEB"> {{'HOME.FEB'| translate}} </option>
                        <option value="MAR"> {{'HOME.MAR'| translate}} </option>
                        <option value="APR"> {{'HOME.APR'| translate}} </option>
                        <option value="MAY"> {{'HOME.MAY'| translate}} </option>
                        <option value="JUN"> {{'HOME.JUN'| translate}} </option>
                        <option value="JUL"> {{'HOME.JUL'| translate}} </option>
                        <option value="AUG"> {{'HOME.AUG'| translate}} </option>
                        <option value="SEP"> {{'HOME.SEP'| translate}} </option>
                        <option value="OCT"> {{'HOME.OCT'| translate}} </option>
                        <option value="NOV"> {{'HOME.NOV'| translate}} </option>
                        <option value="DEC"> {{'HOME.DEC'| translate}} </option>

                    </select>
                    <div *ngIf="submitted && fields.month.errors" class="invalid-feedback">
                        <div *ngIf="fields.month.errors.required"> {{'HOME.Monthisrequired'| translate}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 ml-2">
                <div class="form-group year_MV">
                    <label for="year">
                        <span class="input-text-header">{{'HOME.Year'| translate}}</span><span class="mandatory-field pl-1">*</span>
                    </label>
                    <select class="form-control input-text addPriceIndexSelect year-dropdown-width"
                        formControlName="Year" [ngClass]="{ 'is-invalid': submitted && fields.Year.errors }" id="Year"
                        [attr.disabled]="isPriceIndexFormEdit">
                        <option value="">{{'HOME.SelectYear'| translate}}</option>
                        <option *ngFor="let year of yearList" [value]="year">
                            {{year}}
                        </option>
                    </select>
                    <div *ngIf="submitted && fields.Year.errors" class="invalid-feedback">
                        <div *ngIf="fields.Year.errors.required">{{'HOME.Yearisreq'| translate}}
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        <div class="row pl-3 pt-3 no-gutters">
            <div class="col-md-2 ">
                <div class="form-group ">
                    <label for="last_name">
                        <span class="input-text-header"> {{'HOME.BitumenIndex'| translate}}
                            (2020=100)</span><span class="mandatory-field pl-1">*</span>
                    </label>  
                    <input type="number" class="form-control input-text input_box_width_test" formControlName="Index_2020"
                        [ngClass]="{ 'is-invalid': submitted && fields.Index_2020.errors, 'is-invalid-rate': isRateValueInvalid }"                        
                            id="txt_Index_2020" placeholder="(2020=100)">
                    <div *ngIf="submitted && fields.Index_2020.errors" class="invalid-feedback">
                        <div *ngIf="fields.Index_2020.errors.required">
                            {{'HOME.isreq'| translate}}  (2020=100) 
                        </div>
                    </div>
                    <div *ngIf="isRateValueInvalid2020" class="is-invalid-quantity-message">
                        <div>{{'HOME.Entervalidnumberupto3decimalplacesonly'| translate}}</div>
                    </div>

                </div>
            </div>
            <div class="col-md-2 ml-4">
                <div class="form-group bi_MV">
                    <label for="last_name">
                        <span class="input-text-header"> {{'HOME.BitumenIndex'| translate}}
                            (2015=100)</span><span class="mandatory-field pl-1">*</span>
                    </label>  
                    <input type="number" class="form-control input-text input_box_width_test" formControlName="Index_2015"
                        [ngClass]="{ 'is-invalid': submitted && fields.Index_2015.errors, 'is-invalid-rate': isRateValueInvalid }"                        
                            id="txt_Index_2015" placeholder="(2015=100)">
                    <div *ngIf="submitted && fields.Index_2015.errors" class="invalid-feedback">
                        <div *ngIf="fields.Index_2015.errors.required">
                            {{'HOME.isreq'| translate}}  (2015=100) 
                        </div>
                    </div>
                    <div *ngIf="isRateValueInvalid2015" class="is-invalid-quantity-message">
                        <div>{{'HOME.Entervalidnumberupto3decimalplacesonly'| translate}}</div>
                    </div>

                </div>
            </div>
            <div class="col-md-2 ml-4">
                <div class="form-group bi_MV_15">
                    <label for="last_name">
                        <span class="input-text-header">{{'HOME.BitumenIndex'| translate}} (2010=100)</span><span class="mandatory-field pl-1">*</span>
                    </label>
                   
                    <input type="number" class="form-control input-text input_box_width_test" formControlName="Index_2010"
                    [ngClass]="{ 'is-invalid': submitted && fields.Index_2010.errors, 'is-invalid-rate': isRateValueInvalid }"                        
                            id="txt_Index_2010" placeholder="(2010=100)">
                    <div *ngIf="submitted && fields.Index_2010.errors" class="invalid-feedback">
                        <div *ngIf="fields.Index_2010.errors.required"> {{'HOME.isreq'| translate}}(2010=100)
                           
                        </div>
                    </div>
                    <div *ngIf="isRateValueInvalid2010" class="is-invalid-quantity-message">
                        <div>{{'HOME.Entervalidnumberupto3decimalplacesonly'| translate}}</div>
                    </div>

                </div>
            </div>
            <div class="col-md-2 ml-4 ">
                <div class="form-group bi_2005_MV">
                    <label for="last_name">
                        <span class="input-text-header">{{'HOME.BitumenIndex'| translate}} (2005=100)</span><span class="mandatory-field pl-1">*</span>
                    </label>
                   
                    <input type="number" class="form-control input-text input_box_width_test" formControlName="Index_2005" 
                    [ngClass]="{ 'is-invalid': submitted && fields.Index_2005.errors, 'is-invalid-rate': isRateValueInvalid }"                       
                            id="txt_Index_2005" placeholder="(2005=100)">
                    <div *ngIf="submitted && fields.Index_2005.errors" class="invalid-feedback">
                        <div *ngIf="fields.Index_2005.errors.required">{{'HOME.isreq'| translate}}(2005=100) 
                        </div>
                    </div>
                    <div *ngIf="isRateValueInvalid2005" class="is-invalid-quantity-message">
                        <div>{{'HOME.Entervalidnumberupto3decimalplacesonly'| translate}}</div>
                    </div>

                </div>
            </div>
            <div class="col-md-2  ml-4">
                <div class="form-group bi_20_MV">
                    <label for="last_name">
                        <span class="input-text-header">{{'HOME.BitumenIndex'| translate}} (2000=100)</span><span class="mandatory-field pl-1">*</span>
                    </label>
                   
                    <input type="number" class="form-control input-text input_box_width_test" formControlName="Index_2000"  
                    [ngClass]="{ 'is-invalid': submitted && fields.Index_2000.errors, 'is-invalid-rate': isRateValueInvalid }"                      
                        id="txt_Index_2000" placeholder="(2000=100)">
                    <div *ngIf="submitted && fields.Index_2000.errors" class="invalid-feedback">
                        <div *ngIf="fields.Index_2000.errors.required"> {{'HOME.isreq'| translate}}(2000=100)
                        </div>
                    </div>
                    <div *ngIf="isRateValueInvalid2000" class="is-invalid-quantity-message">
                        <div>{{'HOME.Entervalidnumberupto3decimalplacesonly'| translate}}</div>
                    </div>

                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group bi_1995_MV">
                    <label for="last_name">
                        <span class="input-text-header">{{'HOME.BitumenIndex'| translate}} (1995=100)</span><span class="mandatory-field pl-1">*</span>
                    </label>
                   
                    <input type="number" class="form-control input-text input_box_width_test" formControlName="Index_1995"
                        [ngClass]="{ 'is-invalid': submitted && fields.Index_1995.errors, 'is-invalid-rate': isRateValueInvalid }"
                        id="txt_Index_1995" placeholder="(1995=100)">
                    <div *ngIf="submitted && fields.Index_1995.errors" class="invalid-feedback">
                        <div *ngIf="fields.Index_1995.errors.required"> {{'HOME.isreq'| translate}}(1995=100)
                        </div>
                    </div>
                    <div *ngIf="isRateValueInvalid1995" class="is-invalid-quantity-message">
                        <div>{{'HOME.Entervalidnumberupto3decimalplacesonly'| translate}}</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row pl-1 pt-2">
            <div class="col-sm">
                <button id="btn-save" class="btn btn-sm btn-primary add_bitumen_index_buttons saveBtn"
                    (click)="save()">{{'HOME.Save'| translate}}</button>
            </div>
            <div class="col-sm">
                <button id="btn-reset" class="btn btn-sm btn-primary add_bitumen_index_buttons cancelBtn"
                    (click)="reset()">{{'HOME.Reset'| translate}}</button>
            </div>
        </div>
    </form>

    <div class="container-fluid sidepadding-none pt-3" [hidden]='!(bitumeIndexData.length>0)'>
        <!-- <div class="col-lg-12">
            <div class='row no-gutters'>
                <div class="col-lg-2 align-right">
                    <label class="font-bold font-small" for="productType">Year</label>
                </div>
                <div class="col-lg-2 align-left">
                    <select class="selectDropdown" [(ngModel)]="yearToSearch">

                        <option *ngFor="let year of years" [value]="year">
                            {{ year }}
                        </option>
                    </select>
                </div>
                <div class="col-lg-6 offset-lg-2 align-right">
                    <button type="button" class="btn filter-button" for="filter" (click)='filterIndex(yearToSearch)'>
                        <label class="font-bold font-small" for="filter">Find</label></button>
                </div>
            </div>
        </div> -->
        <div class="col-lg-12 mt-10 ">
            <div class='row no-gutters'>
                <div class="mt-5" [hidden]="displayTable">
                    {{'HOME.Norecordsfound'| translate}}
                </div>
                <div class="col-lg-12 no-gutters" [hidden]='!displayTable'>
                    <!--Angular 7 Impl-->
                    <mat-table #table [dataSource]="dataSource" #sort="matSort" matSort 
                    class='table-material horizontal_scrollbar'>

                        <!-- Item Column -->

                        <ng-container matColumnDef="Year">
                            <mat-header-cell [style.display]="'none'" class="header-font" *matHeaderCellDef>
                                -{{'HOME.Year'| translate}}
                            </mat-header-cell>
                            <mat-cell [style.display]="'none'" *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.Year}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Date">
                            <!-- Sort as per year only -->
                            With Year Sort<mat-header-cell class="header-font mat-column-small" *matHeaderCellDef mat-sort-header="Year">
                                {{'HOME.Month'| translate}}-{{'HOME.Year'| translate}}
                                <!-- <mat-header-cell  class="header-font" *matHeaderCellDef > Month-Year -->
                            </mat-header-cell>
                            <!-- <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-small">
                                {{fetchedDataForItemArray.month}} {{fetchedDataForItemArray.Year}}
                            </mat-cell> -->

                            <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                {{'HOME.' + fetchedDataForItemArray.month | translate}} {{fetchedDataForItemArray.Year}}
                            </mat-cell>

                        </ng-container>
                        <ng-container matColumnDef="month">
                            <mat-header-cell [style.display]="'none'" class="header-font" *matHeaderCellDef>
                                {{'HOME.Month'| translate}}-{{'HOME.Year'| translate}}
                            </mat-header-cell>
                            <mat-cell [style.display]="'none'" *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.month}}

                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Index_2020">
                            <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header> {{'HOME.BitumenIndex'| translate}} (2020=100)
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-large">
                                {{fetchedDataForItemArray.Index_2020}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Index_2015">
                            <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header> {{'HOME.BitumenIndex'| translate}} (2015=100)
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-large">
                                {{fetchedDataForItemArray.Index_2015}}
                            </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="Index_2010">
                            <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>{{'HOME.BitumenIndex'| translate}} (2010=100)

                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-large">
                                {{fetchedDataForItemArray.Index_2010}}
                            </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="Index_2005">
                            <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header> {{'HOME.BitumenIndex'| translate}} (2005=100)
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-large">
                                {{fetchedDataForItemArray.Index_2005}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Index_2000">
                            <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header> {{'HOME.BitumenIndex'| translate}} (2000=100)
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-large">
                                {{fetchedDataForItemArray.Index_2000}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Index_1995">
                            <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header> {{'HOME.BitumenIndex'| translate}} (1995=100)
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-large">
                                {{fetchedDataForItemArray.Index_1995}}
                            </mat-cell>
                        </ng-container>

                        <!-- <ng-container matColumnDef="Actions">
                            <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef>
                            </mat-header-cell>
                            <th mat-header-cell *matHeaderCellDef
                                [ngClass]="'mat-table-column-header mat-column-actions'">
                            </th>
                            <mat-cell class="mat-column-medium" *matCellDef="let element">
                                <span (click)='editPriceIndex(element)' title="Edit price index">
                                    <i class="far fa-edit" aria-hidden="true"></i>
                                </span>
                            </mat-cell>
                        </ng-container> -->

                        <ng-container matColumnDef="Actions">
                            <mat-header-cell class="header-font mat-column-small-xs" *matHeaderCellDef>
                            </mat-header-cell>
                            <th mat-header-cell *matHeaderCellDef [ngClass]="'mat-table-column-header mat-column-actions'">
                            </th>
                            <mat-cell class="mat-column-small-xs" *matCellDef="let element">
                                <span (click)='editPriceIndex(element)' title=" {{'HOME.Editbitumenindex'| translate}}"  class='active-icon'>
                                    <i class="far fa-edit" aria-hidden="true"></i>
                                </span>
                            </mat-cell>
                        </ng-container>

                        <!-- <ng-container matColumnDef="edit">
                            <mat-header-cell class="header-font " *matHeaderCellDef> Edit
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray">
                                <span class='active-icon-color'>
                                    <i class="fa fa-pencil" aria-hidden="true" (click)="openDialog('Update',element)">
                                    </i>
                                </span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <mat-header-cell class="header-font " *matHeaderCellDef>Delete
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray">
                                <span class='active-icon-color'>
                                    <i class="fas fa-trash" aria-hidden="true" (click)="openDialog('Delete',element)">
                                    </i>
                                </span>
                            </mat-cell>
                        </ng-container> -->

                        <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns;sticky: true">
                        </mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>

                    <mat-paginator   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"   showFirstLastButtons></mat-paginator>



                    <!-- <div class="row buttonGroup">
                        <button class="newEntryButton" type="submit" (click)="newEntryFunction()">
                            New Entry
                        </button>
                        <button class=" downloadButton" type="submit" (click)="downloadBitumenIndexData()">
                            Save
                        </button>
                    </div> -->
                </div>
            </div>
        </div>


        <!-- Option for table content scroll <div class="row buttonGroup">
                                <button class=" downloadButton" type="submit" (click)="downloadBitumenIndexData()">Download</button>
                            </div> -->
    </div>
    <div class="container-fluid sidepadding-none" [hidden]='(bitumeIndexData.length>0)'>
        {{'HOME.DNA'| translate}}
    </div>
</div>
