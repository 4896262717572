<div class="container-fluid zero-left-right-padding">
    <div class="pt-2 col-lg-12 col-md-12 zero-left-right-padding sales_report_div_mv">
        <div class="row">

            <!-- Commented old code, kept for future reference -->
            <!-- <div class="col-lg-6 toggle_btn_div_mv"
                *ngIf="checkIfMenuIdIsAllowedToLoggedInUser('47') && checkIfMenuIdIsAllowedToLoggedInUser('50')">
                <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                    #group="matButtonToggleGroup" [(ngModel)]="currentToggleMenu" value="daily-reports">
                    <mat-button-toggle value="daily-reports" ngDefaultControl (click)="toggleButtonClick('daily-reports')">
                        {{'HOME.Dailyreports'| translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="shipped-deliveries" (click)="toggleButtonClick('shipped-deliveries')">
                        {{'HOME.Shippeddeliveries'| translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div> -->
        
            <!-- Commented old code, kept for future reference -->
            <!-- <div class="col-lg-6 toggle_btn_div_mv">
                <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                    #group="matButtonToggleGroup" [(ngModel)]="currentToggleMenu" value="daily-reports">
                    <mat-button-toggle value="daily-reports" ngDefaultControl (click)="toggleButtonClick('daily-reports')">
                        {{'HOME.Dailyreports'| translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="shipped-deliveries" (click)="toggleButtonClick('shipped-deliveries')">
                        {{'HOME.Shippeddeliveries'| translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div> -->
        
        </div>
        <div class="row pt-2" *ngIf="currentToggleMenu === 'daily-reports'">
            <div class="col-lg-6 toggle_button_div_mv">
                <!-- <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                    #group="matButtonToggleGroup" [(ngModel)]="currentToggleMenu" value="reports">
                    <mat-button-toggle value="reports" ngDefaultControl (click)="toggleButtonClick('reports')">
                        {{'HOME.Reports'| translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="graph" (click)="toggleButtonClick('graph')">
                        {{'HOME.graph'| translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group> -->
            </div>
            <div class="col-lg-6 download_button_div_mv no-top-margin" *ngIf="!this.mobileQuery.matches">
                <div class="downloadDiv pt-2">
                    <div *ngIf="displayTable">
                        <i (click)="downloadReportsData()" class="fas fa-download downloadIcon "><span
                                class="downloadText">{{'HOME.Download'| translate}}</span></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-1" *ngIf="currentToggleMenu === 'daily-reports'">
            <div class="pl-3 customer-name-label_mv">
                <label for="customerName">
                    <span class="customer-name-label">{{'HOME.Customername'| translate}}
                    </span>
                </label>
            </div>
            <div class=" customer_name_dropdown_div customer_name_dropdown_div_mv " *ngIf="isLoggedInUserPortalAdmin">
                <select class="customer-name-dropdown customer_name_dropdown_width customer_name_dropdown_width_mv" id="customer-name"
                    [(ngModel)]='customerIdUserSelected' (change)="this.getDeliverySites()">
                    <!-- <option value="">{{'HOME.All'| translate}}</option> -->
                    <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                        {{customer.CustomerName}}
                    </option>
                </select>
            </div>
            <div class="col-3 pl-1 customer-name-text_mv" *ngIf="!isLoggedInUserPortalAdmin">
                <input type="text" class="customer-name-textbox pr-5" [(ngModel)]='customerName' disabled />
            </div>
    
            <div class="delivery_site_label_div_mv pl-4">
                <label for="deliverysite">
                    <span class="customer-name-label">{{'HOME.Deliverysite'| translate}}
                    </span>
                </label>
            </div>
            <div class="delivery_site_dropdown_div delivery_site_dropdown_div_mv">
                <select class="customer-name-dropdown deliverySite_name_dropdown_width_mv" id="delivery-site" [(ngModel)]='deliverySiteUserSelected'>
                    <option value="">{{'HOME.All'| translate}}</option>
                    <option *ngFor="let deliverySite of deliverySiteList" [value]="deliverySite">{{deliverySite}}
                    </option>
                </select>
            </div>
        </div>
        <div class="row pt-3 no-gutters" *ngIf="currentToggleMenu === 'daily-reports'">
            <!-- Commented code for View dropdown, kept for future reference -->
            <!-- <div class="col-lg-1 viewBy_label_div viewBy_label_div_mv">
                        <label for="viewBy">
                            <span class="customer-name-label">{{'HOME.Viewby'| translate}}
                            </span>
                        </label>
                    </div>
                    <div class="col-lg-2 viewBy_input_div viewBy_input_div_mv">
                        <select class="customer-name-dropdown viewBy_input_width viewBy_input_width_mv" id="viewBy"
                            [(ngModel)]='viewUserSelected'>
                            <option value="All">{{'HOME.All'| translate}}</option>
                            <option value="By deliverysite">{{'HOME.Bydeliverysite'| translate}}</option>
                            <option value="By product"> {{'HOME.Byproduct'| translate}} </option>
                            <option value="By depot"> {{'HOME.Bydepot'| translate}} </option>
                            <option value="By product and depot"> {{'HOME.Byproductanddepot'| translate}} </option>
                        </select>
                    </div> -->
    
            <div class="col-lg-1 pl-3 fromDate">
                <label for="FromDate">
                    <span class="customer-name-label">{{'HOME.FromDate'| translate}}
                    </span>
                </label>
            </div>
            <div class="col-lg-2 pl-4 calendar-picker_mv">
            <!-- <mat-form-field appearance="fill">
                <mat-label>{{'HOME.FromDate'| translate}}</mat-label>
                <input matInput [matDatepicker]="picker1" [(ngModel)]='startDate'>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field> -->
                            <mat-form-field class="datepicker">
                                <input matInput [matDatepicker]="picker" placeholder="" [(ngModel)]='startDate' [min]="january012020Date">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="startDate == null" class="invalid-date">
                                {{'HOME.FromDateIsRequired'| translate}}
                            </div>
                            <div *ngIf="fromDateIsOlderThan1stJanuary2020" class="invalid-date">
                                {{'HOME.FromDateIsOlderThan1stJanuary2020'| translate}}
                            </div>
            </div>
            <div class="col-lg-1 toDate pl-4 calendar-picker_mv">
                <label for="ToDate">
                    <span class="customer-name-label">{{'HOME.ToDate'| translate}}
                    </span>
                </label>
            </div>
              <div class="col-lg-2 pl-4 calendar-picker_mv">
              <!-- <mat-form-field appearance="fill">
                <mat-label>{{'HOME.ToDate'| translate}}</mat-label>
                <input matInput [matDatepicker]="picker2" [(ngModel)]='endDate'>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field> -->
                            <mat-form-field class="datepicker">
                                <input matInput [matDatepicker]="picker1" placeholder="" [(ngModel)]='endDate' [min]="january012020Date">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="endDate == null" class="invalid-date">
                                {{'HOME.ToDateIsRequired'| translate}}
                            </div>
            </div>

            <div class="col-lg-1 pl-2 search-by">
                <label for="searchBy">
                    <span class="customer-name-label">{{'HOME.Searchby'| translate}}
                    </span>
                </label>
            </div>
            <div class="col-lg-2 search-by">
                <div>
                    <select class="searchByDropdown" id="searchBy" [(ngModel)]='searchBy'>
                        <option *ngIf="(countryCode === 'GBR')" value="REQUESTED_DATE"> {{'HOME.Requesteddate'| translate}} </option>
                        <option value="ACTUAL_SHIP_DATE"> {{'HOME.ActualShipDate'| translate}} </option>
                    </select>
                </div>
            </div>
            
            <div class="col-lg-1 findButton_div_mv">
                <button class="findButton" (click)="getReportsForTable()">{{'HOME.Find'| translate}}</button>
            </div>
            
            <div class="global-search-text-box">
                <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
            </div>

            <div class="page-note search-by">
                <span>{{'HOME.shippedDeliveriesPageNote'| translate }}</span>
            </div>
            
        </div>
       <div class='row no-gutters pt-4 pl-3' *ngIf="currentToggleMenu === 'shipped-deliveries'">
          <div class="Customer_name_label_div Customer_name_label_div_mv" *ngIf="this.role == 'PA'">
             <label class="sales_report_label" for="year">
             <span class="input-text-header"> {{'HOME.Customername'| translate}}</span>
             </label>
          </div>
          <div class="Customer_name_dropdown_div Customer_name_dropdown_div_mv" *ngIf="this.role == 'PA'">
             <select class="sales_report_dropdown sales_report_dropdown_mv" id="CustomerId" [(ngModel)]='customerIdUserSelected'>
             <option value="">{{'HOME.All'| translate}}</option>
             <option *ngFor="let customer of Customers" [ngValue]="customer.CustomerID">
             {{customer.CustomerName}}
             </option>
             </select>
          </div>
          <div class="year_label_div year_label_div_mv">
             <label class="sales_report_label" for="year">
             <span class="input-text-header"> {{'HOME.Year'| translate}}</span>
             </label>
          </div>
          <div class="year_dropdown_div year_dropdown_div_mv">
             <select class=" sales_report_dropdown input-text" id="Year" [(ngModel)]='yearUserSelected'>
             <option *ngFor="let year of yearList" [value]="year">{{year}}</option>
             </select>
          </div>
          <div class="month_label_div month_label_div_mv">
             <label class="sales_report_label" for="month">
             <span class="input-text-header"> {{'HOME.Month'| translate}}</span>
             </label>
          </div>
          <div class="month_dropdown_div month_dropdown_div_mv">
             <select class="sales_report_dropdown month_dropdown_mv input-text" id="Month" [(ngModel)]='monthUserSelected'>
             <option value="">{{'HOME.All'| translate}}</option>
             <option value="01"> {{'HOME.Jan'| translate}} </option>
             <option value="02"> {{'HOME.Feb'| translate}} </option>
             <option value="03"> {{'HOME.Mar'| translate}} </option>
             <option value="04"> {{'HOME.Apr'| translate}} </option>
             <option value="05"> {{'HOME.May'| translate}} </option>
             <option value="06"> {{'HOME.Jun'| translate}} </option>
             <option value="07"> {{'HOME.Jul'| translate}} </option>
             <option value="08"> {{'HOME.Aug'| translate}} </option>
             <option value="09"> {{'HOME.Sep'| translate}} </option>
             <option value="10"> {{'HOME.Oct'| translate}} </option>
             <option value="11"> {{'HOME.Nov'| translate}} </option>
             <option value="12"> {{'HOME.Dec'| translate}} </option>
             </select>
          </div>
          <div class="find_btn_div  ">
             <button class="find_btn" type="submit" (click)="findSalesReport()">{{'HOME.Find'| translate}}
             </button>
          </div>
       </div>
    </div>
    <div class="col-lg-12 mt-3 sales_report_table_div_mv pl-3" [hidden]="currentToggleMenu === 'daily-reports'">
       <mat-table #table [dataSource]="dataSource" matSort class="forecastTable shipped-deliveries-mat-table" (matSortChange)="sortData($event)">
       <ng-container matColumnDef="CUSTOMER_Name">
          <mat-header-cell class="mat-column-large" *matHeaderCellDef mat-sort-header>
             {{'HOME.Customername'| translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-large">
             {{fetchedDataForItemArray.CUSTOMER_Name}}
          </mat-cell>
       </ng-container>
       <ng-container matColumnDef="Month">
          <mat-header-cell class="mat-column-small" *matHeaderCellDef mat-sort-header>
             {{'HOME.Month'| translate}}
          </mat-header-cell>
          <mat-cell class="mat-column-small" *matCellDef="let fetchedDataForItemArray">
             {{'HOME.' + fetchedDataForItemArray.Month | translate}}
          </mat-cell>
       </ng-container>
       <ng-container matColumnDef="YEAR">
          <mat-header-cell class="mat-column-small" *matHeaderCellDef mat-sort-header>
             {{'HOME.Year'| translate}}
          </mat-header-cell>
          <mat-cell class="mat-column-small" *matCellDef="let fetchedDataForItemArray">
             {{fetchedDataForItemArray.YEAR}}
          </mat-cell>
       </ng-container>
       <ng-container matColumnDef="LastModifiedDate">
          <mat-header-cell class="mat-column-small" *matHeaderCellDef mat-sort-header>
             {{'HOME.Updatedon'| translate}}
          </mat-header-cell>
          <mat-cell class="mat-column-small" *matCellDef="let fetchedDataForItemArray">
             {{fetchedDataForItemArray.LastModifiedDate | date: 'dd/MM/yyyy'}}
             <!-- {{fetchedDataForItemArray.LastModifiedDate}} -->
          </mat-cell>
       </ng-container>
    <ng-container matColumnDef="SALES_REPORT">
        <mat-header-cell *matHeaderCellDef class="mat-column-small"
            [ngClass]="{'hide-table-column': (mobileQuery.matches) }">
            {{'HOME.Download'| translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-small"
            [ngClass]="{'hide-table-column': (mobileQuery.matches) }">
            <a class='pdf-content' (click)='downloadExcel(fetchedDataForItemArray)'>
                <span class='font-vsmall font-bold'>
                    {{'HOME.Download'| translate}}
                </span>
            </a>
        </mat-cell>
    </ng-container>
       <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsShippedDeliveries; sticky:true">
       </mat-header-row>
       <mat-row *matRowDef="let row; columns: displayedColumnsShippedDeliveries;"></mat-row>
       </mat-table>
    </div>
    <div class="row pt-3" *ngIf="currentToggleMenu === 'daily-reports' && !displayTable && isFindButtonClicked">
        <div class="col-lg-12 mt-3">
            <div class="mt-5" class="no-records-message">
                <span>{{'HOME.Norecordsfound'| translate}}</span>
            </div>
        </div>
    </div>
    <div class="row no-bottom-margin" [hidden]='!displayTable || currentToggleMenu === "shipped-deliveries"'>
        <div class="col-lg-12 table_div_mv">

            <div class="daily-reports-container mat-elevation-z8">
            
            <mat-table #table [dataSource]="dataSource" [ngClass]="{'uk-mat-table': (countryCode === 'GBR'), 'non-uk-mat-table': (countryCode !== 'GBR'), 'horizontal_scrollbar_uk': (countryCode === 'GBR'), 'horizontal_scrollbar_non_uk': (countryCode !== 'GBR') }" #dailyReportTableSort="matSort" matSort matSortActive="ORDER_NUMBER" matSortDirection="asc" >
                <ng-container matColumnDef="DELIVERY_TICKET">
                    <mat-header-cell
                        [ngClass]="{'hide-table-column': (countryCode === 'FIN' || countryCode === 'SWE' || countryCode === 'NOR' || countryCode ==='DNK')}"
                        class="column_medium" *matHeaderCellDef mat-sort-header>
                        <div class="align-text-left">{{'HOME.DeliveryticketNumber'| translate}}</div>
                    </mat-header-cell>
                    <mat-cell
                        [ngClass]="{'hide-table-column': (countryCode === 'FIN' || countryCode === 'SWE' || countryCode === 'NOR' || countryCode ==='DNK')}"
                        class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.DELIVERY_TICKET }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CUSTOMER_NAME">
                    <mat-header-cell class="column_medium" [ngClass]="{'hide-table-column': !(showUK)}" *matHeaderCellDef
                        mat-sort-header>
                        {{'HOME.Customername'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" [ngClass]="{'hide-table-column': !(showUK)}"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.CUSTOMER_NAME }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CUSTOMER_PO">
                    <mat-header-cell
                        [ngClass]="{'customer-po-number-column': (selectedLanguage === 'NR'), 'column_medium': (selectedLanguage !== 'NR')}"
                        *matHeaderCellDef mat-sort-header>
                        <!-- {{'HOME.CustomerPOnumber'| translate}} -->
                        <div *ngIf="countryCode !=='SWE' && countryCode !=='DNK'">
                            <div class="align-text-left">{{'HOME.CustomerPO'| translate}}</div>
                            <div class="align-text-left">{{'HOME.number'| translate}}</div>
                        </div>
                        <div *ngIf="countryCode ==='SWE' || countryCode ==='DNK'">
                            <div>{{'HOME.Deliveryticket'| translate}}</div>
                            <div class="align-text-left">{{'HOME.number'| translate}}</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell
                        [ngClass]="{'customer-po-number-column': (selectedLanguage === 'NR'), 'column_medium': (selectedLanguage !== 'NR')}"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.CUSTOMER_PO }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DEPOT_NAME">
                    <mat-header-cell class="column_medium"
                        [ngClass]="{'hide-table-column': !(showUK) || !(showDepotNameColumn) }" *matHeaderCellDef mat-sort-header>
                        {{'HOME.LoadingPoint'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium"
                        [ngClass]="{'hide-table-column': !(showUK) || !(showDepotNameColumn) }"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.DEPOT_NAME }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ACTUAL_SHIP_DATE">
                    <mat-header-cell class="column_medium" *matHeaderCellDef mat-sort-header>
                        <!-- {{'HOME.ActualShipDate'| translate}} -->
                        <div *ngIf="countryCode !=='SWE' && countryCode !=='DNK'">
                            <div class="align-text-left">{{'HOME.ActualShipDate'| translate}}</div>
                        </div>
                        <div *ngIf="countryCode ==='SWE' || countryCode ==='DNK'">
                            <div>{{'HOME.Shipdate'| translate}}</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ACTUAL_SHIP_DATE }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DELIVERY_SITE">
                    <mat-header-cell [ngClass]="{'delivery-place-column': !(showUK), 'column_medium': (showUK)}" *matHeaderCellDef
                        mat-sort-header>
                        {{'HOME.DeliveryPlace'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'delivery-place-column': !(showUK), 'column_medium': (showUK)}"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.DELIVERY_SITE }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="PRODUCT_NAME">
                    <mat-header-cell class="column_medium"
                        *matHeaderCellDef mat-sort-header>
                        {{'HOME.Product'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.PRODUCT_NAME }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SHIPPED_QTY">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.QuantityMT'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.SHIPPED_QTY | number:'1.2-2':'fr-FR' }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="productTypeId">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium" *matHeaderCellDef>
                        {{'HOME.Typeofproduct'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" [ngClass]="{'hide-table-column': !(showUK)}"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.productTypeId }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="INVOICE_NUMBER">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium" *matHeaderCellDef>
                        {{'HOME.Invoicenumber'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.INVOICE_NUMBER }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="INVOICE_DATE">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium" *matHeaderCellDef
                        mat-sort-header>
                        {{'HOME.Invoicedate'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.INVOICE_DATE }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="INCOTERMS">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium"
                        *matHeaderCellDef>
                        {{'HOME.Incoterms'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.INCOTERMS }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ORDER_NUMBER">
                    <mat-header-cell class="column_medium" *matHeaderCellDef mat-sort-header>
                        <!-- {{'HOME.NynasOrderNumber'| translate}} -->
                        <div>
                            <div class="align-text-left">{{'HOME.NynasOrder'| translate}}</div>
                            <div class="align-text-left">{{'HOME.number'| translate}}</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ORDER_NUMBER }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ORDER_TYPE">
                    <mat-header-cell class="column_medium" *matHeaderCellDef [ngClass]="{'hide-table-column': !(showUK)}">
                        {{'HOME.NynasOrderType'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" [ngClass]="{'hide-table-column': !(showUK)}"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ORDER_TYPE }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ORIGINAL_ORDER_NUMBER">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium" *matHeaderCellDef>
                        <!-- {{'HOME.NynasOriginalordernumber'| translate}} -->
                        <div>
                            <div>{{'HOME.Nynasoriginal'| translate}}</div>
                            <div class="align-text-left">{{'HOME.order'| translate}} {{'HOME.number'| translate}}</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ORIGINAL_ORDER_NUMBER }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ADHESIVE_CONTENT">
                    <mat-header-cell [ngClass]="{'hide-table-column': (!showUK || (countryCode === 'SWE' || countryCode === 'NOR' || countryCode ==='DNK'))}"
                        class="column_medium" *matHeaderCellDef>
                        {{'HOME.AddisivecontentPercentage'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': (!showUK || (countryCode === 'SWE' || countryCode === 'NOR' || countryCode ==='DNK'))}"
                        class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ADHESIVE_CONTENT }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="TEMPERATURE">
                    <mat-header-cell [ngClass]="{'hide-table-column': (!showUK || (countryCode === 'SWE' || countryCode === 'NOR' || countryCode ==='DNK'))}"
                        class="column_medium" *matHeaderCellDef>
                        {{'HOME.Temperature'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': (!showUK || (countryCode === 'SWE' || countryCode === 'NOR' || countryCode ==='DNK'))}"
                        class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.TEMPERATURE }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="REQUESTED_DATE">
                    <mat-header-cell
                        [ngClass]="{'hide-table-column': (countryCode === 'FIN' || countryCode === 'SWE' || countryCode === 'NOR' || countryCode ==='DNK')}"
                        class="column_medium" *matHeaderCellDef mat-sort-header>
                        <div class="align-text-left">{{'HOME.Requesteddate'| translate}}</div>
                    </mat-header-cell>
                    <mat-cell
                        [ngClass]="{'hide-table-column': (countryCode === 'FIN' || countryCode === 'SWE' || countryCode === 'NOR' || countryCode ==='DNK')}"
                        class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.REQUESTED_DATE }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="INVOICE_NUMBER_UK">
                    <mat-header-cell
                        [ngClass]="{'hide-table-column': (countryCode === 'FIN' || countryCode === 'SWE' || countryCode === 'NOR' || countryCode ==='DNK')}"
                        class="column_medium1" *matHeaderCellDef mat-sort-header>
                        {{'HOME.InvoiceNumber'| translate}}
                    </mat-header-cell>
                    <mat-cell
                        [ngClass]="{'hide-table-column': (countryCode === 'FIN' || countryCode === 'SWE' || countryCode === 'NOR' || countryCode ==='DNK')}"
                        class="column_medium1" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.INVOICE_NUMBER_UK }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ENGINE_VEHICLE_NUMBER">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(countryCode === 'SWE' || countryCode ==='DNK')}"
                        class="column_medium" *matHeaderCellDef>
                        {{'HOME.EngineVehicleNumber'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': !(countryCode === 'SWE' || countryCode ==='DNK')}" class="column_medium"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ENGINE_VEHICLE_NUMBER }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="COMPARTMENT_VEHICLE_NUMBER">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(countryCode === 'SWE' || countryCode ==='DNK')}"
                        class="column_medium" *matHeaderCellDef>
                        {{'HOME.CompartmentVehicleNumber'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': !(countryCode === 'SWE' || countryCode ==='DNK')}" class="column_medium"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.COMPARTMENT_VEHICLE_NUMBER }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ORIGINAL_ORDER_NUMBER_UK">
                    <mat-header-cell [ngClass]="{'hide-table-column': (showUK)}" class="column_medium" *matHeaderCellDef>
                        <div>
                            <div>{{'HOME.Nynasoriginal'| translate}}</div>
                            <div class="align-text-left">{{'HOME.order'| translate}} {{'HOME.number'| translate}}</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': (showUK)}" class="column_medium"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ORIGINAL_ORDER_NUMBER_UK }}
                    </mat-cell>
                </ng-container>
                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsDailyReports; sticky:true">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsDailyReports;" [ngClass]="{'row-top-border': row.showRowTopBorder}">
                </mat-row>
            </mat-table>

        </div>

            <!-- <mat-paginator [length]="50" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons>
            </mat-paginator> -->
            
        </div>
    </div>
 </div>