// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  getDeviceIPAddressUrl: 'http://api.ipify.org/?format=json',

 //  apiUrl: 'https://integration.nynas.com',
  //cookieDomain: 'https://integration.nynas.com',
  
  // apiUrl: 'http://nis-dev.nynas.com:7575',
  // cookieDomain: 'http://nis-dev.nynas.com:7575',

  //apiUrl: 'http://130.61.54.223', -->Dev
  //cookieDomain: 'http://130.61.54.223'
  apiUrl: 'http://10.0.0.17', //test
  cookieDomain: 'http://10.0.0.17'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
