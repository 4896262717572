import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShippedDeliveriesService {

  apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getSalesReportLocation(year: string, countryCode: string, customerId: string) {
    const url = this.apiUrl + '/GetSalesReportLocation';
    return this.httpClient.get(url, {
      params: {
        Year: year,
        Country: countryCode,
        CustomerID: customerId,
      }
    });
  }

  fetchDailyActualSalesForAll(countryCode: string, filter: string, startDate: string, endDate: string, customerId: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/FetchDailyActualSales/FetchDailySalesForAdmin';
    return this.httpClient
      .get(url, {
        params: {
          CountryCode: countryCode,
          Filter: filter,
          Value_1: startDate,
          Value_2: endDate,
          CustomerID: customerId,
          JDE_Address_Number: jdeAddressNumber
        }
      });
  }

  fetchDailySalesForUser(countryCode: string, filter: string, startDate: string, endDate: string, userId: string, customerId: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/FetchDailyActualSales/FetchDailySalesForUser';
    return this.httpClient
      .get(url, {
        params: {
          CountryCode: countryCode,
          Filter: filter,
          Value_1: startDate,
          Value_2: endDate,
          CustomerID: customerId,
          User_ID: userId,
          JDE_Address_Number: jdeAddressNumber
        }
      });
  }

  fetchDailyReportsData(countryCode: string, filter: string, startDate: string, endDate: string, userId: string, customerId: string, jdeAddressNumber: string, showUserDataOnly: boolean) {
    if (showUserDataOnly) {
      return this.fetchDailySalesForUser(countryCode, filter, startDate, endDate, userId, customerId, jdeAddressNumber);
    } else {
      return this.fetchDailyActualSalesForAll(countryCode, filter, startDate, endDate, customerId, jdeAddressNumber);
    }
  }

  fetchDeliverySitesData(userId: string, countryCode: any, status: any, customerId: any, showUserDataOnly: boolean) {
    if (showUserDataOnly) {
      return this.getDeliverySiteListForUser(userId, countryCode, status, customerId);
    } else {
      return this.getDeliverySiteList(customerId, status, countryCode);
    }
  }

  getDeliverySiteList(customerId: string, approvalStage: string, countryCode: string) {
    let url = this.apiUrl + '/FetchDeliverySiteService';
    return this.httpClient
      .get(url, {
        params: {
          CustomerId: customerId,
          ApprovalStage: approvalStage,
          CountryCode: countryCode
        }
      });
  }

  getDeliverySiteListForUser(userId: string, countryCode: any, mappingStatus: any, customerId: any) {
    let url = this.apiUrl + '/GetDeliverySiteForUser';
    return this.httpClient
      .get(url, {
        params: {
          UserID: userId,
          CountryCode: countryCode,
          MappingStatus: "ACTIVE",  // Pass MappingStatus as ACTIVE to get delivery sites mapped to current user
          CustomerID: customerId
        }
      });
  }

}
