<div class="container-fluid">
    <div class="">
        <div class="row">
            <div class="col-2">
                <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                    #group="matButtonToggleGroup" [(ngModel)]="currentToggleMenu" value="daily">
                    <mat-button-toggle value="daily" ngDefaultControl (click)="toggleButtonClick('daily')">
                        {{'HOME.Daily'| translate}}

                    </mat-button-toggle>
                    <mat-button-toggle value="monthly" (click)="toggleButtonClick('monthly')">
                        {{'HOME.Monthly'| translate}}

                    </mat-button-toggle>
                </mat-button-toggle-group>

                <!-- <mat-button-toggle-group class="button-group-style " #group="matButtonToggleGroup" [(ngModel)]="view"
                        value="Grid">
                        <mat-button-toggle value="Grid" ngDefaultControl>List view</mat-button-toggle>
                        <mat-button-toggle value="Table" ngDefaultControl>Grid view</mat-button-toggle>
                    
                    </mat-button-toggle-group> -->

            </div>
        </div>
        <form [formGroup]="priceIndexForm">
            <div class="row pl-3 pt-2 no-gutters">
                <div class="col-md-2">
                    <!-- <div class="form-group">
                        <label for="last_name">
                            <span class="input-text-header">Source</span>
                            <span class="mandatory-field pl-1">*</span>
                        </label>
                        <div class="">
                            <input type="radio" formControlName="Source" id="radio-ecb" value="ECB" />
                            <span class="radio-button-text" for="ECB">ECB</span> 

                            <input type="radio" formControlName="Source" id="radio-nordea" value="NORDEA" class="ml-4" />
                            <span class="radio-button-text" for="NORDEA">NORDEA</span>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="row pl-3 no-gutters">
                <div class="col-md-2" [hidden]='currentToggleMenu === "monthly"'>
                    <div class="form-group">
                        <label for="IndexDate">
                            <span class="input-text-header"> {{'HOME.Date'| translate}}
                            </span>
                            <span class="mandatory-field pl-1">*</span>
                        </label>
                        <mat-form-field [ngClass]="{'disabled-date-picker': isPriceIndexFormEdit}" class="datepicker">
                            <input matInput [matDatepicker]="picker" placeholder="" formControlName="IndexDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <!-- <div *ngIf="submitted && fields.IndexDate.errors" class="date-isinvalid">
                            <div *ngIf="fields.IndexDate.errors.required"> {{'HOME.Dateisrequired'| translate}}

                            </div>
                        </div> -->
                        <div *ngIf="submitted && fields.IndexDate.errors" class="date-isinvalid">
                            <div *ngIf="fields.IndexDate.errors.required && !fields.IndexDate.errors.matDatepickerParse">
                                {{'HOME.Dateisrequired'| translate}}
                            </div>
                            <div *ngIf="fields.IndexDate.errors.required && fields.IndexDate.errors.matDatepickerParse">
                                {{'HOME.Dateisinvalid'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2" [hidden]='currentToggleMenu === "daily"'>
                    <div class="form-group">
                        <label for="month">
                            <span class="input-text-header"> {{'HOME.Month'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addPriceIndexSelect month-dropdown-width"
                            formControlName="Month" [ngClass]="{ 'is-invalid': submitted && fields.Month.errors }"
                            id="Month" [attr.disabled]="isPriceIndexFormEdit">
                            <option value="">{{'HOME.Selectmonth'| translate}}</option>
                            <!-- <option *ngFor="let month of monthList" [value]="getFullMonthName(month)">
                                {{ getFullMonthName(month) }}
                            </option> -->

                            <!-- <option value="January"> {{'HOME.JANUARY'| translate}} </option>
                            <option value="February"> {{'HOME.FEBRUARY'| translate}} </option>
                            <option value="March"> {{'HOME.MARCH'| translate}} </option>
                            <option value="April"> {{'HOME.APRIL'| translate}} </option>
                            <option value="May"> {{'HOME.MAY'| translate}} </option>
                            <option value="June"> {{'HOME.JUNE'| translate}} </option>
                            <option value="July"> {{'HOME.JULY'| translate}} </option>
                            <option value="August"> {{'HOME.AUGUST'| translate}} </option>
                            <option value="September"> {{'HOME.SEPTEMBER'| translate}} </option>
                            <option value="October"> {{'HOME.OCTOBER'| translate}} </option>
                            <option value="November"> {{'HOME.NOVEMBER'| translate}} </option>
                            <option value="December"> {{'HOME.DECEMBER'| translate}} </option> -->

                            <option value="JAN"> {{'HOME.JAN'| translate}} </option>
                            <option value="FEB"> {{'HOME.FEB'| translate}} </option>
                            <option value="MAR"> {{'HOME.MAR'| translate}} </option>
                            <option value="APR"> {{'HOME.APR'| translate}} </option>
                            <option value="MAY"> {{'HOME.MAY'| translate}} </option>
                            <option value="JUN"> {{'HOME.JUN'| translate}} </option>
                            <option value="JUL"> {{'HOME.JUL'| translate}} </option>
                            <option value="AUG"> {{'HOME.AUG'| translate}} </option>
                            <option value="SEP"> {{'HOME.SEP'| translate}} </option>
                            <option value="OCT"> {{'HOME.OCT'| translate}} </option>
                            <option value="NOV"> {{'HOME.NOV'| translate}} </option>
                            <option value="DEC"> {{'HOME.DEC'| translate}} </option>

                        </select>
                        <div *ngIf="submitted && fields.Month.errors" class="invalid-feedback">
                            <div *ngIf="fields.Month.errors.required"> {{'HOME.Monthisreq'| translate}}

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 year_align" [hidden]='currentToggleMenu === "daily"'>
                    <div class="form-group year_div">
                        <label for="year">
                            <span class="input-text-header"> {{'HOME.Year'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addPriceIndexSelect year-dropdown-width"
                            formControlName="Year" [ngClass]="{ 'is-invalid': submitted && fields.Year.errors }"
                            id="Year" [attr.disabled]="isPriceIndexFormEdit">
                            <option value=""> {{'HOME.SelectYear'| translate}}
                            </option>
                            <option *ngFor="let year of yearList" [value]="year">
                                {{year}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.Year.errors" class="invalid-feedback">
                            <div *ngIf="fields.Year.errors.required"> {{'HOME.Yearisreq'| translate}}

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 Notation_align " [ngClass]="{'margin-left-7': currentToggleMenu === 'monthly'}">
                    <div class="form-group">
                        <label for="last_name">

                            <span class="input-text-header"> {{'HOME.Notation'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addPriceIndexSelect priceNotation-dropdown-width year_select_mz"
                            formControlName="PriceNotation"
                            [ngClass]="{ 'is-invalid': submitted && fields.PriceNotation.errors }" id="priceNotation"
                            [attr.disabled]="isPriceIndexFormEdit">
                            <option value=""> {{'HOME.selectnotation'| translate}}
                            </option>
                            <option *ngFor="let priceNotation of priceNotationList" [value]="priceNotation">
                                {{priceNotation}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.PriceNotation.errors" class="invalid-feedback">
                            <div *ngIf="fields.PriceNotation.errors.required">{{'HOME.Notationisreq'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="last_name">
                            <span class="input-text-header">{{'HOME.Rate'| translate}}</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="number" class="form-control input-text " formControlName="Rate"
                            [ngClass]="{ 'is-invalid': submitted && fields.Rate.errors, 'is-invalid-rate': isRateValueInvalid }"
                            id="txt_rate" placeholder="Rate">
                        <div *ngIf="submitted && fields.Rate.errors" class="invalid-feedback">
                            <div *ngIf="fields.Rate.errors.required">{{'HOME.Rateisreq'| translate}}
                            </div>
                        </div>
                        <div *ngIf="isRateValueInvalid" class="is-invalid-quantity-message">
                            <div>{{'HOME.Entervalidnumberupto3decimalplacesonly'| translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 UOM_align">
                    <div class="form-group">
                        <label for="uom">
                            <span class="input-text-header">UOM</span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addPriceIndexSelect uom-dropdown-width"
                            formControlName="UOM" [ngClass]="{ 'is-invalid': submitted && fields.UOM.errors }" id="UOM">
                            <option value=""> {{'HOME.SELECT'| translate}}
                                UOM</option>
                            <option *ngFor="let uom of uomList" [value]="uom">
                                {{uom}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.UOM.errors" class="invalid-feedback">
                            <div *ngIf="fields.UOM.errors.required"> {{'HOME.UOMisreq'| translate}}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pl-1 pt-2" [ngClass]="{'margin-top-2': currentToggleMenu === 'daily'}">
                <div class="col-sm">
                    <button id="btn-save" class="btn btn-sm btn-primary add-price-buttons saveBtn" (click)="save()">
                        {{'HOME.Save'| translate}}
                    </button>
                </div>
                <div class="col-sm">
                    <button id="btn-reset" class="btn btn-sm btn-primary add-price-buttons cancelBtn" (click)="reset()">
                        {{'HOME.Reset'| translate}}
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div [hidden]='currentToggleMenu !== "daily"'>
        <div class="row pt-3 pb-5">
            <div class="col-sm">
                <div [hidden]="(!isRequestCompleted || (dataSourceForDailyTable.data && dataSourceForDailyTable.data.length))"
                    class="no-records-message pt-3 pl-2"> {{'HOME.Norecordsfound'| translate}}
                </div>
                <div [hidden]="(!isRequestCompleted || (!dataSourceForDailyTable.data || (dataSourceForDailyTable.data && !dataSourceForDailyTable.data.length)))"
                    class="price-index-daily-table">
                    <!--Angular 7 Impl-->
                    <!-- <mat-table #table [dataSource]="dataSourceForDailyTable" class="userTable" matSort> -->
                    <div class="horizontal_scrollbar_div_mv">
                        <table mat-table #firstTable #priceIndexDailyTableSort="matSort"
                            [dataSource]="dataSourceForDailyTable" class="userTable horizontal_scrollbar" matSort>
                            <ng-container matColumnDef="IndexDate">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef
                                    mat-sort-header>
                                    {{'HOME.Date'| translate}}

                                </mat-header-cell>
                                <!-- <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.IndexDate}}
                            </mat-cell> -->

                                <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                    {{ getDateObject(fetchedDataForItemArray.IndexDate)['date'] }}-{{'HOME.' + this.getDateObject(fetchedDataForItemArray.IndexDate)['month'] | translate}}-{{ getDateObject(fetchedDataForItemArray.IndexDate)['year'] }}
                                </mat-cell>

                            </ng-container>
                            <ng-container matColumnDef="PriceNotation">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef
                                    mat-sort-header>
                                    {{'HOME.Notation'| translate}}

                                </mat-header-cell>
                                <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                    {{fetchedDataForItemArray.PriceNotation}}
                                </mat-cell>
                            </ng-container>
                            <!-- <ng-container matColumnDef="Source">
                            <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>
                                {{'HOME.Source'| translate}}

                            </mat-header-cell>
                            <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.Source}}
                            </mat-cell>
                        </ng-container> -->
                            <ng-container matColumnDef="UOM">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef
                                    mat-sort-header>
                                    UOM
                                </mat-header-cell>
                                <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                    {{fetchedDataForItemArray.UOM}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Rate">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef
                                    mat-sort-header>
                                    {{'HOME.Rate'| translate}}

                                </mat-header-cell>
                                <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                    {{fetchedDataForItemArray.Rate | number: '1.2-3'}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Actions">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef>
                                </mat-header-cell>
                                <th mat-header-cell *matHeaderCellDef
                                    [ngClass]="'mat-table-column-header mat-column-actions'">
                                </th>
                                <mat-cell class="mat-column-medium" *matCellDef="let element">
                                    <span (click)='editPriceIndex(element)'
                                        title=" {{'HOME.Editpriceindex'| translate}}"
                                        class='price-index-edit-icon'>
                                        <i class="far fa-edit" aria-hidden="true"></i>
                                    </span>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row class="table-header"
                                *matHeaderRowDef="displayedColumnsForDailyTable; sticky:true">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsForDailyTable;"></mat-row>
                            <!-- </mat-table> -->
                            <!-- </mat-table> -->
                        </table>
                    </div>
                    <mat-paginator #TableOnePaginator="matPaginator" [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons>
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]='currentToggleMenu !== "monthly"'>
        <div class="row pt-3 pb-5">
            <div class="col-sm">
                <div [hidden]="(!isRequestCompleted || (dataSourceForMonthlyTable.data && dataSourceForMonthlyTable.data.length))"
                    class="no-records-message pt-3 pl-2"> {{'HOME.Norecordsfound'| translate}}
                </div>
                <div [hidden]="(!isRequestCompleted || (!dataSourceForMonthlyTable.data || (dataSourceForMonthlyTable.data && !dataSourceForMonthlyTable.data.length)))"
                    class="price-index-monthly-table ">
                    <!-- <mat-table #table [dataSource]="dataSourceForMonthlyTable" class="userTable" matSort> -->
                    <div class="horizontal_scrollbar_div_mv">
                        <table mat-table #secondTable #priceIndexMonthlyTableSort="matSort"
                            [dataSource]="dataSourceForMonthlyTable" class="userTable horizontal_scrollbar" matSort>
                            <ng-container matColumnDef="Month">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef
                                    mat-sort-header>
                                    {{'HOME.Month'| translate}}

                                </mat-header-cell>
                                <!-- <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.Month}}
                            </mat-cell> -->

                                <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                    {{'HOME.' + fetchedDataForItemArray.Month | translate}}
                                </mat-cell>

                            </ng-container>
                            <ng-container matColumnDef="Year">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef
                                    mat-sort-header>
                                    {{'HOME.Year'| translate}}

                                </mat-header-cell>
                                <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                    {{fetchedDataForItemArray.Year}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="PriceNotation">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef
                                    mat-sort-header>
                                    {{'HOME.Notation'| translate}}

                                </mat-header-cell>
                                <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                    {{fetchedDataForItemArray.PriceNotation}}
                                </mat-cell>
                            </ng-container>
                            <!-- <ng-container matColumnDef="Source">
                            <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>
                                {{'HOME.Source'| translate}}

                            </mat-header-cell>
                            <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.Source}}
                            </mat-cell>
                        </ng-container> -->
                            <ng-container matColumnDef="UOM">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef
                                    mat-sort-header>
                                    UOM
                                </mat-header-cell>
                                <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                    {{fetchedDataForItemArray.UOM}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Rate">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef
                                    mat-sort-header>
                                    {{'HOME.Rate'| translate}}

                                </mat-header-cell>
                                <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                    {{fetchedDataForItemArray.Rate | number: '1.2-3'}}
                                </mat-cell>
                            </ng-container>

                            
                            <ng-container matColumnDef="Actions">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef>
                                </mat-header-cell>
                                <th mat-header-cell *matHeaderCellDef
                                    [ngClass]="'mat-table-column-header mat-column-actions'">
                                </th>
                                <mat-cell class="mat-column-medium" *matCellDef="let element">
                                    <span (click)='editPriceIndex(element)' title="{{'HOME.Editpriceindex'| translate}}"
                                        class='price-index-edit-icon'>
                                        <i class="far fa-edit" aria-hidden="true"></i>
                                    </span>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row class="table-header"
                                *matHeaderRowDef="displayedColumnsForMonthlyTable; sticky:true">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsForMonthlyTable;"></mat-row>
                            <!-- </mat-table> -->
                            <!-- </mat-table> -->
                        </table>
                    </div>
                    <mat-paginator #TableTwoPaginator="matPaginator"   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"  showFirstLastButtons>
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>