<div class="container-fluid" [hidden]='!(bitumeIndexData.length>0)'>
    
    <!-- Commented old code, moved download button to below div -->
    <!-- <div class="col-lg-12" *ngIf="!this.mobileQuery.matches">
        <div class="downloadHead">
            <i (click)="downloadBitumenIndexData()" class="fas fa-download downloadIcon"><span class="downloadText">{{'HOME.Download'| translate}}</span></i>
        </div>
    </div> -->
    
    <div class="col-lg-12">
        <div class='row no-gutters'>
            <div class="col-lg-1 col-xs-2 align-right year_label_div_mv ">
                <label class="font-bold font-small year_label_mv " for="productType">{{'HOME.Year'| translate}}</label>
            </div>
            <div class="col-lg-2 col-xs-6 align-left">
                <select class="selectDropdown form-control" [(ngModel)]="yearToSearch">
    
                    <option *ngFor="let year of years" [value]="year">
                        <!-- {{ year }} -->
                        {{'HOME.' + getStringWithoutSpaces(year) | translate}}
                    </option>
                </select>
            </div>
            <div class="col-lg-1 align-right">
                <button type="button" class="btn filter-button" for="filter" (click)='filterIndex(yearToSearch)'>
                    <label class="font-bold font-small" for="filter">{{'HOME.Find'| translate}}</label></button>
            </div>
            <div class="col-lg-7 align-left disclaimer">{{'HOME.bitumen-disclaimer' | translate}}</div>
            <div class="col-lg-1 align-right" *ngIf="!this.mobileQuery.matches">
                <div class="downloadHead">
                    <i (click)="downloadBitumenIndexData()" class="fas fa-download downloadIcon"><span
                            class="downloadText">{{'HOME.Download'| translate}}</span></i>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12 mt-10 ">
        <div class='row no-gutters'>
            <div class="mt-5" [hidden]="displayTable">
                {{'HOME.Norecordsfound'| translate}}
            </div>
            <div class="col-lg-12 no-gutters" [hidden]='!displayTable'>
                <!--Angular 7 Impl-->
                <mat-table #table [dataSource]="dataSource" matSort class='table-material table horizontal_scrollbar'>

                    <!-- Item Column -->
                   
                    <ng-container matColumnDef="Year">
                        <mat-header-cell  [style.display]="'none'"  class="header-font" *matHeaderCellDef >  -- {{'HOME.Year'| translate}}
                        </mat-header-cell>
                        <mat-cell [style.display]="'none'"  *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.Year}}
                        </mat-cell>
                    </ng-container>

                    <ng-container     matColumnDef="Date">
                        <!-- Sort as per year only -->
                        {{'HOME.withyearsort'| translate}}<mat-header-cell  class="header-font" *matHeaderCellDef mat-sort-header="Year"> {{'HOME.Month'| translate}}
                            - {{'HOME.Year'| translate}}
                            <!-- <mat-header-cell  class="header-font" *matHeaderCellDef > Month-Year -->
                            </mat-header-cell>
                            <!-- <mat-cell   *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.month}} {{fetchedDataForItemArray.Year}}
                            </mat-cell> -->

                            <mat-cell *matCellDef="let fetchedDataForItemArray">
                                {{'HOME.' + fetchedDataForItemArray.month | translate}} {{fetchedDataForItemArray.Year}}
                            </mat-cell>

                        </ng-container>
                        <ng-container matColumnDef="month">
                                <mat-header-cell  [style.display]="'none'" class="header-font" *matHeaderCellDef > {{'HOME.Month'| translate}}
                                    - {{'HOME.Year'| translate}}
                                </mat-header-cell>
                                <mat-cell  [style.display]="'none'" *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.month}}
                                      
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Index_2020">
                                <mat-header-cell class="header-font" *matHeaderCellDef>  {{'HOME.BitumenIndex'| translate}}
                                    (2020=100)
                                </mat-header-cell>
                                <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.Index_2020}}
                                </mat-cell>
                            </ng-container>
                    <ng-container matColumnDef="Index_2015">
                        <mat-header-cell class="header-font" *matHeaderCellDef>  {{'HOME.BitumenIndex'| translate}}
                            (2015=100)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.Index_2015}}
                        </mat-cell>
                    </ng-container>

                   
                    <ng-container matColumnDef="Index_2010">
                        <mat-header-cell class="header-font" *matHeaderCellDef>{{'HOME.BitumenIndex'| translate}} (2010=100)

                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.Index_2010}}
                        </mat-cell>
                    </ng-container>

                   
                    <ng-container matColumnDef="Index_2005">
                        <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.BitumenIndex'| translate}} (2005=100)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.Index_2005}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Index_2000">
                        <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.BitumenIndex'| translate}} (2000=100)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.Index_2000}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Index_1995">
                        <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.BitumenIndex'| translate}} (1995=100)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.Index_1995}}
                        </mat-cell>
                    </ng-container>

                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns;sticky: true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <mat-paginator   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"   showFirstLastButtons></mat-paginator>
                <!-- <div class="row buttonGroup">
                    <button class=" downloadButton" type="submit" (click)="downloadBitumenIndexData()">{{'HOME.Download'| translate}}</button>
                </div> -->
            </div>
        </div>
    </div>
     <!-- Option for table content scroll <div class="row buttonGroup">
                    <button class=" downloadButton" type="submit" (click)="downloadBitumenIndexData()">Download</button>
                </div> -->
</div>
<div class="container-fluid sidepadding-none" [hidden]='(bitumeIndexData.length>0)'>
    {{'HOME.DNA'| translate}}
</div>