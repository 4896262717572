import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PriceDataService {

    apiUrl = environment.apiUrl;
    constructor(private httpClient: HttpClient) {
    }
    getLineChartExhangeData(Type: string, Year: any) {
        let url = this.apiUrl + '/GetPriceIndexAndConversionRate';
        return this.httpClient
            .get(url, {
                params: {
                    Type: Type,
                    Year: Year
                }
            });
    }
}
