<div class="container-fluid no-padding-margin">
    <div class="row">
        <div class="col-sm">
            <div>
                <mat-dialog-content class="message-body">
                    <div class="text-center pt-3">
                        <span class="exclamation-icon">
                            <i class="fas fa-exclamation"></i>
                        </span>
                    </div>
                    <!-- <div class="top-border"> -->
                    <!-- <div class="message-text message-border pl-2" [ngClass]="{ 'pb-2': !message.linkedMessage }"
                        *ngFor="let message of messages">
                        <div *ngIf="!message.hasChild">
                            <span [hidden]="message.type !== 'warning'" class="warning-icon pr-1"><i
                                    class="fas fa-exclamation-triangle"></i></span>
                            <span class="messageFont" [ngClass]="{ 'pl-4': message.type === '' }"> {{ message.text
                                }}</span>
                        </div>
                        <div *ngIf="message.hasChild">
                            <div *ngFor="let childMessage of message.text; first as isFirst">
                                <span [hidden]="!isFirst || message.type !== 'warning'" class="warning-icon pr-1"><i
                                        class="fas fa-exclamation-triangle"></i></span>
                                <span class="messageFont" [ngClass]="{ 'pl-4': !isFirst }"> {{ childMessage
                                    }}</span>
                            </div>
                        </div>
                    </div> -->

                    <div class="message-text message-border pl-2" *ngFor="let message of messages">
                        <div>
                            <!-- <span class="warning-icon pr-1"><i class="fas fa-exclamation-triangle"></i></span> -->
                            <span class="messageFont boldFont"> {{ message }}</span>
                        </div>
                    </div>

                    <div *ngIf="!hideOkButton" class="message-text pl-2"  [ngClass]="{ 'pt-4': (messages && messages.length), 'pt-2': !(messages && messages.length) }">
                        <!-- <span class="question-icon pr-1"><i class="fas fa-question-circle"></i></span> -->
                        <span class="messageFont boldFont">{{ footerMessage }}</span>
                    </div>
                    <!-- </div> -->
                </mat-dialog-content>
                <mat-dialog-actions>
                    <div class="col-sm text-center pt-2">
                        <button *ngIf="!hideOkButton" class="btn btn-sm btn-primary dialog-footer-button ok-button"
                            (click)="onConfirmClick()">{{'HOME.Ok'| translate}}</button>
                        <button class="btn btn-sm btn-primary dialog-footer-button cancel-button ml-2"
                            (click)="onCancelClick()" mat-dialog-close tabindex="-1">{{'HOME.Cancel'|
                            translate}}</button>
                        <!-- <span class="pl-2">
                            <button class="btn btn-sm btn-primary dialog-footer-button cancel-button"
                                (click)="onCancelClick()" mat-dialog-close tabindex="-1">{{'HOME.Cancel'|
                                translate}}</button>
                        </span> -->
                    </div>
                </mat-dialog-actions>
            </div>
        </div>
    </div>
</div>