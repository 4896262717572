import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { CommonService } from '../order-management/order-management/order-management.component.service';
import { AppService } from '../shared-modules/services/shared.service';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { DashboardNav } from './dashboard-nav';
import { NavItem } from './nav-items';
import { NavService } from './nav-service';
import { AuthService } from '../guards/auth.service';
// Commented code for session management, kept for future reference
// import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.css']
})
export class DashboardNavComponent implements AfterViewInit, OnInit, OnDestroy {  
  public pageTitle: string = "";
  countrySearch: any;
  countryList: any;
  imageUrl: string;
  selectedLanguage: string = 'FI';
  @ViewChild('appDrawer', { static: true }) appDrawer: ElementRef;
  selectedItem: string = 'Home';
  navbarOpen = false;
  userInfo: any;
  name: String;
  emailId: string;
  isMobileDevice: boolean;




  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

 
  pageHeading1: any;
  pageHeading2: any;
  pageHeading3: any;
  pageHeading4: any;
  pageHeading5: any;
  pageHeading6: any;
  pageHeading7: any;
  pageHeading8: any;
  pageHeading9: any;
  pageHeading10: any;
  pageHeading11: any;
  pageHeading12: any;
  pageHeading13: any;
  pageHeading14:any;
  pageHeading15:any;
  pageHeading16:any;
  pageHeading17:any;
  pageHeading18:any;
  pageHeading19:any;
  pageHeading20:any;
  pageHeading21:any;
  pageHeading22:any;
  pageHeading23:any;
  pageHeading24:any;
  pageHeading25:any;
  pageHeading26:any;
  pageHeading27:any;
  pageHeading28:any;
  
  

  pageHeading30: any;
  pageHeading31: any;
  pageHeading32:any;
  pageHeading33:any;
  pageHeading34: any;
  pageHeading35: any;
  pageHeading36: any;
  pageHeading37: any;
  pageHeading39: any;
  pageHeading40: any;
  pageHeading41: any;
  pageHeading44: any;
  pageHeading45: any;
  pageHeading46: any;
  pageHeading49: any;
  pageHeading47: any;
  pageHeading50: any;
  pageHeading54: any;
  pageHeading55: any;
  pageHeading56: any;
  pageHeading57: any;
  pageHeading58: any;
  pageHeading60: any;
 
  displayName2: any;
  displayName3: any;
  displayName4: any;
  displayName7: any;
  displayName10: any;
  displayName14: any;
  displayName18: any;
  displayName25: any;
  displayName26: any;
  displayName27: any;
  displayName30: any;
  displayName31: any;
  displayName32: any;
  displayName33: any;
  displayName34: any;
  displayName35: any;
  displayName36: any;
  displayName37: any;
  displayName38: any;
  displayName39: any;
  displayName40: any;
  displayName41: any;
  displayName44: any;
  displayName45: any;
  displayName46: any;
  displayName47: any;
  displayName50: any;
  displayName51: any;
  displayName52: any;
  displayName53: any;
  displayName54: any;
  displayName55: any;
  displayName56: any;
  displayName57: any;
  displayName58: any;
  displayName60: any;

  menuAccessList: string[] = [];
  navItems: NavItem[] = [];
  isLoggedInUserPortalAdmin: boolean;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public displayedColumns = ['Title', 'Date', 'View'];
  isLoggedInUserAdmin: boolean;
  public dataSource = new MatTableDataSource<DashboardNav>();
  mobileQuery: MediaQueryList;
  defaultSelectedLanguageCode: string = "FI";
  countryCode: string;
  userId: string;
  customerId: string;

  private _mobileQueryListener: () => void;
  EN: string = 'EN';
  FI: string = 'FI';
  SE: string = 'SE';
  NR: string = 'NR';
  DA: string = 'DA';

 
  constructor(private navService: NavService, public router: Router,private ccService: NgcCookieConsentService, private sharedService: AppService, private dialog: MatDialog, private commonService: CommonService, public translate: TranslateService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher
    
    // Commented code for session management, kept for future reference
    // , private bnIdle: BnNgIdleService
    ,private accessId:AuthService
    ) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.imageUrl = this.userInfo ? (this.userInfo.ProfilePhoto ? ('data:image/png;base64,' + this.userInfo.ProfilePhoto) : null) : '';
    this.name = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.isLoggedInUserPortalAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA") : false);
    this.getAllowedMenuIdList(this.userInfo ? this.userInfo.Menus : []);
    this.countryCode = (this.userInfo ? this.userInfo.CountryCode : '');
    this.userId = this.userInfo ? this.userInfo.UserID : '';
    this.customerId = this.userInfo ? this.userInfo.CustomerID : '';
    //this.defaultSelectedLanguageCode = (this.countryCode === "FIN") ? "FI" : "EN";

    // Set language code as per logged in user's country
    if (this.countryCode === "FIN") {
      this.defaultSelectedLanguageCode = "FI"
    } else if (this.countryCode === "SWE") {
      this.defaultSelectedLanguageCode = "SE"
    } else if (this.countryCode === "NOR") {
      this.defaultSelectedLanguageCode = "NR"
    } else if (this.countryCode === "GBR") {
      this.defaultSelectedLanguageCode = "EN"
    } else if (this.countryCode === "DNK") {
      this.defaultSelectedLanguageCode = "DA"
    } else {
      this.defaultSelectedLanguageCode = "EN"
    }

    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as {
        defaultSelectedLanguageCode: string,
      };
      if (state && state.defaultSelectedLanguageCode) {
        this.defaultSelectedLanguageCode = state.defaultSelectedLanguageCode;
      }
    }
    translate.addLangs(['EN', 'FI','SE','NR', 'DA']);
    translate.setDefaultLang('EN');
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/EN|FI/||/SE|NR/) ? browserLang : this.defaultSelectedLanguageCode);
    // translate.use(browserLang.match(/SE|NR/) ? browserLang : this.defaultSelectedLanguageCode);
    this.selectedLanguage = this.defaultSelectedLanguageCode;
    this.translate.use(this.defaultSelectedLanguageCode);
    //console.log('Menu List',this.menuAccessList);
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // Translate cookie bar to the selected language
    if (!this.sharedService.getCookie()) {
      this.translateCookieBar();
    }
  }
 
 
  ngOnInit() {




    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {

        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });




    
    this.navService.appDrawer = this.appDrawer;
    this.countryList = { fi: 'Finnish', uk: 'English' };
    this.countrySearch = 'fi';
    this.imageUrl = this.userInfo ? (this.userInfo.ProfilePhoto ? ('data:image/png;base64,' + this.userInfo.ProfilePhoto) : null) : '';
    this.emailId = this.userInfo ? this.userInfo.UserID : '';
    this.isMobileDevice = this.checkIfMobileDevice();
    this.pageHeading1 = 'Home';
    this.navItems = [
      {
        menuId: '',
        displayName1: 'Home',
        iconName: 'fa fa-home',
        route: 'app/home',
        disabled: false
      },
 
      {
        menuId: '2',
        displayName2: 'Order management',
        iconName: 'fa fas fa-calendar-alt',
        route: 'app/order-management',
        disabled: !(this.validateMenu('2')),  // 2 - Order management
        children: [
          {
            menuId: '6',
            displayName3: 'Forecast entry',
            iconName: 'fa fa-pencil-square-o',
            route: 'app/order-management/forecast-entry',
            // disabled: !this.validateMenu('6'),
            disabled: !(this.validateMenu('2') && (this.countryCode !== 'GBR' && this.countryCode !== 'DNK') && ((this.countryCode !== 'SWE' ) || (this.validateMenu('45') || this.validateMenu('46'))))
            //disabled: !(this.validateMenu('2') && (this.countryCode !== 'SWE' || (this.validateMenu('45') || this.validateMenu('46')))),  // 45 - Rolling 4 week, 46 - Monthly forecast entry
          },
          {
            menuId: '7',
            displayName4: 'Forecast history',
            iconName: 'fas fa-history',
            route: 'app/order-management/history',
            // disabled: !this.validateMenu('7'),
            disabled: (!(this.validateMenu('2') && this.countryCode !== 'GBR' && this.countryCode !== 'DNK' && this.isLoggedInUserAdmin)), // Show Forecast history menu for portal admin and customer admin
          },
          {
            menuId: '8',
            displayName5: 'Forecast status ',
            iconName: 'fas fa-user-clock',
            route: 'app/order-management/pending-forecast',
            // disabled: !this.validateMenu('8'),
            // disabled: !(this.validateMenu('2')),
            disabled: !(this.validateMenu('2') && (this.countryCode !== 'GBR' && this.countryCode !== 'DNK') && ((this.countryCode !== 'SWE' ) || (this.validateMenu('48'))))
            //disabled: !(this.validateMenu('2') && (this.countryCode !== 'SWE' || (this.validateMenu('48')))),  // 48 - Forecast status
          },
          {
            menuId: '51',
            displayName51: 'Order entry',
            iconName: 'fas fa-file-signature',
            route: 'app/order-management/order-entry',
            // disabled: !(this.validateMenu('51') && this.countryCode === 'GBR'  && !this.isLoggedInUserPortalAdmin),
            disabled: !(this.validateMenu('51') && this.countryCode === 'GBR'  ),
           
          },
          {
            menuId: '52',
            displayName52: 'Order tracking',
            iconName: 'fas fa-route', 
            route: 'app/order-management/order-tracking',
            disabled: !(this.validateMenu('52') && this.countryCode === 'GBR'),
              
          },
          {
            menuId: '22',
            displayName6: 'Ordering procedure',
            iconName: 'fa fa-pencil-square-o',
            route: 'app/order-management/ordering-procedure',
            // disabled: !this.validateMenu('22'),
            disabled: !(this.validateMenu('2') && this.countryCode !== 'GBR'),
          },
          {
            menuId: '',
            displayName56: 'Unlock order',
            iconName: 'fas fa-unlock',
            route: 'app/order-management/unlock-order',
            disabled: !(this.validateMenu('2') && this.countryCode === 'GBR' && this.isLoggedInUserPortalAdmin),
          }]
      },
 
      {
        menuId: '3',
        displayName8: 'Product catalogue',
        iconName: 'fab fa-product-hunt',
        route: 'app/product-management',

        // Commented old code, kept for future reference
        // disabled: !(this.validateMenu('3') && (this.isLoggedInUserPortalAdmin || this.countryCode !== 'SWE')),
        
        disabled: !(this.validateMenu('3')),  // 3 - Product information

        children: [
          {
            menuId: '',
            displayName7: 'Product information',
            iconName: 'fa fa-info-circle',
            route: 'app/product-management/product-information',
            // disabled: !(this.validateMenu('3') && (this.isLoggedInUserPortalAdmin || !(this.countryCode === 'SWE' || this.countryCode === 'NOR'))),
            disabled: true
          },
          {
            menuId: '',
            displayName54: 'Product documentation',
            iconName: 'fa fa-info-circle',
            route: 'app/product-management/product-documentation',

            // Commented old code, kept for future reference
            // disabled: !(this.validateMenu('3') && (this.isLoggedInUserPortalAdmin || !(this.countryCode === 'SWE' || this.countryCode === 'NOR'))),
            
            disabled: !(this.validateMenu('3')),  // 3 - Product information

          },
          {
            menuId: '14',
            displayName9: 'Delivery guide',
            iconName: 'fas fa-truck',
            route: 'app/product-management/delivery-guide',
            disabled: !(this.validateMenu('3') && this.countryCode !== 'SWE' && this.countryCode !== 'DNK'),
          }]
      },
      {
        menuId: '4',
        displayName10: 'Price & Index',
        iconName: 'far fa-chart-bar',
        route: 'app/price-management',
        disabled: !(this.validateMenu('4')),
        children: [
          {
            menuId: '15',
            displayName11: 'Product prices',
            iconName: 'fab  fa-product-hunt',
            route: 'app/price-management/price-data',
            // disabled: !this.validateMenu('15'),
            disabled: !(this.validateMenu('4')),
          },
          {
            menuId: '16',
            displayName12: 'Price notation',
            iconName: 'fa fa-line-chart',
            route: 'app/price-management/price-authors',
            // disabled: !this.validateMenu('16'),
            disabled: !(this.validateMenu('4')),
          },
          {
            menuId: '17',
            displayName13: 'Bitumen index',
            iconName: 'fa fa-bold',
            route: 'app/price-management/bitumen-index',
            // disabled: !this.validateMenu('17'),
            disabled: !(this.validateMenu('4')),
          }
        ]
      },
 
 
      {
        menuId: '41',
        displayName14: 'My company',
        iconName: 'fa fa-building',
        route: 'app/my-company',
        disabled: !(this.validateMenu('41')),
        children: [
          {
            menuId: '',
            displayName15: 'Company details',
            iconName: 'fas fa-warehouse',
            route: 'app/my-company/company-details',
            disabled: !(this.validateMenu('41')),
          },
          {
            menuId: '10',
            displayName16: 'Add delivery site',
            iconName: 'fa fa-file-text',
            route: 'app/my-company/add-delivery-site',

            // Hide old add delivery site page
            // disabled: !(this.validateMenu('41') && this.validateMenu('10') && this.countryCode === 'FIN'),  // 10 - Add delivery site
            disabled: true
          },

          {
            menuId: '10',
            displayName40: 'Add delivery site',
            iconName: 'fa fa-file-text',
            route: 'app/my-company/add-delivery-site-sweden',

            // Hide old add delivery site page
            // disabled: !(this.validateMenu('41') && this.validateMenu('10') && this.countryCode !== 'FIN'),  // 10 - Add delivery site
            disabled: true
          },
          {
            menuId: '11',
            displayName17: 'Modify delivery site',
            iconName: 'fa fa-pencil-square-o',
            route: 'app/my-company/modify-delivery-site',
            disabled: !(this.validateMenu('41') && this.validateMenu('11') && this.countryCode === 'FIN'),  // 11 - Modify delivery site
          },
          {
            menuId: '11',
            displayName17: 'Modify delivery site',
            iconName: 'fa fa-pencil-square-o',
            route: 'app/my-company/modify-delivery-site-sweden',
            disabled: !(this.validateMenu('41') && this.validateMenu('11') && this.countryCode !== 'FIN'),  // 11 - Modify delivery site
          },
          {
            menuId: '10',
            displayName40: 'Add delivery site',
            iconName: 'fa fa-file-text',
            route: 'app/my-company/add-edit-delivery-site',
            disabled: !(this.validateMenu('41') && this.validateMenu('10')),  // 10 - Add delivery site
          }
        
        ]
      },
 
 
      {
        menuId: '5',
        displayName18: 'Reports',
        iconName: 'fas fa-chart-pie',
        route: 'app/reports',
        // disabled: !(this.validateMenu('5')),
        disabled: !(this.validateMenu('5')),
        children: [
          // {
          //   menuId: '24',
          //   displayName19: 'Forecast v/s Actual',
          //   iconName: 'fa fa-line-chart',
          //   route: 'app/reports/forecastvsactual',
          //   // disabled: !this.validateMenu('24'),
          // },

          /* {
            menuId: '24',
            displayName19: 'Forecast v/s Actual',
            iconName: 'fa fa-line-chart',
            route: 'app/reports/forecastvsactualfinland',
            disabled: !(this.countryCode === 'FIN'),
          }, */
          {
            menuId: '',
            displayName46: 'Monthly reports',
            iconName: 'fa fa-line-chart',
            route: 'app/reports/monthly-reports',
            // disabled: false,
            disabled: !(this.validateMenu('5') && (!(this.countryCode === 'SWE' || this.countryCode === 'GBR' || this.countryCode === 'DNK'))  || this.validateMenu('44')),  // 44 - Monthly reports
          },
          {
            menuId: '',
            displayName53: 'Contractual volume reports',
            iconName: 'fa fa-line-chart',
            route: 'app/reports/contractual-volume-reports',
            disabled: true
          },
          {
            menuId: '',
            displayName47: 'Weekly reports',
            iconName: 'fa fa-line-chart',
            route: 'app/reports/weekly-reports',
            // disabled: false,
            disabled: !(this.validateMenu('5') && (!(this.countryCode === 'SWE' || this.countryCode === 'GBR' || this.countryCode === 'DNK'))  || this.validateMenu('43')),  // 43 - Weekly reports
          }, 
          {
            menuId: '50',
            displayName50: 'Daily reports',
            iconName: 'fa fa-line-chart',
            route: 'app/reports/daily-reports',
            // disabled: !(this.validateMenu('5') && this.validateMenu('50')),  // 50 - Daily sales report
            disabled: true
          },
          /* {
            menuId: '24',
            displayName42: 'Weekly reports (old)',
            iconName: 'fa fa-line-chart',
            route: 'app/reports/forecastvsactualweekly',
            disabled: false,
          }, */ 
          {
            menuId: '49',
            displayName44: 'Sales Reports',
            iconName: 'fa fa-truck',
            route: 'app/reports/salesreport',
            // disabled: !(this.countryCode === 'FIN'),
            // disabled: false,

            // Hide Sales Reports page, show only Shipped deliveries page with tab of Daily sales reports on it
            // disabled: !(this.isLoggedInUserPortalAdmin && (this.validateMenu('5') && (this.countryCode !== 'SWE' || this.validateMenu('47')) && !this.mobileQuery.matches)),  // 47 - Shipped deliveries (For Nynas admin role)
            disabled: true
          },
          {
            menuId: '49',
            displayName44: 'Shipped deliveries',
            iconName: 'fa fa-truck',
            route: 'app/reports/shipped-deliveries',
            // disabled: !(this.countryCode === 'FIN'),
            // disabled: false,

            // Commented below code, kept for future reference, show Daily reports page for portal admin and customer admin roles only, for customer user role, show only Shipped deliveries
            // disabled: !((this.validateMenu('5') && (this.countryCode !== 'SWE' || this.validateMenu('50')) && !this.mobileQuery.matches)),

            // disabled: !(this.validateMenu('5') && ((this.validateMenu('47')) || (this.validateMenu('50')))),  // 47 - Shipped deliveries, 50 - Daily reports

            disabled: !(this.validateMenu('5') && this.validateMenu('50')),  // 50 - Daily reports, hide Shipped deliveries tab for all users

          },
          {
            menuId: '60',
            displayName60: 'Delivery analysis',
            iconName: 'fa fa-truck',
            route: 'app/reports/delivery-analysis',
            //disabled: (!(this.countryCode !== 'GBR' && this.isLoggedInUserPortalAdmin)),  // Display page for Portal admins only
           disabled: (!((this.countryCode == 'SWE' || this.countryCode == 'NOR' || this.countryCode == 'FIN' ) && (this.validateMenu('5') && this.validateMenu('53')))), // 53- delivery analysis for NOR and SWE countries menu item enabled users and admin only

             //disabled: false,

            // Commented below code, kept for future reference, show Daily reports page for portal admin and customer admin roles only, for customer user role, show only Shipped deliveries
            // disabled: !((this.validateMenu('5') && (this.countryCode !== 'SWE' || this.validateMenu('50')) && !this.mobileQuery.matches)),
 
            // disabled: !(this.validateMenu('5') && ((this.validateMenu('47')) || (this.validateMenu('50')))),  // 47 - Shipped deliveries, 50 - Daily reports
            // disabled: true
          },

          /* {
            menuId: '24',
            displayName19: 'Forecast v/s Actual',
            iconName: 'fa fa-line-chart',
            route: 'app/reports/forecastvsactualmonthly',
            disabled: !(this.countryCode === 'NOR'),
          } */
          
          // ,
          // {
          //   menuId:'25',
          //   displayName: 'Portal usage',
          //   iconName: 'fa fa-file-text',
          //   route: 'app/reports/portalusage',
          //   disabled: ! this.validateMenu('25'),
          // }
          // {
          //   menuId: '25',
          //   displayName: 'Forecast v/s Actual- SWD',
          //   iconName: 'fa fa-line-chart',
          //   route: 'app/reports/forecastactual-swd',
          //   // disabled: !this.validateMenu('24'),
          // }
        ]
      },
      {
        menuId: '',
        displayName20: 'User management',
        iconName: 'fa fa-user',
        route: 'app/user-management',
        // disabled: (!(this.isLoggedInUserAdmin)),
        // User management will be displayed for Sweden portal admins only, for other countries it will be displayed for portal admin and customer admin
        disabled: (!(this.isLoggedInUserPortalAdmin || (this.countryCode !== 'SWE' && this.countryCode !== 'DNK' && this.countryCode !== 'GBR' && this.isLoggedInUserAdmin))),
        children: [
 
          {
            menuId: '18',
            displayName21: 'Manage users',
            iconName: 'fas fa-user-edit',
            route: 'app/user-management/manage-users',
            // disabled: (!(this.isLoggedInUserAdmin && this.validateMenu('18'))),
            disabled: false
          },
          {
            menuId: '19',
            displayName22: 'Add user',
            iconName: 'fa fa-user-plus',
            route: 'app/user-management/add-user',
            // disabled: (!(this.isLoggedInUserAdmin && this.validateMenu('19'))),
            disabled: false
          },
          {
            menuId: '20',
            displayName23: 'Manage user group',
            iconName: 'fa fa-users',
            route: 'app/user-management/manage-user-group',
            // disabled: (!(this.isLoggedInUserAdmin && this.validateMenu('20'))),
            disabled: false
          },
          {
            menuId: '21',
            displayName24: 'Add user group',
            iconName: 'fas fa-user-friends',
            route: 'app/user-management/add-user-group',
            // disabled: (!(this.isLoggedInUserAdmin && this.validateMenu('21'))),
            disabled: false
          }
 
        ]
      },
      {
        menuId:'',
        displayName28: 'Configuration',
        iconName: 'fa fa-cog',
        route: 'app/configuration',
        disabled: (!(this.isLoggedInUserPortalAdmin)),
        children: [ 
          
          {
            menuId:'',
            displayName31: 'C 2 P mapping',
            iconName: 'fas fa-file-contract',
            route: 'app/configuration/customer-contract',
            disabled: false
          },
          {
            menuId:'',
            displayName32: 'Add price index',
            iconName: 'fas fa-money-bill-wave',
            route: 'app/configuration/add-price-index',
            disabled: false
          },
          {
            menuId:'',
            displayName41: 'Add product price',
            iconName: 'fab fa-product-hunt',
            route: 'app/configuration/add-product-price',
            disabled: false
          },
          {
            menuId:'',
            displayName33: 'Add exchange rate',
            iconName: 'fas fa-euro-sign',
            route: 'app/configuration/add-exchange-rate-admin',
            disabled: false
          },
          {
            menuId:'',
            displayName35: 'Add bitumen index',
            iconName: 'fa fa-bold',
            route: 'app/configuration/bitumen-index-admin',
            disabled: false
          },
          {
            menuId:'30',
            displayName30: 'Add delivery guide',
            iconName: 'fa fa-user-plus',
            route: 'app/configuration/delivery-guide-admin',
            disabled: (this.countryCode === 'SWE' || this.countryCode === 'DNK'),
          },
          {
            menuId:'',
            displayName36: 'Upload document',
            iconName: 'fas fa-file-upload', 
            route: 'app/configuration/upload-document',
            disabled: false
          }, 
          {
            menuId:'',
            displayName34: 'Add contact us',
            iconName: 'fas fa-comment-alt', 
            route: 'app/configuration/contact-us-admin',
            disabled: false
          },
          {
            menuId: '',
            displayName45: 'Login History',
            iconName: 'fas fa-user-clock',
            route: 'app/configuration/login-history',
            disabled: true
          },
          {
            menuId: '',
            displayName58: 'Settings',
            iconName: 'fas fa-tools',
            route: 'app/configuration/settings',
             //disabled: true
            disabled: (this.countryCode === 'SWE' || this.countryCode === 'DNK' || this.countryCode === 'FIN' || this.countryCode === 'NOR' )
          },
          {
            menuId: '',
            displayName37: 'Guest view',
            iconName: 'fas fa-street-view',
            route: 'app/configuration/guest-view',
            disabled: false
          },
          
        ]
      },
      {
        menuId: '',
        displayName25: 'Nynas updates',
        iconName: 'fa fa-envelope',
        route: 'app/nynas-updates',
        disabled: !this.validateMenu('23')
        // disabled: false,
      },
      {
        menuId: '',
        displayName26: 'My profile',
        iconName: 'fas fa-user-edit',
        route: 'app/my-profile',
        disabled: false,
      },
      {
        menuId: '',
        displayName27: 'Contact us',
        iconName: 'fas fa-comment-alt',
        route: 'app/contactus',
        disabled: false
      },
      {
        menuId: '',
        displayName55: 'Udsp mapping',
        iconName: 'fas fa-comment-alt',
        route: 'app/udsp-mapping',
        disabled: true
      },
      {
        menuId: '',
        displayName38: 'Sign out',
        iconName: 'fa fa-sign-out',
        route: '/login',
        disabled: !this.isMobileDevice
      }
    ];
    // console.log(this.navItems);

    
   

  }
  ngAfterViewInit() {
 
    this.navService.appDrawer = this.appDrawer;
    this.countryList = { fi: 'Finnish', uk: 'English' };
    this.countrySearch = 'fi';
    this.imageUrl = this.userInfo ? (this.userInfo.ProfilePhoto ? ('data:image/png;base64,' + this.userInfo.ProfilePhoto) : null) : '';
    this.emailId = this.userInfo ? this.userInfo.UserID : '';
    this.pageHeading1 = 'Home';

    // this.startCheckForIdleTime();
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    // this.resetSessionIdleTimeCounter();
  }
  getCountry(data) {
    return this.countryList
  }
 
  // updatePassword() {
  //   this.dialog.open(UpdatePasswordComponent);
  // }
 
  logout() {
    this.accessId.logout();
    this.router.navigate(["/login"]);

    // Clear previous login response from cache, when user logs out
    this.sharedService.setLoginResponse(null);

    // this.resetSessionIdleTimeCounter();
    
    // Close all open pop ups on log out
    this.dialog.closeAll();
  }
 
  updateLanguage(selectedLanguage: string) {

    this.selectedLanguage = selectedLanguage;

    // set selectedLanguage in shared service
    this.sharedService.setSelectedLanguage(selectedLanguage);

    this.translate.use(selectedLanguage);
    
    if (!this.sharedService.getCookie()) {
      this.translateCookieBar();

      // Moved to method - translateCookieBar
      /* this.translate .get(['HOME.header', 'HOME.messagecookie', 'HOME.dismiss', 'HOME.allow', 'HOME.deny', 'HOME.link', 'HOME.policy'])
      .subscribe(data => {
  
        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // Override default messages with the translated ones
         this.ccService.getConfig().content.header = data['HOME.header'];
        this.ccService.getConfig().content.messagecookie = data['HOME.messagecookie'];
        this.ccService.getConfig().content.dismiss = data['HOME.dismiss'];
        this.ccService.getConfig().content.allow = data['HOME.allow'];
        this.ccService.getConfig().content.deny = data['HOME.deny'];
        this.ccService.getConfig().content.link = data['HOME.link'];
        this.ccService.getConfig().content.policy = data['HOME.policy'];
  
        this.ccService.destroy();//remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      }); */

    }

    // Reload page on language change
    let currentUrl = this.router.url, urlsToSkip = ['add-user', 'my-profile'];
    if (currentUrl && !urlsToSkip.some(url => currentUrl.includes(url))) {
      const navigationExtras: NavigationExtras = {
        state: {
          defaultSelectedLanguageCode: selectedLanguage,
          // emailId: this.userId,
          // isMyProfile: true,
          // customerId: this.customerId
        }
      };
      this.router.navigateByUrl('/').then(() => this.router.navigate([currentUrl], navigationExtras));
    }
  }
 
  editUser() {
    const navigationExtras: NavigationExtras = {
      state: {
        emailId: this.emailId,
        isMyProfile: true
      }
    };
    this.router.navigate(["/user-management/add-user"], navigationExtras);
  }
 
  displayRoute(routeHeading: any, item: any) {
 
    // console.log('item  ' + item);
 
    let currentPageHeadingKey;
    let currentPageHeadingValue;
 
 
   /*  if(item){
 
      currentPageHeadingKey = this.getDisplayNameKey(item);
      currentPageHeadingValue = this.getDisplayNameValue(item);  
      console.log('item  ' + item);
 
 
    } */
    if (item.children && item.children.length) {
      if (this.getDisplayNameValue(item) === routeHeading) {
        currentPageHeadingKey = this.getDisplayNameKey(item);
        currentPageHeadingValue = this.getDisplayNameValue(item);
      } else {
        for (let prop of item.children) {
          if (prop && this.getDisplayNameValue(prop) === routeHeading) {
            currentPageHeadingKey = this.getDisplayNameKey(prop);
            currentPageHeadingValue = this.getDisplayNameValue(prop);
            break;
          }
        }
 
      }
      // for (let prop in item.children) {
      //   if (prop && this.getDisplayNameValue(prop) === routeHeading) {
      //     currentPageHeadingKey = this.getDisplayNameKey(prop);
      //     currentPageHeadingValue = this.getDisplayNameValue(prop);
      //     break;
      //   }
      // }
 
 
    } else {
      currentPageHeadingKey = this.getDisplayNameKey(item);
      currentPageHeadingValue = this.getDisplayNameValue(item); 
    } 
 
 
    this.pageHeading1 = routeHeading;
    this.pageHeading2 = routeHeading;
    this.pageHeading3 = routeHeading;
    this.pageHeading4 = routeHeading;
    this.pageHeading5 = routeHeading;
    this.pageHeading6 = routeHeading;
    this.pageHeading7 = routeHeading;
    this.pageHeading8 = routeHeading;
    this.pageHeading9 = routeHeading;
    this.pageHeading10 = routeHeading;
    this.pageHeading11 = routeHeading;
    this.pageHeading12 = routeHeading;
    this.pageHeading13 = routeHeading;
    this.pageHeading14 = routeHeading;
    this.pageHeading15 = routeHeading;
    this.pageHeading16 = routeHeading;
    this.pageHeading17 = routeHeading;
    this.pageHeading18 = routeHeading;
    this.pageHeading19 = routeHeading;
    this.pageHeading20 = routeHeading;
    this.pageHeading21 = routeHeading;
    this.pageHeading22 = routeHeading;
    this.pageHeading23 = routeHeading;
    this.pageHeading24 = routeHeading;
    this.pageHeading25 = routeHeading;
    this.pageHeading26 = routeHeading;
    this.pageHeading27 = routeHeading;
    this.pageHeading28 = routeHeading;
    this.pageHeading30 = routeHeading;
    this.pageHeading31 = routeHeading;
    this.pageHeading32 = routeHeading;
    this.pageHeading33 = routeHeading;
    this.pageHeading34 = routeHeading;
    this.pageHeading35 = routeHeading;
    this.pageHeading36 = routeHeading;
    this.pageHeading37 = routeHeading;
    this.pageHeading39 = routeHeading;
    this.pageHeading40 = routeHeading;
    this.pageHeading41 = routeHeading;
    this.pageHeading44 = routeHeading;
    this.pageHeading45 = routeHeading;
    this.pageHeading46 = routeHeading;
    //this.pageHeading47 = routeHeading;
    this.pageHeading49 = routeHeading;
    this.pageHeading47 = routeHeading;
    this.pageHeading50 = routeHeading;
    this.pageHeading54 = routeHeading;
    this.pageHeading55 = routeHeading;
    this.pageHeading56 = routeHeading;
    this.pageHeading57 = routeHeading;
    this.pageHeading58 = routeHeading;
    this.pageHeading60 = routeHeading;

    this['currentPageHeadingKey'] = currentPageHeadingValue;
 
    if (currentPageHeadingKey === 'displayName1') {
      this.pageHeading1 = currentPageHeadingValue;
    } else {
      this.pageHeading1 = '';
    }
    if (currentPageHeadingKey === 'displayName2') {
      this.pageHeading2 = currentPageHeadingValue;
    } else {
      this.pageHeading2 = '';
    }
    if (currentPageHeadingKey === 'displayName3') {
      this.pageHeading3 = currentPageHeadingValue;
    } else {
      this.pageHeading3 = '';
    }
    if (currentPageHeadingKey === 'displayName4') {
      this.pageHeading4 = currentPageHeadingValue;
    } else {
      this.pageHeading4 = '';
    }
    if (currentPageHeadingKey === 'displayName5') {
      this.pageHeading5 = currentPageHeadingValue;
    } else {
      this.pageHeading5 = '';
    }
    if (currentPageHeadingKey === 'displayName6') {
      this.pageHeading6 = currentPageHeadingValue;
    } else {
      this.pageHeading6 = '';
    }
    if (currentPageHeadingKey === 'displayName10') {
      this.pageHeading10 = currentPageHeadingValue;
    } else {
      this.pageHeading10 = '';
    }
    if (currentPageHeadingKey === 'displayName14') {
      this.pageHeading14 = currentPageHeadingValue;
    } else {
      this.pageHeading14 = '';
    }
 
 
    if (currentPageHeadingKey === 'displayName7') {
      this.pageHeading7 = currentPageHeadingValue;
    } else {
      this.pageHeading7 = '';
    }
    if (currentPageHeadingKey === 'displayName8') {
      this.pageHeading8 = currentPageHeadingValue;
    } else {
      this.pageHeading8 = '';
    }
    if (currentPageHeadingKey === 'displayName9') {
      this.pageHeading9= currentPageHeadingValue;
    } else {
      this.pageHeading9 = '';
    }
    if (currentPageHeadingKey === 'displayName11') {
      this.pageHeading11 = currentPageHeadingValue;
    } else {
      this.pageHeading11 = '';
    }
    if (currentPageHeadingKey === 'displayName12') {
      this.pageHeading12 = currentPageHeadingValue;
    } else {
      this.pageHeading12 = '';
    }
    if (currentPageHeadingKey === 'displayName13') {
      this.pageHeading13 = currentPageHeadingValue;
    } else {
      this.pageHeading13 = '';
    }
    if (currentPageHeadingKey === 'displayName15') {
      this.pageHeading15 = currentPageHeadingValue;
    } else {
      this.pageHeading15 = '';
    }
    if (currentPageHeadingKey === 'displayName16') {
      this.pageHeading16 = currentPageHeadingValue;
    } else {
      this.pageHeading16 = '';
    }
    if (currentPageHeadingKey === 'displayName17') {
      this.pageHeading17 = currentPageHeadingValue;
    } else {
      this.pageHeading17 = '';
    }
    if (currentPageHeadingKey === 'displayName19') {
      this.pageHeading19 = currentPageHeadingValue;
    } else {
      this.pageHeading19 = '';
    }
    if (currentPageHeadingKey === 'displayName20') {
      this.pageHeading20 = currentPageHeadingValue;
    } else {
      this.pageHeading20 = '';
    } if (currentPageHeadingKey === 'displayName21') {
      this.pageHeading21 = currentPageHeadingValue;
    } else {
      this.pageHeading21 = '';
    }
     if (currentPageHeadingKey === 'displayName22') {
      this.pageHeading22 = currentPageHeadingValue;
    } else {
      this.pageHeading22 = '';
    }
    if (currentPageHeadingKey === 'displayName23') {
      this.pageHeading23 = currentPageHeadingValue;
    } else {
      this.pageHeading23 = '';
    }
    if (currentPageHeadingKey === 'displayName24') {
      this.pageHeading24 = currentPageHeadingValue;
    } else {
      this.pageHeading24 = '';
    }
 
 
 
 
    if (currentPageHeadingKey === 'displayName18') {
      this.pageHeading18 = currentPageHeadingValue;
    } else {
      this.pageHeading18 = '';
    }
 
    if (currentPageHeadingKey === 'displayName25') {
      this.pageHeading25 = currentPageHeadingValue;
    } else {
      this.pageHeading25 = '';
    }
 
    if (currentPageHeadingKey === 'displayName26') {
      this.pageHeading26 = currentPageHeadingValue;
    } else {
      this.pageHeading26= '';
    }
 
    if (currentPageHeadingKey === 'displayName27') {
      this.pageHeading27 = currentPageHeadingValue;
    } else {
      this.pageHeading27 = '';
    }


    if (currentPageHeadingKey === 'displayName28') {
      this.pageHeading28 = currentPageHeadingValue;
    } else {
      this.pageHeading28 = '';
    }
    if (currentPageHeadingKey === 'displayName34') {
      this.pageHeading34 = currentPageHeadingValue;
    } else {
      this.pageHeading34 = '';
    }
    if (currentPageHeadingKey === 'displayName30') {
      this.pageHeading30 = currentPageHeadingValue;
    } else {
      this.pageHeading30 = '';
    }
    if (currentPageHeadingKey === 'displayName31') {
      this.pageHeading31 = currentPageHeadingValue;
    } else {
      this.pageHeading31 = '';
    }
    if (currentPageHeadingKey === 'displayName32') {
      this.pageHeading32 = currentPageHeadingValue;
    } else {
      this.pageHeading32 = '';
    }
    if (currentPageHeadingKey === 'displayName33') {
      this.pageHeading33 = currentPageHeadingValue;
    } else {
      this.pageHeading33 = '';
    }
    if (currentPageHeadingKey === 'displayName35') {
      this.pageHeading35 = currentPageHeadingValue;
    } else {
      this.pageHeading35 = '';
    }
    if (currentPageHeadingKey === 'displayName36') {
      this.pageHeading36 = currentPageHeadingValue;
    } else {
      this.pageHeading36 = '';
    }
    if (currentPageHeadingKey === 'displayName37') {
      this.pageHeading37 = currentPageHeadingValue;
    } else {
      this.pageHeading37 = '';
    }
    if (currentPageHeadingKey === 'displayName39') {
      this.pageHeading39 = currentPageHeadingValue;
    } else {
      this.pageHeading39 = '';
    }
      
    if (currentPageHeadingKey === 'displayName40') {
      this.pageHeading40 = currentPageHeadingValue;
    } else {
      this.pageHeading40 = '';
    }    
    if (currentPageHeadingKey === 'displayName41') {
      this.pageHeading41 = currentPageHeadingValue;
    } else {
      this.pageHeading41 = '';
    }
    if (currentPageHeadingKey === 'displayName42') {
      this.pageHeading41 = currentPageHeadingValue;
    } else {
      this.pageHeading41 = '';
    }
    if (currentPageHeadingKey === 'displayName43') {
      this.pageHeading41 = currentPageHeadingValue;
    } else {
      this.pageHeading41 = '';
    }
    if (currentPageHeadingKey === 'displayName44') {
      this.pageHeading44 = currentPageHeadingValue;
    } else {
      this.pageHeading44 = '';
    }
    if (currentPageHeadingKey === 'displayName45') {
      this.pageHeading45 = currentPageHeadingValue;
    } else {
      this.pageHeading45 = '';
    }
    if (currentPageHeadingKey === 'displayName46') {
      this.pageHeading46 = currentPageHeadingValue;
    } else {
      this.pageHeading46 = '';
    }
    if (currentPageHeadingKey === 'displayName49') {
      this.pageHeading49 = currentPageHeadingValue;
    } else {
      this.pageHeading49 = '';
    }
    if (currentPageHeadingKey === 'displayName50') {
      this.pageHeading50 = currentPageHeadingValue;
    } else {
      this.pageHeading50 = '';
    }

    if (currentPageHeadingKey === 'displayName54') {
      this.pageHeading54 = currentPageHeadingValue;
    } else {
      this.pageHeading54 = '';
    }

    if (currentPageHeadingKey === 'displayName55') {
      this.pageHeading55 = currentPageHeadingValue;
    } else {
      this.pageHeading55 = '';
    }

    if (currentPageHeadingKey === 'displayName56') {
      this.pageHeading56 = currentPageHeadingValue;
    } else {
      this.pageHeading56 = '';
    }

    if (currentPageHeadingKey === 'displayName57') {
      this.pageHeading57 = currentPageHeadingValue;
    } else {
      this.pageHeading57 = '';
    }
    if (currentPageHeadingKey === 'displayName58') {
      this.pageHeading58 = currentPageHeadingValue;
    } else {
      this.pageHeading58 = '';
    }
    if (currentPageHeadingKey === 'displayName60') {
      this.pageHeading60 = currentPageHeadingValue;
    } else {
      this.pageHeading60 = '';
    }
 
  }
  validateMenu(menuId: string): boolean {
    return this.menuAccessList.includes(menuId);
  }
  getAllowedMenuIdList(menuList: any) {
    if (menuList && menuList.length) {
      for (var menu of menuList) {
        this.menuAccessList.push(menu.MenuID);
      }
    }
  }
  getDisplayNameValue(item: any): string {
    var displayNameKey;
    for (let prop in item) {
      if (prop && prop.toString().startsWith('displayName')) {
        displayNameKey = prop;
        break;
      }
    }
    return item[displayNameKey];
  }
 
 
 
 
 
  getDisplayNameKey(item: any): string {
    var displayNameKey;
    for (let prop in item) {
      if (prop && prop.toString().startsWith('displayName')) {
        displayNameKey = prop;
        break;
      }
    }
    // console.log("displayNameKey" +  displayNameKey);
    return displayNameKey;
  }

  checkIfMobileDevice(): boolean {
    var ua = navigator.userAgent, isMobileDevice = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      isMobileDevice = true;
    }
    return isMobileDevice;
  }

  translateCookieBar() {
    this.translate.get(['HOME.header', 'HOME.messagecookie', 'HOME.dismiss', 'HOME.allow', 'HOME.deny', 'HOME.link', 'HOME.policy'])
      .subscribe(data => {
        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        this.ccService.getConfig().content.header = data['HOME.header'];
        this.ccService.getConfig().content.messagecookie = data['HOME.messagecookie'];
        this.ccService.getConfig().content.dismiss = data['HOME.dismiss'];
        this.ccService.getConfig().content.allow = data['HOME.allow'];
        this.ccService.getConfig().content.deny = data['HOME.deny'];
        this.ccService.getConfig().content.link = data['HOME.link'];
        this.ccService.getConfig().content.policy = data['HOME.policy'];
        this.ccService.destroy();
        this.ccService.init(this.ccService.getConfig());
      });
  }

  // Commented code for session management, kept for future reference
  /* openDialog(headerTitle: string, message: string, isSuccess: boolean) {
    const dialogRef = this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess
      },
      height: 'auto',
      width: (this.mobileQuery.matches ? '50%' : '25%'),
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.bnIdle.resetTimer();
        this.bnIdle.stopTimer();
        this.logout();
      }
    });
  }

  startCheckForIdleTime() {
    let showSessionExpiredPopUp: boolean = true;
    this.bnIdle.startWatching(300).subscribe((response) => {
      if (response && showSessionExpiredPopUp) {
        this.openDialog('session-expired', 'session-expired', false);
        showSessionExpiredPopUp = false;
      }
    })
  }

  resetSessionIdleTimeCounter() {
    this.bnIdle.resetTimer();
    this.bnIdle.stopTimer();
  } */
 
}