<div class="container">
    <div class="row">
        <div class="col-sm text-center">
            <span class="change-password-icon"><i class="fas fa-unlock-alt"></i></span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm text-center">
            <span class="change-password-title"> {{'HOME.ChangePassword'| translate}}
            </span>
        </div>
    </div>
    <div class="row" *ngIf="isFirstLogin">
        <div class="col-sm text-center">
            <!-- <span mat-dialog-title class="header-text">  {{'HOME.Thisisyourfirstlogin,pleasechangepassword'| translate}} -->
            <span mat-dialog-title class="header-text">  {{'HOME.please-change-password'| translate}}
            </span>
        </div>
    </div>
    <div class="row pt-2">
        <div class="col-sm">
            <form [formGroup]="form1">
                <div class="form-group">
                    <label><span class="label-text"> {{'HOME.OldPassword'| translate}}
                        </span></label>
                    <input formControlName="oldPwd" id="oldPwd" type="password" class="form-control text-input" />
                </div>
                <div class="form-group">
                    <label><span class="label-text"> {{'HOME.NewPassword'| translate}}
                        </span></label>
                    <input formControlName="newPwd"   id="newPwd" type="password"  class="form-control" />
                </div>
                
                <div class="form-group">
                    <label><span class="label-text"> {{'HOME.Confirmpassword'| translate}}
                        </span></label>
                    <input formControlName="confirmPwd" id="confirmPwd" type="password" class="form-control" />
                </div>
            </form>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col-sm text-center">
            <button class="btn btn-sm btn-primary dialog-footer-button" (click)="changePassword()"> {{'HOME.Save'| translate}}
            </button>
        </div>
        <div class="col-sm text-center cancel-button-padding">
            <button class="btn btn-sm btn-primary dialog-footer-button" (click)="cancel()"> {{'HOME.Cancel'| translate}}
            </button>
        </div>
    </div>
</div>