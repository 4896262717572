<div class="container-fluid">
    <div class="col-lg-12 col-md-12">
        <form [formGroup]="addUserForm">
            <div class="container">
                <div class="row pl-1 pb-1">
                    <div class="col-md-5"></div>
                    <div class="col-md-4 pl-5 pt-1 pb-2">
                        <div class="avatar-upload" title=" {{'HOME.clicktoupload'| translate}}">
                            <div class="avatar-edit">
                                <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                                    (change)="uploadFile($event)" />
                            </div>
                            <div class="avatar-preview">
                                <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'"
                                    (click)="fileInput.click()"> <i class="fas fa-camera edit-image"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class='col-lg-12'>
                                    <div class='row'>
                                        <div class="col-lg-1 mt-10 align-right">
                                            <label class="font-bold font-small" for="countryType">Country</label>
                                        </div>
                                        <div class="col-lg-4">
                                            <mat-form-field appearance="outline">
                                                <mat-select disableOptionCentering [(value)]="countrySearch"
                                                    panelClass="select-card">
                                                    <mat-select-trigger>
                                                        <span
                                                            class="flag-icon flag-icon-{{countrySearch | lowercase}} flag-size"></span>{{countryList[countrySearch]}}
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let country of countryList | keyvalue"
                                                        [value]="country.key">
                                                        <span
                                                            class="flag-icon flag-icon-{{ country.key | lowercase}} flag-size"></span>{{country.value}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>


                                    </div>
                                </div> -->
                <div class="row pl-1 pt-4 no-gutters">
                    <div class="col-md-4 pr-4">
                        <div class="form-group">
                            <label for="Contact_Name">
                                <span class="input-text-header">{{'HOME.ContactName'| translate}}</span><span
                                    class="mandatory-field pl-1">*</span>
                            </label>

                            <input type="text" class="form-control input-text" formControlName="Contact_Name"
                                id="Contact_Name" [ngClass]="{ 'is-invalid': submitted && fields.Contact_Name.errors }">

                            <div *ngIf="submitted && fields.Contact_Name.errors" class="invalid-feedback">
                                <div *ngIf="fields.Contact_Name.errors.required">
                                    {{'HOME.Contactnameisrequired'| translate}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="Designation">
                                <span class="input-text-header">{{'HOME.Designation'| translate}}</span><span
                                    class="mandatory-field pl-1">*</span>
                            </label>

                            <input type="text" class="form-control input-text" formControlName="Designation"
                                id="Designation" [ngClass]="{ 'is-invalid': submitted && fields.Designation.errors }">

                            <div *ngIf="submitted && fields.Designation.errors" class="invalid-feedback">
                                <div *ngIf="fields.Designation.errors.required">
                                    {{'HOME.Designationisrequired'| translate}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row pl-1 pt-4">
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="Phone_Number">
                                <span class="input-text-header">{{'HOME.PhoneNumber'| translate}}</span><span
                                    class="mandatory-field pl-1">*</span>
                            </label>

                            <select class="form-control input-text addUserSelect"
                                formControlName="PrimaryContactCountryCode" id="primaryContactCountryCode"
                                [ngClass]="{ 'is-invalid': submitted && fields.PrimaryContactCountryCode.errors }">
                                <option value="">Country code</option>
                                <option *ngFor="let country of listCountries" [value]="country.id">
                                    {{ country.id }} (+ {{ country.code }} )
                                </option>
                            </select>

                            <div *ngIf="submitted && fields.PrimaryContactCountryCode.errors" class="invalid-feedback">
                                <div *ngIf="fields.PrimaryContactCountryCode.errors.required">
                                    {{'HOME.Countrycodeisrequired'| translate}}</div>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-2 pl-1 pr-1">
                        <div class="form-group">
                            <label for="Phone_Number">
                                <span>&nbsp;</span>
                            </label>
                            <input type="text" class="form-control input-text" formControlName="Phone_Number" id="Phone_Number"
                                [ngClass]="{ 'is-invalid': submitted && fields.Phone_Number.errors }" />

                            <div *ngIf="submitted && fields.Phone_Number.errors" class="invalid-feedback">
                                <div *ngIf="fields.Phone_Number.errors.required">
                                    {{'HOME.Phonenumberisrequired'| translate}}</div>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-4 pl-3">
                        <div class="form-group">
                            <label for="Email Id">
                                <span class="input-text-header">{{'HOME.Emailaddress'| translate}}</span><span
                                    class="mandatory-field pl-1">*</span>
                            </label>

                            <input type="text" class="form-control input-text" formControlName="Email_Id" id="Email_Id"
                                [ngClass]="{ 'is-invalid': submitted && fields.Email_Id.errors }" />

                            <div *ngIf="submitted && fields.Email_Id.errors" class="invalid-feedback">
                                <div *ngIf="fields.Email_Id.errors.required">{{'HOME.Emailisrequired'| translate}}</div>
                                <div *ngIf="fields.Email_Id.errors.email">
                                    {{'HOME.Emailmustbeavalidemailaddress'| translate}}</div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-4 pl-3">
                        <div class="form-group">
                            <label for="test">
                                <span class="input-text-header">{{'HOME.Country'| translate}}</span><span
                                    class="mandatory-field pl-1">*</span>
                            </label>
                            <select class="form-control input-text addUserSelect" [(ngModel)]='selectedCountry' formControlName="Country_Code"
                                id="Country_Code" [ngClass]="{ 'is-invalid': submitted && fields.Country_Code.errors }">
                                <option value=""> {{'HOME.Selectcountry'| translate}}
                                </option>
                                <option *ngFor="let country of listCountries" [value]="country.id">
                                    <!-- {{country.name}} -->
                                    {{'HOME.' + country.name | translate}}
                                </option>
                            </select>

                            <div *ngIf="submitted && fields.Country_Code.errors" class="invalid-feedback">
                                <div *ngIf="fields.Country_Code.errors.required">{{'HOME.Countryisrequired'| translate}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </form>

        <!-- <div class="row">
            <div class="col-sm">
                <button id="btn-save" class="btn btn-sm btn-primary add-price-buttons saveBtn"
                    (click)="save()">{{'HOME.Save'| translate}}</button>
            </div>

            <div class="col-sm">
                <button id="btn-cancel" name="cancel" class="btn btn-sm btn-primary add-price-buttons cancelBtn"
                    (click)="reset()">{{'HOME.Reset'| translate}}</button>
            </div>
        </div> -->

        <div class="row pl-1 pt-2">
            <div class="col-sm">
                <button id="btn-save" class="btn btn-sm btn-primary add_bitumen_index_buttons saveBtn"
                    (click)="save()">{{'HOME.Save'| translate}}</button>
            </div>
            <div class="col-sm">
                <button id="btn-reset" class="btn btn-sm btn-primary add_bitumen_index_buttons cancelBtn"
                    (click)="reset()">{{'HOME.Reset'| translate}}</button>
            </div>
        </div>


        <!-- <div class="col-md-3"></div> -->
        <!-- selected country's code: {{selectedCountry}} -->


        <div class="col-lg-12">
            <div class="horizontal_scrollbar_div_mv ">
                <table mat-table #table [dataSource]="dataSource" matSort
                    class="horizontal_scrollbar manage-user-table scrollbar-manage-user ">

                    <ng-container matColumnDef="Contact_Name">
                        <mat-header-cell class="header-font mat-column-name" *matHeaderCellDef mat-sort-header>
                            {{'HOME.ContactName'| translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat-column-name mat-table-column-row-text'" class="mat-column-name">
                            {{fetchedDataForItemArray.Contact_Name}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Designation">
                        <mat-header-cell class="header-font mat-column-designation" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Designation'| translate}}

                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-designation">
                            {{fetchedDataForItemArray.Designation}}
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="Phone_Number">
                        <mat-header-cell class="header-font mat-column-phno" *matHeaderCellDef mat-sort-header>
                            {{'HOME.PhoneNumber'| translate}}

                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-phno">
                            {{fetchedDataForItemArray.Phone_Number}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Email_Id">
                        <mat-header-cell class="header-font mat-column-email" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Email'| translate}} {{'HOME.Address'| translate}}

                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-email">
                            {{fetchedDataForItemArray.Email_Id}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Country_Name">
                        <mat-header-cell class="header-font mat-column-countryname" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Country'| translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-countryname">
                            <!-- {{fetchedDataForItemArray.Country_Name}} -->
                            {{'HOME.' + fetchedDataForItemArray.Country_Name | translate}}
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="edit">
                        <mat-header-cell class="header-font mat-column-edit" *matHeaderCellDef>
                            {{'HOME.Edit'| translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" (click)='selectRow(row)' class="mat-column-edit">
                            <span class='active-icon'>
                                <i class="far fa-edit" aria-hidden="true"></i>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <mat-header-cell class="header-font mat-column-delete" *matHeaderCellDef>
                            {{'HOME.Delete'| translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" (click)='deleteContact(row.Contact_ID)'
                            class="mat-column-delete">
                            <span class='active-icon'>
                                <i class="fas fa-trash" aria-hidden="true"></i>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                </table>
            </div>
            <mat-paginator   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"   showFirstLastButtons></mat-paginator>
        </div>




    </div>