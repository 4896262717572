import { MediaMatcher } from '@angular/cdk/layout';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy,Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { LoaderService } from './loader.service';
import { DialogBodyComponent } from './user-management/dialog-body/dialog-body.component';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor, OnDestroy {
    private requests: HttpRequest<any>[] = [];
    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    apiUrl = environment.apiUrl;
    getDeviceIPAddressUrl = environment.getDeviceIPAddressUrl;

    constructor(private loaderService: LoaderService, private router: Router, private dialog: MatDialog, private injector: Injector,
        // private cd: ChangeDetectorRef, 
        private media: MediaMatcher) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        // this._mobileQueryListener = () => cd.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    /* ngAfterContentChecked() {
        this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
        // this._mobileQueryListener = () => this.cd.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    } */

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        if (req && req.url && !(req.url.includes('TrackOrder') || req.url.includes('TrackJDEOrder'))) {
            this.loaderService.isLoading.next(this.requests.length > 0);
        } else {            
            this.loaderService.isLoading.next(false);
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tokenService = this.injector.get(LoaderService); 
        const token = tokenService.getToken(); 
        if (token) {
            req = req.clone({
                  setHeaders: {
                      Authorization: `Basic ${token}`
                     }
                  });
          }
 
        this.requests.push(req);
        // console.log("No of requests--->" + this.requests.length);

        if (req && req.url && !(req.url.includes('TrackOrder') || req.url.includes('TrackJDEOrder'))) {
            this.loaderService.isLoading.next(true);
        }
        
        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        // alert('error returned');
                        // Navigate to Page not found, if there is an issue with internet connection only, else display the error message.
                        if (err) {
                            if (!window.navigator.onLine) {
                                this.router.navigate(['/pagenotfound']);
                                this.removeRequest(req);
                                observer.error(err);
                            } else {
                                if (req && req.url && req.url !== this.getDeviceIPAddressUrl) {
                                    this.openSaveUserDialog((err.statusText + ' - ' + err.name), err.message + (err.error ? err.error.errorMessage : ''), false, 'Something-went-wrong');
                                }
                                this.removeRequest(req);
                                observer.complete();
                            }
                        } else {
                            if (req && req.url && req.url !== this.getDeviceIPAddressUrl) {
                                this.openSaveUserDialog('Something went wrong', '', false, 'Something-went-wrong');
                            }
                            this.removeRequest(req);
                            observer.complete();
                        }
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }

    openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
        this.dialog.open(DialogBodyComponent, {
            data: {
                header: headerTitle,
                message: message ? message : ' ',
                buttonText: {
                    ok: 'Ok'
                },
                isSuccess: isSuccess,
                headerNumber: headerNumber
            },
            height: '50%',
            width: (this.mobileQuery.matches ? '50%' : '22%'),
        });
    }
}