import { MediaMatcher } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { HistoryService } from 'src/app/order-management/history/history.component.service';
import { CommonService } from 'src/app/order-management/order-management/order-management.component.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import * as XLSX from 'xlsx';
import { ForecastactualweeklyswedenService } from '../forecastactualweeklysweden/forecastactualweeklysweden.service';
import { DailyReportsService } from './daily-reports.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YY',
  },
  display: {
    dateInput: 'DD-MMM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-daily-reports',
  templateUrl: './daily-reports.component.html',
  styleUrls: ['./daily-reports.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class DailyReportsComponent implements OnInit {
  
  displayTable: boolean;
  dataSource: any;
  customerIdUserSelected: string;
  deliverySiteList: any = [];
  deliverySiteUserSelected: string;
  userInfo: any;
  countryCode: any;
  customerList = [];
  customerId: string;
  customerName: string;
  isLoggedInUserPortalAdmin: boolean;
  role: string;
  userID: string;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  deliverySiteObjectList: any = [];
  viewUserSelected: string = 'By deliverysite';
  showDepotNameColumn: boolean;
  showProductNameColumn: boolean;
  showDeliverySiteColumn: boolean;
  showProdDepotColumn : boolean;
  showUK : boolean;
  isRequestCompleted: boolean;
  startDate: Date;
  endDate: Date;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: any = ['CUSTOMER_NAME', 'CUSTOMER_PO', 'DEPOT_NAME', 'ACTUAL_SHIP_DATE', 'DELIVERY_SITE', 'PRODUCT_NAME', 'SHIPPED_QTY', 'productTypeId', 'INVOICE_NUMBER', 'INVOICE_DATE', 'INCOTERMS', 'ORDER_NUMBER', 'ORDER_TYPE', 'ORIGINAL_ORDER_NUMBER','ADHESIVE_CONTENT','TEMPERATURE'];
  @ViewChild('dailyReportTableSort', { static: true }) public dailyReportTableSort: MatSort;
  january012020Date: any = new Date('01/01/2020');
  fromDateIsOlderThan1stJanuary2020: boolean;
  isFindButtonClicked: boolean;

  constructor(private forecastactualweeklyswedenService: ForecastactualweeklyswedenService,
    private dailyReportsService: DailyReportsService,
    private sharedService: AppService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private media: MediaMatcher,
    private historyService: HistoryService,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService,
    private commonService: CommonService) { 
      this.userInfo = this.sharedService.getLoginResponse();
      if (this.userInfo) {
        this.countryCode = (this.userInfo.CountryCode ? this.userInfo.CountryCode : '');
        this.customerId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
        this.customerName = (this.userInfo.CustomerName ? this.userInfo.CustomerName : '');
        this.isLoggedInUserPortalAdmin = (this.userInfo.RoleID === 'PA');
        this.role = (this.userInfo ? this.userInfo.RoleID : '');
        this.userID = (this.userInfo ? this.userInfo.UserID : '');
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => cd.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
      }
    }

 async ngOnInit() {
  this.getDefaultFromAndToDate();
  this.viewUserSelected = 'All';
  this.translate.get(['HOME.selectedLanguageForDatePicker'])
  .subscribe(data => {
    this.dateAdapter.setLocale(data['HOME.selectedLanguageForDatePicker']);
  });
    try {
      await this.getCustomerList();
    }
    catch (e) {
    }

    try {
      await this.getDeliverySites();
    }
    catch (e) {
    }
    /* if (this.isLoggedInUserPortalAdmin ) {
    //   this.customerIdUserSelected = '';
    // } else {
      this.customerIdUserSelected = this.customerId;
    } */

    // Do not load data on start, let user click on Find button to get the records on page
    // this.getReportsForAll();

  //   this.showDeliverySiteColumn = true;
  //   this.showDepotNameColumn = false;
  // this.showProductNameColumn = false;
  // this.showDeliverySiteColumn = false;
  // this.showProdDepotColumn = false;
  if (this.countryCode === 'GBR'){
    this.showUK = false;
  }
  else {
    this.showUK = true;
  }

   /* this.translate.get(['HOME.itemsPerPageLabel'])
     .subscribe(data => {
       this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
     }); */

  }

  async getCustomerList() {
    this.customerList = [];
    return new Promise<void>((resolve, reject) => {
      this.forecastactualweeklyswedenService.getCustomerList(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.customerList = res["Customers"]
            /* if (this.isLoggedInUserPortalAdmin) {
            //   this.customerIdUserSelected = '';
            // } else {
              this.customerIdUserSelected = this.customerId;
            } */
            this.customerIdUserSelected = this.customerId;
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  async getDeliverySites() {
    this.deliverySiteList = [];
    this.deliverySiteObjectList = [];
    return new Promise<void>((resolve, reject) => {
      this.commonService.getDeliverySite(this.customerIdUserSelected, "Inactive", this.countryCode).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            for (let deliverySiteObject of response["DeliverySiteCollection"]) {
              this.deliverySiteObjectList.push(deliverySiteObject);
                this.deliverySiteList.push(deliverySiteObject["DeliverySite"]);
                this.deliverySiteUserSelected = '';
            }
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }
  
  getReportsForTable() {
    if (this.viewUserSelected == "All") {
      this.getReportsForAll();
    }
    if (this.viewUserSelected == "By deliverysite") {
      this.getReportsForTableByDS();
    } else if (this.viewUserSelected == "By product") {
      this.getReportsForTableByViewProduct();
    } else if (this.viewUserSelected == "By depot") {
      this.getReportsForTableByViewDepot();
    } else if (this.viewUserSelected == "By product and depot") {
      this.getReportsForTableByViewProductAndDepot();
    }
  }

  getReportsForAll() {
    /* this.showDeliverySiteColumn = true;
    this.showDepotNameColumn = true;
  this.showProductNameColumn = true; */
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;

    if (!this.validateFromAndToDate()) {
      return;
    }

    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      this.dailyReportsService.fetchDailyActualSalesForAll(this.countryCode, this.getFormattedDate(this.startDate), this.getFormattedDate(this.endDate), this.customerIdUserSelected, jdeAddressNumber).subscribe
        (response => {

          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let dailyReportForAll = [];
            let dailyReportObject = {};
            let customerCollection = response["CustomerCollection"];
            
            if (customerCollection && customerCollection.length) {
              for (let customerObject of customerCollection) {
                if (customerObject && customerObject['DeliverySiteCollection'] && customerObject['DeliverySiteCollection'].length) {
                  let customerName = customerObject['CUSTOMER_NAME'];
                  for (let deliverySiteObject of customerObject['DeliverySiteCollection']) {
                    if (deliverySiteObject && deliverySiteObject['DetailsCollection'] && deliverySiteObject['DetailsCollection'].length) {
                      let deliverySiteName = deliverySiteObject['DELIVERY_SITE'];
                      // let jdeAddressNumber = deliverySiteObject['JDE_ADDRESS_NUMBER'];
                      for (let detailsObject of deliverySiteObject['DetailsCollection']) {
                        dailyReportObject['CUSTOMER_NAME'] = customerName;
                        dailyReportObject['DELIVERY_SITE'] = deliverySiteName;
                        dailyReportObject['PRODUCT_NAME'] = detailsObject['PRODUCT_NAME'];
                        dailyReportObject['DEPOT_NAME'] = detailsObject['DEPOT_NAME'];
                        // dailyReportObject['JDE_ADDRESS_NUMBER'] = jdeAddressNumber;
                        dailyReportObject['CUSTOMER_PO'] = detailsObject['CUSTOMER_PO'];
                        dailyReportObject['SHIPPED_QTY'] = detailsObject['SHIPPED_QTY'];
                        dailyReportObject['ACTUAL_SHIP_DATE'] = detailsObject['ACTUAL_SHIP_DATE'];
                        dailyReportObject['ORDER_DATE'] = detailsObject['ORDER_DATE'];
                        dailyReportObject['ORDER_TYPE'] = detailsObject['ORDER_TYPE'];
                        dailyReportObject['ORDER_NUMBER'] = detailsObject['ORDER_NUMBER'];
                        // dailyReportObject['DELIVERY_GROUP_NAME'] = detailsObject['DELIVERY_GROUP_NAMEOdown'];
                        dailyReportObject['INVOICE_NUMBER'] = detailsObject['INVOICE_NUMBER'];
                        dailyReportObject['INVOICE_DATE'] = detailsObject['INVOICE_DATE'];
                        dailyReportObject['INCOTERMS'] = detailsObject['INCOTERMS'];
                        dailyReportObject['ADHESIVE_CONTENT'] = detailsObject['ADHESIVE_CONTENT'];
                        dailyReportObject['TEMPERATURE'] = detailsObject['TEMPERATURE'];
                        dailyReportObject['productTypeId'] = detailsObject['productTypeId'];
                        dailyReportForAll.push(dailyReportObject);
                        dailyReportObject = {};
                      }
                    }
                  }
                }
              }
            }

            // Reset already present data, used to prevent repeated records displayed in table rows for customer, delivery site, depot and product names.
            // this.resetLastRowValueObject();

            

            // for (let weekData of weekCollection) {
            //   let dailyObject = {};
            //   /* if (this.lastRowValueObject['CustomerName'] !== weekData['CustomerName']) {
            //     weekObject['CustomerName'] = weekData['CustomerName'];
            //     this.lastRowValueObject['CustomerName'] = weekData['CustomerName'];
            //   } */
            //   dailyObject['CustomerNameFromResponse'] = weekData['CustomerName']; // Needed for sorting, not for display
            //   dailyObject['DeliverySiteName'] = weekData['DeliverySiteName'];
            //   dailyObject['ProductName'] = weekData['ProductName'];
            //   dailyObject['DepotName'] = weekData['DepotName'];
            //   dailyObject['WeekNumber'] = weekData['WeekNumber'];
            //   dailyObject['forecastquantity'] = weekData['forecastquantity'];
            //   dailyObject['FIRST_SNAPSHOT_QUANTITY'] = weekData['FIRST_SNAPSHOT_QUANTITY'];
            //   dailyObject['actualquantity'] = weekData['actualquantity'];
            //   dailyObject['difference'] = weekData['difference'];
            //   dailyObject['CustomerID'] = weekData['CustomerID'];
            //   dailyObject['DepoId'] = weekData['DepoId'];
            //   dailyObject['ProductId'] = weekData['ProductId'];

            //   // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
            //   /* if (dailyObject['CustomerName'] && dailyReportForAll && dailyReportForAll.length) {
            //     dailyObject['showRowTopBorder'] = true;
            //   } */

            //   dailyReportForAll.push(dailyObject);
            // }
            this.dataSource = new MatTableDataSource(dailyReportForAll);
            this.dataSource.sort = this.dailyReportTableSort;
            this.dataSource.paginator = this.paginator;

            // No need to convert to lower case when date object is passed
            this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
              if (data[sortHeaderId] && sortHeaderId !== 'ActualShipDate' && sortHeaderId !== 'InvoiceDate') {
                return data[sortHeaderId].toLocaleLowerCase();
              }
              return data[sortHeaderId];
            };

            this.displayTable = (dailyReportForAll && dailyReportForAll.length && dailyReportForAll.length > 0);
            this.isRequestCompleted = true;
            this.showDepotNameColumn = true;
            this.showProductNameColumn = true;
            this.showDeliverySiteColumn = true;
            this.isFindButtonClicked = true;

            resolve();

          } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
          else {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
        })
    });
  }

  getReportsForTableByDS(){
    this.showDeliverySiteColumn = false;
    this.showDepotNameColumn = true;
  this.showProductNameColumn = true;
  //this.showDeliverySiteColumn = true;
  this.showProdDepotColumn = true;
    
  }

  getReportsForTableByViewProduct(){
    this.showDeliverySiteColumn = true;
    this.showDepotNameColumn = true;
  this.showProductNameColumn = false;
    
  }

  getReportsForTableByViewDepot(){
    this.showDeliverySiteColumn = true;
    this.showDepotNameColumn = false;
  this.showProductNameColumn = true;
  
    
  }

  getReportsForTableByViewProductAndDepot(){
    this.showDeliverySiteColumn = true;
    this.showDepotNameColumn = false;
  this.showProductNameColumn = false;
  
  }

  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        if (deliverySiteObject && deliverySiteObject['DeliverySite'] === deliverySiteName) {
          jdeAddressNumber = deliverySiteObject['JdeAddressNumber'];
          break;
        }
      }
    }
    return jdeAddressNumber;
  }

  getFormattedDate(date: Date): string {
    if (date) {
      var datePipe = new DatePipe("en-US");
      return datePipe.transform(new Date(date), 'dd-MMM-yy').toLocaleUpperCase();
    }
    return '';
  }

  getDefaultFromAndToDate() {
    let currentDateTime = this.sharedService.getCurrentDateTime();
    let currentYear = currentDateTime.getFullYear();
    let currentMonth = currentDateTime.getMonth() + 1;
    this.startDate = new Date(currentMonth + '/01/' + currentYear); // Get date of 1st January of current year
    this.endDate = currentDateTime;
  }

  downloadReportsData() {
    if (!this.validateFromAndToDate()) {
      return;
    }
    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      this.dailyReportsService.fetchDailyActualSalesForAll(this.countryCode, this.getFormattedDate(this.startDate), this.getFormattedDate(this.endDate), this.customerIdUserSelected, jdeAddressNumber).subscribe
        (response => {
          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let dailyReportForAll = [];
            let dailyReportObject = {};
            let customerCollection = response["CustomerCollection"];
            if (customerCollection && customerCollection.length) {
              for (let customerObject of customerCollection) {
                if (customerObject && customerObject['DeliverySiteCollection'] && customerObject['DeliverySiteCollection'].length) {
                  let customerName = customerObject['CUSTOMER_NAME'];
                  for (let deliverySiteObject of customerObject['DeliverySiteCollection']) {
                    if (deliverySiteObject && deliverySiteObject['DetailsCollection'] && deliverySiteObject['DetailsCollection'].length) {
                      let deliverySiteName = deliverySiteObject['DELIVERY_SITE'];
                      // let jdeAddressNumber = deliverySiteObject['JDE_ADDRESS_NUMBER'];
                      for (let detailsObject of deliverySiteObject['DetailsCollection']) {
                        dailyReportObject['Customer name'] = customerName;
                        dailyReportObject['Delivery Place'] = deliverySiteName;
                        dailyReportObject['Product'] = detailsObject['PRODUCT_NAME'];
                        // dailyReportObject['Nynas Delivery Place'] = jdeAddressNumber;
                        dailyReportObject['Customer PO number'] = detailsObject['CUSTOMER_PO'];
                        dailyReportObject['Quantity MT'] = detailsObject['SHIPPED_QTY'];
                        dailyReportObject['Actual ship date'] = detailsObject['ACTUAL_SHIP_DATE'] ? this.datePipe.transform(detailsObject['ACTUAL_SHIP_DATE'], 'dd/MM/yyyy') : '';
                        dailyReportObject['Nynas Order Number'] = detailsObject['ORDER_NUMBER'];
                        

                        // Addisive content % & Temperature columns to be shown for Finland only
                        if (this.countryCode === 'FIN') {
                          dailyReportObject['Addisive content %'] = detailsObject['ADHESIVE_CONTENT'];
                          dailyReportObject['Temperature'] = detailsObject['TEMPERATURE'];
                        }

                        // Some columns not to be shown for UK
                        if (this.countryCode !== 'GBR') {
                          dailyReportObject['Loading Point'] = detailsObject['DEPOT_NAME'];
                          dailyReportObject['Type of product'] = detailsObject['productTypeId'];
                          dailyReportObject['Invoice number'] = detailsObject['INVOICE_NUMBER'];
                          dailyReportObject['Invoice Date'] = detailsObject['INVOICE_DATE'] ? this.datePipe.transform(detailsObject['INVOICE_DATE'], 'dd/MM/yyyy') : '';
                          dailyReportObject['Incoterms'] = detailsObject['INCOTERMS'];
                          dailyReportObject['Nynas Order type'] = detailsObject['ORDER_TYPE'];
                          // dailyReportObject['Group name'] = detailsObject['DELIVERY_GROUP_NAME'];
                          dailyReportObject['Nynas Original order number'] = detailsObject['ORIGINAL_ORDER_NUMBER'];
                        }

                        dailyReportForAll.push(dailyReportObject);
                        dailyReportObject = {};
                      }
                    }
                  }
                }
              }
            }

            let headerList = ['Customer name', 'Customer PO number', 'Loading Point', 'Actual ship date', 'Delivery Place', 'Product', 'Quantity MT', 'Type of product', 'Invoice number', 'Invoice Date', 'Incoterms', 'Nynas Order Number', 'Nynas Order type', 'Nynas Original order number', 'Addisive content %', 'Temperature'];

            // Addisive content % & Temperature columns not to be shown for Sweden & Norway
            if (this.countryCode === 'SWE' || this.countryCode === 'NOR') {
              headerList = ['Customer name', 'Customer PO number', 'Loading Point', 'Actual ship date', 'Delivery Place', 'Product', 'Quantity MT', 'Type of product', 'Invoice number', 'Invoice Date', 'Incoterms', 'Nynas Order Number', 'Nynas Order type', 'Nynas Original order number'];
            }

            // Some columns not to be shown for UK
            if (this.countryCode === 'GBR') {
              headerList = ['Customer name', 'Customer PO number', 'Actual ship date', 'Delivery Place', 'Product', 'Quantity MT', 'Nynas Order Number'];
            }

            this.exportDataInExcelFile(dailyReportForAll, 'Daily reports', headerList);
            resolve();
          }
          else {
            this.showStatusDialog(false, 'Something-went-wrong');
            reject();
          }
        })
    });
  }

  exportDataInExcelFile(data: any[], excelFileName: string, header: any): void {
    if (data) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((data), { header: header });
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const Report: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(Report, excelFileName + EXCEL_EXTENSION);
    }
  }

  showStatusDialog(isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,

      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  validateFromAndToDate(): boolean {
    this.fromDateIsOlderThan1stJanuary2020 = (this.startDate && new Date(this.startDate) < this.january012020Date);
    return ((this.startDate !== null && this.endDate !== null) && !this.fromDateIsOlderThan1stJanuary2020);
  }

}
