<div class="container-fluid sidepadding-none  scrollbar-table-content mt-negate">
    <div class="row ">
 
        <div class="col-lg-12 col-md-12">
           
            <!-- //2nd row start  -->
            <div class="row rowFirstGraph" [ngClass]="{'rowHight' : imageUrl }">
                <div class="col-lg-4 col-md-4 test">
                    <div class="row">
 
                        <div class="usr" *ngIf="imageUrl" (click)="editUser()">
                            <a class="normal">
                                <img class="userPhoto" src="{{ imageUrl }}"> <img>
 
                            </a>
                        </div>
                        <div class="usr" *ngIf="!imageUrl" (click)="editUser()">
                            <i class="far fa-user userPhoto userIconPhoto" aria-hidden="true"></i>
                        </div>
 
                        <div class="userdetails pl-4">
                            <span class="userName"> {{'HOME.Welcome'| translate}}
                                {{userInfo.FirstName}}!</span>
 
                            <!-- <div class="userNamedetails"> {{'HOME.CompanyName'| translate}}
                                :
                                <span class="label"> {{userInfo.CustomerName}} </span>
                            </div>
 
 
                            <div class="userNamedetails"> {{'HOME.Usergroup'| translate}}
                                :
                                <span class="label">{{userInfo.UserGroupName}}</span>
                            </div> -->

                            <div class="userNamedetails">
                                <span class="label">{{userInfo.CustomerName}}</span>
                            </div>
                            <div class="userNamedetails">
                                <span class="label">{{userInfo.UserGroupName}}</span>
                            </div>

                            <!-- <span class="userNamedetails">Last login:{{userInfo.LastLoginTime}}</span> -->
                            <span class="changePwd" (click)="editUser()"> {{'HOME.MyProfile'| translate}}
                            </span>
                            <span class="changePwd" (click)="updatePassword()"> {{'HOME.Changepassword'| translate}}
                            </span>
                        </div>
                    </div>
                    <!-- <hr> -->
                    <div *ngIf="this.countryCode !== 'GBR'" class="row">
 
                        <div class="col-lg-6col-md-6 col-sm-6 userNav" *ngIf="validateMenu('41')">
                            <a class="normal" routerLink="../../app/my-company/">
                                <i class="fa fa-building tileIcon"></i>
                                <p class="usrtile"> {{'HOME.Mycompany'| translate}}
                                </p>
                            </a>
                        </div>
 
                        <div *ngIf="this.countryCode !== 'DNK'" class="col-lg-6 col-md-6 col-sm-6 userNav">
                            <a class="normal" routerLink="../../app/order-management/pending-forecast"
                                (click)="showSelectedMenuItem('Forecast status')">
                                <i class="fas fa-user-clock tileIcon"></i>
                                <p class="usrtile" (click)="showSelectedMenuItem('Forecast status')">
                                    {{'HOME.Pendingforecast'| translate}}
 
                                </p>
                            </a>
                        </div>
                        </div>
                        <div *ngIf="this.countryCode === 'GBR'" class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 userNav" *ngIf="validateMenu('51')">
                                <a class="normal" routerLink="../../app/order-management/order-entry" (click)="showSelectedMenuItem('Order entry')">
                                    <i class="fas fa-file-signature tileIcon"></i>
                                    <p class="usrtile" (click)="showSelectedMenuItem('Order entry')">
                                        Place an order
                                    </p>
                                </a>
                            </div>
                            <div class="col-lg-6col-md-6 col-sm-6 userNav" *ngIf="validateMenu('52')">
                                <a class="normal" routerLink="../../app/order-management/order-tracking"
                                    (click)="showSelectedMenuItem('Order tracking')">
                                    <i class="fas fa-route tileIcon"></i>
                                    <p class="usrtile" (click)="showSelectedMenuItem('Order tracking')">
                                        View existing orders
                                    </p>
                                </a>
                            </div>
                        </div>
 
 
 
                </div>
                <div *ngIf="((countryCode === 'SWE' || countryCode === 'NOR') || (countryCode === 'FIN' && validateMenu('54')))" class="col-lg-7 col-md-7  banner-two-planned">
                     
                    <div class="nysHead">{{'HOME.home-page-graph1'| translate}}</div>
 
                    <!-- Do not display any non translated message on page -->
                    <!-- <div class="mt-5" [hidden]="!displayErrorMsg" class="errMsg">
                        <span>{{errorDesc}}</span>
                    </div> -->
 
                    <div id="bar1" *ngIf="(userInfo.RoleID === 'U')">
                        <canvas baseChart [datasets]="barChartData1" [labels]="barChartLabels1"
                            [options]="barChartOptions1" [plugins]="barChartPlugins1" [legend]="barChartLegend1"
                            [chartType]="barChartType1">
                        </canvas>
                    </div>
                    <div id="bar2" *ngIf="(userInfo.RoleID === 'PA' || userInfo.RoleID === 'A' )">
                        <canvas baseChart [datasets]="barChartData2" [labels]="barChartLabels2"
                            [options]="barChartOptions2" [plugins]="barChartPlugins2" [legend]="barChartLegend2"
                            [chartType]="barChartType2">
                        </canvas>
                    </div>
 
                </div>
                <div *ngIf="this.countryCode === 'GBR'" class="col-lg-7 col-md-7  banner-two-planned">
                
 
                </div>
 
            </div>
            <!-- //2nd row end  -->
            <!-- //3rd row start  -->
            <div class="row thirdrow" [ngClass]="{'pt-5': (!dataSource || !(dataSource && dataSource.data && dataSource.data.length))}">
                <div class="col-lg-4 col-md-4  test" [ngClass]="{'hide-nynas-update-div': !(validateMenu('23'))}">
                    <!-- Do not display any non translated message on page -->
                    <!-- <div class="mt-5" [hidden]="!displayErrorMsg" class="errMsg">
                        <span>{{errorDesc}}</span>
                    </div> -->
 
                    <span class="nysHead"> {{'HOME.NynasUpdates'| translate}}
                    </span>
                    <div class="container_main_table manage-user-table scrollbar-manage-user">
                        <table mat-table [dataSource]="dataSource" matSort>
 
                            <ng-container matColumnDef="TITLE">
                                <th mat-header-cell *matHeaderCellDef
                                    [ngClass]="'mat-table-column-header mat-column-Title mat-table-column-header-text'">
                                    {{'HOME.Title'| translate}}
                                </th>
                                <!-- <th mat-header-cell *matHeaderCellDef> CustomerName </th> -->
                                <td mat-cell *matCellDef="let element"
                                    [ngClass]="'mat-column-customer-Title mat-table-column-row-text'">
                                    {{ element.TITLE }} </td>
                            </ng-container>
                            <ng-container matColumnDef="NYNAS_UPDATE_DATE">
                                <th mat-header-cell *matHeaderCellDef
                                    [ngClass]="'mat-table-column-header mat-column-Date mat-table-column-header-text'">
                                    {{'HOME.Date'| translate}}
 
                                </th>
                                <td mat-cell *matCellDef="let element"
                                    [ngClass]="'mat-column-Date mat-table-column-row-text'">
 
                                    {{element.NYNAS_UPDATE_DATE | date: 'MM/dd/yyyy'}}
                                </td>
 
                            </ng-container>
                            <ng-container matColumnDef="View">
                                <th mat-header-cell *matHeaderCellDef
                                    [ngClass]="'mat-table-column-header mat-column-user-Download mat-table-column-header-text'">
                                </th>
                                <!-- <th mat-header-cell *matHeaderCellDef> UserRole </th> -->
                                <!-- <td mat-cell *matCellDef="let element"
                                    [ngClass]="'mat-column-user-Download mat-table-column-row-text download-button'">
                                    <span class='pdf-content' (click)='downloadPdf(element.DOCUMENT)'>
                                        <i class="fa fa-download" aria-hidden="true"></i>
                                    </span>
                                </td> -->
 
 
                                <td mat-cell *matCellDef="let element">
                                    <a class='pdf-content'
                                        (click)='downloadPdf(element.DOUCUMENT_URL, element.STORAGE_TYPE)'>
                                        <ng-container *ngIf='element.DOUCUMENT_URL!=""'>
                                            <span class='font-vsmall font-bold'>{{'HOME.View'| translate}}</span>
                                        </ng-container>
                                        <ng-container *ngIf='element.DOUCUMENT_URL==""'>
                                            <span class='font-vsmall font-bold'>NA</span>
                                        </ng-container>
                                    </a>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
 
                </div>
                <div class="col-lg-7 col-md-7  banner-two-usd">
                    <!-- Do not display any non translated message on page -->
                    <!-- <div class="mt-5" [hidden]="!displayErrorMsg" class="errMsg">
                        <span>{{errorDesc}}</span>
                    </div> -->
 
                    <!-- <div *ngIf="countryCode === 'FIN'" style="display: block;">
                        <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels"
                            [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                            [chartType]="lineChartType" ></canvas>
                    </div>
                    <div *ngIf="countryCode !== 'FIN'" style="display: block;">
                        <canvas baseChart [datasets]="lineChartData1" [labels]="lineChartLabels1"
                            [options]="lineChartOptions1" [colors]="lineChartColors1" [legend]="lineChartLegend1"
                            [chartType]="lineChartType1" ></canvas>
                    </div> -->

                    <div *ngIf="countryCode === 'FIN' && validateMenu('55')" style="display: block;">
                        <!-- Uncomment below code changes to hide graph until price formula changes are done, while deploying in Prod -->
                        <!-- <span class="price-graph-unavailable-message">
                                                {{'HOME.price-graph-unavailable-message'| translate}}
                                            </span> -->
                        <!-- Comment below code changes to hide graph until price formula changes are done, while deploying in Prod -->
                        <!-- Commented code changes done for past years -->
                        <!-- <div *ngIf="this.year <= 2020">
                            <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
                                [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"></canvas>
                        </div> -->

                        <div class="nysHead">{{'HOME.home-page-graph2'| translate}}</div>
                        
                        <div>
                            <canvas baseChart [datasets]="lineChartData2" [labels]="lineChartLabels2" [options]="lineChartOptions2"
                                [colors]="lineChartColors2" [legend]="lineChartLegend2" [chartType]="lineChartType2"></canvas>
                        </div>
                        <div class="pt-3" *ngIf="expectedPriceChange && expectedPriceChange.toUpperCase() !== 'NAN'">
                            <span class="graph-title-font  expected-price-font">{{'HOME.Estimatedpricechange'| translate}}:
                                {{ expectedPriceChange }} EUR/MT</span>
                        </div>
                        <div class="pt-1 padding-bottom-8">
                            <!-- <table>
                                <tr>
                                    <td *ngIf="this.year <= 2020" class="graph-title-column-width"><span
                                            class="graph-title-brent-rate-line">&mdash;</span><span
                                            class="graph-title-font">{{'HOME.iceBrentNotation'| translate}}</span></td>
                                    <td class="graph-title-column-width"><span class="graph-title-brent-rate-line">&mdash;</span><span
                                            class="graph-title-font">{{'HOME.PlattsHSFO35FOBRotterdamBargesUSDMT'| translate}}</span></td>
                                    <td class="graph-title-column-width"><span class="graph-title-conversion-rate-line">&mdash;</span><span
                                            class="graph-title-font">{{'HOME.conversionRate'| translate}}</span></td>
                                </tr>
                                <tr>
                                    <td *ngIf="this.year <= 2020" class="graph-title-column-width"><span
                                            class="graph-title-average-brent-rate-line">-&nbsp;-</span><span
                                            class="graph-title-font">{{'HOME.averageBrentRate'| translate}}</span></td>
                                    <td class="graph-title-column-width"><span
                                            class="graph-title-average-brent-rate-line">-&nbsp;-</span><span
                                            class="graph-title-font">{{'HOME.AveragePlattsHSFOrate'| translate}}</span></td>
                                    <td class="graph-title-column-width"><span
                                            class="graph-title-average-conversion-rate-line">-&nbsp;-</span><span
                                            class="graph-title-font">{{'HOME.averageConversionRate'| translate}}</span></td>
                                </tr>
                            </table> -->
                            <table>
                                <tr>
                                    <td class="graph-title-column-width">
                                        <span class="graph-title-span blue-color">
                                            <span class="pt-1">-&nbsp;</span>
                                            <!-- <span class="dot blue-background-color"></span> -->
                                            <span class="pt-1">-&nbsp;</span>
                                        </span>
                                        <span class="graph-title-font padding-left-2">{{'HOME.CumulativeaverageofdailyPlattsHSFO35FOBRotterdamBargesnotationsEURMT'| translate}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="graph-title-column-width">
                                        <span class="graph-title-span marron-color">&mdash;</span>
                                        <span class="graph-title-font">{{'HOME.AverageofdailyPlattsHSFO35FOBRotterdamBargesnotationsEURMT'| translate}} {{ getPreviousMonthNameForSelectedLanguage() }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="graph-title-column-width">
                                        <span class="graph-title-span yellow-color">
                                            <span class="pt-1">-&nbsp;</span>
                                            <!-- <span class="dot marron-background-color"></span> -->
                                            <span class="pt-1">-&nbsp;</span>
                                        </span>
                                        <span class="graph-title-font padding-left-2">{{'HOME.CumulativeaverageofexchangeratesUSDEUR'| translate}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="graph-title-column-width">
                                        <span class="graph-title-span teal-color">&mdash;</span>
                                        <span class="graph-title-font">{{'HOME.ExchangerateUSDEUR'| translate}} {{ getPreviousMonthNameForSelectedLanguage() }}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>


                    <!-- <div *ngIf="countryCode == 'DNK'" style="display: block;" class="padding-bottom-8">
                        <canvas baseChart [datasets]="lineChartData1" [labels]="lineChartLabels1" [options]="lineChartOptions1"
                            [colors]="lineChartColors1" [legend]="lineChartLegend1" [chartType]="lineChartType1"></canvas>
                    </div> -->
                    
                    <div *ngIf="countryCode == 'NOR' || countryCode == 'SWE' || countryCode == 'DNK'" style="display: block;">
                        <!-- <div class="col-xs-12 col-sm-12 mobile"> -->
                        <a href="https://www.nynas.com/en/media/newslist/" target="_blank">
                            <img src="../../assets/dashboard.jpg" style="width: 100%; padding:25px" />
                            <div class="news-bubble">Latest news, events and reports</div>
                        </a>
                        <!-- </div> -->
                    
                    </div>
 
                </div>
 
            </div>
            <!-- //Icon based menu for mobile View -->
            <div class="row mobileViewMenu">
 
                <div class="col-6 iconsClass"
                    *ngIf="(validateMenu('2') && (countryCode !== 'GBR') && (countryCode !== 'DNK') && ((countryCode !== 'SWE') || (this.validateMenu('45') || this.validateMenu('46'))))">
                    <a class="normal" routerLink="../../app/order-management/forecast-entry"
                        (click)="showSelectedMenuItem('Forecast entry')">
                        <i class="fa fas fa-calendar-alt mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.item.displayName3'|translate}}
                        </span>
                    </a>
                </div>
                <div class="col-6 iconsClass" *ngIf="validateMenu('51')">
                    <a class="normal" routerLink="../../app/order-management/order-entry" (click)="showSelectedMenuItem('Order entry')">
                        <i class="fas fa-file-signature mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.item.displayName51'|translate}}
                        </span>
                    </a>
                </div>
                <div class="col-6 iconsClass" *ngIf="validateMenu('52')">
                    <a class="normal" routerLink="../../app/order-management/order-tracking" (click)="showSelectedMenuItem('Order tracking')">
                        <i class="fas fa-route mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.item.displayName52'|translate}}
                        </span>
                    </a>
                </div>
                <div class="col-6 iconsClass" *ngIf="validateMenu('3')">
 
                    <a class="normal" routerLink="../../app/product-management/product-documentation">
 
                        <i class="fab fa-product-hunt mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.Proinfo'|translate}}
                        </span>
 
                    </a>
 
                </div>
                <div class="col-6 iconsClass" *ngIf="validateMenu('4')">
 
                    <a class="normal" routerLink="../../app/price-management/price-data">
 
                        <i class="far fa-chart-bar mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.Price&Index'|translate}}
                        </span>
                    </a>
                </div>
 
                <div class="col-6 iconsClass" *ngIf="validateMenu('41')">
 
                    <a class="normal" routerLink="../../app/my-company/company-details"
                    >
 
                        <i class="fa fa-building mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.item.displayName14'|translate}}
                        </span>
                    </a>
                </div>
                <div class="col-6 iconsClass"
                    *ngIf="(this.validateMenu('5') && (!(this.countryCode === 'SWE' || this.countryCode === 'GBR' || this.countryCode === 'DNK')) || this.validateMenu('43'))">
                    <a class="normal" routerLink="../../app/reports/weekly-reports">
                        <i class="fas fa-chart-pie mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.Reports'|translate}}
                        </span>
                    </a>
                </div>
                <div class="col-6 iconsClass"
                    *ngIf="(this.countryCode === 'GBR' && this.validateMenu('5') && ((this.validateMenu('47')) || (this.validateMenu('50'))))">
                    <a class="normal" routerLink="../../app/reports/shipped-deliveries">
                        <i class="fas fa-chart-pie mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.Reports'|translate}}
                        </span>
                    </a>
                </div>
                <div class="col-6 iconsClass" *ngIf="isLoggedInUserPortalAdmin">
                    <a class="normal" routerLink="../../app/user-management/manage-users"
                    >
 
                        <i class="fa fa-user mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.item.displayName20'|translate}}
                        </span>
                    </a>
                </div>
                <div class="col-6 iconsClass" *ngIf="isLoggedInUserPortalAdmin">
                    <a class="normal" routerLink="../../app/configuration/customer-contract">
 
                        <i class="fa fa-cog mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.item.displayName28'|translate}}
                        </span>
                    </a>
                </div>
                <div class="col-6 iconsClass" *ngIf="(validateMenu('23'))">
                    <a class="normal" routerLink="../../app/nynas-updates">
 
                        <i class="fa fa-envelope mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.item.displayName25'|translate}}
                        </span>
                    </a>
                </div>
 
                <div class="col-6 iconsClass">
                    <a class="normal" routerLink="../../app/contactus">
 
                        <i class="fas fa-comment-alt mobile-icon"></i>
                        <span class="boxheader">
                            {{'HOME.item.displayName27'|translate}}
                        </span>
                    </a>
                </div>
 
            </div>
        </div>
    </div>
</div>