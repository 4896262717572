<div class="container-fluid sidepadding-none ">
    <div class="main-container">
        <form [formGroup]="userGroupForm" (ngSubmit)="onSubmit()">
            <div class="form-group">

                <div class="row ">

                    <div class="col-lg-6 pt-2 no-gutters">

                        <div class="row">

                        <label class="font-bold company-name-label" for="companyName"> {{'HOME.CompanyName'| translate}}
                            : &nbsp;</label>

                        <select class=" form-control  company-name-dropdown" formControlName="companyName"
                            [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }"
                            [attr.disabled]="this.isLoggedInUserCustomerAdmin">
                            
                            <!-- <option class="validationMessage" [ngValue]="null" disabled> {{'HOME.selectcompanyname'| translate}}
                            </option> -->
                            <option value="">{{'HOME.selectcompanyname'| translate}}</option>                           
                            <option *ngFor="let companyData of companyList" [ngValue]="companyData.CustomerID">
                                {{ companyData.CustomerName }}
                            </option>
                        </select>

                        <div *ngIf="submitted && f.companyName.errors" class=" companyValidationMessage invalid-feedback">
                            <div *ngIf="f.companyName.errors.required"> {{'HOME.Companynameisrequired'| translate}}
                            </div>
                        </div>

                        </div>

                    </div>

                    <div class="col-lg-6 pt-2 no-gutters">

                            <div class="row">
        
                            <label class="font-bold userGroup-name-label" for="UserGroupName"> {{'HOME.Usergroupname'| translate}}
                                : &nbsp; </label>
        
                            <input type="text" placeholder=" {{'HOME.Enterusergroupname'| translate}}" formControlName="UserGroupName"
                                class=" form-control company-name-dropdown addUserSelect input-card"
                                [ngClass]="{ 'is-invalid': submitted && f.UserGroupName.errors }" />
                               
                            <div *ngIf="submitted && f.UserGroupName.errors" class="invalid-feedback userGroupValidationMessage">
                                <div *ngIf="f.UserGroupName.errors.required"> {{'HOME.Usergroupnameisrequired'| translate}}
                                </div>
                            </div>
        
                            </div>
                            
                        </div>

                </div>

            </div>

        </form>

        <br>




        <span class="bold-font">
            {{'HOME.Systemmenuaccess'| translate}}

        </span>
        <div class="underline-data">
        </div>

        



        <div class="row">

            <div class="col-lg-12">

                <div class="add-user-group-table scrollbar-ripe-malinka scrollbar-add-user">
                    <table mat-table #firstTable #firstTableSort="matSort" [dataSource]="myFirstTableData" matSort>
                        <ng-container matColumnDef="MenuName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                [ngClass]="'mat-table-column-header mat-column-name mat-table-column-header-text'">
                                {{'HOME.Menu'| translate}}</th>
                            <td mat-cell *matCellDef="let element" [ngClass]="'mat-column-name mat-table-column-row-text'">
                                {{'HOME.'+getStringWithoutSpaces(element.MenuName) | translate }}</td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef
                                [ngClass]="'mat-table-column-header mat-column-name mat-table-column-header-text'">
                                {{'HOME.Add/Remove'| translate}}
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="'mat-column-name mat-table-column-row-text'">
                                <span class="mat-table-actions-padding">
                                    <span>
                                        <input class="checkbox-add-remove" type="checkbox" [(ngModel)]="element.AccessFlag">
                                    </span>
                                </span>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>

                <!-- Uncomment for new menu tree structure -->
                <!-- <div class="add-user-group-table scrollbar-ripe-malinka scrollbar-add-user">
                    <table mat-table #firstTable #firstTableSort="matSort" [dataSource]="myFirstTableData" matSort
                        class="table-bottom-border">
                        <ng-container matColumnDef="expandCollapseIcon">
                            <th mat-header-cell *matHeaderCellDef class="expand-collapse-icon-column"
                                [ngClass]="'mat-table-column-header mat-column-name mat-table-column-header-text'">
                                <span (click)="expandCollapseAllMenus()">
                                    <span *ngIf="!isAllMenusCollapsed" title="{{'HOME.Collapseall'| translate}}">
                                        <i class="fas fa-minus icon-size white-color-icon"></i>
                                    </span>
                                    <span *ngIf="isAllMenusCollapsed" title="{{'HOME.Expandall'| translate}}">
                                        <i class="fas fa-plus icon-size white-color-icon"></i>
                                    </span>
                                </span>
                            </th>
                            <td mat-cell *matCellDef="let element" class="expand-collapse-icon-column">
                                <span (click)="expandCollapseMenu(element)">
                                    <span *ngIf="element.isExpanded && !element.ParentMenuId && hasChildren(element['MenuId'])"
                                        title="{{'HOME.Collapse'| translate}}">
                                        <i class="fas fa-minus icon-size"></i>
                                    </span>
                                    <span *ngIf="!element.isExpanded && !element.ParentMenuId && hasChildren(element['MenuId'])"
                                        title="{{'HOME.Expand'| translate}}">
                                        <i class="fas fa-plus icon-size"></i>
                                    </span>
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef
                                [ngClass]="'mat-table-column-header mat-column-name mat-table-column-header-text'"
                                class="checkbox-column">
                                <span>
                                    <input class="select-all-checkbox" type="checkbox" [(ngModel)]="isAllMenusSelected"
                                        (click)="selectAllMenusToSave()" title="{{'HOME.Selectall'| translate}}">
                                </span>
                            </th>
                            <td mat-cell *matCellDef="let element" [class]="'mat-column-name mat-table-column-row-text'"
                                [ngClass]="{'pad-left-3': element.ParentMenuId}" class="checkbox-column">
                                <span class="mat-table-actions-padding">
                                    <span (click)="selectUnselectMenu(element)">
                                        <input class="checkbox-add-remove" type="checkbox" [(ngModel)]="element.AccessFlag"
                                            title="{{'HOME.SELECT'| translate}}">
                                    </span>
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="MenuName">
                            <th mat-header-cell *matHeaderCellDef
                                [ngClass]="'mat-table-column-header mat-column-name mat-table-column-header-text'" class="menu-column">
                                {{'HOME.Menu'| translate}}
                            </th>
                            <td mat-cell *matCellDef="let element" [class]="'mat-column-name mat-table-column-row-text'"
                                [ngClass]="{'pad-left-3': element.ParentMenuId}" class="menu-column">
                                {{'HOME.'+getStringWithoutSpaces(element.MenuName) | translate }}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            [ngClass]="{'hidden-row': row.isHidden, 'parent-row': !row.ParentMenuId, 'child-row': row.ParentMenuId, 'row-top-border': !row.ParentMenuId }">
                        </tr>
                
                    </table>
                </div> -->

            </div>

        </div>


        <br>   



        <div [hidden]="!groupId">

            <span class="bold-font">
                {{'HOME.User'| translate}}

            </span>
            <div class="underline-data">
            </div>

            <br>

            <div class="row">

                <div class="col-lg-12">
                    <!-- <div [hidden]="(!isRequestCompleted || (dataSource1.data && dataSource1.data.length))" class="no-records-message pt-3 pl-2">No records found!</div>    
                    <div [hidden]="(!isRequestCompleted || (!dataSource1.data || (dataSource1.data && !dataSource1.data.length)))" class="manage-user-table scrollbar-ripe-malinka scrollbar-add-user forecast-user-table"> -->
                    <div [hidden]="(!isRequestCompleted || (mySecondTableData.data && mySecondTableData.data.length))" class="no-records-message pt-3 pl-2"> {{'HOME.Norecordsfound'| translate}}
                        !</div>    
                    <div [hidden]="(!isRequestCompleted || (!mySecondTableData.data || (mySecondTableData.data && !mySecondTableData.data.length)))" class="manage-user-table scrollbar-ripe-malinka scrollbar-add-user forecast-user-table">
                        <!-- <table mat-table [dataSource]="dataSource1" matSort> -->
                            <table
                                mat-table
                                #secondTable
                                #secondTableSort="matSort"
                                [dataSource]="mySecondTableData"
                                matSort>
                            <ng-container matColumnDef="FirstName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    [ngClass]="'mat-table-column-header mat-column-email mat-table-column-header-text'">
                                    {{'HOME.Username'| translate}}
 </th>
                                <td mat-cell *matCellDef="let element"
                                    [ngClass]="'mat-column-name mat-table-column-row-text'">
                                    {{element.FirstName}} {{element.LastName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="EmailID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    [ngClass]="'mat-table-column-header mat-column-email mat-table-column-header-text'">
                                    {{'HOME.Email'| translate}}

                                </th>
                                <td mat-cell *matCellDef="let element"
                                    [ngClass]="'mat-column-customer-name mat-table-column-row-text'">
                                    {{element.EmailID}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                        </table>
                        <!-- </mat-table> -->
                    </div>

                </div>

            </div>

        </div>

        <br>


        <div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-6 no-gutters">
                            <button id="submit" name="submit" class="btn btn-primary saveBtn 
                            action-button" (click)="saveUserDetails()"> {{'HOME.Save'| translate}}
                        </button>
                        </div>
                        <div class="col-lg-6">
                            <button id="submit" name="submit" class="btn btn-primary cancelBtn 
                            action-button" (click)="cancelUserDetails()"> {{'HOME.Cancel'| translate}}
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>