import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';
import { PendingForecastService } from 'src/app/order-management/pending-forecast/pending-forecast.component.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { PriceDataService } from './price-data.service';


@Component({
  selector: 'app-price-data',
  templateUrl: './price-data.component.html',
  styleUrls: ['./price-data.component.css']
})
export class PriceDataComponent implements OnInit {

  date: any;

  dropDownYear = [];
  forecastEntryForm: FormGroup;
  currYear: number;
  priceValue: any = "product-prices";
  currMonth: any;
  weeklyData: any;
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  dataSource2:any = new MatTableDataSource([]);
  dataSource1: any;
  userInfo: any;
  submitted: boolean;
  displayTable: boolean = false;
  priceData: any;
  displayedColumns: string[];
  excelData: any[];

  displayedColumnsProductPrice: string[];
  customerID: any;
  Customers: [];
  customerUserSelected: any;
  customerIdUserSelected: any;
  customerName: string;
  username: string;
  role: any;
  isLoggedInUserAdmin: boolean;
  noDisp: boolean;
  yearUserSelected: any;
  priceVar: any;
  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableTwoPaginator', { static: true }) tableTwoPaginator: MatPaginator;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('productPricesTableSort', { static: true }) public productPricesTableSort: MatSort;
  @ViewChild('adhesiveProductPriceTableSort', { static: true }) public adhesiveProductPriceTableSort: MatSort;
  displayTable1: boolean = false;
  year: number;
  monthlyAvg: unknown;
  exchangeRate: any;
  currentMonth: any;
  currentYear: any;
  currentAvgMonth: any;
  currentAvgYear: any;
  ConversionRate: any;
  BrentRate: any;
  ICEBrentRate: any;
  BIHERate: any;
  type:string ='MONTHLY';
  countryCode: string;
  HSFOUSDPerMT: any;
  HSFOEURPerMT: any;
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;

  constructor(
    private sharedService: AppService,
    private priceDataService: PriceDataService, private pendingForecastService: PendingForecastService,
    private translate: TranslateService,
		private globalSearchService: GlobalSearchService,
    private changeDetectorRef: ChangeDetectorRef) {
    if (this.sharedService.getLoginResponse() == undefined) {
      this.noDisp = true
    } else {
      this.userInfo = this.sharedService.getLoginResponse();
      this.username = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
      this.role = (this.userInfo ? this.userInfo.RoleID : '');
      this.customerID = (this.userInfo ? this.userInfo.CustomerID : '');
      this.customerName = (this.userInfo ? this.userInfo.CustomerName : '');
      this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
      this.noDisp = false
      this.countryCode = this.userInfo.CountryCode;
    }
  }

  async ngOnInit() {
    this.date = this.sharedService.getCurrentDateTime();

    this.currentYear = this.date.getFullYear();
   this.currentMonth = this.months[this.date.getMonth()];

    this.date.toLocaleDateString("en");
    var result = this.getWeekNumber(this.date)
    this.currYear = result[0];
    
    this.getYearList(this.currYear);
    this.currMonth = this.months[0];
    this.yearUserSelected = this.currYear;
    this.monthAvgDropdown();
// this.monthUserSelected = this.currMonth;

    //set customer name
    this.customerUserSelected = this.priceDataService.getCustomerName() ? this.priceDataService.getCustomerName() : this.customerName;
    this.customerIdUserSelected = this.priceDataService.getCustomerId() ? this.priceDataService.getCustomerId() : this.customerID;
    this.priceDataService.setCustomerName(this.customerUserSelected)

    //get customers names
    await this.getCustomers();

    // this.getMonthlyavgDetails();
    // console.log(this.ConversionRate, this.BrentRate, this.ICEBrentRate, this.BIHERate)

    // Auto load data on page load
    this.fetchData1(this.yearUserSelected, this.currentMonth);
        
        this.subscribeToGlobalSearchServiceSearchTerm();
        
  }
  
  ngOnDestroy(): void {
    this.globalSearchText = '';
  }

  ngAfterViewInit(): void {
    this.translate.get(['HOME.itemsPerPageLabel'])
      .subscribe(data => {
        this.tableOnePaginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
        this.tableTwoPaginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
      });
  }

  monthAvgDropdown(){
    var test = new Date('01' + this.currentMonth.toUpperCase().substr(0, 3) + this.yearUserSelected.toString().substr(0, 2));
    if(this.currentMonth === "January"){
      this.currentAvgYear = parseInt(this.yearUserSelected.toString()) - 1;
    } else {
      this.currentAvgYear = this.yearUserSelected;
    }
    if(test.getMonth() === 0){
      this.currentAvgMonth = 'December';
    }
    else{
      this.currentAvgMonth = this.months[test.getMonth() - 1];
    }
  //  this.getMonthlyavgDetails();

    // Auto load data on change of Year / Month in dropdown
    this.fetchData1(this.yearUserSelected, this.currentMonth);
  }

  getYearList(currentYear: number) {
    this.dropDownYear.push(currentYear - 1, currentYear);
  }

  async getMonthlyavgDetails() {

    // Reset values to zero on change of month and year in the dropdown
    this.BrentRate = "0";
    this.ICEBrentRate = "0";
    this.BIHERate = "0";
    this.HSFOUSDPerMT = "0";
    this.HSFOEURPerMT = "0";

    return new Promise<void>((resolve, reject) => {
      this.priceDataService.getMonthlyavgDetails(this.type ,this.yearUserSelected)
        .subscribe(
          response => {
            if (response['ResponseCode']  === '200' && response['ResponseStatus'] 
            && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {

              const priceIndexAndConversionRateCollection = 'PriceIndexAndConversionRateCollection';
              if (response[priceIndexAndConversionRateCollection] && response[priceIndexAndConversionRateCollection].length) {
               // var monthlyIndexCollectionList = [];

               var self = this;
               response[priceIndexAndConversionRateCollection].forEach(function (priceIndexAndConversionRate){
                if ((self.currentMonth.toUpperCase().substring(0, 3) === priceIndexAndConversionRate["Month"].toUpperCase()) && 
                (self.yearUserSelected.toString() === priceIndexAndConversionRate["Year"])) {

                  // console.log('inside loop')

                  self.ConversionRate = priceIndexAndConversionRate["ConversionRate"];
                  if (priceIndexAndConversionRate['PriceNotation'] && priceIndexAndConversionRate['PriceNotation'].length) {
                    for (var priceNotation of priceIndexAndConversionRate['PriceNotation']) {
                      if (priceNotation['Name'] === 'BRENT') {
                        self.BrentRate = priceNotation['Rate'];                              
                      }
                      if (priceNotation['Name'] === 'BIHE : ICE BRENT') {
                        self.ICEBrentRate = priceNotation['Rate'];                              
                      } 
                      if (priceNotation['Name'] === 'BIHE') {
                        self.BIHERate = priceNotation['Rate'];                              
                      }
                      if (priceNotation['Name'] === 'HSFO' && priceNotation['UOM'] === 'USD/MT') {
                        self.HSFOUSDPerMT = priceNotation['Rate'];
                      }
                      if (priceNotation['Name'] === 'HSFO' && priceNotation['UOM'] === 'EUR/MT') {
                        self.HSFOEURPerMT = priceNotation['Rate'];
                      }
                    }
                  }
            }
               })

                /* for (var priceIndexAndConversionRate of response[priceIndexAndConversionRateCollection]) {
                  
                  if ((test.currentMonth.toUpperCase().substring(0, 3) === priceIndexAndConversionRate["Month"]) && 
                      (test.yearUserSelected.toString() === priceIndexAndConversionRate["Year"])) {

                        // console.log('inside loop')

                        test.ConversionRate = priceIndexAndConversionRate["ConversionRate"];
                        if (priceIndexAndConversionRate['PriceNotation'] && priceIndexAndConversionRate['PriceNotation'].length) {
                          for (var priceNotation of priceIndexAndConversionRate['PriceNotation']) {
                            if (priceNotation['Name'] === 'BRENT') {
                              test.BrentRate = priceNotation['Rate'];                              
                            }
                            if (priceNotation['Name'] === 'ICE BRENT') {
                              test.ICEBrentRate = priceNotation['Rate'];                              
                            } 
                            if (priceNotation['Name'] === 'BIHE') {
                              test.BIHERate = priceNotation['Rate'];                              
                            } 
                          }
                        }
                  }                  
                } */
              }
              // Reset any data already entered in form
              //   this.reset();
              //   this.dataSourceForMonthlyTable.data = monthlyIndexCollectionList;
              //   this.isRequestCompleted = true;
              
              //console.log('this.BrentRate::' + this.BrentRate)
              //console.log('this.ICEBrentRate::' + this.ICEBrentRate)
              //console.log('this.BIHERate::' + this.BIHERate)
              resolve();
            }
            else {
              // this.displayTable= false;
              //reject();
            }
          }
        )
    })
  }


  get fields() {
    return this.forecastEntryForm.controls;
  }
 



  getWeekNumber(d) {

    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

    var yearStart
    yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);

    return [d.getUTCFullYear(), weekNo];
  }

  async getCustomers() {
    this.Customers = [];
    return new Promise<void>((resolve, reject) => {
      this.priceDataService.getCustomerNames(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {

            this.Customers = res["Customers"]
            this.customerUserSelected = this.pendingForecastService.getPCustomerName() ? this.pendingForecastService.getPCustomerName() : this.customerName;
            this.customerIdUserSelected = this.pendingForecastService.getPCustomerId() ? this.pendingForecastService.getPCustomerId() : this.customerID;

            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  changeOnClick() {

    this.priceDataService.setCustomerName(this.customerUserSelected);
    this.priceDataService.setCustomerId(this.customerIdUserSelected);

    // Auto load data on change of Customer in dropdown
    this.fetchData1(this.yearUserSelected, this.currentMonth);
  }
  
  async fetchOnClick() {
    this.priceDataService.setCustomerName(this.customerUserSelected)
    this.priceDataService.setCustomerId(this.customerIdUserSelected)


  }

  toggleFuntion() {

    this.dataSource2 = [];
    this.dataSource1 = [];
    this.displayedColumns = [];
    this.displayedColumnsProductPrice = [];
    this.displayTable = false;
    this.displayTable1 = false;

    // Auto load data on tab change
    this.fetchData1(this.yearUserSelected, this.currentMonth);

  }

  fetchData1(year, month){
    this.BrentRate = [''];
    this.ConversionRate = [''];
    this.ICEBrentRate = [''];
    this.BIHERate = [''];
    this.HSFOUSDPerMT = [''];
    this.HSFOEURPerMT = [''];
    this.fetchData();
    this.yearUserSelected = year;
    this.currentMonth = month;
    this.getMonthlyavgDetails();
    // await this.getMonthlyavgDetails();
    // console.log(month, this.ConversionRate, this.BrentRate, this.ICEBrentRate, this.BIHERate)

  }

  async fetchData() {
    return new Promise(async (resolve, reject) => {
      if (this.priceValue == "product-prices") {
        try {
          var ProductPriceArr = await this.fetchProductPriceData();
          resolve(ProductPriceArr);
        } catch (error) {
          reject();
        }
      } else if (this.priceValue == "adhesive-price") {
        try {

          var adhesivePriceArr = await this.fetchAdhesivePriceData()
          resolve(adhesivePriceArr);
        } catch (error) {
          reject(error);
        }

      }

    })
    // '31-' + }
  }

  async fetchProductPriceData() {
    this.dataSource1 = [];
    this.displayedColumns = [];

    // Commented code for effective date, since its format is now changed to yyyy-mm-dd
    // let EffectiveDate = '01-' + this.currentMonth.trim().substr(0, 3).toUpperCase() + '-' + this.yearUserSelected.toString().substr(2, 4);

    let effectiveDate = this.yearUserSelected.toString() + '-' + this.getMonthNumberByName(this.currentMonth.trim().substr(0, 3).toUpperCase()) + '-' + '01';

    return new Promise((resolve, reject) => {
      this.priceDataService.getProductPrice(this.customerIdUserSelected, effectiveDate, this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] == 200) {
            let priceJson1 = [];
            let depotData = res['DepotCollection'];
            this.priceData = res['ProductPricesData'];
            let array = ['ProductName'];
            depotData.forEach(function (item) {
              array.push(item.DepotName);

            })
            this.displayedColumnsProductPrice = array;

            this.priceData.forEach(function (item) {
              var obj = {};
              array.forEach(function (item) {
                obj[item] = ''
              });
              obj[array[0]] = item.ProductName;
              let priceArray = item.DepotPrice;
              priceArray.forEach(function (depots) {
                array.forEach(function (item) {
                  if (item == depots.DepotName) {
                    obj[item] = depots.Price;
                  }
                })
              })

              priceJson1.push(obj);
            })

            let tableData1 = priceJson1;

            this.excelData = tableData1;
            this.dataSource2 = new MatTableDataSource(tableData1);

            this.dataSource2.sort = this.productPricesTableSort;
            this.displayTable = true;
            this.dataSource2.paginator = this.tableOnePaginator;

          }

          else {
            this.displayTable1 = false;
            this.displayTable = false;
          }


        });

    });
  }


  async fetchAdhesivePriceData() {
    this.dataSource2 = [];
    this.displayedColumnsProductPrice = [];
    // this.displayedColumns = [];

    // Commented code for effective date, since its format is now changed to yyyy-mm-dd
    // let EffectiveDate = '01-' + this.currentMonth.trim().substr(0, 3).toUpperCase() + '-' + this.yearUserSelected.toString().substr(2, 4);

    let effectiveDate = this.yearUserSelected.toString() + '-' + this.getMonthNumberByName(this.currentMonth.trim().substr(0, 3).toUpperCase()) + '-' + '01';

    return new Promise((resolve, reject) => {
      this.priceDataService.getAdhesivePrice(this.customerIdUserSelected, effectiveDate, this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {

            let priceJson = [];
            let productData = res['ProductCollection'];
            this.priceData = res['AdhesiveProductPricesData'];
            let array = ['AdhesivePercent'];
            productData.forEach(function (item) {
              array.push(item.ProductName);

            })
            this.displayedColumns = array;
            /*this.priceData.forEach(function (item) {
              var obj = {};
              array.forEach(function (item) {
                obj[item] = ''
              });

              obj[array[0]] = item.AdhesivePercent;
              obj[item.ProductName] = item.TotalPrice;



              priceJson.push(obj);
            })


            let tableData = priceJson;

            this.displayTable1 = true;
            this.displayTable = false;
            this.excelData = tableData;
            this.dataSource1 = new MatTableDataSource(tableData);

            this.dataSource1.sort = this.adhesiveProductPriceTableSort;

            this.dataSource1.paginator = this.tableTwoPaginator; */

            let priceDataObjForAdhesive3Percent = {};
            priceDataObjForAdhesive3Percent['AdhesivePercent'] = '0.3';
            let priceDataObjForAdhesive4Percent = {};
            priceDataObjForAdhesive4Percent['AdhesivePercent'] = '0.4';
            let priceDataObjForAdhesive5Percent = {};
            priceDataObjForAdhesive5Percent['AdhesivePercent'] = '0.5';
            let priceDataObjForAdhesive6Percent = {};
            priceDataObjForAdhesive6Percent['AdhesivePercent'] = '0.6';
            let priceDataObjForAdhesive7Percent = {};
            priceDataObjForAdhesive7Percent['AdhesivePercent'] = '0.7';
            let priceDataObjForAdhesive8Percent = {};
            priceDataObjForAdhesive8Percent['AdhesivePercent'] = '0.8';
            let priceDataObjForAdhesive9Percent = {};
            priceDataObjForAdhesive9Percent['AdhesivePercent'] = '0.9';

            this.priceData.forEach(function (item: any) {
              if (item['AdhesivePercent'] === '0.3') {
                priceDataObjForAdhesive3Percent[item.ProductName] = item.TotalPrice;
              }
              if (item['AdhesivePercent'] === '0.4') {
                priceDataObjForAdhesive4Percent[item.ProductName] = item.TotalPrice;
              }
              if (item['AdhesivePercent'] === '0.5') {
                priceDataObjForAdhesive5Percent[item.ProductName] = item.TotalPrice;
              }
              if (item['AdhesivePercent'] === '0.6') {
                priceDataObjForAdhesive6Percent[item.ProductName] = item.TotalPrice;
              }
              if (item['AdhesivePercent'] === '0.7') {
                priceDataObjForAdhesive7Percent[item.ProductName] = item.TotalPrice;
              }
              if (item['AdhesivePercent'] === '0.8') {
                priceDataObjForAdhesive8Percent[item.ProductName] = item.TotalPrice;
              }
              if (item['AdhesivePercent'] === '0.9') {
                priceDataObjForAdhesive9Percent[item.ProductName] = item.TotalPrice;
              }
            })

            priceJson.push(priceDataObjForAdhesive3Percent);
            priceJson.push(priceDataObjForAdhesive4Percent);
            priceJson.push(priceDataObjForAdhesive5Percent);
            priceJson.push(priceDataObjForAdhesive6Percent);
            priceJson.push(priceDataObjForAdhesive7Percent);
            priceJson.push(priceDataObjForAdhesive8Percent);
            priceJson.push(priceDataObjForAdhesive9Percent);

            let tableData = priceJson;

            this.displayTable1 = true;
            this.displayTable = false;
            this.excelData = tableData;
            this.dataSource1 = new MatTableDataSource(tableData);

            this.dataSource1.sort = this.adhesiveProductPriceTableSort;

            this.dataSource1.paginator = this.tableTwoPaginator;

          }
          else {
            this.displayTable1 = false;
            this.displayTable = false;
          }
        });

    });

  }

  downloadAdhesiveProductPriceData() {
    // Commented old code, kept for future reference
    // this.pendingForecastService.exportAsExcelFile(this.excelData, "AdhesiveProductPriceList");

    let adhesivePriceDataList: any = [];
    if (this.excelData && this.excelData.length) {
      for (let adhesivePriceData of this.excelData) {
        let adhesivePriceDataObjectForExcelDownload: any = {};
        adhesivePriceDataObjectForExcelDownload['Content m - %'] = adhesivePriceData['AdhesivePercent'];
        for (let adhesivePriceDataObject in adhesivePriceData) {
          if (adhesivePriceDataObject !== 'AdhesivePercent') {
            adhesivePriceDataObjectForExcelDownload[adhesivePriceDataObject] = adhesivePriceData[adhesivePriceDataObject];
          }
        }
        adhesivePriceDataList.push(adhesivePriceDataObjectForExcelDownload);
      }
    }

    let headerList = ["Content m - %"];

    this.sharedService.downloadDataInExcelFile(adhesivePriceDataList, 'AdhesiveProductPriceList', headerList);
  }

  downloadProductPriceData() {
    // Commented old code, kept for future reference
    // this.pendingForecastService.exportAsExcelFile(this.excelData, "ProductPriceList");

    let priceDataList: any = [];
    if (this.excelData && this.excelData.length) {
      for (let priceData of this.excelData) {
        let priceDataObjectForExcelDownload: any = {};
        priceDataObjectForExcelDownload['Product name'] = priceData['ProductName'];
        for (let priceDataObject in priceData) {
          if (priceDataObject !== 'ProductName') {
            priceDataObjectForExcelDownload[priceDataObject] = priceData[priceDataObject];
          }
        }
        priceDataList.push(priceDataObjectForExcelDownload);
      }
    }

    let headerList = ["Product name"];

    this.sharedService.downloadDataInExcelFile(priceDataList, 'ProductPriceList', headerList);

  }

  getMonthNumberByName(monthName: string): string {
    var months = {
      'JAN': '01',
      'FEB': '02',
      'MAR': '03',
      'APR': '04',
      'MAY': '05',
      'JUN': '06',
      'JUL': '07',
      'AUG': '08',
      'SEP': '09',
      'OCT': '10',
      'NOV': '11',
      'DEC': '12'
    }
    return months[monthName];
  }

  getShortMonthName(monthName: string): string {
    let shortMonthName = '';
    if (this.currentAvgMonth) {
      shortMonthName = this.currentAvgMonth.toUpperCase().substr(0, 3);
    }
    return shortMonthName;
  }

  getPreviousMonthName(): string {
    let previousMonthName = '';
    if (this.currentMonth) {
      let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let fullMonthName = months[(months.indexOf(this.currentMonth) + 12 - 1) % 12];
      previousMonthName = (fullMonthName ? fullMonthName.toUpperCase().substr(0, 3) : '');
    }
    return previousMonthName;
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.changeDetectorRef.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.changeDetectorRef.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource2.filter = filterValue;
      this.displayTable = (this.dataSource2 && this.dataSource2.filteredData && this.dataSource2.filteredData.length) ? true : false;
    }
  }

}