import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-distribution-program',
  templateUrl: './distribution-program.component.html',
  styleUrls: ['./distribution-program.component.css']
})
export class DistributionProgramComponent implements OnInit {
  distributionInformationData: any;
  productType: any;
  productSubType: any;
  depotLocation: any;
  productTypeToSearch: any;
  productSubTypeToSearch: any;
  depotLocationToSearch: any;
  distributionData: any;
  tableInformation: any;
  countrySearch: any;
  countryList: any;
  constructor() { }

  ngOnInit() {
    // this.countryList={no:'Norway',dk:'Denmark',se:'Sweden',fi:'Finland'};
    this.countryList={fi:'Finland'};
    this.countrySearch='fi';
    this.productTypeToSearch = '';
    this.productSubTypeToSearch = '';
    this.depotLocationToSearch = '';
    this.productSubType = ['All', 'Regular', 'Extra'];
    this.productType = ['All', 'Paving Bitumen', 'Adhesive road bitumens'];
    this.depotLocation = ['All', 'Naantali', 'Hamina', 'Rauma'];
    this.tableInformation = [{
      productType: 'Paving Bitumen',
      tableColumns: [{ "productName": 'PRODUCT NAME' }, { "naanTaliAvailability": 'NAANTALI' }, { "haminaAvailability": 'HAMINA' }],
      tabledata: [{
        productSubType: 'Regular',
        productsData: [{
          productName: "Nyn 50/70",
          naanTaliAvailability: "1.4. - 29.11. ",
          haminaAvailability: "  "
        },
        {
          productName: "Nyn 70/100",
          naanTaliAvailability: "1.4. - 29.11. ",
          haminaAvailability: "2.5. - 30.9. "
        },
        {
          productName: "Nyn 100/150",
          naanTaliAvailability: "1.4. - 29.11. ",
          haminaAvailability: "2.5. - 30.9. "


        }]
      },
      {
        productSubType: 'Extra',
        productsData: [{
          productName: "Nynas 160/220",
          naanTaliAvailability: "1.4. - 29.11. ",
          haminaAvailability: "  "
        },
        {
          productName: "Nynas 250/330",
          naanTaliAvailability: "1.4. - 29.11. ",
          haminaAvailability: "2.5. - 30.9. "
        }]
      }

      ]

    },
    //data 2nd table
    {
      productType: 'Adhesive Road Bitumens',
      tableColumns: [{ "productName": 'PRODUCT NAME' }, { "naanTaliAvailability": 'NAANTALI' }],
      tabledata: [{
        productSubType: 'Extra',
        productsData: [{
          productName: "Nypave 380A",
          naanTaliAvailability: "1.4. - 29.11. "
        },
        {
          productName: "Nypave 575A",
          naanTaliAvailability: "1.4. - 29.11. "
        },
        {
          productName: "Nypave 775A",
          naanTaliAvailability: "1.4. - 29.11. "



        },
        {
          productName: "Nybit V12000A",
          naanTaliAvailability: "1.4. - 29.11. "



        },
        {
          productName: "Nybit V6000A",
          naanTaliAvailability: "1.4. - 29.11. "



        },
        {
          productName: "Nybit V3000A",
          naanTaliAvailability: "1.4. - 29.11. "



        },
        {
          productName: "Nybit V1500A",
          naanTaliAvailability: "1.4. - 29.11. "



        }
        ]
      }

      ],
      staticInformation: 'A after the name means that the product is contagious.' + '<br>' + 'The minimum content of the additive is 0.7 m-% and maximum content is 0.9 m-%',

    },
    // 3rd Table data
    {
      productType: 'Bituminous solutions and fluxed Bitumens',
      tableColumns: [{ "productName": 'PRODUCT NAME' }, { "adhesionAgent": 'SOMETIMES  ADHESION AGENT' }, { "naanTaliAvailability": 'NAANTALI' }],
      tabledata: [{
        productSubType: 'Regular',
        productsData: [{
          productName: "Nynas BL0S",
          adhesionAgent: '0.9 %',
          naanTaliAvailability: "1.4. - 29.11. "
        },
        {
          productName: "Nynas BL5",
          naanTaliAvailability: "23.5. - 30.9. "
        },
        {
          productName: "Nynas BL5S",
          adhesionAgent: '0.3% to 0.9 %',
          naanTaliAvailability: "13.5. - 29.11. "



        },
        {
          productName: "Nybit V12000A",
          naanTaliAvailability: "1.4. - 29.11. "



        }
        ]
      },
      {
        productSubType: 'Extra',
        productsData: [{
          productName: "NyFlow Fv600",
          adhesionAgent: '0.9 %',
          naanTaliAvailability: "1.4. - 29.11. "
        },

        {
          productName: "NyFlow Fv600A",
          adhesionAgent: '0.3% to 0.9 %',
          naanTaliAvailability: "13.5. - 29.11. "



        },
        {
          productName: "Nybit V12000A",
          naanTaliAvailability: "1.4. - 29.11. "



        }
        ]
      },

      ],


    },
    //4rth  Table Data
    {
      productType: 'Industrial Applications',
      tableColumns: [{ "productName": 'PRODUCT NAME' }, { "deliveryInformation": 'DELIVERY POINT AND TIME' }],
      tabledata: [{
        productSubType: 'Extra',
        productsData: [{
          productName: "Nyspec 50/70 -IND",
          deliveryInformation: "Naantali, Jan 1 - Dec "
        },
        {
          productName: "Nyspec 190 -IND",
          deliveryInformation: "Naantali, Jan 1 - Dec "
        },



        ]
      }

      ],
      staticInformation: 'The availability of solution is confirmed on order.' + '<br>' + 'A or S after the name means that the product is contagious.' + '<br>' + 'The BL0S has a tank content of 0.9% by weight',

    },



    ];



    this.distributionInformationData = [
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: "Nyn 50/70",
        productNumber: '180020',
        adhesionAgent: '',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: "Nyn 70/100",
        productNumber: '180020',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: '2-May', availabilityToDate: '30-Sept' }

        ]


      },
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: "Nyn 100/150",
        productNumber: '180020',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: '2-May', availabilityToDate: '30-Sept' }

        ]


      },
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: "Nynas 160/220",
        productNumber: '180020',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: '2-May', availabilityToDate: '30-Sept' }

        ]


      },
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: "Nynas 250/330",
        productNumber: '180020',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: '2-May', availabilityToDate: '30-Sept' }

        ]


      },
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: "Nynas 330/430",
        productNumber: '180020',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: "Nynas 500/650",
        productNumber: '180020',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: "Nynas 650/900",
        productNumber: '180020',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: " Nynas V12000",
        productNumber: '180020',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: "Nynas V6000",
        productNumber: '180020',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: "Nynas V3000",
        productNumber: '180020',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Paving Bitumen",
        productSubType: "Regular",
        productDescription: "Nynas V1500",
        productNumber: '180020',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },



      {
        productType: "Adhesive road bitumens",
        productSubType: "Extra",
        productDescription: "Nypave 380A",
        productNumber: '180029',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Adhesive road bitumens",
        productSubType: "Extra",
        productDescription: "Nypave 575A",
        productNumber: '180029',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Adhesive road bitumens",
        productSubType: "Extra",
        productDescription: "Nypave 775A",
        productNumber: '180029',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Adhesive road bitumens",
        productSubType: "Extra",
        productDescription: "Nybit V12000A",
        productNumber: '180029',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Adhesive road bitumens",
        productSubType: "Extra",
        productDescription: "Nybit V6000A",
        productNumber: '180029',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Adhesive road bitumens",
        productSubType: "Extra",
        productDescription: "Nybit V3000A",
        productNumber: '180029',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Adhesive road bitumens",
        productSubType: "Extra",
        productDescription: "Nybit V1500A",
        productNumber: '180029',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Bituminous solutions and fluxed bitumens",
        productSubType: "Regular",
        productDescription: "Nynas BL0S",
        productNumber: '180029',
        adhesionAgent: '0.3 to 0.9%',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]
      },
      {
        productType: "Bituminous solutions and fluxed bitumens",
        productSubType: "Regular",
        productDescription: "Nynas BL5",
        productNumber: '180029',
        adhesionAgent: '0.3 to 0.9%',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Bituminous solutions and fluxed bitumens",
        productSubType: "Extra",

        productDescription: "Nyflow Fv 600",
        productNumber: '180029',
        adhesionAgent: '',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Bituminous solutions and fluxed bitumens",
        productSubType: "Extra",

        productDescription: "Nyflow Fv 600A",
        productNumber: '180029',
        adhesionAgent: '0.3 to 0.9%',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-April', availabilityToDate: '29-Nov' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Industrial Applications",
        productSubType: "Extra",

        productDescription: " Nyspec 50/70-IND",
        productNumber: '180029',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-Jan', availabilityToDate: '31-Dec' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      },
      {
        productType: "Industrial Applications",
        productSubType: "Extra",
        productDescription: "Nyspec 190-IND",
        productNumber: '180029',
        depotInformation: [
          { depot: 'Naantali', availabilityFromDate: '1-Jan', availabilityToDate: '31-Dec' },
          { depot: 'Hamina', availabilityFromDate: ' ', availabilityToDate: ' ' }

        ]


      }];

    //this.distributionData = this.distributionInformationData.filter(data => { return data.productType == 'Paving Bitumen'; });

    // console.log("paving data", this.distributionData);

    

  }

  getTableCSS(data){
   let flag=true;
   data.tabledata.forEach(element => {
     if(element.productSubType=='Regular'){
      flag=false;
     }
   });

   if(flag){return 'extra-color';}else{return 'regular-color';}
  }

}
