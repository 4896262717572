import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderTrackingService {

  
  apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

 
  getCustomerNames(countryCode) {
      let url = this.apiUrl + '/getCustomer';
      return this.httpClient.get(url, {
          params: {
            CountryCode: countryCode,
          }
        });
  
}
getDeliverySites(customerId: string, countryCode: string) {
  const url = this.apiUrl + '/FetchDeliverySiteService';
  return this.httpClient
    .get(url, {
      params: {
        CustomerId: customerId,
        ApprovalStage: 'Inactive',
        CountryCode: countryCode
      }
    });
}

getDeliverySitesForUSER(USERID: string, customerId: string, countryCode: string) {
  const url = this.apiUrl + '/GetDeliverySiteForUser';
  return this.httpClient
    .get(url, {
      params: {
        UserID: USERID,
        CountryCode: countryCode,
        MappingStatus: 'ACTIVE',
        CustomerID: customerId
      }
    });
}

getProductInformationFORUSER(CustomerID: string, countryCode: string ,jdeAddNum : string) {
  const serviceUrl = this.apiUrl + '/GetDeliverySiteProducts';

  return this.httpClient
    .get(serviceUrl, {
      params: {
        CustomerID: CustomerID,
        CountryCode: countryCode,
        JDEAddressNumber:jdeAddNum
        

      }
    });
}  


getProductInformation(CustomerID, countryCode) {
  const serviceUrl = this.apiUrl + '/GetAllProduct';

  return this.httpClient
    .get(serviceUrl, {
      params: {
        Status:'ACTIVE',
        Country: countryCode,
        CustomerID: CustomerID

      }
    });
}
getPortalOrderTrackDetails(CustomerID, countryCode,jdeAddNum ,productId, reqDate,refNum,orderStatus){
  const serviceUrl = this.apiUrl + '/TrackOrder';

  return this.httpClient
  .get(serviceUrl, {
    params: {
      CustomerID: CustomerID,
      CountryCode: countryCode, 
   JDEAddressNumber: jdeAddNum ,
   ProductID: productId,
   RequestedDate: reqDate,
    ReferenceNumber: refNum,
    OrderStatus:orderStatus
     

    }
  })
}
getPortalOrderTrackDetailsUser(userID,CustomerID, countryCode,jdeAddNum ,productId, reqDate,refNum,orderStatus){
  const serviceUrl = this.apiUrl + '/TrackOrderForUser';

  return this.httpClient
  .get(serviceUrl, {
    params: {
      UserID: userID,
      CustomerID: CustomerID,
      CountryCode: countryCode, 
   JDEAddressNumber: jdeAddNum ,
   ProductID: productId,
   RequestedDate: reqDate,
    ReferenceNumber: refNum,
    OrderStatus:orderStatus
     

    }
  })

}
getJDEOrderTrackDetails(custId,countryCode ,productId,productName,jdeOrderNumber, orderStatus,jdeAddNum){
  const serviceUrl = this.apiUrl + '/TrackJDEOrder';

  const httpOptions = {
    headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    })
};

let postData = JSON.stringify({

       OrderCompany : "",
        SummaryDetail : "S",
        OrderType : "",
        ProductName : productName,
        ProductID : productId,
        CustomerNumber : custId,
        LoadingDepot : "",
        OrderNo: jdeOrderNumber,
        DeliveryDate : "",
        CustomerPO : "",
        OrderDate : "",
        OpenClosed : orderStatus,
        CountryCode : countryCode,
        DeliverySite:jdeAddNum
  
});
return this.httpClient.post(serviceUrl, postData, httpOptions).pipe();
}
getJDEOrderDetails(OrderNumber : string , CountryCode:string){
  const serviceUrl = this.apiUrl + '/GetJDEOrderFromPortal';

  return this.httpClient
  .get(serviceUrl, {
    params: {
      OrderNumber:OrderNumber,
      CountryCode:CountryCode, 
    }
  })

}
getPortalOrderDetails(RefNum:string, CountryCode:string){
  const serviceUrl = this.apiUrl + '/GetRefNoDetailsFromPortal';

  return this.httpClient
  .get(serviceUrl, {
    params: {
      ReferenceNumber:RefNum,
      CountryCode:CountryCode, 
    }
  })

}

  updateLockStatusForOrders(referenceId: string, closeCode: string, zone: string, loggedInUserId: string) {
    const serviceUrl = this.apiUrl + '/UpdateLockStatusForOrders';

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };

    let postData = JSON.stringify({
      Reference_ID: referenceId,
      Close_Code: closeCode,
      Zone: zone,
      LoggedInUser: loggedInUserId
    });

    return this.httpClient.post(serviceUrl, postData, httpOptions).pipe();
  }

  getLockedOrders(referenceId: string, customerId: string, countryCode: string, lockedTime: string) {
    const serviceUrl = this.apiUrl + '/UpdateLockStatusForOrders/FetchFlagForOrders';
    return this.httpClient
      .get(serviceUrl, {
        params: {
          Reference_ID: referenceId,
          Customer_ID: customerId,
          Country: countryCode,
          Locked_Time: lockedTime
        }
      })
  }

  updateUnlockStatusForOrders(referenceIdList: any, loggedInUserId: string) {
    const serviceUrl = this.apiUrl + '/UpdateLockStatusForOrders/UpdateUnLockStatusForOrders';

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };

    let postData = JSON.stringify({
      Reference_ID: referenceIdList,
      LoggedInUser: loggedInUserId
    });

    return this.httpClient.post(serviceUrl, postData, httpOptions).pipe();
  }

}
