<div class="container-fluid sidepadding-none  " [hidden]='!(productsInformationData.length>0)'>
 
    <!-- <div class="col-lg-12">
        <mat-button-toggle-group class="button-group-style " #group="matButtonToggleGroup" [(ngModel)]="view"
            value="Grid">
            <mat-button-toggle value="Grid" ngDefaultControl> {{'HOME.ListView'| translate}}
            </mat-button-toggle>
            <mat-button-toggle value="Table" ngDefaultControl> {{'HOME.GridView'| translate}}
            </mat-button-toggle>
 
        </mat-button-toggle-group>
    </div> -->
    <!-- Grid View Of products Starts Here
    Condition to be checked for toggle logic
    -->
    <div [hidden]='view=="Grid"' class='margin-top-5'>
        <div class="col-lg-12">
            <div class="card card-blue">
 
 
                <div class="row  padding-top">
                    <div class="col-lg-1 ">
                        <span class='paddingl-5 font-items'>{{productsInformationDataCopy.length}}  {{'HOME.Items'| translate}}
                        </span>
                    </div>
                    <div class="col-lg-2 align-right Product_type_label_grid_mv">
                        <label class="font-bold font-small" for="productType"> {{'HOME.pinfo'| translate}}
                        </label>
                    </div>
                    <div class="col-lg-2">
                        <select class="selectDropdown form-control Product_type_dropdown_grid_mv" [(ngModel)]="productTypeToSearch">
 
                            <option *ngFor="let product of productType" [value]="product">
                                {{ product }}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-2 align-right Product_category_label_grid_mv">
                        <label class="font-bold font-small " for="productSubType"> {{'HOME.ProductCategory'| translate}}
                        </label>
                    </div>
                    <div class="col-lg-2">
                        <select class="selectDropdown form-control Product_category_dropdown_grid_mv" [(ngModel)]="productSubTypeToSearch">
 
                            <option *ngFor="let productSub of productSubType;  let in = index;" [value]="productSub">
                                {{ productSub }}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-1 offset-lg-2 find_button_grid_mv">
                        <button type="button" class="btn filter-button" for="filter"
                            (click)=filterProducts(productsInformationData)>
                            <label class="font-bold font-small" for="filter"> {{'HOME.Find'| translate}}
                            </label></button>
                    </div>
 
                </div>
            </div>
 
 
 
        </div>
        <!-- <div class="col-lg-12">
            <div class='row '>
                <div class='col-lg-3'></div>
                <div class='col-lg-3'></div>
                <div class='col-lg-3'></div>
                <div class='col-lg-3'></div>
                <div class='col-lg-12' *ngIf='productsInformationDataCopy.length==0'>
                    No Data Available for Current Filter
                </div>
                <ng-container *ngFor='let productInfo of productsInformationDataCopy; let showIndex=index;'>
                    <div class='col-lg-3 mt-25' [ngClass]='checkDislayFlag(showIndex)'>
                        <div class='card product-card'>
 
                            <div class='row mt-25'>
 
                                <div class='col-lg-12 align-center'>
                                    <span class='font-bold'> {{productInfo.itemDescription}}</span>
                                </div>
                                <div class='col-lg-12 align-center'>
                                    <span class='font-product'>
                                        {{productInfo.productType}}-{{productInfo.productSubType}}</span>
                                </div>
 
 
                            </div>
 
                            <div class='row mt-25'>
 
                                Old code with dynamic data
 
                                <div class='col-reports  padding-none text-center'
                                    *ngFor='let reportsInfo of productInfo.downloadUrls'>
                                    <a class='pdf-content'
                                        (click)='downloadPdf(showIndex,reportsInfo.pdfUrl,productInfo.itemDescription,reportsInfo.Label)'>
                                        <span class='font-vsmall font-bold'> {{reportsInfo.Label}}</span>
                                    </a>
                                </div>
                                <div class='col-lg-4  padding-none text-center'>
                                    <a class='pdf-content'
                                        (click)='downloadPdf(showIndex,productInfo.productInformation,productInfo.itemDescription,productInfo.productInformation)'>
                                        <span class='font-vsmall font-bold'> Product Information</span>
                                    </a>
                                </div>
                                <div class='col-lg-4  padding-none text-center'>
                                    <a class='pdf-content'
                                        (click)='downloadPdf(showIndex,productInfo.productInformation,productInfo.itemDescription,productInfo.productInformation)'>
                                        <span class='font-vsmall font-bold'> Performance Report</span>
                                    </a>
                                </div>
                                <div class='col-lg-4  padding-none text-center'>
                                    <a class='pdf-content'
                                        (click)='downloadPdf(showIndex,productInfo.productInformation,productInfo.itemDescription,productInfo.productInformation)'>
                                        <span class='font-vsmall font-bold'> Quality Certificate</span>
                                    </a>
                                </div>
                                <div class='col-lg-12 mt-25 padding-none text-center'></div>
                                <div class='col-lg-4 offset-lg-2  padding-none text-center'>
 
                                    <a class='pdf-content'
                                        (click)='downloadPdf(showIndex,productInfo.productInformation,productInfo.itemDescription,productInfo.productInformation)'>
                                        <span class='font-vsmall font-bold'>Safety Data</span>
                                    </a>
                                </div>
                                <div class='col-lg-4 offset-lg-2  padding-none text-center'>
 
                                    <a class='pdf-content'
                                        (click)='downloadPdf(showIndex,productInfo.productInformation,productInfo.itemDescription,productInfo.productInformation)'>
                                        <span class='font-vsmall font-bold'>CE Mark</span>
                                    </a>
                                </div>
                            </div>
 
 
 
                        </div>
                    </div>
                </ng-container>
            </div>
 
        </div>
 
        <div class='col-lg-12' *ngIf='productsInformationDataCopy.length>=8'>
            <div class='row mt-25 align-items-center'>
                <div class='col-lg-4 offset-lg-4 '>
                    <button type="button" class="btn btn-primary large-button " for="moreResults"
                        (click)='displayMoreProducts(productsInformationDataCopy)'>
                        <label class="font-bold font-small" for="moreResults">More Products</label></button>
                </div>
 
            </div>
 
        </div> -->
 
        <!-- Grid View Of products Ends Here-->
        <!-- Grid View Of products Starts Here-->
        <div class="scroll-table-content scrollbar-table-content">
            <div class="col-lg-12 ">
                <div class='row '>
                    <div class='col-lg-4'></div>
                    <div class='col-lg-4'></div>
                    <div class='col-lg-4'></div>
 
                    <div class='col-lg-12' *ngIf='productsInformationDataCopy.length==0'>
                        {{'HOME.NQC'| translate}}
 
                    </div>
                    <ng-container *ngFor='let productInfo of productsInformationDataCopy; let showIndex=index;'>
                        <div class='col-lg-4 mt-15' [ngClass]='checkDisplayFlag(showIndex)'>
                            <div class='card product-card'>
 
                                <div class='row mt-15'>
 
                                    <div class='col-lg-12 align-center'>
                                        <span class='font-bold'> {{productInfo.PRODUCT_NAME}}</span>
                                    </div>
                                    <div class='col-lg-12 mt-10 align-center'>
                                        <span class='font-product'>
                                            {{productInfo.PRODUCT_TYPE}}-{{productInfo.PRODUCT_SUB_TYPE}}</span>
                                    </div>
 
 
                                </div>
                                <div class='row mt-15'></div>
                                <div class='row mt-15'>
 
 
                                    <div class='col-reports  padding-none text-center'>
                                        <!-- <a class='pdf-content'
                                            (click)='downloadPdf(showIndex,productInfo.productInformation,productInfo.PRODUCT_NAME,"PW")'>
                                            <span class='font-vsmall font-bold'> Product datasheet</span>
                                        </a> -->
                                        <a class='pdf-content'
                                        (click)='downloadPdf(productInfo.PRODUCT_INFO)'>
 
                                        <ng-container *ngIf='productInfo.PRODUCT_INFO!=""'><span class='font-vsmall font-bold'>
                                            {{'HOME.Pdatasheet'| translate}}
 
 
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf='productInfo.PRODUCT_INFO==""'><span class='font-vsmall font-bold'>
                                       NA
 
                                            </span>
                                        </ng-container>
                                    </a>
                                        <!-- <a href="http://www.pdf995.com/samples/pdf.pdf" download="Productdatasheet">Download file</a> -->
                                    </div>
                                    <div class='col-reports  padding-none text-center'>
                                        <a class='pdf-content'
                                            (click)='downloadPdf(productInfo.PRODUCT_NAME)'>
                                            <ng-container *ngIf='productInfo.SAFETY_DATA!=""'><span class='font-vsmall font-bold'>
                                                {{'HOME. SafetyData'| translate}}    Safety data
 
                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf='productInfo.SAFETY_DATA==""'><span class='font-vsmall font-bold'>
                                               NA
 
 
                                                </span>
                                            </ng-container>
                                        </a>
 
                                    </div>
                                    <div class='col-reports  padding-none text-center'>
                                        <!-- <a class='pdf-content'
                                            (click)='downloadPdf(showIndex,productInfo.qualityCertificate,productInfo.PRODUCT_NAME,"QC")'>
                                            <span class='font-vsmall font-bold'> Quality certificate</span>
                                        </a> -->
                                        <a class='pdf-content'
                                        (click)='getQualityCertificate(productInfo.PRODUCT_ID)'>
                                        <span class='font-vsmall font-bold'>  {{'HOME.Qualityc'| translate}}
                                        </span>
 
                                    </a>
                                    </div>
 
                                    <div class='col-reports offset-lg-2  padding-none text-center'>
                                        <a class='pdf-content'
                                            (click)='downloadPdf(productInfo.PRODUCT_NAME)'>
 
                                            <ng-container *ngIf='productInfo.CE_MARK!=""'><span class='font-vsmall font-bold'>
                                                {{'HOME.CE-Mark'| translate}}
                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf='productInfo.CE_MARK==""'><span class='font-vsmall font-bold'>
                                               NA
 
                                                </span>
                                            </ng-container>
                                        </a>
 
                                    </div>
                                    <div class='col-reports offset-lg-2  padding-none text-center'>
 
 
                                        <a class='pdf-content'
                                            (click)='downloadPdf(productInfo.PRODUCT_NAME)'>
                                            <span class='font-vsmall font-bold'> </span>
                                            <ng-container *ngIf='productInfo.PERFORMANCE_REPORT!=""'><span class='font-vsmall font-bold'>
                                                {{'HOME.PerformanceReport'| translate}}
 
 
                                                </span>
                                            </ng-container>
                                            <ng-container *ngIf='productInfo.PERFORMANCE_REPORT==""'><span class='font-vsmall font-bold'>
                                               NA
 
                                                </span>
                                            </ng-container>
                                        </a>
                                    </div>
                                </div>
 
 
 
                            </div>
                        </div>
                    </ng-container>
                </div>
 
            </div>
            <div class='col-lg-12' *ngIf='productsInformationDataCopy.length>=6'>
                <div class='row  mt-15 align-items-center'>
                    <div class='col-lg-4 offset-lg-4 '>
                        <button type="button" class="btn btn-primary large-button " for="moreResults"
                            (click)='displayMoreProducts(productsInformationDataCopy)'>
                            <label class="font-bold font-small" for="moreResults"> {{'HOME.Moreproducts'| translate}}
                            </label></button>
                    </div>
 
                </div>
 
            </div>
        </div>
        <!-- Grid View Of products Ends Here-->
    </div>
 
 
 
    <!-- Table View Of products Starts Here-->
    <div class='margin-top-5 '>
        <div [hidden]='view=="Table"' class="col-lg-12">
 
 
            <div class="row padding-top">
 
                <!-- <span class='ml-25 font-small'>{{productsInformationDataCopy.length}} Items</span> -->
 
                <div class="col-lg-2 ">
                    <label class="font-bold font-small" for="productType">{{'HOME.pinfo'| translate}}</label>
                </div>
                <div class="col-lg-2">
                    <select class="selectDropdown form-control productType_dropdown_mv " [(ngModel)]="productTypeToSearch">
                        <!-- <option [value]="allProducts" >All Products</option> -->
                        <option [value]="All">{{'HOME.All'| translate}}</option>
                        <option *ngFor="let product of productType" [value]="product">
                            {{product}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-2 align-right ProductCategory_label_mv">
                    <label class="font-bold font-small" for="productSubType">{{'HOME.ProductCategory'| translate}}</label>
                </div>
                <div class="col-lg-2">
                    <select class="selectDropdown form-control ProductCategory_dropdown_mv " [(ngModel)]="productSubTypeToSearch">
                        <!-- <option [value]="allSubProducts" >All SubProducts</option> -->
                        <option [value]="All">{{'HOME.All'| translate}}</option>
                        <option *ngFor="let productSub of productSubType"  [value]="productSub">
                            {{productSub}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-1 offset-lg-3">
                    <button type="button" class="btn filter-btn" for="filter"
                        (click)=filterProducts(productsInformationData)>
                        <label class="font-bold font-small" for="filter">{{'HOME.Find'| translate}}</label></button>
                </div>
 
            </div>
        </div>
        <div [hidden]='view=="Table"' class="col-lg-12  ">
            <div class='row'>
                <div class="mt-5" [hidden]="displayTable">
                    {{'HOME.Norecordsfound'| translate}}
                </div>
                <div class='col-lg-12' *ngIf='productsInformationDataCopy.length==0'>
                    {{'HOME.NDA'| translate}}
                    </div>
                <div class="col-lg-12" [hidden]='!(productsInformationDataCopy.length>0)'>
                    <!--Angular 7 Impl-->
                    <mat-table #table [dataSource]="dataSource" matSort class='table-material horizontal_scrollbar '>
 
                        <!-- Item Column -->
 
                        <ng-container matColumnDef="PRODUCT_NAME">
                            <mat-header-cell class="header-font mat_column_product_name_mv " *matHeaderCellDef mat-sort-header> {{'HOME.Pname'| translate}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray" class=" mat_column_product_name_mv ">
                                {{fetchedDataForItemArray.PRODUCT_NAME}}
                            </mat-cell>
                        </ng-container>
 
                        <ng-container matColumnDef="PRODUCT_TYPE">
                            <!-- With Year Sort<mat-header-cell  class="header-font" *matHeaderCellDef mat-sort-header="Year"> Month-Year -->
                            <mat-header-cell class="header-font mat_column_product_type_mv" *matHeaderCellDef> {{'HOME.pinfo'| translate}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray" class=" mat_column_product_type_mv">
                                {{fetchedDataForItemArray.PRODUCT_TYPE}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="PRODUCT_SUB_TYPE">
                            <mat-header-cell class="header-font " *matHeaderCellDef> {{'HOME.ProductCategory'| translate}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.PRODUCT_SUB_TYPE}}
 
                            </mat-cell>
                        </ng-container>
 
                        <ng-container matColumnDef="productInformation">
                            <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Pdatasheet'| translate}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray;let pdsIndex = index;">
 
                                <!-- <a class='pdf-content'
                                (click)='downloadPdf(pdsIndex,fetchedDataForItemArray.productInformation,fetchedDataForItemArray.PRODUCT_NAME,"PW")'>
                                <span class='font-vsmall font-bold'> Download</span>
                            </a> -->
                            <a class='pdf-content'
                            (click)='downloadPdf(fetchedDataForItemArray.PRODUCT_INFO)'>
 
                            <ng-container *ngIf='fetchedDataForItemArray.PRODUCT_INFO!=""'><span class='font-vsmall font-bold'>
                                {{'HOME.View'| translate}}
 
                                </span>
                            </ng-container>
                            <ng-container *ngIf='fetchedDataForItemArray.PRODUCT_INFO==""'><span class='font-vsmall font-bold'>
                               NA
 
                                </span>
                            </ng-container>
                        </a>
                            </mat-cell>
                        </ng-container>
 
 
                        <ng-container matColumnDef="SAFETY_DATA">
                            <mat-header-cell class="header-font" [ngClass]="{'safety-data-column': (countryCode === 'FIN') }" 
                                *matHeaderCellDef>{{'HOME.Sdata'| translate}}
 
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray;let sdIndex = index;"
                                [ngClass]="{'safety-data-column': (countryCode === 'FIN') }">
 
                                <a class='pdf-content'
                                (click)='downloadPdf(fetchedDataForItemArray.SAFETY_DATA)'>
 
                                <ng-container *ngIf='fetchedDataForItemArray.SAFETY_DATA!=""'><span class='font-vsmall font-bold'>
                                    {{'HOME.View'| translate}}
 
                                    </span>
                                </ng-container>
                                <ng-container *ngIf='fetchedDataForItemArray.SAFETY_DATA==""'><span class='font-vsmall font-bold'>
                                   NA
 
                                    </span>
                                </ng-container>
                            </a>
                            </mat-cell>
                        </ng-container>
 
 
                        <ng-container matColumnDef="CE_MARK">
                            <mat-header-cell class="header-font  " *matHeaderCellDef> {{'HOME.CE-Mark'| translate}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray;let ceIndex = index;"  >
 
                                <a class='pdf-content'
                                (click)='downloadPdf(fetchedDataForItemArray.CE_MARK)'>
 
                                <ng-container *ngIf='fetchedDataForItemArray.CE_MARK!=""'><span class='font-vsmall font-bold'>
                                    {{'HOME.View'| translate}}
 
                                    </span>
                                </ng-container>
                                <ng-container *ngIf='fetchedDataForItemArray.CE_MARK==""'><span class='font-vsmall font-bold'>
                                   NA
 
                                    </span>
                                </ng-container>
                            </a>
                            </mat-cell>
                        </ng-container>
 
                        <ng-container matColumnDef="PERFORMANCE_REPORT">
                            <mat-header-cell class="header-font mat_column_performance_report_mv " *matHeaderCellDef>
                                <!-- {{'HOME.Preport'| translate}} -->
                                {{'HOME.Declarationofperformance'| translate}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray;let pfIndex = index;" class=" mat_column_performance_report_mv" >
 
                                <a class='pdf-content'
                                (click)='downloadPdf(fetchedDataForItemArray.PERFORMANCE_REPORT)'>
 
                                <ng-container *ngIf='fetchedDataForItemArray.PERFORMANCE_REPORT!=""'><span class='font-vsmall font-bold'>
                                    {{'HOME.View'| translate}}
 
                                    </span>
                                </ng-container>
                                <ng-container *ngIf='fetchedDataForItemArray.PERFORMANCE_REPORT==""'><span class='font-vsmall font-bold'>
                                   NA
 
                                    </span>
                                </ng-container>
                            </a>
                            </mat-cell>
                        </ng-container>
 
                        <ng-container matColumnDef="qualityCertificate">
                            <mat-header-cell class="header-font"
                                [ngClass]="{'hide-table-column': (countryCode === 'SWE' || countryCode === 'NOR')}" *matHeaderCellDef>
                                {{'HOME.Qualityc'| translate}}
                            </mat-header-cell>
                            <mat-cell [ngClass]="{'hide-table-column': (countryCode === 'SWE' || countryCode === 'NOR')}"
                                *matCellDef="let fetchedDataForItemArray;let qcIndex = index;">
                                <!-- <a class='pdf-content'
                                                            (click)='downloadPdf(qcIndex,fetchedDataForItemArray.qualityCertificate,fetchedDataForItemArray.PRODUCT_NAME,"QC")'>
                                                            <span class='font-vsmall font-bold'> Download</span>
                                                        </a> -->
                                <a class='pdf-content' (click)='getQualityCertificate(fetchedDataForItemArray.PRODUCT_ID)'>
                                    <span class='font-vsmall font-bold'> {{'HOME.View'| translate}}</span>
                                </a>
                            </mat-cell>
                        </ng-container>
 
                        <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns;sticky: true">
                        </mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                    <mat-paginator   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"   showFirstLastButtons></mat-paginator>
                    <!-- <div class="row buttonGroup">
                        <button class=" downloadButton" type="submit" (click)="downloadBitumenIndexData()">Download</button>
                    </div> -->
                </div>
            </div>
        </div>
 
    </div>
    <!-- Table View Of products Ends Here-->
</div>
<div class="container-fluid sidepadding-none  " [hidden]='(productsInformationData.length>0)'>
    <!-- <div class="container-fluid sidepadding-none  " [hidden]='!displayView'> -->
        {{'HOME.DNA'| translate}}
 
</div>