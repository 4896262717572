import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forecastactualsweden',
  templateUrl: './forecastactualsweden.component.html',
  styleUrls: ['./forecastactualsweden.component.css']
})
export class ForecastactualswedenComponent implements OnInit {

  forecastActualValue: string = 'weekly';
  constructor() { }

  ngOnInit() {
  }

}
