<div class="container-fluid sidepadding-none">
    <div class="row ">
        <div class="col-lg-2 col-md-2">
            <div class="sidenav">
                <div>
                    <img class="menu-logo" src="../../../assets/nynas_logo.png">
                </div>
            </div>
        </div>
        <div class="col-lg-10 col-md-10">
            <div class="row">
                <div>
                    <div class="internet-icon"><i class="fas fa-wifi"></i></div>
                </div>
            </div>
            <div class="row">
                <div>
                    <div class="header-message">Sorry we are stuck in the slow lane.</div>
                </div>
            </div>
            <div class="row">
                <div>
                    <div class="message">There seems to be an issue connecting to the BITUMEN CUSTOMER PORTAL.</div>                  
                </div>
            </div>
            <div class="row">
                <div>
                    <div class="message">Please confirm if you have internet connection and then try again logging into our application at: 
                        <a href="https://bitumen.nynas.com/">https://bitumen.nynas.com/</a>
                    </div>                  
                </div>
            </div>
        </div>
    </div>
</div>