   <div class="container">
    <div class="row pt-1 pb-1 no-gutters">
       <div class="col-md-6 pt-1">
          <label class="language-title" for="language"> {{'HOME.Language'| translate}}
          </label>
       </div>
       <div class="col-md-6">
         <select class="selectDropdown" [(ngModel)]="languageSelected">
            <option value="">{{'HOME.SELECT'| translate}}</option>
            <option *ngFor="let language of languageList" [ngValue]="language.LanguageName">
               <!-- {{language.LanguageName}} -->
               {{'HOME.' + language.LanguageName | translate}}
            </option>
         </select>
       </div>
    </div>
    <div class="row no-gutters">
      <div class="col-md-12">
         <label class="message-font" [hidden]="!(viewButtonClicked && !languageSelected)"> {{'HOME.Pleaseselectlanguage'|
            translate}}
         </label>
      </div>
    </div>
    <div class="row pt-3">
       <div class="col-sm text-center">
          <button class="btn btn-sm btn-primary dialog-footer-button"
             (click)="getProductDocument()"> {{'HOME.View'| translate}}
          </button>
       </div>
       <div class="col-sm text-center cancel-button-padding">
          <button class="btn btn-sm btn-primary dialog-footer-button" (click)="cancel()"> {{'HOME.Cancel'| translate}}
          </button>
       </div>
    </div>
 </div>