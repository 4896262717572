<!-- <h1 mat-dialog-title>
    {{title}}
</h1>
 
<div mat-dialog-content>
    <p>{{message}}</p>
</div>
 
<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">No</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
</div> -->
 
<div class="text-center">
    <span class="exclamation-icon">
        <i class="fas fa-exclamation"></i>
    </span>
</div>
<div class="container pt-3">
    <div class="row">
        <div class="col-sm">
            <div class="text-center">
                <span>
                    <span mat-dialog-title class="confirm-dialog-header" *ngIf="headerNumber === 'delete-user-group-popup'">{{'HOME.delete-user-group-popup'|translate}} </span>
                    <span mat-dialog-title class="confirm-dialog-header" *ngIf="headerNumber === 'alert-delete-message'">{{'HOME.alert-delete-message'|translate}} </span>
                    <span mat-dialog-title class="confirm-dialog-header" *ngIf="messageNumber === 'user-present-in-group'">{{'HOME.user-present-in-group'|translate}} {{ count }} </span>
                    <span mat-dialog-title class="confirm-dialog-header" *ngIf="headerNumber === 'pwd-change-days'">{{'HOME.pwd-change-days'|translate}}{{ count }}{{'HOME.pwd-change-days-msg'|translate}} </span>
                    <span mat-dialog-title class="confirm-dialog-header" *ngIf="messageNumber === 'pwd-change-days-msg'"></span>
                    <span mat-dialog-title class="confirm-dialog-header" *ngIf="headerNumber === 'delete-contact-us-confirmation'">{{'HOME.delete-contact-us-confirmation'|translate}}  </span>
                    <span mat-dialog-title class="confirm-dialog-header" *ngIf="headerNumber === 'delete-nynas-updates-confirmation'">{{'HOME.delete-nynas-updates-confirmation'|translate}}  </span>
                    <span mat-dialog-title class="confirm-dialog-header" *ngIf="headerNumber === 'guest-view-navigation'">{{'HOME.guest-view-navigation'|translate}}  </span>
                    <span mat-dialog-title class="confirm-dialog-header" *ngIf="headerNumber === 'delete-delsite-confirmation'">{{'HOME.delete-delsite-confirmation'|translate}}  </span>
                    <span mat-dialog-title class="confirm-dialog-header" *ngIf="headerNumber === 'deliverySite-statusChange-confirmation'">{{'HOME.deliverySite-statusChange-confirmation'|translate}} </span>
                </span>
            </div>
            <div class="text-center">
                <mat-dialog-content>
                    <span class="message-text text-center">
                        <span mat-dialog-title class="message-text"
                            *ngIf="messageNumber === 'guest-view-message'">{{'HOME.guest-view-message'|translate}} </span>
                    </span>
                </mat-dialog-content>
                <div mat-dialog-actions>
                    <button mat-raised-button color="primary" (click)="onConfirm()"
                        class="btn btn-sm btn-primary confirm-dialog-footer-buttons confirm-dialog-yes-button">
                        {{'HOME.Yes'| translate}}
                    </button>
                    <button mat-button (click)="onDismiss()"
                        class="btn btn-sm btn-primary confirm-dialog-footer-buttons confirm-dialog-no-button">
                        {{'HOME.No'| translate}}
                    </button>
                </div>
            </div>
            </div>
        </div>
    </div>