import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { ResetPasswordComponentService } from './reset-password.component.service';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  message: string = "";
  cancelButtonText = "Cancel";
  form1: FormGroup;
	mobileQuery: MediaQueryList;
	private _mobileQueryListener: () => void; 
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ResetPasswordComponent>,
    private resetPasswordComponentService: ResetPasswordComponentService,
    private dialog: MatDialog,
    fb: FormBuilder,
		private changeDetectorRef: ChangeDetectorRef, 
		private media: MediaMatcher) {
      this.form1 = fb.group({
        'userId': ['',Validators.required]
      });
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
    // this.dialogRef.updateSize('300vw','350vw')
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
  }

	ngOnDestroy(): void {
	  this.mobileQuery.removeListener(this._mobileQueryListener);
	}

  get userId(){
    return (this.form1.get('userId') ? this.form1.get('userId').value : null);
  }

  resetPassword(): void {
    var userObject = new Object();
		userObject['UserId'] = this.userId;
		this.resetPasswordComponentService.resetPassword(userObject)
			.subscribe(
				response => {
					this.resetPasswordStatusDialog(response);
				});
    this.dialogRef.close(true);
  }

	resetPasswordStatusDialog(response: any) {
		if (response && response['ResponseCode'] === 'OSB_OK_200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
      // this.openResetPasswordDialog('Password Reset Sucessful', '', true,'home-reset-password-success');
      this.openResetPasswordDialog('Password Reset Sucessful', '', true,'reset-password-success');
		
		} 
  else if (response['Description'] === "Please Enter Correct UserId"){
			this.openResetPasswordDialog('', '', false,'home-correct-user-id');
	} else if ( response['Description'] === "Invalid Schema:UserID is blank or Missing or not in proper format "){
    this.openResetPasswordDialog('', '', false,'home-correct-user-id-format');
  }
  else  if  (response && response['ResponseCode'] === '500' && response['ResponseStatus']  && response['ResponseStatus'].toUpperCase()=== 'ERROR'){
    this.openResetPasswordDialog('', '', false,'home-reset-password-fail');
  }
  else  if  (response && response['ResponseCode'] === 'OSB_ERROR_403'){
    this.openResetPasswordDialog('', '', false,'home-reset-password-fail-deactive');
  }
  else  if  (response && response['ResponseCode'] === 'OSB_ERROR_400'){
    this.openResetPasswordDialog('', '', false,'home-reset-password-fail-incoorect-format');
  }
  else  if  (response && response['ResponseCode'] === 'OSB_ERROR_404'){
    this.openResetPasswordDialog('', '', false,'home-reset-password-fail-userId-not-found');
  }
  else {
    this.openResetPasswordDialog('', '', false,'home-reset-password-fail');
  }}

  openResetPasswordDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        isSuccess: isSuccess,
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        }
      },
      height: '35%',
      width: (this.mobileQuery.matches ? '50%' : '25%'),
    });
  }

  cancel(): void {
    this.dialogRef.close(true);
  }
}