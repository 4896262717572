import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyDetailsService {
  apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient) { }

  getMyCompanyDetails(CustomerId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      })
    };
    let weekly_url = this.apiUrl + '/GetMyCompany';
    return this.httpClient
      .get(weekly_url,
        {
          params: {
            CustomerId: CustomerId,
          }
        });

  }

  getDeliverySites(customerId: string, approvalStage: string, countryCode: string) {
    const url = this.apiUrl + '/FetchDeliverySiteService';
    return this.httpClient
      .get(url, {
        params: {
          CustomerId: customerId,
          ApprovalStage: approvalStage,
          CountryCode: countryCode
        }
      });
  }

}
