import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuperUserService {

  apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private httpClient: HttpClient) { }

  getCustomerListForUser(userId: string, countryCode: string) {
    const serviceUrl = this.apiUrl + '/GetCustomerListForUser';
    return this.httpClient
      .get(serviceUrl, {
        params: {
          User_ID: userId,
          CountryCode: countryCode
        }
      });
  }

  getDisplayMessage(type: string) {
    let url = this.apiUrl + '/GetDisplayMessage';
    return this.httpClient.get(url, {
      params: {
        Type: type
      }
    });
  }
}
