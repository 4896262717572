import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { environment } from '../../../environments/environment';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class ModifyDeliverySiteSwedenService {
 
  apiUrl = environment.apiUrl;
 
  modifyDelSite: any;
 
  constructor(
    private httpClient: HttpClient) { }
 
  setModifyDelSite(modifyDelSite: any) {
    this.modifyDelSite = modifyDelSite;
  }
  getModifyDelSite() {
    return this.modifyDelSite;
  }
  getCustomerList(countryCode) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }  
 
  getDeliverySites(customerId: string, countryCode: string) {
    const url = this.apiUrl + '/FetchDeliverySiteService';
    return this.httpClient
      .get(url, {
        params: {
          CustomerId: customerId,
          ApprovalStage: '',
          CountryCode: countryCode
        }
      });
  }
 
  deleteDelSite(delsite: string, customerId: string, countryCode: string,) {
    let url = this.apiUrl + '/DeleteDeliveryService';
    let httpParams = new HttpParams().set('DeliverySite', delsite).set('CustomerId', customerId).set('Country', countryCode);

    let options = { params: httpParams };

    /* let postData = JSON.stringify({
        "ContactId": id,
    }) */

    return this.httpClient.delete(url, options).pipe();
  }

  getModifyDeliverySiteDetails(CustomerID: string, countryCode: string,) {
    let weekly_url = this.apiUrl + '/FetchDeliverySiteService';
    return this.httpClient
      .get(weekly_url,
        {
          params: {
            CustomerId: CustomerID, //"267359",

            CountryCode: countryCode
          }
        });
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      const workbook: XLSX.WorkBook = { Sheets: { 'MonthlyForecast': worksheet }, SheetNames: ['ModifyDeliverySiteDetails'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const MonthlyForecast: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(MonthlyForecast, fileName + EXCEL_EXTENSION);
  }

}