<div class="container">
    <!-- <div class="row pl-1 pt-2 no-gutters">
        <div class="col-md-4">
            <span class="note-text-header">Select the desired customer maintenance group: </span>
        </div>
    </div> -->
    <form [formGroup]="guestViewForm" >
        <div class="row pl-1 pt-2 no-gutters">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="customer_name">
                        <span class="input-text-header">{{'HOME.Customername'| translate}}</span><span
                            class="mandatory-field pl-1">*</span>
                    </label>
                    <select class="form-control input-text addUserSelect" formControlName="CustomerID"
                        [ngClass]="{ 'is-invalid': submitted && fields.CustomerID.errors }" id="customers"
                        (change)="changeCustomerName()">
                        <option value="">{{'HOME.SELECT'| translate}} {{'HOME.Customername'| translate}}</option>
                        <option *ngFor="let customer of customerList" [value]="customer.CustomerID">
                            {{customer.CustomerName}}
                        </option>
                    </select>
                    <div *ngIf="submitted && fields.CustomerID.errors" class="invalid-feedback">
                        <div *ngIf="fields.CustomerID.errors.required">{{'HOME.Customernameisrequired'| translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pl-1 pt-2">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="user_group">
                        <span class="input-text-header">{{'HOME.Usergroup'| translate}}</span><span
                            class="mandatory-field pl-1">*</span>
                    </label>
                    <select class="form-control input-text addUserSelect" formControlName="UserGroup"
                        [ngClass]="{ 'is-invalid': submitted && fields.UserGroup.errors }">
                        <option value="">{{'HOME.SelectUserGroup'| translate}}</option>
                        <option *ngFor="let userGroup of userGroups" [value]="userGroup.UserGroupID">
                            {{ userGroup.UserGroupName }}
                        </option>
                    </select>
                    <div *ngIf="submitted && fields.UserGroup.errors" class="invalid-feedback">
                        <div *ngIf="fields.UserGroup.errors.required">{{'HOME.Usergroupisrequired'| translate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pl-1 pt-2">
            <div class="col-sm">
                <button id="btn-save" class="btn btn-sm btn-primary add-price-buttons saveBtn" (click)="confirmNavigationToGuestView()">{{'HOME.Switchto'| translate}}
                </button>
            </div>
        </div>
    </form>

</div>