import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DashboardService } from '../home/dashboard.component.service';
import { User } from '../model/user';
import { PriceDataService } from '../price-management/price-authors/price-authors.service';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { AppService } from '../shared-modules/services/shared.service';
import { SuperUserComponent } from '../super-user/super-user.component';
import { UpdatePasswordComponent } from '../update-password/update-password.component';
import { Customer } from '../user-management/customer';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { UserGroup } from '../user-management/user-group';
import { LoginService } from './login.component.service';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit, OnDestroy {
  
  Username: string;
  Password: string;
  user: User = new User();
  noUserInput: boolean = false;
  invalidLogin: boolean = false;
  customerList: Customer[] = [];
  userGroupList: UserGroup[] = [];
  mobileQuery: MediaQueryList;
  deviceIPAddress: string = '';
  browserName: string;
  downTimeMessage: string;

  private _mobileQueryListener: () => void;
  hsfoUSDPerMTList: any = [];
  conversionRateList: any = [];
  monthlyHSFOUSDPerMTList: any = [];
  monthlyHSFOEURPerMTList: any = [];
  monthlyConversionRateList: any = [];

  constructor(private sharedService: AppService, private loginService: LoginService,
    private router: Router, private dialog: MatDialog, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private dashboardService: DashboardService,
    private exchangeBrentData: PriceDataService,private loaderService: LoaderService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    }             
  
  ngOnInit() {
    this.getBrowserName();
    this.getDeviceIPAddress();
    this.getDowntimeMessage();  
    console.clear();
    sessionStorage.clear();
  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  loginUser() {
    this.noUserInput = false;
    this.invalidLogin = false;
    if (!this.Username || !this.Password) {
      this.noUserInput = true;
    } else {
      this.getLoginDetails();
    }
  }

  getCustomerNameById(customerId: string): string {
    for (var customer of this.customerList) {
      if (customer && customer.CustomerID === customerId) {
        return customer.CustomerName;
      }
    }
    return null;
  }

  getUserGroupNameById(userGroupId: number): string {
    for (var userGroup of this.userGroupList) {
      if (userGroup && userGroup.UserGroupID === +userGroupId) {
        return userGroup.UserGroupName;
      }
    }
    return null;
  }

  getLoginDetails() {
   let encryptPswd = window.btoa(this.Password);
    this.loginService.getLoginService(this.Username, encryptPswd, this.deviceIPAddress, this.browserName)
      .subscribe(
        response => {
          if (response) { 
            if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
              const token = response['Token'];
              this.sharedService.setLoginResponse(response);
              this.loaderService.saveToken(token);
              // console.log(JSON.stringify(response));
              // Set CurrentDateTime to use Server time throughout the application
              this.sharedService.setCurrentDateTime(response['CurrentDateTime']);

              // Check if browser opened in mobile device, used for displaying graphs
              this.sharedService.setIsMobileDevice(this.mobileQuery.matches);

              if (response["FirstLoginFlag"] === 'True') {
                // Set language for Change password pop-up
                this.setSelectedLanguageByLoggedInUserCountry(response);

                this.updatePasswordForFirstLogin();
              } else {
                //To view old navigation please use
                //this.router.navigate(['/dashboard']);
                this.setSelectedLanguageByLoggedInUserCountry(response);

                // Commented old code, kept for future reference
                // this.navigateToHomePage(response['CountryCode']);

                // For super user, user need to select a country code after login
                this.checkIfLoggedInUserIsASuperUser(response);
              }
            } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
              // Navigate to Page not found, if service returned error in response
              // this.router.navigate(['/pagenotfound']);

              if (response['Description'] === "Password does not match.") {
                // this.openSaveUserDialog('Login Failed', '', false, 'login-fail-password-not-match');
                this.openSaveUserDialog('Login Failed', '', false, 'invalid-username-password');
              } else if (response['Description'] === "No data found for the given UserId.") {
                // this.openSaveUserDialog('Login Failed', '', false, 'login-fail-no-data');
                this.openSaveUserDialog('Login Failed', '', false, 'invalid-username-password');
              }
                else if (response['Description'] === "Status is either deactive or active-locked.") {
                this.openSaveUserDialog('Login Failed', '', false, 'login-fail-active-data');
                
              } else if (response['Description'] === "Password expired. Please reset password by using reset password link.") {
                this.openSaveUserDialog('Password expired', '', false, 'login-fail-password-expired');
              } 
              else if(response['Description'] === "User deactivated"){
              this.openSaveUserDialog('Login Failed due to more than 10 attempts', '', false,'login-fail-tenattempts');
            }
              else {
                this.openSaveUserDialog('Login Failed', '', false, 'login-fail');
              }

                
            }
            
            
            else {
              this.invalidLogin = true;
              this.openSaveUserDialog('Login Failed', '', false,'login-fail');

            }
          }
          /* else {
            if(this.Username==undefined || this.Username==null || this.Username=='' && this.Password==undefined || this.Password==null || this.Password=='') {
                  this.noUserInput=true;
            }
            else {
                  this.invalidLogin=true;
            }
          } */
        });

    /* const request1 = this.loginService.getLoginService(this.Username, this.Password);
    const request2 = this.manageUsersService.getCustomerList();
    const request3 = this.addUserComponentService.getUserGroupsByCustomerId('');

    const requestArray = [];
    requestArray.push(request1);
    requestArray.push(request2);
    requestArray.push(request3);

    forkJoin(requestArray).subscribe(results => {
      if (results && results.length) {
        if (results[0]) {
          if (results[0] && results[0]['ResponseCode'] === '200' && results[0]['ResponseStatus'] === 'SUCCESS') {
            this.router.navigate(['/dashboard']);
            localStorage.setItem("userName", results[0]["FirstName"] + ' ' + results[0]["LastName"]);
            localStorage.setItem("userId", results[0]["EmailID"]);
            localStorage.setItem("customerID", results[0]["CustomerID"]);
            localStorage.setItem("role", results[0]["Role"]);
            localStorage.setItem("lastLoginTime", this.sharedService.getCurrentDateTime().toString());
            for (var customer of this.customerList) {
              if (customer.CustomerID === results[0]["CustomerID"]) {
                localStorage.setItem("customerName", customer.CustomerName);
              }
            }
            for (var userGroup of this.userGroupList) {
              if (userGroup.UserGroupID && userGroup.UserGroupID.toString() === results[0]["UserGroupID"]) {
                localStorage.setItem("userGroupName", userGroup.UserGroupName);
              }
            }
          } else if (!this.Username || !this.Password) {
            this.noUserInput = true;
          } else {
            this.invalidLogin = true;
          }
        }
        if (results[1] && results[1].Customers && results[1].Customers.length) {
          this.customerList = results[1].Customers;
        }
        if (results[2] && results[2].UsersGroups && results[2].UsersGroups.length) {
          this.userGroupList = results[2].UsersGroups;
        }
      }
    }); */

  }

  openAlertDialog() {
    this.dialog.open(ResetPasswordComponent, {
      height: '50%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }
  openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean,headerNumber:string) {
		this.dialog.open(DialogBodyComponent, {
			data: {
				header: headerTitle,
				message: message ? message : ' ',
				buttonText: {
					ok: 'Ok'
				},
        isSuccess: isSuccess,
        headerNumber:headerNumber
			},
      // height: '37%',
      height: 'auto',
      // width: (this.mobileQuery.matches ? '50%' : '22%'),
      width: (this.mobileQuery.matches ? 'auto' : '22%'),
		});
	}

	updatePasswordForFirstLogin() {
    this.dialog.open(UpdatePasswordComponent, {      
			// height: '60%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
      // height: 'auto',
      height: '75%',
			// width: 'auto'
    });
  }

  getDeviceIPAddress() {
    this.loginService.getDeviceIPAddress()
      .subscribe(
        response => {
          if (response) {
            this.deviceIPAddress = response['ip'];
          }
        });
  }
  

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        this.browserName = 'edge';
        break;
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        this.browserName = 'opera';
        break;
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        this.browserName = 'chrome';
        break;
      case agent.indexOf('trident') > -1:
        this.browserName = 'ie';
        break;
      case agent.indexOf('firefox') > -1:
        this.browserName = 'firefox';
        break;
      case agent.indexOf('safari') > -1:
        this.browserName = 'safari';
        break;
      default:
        this.browserName = 'other';
    }
  }  

  setSelectedLanguageByLoggedInUserCountry(response: any) {
    if (response && response['CountryCode']) {
      let selectedLanguage: string;
      switch (response['CountryCode']) {
        case 'FIN':
          selectedLanguage = 'FI';
          break;
        case 'SWE':
          selectedLanguage = 'SE';
          break;
        case 'NOR':
          selectedLanguage = 'NR';
          break;
        case 'DNK':
          selectedLanguage = 'DA';
          break;
        default:
          selectedLanguage = 'FI';
      }
      this.sharedService.setSelectedLanguage(selectedLanguage);
    }
  }

  getDowntimeMessage() {
    let type: string = 'Login_Message';
    // this.downTimeMessage= 'The service is not reachable due a technical issue. We are working to solve the issue as soon as possible. Sorry for the inconvenience.'
    // this.downTimeMessage= 'There is an unexpected downtime for Portal starting at 0700 hrs CET today due to a server breakdown. We apologize for the inconvenience caused.'
     this.loginService.getDisplayMessage(type)
       .subscribe(
       response => {
      if (response && response['ResponseCode'] === '200') {
          this.downTimeMessage = response['Message'];
       }
       
      });
  }

  checkIfLoggedInUserIsASuperUser(loginResponse: any) {
    this.loginService.fetchCountryAccessForUser(loginResponse['UserID'])
      .subscribe(
        response => {
          if (response && response['ResponseCode'] === '200' && response['Collection'] && response['Collection'].length) {
            this.showSuperUserPopUp(response['Collection']);
          } else {
            this.navigateToHomePage(loginResponse['CountryCode']);
          }
        });
  }

  showSuperUserPopUp(countryList: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      countryList: countryList
    };
    // dialogConfig.height = (this.mobileQuery.matches ? '30%' : '36%');
    // dialogConfig.width = (this.mobileQuery.matches ? '50%' : '40%');
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    dialogConfig.minWidth = '40%';

    this.dialog.open(SuperUserComponent, dialogConfig);
  }

  async navigateToHomePage(countryCode: string) {
    this.resetExistingGraphParameters();
    if (countryCode === 'FIN') {
      let currentDateTime = this.sharedService.getCurrentDateTime();
      let currentYear = currentDateTime.getFullYear();
      try {
        await this.getHomePageGraphAxisLimits();
      }
      catch (e) {
      }
      try {
        await this.getLineChartData();
      }
      catch (e) {
      }
      try {
        await this.getBrentExchangeData(currentYear - 1);
      }
      catch (e) {
      }
      try {
        await this.getBrentExchangeData(currentYear);
      }
      catch (e) {
      }
      try {
        await this.getExpectedPriceChange();
      }
      catch (e) {
      }
    }
    this.router.navigate(['/app/home']);
  }

  async getHomePageGraphAxisLimits() {
    let key = 'HOME_PAGE_GRAPH_AXIS_LIMITS';
    return new Promise<void>((_resolve, _reject) => {
      this.loginService.getDisplayMessage(key).subscribe(
        response => {
          if (response && response['ResponseCode'] === '200') {
            if ((response['Message'] && response['Message'].split(',') && response['Message'].split(',').length)) {
              let graphAxisLowerLimit = response['Message'].split(',')[0] ? response['Message'].split(',')[0] : 280;
              let graphAxisUpperLimit = response['Message'].split(',')[1] ? response['Message'].split(',')[1] : 420;
              this.sharedService.setFinlandHomePageGraphAxisLowerLimit(graphAxisLowerLimit);
              this.sharedService.setFinlandHomePageGraphAxisUpperLimit(graphAxisUpperLimit);
            }
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

  async getLineChartData() {
    let uom: string = 'USD/MT';
    return new Promise<void>((_resolve, _reject) => {
      this.dashboardService.getLineChartData(uom).subscribe(
        response => {
          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            if (response["PriceIndexAndConversionRateCollection"] && response["PriceIndexAndConversionRateCollection"].length) {
              response["PriceIndexAndConversionRateCollection"].forEach(priceIndexAndConversionRateObject => {
                this.conversionRateList.push(priceIndexAndConversionRateObject['ConversionRate']);
                priceIndexAndConversionRateObject['PriceNotation'].forEach(priceNotationObject => {
                  if (priceNotationObject['Name'] === 'HSFO' && priceNotationObject['UOM'] === 'USD/MT') {
                    this.hsfoUSDPerMTList.push(priceNotationObject['Rate']);
                  }
                })
              });
            }
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

  getBrentExchangeData(year: number) {
    let type = 'MONTHLY';
    return new Promise<void>((_resolve, _reject) => {
      this.exchangeBrentData.getLineChartExhangeData(type, year).subscribe(
        response => {
          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let priceInformationData = response["PriceIndexAndConversionRateCollection"];
            priceInformationData.forEach(priceObject => {
              this.monthlyConversionRateList.push(priceObject['ConversionRate']);
              priceObject['PriceNotation'].forEach(notation => {
                if (notation.Name === 'HSFO' && notation.UOM === 'USD/MT') {
                  this.monthlyHSFOUSDPerMTList.push(notation['Rate']);
                }
                if (notation.Name === 'HSFO' && notation.UOM === 'EUR/MT') {
                  this.monthlyHSFOEURPerMTList.push(notation['Rate']);
                }
              })
            })
            this.sharedService.setMonthlyConversionRateList(this.monthlyConversionRateList);
            this.sharedService.setMonthlyHSFOUSDPerMTList(this.monthlyHSFOUSDPerMTList);
            this.sharedService.setMonthlyHSFOEURPerMTList(this.monthlyHSFOEURPerMTList);
            _resolve();
          }
          else {
            _reject();
          }
        });
    })
  }

  resetExistingGraphParameters() {
    this.sharedService.setFinlandHomePageGraphAxisLowerLimit(undefined);
    this.sharedService.setFinlandHomePageGraphAxisUpperLimit(undefined);
    this.sharedService.setConversionRateList(undefined);
    this.sharedService.setHSFOUSDPerMTList(undefined);
    this.sharedService.setMonthlyConversionRateList(undefined);
    this.sharedService.setMonthlyHSFOUSDPerMTList(undefined);
    this.sharedService.setMonthlyHSFOEURPerMTList(undefined);
  }

  getExpectedPriceChange() {
    let uom: string = 'USD/MT';
    return new Promise<void>((_resolve, _reject) => {
      this.dashboardService.fetchAveragePriceRate(uom)
        .subscribe(
          response => {
            if (response && response["ResponseCode"] === "200" && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
              let averageCollectionObject = response["AverageCollection"];
              if (averageCollectionObject && averageCollectionObject.length) {
                let averageConversionRate = (averageCollectionObject[0]['CURRENT_MONTH_DAILY_RATE'] ? +(averageCollectionObject[0]['CURRENT_MONTH_DAILY_RATE']) : undefined);
                let averageHSFOEURPerMT = (averageCollectionObject[0]['PAST_MONTH__MONTHLY_HSFO'] ? +(averageCollectionObject[0]['PAST_MONTH__MONTHLY_HSFO']) : undefined);
                this.conversionRateList.push(averageConversionRate);
                this.hsfoUSDPerMTList.push(averageHSFOEURPerMT);
                this.sharedService.setConversionRateList(this.conversionRateList);
                this.sharedService.setHSFOUSDPerMTList(this.hsfoUSDPerMTList);
              }
              _resolve();
            }
            else {
              _reject();
            }
          });
    })
  }

}
