import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { NavigationExtras, Router } from '@angular/router';
import { MY_FORMATS } from '../../delivery-guide-admin/delivery-guide-admin.component';
import { AppService } from '../../shared-modules/services/shared.service';
import { OrderTrackingService } from './order-tracking.service';
import { DatePipe } from '@angular/common';
import { GlobalSearchService } from '../../global-search/global-search.service';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class OrderTrackingComponent implements OnInit {
  userInfo: any;
  username: any;
  userID: any;
  customerId: any;
  // portalCustomerIdUserSelected: string;
  // jdeCustomerIdUserSelected: string;
  delsite: any;
  // portalDeliverySiteUserSelected: string;
  // jdeDeliverySiteUserSelected: string;
  selectedProductName: string = '';
  selectedProductId: string = '';
  // portalDeliverySiteList: any = [];
  // jdeDeliverySiteList: any = [];
  deliverySiteList: any = [];
  productList: any = [];
  role: any;
  customerName: string;
  isLoggedInUserAdmin: boolean;
  isLoggedInUserPortalAdmin: boolean;
  isLoggedInUserCustomerUser: boolean;
  loggedInUserId: string;
  loggedInUserName: string;
  countryCode: any;
  customerList: any;
  displayTableOne: boolean;
  displayTableTwo: boolean;
  displayTableThree: boolean;
  jdeClosedOrderCount: string;
  jdeOpenOrderCount: string
  mobileQuery: MediaQueryList;
  todayDate: Date;
  private _mobileQueryListener: () => void;
  currentToggleMenu: string = 'closed';
  fromDate: Date;
  productId: string = '';
  reqDate: string = '';
  refNum: string = '';
  orderNumber: string = '';
  orderCreationCount: number;
  changeReqCount: number;
  jdeAddNum: string = '';
  selectedRequestStatus: string = 'O';
  selectedOrderStatus: string = 'A';
  statusFlag: boolean;
  isReqCompleted: boolean;
  reqCompleted : boolean;
  rowDataForOrder:any;
  rowDataForRequest:any;
  jdeAddNumCommaSep:string ='';

  // orders table
  dataSourceTable1: any = new MatTableDataSource();
  displayedColumnsOne: any; // JDE order data table
  @ViewChild('dataSourceTable1paginator', { static: true }) dataSourceTable1paginator: MatPaginator;
  @ViewChild('openOrderTableSort', { static: true }) public openOrderTableSort: MatSort;

  // request table  
  dataSourceTable2: any = new MatTableDataSource();

  // cancelled and rejected request table  
  dataSourceTable3: any = new MatTableDataSource();

  // Commented below code, kept for future reference
  /* displayedColumnsTwo: any = ['ReferenceNumber', 'DeliverySite', 'StatusDescription', 'OrderDate', 'CreatedBy', 'RequestedDate',
    'ProductName', 'Quantity', 'reqTime', 'custPO', 'OrderNumber', 'OrderFlag']; */

  displayedColumnsTwo: any; // Portal request data table
  displayedColumnsThree: any; // Portal cancelled and rejected request data table

  @ViewChild('dataSourceTable2paginator', { static: true }) dataSourceTable2paginator: MatPaginator;
  @ViewChild('dataSourceTable3paginator', { static: true }) dataSourceTable3paginator: MatPaginator;
  @ViewChild('closedOrderTableSort', { static: true }) public closedOrderTableSort: MatSort;
  deliverySiteCity: any;
  portalDataRequestPending: boolean;
  jdeDataRequestPending: boolean;
  portalCancelledAndRejetedDataRequestPending: boolean
  previouslySelectedCustomerId: string;
	showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;
  customerIdUserSelected: string;
  @ViewChild('cancelledAndRejectedRequestTableSort', { static: true }) public cancelledAndRejectedRequestTableSort: MatSort;
  globalSearchTextForPortalOpenRequest: string;
  globalSearchTextForJDEOrders: string;
  globalSearchTextForPortalCancelledRejectedRequest: string;

  constructor(private router: Router, private datePipe: DatePipe, private dialog: MatDialog, private ordertrackService: OrderTrackingService,
    private media: MediaMatcher, private changeDetectorRef: ChangeDetectorRef, private cdRef: ChangeDetectorRef, private sharedService: AppService,
		private globalSearchService: GlobalSearchService) {

    this.userInfo = this.sharedService.getLoginResponse();
    this.countryCode = (this.userInfo.CountryCode ? this.userInfo.CountryCode : '');
    this.customerId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
    this.customerName = (this.userInfo.CustomerName ? this.userInfo.CustomerName : '');
    this.isLoggedInUserPortalAdmin = (this.userInfo.RoleID === 'PA');
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.isLoggedInUserCustomerUser = (this.userInfo ? (this.userInfo.RoleID === 'U') : false);
    this.role = (this.userInfo ? this.userInfo.RoleID : '');
    this.userID = (this.userInfo ? this.userInfo.UserID : '');
    this.loggedInUserId = (this.userInfo ? this.userInfo.UserID : '');
    this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + ' ' + this.userInfo.LastName) : '');
    this.countryCode = this.userInfo.CountryCode;

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if(this.isLoggedInUserPortalAdmin){

      this.displayedColumnsOne = ['PortalRefNumber', 'OrderNumber', 'DeliverySite', 'StatusDescription', 'OrderDate', 'RequestedDate',
        'ProductName', 'QuantityOrdered', 'reqTime', 'custPO', 'SourceSystem', 'OrderedByDesc', 'holdCode'];

      this.displayedColumnsTwo = ['ReferenceNumber', 'OrderNumber', 'DeliverySite', 'StatusDescription', 'OrderDate', 'RequestedDate',
        'ProductName', 'Quantity', 'reqTime', 'custPO', 'OrderFlag', 'CreatedBy',];

      this.displayedColumnsThree = ['ReferenceNumber', 'OrderNumber', 'DeliverySite', 'StatusDescription', 'OrderDate', 'RequestedDate',
        'ProductName', 'Quantity', 'reqTime', 'custPO', 'OrderFlag', 'CreatedBy',];

      // Commented below code, kept for future reference
      /* this.displayedColumnsOne = ['OrderNumber', 'DeliverySite', 'OrderDate', 'OrderedByDesc', 'RequestedDate',
      'ProductName', 'QuantityOrdered', 'reqTime', 'custPO','holdCode']; */

      // Get previously selected customer when navigating back to Order tracking page
      const navigation = this.router.getCurrentNavigation();
      if (navigation) {
        const state = navigation.extras.state as {
          selectedCustomerId: string;
        };
        if (state && state.selectedCustomerId) {
          this.previouslySelectedCustomerId = state.selectedCustomerId;
        }
      }

    }
    else{

      this.displayedColumnsOne = ['PortalRefNumber', 'OrderNumber', 'DeliverySite', 'StatusDescription', 'OrderDate', 'OrderedByDesc', 'RequestedDate',
        'ProductName', 'QuantityOrdered', 'reqTime', 'custPO', 'SourceSystem'];

      this.displayedColumnsTwo = ['ReferenceNumber', 'OrderNumber', 'DeliverySite', 'StatusDescription', 'OrderDate', 'CreatedBy', 'RequestedDate',
        'ProductName', 'Quantity', 'reqTime', 'custPO', 'OrderFlag'];

      this.displayedColumnsThree = ['ReferenceNumber', 'OrderNumber', 'DeliverySite', 'StatusDescription', 'OrderDate', 'CreatedBy', 'RequestedDate',
        'ProductName', 'Quantity', 'reqTime', 'custPO', 'OrderFlag'];

      // Commented below code, kept for future reference
      /* this.displayedColumnsOne  = ['OrderNumber', 'DeliverySite', 'OrderDate', 'OrderedByDesc', 'RequestedDate',
      'ProductName', 'QuantityOrdered', 'reqTime' ,'custPO']; */
    }

  }
  async ngOnInit() {
    try {
      await this.getCustomerList();
    }
    catch (e) {
    }
    try {
      await this.getDeliverySites();
    }
    catch (e) {
    }
    try {

      // Product list dropdown is removed from page, so product list not needed 
      // await this.getProductDetails();
    }
    catch (e) {
    }

    // Get data in Portal request and JDE order tables for display on page load  
    try {
      await this.getTableData();
    }
    catch (e) {
    }
    try {
      await this.getJDETableData();
    }
    catch (e) {
    }
    try {
      await this.getCancelledAndRejectedRequestTableData();
    }
    catch (e) {
    }

    this.initialiseSearchTextBox();
  }
 
  ngOnDestroy(): void {
  }

  async toggleButtonClick(toggleMenu: string) {
    this.currentToggleMenu = toggleMenu;

    if (this.currentToggleMenu === 'open') {
      // this.jdeCustomerIdUserSelected = this.customerId;
      // this.portalDeliverySiteUserSelected = '';
      // this.jdeDeliverySiteUserSelected = '';
      this.selectedProductId = '';
      this.selectedProductName = '';
      this.refNum = '';
      this.orderNumber = '';
      this.selectedRequestStatus = 'O';
      this.selectedOrderStatus = 'A';
      
        try {
          // await this.getDeliverySites('jde');
        }
        catch (e) {
        }

        // Product list dropdown is removed from page, so product list not needed
        /* try {
          await this.getProductDetails();
        }
        catch (e) {
        } */ 

    } else if (this.currentToggleMenu === 'closed') {
      // this.portalCustomerIdUserSelected = this.customerId;
      // this.portalDeliverySiteUserSelected = '';
      // this.jdeDeliverySiteUserSelected = '';
      this.selectedProductId = '';
      this.selectedProductName = '';
      this.refNum = '';
      this.orderNumber = '';
      this.selectedRequestStatus = 'O';
      this.selectedOrderStatus = 'A';
      
        try {
          // await this.getDeliverySites('portal');
        }
        catch (e) {
        }

        // Product list dropdown is removed from page, so product list not needed
        /* try {
          await this.getProductDetails();
        }
        catch (e) {
        } */ 

    }
  }

  async getCustomerList() {
    this.customerList = [];
    return new Promise<void>((resolve, reject) => {
      this.ordertrackService.getCustomerNames(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.customerList = res["Customers"];
            /* this.portalCustomerIdUserSelected = this.customerId;
            this.jdeCustomerIdUserSelected = this.customerId; */
            this.customerIdUserSelected = (this.isLoggedInUserPortalAdmin ? "" : (this.customerId));

            /* if (this.isLoggedInUserPortalAdmin && this.previouslySelectedCustomerId) {
              // this.portalCustomerIdUserSelected = this.previouslySelectedCustomerId;
              // this.jdeCustomerIdUserSelected = this.previouslySelectedCustomerId;
              this.customerIdUserSelected = this.previouslySelectedCustomerId;
            } */

            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  
  async getDeliverySites() {
    this.deliverySiteList = [];
    /* let customerIdUserSelected: string;
    if (orderType === 'jde') {
      customerIdUserSelected = this.jdeCustomerIdUserSelected;
      this.jdeDeliverySiteList = [];
    } else if (orderType === 'portal') {
      customerIdUserSelected = this.portalCustomerIdUserSelected;
      this.portalDeliverySiteList = [];
    } else if (this.isLoggedInUserPortalAdmin && this.previouslySelectedCustomerId) {
      customerIdUserSelected = this.previouslySelectedCustomerId;
      this.portalDeliverySiteList = [];
      this.jdeDeliverySiteList = [];
    } else {
      customerIdUserSelected = this.customerId;
      this.portalDeliverySiteList = [];
      this.jdeDeliverySiteList = [];
    } */

    if(this.isLoggedInUserAdmin){
      return new Promise<void>((resolve, reject) => {
        this.ordertrackService.getDeliverySites(this.customerIdUserSelected, this.countryCode).subscribe(
          res => {
            if (res["ResponseCode"] === "200") {
  
              this.deliverySiteList = res["DeliverySiteCollection"];
  
              /* if (orderType === 'jde') {
                this.jdeDeliverySiteList = deliverySiteList;
                this.jdeDeliverySiteUserSelected = '';
              } else if (orderType === 'portal') {
                this.portalDeliverySiteList = deliverySiteList;
                this.portalDeliverySiteUserSelected = '';
              } else {
                this.portalDeliverySiteList = deliverySiteList;
                this.jdeDeliverySiteList = deliverySiteList;
                this.portalDeliverySiteUserSelected = '';
                this.jdeDeliverySiteUserSelected = '';
              } */

              resolve();
            }
            else if (res["ResponseCode"] === "201") {
              reject();          
            }
            else {
              reject();
            }
          });
      })

    }else if (this.isLoggedInUserCustomerUser) {
      return new Promise<void>((resolve, reject) => {

        this.ordertrackService.getDeliverySitesForUSER(this.userID, this.customerIdUserSelected, this.countryCode).subscribe(
          res => {
            if (res["ResponseCode"] === "200") {
  
             
            //this.delSiteList = res["DeliverySiteCollection"];
            var DeliverySiteCollection1 = [];
            //  this.deliverySiteList= res["DeliverySites"];
           
            for (let deliverySite of res["DeliverySites"]) {
  
              let deliverySiteObj = {};
              deliverySiteObj['DeliverySite'] = deliverySite['DeliverySiteName'];
              deliverySiteObj['JdeAddressNumber'] = deliverySite['JDE_Address_Number'];
              deliverySiteObj['AddressLine1'] = deliverySite['AddressLine1'];
              deliverySiteObj['AddressLine2'] = deliverySite['AddressLine2'];
              deliverySiteObj['DeliveryPostalCode'] = deliverySite['DeliveryPostalCode'];
              deliverySiteObj['City'] = deliverySite['City'];
              DeliverySiteCollection1.push(deliverySiteObj);
            }

            this.deliverySiteList = DeliverySiteCollection1;

            // this.deliverySiteUserSelected = '';

              /* if (orderType === 'jde') {
                this.jdeDeliverySiteList = DeliverySiteCollection1;
                this.jdeDeliverySiteUserSelected = '';
              } else if (orderType === 'portal') {
                this.portalDeliverySiteList = DeliverySiteCollection1;
                this.portalDeliverySiteUserSelected = '';
              } else {
                this.portalDeliverySiteList = DeliverySiteCollection1;
                this.jdeDeliverySiteList = DeliverySiteCollection1;
                this.portalDeliverySiteUserSelected = '';
                this.jdeDeliverySiteUserSelected = '';
              } */

            resolve();
            }
            else if (res["ResponseCode"] === "201") {
              // reject();

            }
            else {
              reject();
            }
          });
      })
      
    }
    

  }


  async getProductDetails() {
    this.productList = [];
    this.selectedProductName = '';
    this.selectedProductId = '';
    this.ordertrackService.getProductInformation(this.customerId, this.countryCode)
      .subscribe(
        res => {
          if (res["ResponseCode"] == 200) {
            this.productList = res['GetAllProductResponses'];
          }
        });
  }

  getProductIdbyName() {
    // this.selectedProductId = '';
    //   this.selectedProductName = '';
    if(!this.selectedProductId){
      this.selectedProductName = '';
    }else {
      for (let product of this.productList) {
        if (product.PRODUCT_ID === this.selectedProductId) {
          this.selectedProductId = product['PRODUCT_ID'];
          this.selectedProductName = product['PRODUCT_NAME'];
        }
      }
    }
    
    //console.log("product Name + Product Id" + this.selectedProductId + this.selectedProductName);
  }

  getJDEAddressNumberByDeliverySiteName() {
    this.jdeAddNum = '';

    let deliverySiteName: string = '';
    /* if (orderType === 'jde') {
      deliverySiteList = this.jdeDeliverySiteList;
      deliverySiteName = this.jdeDeliverySiteUserSelected;
    } else if (orderType === 'portal') {
      deliverySiteList = this.portalDeliverySiteList;
      deliverySiteName = this.portalDeliverySiteUserSelected;
    } else {
      deliverySiteList = this.portalDeliverySiteList;
      deliverySiteName = '';
    } */

    for (let deliverySiteObject of this.deliverySiteList) {

      if (deliverySiteObject) {
        let currentDeliverySiteName = (deliverySiteObject['DeliverySite'] || deliverySiteObject['DeliverySiteName']);
        if (currentDeliverySiteName === deliverySiteName) {
          this.jdeAddNum = (deliverySiteObject['JDE_Address_Number'] || deliverySiteObject['JdeAddressNumber']);
          break;
        }
      }
    }

    // console.log("this.jdeAddNum"+this.jdeAddNum);

  }

  getJDEAddNumForJdeTab(){
    this.jdeAddNumCommaSep = '';
    var jdeAddNumList=[];
    for (let deliverySiteObject of this.deliverySiteList) {

      if (deliverySiteObject) {
        jdeAddNumList.push(deliverySiteObject['JDE_Address_Number'] || deliverySiteObject['JdeAddressNumber']);
      }
    }
    this.jdeAddNumCommaSep =  jdeAddNumList.toString() ;
    //console.log("this.jdeAddNumCommaSep " +  this.jdeAddNumCommaSep);
  }

  async getJDETableData() {
    this.jdeDataRequestPending = true;
    this.dataSourceTable1 = new MatTableDataSource([]);
    this.globalSearchTextForJDEOrders = '';
    this.getProductIdbyName();

    if (this.customerIdUserSelected) {
      this.getJDEAddNumForJdeTab();
      this.jdeAddNum = "," + this.jdeAddNumCommaSep + ",";
    } else {
      this.jdeAddNum = '';
    }

    /* if(this.jdeDeliverySiteUserSelected){
      this.getJDEAddressNumberByDeliverySiteName();
      this.jdeAddNum =  ","+ this.jdeAddNum + ",";
    }else {
      this.getJDEAddNumForJdeTab();
      this.jdeAddNum =  ","+ this.jdeAddNumCommaSep + ",";
    } */
    
    //console.log("test jde AddNum" + this.jdeAddNum);

    this.ordertrackService.getJDEOrderTrackDetails(this.customerIdUserSelected,
      this.countryCode, this.selectedProductId, this.selectedProductName, this.orderNumber,
      this.selectedOrderStatus,this.jdeAddNum).subscribe(
        res => {
          this.jdeDataRequestPending = false;
          this.isReqCompleted = true;
          // console.log(res);
          if (res["ResponseCode"] == 200) {
            this.displayTableOne = true;
           
            this.jdeClosedOrderCount = res['ClosedOrderCount'];
            this.jdeOpenOrderCount = res['OpenOrderCount'];
            var jdeOrderCollection = (res['OrderRowCollection'] && res['OrderRowCollection'].length && res['OrderRowCollection'][0] && res['OrderRowCollection'][0]['OrderCollection']) ? res['OrderRowCollection'][0]['OrderCollection'] : [];
            //console.log(JSON.stringify(jdeOrderCollection));
            //this.getStatus(jdeOrderCollection);
            //this.dataSourceTable1 = new MatTableDataSource([]);
            this.dataSourceTable1 = new MatTableDataSource(jdeOrderCollection);
            this.dataSourceTable1.paginator = this.dataSourceTable1paginator;
            this.dataSourceTable1.sort = this.openOrderTableSort;

            this.dataSourceTable1.sortingDataAccessor = (data, sortHeaderId) => {
              if (data[sortHeaderId] && sortHeaderId !== 'OrderDate' && sortHeaderId !== 'RequestedDate') {
                return data[sortHeaderId].toLocaleLowerCase();
              } else if (data[sortHeaderId] && (sortHeaderId === 'OrderDate' || sortHeaderId === 'RequestedDate')) {
                let date = data[sortHeaderId] ? data[sortHeaderId] : '';
                return new Date(date);
              }
              return data[sortHeaderId];
            };

          }
          else if (res["ResponseCode"] === "201"){
           
            this.displayTableOne = false;
          }
          else {
           
            this.displayTableOne = false;
          }
        });

  }

  async getTableData() {
    this.portalDataRequestPending = true;
    this.dataSourceTable2 = new MatTableDataSource([]);
    this.globalSearchTextForPortalOpenRequest = '';

    // console.log("table2");
    this.getJDEAddressNumberByDeliverySiteName();
    //console.log("testing data " + this.selectedStatus + this.refNum);
    let selectedRequestStatus = 'O';
    if(this.isLoggedInUserAdmin){
      this.ordertrackService.getPortalOrderTrackDetails(this.customerIdUserSelected,
        this.countryCode, this.jdeAddNum, this.selectedProductId, this.reqDate,
        this.refNum, selectedRequestStatus).subscribe(
          res => {
            this.portalDataRequestPending = false;
            if (res["ResponseCode"] == 200) {
              this.displayTableTwo = true;
              this.orderCreationCount = res['OrderCreationCount'];
              this.changeReqCount = res['ChangeReqCount'];
              var orderCollection = res['OrderCollection']
              //this.getStatus(orderCollection);
              this.dataSourceTable2 = new MatTableDataSource(orderCollection);
              this.dataSourceTable2.paginator = this.dataSourceTable2paginator;
              this.dataSourceTable2.sort = this.closedOrderTableSort;
  
              this.dataSourceTable2.sortingDataAccessor = (data, sortHeaderId) => {
                if (data[sortHeaderId] && sortHeaderId !== 'OrderDate' && sortHeaderId !== 'RequestedDate') {
                  return data[sortHeaderId].toLocaleLowerCase();
                } else if (data[sortHeaderId] && (sortHeaderId === 'OrderDate' || sortHeaderId === 'RequestedDate')) {
                  let date = data[sortHeaderId] ? data[sortHeaderId] : '';
                  return new Date(date);
                }
                return data[sortHeaderId];
              };
  
            }else if (res["ResponseCode"] === "201"){
              this.displayTableTwo = false;
            }
            else {
              this.displayTableTwo = false;
            }
          });
    } else if(this.isLoggedInUserCustomerUser) {
      this.ordertrackService.getPortalOrderTrackDetailsUser(this.userID,this.customerIdUserSelected,
        this.countryCode, this.jdeAddNum, this.selectedProductName, this.reqDate,
        this.refNum, selectedRequestStatus).subscribe(
          res => {
            this.portalDataRequestPending = false;
            if (res["ResponseCode"] == 200) {
              this.displayTableTwo = true;
              this.orderCreationCount = res['OrderCreationCount'];
              this.changeReqCount = res['ChangeReqCount'];
              var orderCollection = res['OrderCollection']
              //this.getStatus(orderCollection);
              this.dataSourceTable2 = new MatTableDataSource(orderCollection);
              this.dataSourceTable2.paginator = this.dataSourceTable2paginator;
              this.dataSourceTable2.sort = this.closedOrderTableSort;
  
              this.dataSourceTable2.sortingDataAccessor = (data, sortHeaderId) => {
                if (data[sortHeaderId] && sortHeaderId !== 'OrderDate' && sortHeaderId !== 'RequestedDate') {
                  return data[sortHeaderId].toLocaleLowerCase();
                } else if (data[sortHeaderId] && (sortHeaderId === 'OrderDate' || sortHeaderId === 'RequestedDate')) {
                  let date = data[sortHeaderId] ? data[sortHeaderId] : '';
                  return new Date(date);
                }
                return data[sortHeaderId];
              };
  
            }else if (res["ResponseCode"] === "201"){
              this.displayTableTwo = false;
            }
            else {
              this.displayTableTwo = false;
            }
          });
    }
    

  }

  async getCancelledAndRejectedRequestTableData() {
    this.portalCancelledAndRejetedDataRequestPending = true;
    this.dataSourceTable3 = new MatTableDataSource([]);
    this.globalSearchTextForPortalCancelledRejectedRequest = '';
    this.getJDEAddressNumberByDeliverySiteName();
    let selectedRequestStatus = 'R';
    if (this.isLoggedInUserAdmin) {
      this.ordertrackService.getPortalOrderTrackDetails(this.customerIdUserSelected,
        this.countryCode, this.jdeAddNum, this.selectedProductId, this.reqDate,
        this.refNum, selectedRequestStatus).subscribe(
          res => {
            this.portalCancelledAndRejetedDataRequestPending = false;
            if (res["ResponseCode"] == 200) {
              this.displayTableThree = true;
              this.orderCreationCount = res['OrderCreationCount'];
              this.changeReqCount = res['ChangeReqCount'];
              var orderCollection = res['OrderCollection']
              this.dataSourceTable3 = new MatTableDataSource(orderCollection);
              this.dataSourceTable3.paginator = this.dataSourceTable3paginator;
              this.dataSourceTable3.sort = this.cancelledAndRejectedRequestTableSort;
              this.dataSourceTable3.sortingDataAccessor = (data, sortHeaderId) => {
                if (data[sortHeaderId] && sortHeaderId !== 'OrderDate' && sortHeaderId !== 'RequestedDate') {
                  return data[sortHeaderId].toLocaleLowerCase();
                } else if (data[sortHeaderId] && (sortHeaderId === 'OrderDate' || sortHeaderId === 'RequestedDate')) {
                  let date = data[sortHeaderId] ? data[sortHeaderId] : '';
                  return new Date(date);
                }
                return data[sortHeaderId];
              };
            } else if (res["ResponseCode"] === "201") {
              this.displayTableThree = false;
            }
            else {
              this.displayTableThree = false;
            }
          });
    } else if(this.isLoggedInUserCustomerUser) {
      this.ordertrackService.getPortalOrderTrackDetailsUser(this.userID, this.customerIdUserSelected,
        this.countryCode, this.jdeAddNum, this.selectedProductName, this.reqDate,
        this.refNum, selectedRequestStatus).subscribe(
          res => {
            this.portalCancelledAndRejetedDataRequestPending = false;
            if (res["ResponseCode"] == 200) {
              this.displayTableThree = true;
              this.orderCreationCount = res['OrderCreationCount'];
              this.changeReqCount = res['ChangeReqCount'];
              var orderCollection = res['OrderCollection']
              //this.getStatus(orderCollection);
              this.dataSourceTable3 = new MatTableDataSource(orderCollection);
              this.dataSourceTable3.paginator = this.dataSourceTable3paginator;
              this.dataSourceTable3.sort = this.cancelledAndRejectedRequestTableSort;
              this.dataSourceTable3.sortingDataAccessor = (data, sortHeaderId) => {
                if (data[sortHeaderId] && sortHeaderId !== 'OrderDate' && sortHeaderId !== 'RequestedDate') {
                  return data[sortHeaderId].toLocaleLowerCase();
                } else if (data[sortHeaderId] && (sortHeaderId === 'OrderDate' || sortHeaderId === 'RequestedDate')) {
                  let date = data[sortHeaderId] ? data[sortHeaderId] : '';
                  return new Date(date);
                }
                return data[sortHeaderId];
              };
            } else if (res["ResponseCode"] === "201") {
              this.displayTableThree = false;
            }
            else {
              this.displayTableThree = false;
            }
          });
    }
  }

  ngAfterViewInit(): void {
    // this.dataSource.sort = this.sort;

    this.dataSourceTable1.sort = this.openOrderTableSort;
    this.dataSourceTable2.sort = this.closedOrderTableSort;
    this.dataSourceTable3.sort = this.cancelledAndRejectedRequestTableSort;

    this.cdRef.detectChanges();

    this.dataSourceTable1.sortingDataAccessor = (data, sortHeaderId) => {
      if (data[sortHeaderId] && sortHeaderId !== 'OrderDate' && sortHeaderId !== 'RequestedDate') {
        return data[sortHeaderId].toLocaleLowerCase();
      } else if (data[sortHeaderId] && (sortHeaderId === 'OrderDate' || sortHeaderId === 'RequestedDate')) {
        let date = data[sortHeaderId] ? data[sortHeaderId] : '';
        return new Date(date);
      }
      return data[sortHeaderId];
    };
    this.dataSourceTable2.sortingDataAccessor = (data, sortHeaderId) => {
      if (data[sortHeaderId] && sortHeaderId !== 'OrderDate' && sortHeaderId !== 'RequestedDate') {
        return data[sortHeaderId].toLocaleLowerCase();
      } else if (data[sortHeaderId] && (sortHeaderId === 'OrderDate' || sortHeaderId === 'RequestedDate')) {
        let date = data[sortHeaderId] ? data[sortHeaderId] : '';
        return new Date(date);
      }
      return data[sortHeaderId];
    };
    this.dataSourceTable3.sortingDataAccessor = (data, sortHeaderId) => {
      if (data[sortHeaderId] && sortHeaderId !== 'OrderDate' && sortHeaderId !== 'RequestedDate') {
        return data[sortHeaderId].toLocaleLowerCase();
      } else if (data[sortHeaderId] && (sortHeaderId === 'OrderDate' || sortHeaderId === 'RequestedDate')) {
        let date = data[sortHeaderId] ? data[sortHeaderId] : '';
        return new Date(date);
      }
      return data[sortHeaderId];
    };
  }

  getStatus(orderCollection: any) {

    for (let obj of orderCollection) {
      if (this.isLoggedInUserPortalAdmin) {
        if (obj.Status !== "05" && obj.Status !== "04" && obj.Status !== "06") {
          obj['showHyperlink'] = true;
          //this.router.navigate(["../../app/order-management/order-entry"],navigationExtras);
        }
      } else if (this.role === "U" || this.role === "A") {
        if (obj.Status !== "05" && obj.Status !== "04" && obj.Status !== "06") {
          obj['showHyperlink'] = true;
          //this.router.navigate(["../../app/order-management/order-entry"],navigationExtras);
        }
      }
      else {
        obj['showHyperlink'] = false;
      }
    }

  }
  getRow(rowData: any, source: string, isOrdersAwaitingApprovalTableRow: boolean) {
    this.rowDataForOrder = [];
    this.rowDataForRequest = [];
   if(source === 'order'){
  //service call response success rowdata = order collection 
 //console.log("OrderNumber" +JSON.stringify(rowData['OrderNumber']));
    this.ordertrackService.getJDEOrderDetails(rowData['OrderNumber'], this.countryCode).subscribe(
      res=>{
       console.log(JSON.stringify(res));
       if (res["ResponseCode"] == 200) {
        if( res['OrderCollection'] &&  res['OrderCollection'].length){
          this.rowDataForOrder =  res['OrderCollection'][0];
          //  this.reqCompleted = true;

          let actualJDEOrderObject = {};
          actualJDEOrderObject = rowData;
          actualJDEOrderObject['DeliveryInstructionsLine1'] = rowData['DeliveryTicketText'];
          actualJDEOrderObject['DeliveryInstructionsLine2'] = this.rowDataForOrder['DeliveryInstructionsLine2'];
          actualJDEOrderObject['Information'] = this.rowDataForOrder['Information'];
          actualJDEOrderObject['EarliestArrivalTime'] = rowData['EarliestArrivalTime'] ? (+rowData['EarliestArrivalTime']).toString() : '';
          actualJDEOrderObject['LatestArrivalTime'] = rowData['LatestArrivalTime'] ? (+rowData['LatestArrivalTime']).toString() : '';
          actualJDEOrderObject['ReferenceNumber'] = rowData['PortalRefNumber'];

           const navigationExtras: NavigationExtras = {
            state: {
              rowData: actualJDEOrderObject,
              source: source
            }
          };
          this.router.navigate(["../../app/order-management/order-entry"], navigationExtras);
        }
      
       }
       else {

         let actualJDEOrderObject = {};
         actualJDEOrderObject = rowData;
         actualJDEOrderObject['DeliveryInstructionsLine1'] = rowData['DeliveryTicketText'];
         actualJDEOrderObject['EarliestArrivalTime'] = rowData['EarliestArrivalTime'] ? (+rowData['EarliestArrivalTime']).toString() : '';
         actualJDEOrderObject['LatestArrivalTime'] = rowData['LatestArrivalTime'] ? (+rowData['LatestArrivalTime']).toString() : '';
         actualJDEOrderObject['ReferenceNumber'] = rowData['PortalRefNumber'];

         const navigationExtras: NavigationExtras = {
           state: {
             rowData: actualJDEOrderObject,
             source: source
           }
         };
         this.router.navigate(["../../app/order-management/order-entry"], navigationExtras);
       }
       

      });
   } else if (source === 'request') {

     if (isOrdersAwaitingApprovalTableRow) {
       let closeCode = '';  // Send closeCode as blank to update lock status
       let zone = 'Europe/London';
       this.ordertrackService.updateLockStatusForOrders(rowData['ReferenceNumber'], closeCode, zone, this.loggedInUserId).subscribe(
         response => {
           if (response && response["ResponseCode"] == 200) {
             let isRequestLocked = (response['ResponseDescription'] ? response['ResponseDescription'] === 'No Valid transaction' : undefined);
             let hasLoggedInUserLockedThisRequest = (response['ResponseDescription'] ? response['ResponseDescription'] === 'Reference Number is locked' : undefined);
             this.ordertrackService.getPortalOrderDetails(rowData['ReferenceNumber'], this.countryCode).subscribe(
               res => {
                 console.log(JSON.stringify(res));
                 if (res["ResponseCode"] == 200) {
                   if (res['OrderCollection'] && res['OrderCollection'].length) {
                     this.rowDataForRequest = res['OrderCollection'][0];
                     const navigationExtras: NavigationExtras = {
                       state: {
                         rowData: this.rowDataForRequest,
                         source: source,
                         isRequestLocked: isRequestLocked,
                         lockedStatus: response['ResponseDescription'],
                         isOrdersAwaitingApprovalTableRow: true,
                         lockedByUser: response['LockedByUser'],
                         lockedOn: response['Lock_Time'],
                         hasLoggedInUserLockedThisRequest: hasLoggedInUserLockedThisRequest
                       }
                     };
                     this.router.navigate(["../../app/order-management/order-entry"], navigationExtras);
                   }
                 }
                 else {
                   const navigationExtras: NavigationExtras = {
                     state: {
                       rowData: rowData,
                       source: source,
                       isRequestLocked: isRequestLocked,
                       lockedStatus: response['ResponseDescription'],
                       isOrdersAwaitingApprovalTableRow: true,
                       lockedByUser: response['LockedByUser'],
                       lockedOn: response['Lock_Time'],
                       hasLoggedInUserLockedThisRequest: hasLoggedInUserLockedThisRequest
                     }
                   };
                   this.router.navigate(["../../app/order-management/order-entry"], navigationExtras);
                 }
               });
           } else {
           }
         });
     } else {
       this.ordertrackService.getPortalOrderDetails(rowData['ReferenceNumber'], this.countryCode).subscribe(
         res => {
           console.log(JSON.stringify(res));
           if (res["ResponseCode"] == 200) {
             if (res['OrderCollection'] && res['OrderCollection'].length) {
               this.rowDataForRequest = res['OrderCollection'][0];
               //  this.reqCompleted = true;
               const navigationExtras: NavigationExtras = {
                 state: {
                   rowData: this.rowDataForRequest,
                   source: source
                 }
               };
               this.router.navigate(["../../app/order-management/order-entry"], navigationExtras);
             }

           }
           else {
             const navigationExtras: NavigationExtras = {
               state: {
                 rowData: rowData,
                 source: source
               }
             };
             this.router.navigate(["../../app/order-management/order-entry"], navigationExtras);
           }


         });
     }

   }
}

  applyFilter(dataType: string, filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches

      if (dataType === 'portal-open-request') {
        this.dataSourceTable2.filter = filterValue;
        // this.displayTableTwo = (this.dataSourceTable2 && this.dataSourceTable2.filteredData && this.dataSourceTable2.filteredData.length) ? true : false;
      } else if (dataType === 'jde-all-request') {
        this.dataSourceTable1.filter = filterValue;
        // this.displayTableOne = (this.dataSourceTable1 && this.dataSourceTable1.filteredData && this.dataSourceTable1.filteredData.length) ? true : false;
      } else if (dataType === 'portal-cancelled-rejected-request') {
        this.dataSourceTable3.filter = filterValue;
        // this.displayTableThree = (this.dataSourceTable3 && this.dataSourceTable3.filteredData && this.dataSourceTable3.filteredData.length) ? true : false;
      }
    }
  }

  async changeCustomerName() {
    if (this.customerIdUserSelected) {
      try {
        await this.getDeliverySites();
      }
      catch (e) {
      }
    }
    try {
      await this.getTableData();
    }
    catch (e) {
    }
    try {
      await this.getJDETableData();
    }
    catch (e) {
    }
    try {
      await this.getCancelledAndRejectedRequestTableData();
    }
    catch (e) {
    }

  }

  async refreshData(dataType: string) {
    if (dataType === 'portal-open-request') {
      try {
        this.globalSearchTextForPortalOpenRequest = '';
        await this.getTableData();
      }
      catch (e) {
      }
    } else if (dataType === 'jde-all-request') {
      try {
        this.globalSearchTextForJDEOrders = '';
        await this.getJDETableData();
      }
      catch (e) {
      }
    } else if (dataType === 'portal-cancelled-rejected-request') {
      try {
        this.globalSearchTextForPortalCancelledRejectedRequest = '';
        await this.getCancelledAndRejectedRequestTableData();
      }
      catch (e) {
      }
    }
  }
  
	initialiseSearchTextBox() {
		this.dataSourceTable1.filterPredicate = function (data, filter: string): boolean {
			return (data['PortalRefNumber'].toLowerCase().includes(filter) ||
				data['DeliverySite'].toLowerCase().includes(filter) ||
				data['StatusDescription'].toLowerCase().includes(filter) ||
				data['CreatedBy'].toLowerCase().includes(filter) ||
				data['ProductName'].toLowerCase().includes(filter) ||
				data['Quantity'].toLowerCase().includes(filter) ||
				data['EarliestArrivalTime'].toLowerCase().includes(filter) ||
				data['LatestArrivalTime'].toLowerCase().includes(filter) ||
				data['CustomerPO'].toLowerCase().includes(filter) ||
				data['SourceSystem'].toLowerCase().includes(filter) ||
				data['OrderDate'].toString().toLowerCase().includes(filter) ||
				data['RequestedDate'].toString().toLowerCase().includes(filter));
		};
		this.dataSourceTable2.filterPredicate = function (data, filter: string): boolean {
			return (data['ReferenceNumber'].toLowerCase().includes(filter) ||
				data['DeliverySite'].toLowerCase().includes(filter) ||
				data['StatusDescription'].toLowerCase().includes(filter) ||
				data['CreatedBy'].toLowerCase().includes(filter) ||
				data['ProductName'].toLowerCase().includes(filter) ||
				data['Quantity'].toLowerCase().includes(filter) ||
				data['EarliestArrivalTime'].toLowerCase().includes(filter) ||
				data['LatestArrivalTime'].toLowerCase().includes(filter) ||
				data['CustomerPO'].toLowerCase().includes(filter) ||
				data['OrderFlag'].toLowerCase().includes(filter) ||
				data['OrderDate'].toString().toLowerCase().includes(filter) ||
				data['RequestedDate'].toString().toLowerCase().includes(filter));
		};
		this.dataSourceTable3.filterPredicate = function (data, filter: string): boolean {
			return (data['ReferenceNumber'].toLowerCase().includes(filter) ||
				data['DeliverySite'].toLowerCase().includes(filter) ||
				data['StatusDescription'].toLowerCase().includes(filter) ||
				data['CreatedBy'].toLowerCase().includes(filter) ||
				data['ProductName'].toLowerCase().includes(filter) ||
				data['Quantity'].toLowerCase().includes(filter) ||
				data['EarliestArrivalTime'].toLowerCase().includes(filter) ||
				data['LatestArrivalTime'].toLowerCase().includes(filter) ||
				data['CustomerPO'].toLowerCase().includes(filter) ||
				data['OrderFlag'].toLowerCase().includes(filter) ||
				data['OrderDate'].toString().toLowerCase().includes(filter) ||
				data['RequestedDate'].toString().toLowerCase().includes(filter));
		};
	}

}
