<div class="container-fluid">
    <div class="row">
       
        <div class="col-lg-12 col-md-12">
            <div class="row header">
                <br>
                <span>{{selectedItem}}</span>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="main">
                <router-outlet></router-outlet>
            </div>
        </div>


    </div>

</div>