<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-lg-2 col-md-2">

            <div class="sidenav">
                <div>
                    <img class="menu-logo" src="../../../assets/nynas_logo.png">
                </div>
                <div>
                    <p class="text-center menu-heading">Customer Portal</p>
                </div>
                <div class="sidenavbar">
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Home'}"
                        (click)="showSelectedMenuItem('Home')">
                        <a routerLink="/dashboard" class="menu-list-names text-left">
                            <i class="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Home
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='My Company'}"
                        (click)="showSelectedMenuItem('My Company')">
                        <a routerLink="/my-company" class="menu-list-names text-left">
                            <i class="fa fa-building" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Forecast entry
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Safety Datasheet'}"
                        (click)="showSelectedMenuItem('Safety Datasheet')">
                        <a routerLink="/product-management/product-information" class="menu-list-names text-left">
                            <i class="fa fa-file-text" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Forecast history
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Ordering Procedure'}"
                        (click)="showSelectedMenuItem('Ordering Procedure')">
                        <a routerLink="/product-management/ordering-procedure" class="menu-list-names text-left">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Pending forecast
                        </a>
                    </div>

                </div>
                <div class="sideInfo">
                    <div> <span class="sideInfoLabel">User name: </span><span></span>User</div>
                    <div><span class="sideInfoLabel">Customer name:</span> <span></span>Test</div>
                    <div><span class="sideInfoLabel">Company name:</span> <span></span>Yit</div>
                    <div><span class="sideInfoLabel">User group: </span><span></span>New</div>
                    <div><span class="sideInfoLabel">Login time: </span><span></span>2019-09-10, 15:02:32</div>
                </div>
            </div>

        </div>
    </div>
</div> -->


<div class="container-fluid">
    <div class="row">

        <div class="col-lg-2 col-md-2 col-sm-10">

            <div class="sidenav">
                <div>
                    <img class="menu-logo" src="../../../assets/nynas_logo.png">
                </div>

                <div class="sidenavbar">
                    <div class="menu-item-padding">
                        <a routerLink="/dashboard" class="menu-list-names text-left" (click)="showSelectedMenuItem('Home')">
                            <i class="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Home
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Forecast entry'}"
                        (click)="showSelectedMenuItem('Forecast entry')"
                        *ngIf="validateMenu('6')">
                        <a [routerLink]="['forecast-entry']"
                            [ngClass]="{'selected-class-text': selectedItem === 'Forecast entry'}"
                            class="menu-list-names text-left">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Forecast entry
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Forecast history'}"
                        (click)="showSelectedMenuItem('Forecast history')"
                        *ngIf="validateMenu('7')">
                        <a [routerLink]="['history']"
                            [ngClass]="{'selected-class-text': selectedItem === 'Forecast history'}"
                            class="menu-list-names text-left">
                            <i class="fas fa-history" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Forecast history
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Forecast status'}"
                        (click)="showSelectedMenuItem('Forecast status')"
                        *ngIf="validateMenu('8')">
                        <a [routerLink]="['pending-forecast']"
                            [ngClass]="{'selected-class-text': selectedItem === 'Forecast status'}"
                            class="menu-list-names text-left">
                            <i class="fas fa-user-clock" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Forecast status 
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Ordering procedure'}"
                        (click)="showSelectedMenuItem('Ordering procedure')"
                        *ngIf="validateMenu('22')">
                        <a [routerLink]="['ordering-procedure']"
                            [ngClass]="{'selected-class-text': selectedItem === 'Ordering procedure'}"
                            class="menu-list-names text-left">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Ordering procedure
                        </a>
                    </div>


                </div>
                <!-- <div class="sideInfo">
                    <div> <span class="sideInfoLabel">User name: </span><span >{{name}}</span></div>
                    <div><span class="sideInfoLabel">Company name:</span><span>{{userInfo?.CustomerName}}</span></div>
                    <div><span class="sideInfoLabel">User group: </span><span>{{userInfo?.UserGroupName}}</span></div>
                    <div><span class="sideInfoLabel">Login time: </span><span>{{userInfo.LastLoginTime}}</span></div> 
                    <div><span class="sideInfoLabel">Current week number: </span><span> {{forecastEntryService.getCurrWeek()}} </span></div>
                </div>-->
            </div>

        </div>
        
        <div class="col-lg-10 col-md-10 col-sm-10">
            <div class="row header">
                <br>
                <span>{{selectedItem }}</span>
            </div>
            <div class="row">
                <div class='col-lg-12 col-md-12'>
                    <div class="main ">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>





<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-lg-2 col-md-2">

            <div class="sidenav">
                <div>
                    <img class="menu-logo" src="../../../assets/nynas_logo.png">
                </div>
                <div>
                    <p class="text-center menu-heading">Customer Portal</p>
                </div>
                <div class="menu-sub-div">
                    <p class="text-center menu-sub-heading">ORDER MANAGEMENT</p>
                </div>
                <div>
                    <div class="menu-item-padding">
                        <a routerLink="/dashboard" class="menu-list-names text-left">
                            <i class="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Home
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='FORECAST ENTRY'}"
                        (click)="showSelectedMenuItem('FORECAST ENTRY')">
                        <a [routerLink]="['forecast-entry']"
                            [ngClass]="{'selected-class-text': selectedItem === 'FORECAST ENTRY'}"
                            class="menu-list-names text-left">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Forecast Entry
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='FORECAST HISTORY'}"
                        (click)="showSelectedMenuItem('FORECAST HISTORY')">
                        <a [routerLink]="['history']"
                            [ngClass]="{'selected-class-text': selectedItem === 'FORECAST HISTORY'}"
                            class="menu-list-names text-left">
                            <i class="fas fa-history" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Forecast History
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='PENDING FORECAST'}"
                        (click)="showSelectedMenuItem('PENDING FORECAST')">
                        <a [routerLink]="['pending-forecast']"
                            [ngClass]="{'selected-class-text': selectedItem === 'PENDING FORECAST'}"
                            class="menu-list-names text-left">
                            <i class="fas fa-user-clock" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Pending Forecast
                        </a>
                    </div>





                </div>
                <div class="sideInfo">
                    <div>Username: <span class="forecastSelect"></span>{{UserName}}</div>
                    <div>Customer Name: <span class="forecastSelect"></span>{{customerName}}</div>
                    <div>UserGroup: <span class="forecastSelect"></span>{{UserName}}</div>
                    <div>Login Time: <span>{{myDate | date:'yyyy-MM-dd hh:mm:ss a': '+0530' }}</span></div>
                    <div>Current Week Number: <span>{{forecastEntryService.getCurrWeek()}}</span></div>
                </div>
            </div>

        </div>
        <div class="col-lg-10 col-md-10">
            <div class="row header">

                <br>
                <span>{{selectedItem}}</span>
            </div>

        </div>


    </div>
    <div class="main">
        <router-outlet></router-outlet>
    </div>
</div> -->