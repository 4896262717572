<div class="container-fluid sidepadding-none">
    <div class="col-lg-12">
        <div class='row no-gutters'>
            <div class="col-lg-2 col-md-2 col-xs-2 align-right">
                <label class="font-bold font-small" for="productType">{{'HOME.Year'| translate}}</label>
            </div>
            <div class="col-lg-2 col-md-2 col-xs-4 align-left" >
                <select class="selectDropdown form-control" [(ngModel)]="yearToSearch" (change)="showPriceChangeWarningPopUp()">
                    <option *ngFor="let year of years" [value]="year">
                        {{ year }}
                    </option>
                </select>
            </div>
            <div class="col-lg-6 offset-lg-2 align-right">
                    <button type="button" class="btn filter-button" for="filter"
                        (click)='filterIndex(yearToSearch)'>
                        <label class="font-bold " for="filter">{{'HOME.Find'| translate}}</label></button>
                </div>
        </div>
    </div>
    <div class=''  *ngIf="this.submitted">
    <div class="col-lg-12 mt-10 ">
        <div class='row no-gutters'>
            <div class="mt-5" [hidden]="displayTable"
                style="display: flex; justify-content: center; font-size: larger;">
                {{errorDesc}}
            </div>
            <div class="col-lg-12" [hidden]='!displayTable'>
                <!--Angular 7 Impl-->
                <div class='row' *ngIf="this.yearToSearch <= 2020">
                    <div class='col-lg-6'>
                        <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
                            [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"></canvas>
                        <div>
                            <span class="graph-title-font">
                                <span class="graph-title-brent-rate-line">&mdash;</span>
                                <span class="graph-title-text">{{'HOME.iceBrentNotation'| translate}}</span>
                            </span>
                            <span class="graph-title-font">
                                <span class="graph-title-conversion-rate-line">&mdash;</span>
                                <span class="graph-title-text">{{'HOME.conversionRate'| translate}}</span>
                            </span>
                        </div>
                    </div>
                    <div class='col-lg-6'>
                        <canvas baseChart [datasets]="lineChartData1" [labels]="lineChartLabels1" [options]="lineChartOptions1"
                            [colors]="lineChartColors1" [legend]="lineChartLegend1" [chartType]="lineChartType1"></canvas>
                        <div>
                            <span class="graph-title-font">
                                <span class="graph-title-conversion-rate-line">&mdash;</span>
                                <span class="graph-title-text">BIHE (€/MT)</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class='row' *ngIf="this.yearToSearch > 2020">
                    <div class='col-lg-6'>
                        <canvas baseChart [datasets]="lineChartData2" [labels]="lineChartLabels2" [options]="lineChartOptions2"
                            [colors]="lineChartColors2" [legend]="lineChartLegend2" [chartType]="lineChartType2"></canvas>
                        <div>
                            <div class="graph-title-font">
                                <span class="graph-title-brent-rate-line">&mdash;</span>
                                <span class="graph-title-text">{{'HOME.MonthlyaverageofPlattsHSFO35FOBRotterdamBargesUSDMT'| translate}}</span>
                            </div>
                            <div class="graph-title-font">
                                <span class="graph-title-conversion-rate-line">&mdash;</span>
                                <span class="graph-title-text">{{'HOME.MonthlyaverageofconversionrateUSDEUR'| translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div class='col-lg-6'>
                        <canvas baseChart [datasets]="lineChartData3" [labels]="lineChartLabels3" [options]="lineChartOptions3"
                            [colors]="lineChartColors3" [legend]="lineChartLegend3" [chartType]="lineChartType3"></canvas>
                        <div>
                            <span class="graph-title-font">
                                <span class="graph-title-conversion-rate-line">&mdash;</span>
                                <span class="graph-title-text">{{'HOME.MonthlyaverageofPlattsHSFO35FOBRotterdamBargesEURMT'| translate}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <div class="col-lg-12 mt-10 ">
        <div class='row no-gutters'>
            <div class="mt-5" [hidden]="displayTable"
                style="display: flex; justify-content: center; font-size: larger;">
                {{errorDesc}}
            </div>
            <div class="col-lg-12" [hidden]='!displayTable'>
                <div class="row">
                    <div class="col-lg-10 paddingNone">
                    <span class="tabletextFont">{{'HOME.PriceNotationTableText'| translate}}</span>
                </div>
                <div class="col-lg-2" *ngIf="!this.mobileQuery.matches">
                    <!-- <i (click)="downloadBitumenIndexData()" class="fas fa-download downloadIcon"><span class="downloadText">{{'HOME.Download'| translate}}</span></i> -->

                    <i (click)="download()" class="fas fa-download downloadIcon"><span class="downloadText">{{'HOME.Download'| translate}}</span></i>

                  </div>
                </div>
              
                <mat-table #table [dataSource]="dataSource" matSort class='table-material table horizontal_scrollbar'>

                   
                    <ng-container matColumnDef="ExchangeMonth">
                        <mat-header-cell class="header-font mat_column_ExchangeMonth small-column" *matHeaderCellDef> {{'HOME.Month'| translate}}
                            - {{'HOME.Year'| translate}}
                        </mat-header-cell>
                        <!-- <mat-cell *matCellDef="let PriceNotation" class="mat_column_ExchangeMonth">{{PriceNotation.Month}} - {{PriceNotation.Year}}
                        </mat-cell> -->

                        <mat-cell *matCellDef="let PriceNotation" class="mat_column_ExchangeMonth small-column">
                            {{'HOME.' + PriceNotation.Month | translate}} - {{PriceNotation.Year}}
                        </mat-cell>

                    </ng-container>

                   
                    <ng-container matColumnDef="ConversionRate">
                        <mat-header-cell class="header-font mat_column_ConversionRate small-column" *matHeaderCellDef>{{'HOME.USDEURexchangerate'| translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let PriceNotation" class="mat_column_ConversionRate small-column">{{PriceNotation.ConversionRate | number: '1.2-4'}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="BrentRate">
                        <mat-header-cell class="header-font mat_column_BrentRate medium-column" *matHeaderCellDef>
                            <span *ngIf="this.yearToSearch <= 2020">
                                {{'HOME.BRate'| translate}}
                            </span>
                            <span *ngIf="this.yearToSearch > 2020">
                                {{'HOME.PlattsHSFO35FOBRotterdamBargesUSDMT'| translate}}
                            </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let PriceNotation" class="mat_column_BrentRate medium-column">
                            <span *ngIf="this.yearToSearch <= 2020">{{PriceNotation.BrentRate | number: '1.2-3' }}</span>
                            <span *ngIf="this.yearToSearch > 2020">{{PriceNotation.HSFOUSDPerMT | number: '1.2-3' }}</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="BIHERate">
                        <mat-header-cell class="header-font mat_column_BIHERate medium-column" *matHeaderCellDef>
                            <span *ngIf="this.yearToSearch <= 2020">
                                {{'HOME.BIHE'| translate}}
                            </span>
                            <span *ngIf="this.yearToSearch > 2020">
                                {{'HOME.PlattsHSFO35FOBRotterdamBargesEURMT'| translate}}
                            </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let PriceNotation" class="mat_column_BIHERate medium-column">
                            <span *ngIf="this.yearToSearch <= 2020">{{PriceNotation.BIHERate | number: '1.2-3' }}</span>
                            <span *ngIf="this.yearToSearch > 2020">{{PriceNotation.HSFOEURPerMT | number: '1.2-3' }}</span>
                        </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="BIHEICEBRENT">
                        <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.BIHEICEBRENT'| translate}}

                        </mat-header-cell>
                        <mat-cell *matCellDef="let PriceNotation">
                        
                            <span>{{PriceNotation.BIHEICEBRENT | number}}</span>
                           
                        </mat-cell>
                    </ng-container> -->
                    

                    
                     <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns;sticky: true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <!-- <div class="row buttonGroup">
                    <button class=" downloadButton" type="submit" (click)="downloadBitumenIndexData()"> {{'HOME.Download'| translate}}</button>
                </div> -->
            </div>
        </div>
    </div> 
</div>
   
</div>