<div class="container reset_align">
  <div class="row">
    <div class="col-sm text-center">
      <span class="reset-password-icon"><i class="fas fa-unlock-alt"></i></span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm text-center">
      <span class="reset-password-title"> {{'HOME.ResetPassword'| translate}}
      </span>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-sm">
      <form [formGroup]="form1">
        <div class="form-group">
          <label><span class="label-text"> {{'HOME.UserId'| translate}}
          </span></label>
          <input formControlName="userId" id="userId" type="text" class="form-control text-input" />
          <div class="alert alert-danger" *ngIf="userId.touched && userId.invalid">
            <div *ngIf="userId.errors.required">{{'HOME.UserIdisrequired'| translate}}</div>
          </div>
        </div>
        <!-- <div class="form-group">
          <label><span class="label-text">Old Password</span></label>
          <input formControlName="oldPwd" id="oldPwd" type="password" class="form-control text-input" />
          <div class="alert alert-danger" *ngIf="oldPwd.touched && oldPwd.invalid">
            <div *ngIf="oldPwd.errors.required">Old Password is required.</div>
            <div *ngIf="oldPwd.errors.shouldBe1234">Old Password is not valid.</div>
          </div>
        </div> -->

        <!-- <div class="form-group">
            <label>New Password</label>
            <input formControlName="newPwd" id="newPwd" type="password" class="form-control" />
            <div class="alert alert-danger" *ngIf="newPwd.touched && newPwd.invalid">
              <div *ngIf="newPwd.errors.required">New Password is required.</div>
            </div>
          </div> -->

        <!-- <div class="form-group">
            <label>Confirm Password</label>
            <input formControlName="confirmPwd" id="confirmPwd" type="password" class="form-control" />
            <div class="alert alert-danger" *ngIf="confirmPwd.touched && confirmPwd.invalid">
              <div *ngIf="confirmPwd.errors.required">Confirm Password is required.</div>
            </div>
            <div class="alert alert-danger" *ngIf="confirmPwd.valid && form.invalid && form.errors.pwdsDontMatch">
              Passwords do not match.
            </div>
          </div> -->
      </form>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-sm text-center">
      <button class="btn btn-sm btn-primary dialog-footer-button" (click)="resetPassword()"> {{'HOME.Reset'| translate}}
      </button>
    </div>
    <div class="col-sm text-center cancel-button-padding">
      <button class="btn btn-sm btn-primary dialog-footer-button" (click)="cancel()"> {{'HOME.Cancel'| translate}}
      </button>
    </div>
  </div>
</div>