import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { Customer } from 'src/app/user-management/customer';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import { AddDeliverySiteComponentService } from './add-delivery-site.component.service';
import { Country } from './country';
import { Router } from '@angular/router';
import { DelSite } from '../modify-delivery-site/DelSite';
import { DatePipe } from '@angular/common';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-add-delivery-site',
  templateUrl: './add-delivery-site.component.html',
  styleUrls: ['./add-delivery-site.component.css']
})
export class AddDeliverySiteComponent implements OnInit, OnDestroy {
  
  companyName: any;
  addDeliveryForm: FormGroup;
  submitted = false;
  // Countries: Country[] = [];
  Countries: Country[] = [];
  
  cities = [{
    id: 1,
    name: 'Vilnius'
  },
  {
    id: 2,
    name: 'Kaunas'
  },
  {
    id: 3,
    name: 'Pavilnys',
    disabled: true
  },
  {
    id: 4,
    name: 'Pabradė'
  },
  {
    id: 5,
    name: 'Klaipėda'
  }
];
  
  countryCodeList: number[] = [358, 46, 47, 45, 91, 372];
  userInfo: any;
  role: any;
  customerName: any;
  customerNamePrefix:any;
  customerId: string;
  customerList: Customer[] = [];
  selectedDeliverySite: DelSite;
  isLoggedInUserPortalAdmin: boolean;
  statusList = [{code: '', description: 'Select status'}, {code: 'Approved', description: 'Approved'}, {code: 'Rejected', description: 'Rejected'}];
  isLoggedInUserAdmin: boolean;
  loggedInUserId: string;
  loggedInUserName: string;
  countryCode: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  isDeliverySiteNameMoreThan32Characters: boolean;

  constructor(private formBuilder: FormBuilder,
    private addDeliverySiteComponentService: AddDeliverySiteComponentService,
    private sharedService: AppService,
		private dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef, 
    private media: MediaMatcher) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.role = (this.userInfo ? this.userInfo.RoleName : '');
    this.customerName = (this.userInfo ? this.userInfo.CustomerName : '');
    this.customerId = (this.userInfo ? this.userInfo.CustomerID : '');
    this.isLoggedInUserPortalAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA") : false);
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "A") : false);
    this.loggedInUserId = (this.userInfo ? this.userInfo.UserID : '');
    this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + ' ' + this.userInfo.LastName) : '');
    this.countryCode = this.userInfo.CountryCode;
		const navigation = this.router.getCurrentNavigation();
		if (navigation) {
			const state = navigation.extras.state as {
				selectedDeliverySite: DelSite
			};
			if (state && state.selectedDeliverySite) {
				this.selectedDeliverySite = state.selectedDeliverySite;
			}
		}
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  

  get fields() {
		return this.addDeliveryForm.controls;
  }
  changeCountryName(event: any) {}

  saveDeliverySiteDetails() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.addDeliveryForm.invalid || this.isDeliverySiteNameMoreThan32Characters) {
      // this.openSaveUserDialog('There are items that require your attention', 'Please complete all fields marked with *', false,'add-delivery-site-save-fail');
      this.openSaveUserDialog('There are items that require your attention', 'Please complete all fields marked with *', false,'required-fields-validation-message');
      return;
    }

    // Set value for field not present in UI form
    this.addDeliveryForm.controls['UpdatedBy'].setValue(this.loggedInUserName);
    this.addDeliveryForm.controls['CreatedBy'].setValue(this.loggedInUserName);
    this.addDeliveryForm.controls['UpdatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
    if (this.addDeliveryForm.value['CustomerId']) {
      this.addDeliveryForm.controls['CustomerName'].setValue(this.getCustomerNameById(this.addDeliveryForm.value['CustomerId']));
    }

    // Save phone number with country code
    // this.addDeliveryForm.controls['PrimaryContact'].setValue(this.addDeliveryForm.value['PrimaryContactCountryCode'] + this.addDeliveryForm.value['PrimaryContact']);
    let primaryContactWithCountryCode = (this.addDeliveryForm.value['PrimaryContactCountryCode'] + this.addDeliveryForm.value['PrimaryContact']); // FIN123

    // Set value for SendReminderFlag in payload
    let sendReminderFlag: string = (this.addDeliveryForm.value['SendReminderFlag'] ? 'TRUE' : 'FALSE');

    // Check if it is edit user or create new user
    if (this.selectedDeliverySite) {
      this.addDeliverySiteComponentService.updateDeliverySite(this.addDeliveryForm.value, primaryContactWithCountryCode, sendReminderFlag)
        .subscribe(
          (response: any) => {
            if (this.getUpdatedFieldsName() && this.getUpdatedFieldsName().length) {
              this.saveStatusDialog(response, 'DS_UPDATE', primaryContactWithCountryCode, this.getUpdatedFieldsName(), null);
            } else {
              this.openSaveUserDialog('Delivery Site Details Saved Successfully', null, true, 'add-delivery-site-save-success');
            }

            // If Billing reference number is changed then send email to Neste
            let existingDeliverySiteObject = this.selectedDeliverySite;
            let updatedDeliverySiteObject = this.addDeliveryForm.value;
            if (existingDeliverySiteObject['BillingReference'] !== updatedDeliverySiteObject['BillingReference']) {
              this.saveStatusDialog(response, 'DS_BILLINGREFERENCENUMBER_CHANGE', primaryContactWithCountryCode, '', null);
            }

            // If Send Forecast Reminder flag is updated then send email to respective users only
            if (existingDeliverySiteObject['SendReminderFlag'] !== sendReminderFlag) {
              this.saveStatusDialog(response, 'DS_SEND_FORECAST_REMINDER', primaryContactWithCountryCode, '', sendReminderFlag);
            }
          });
    } else {
      // Set stage as 'Sent to Depot' only when creating a new delivery site
      this.addDeliveryForm.controls['ApprovalStage'].setValue('Sent to Depot');
      this.addDeliverySiteComponentService.addDeliverySiteService(this.addDeliveryForm.value, primaryContactWithCountryCode, sendReminderFlag)
        .subscribe(
          (response: any) => {
            this.saveStatusDialog(response, 'DS_CREATE', primaryContactWithCountryCode, '', null);
          });
    }

    /* this.addDeliverySiteComponentService.updateDeliverySite(this.addDeliveryForm.value)
      .subscribe(
        (response: any) => {
          this.saveStatusDialog(response);
        }); */

    /* this.addDeliverySiteComponentService.addDeliverySiteService(this.addDeliveryForm.value)
    .subscribe(
      (response: any) => {
        this.saveStatusDialog(response);
      }); */

    /* // Check if it is UpdateDeliverySite or UpdateDepotStatus or UpdateJDEStatus
    if (!this.addDeliveryForm.controls['DepotAddressNumber'].value && !this.addDeliveryForm.controls['JdeAddressNumber'].value) {
      
      // Set value for field not present in UI form
      this.addDeliveryForm.controls['ApprovalStage'].setValue('Sent to Depot Master for approval');
      this.addDeliverySiteComponentService.updateDeliverySite(this.addDeliveryForm.value)
        .subscribe(
          (response: any) => {
            this.saveStatusDialog(response);
          });
    } else if (this.addDeliveryForm.controls['DepotAddressNumber'].value && !this.addDeliveryForm.controls['JdeAddressNumber'].value) {
      // Set value for field not present in UI form
      this.addDeliveryForm.controls['Type'].setValue('JDE_CONTACT');
      this.addDeliveryForm.controls['Status'].setValue('Approved');
      this.addDeliverySiteComponentService.updateDepotStatus(this.addDeliveryForm.value)
        .subscribe(
          (response: any) => {
            this.saveStatusDialog(response);
          });
    } else if (this.addDeliveryForm.controls['DepotAddressNumber'].value && this.addDeliveryForm.controls['JdeAddressNumber'].value) {
      this.addDeliverySiteComponentService.updateJDEStatus(this.addDeliveryForm.value)
        .subscribe(
          (response: any) => {
            this.saveStatusDialog(response);
          });
    } */

  }

	cancelDeliverySiteDetails() {
		this.router.navigate(["app/home"]);
	}

  onSubmit() {
    this.submitted = true;
    if (!this.addDeliveryForm.valid) {
      return false;
    }
  }
  


  ngOnInit() {
    
    this.addDeliveryForm = this.formBuilder.group({
			PrimaryContact: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      DeliverySiteName:['', Validators.required],
      SubscriberEmailAddress: ['', [Validators.required, Validators.email]],
      CarrierEmailAddress: ['', [Validators.required, Validators.email]],
      DepotAddressNumber: ['', Validators.nullValidator],
      BillingReference: ['', Validators.required],
      AddressLine1: ['', Validators.required],
      AddressLine2: ['', Validators.nullValidator],
      DeliveryPostalCode: ['', Validators.required],
      City: ['', Validators.required],
      CountryId: ['', Validators.required],
      JdeAddressNumber: ['', Validators.nullValidator],
      PrimaryContactCountryCode: ['', Validators.required],
      CustomerId: ['', Validators.required],
      Status: ['', Validators.required],
			ApprovalStage: [''],
			Type: [''],
      selectedCustomerName: [this.customerName],
      UpdatedBy: [''],
      CreatedBy: [''],
      CustomerName:  [''],
      UpdatedOn: [''],
      SendReminderFlag: ['', Validators.nullValidator]
    });


    // onChange(e){
    //   console.log("test123"+ JSON.stringify(e));






    this.getDeliverySiteDetails();

    if (!this.isLoggedInUserPortalAdmin) {
      this.addDeliveryForm.controls['CustomerId'].setValue(this.customerId);
      this.addDeliveryForm.controls['CustomerName'].setValue(this.customerName);      
    }

    if (!this.selectedDeliverySite) {
      // Set value for field not present in UI form
      this.addDeliveryForm.controls['Status'].setValue('In progress');

      // Commented old code kept for future reference
      // this.addDeliveryForm.controls['SendReminderFlag'].setValue(this.isLoggedInUserPortalAdmin && this.countryCode !== 'GBR');

      this.addDeliveryForm.controls['SendReminderFlag'].setValue(false);  // Set default value of Send reminder flag to false for all
    }

    // this.Countries.push(new Country('NRW', 'Norway', 47));
    // this.Countries.push(new Country('NRW', 'Sweden', 47));
    // this.Countries.push(new Country('NRW', 'Finland', 47));
  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }


  getCustomerNameAbbreviation() {
    if (this.addDeliveryForm.value['CustomerId']) {
      this.addDeliveryForm.controls['DeliverySiteName'].setValue(this.getCustomerAbbreviationById(this.addDeliveryForm.value['CustomerId']));
    }
    else {
      this.addDeliveryForm.controls['DeliverySiteName'].setValue('');
    }
    this.checkDeliverySiteNameLength();
  }

  getCustomerAbbreviationById(id: string): string {
    let customerNameAbbreviation = '';
    for (let customer of this.customerList) {
      if (customer['CustomerID'] === id) {
        customerNameAbbreviation = customer['CustomerName_Prefix'];
        break;
      }
    }
    return customerNameAbbreviation;
  }

  getDeliverySiteDetails() {
    const requestArray = [];


    const request1 = this.addDeliverySiteComponentService.getCountries();
		const request2 = this.addDeliverySiteComponentService.getCustomerNames(this.countryCode);
    requestArray.push(request1);
		requestArray.push(request2);
    
    forkJoin(requestArray).subscribe(results => {
      // if (results && results.length) {
        if (results[0] && results[0]['Countries'] && results[0]['Countries'].length) {
          // this.Countries = results[0].Countries;
          results[0]['Countries'].forEach((country, index) => {
            var countryCode: number = 0;
            if (country["CountryID"] === 'FIN') {
              countryCode = 358;
            } else if (country["CountryID"] === 'SWE') {
              countryCode = 46;
            } else if (country["CountryID"] === 'NOR') {
              countryCode = 47;
            } else if (country["CountryID"] === 'EST') {
              countryCode = 372;
            } else if (country["CountryID"] === 'DNK') {
              countryCode = 45;
            } else if (country["CountryID"] === 'GBR') {
              countryCode = 44;
            }
            this.Countries.push(new Country(country["CountryID"], country["CountryName"], countryCode));
            // this.Countries.push(new Country(country["CountryID"], country["CountryName"], (this.countryCodeList[index] ? +this.countryCodeList[index] : 0)));
          });
        }
        if (results[1] && results[1]['Customers'] && results[1]['Customers'].length) {
					this.customerList = results[1]['Customers'];
        }
        if (this.selectedDeliverySite) {
          this.addDeliveryForm.patchValue(this.selectedDeliverySite);
          this.addDeliveryForm.controls['DeliverySiteName'].setValue(this.selectedDeliverySite.DeliverySite);

          // Split country code and phone number
          // this.addDeliveryForm.controls['PrimaryContactCountryCode'].setValue(this.selectedDeliverySite['CountryId']);
          let primaryContactWithCountryCode = this.selectedDeliverySite['PrimaryContact'];	//if primaryContactWithCountryCode = 'EST123'
          this.addDeliveryForm.controls['PrimaryContactCountryCode'].setValue(primaryContactWithCountryCode.substr(0, 3));	// set PrimaryContactCountryCode = 'EST'
          this.addDeliveryForm.controls['PrimaryContact'].setValue(primaryContactWithCountryCode.substring(3));	// set PrimaryContact='123'

          this.addDeliveryForm.controls['selectedCustomerName'].setValue(this.selectedDeliverySite['CustomerName']);
          /* if (this.isLoggedInUserPortalAdmin) {      
            this.addDeliveryForm.controls['Status'].setValue('Approved');
          } */
        }

      // Set customer abbreviation in delivery site name as a prefix, when logged in user is customer admin or user, for creating a new delivery site
      if (!this.isLoggedInUserPortalAdmin && !this.selectedDeliverySite) {
        this.addDeliveryForm.controls['DeliverySiteName'].setValue(this.getCustomerAbbreviationById(this.customerId));
      }

      if (this.selectedDeliverySite) {
        this.addDeliveryForm.controls['SendReminderFlag'].setValue(this.selectedDeliverySite['SendReminderFlag'] === 'TRUE');
      }

    // }
  });


}

  saveStatusDialog(response: any, emailNotificationKey: string, primaryContactWithCountryCode: string, fieldsChanged: string, sendReminderFlag: string) {
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {

        // Commented old code kept for future reference
        // this.openSaveUserDialog('Delivery Site Details Saved Successfully', null, true, 'add-delivery-site-save-success');

        // Call EmailService to send email notification
        this.sendEmailNotification(emailNotificationKey, primaryContactWithCountryCode, fieldsChanged, sendReminderFlag);

      } else if (response['ResponseCode'] === '201' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'DELIVERY SITE ALREADY PRESENT') {
        this.openSaveUserDialog('Delivery site already exists','', false, 'add-delivery-site-save-fail-already-present');
      } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.openSaveUserDialog('Failed to Save Delivery Site Details','', false, 'add-delivery-site-save-fail');
      } else {
        this.openSaveUserDialog('Failed to Save Delivery Site Details', null, false, 'add-delivery-site-save-fail');
      }
    } else {
      this.openSaveUserDialog('Failed to Save Delivery Site Details', null, false, 'add-delivery-site-save-fail');
    }
  }


	openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
		this.dialog.open(DialogBodyComponent, {
			data: {
				header: headerTitle,
				message: message ? message : ' ',
				buttonText: {
					ok: 'Ok'
				},
        isSuccess: isSuccess,
        headerNumber: headerNumber,
        
			},
      // height: '37%',
      height: 'auto',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
		});
  }

  getCustomerNameById(id: string): string {
    let customerName = '';
    for (let customer of this.customerList) {
      if (customer['CustomerID'] === id) {
        customerName = customer['CustomerName'];
        break;
      }
    }
    return customerName;
  }

  sendEmailNotification(key: string, primaryContactWithCountryCode: string, fieldsChanged: string, sendReminderFlag: string): void {
    this.addDeliverySiteComponentService.emailService(key, this.countryCode, this.addDeliveryForm.value, primaryContactWithCountryCode, fieldsChanged, sendReminderFlag)
      .subscribe(
        (response: any) => {
          if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            this.dialog.closeAll();
            this.openSaveUserDialog('Delivery Site Details Saved Successfully', null, true, 'add-delivery-site-save-success');
          } else {
            this.openSaveUserDialog('Failed to Save Delivery Site Details', null, false, 'add-delivery-site-save-fail');
          }
        });
  }

  getUpdatedFieldsName(): string {
    let updatedFields = [];
    let existingDeliverySiteObject = this.selectedDeliverySite;
    let updatedDeliverySiteObject = this.addDeliveryForm.value;

    if (existingDeliverySiteObject['BillingReference'] !== updatedDeliverySiteObject['BillingReference']) {
      updatedFields.push(' Billing reference ');
    }
    if (existingDeliverySiteObject['SubscriberEmailAddress'] !== updatedDeliverySiteObject['SubscriberEmailAddress']) {
      updatedFields.push(' Primary email address ');
    }
    if (existingDeliverySiteObject['PrimaryContact'] !== (updatedDeliverySiteObject['PrimaryContactCountryCode'] + updatedDeliverySiteObject['PrimaryContact'])) {
      updatedFields.push(' Primary contact ');
    }
    if (existingDeliverySiteObject['CarrierEmailAddress'] !== updatedDeliverySiteObject['CarrierEmailAddress']) {
      updatedFields.push(' Primary Transportation Email ');
    }
    if ((existingDeliverySiteObject['AddressLine1'] !== updatedDeliverySiteObject['AddressLine1']) || (existingDeliverySiteObject['AddressLine2'] !== updatedDeliverySiteObject['AddressLine2'])) {
      updatedFields.push(' Address ');
    }
    if (existingDeliverySiteObject['DeliveryPostalCode'] !== updatedDeliverySiteObject['DeliveryPostalCode']) {
      updatedFields.push(' Delivery postal code ');
    }
    if (existingDeliverySiteObject['City'] !== updatedDeliverySiteObject['City']) {
      updatedFields.push(' City ');
    }

    return updatedFields.toString();
  }

  checkDeliverySiteNameLength(): void {
    this.isDeliverySiteNameMoreThan32Characters = (this.addDeliveryForm.value['DeliverySiteName'] && this.addDeliveryForm.value['DeliverySiteName'].length > 32);
  }
  
}

