import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../shared-modules/services/shared.service';
import { DialogBodyComponent } from '../../user-management/dialog-body/dialog-body.component';
import { LanguageSelectionService } from './language-selection.service';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.css']
})
export class LanguageSelectionComponent implements OnInit, OnDestroy {

  languageList: any;
  languageSelected: string = '';
  productId: string;
  country: string;
  documentType: string;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  userInfo: any;
  countryCode: string;
  viewButtonClicked: boolean;
  countryCodeMarket: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<LanguageSelectionComponent>,
    private languageSelectionService: LanguageSelectionService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private sharedService: AppService) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.countryCode = this.userInfo.CountryCode;
    if (data) {
      this.productId = data.productId;
      this.country = data.country;
      this.documentType = data.documentType;
      this.countryCodeMarket = data.countryCodeMarket;
    }
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.getLanguageList();
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getProductDocument(): void {
    this.viewButtonClicked = true;
    if (this.languageSelected) {
      this.languageSelectionService.fetchProductDetailsFromSaleforce(this.country, this.documentType, this.languageSelected, this.productId)
        .subscribe(
          response => {
            if (response) {
              this.showStatusDialog(response);
            }
          });
      this.dialogRef.close(true);
    }
  }

  showStatusDialog(response: any) {
    if (response && response['ResponseCode'] === '200' && response['Details'] && response['Details']['doc_List'] && response['Details']['doc_List']['url']) {
      this.showProductDocument(response['Details']['doc_List']['url']);
    } else {
      this.openDialog('No document found', '', false, 'no-document-found');
    }
  }

  openDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        isSuccess: isSuccess,
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        }
      },
      height: '39%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  cancel(): void {
    this.dialogRef.close(true);
  }

  showProductDocument(url: string) {
    if (url) {
      window.open(url, "_blank");
    } else {
      this.openDialog('No document found', '', false, 'no-document-found');
    }
  }

  getLanguageList() {
    this.languageList = [];
    this.languageSelectionService.getLanguageForCountry(this.countryCodeMarket)
      .subscribe(
        response => {
          this.languageList = response['LangaugeCollection'];
        });
  }
}
