<div class="container-fluid zero-left-right-padding">
    <div class="pt-2 col-lg-12 col-md-12 zero-left-right-padding sales_report_div_mv">
        <div class="row pt-1">
            <div class="pl-3 ">
                <label for="customerName">
                    <span class="customer-name-label">{{'HOME.Customername'| translate}}
                    </span>
                </label>
            </div>
            <div class=" customer_name_dropdown_div customer_name_dropdown_div_mv " *ngIf="isLoggedInUserPortalAdmin">
                <select class="customer-name-dropdown customer_name_dropdown_width" id="customer-name"
                    [(ngModel)]='customerIdUserSelected' (change)="this.getDeliverySites()">
                    <option *ngIf="isLoggedInUserPortalAdmin" value="">{{'HOME.All'| translate}}</option>
                    <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                        {{customer.CustomerName}}
                    </option>
                </select>
            </div>
            <div class="col-3 pl-1" *ngIf="!isLoggedInUserPortalAdmin">
                <input type="text" class="customer-name-textbox pr-5" [(ngModel)]='customerName' disabled />
            </div>
            <div class="delivery_site_label_div_mv">
                <label for="deliverysite">
                    <span class="customer-name-label">{{'HOME.Deliverysite'| translate}}
                    </span>
                </label>
            </div>
            <div class="delivery_site_dropdown_div delivery_site_dropdown_div_mv">
                <select class="customer-name-dropdown delivery_site_dropdown_width" id="delivery-site"
                    [(ngModel)]='deliverySiteUserSelected'>
                    <option value="">{{'HOME.All'| translate}}</option>
                    <option *ngFor="let deliverySite of deliverySiteList" [value]="deliverySite">{{deliverySite}}
                    </option>
                </select>
            </div>
            <div class="downloadDiv" *ngIf="!mobileQuery.matches">
                <div *ngIf="displayTable">
                    <span>
                        <i (click)="downloadReportsData(customerIdUserSelected)" class="fas fa-download downloadIcon">
                            <span class="downloadText">{{'HOME.Download'| translate}}</span>
                        </i>
                    </span>
                    <span class="pl-3" *ngIf="isLoggedInUserPortalAdmin">
                        <i (click)="downloadReportsData('')" class="fas fa-download downloadIcon">
                            <span class="downloadText">{{'HOME.DownloadAll'| translate}}</span>
                        </i>
                    </span>
                </div>
            </div>
        </div>
        <div class="row pt-3 no-gutters">
            <div class="col-lg-1 pl-3 fromDate">
                <label for="FromDate">
                    <span class="customer-name-label">{{'HOME.FromDate'| translate}}
                    </span>
                </label>
            </div>
            <div class="col-lg-2 pl-4">
                <mat-form-field class="datepicker">
                    <input matInput [matDatepicker]="picker" placeholder="" [(ngModel)]='startDate'
                        [min]="previousYear1stJanuaryDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <div *ngIf="startDate == null" class="invalid-date">
                    {{'HOME.FromDateIsRequired'| translate}}
                </div>
                <div *ngIf="fromDateIsOlderThan1stJanuaryOfPreviousYear" class="invalid-date">
                    {{'HOME.FromDateIsOlderThan1stJanuary2020' | translate: { previousYear: previousYear } }}
                </div>
            </div>
            <div class="col-lg-1 toDate pl-4">
                <label for="ToDate">
                    <span class="customer-name-label">{{'HOME.ToDate'| translate}}
                    </span>
                </label>
            </div>
            <div class="col-lg-2 pl-4">
                <mat-form-field class="datepicker">
                    <input matInput [matDatepicker]="picker1" placeholder="" [(ngModel)]='endDate'
                        [min]="previousYear1stJanuaryDate">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <div *ngIf="endDate == null" class="invalid-date">
                    {{'HOME.ToDateIsRequired'| translate}}
                </div>
            </div>
            <div class="col-lg-1 findButton_div_mv pl-4">
                <button class="findButton" (click)="getDeliveryAnalysisDetails()">{{'HOME.Find'| translate}}</button>
            </div>
            <div class="global-search-text-box">
                <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
            </div>
        </div>
    </div>
    <div class="row pt-3" *ngIf=" !displayTable && isFindButtonClicked">
        <div class="col-lg-12 mt-3">
            <div class="mt-5" class="no-records-message">
                <span>{{'HOME.Norecordsfound'| translate}}</span>
            </div>
        </div>
    </div>
    <div class="row no-bottom-margin" [hidden]='!displayTable '>
        <div class="col-lg-12 table_div_mv">
            <div class="delivery-analysis-container">
                <mat-table #table [dataSource]="dataSource"
                    #dailyReportTableSort="matSort" matSort matSortActive="ORDER_NUMBER" matSortDirection="asc">
                    <ng-container matColumnDef="CUSTOMER_PO">
                        <mat-header-cell class="column-medium1" *matHeaderCellDef mat-sort-header>
                            <div *ngIf="countryCode !=='SWE' && countryCode !=='DNK'">
                                <div class="align-text-left">{{'HOME.CustomerPO'| translate}}</div>
                                <div class="align-text-left">{{'HOME.number'| translate}}</div>
                            </div>
                            <div *ngIf="countryCode ==='SWE' || countryCode ==='DNK'">
                                <div>{{'HOME.Deliveryticket'| translate}}</div>
                                <div class="align-text-left">{{'HOME.number'| translate}}</div>
                            </div>
                        </mat-header-cell>
                        <mat-cell class="column-medium1" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.CUSTOMER_PO }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="DEPOT_NAME">
                        <mat-header-cell class="column_medium" *matHeaderCellDef mat-sort-header>
                            {{'HOME.LoadingPoint'| translate}}
                        </mat-header-cell>
                        <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.DEPOT_NAME }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ACTUAL_SHIP_DATE">
                        <mat-header-cell class="column-medium" *matHeaderCellDef mat-sort-header>
                            <div *ngIf="countryCode !=='SWE' && countryCode !=='DNK'">
                                <div class="align-text-left">{{'HOME.ActualShipDate'| translate}}</div>
                            </div>
                            <div *ngIf="countryCode ==='SWE' || countryCode ==='DNK'">
                                <div>{{'HOME.Shipdate'| translate}}</div>
                            </div>
                        </mat-header-cell>
                        <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.ACTUAL_SHIP_DATE }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="DELIVERY_SITE">
                        <mat-header-cell class="column-large" *matHeaderCellDef mat-sort-header>
                            {{'HOME.DeliveryPlace'| translate}}
                        </mat-header-cell>
                        <mat-cell class="column-large" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.DELIVERY_SITE }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="PRODUCT_NAME">
                        <mat-header-cell class="column-large" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Product'| translate}}
                        </mat-header-cell>
                        <mat-cell class="column-large" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.PRODUCT_NAME }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="SHIPPED_QTY">
                        <mat-header-cell class="column-small" *matHeaderCellDef>
                            {{'HOME.QuantityMT'| translate}}
                        </mat-header-cell>
                        <mat-cell class="column-small" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.SHIPPED_QTY | number:'1.3-3':'fr-FR' }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="TEST">
                        <mat-header-cell class="column-medium" *matHeaderCellDef>
                            {{'HOME.Test'| translate}}
                        </mat-header-cell>
                        <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.TEST }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="RESULT">
                        <mat-header-cell class="column-small header-align-right" *matHeaderCellDef>
                            {{'HOME.Result'| translate}}
                        </mat-header-cell>
                        <mat-cell class="column-small header-align-right" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.RESULT}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="UOM">
                        <mat-header-cell class="column_small" *matHeaderCellDef>
                            {{'HOME.Unitofmeasure'| translate}}
                        </mat-header-cell>
                        <mat-cell class="column_small" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.UOM }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="TYPE_OF_PRODUCT">
                        <mat-header-cell class="column-large" *matHeaderCellDef>
                            {{'HOME.Typeofproduct'| translate}}
                        </mat-header-cell>
                        <mat-cell class="column-large" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.TYPE_OF_PRODUCT }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="SAMPLE_ID">
                        <mat-header-cell class="column-small" *matHeaderCellDef>
                            {{'HOME.SampleId'| translate}}
                        </mat-header-cell>
                        <mat-cell class="column-small" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.SAMPLE_ID }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ORDER_NUMBER">
                        <mat-header-cell class="column-small" *matHeaderCellDef mat-sort-header>
                            <div>
                                <div class="align-text-left">{{'HOME.NynasOrder'| translate}}</div>
                                <div class="align-text-left">{{'HOME.number'| translate}}</div>
                            </div>
                        </mat-header-cell>
                        <mat-cell class="column-small" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.ORDER_NUMBER }}
                        </mat-cell>
                    </ng-container>
                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsDailyReports; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsDailyReports;">
                    </mat-row>
                </mat-table>
            </div>
        </div>
    </div>
</div>