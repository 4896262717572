<div class="container">
    <div class="row pt-1 pb-1 no-gutters">
        <div class="col-md-6 pt-1">
            <label class="font-bold font-small" for="country">
                Country
            </label>
        </div>
        <div class="col-md-6">
            <select class="countryDropdown" [(ngModel)]='selectedCountryCode' (change)="getCustomerListAndUserGroupDetails()">
                <option *ngFor="let country of countryList" [ngValue]="country.COUNTRY_CODE">
                    {{country.COUNTRY_NAME}}
                </option>
            </select>
        </div>
    </div>
    <div class="row pt-1 pb-1 no-gutters">
        <div class="col-md-6 pt-1">
            <label class="font-bold font-small" for="country">
                Customer name
            </label>
        </div>
        <div class="col-md-6">
            <select class="countryDropdown" [(ngModel)]='selectedCustomerId' (change)="getUserGroupDetailsByCustomerId()">
                <option value="">Select</option>
                <option *ngFor="let customer of customerList" [value]="customer.CustomerID">
                    {{customer.CustomerName}}
                </option>
            </select>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="col-md-12">
            <label class="message-font" [hidden]="!(proceedButtonClicked && !selectedCustomerId)"> Please select customer
            </label>
        </div>
    </div>

    <!-- Commented code for user group dropdown, kept for future reference -->
    <!-- <div class="row pt-1 pb-1 no-gutters">
        <div class="col-md-6 pt-1">
            <label class="font-bold font-small" for="country">
                User group
            </label>
        </div>
        <div class="col-md-6">
            <select class="countryDropdown" [(ngModel)]='selectedUserGroupId'>
                <option value="">Select</option>
                <option *ngFor="let userGroup of userGroupList" [value]="userGroup.UserGroupID">
                    {{ userGroup.UserGroupName }}
                </option>
            </select>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="col-md-12">
            <label class="message-font" [hidden]="!(proceedButtonClicked && !selectedUserGroupId)"> Please select user group
            </label>
        </div>
    </div> -->

    <div class="row pt-3">
        <div class="col-sm proceed-button">
            <button class="btn btn-sm btn-primary dialog-footer-button" (click)="proceed()">
                Proceed
            </button>
        </div>
        <div class="col-sm cancel-button cancel-button-padding">
            <button class="btn btn-sm btn-primary dialog-footer-button" (click)="cancel()">
                Cancel
            </button>
        </div>
    </div>
</div>