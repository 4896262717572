<div class="container-main">
 
    <div class="row no-gutters">
   
      <div class="col-lg-1 margin-first-blank">      
      </div>
   
      <div class="col-lg-3  margin-location">
        <i class="icon-size fas fa-map-marker-alt"></i>
        &nbsp; Lindertorpsvagen 7, Stockholm, Sweden
      </div>
   
      <div class="col-lg-2  margin-call">
        <i class="icon-size fa fa-phone-square " aria-hidden="true"></i>
        &nbsp; +4-78-XXX XX XX
      </div>
   
      <div class="col-lg-2 margin-mail">
        <i class="fa icon-size ">&#xf0e0;</i>
        &nbsp; xxxxx@xxx.com
      </div>

      <div class="col-lg-1  margin-social-media">
        <a href="https://in.linkedin.com/showcase/nynas-bitumen" class="icon-size fab fa-linkedin" target="_blank"></a>
    </div>
   
      <div class="col-lg-1 margin-social-media" >
          <a href="https://twitter.com/nynasbitumen?lang=en" class="icon-size fab fa-twitter-square" target="_blank"></a>
      </div> 
      
   
      <div class="col-lg-1  margin-social-media">
          <a href="https://www.youtube.com/channel/UCdWoox6ZB_55r_DGx62-G6w"
              class="icon-size icon-size fab fa-youtube" target="_blank"></a>
      </div>
   
      <div class="col-lg-1 margin-last-blank">
      </div>
   
   
   
    </div>
   
  </div>
