import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, pipe, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoaderService } from '../loader.service';

// import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private message: string;

  constructor(private _router: Router,private accessid:LoaderService) { }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(): boolean {
    return this.accessid.isAuthenticated();
  }

  // simulate jwt token is valid
  // https://github.com/theo4u/angular4-auth/blob/master/src/app/helpers/jwt-helper.ts
  // isTokenExpired(): boolean {
  //   return false;
  // }

  /**
   * this is used to clear local storage and also the route to login
   */
  logout(): void {
    this.accessid.clearToken();
    this._router.navigate(['/login']);
  }

  decode() {
    // Uncomment once token is obtained in _authService
    // return decode(localStorage.getItem('token'));
  }
} 