<mat-sidenav-container fullscreen 
  class="example-sidenav-container">
  <!-- <mat-sidenav #sidenav mode="side" opened={{!isMobileDevice}} role="navigation" class='no-v-scroll'> -->
      <mat-sidenav #sidenav role="navigation" class='no-v-scroll' opened={{!isMobileDevice}} 
        [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches">

    <img class="menu-logo" src="../../../assets/nynas_logo.png">

    <mat-nav-list>
      <app-menu-list-item *ngFor="let item of navItems" [item]="item" (pageTitle)='displayRoute($event, item)'>
      </app-menu-list-item>
      <!-- <a mat-list-item [routerLink]="'/product-management'"  routerLinkActive="active-list-item"> Product management </a>
                    <a mat-list-item [routerLink]="'/price-management'"  routerLinkActive="active-list-item"> Price management </a>               -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class='color-portal' color="secondary">

      <button mat-icon-button (click)='sidenav.toggle()'>
        <i class="fa fa-bars"></i>
      </button>

      <div class="row customerPortalBackground">
       
          
        <div class="col-lg-10 col-md-10 page-title" [ngClass]="{ 'col-10': isMobileDevice }" >
          <a class="normal" routerLink="../app/home">
          BITUMEN CUSTOMER PORTAL
          </a>
        </div>

        <div class="col-lg-2 col-md-2 box-icons" [ngClass]="{ 'col-1': isMobileDevice }">


          <a href="#" data-original-title='Hello' class="box-icons languageIcon" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
<span class= "flag_btn">
            <span *ngIf="selectedLanguage === 'EN'">
              <!-- <img src="/assets/uk.png" class="ukFlagDesign"> -->
              <span class="flag-icon flag-icon-gb flag-icon-rounded"></span>
            </span>
            <span *ngIf="selectedLanguage === 'FI'">
              <!-- <img src="/assets/finland.png" class="ukFlagDesign"> -->
              <span class="flag-icon flag-icon-fi flag-icon-rounded"></span>
            </span>

            <span *ngIf="selectedLanguage === 'SE'">
              <!-- <img src="/assets/finland.png" class="ukFlagDesign"> -->
              <span class="flag-icon flag-icon-se flag-icon-rounded"></span>
            </span>
            <span *ngIf="selectedLanguage === 'NR'">
              <!-- <img src="/assets/finland.png" class="ukFlagDesign"> -->
              <span class="flag-icon flag-icon-no flag-icon-rounded"></span>
            </span>
            <span *ngIf="selectedLanguage === 'DA'">
              <span class="flag-icon flag-icon-dk flag-icon-rounded"></span>
            </span>
          </span>
            <ul class="dropdown-menu languageDropdown languageDropdownWidth languageDropdownmv mobileView">
            
              <li><a class="" #langSelect (click)="updateLanguage('EN')" [rel]="EN" [class.active]="'EN' === translate.currentLang">
                  <!-- <img src="/assets/uk.png" class="flagDesign"> -->
                  <span class="flag-icon flag-icon-gb flag-icon-rounded"></span>
                  <span class="language-code">EN</span>
                </a></li>
            
              <hr class="hrDesign">
            
              <li><a class="" #langSelect (click)="updateLanguage('FI')" [rel]="FI" [class.active]="'FI' === translate.currentLang">
                  <!-- <img src="/assets/finland.png" class="flagDesign">  -->
                  <span class="flag-icon flag-icon-fi flag-icon-rounded"></span>
                  <span class="language-code">FI</span>
                </a></li>

              <hr class="hrDesign">
            
              <li><a class="" #langSelect (click)="updateLanguage('SE')" [rel]="SE" [class.active]="'SE' === translate.currentLang">
                  <!-- <img src="/assets/finland.png" class="flagDesign">  -->
                  <span class="flag-icon flag-icon-se flag-icon-rounded"></span>
                  <span class="language-code">SE</span>
                </a></li>

                <hr class="hrDesign">
            
              <li><a class="" #langSelect (click)="updateLanguage('NR')" [rel]="NR" [class.active]="'NR' === translate.currentLang">
                  <!-- <img src="/assets/finland.png" class="flagDesign">  -->
                  <span class="flag-icon flag-icon-no flag-icon-rounded"></span>
                  <span class="language-code">NO</span>
                </a></li>

                <hr class="hrDesign">
            
              <li><a class="" #langSelect (click)="updateLanguage('DA')" [rel]="DA" [class.active]="'DA' === translate.currentLang">
                  <span class="flag-icon flag-icon-dk flag-icon-rounded"></span>
                  <span class="language-code">DK</span>
                </a></li>
            </ul>

          </a>
          <span class="dropdown">
            <!-- <a data-original-title='Hello1' class=" fa fa-bell  box-icons  notificationsIcon" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">


            </a> -->
          </span>
          <span *ngIf="!isMobileDevice">
            <i class="fa fa-sign-out signOut pl-2" data-toggle="tooltip" data-placement="bottom"
              title=" {{'HOME.Signout'| translate}}" (click)="logout()" aria-hidden="true"></i>
          </span>
        </div>
        <!-- <div class="col-lg-10 col-md-10" > test</div> -->
        <div class="col-lg-10 col-md-10 page-heading">
            <!-- {{pageHeading}} -->
  
            <span *ngIf="router.isActive('app/home', true)"class="menu-list-names">{{'HOME.pageHeading1'|translate}} </span>
  
           
            <span  *ngIf="router.isActive('app/order-management/forecast-entry', true)" class="menu-list-names">{{'HOME.pageHeading3'|translate}} </span>
  
            <span  *ngIf="router.isActive('app/order-management/history', true)" class="menu-list-names">{{'HOME.pageHeading4'|translate}}  </span>
  
            <span  *ngIf="router.isActive('app/order-management/pending-forecast', true)" class="menu-list-names">{{'HOME.pageHeading5'|translate}} </span>
  
            <span  *ngIf="router.isActive('app/order-management/ordering-procedure', true)" class="menu-list-names"> {{'HOME.pageHeading6'|translate}} </span>
  
            <span *ngIf="router.isActive('app/product-management/product-information', true)" class="menu-list-names">{{'HOME.pageHeading8'|translate}} </span>
  
            <span  *ngIf="router.isActive('app/product-management/delivery-guide', true)" class="menu-list-names">{{'HOME.pageHeading9'|translate}} </span>
           
            <span  *ngIf="router.isActive('app/price-management/price-data', true)" class="menu-list-names">{{'HOME.pageHeading11'|translate}} </span>
  
            <span  *ngIf="router.isActive('app/price-management/price-authors', true)" class="menu-list-names">{{'HOME.pageHeading12'|translate}} </span>
  
            <span  *ngIf="router.isActive('app/price-management/bitumen-index', true)" class="menu-list-names">{{'HOME.pageHeading13'|translate}} </span>
  
            <span *ngIf="router.isActive('app/my-company/company-details', true)"  class="menu-list-names">{{'HOME.pageHeading15'|translate}} </span>
  
            <span *ngIf="router.isActive('app/my-company/add-delivery-site', true)" class="menu-list-names">{{'HOME.pageHeading16'|translate}} </span>

            <span *ngIf="router.isActive('app/my-company/add-delivery-site-sweden', true)" class="menu-list-names">{{'HOME.pageHeading40'|translate}} </span>
  
            <span  *ngIf="router.isActive('app/my-company/modify-delivery-site', true)"class="menu-list-names">{{'HOME.pageHeading17'|translate}} </span>

            <span  *ngIf="router.isActive('app/my-company/modify-delivery-site-sweden', true)"class="menu-list-names">{{'HOME.pageHeading17'|translate}} </span>
  
            <!-- <span *ngIf="router.isActive('app/reports/forecastvsactual')" class="menu-list-names">{{'HOME.pageHeading19'|translate}} </span> -->

            <span *ngIf="router.isActive('app/reports/forecastvsactualsweden', true)" class="menu-list-names">{{'HOME.pageHeading19'|translate}} </span>

            <span *ngIf="router.isActive('app/reports/forecastvsactualmonthly', true)" class="menu-list-names">{{'HOME.pageHeading19'|translate}} </span>
            
            <span *ngIf="router.isActive('app/reports/forecastvsactualfinland', true)" class="menu-list-names">{{'HOME.pageHeading19'|translate}} </span>
  
            <span  *ngIf="router.isActive('app/user-management/manage-users', true)" class="menu-list-names">{{'HOME.pageHeading21'|translate}} </span>
  
            <span *ngIf="router.isActive('app/user-management/add-user', true)"class="menu-list-names">{{'HOME.pageHeading22'|translate}} </span>
  
            <span *ngIf="router.isActive('app/user-management/manage-user-group', true)" class="menu-list-names">{{'HOME.pageHeading23'|translate}} </span>
  
            <span  *ngIf="router.isActive('app/user-management/add-user-group', true)" class="menu-list-names">{{'HOME.pageHeading24'|translate}} </span>
  
            <span  *ngIf="router.isActive('app/nynas-updates', true)" class="menu-list-names"> {{'HOME.pageHeading25'|translate}} </span>
  
            <span *ngIf="router.isActive('app/my-profile', true)" class="menu-list-names">{{'HOME.pageHeading26'|translate}} </span>
  
            <span  *ngIf="router.isActive('app/contactus', true)" class="menu-list-names">{{'HOME.pageHeading27'|translate}} </span>
    
            <span *ngIf="router.isActive('app/configuration/delivery-guide-admin', true)" class="menu-list-names">{{'HOME.pageHeading30'|translate}} </span>
  
            <span *ngIf="router.isActive('app/configuration/customer-contract', true)"  class="menu-list-names">{{'HOME.pageHeading31'|translate}} </span>
  
            <span *ngIf="router.isActive('app/configuration/add-price-index', true)" class="menu-list-names"> {{'HOME.pageHeading32'|translate}} </span>

            <span *ngIf="router.isActive('app/configuration/add-product-price', true)" class="menu-list-names"> {{'HOME.pageHeading41'|translate}} </span>
  
            <span *ngIf="router.isActive('app/configuration/add-exchange-rate-admin', true)" class="menu-list-names"> {{'HOME.pageHeading33'|translate}} </span>
  
            <span *ngIf="router.isActive('app/configuration/contact-us-admin', true)" class="menu-list-names">{{'HOME.pageHeading34'|translate}} </span>
  
            <span *ngIf="router.isActive('app/configuration/bitumen-index-admin', true)" class="menu-list-names"> {{'HOME.pageHeading35'|translate}} </span>
            
              <span *ngIf="router.isActive('app/configuration/upload-document', true)" class="menu-list-names"> {{'HOME.pageHeading36'|translate}} </span>
              <span *ngIf="router.isActive('app/configuration/settings', true)" class="menu-list-names">{{'HOME.pageHeading58'|translate}} </span>
            <span *ngIf="router.isActive('app/configuration/guest-view', true)" class="menu-list-names">{{'HOME.pageHeading37'|translate}} </span>

            <span *ngIf="router.isActive('app/reports/salesreport', true)" class="menu-list-names">{{'HOME.pageHeading44'|translate}} </span>
             
            <span *ngIf="router.isActive('app/configuration/login-history', true)" class="menu-list-names"> {{'HOME.pageHeading45'|translate}} </span>

            <span *ngIf="router.isActive('app/reports/monthly-reports', true)" class="menu-list-names">{{'HOME.pageHeading46'|translate}} </span>
            
            <span *ngIf="router.isActive('app/reports/weekly-reports', true)" class="menu-list-names">{{'HOME.pageHeading47'|translate}} </span>
            <span *ngIf="router.isActive('app/reports/daily-reports', true)" class="menu-list-names">{{'HOME.pageHeading49'|translate}} </span>
            <span *ngIf="router.isActive('app/order-management/order-entry', true)" class="menu-list-names">{{'HOME.pageHeading51'|translate}} </span>
            <span *ngIf="router.isActive('app/order-management/order-tracking', true)" class="menu-list-names">{{'HOME.pageHeading52'|translate}} </span>

            <span *ngIf="router.isActive('app/reports/contractual-volume-reports', true)" class="menu-list-names">{{'HOME.pageHeading53'|translate}} </span>
            <span *ngIf="router.isActive('app/product-management/product-documentation', true)" class="menu-list-names">{{'HOME.pageHeading54'|translate}} </span>
            <span *ngIf="router.isActive('app/reports/shipped-deliveries', true)" class="menu-list-names">{{'HOME.pageHeading44'|translate}} </span>
            <span *ngIf="router.isActive('app/reports/delivery-analysis', true)" class="menu-list-names">{{'HOME.pageHeading60'|translate}} </span>
            
            <span *ngIf="router.isActive('app/udsp-mapping', true)" class="menu-list-names">{{'HOME.pageHeading55'|translate}} </span>
            <span *ngIf="router.isActive('app/order-management/unlock-order', true)" class="menu-list-names">{{'HOME.pageHeading56'|translate}} </span>
            <span *ngIf="router.isActive('app/my-company/add-edit-delivery-site', true)" class="menu-list-names">{{'HOME.pageHeading40'|translate}} </span>
          </div>
  
      </div>


    </mat-toolbar>
    <div class="mt-10 mb-5 margin-bottom-mv">

      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>