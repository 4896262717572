import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
// import {MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { AddUserComponentService } from '../add-user/add-user.component.service';
import { Customer } from '../customer';
// import { MatDialogRef } from "@angular/material/dialog";
import { DialogBodyComponent } from '../dialog-body/dialog-body.component';
import { User } from '../user';
import { UserGroup } from '../user-group';
import { ManageUsersService } from './manage-users.service';
import { UserManagementService } from '../user-management.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';

@Component({
	selector: 'app-manage-users',
	templateUrl: './manage-users.component.html',
	styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit, AfterViewInit, OnDestroy {

	userID = '';
	country = '';
	customerName = '';

	users: User[];

	/* displayedColumns: string[] = ['UserID', 'CustomerName', 'UserRole', 'UserStatus', 'LastModifiedTime'];
	dataSource = new MatTableDataSource(this.users); */

	/* displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
	dataSource = new MatTableDataSource(ELEMENT_DATA); */

	public displayedColumns = ['UserPhoto', 'FirstName', 'EmailID', 'userGroupName', 'customerName', 'Role', 'UserStatus', 'UpdatedOn', 'actions'];
	public dataSource = new MatTableDataSource <User> ();

	customerList: Customer[] = [];
	selectedCompanyName: string = "";
	selectedCompanyId: string = "";
	isLoggedInUserPortalAdmin: boolean;
	userGroupList: UserGroup[] = [];
	@ViewChild(MatSort, {static: true}) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	isRequestCompleted: boolean;
	loginResponse: any;
	roleList = [{code: 'U', description: 'User'}, {code: 'A', description: 'Admin'}, {code: 'PA', description: 'Nynas admin'}, {code: 'DA', description: 'Nynas depot admin'}];
	userInfo: any;
	selectedItem: string;
	loggedInUserName: string;
	countryCode: any;
	mobileQuery: MediaQueryList;
	private _mobileQueryListener: () => void; 
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
	isFirstOnLoad: boolean = true;

	constructor(private manageUsersService: ManageUsersService,
		private matDialog: MatDialog,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private router: Router,
		private addUserComponentService: AddUserComponentService,
		private sharedService: AppService,
		private userManagementService: UserManagementService,
		private cd: ChangeDetectorRef,
		private media: MediaMatcher,
        private translate: TranslateService,
		private globalSearchService: GlobalSearchService
		// public dialogRef: MatDialogRef<DialogBodyComponent>
	) {
		this.userInfo = this.sharedService.getLoginResponse();
		this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
		this.countryCode = this.userInfo.CountryCode;		            
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => cd.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}

	ngOnInit() {
		this.userManagementService.currentSelectedItem.subscribe(selectedItem => this.selectedItem = selectedItem);
		this.selectedCompanyName = this.userInfo ? this.userInfo.CustomerName : '';
		if (this.userInfo) {
			this.selectedCompanyId = (this.userInfo.RoleID === "PA") ? '' : (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
		} else {
			this.selectedCompanyId = '';	
		}
		this.isLoggedInUserPortalAdmin = (this.userInfo ? (this.userInfo.RoleID === 'PA') : false);
		if (!this.isLoggedInUserPortalAdmin) {
			this.getUsers();
		}

		this.dataSource.sort = this.sort;	
		this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
			if (data[sortHeaderId]) {
				return data[sortHeaderId].toLocaleLowerCase()
			}
			return null;
		};	

		// Uncomment if services are down to display table data
		/* const userList: User[] = [
			{ FirstName: 'Varad', LastName: 'Mayekar', EmailID: 'varad.mayekar@lntinfotech.com', UserGroup: 'LTI-HR', 
			CustomerID: 'LTI', Role: 'Portal Admin', UserStatus: 'Active', UpdatedOn: this.sharedService.getCurrentDateTime(), actions: [],
			CustomerName: null, UserRole: null, LastModifiedTime: null, name: null },
			{ FirstName: 'Ashit', LastName: 'Lawande', EmailID: 'ashit.lawande@cts.com', UserGroup: 'CTS-Admin', 
			CustomerID: 'CTS', Role: 'Portal Admin', UserStatus: 'Deactive', UpdatedOn: this.sharedService.getCurrentDateTime(), actions: [],
			CustomerName: null, UserRole: null, LastModifiedTime: null, name: null },
			{ FirstName: 'Rahul', LastName: 'Rajaram', EmailID: 'rahul.rajram@wipro.com', UserGroup: 'Wipro-Guest', 
			CustomerID: 'Wipro', Role: 'Portal Admin', UserStatus: 'Locked', UpdatedOn: this.sharedService.getCurrentDateTime(), actions: [],
			CustomerName: null, UserRole: null, LastModifiedTime: null, name: null }
		];
		this.isRequestCompleted = true; */

		//get customer names
		this.manageUsersService.getCustomerList(this.countryCode)
			.subscribe(
				res => {
					if (res["ResponseCode"] == 200) {
						for (let index of res["Customers"]) {
							// this.CustomerList.push(index);
							this.customerList.push(new Customer(index["CustomerID"], index["CustomerName"]));
						}
					}
				});
		/* this.addUserComponentService.getUserGroupsByCustomerId(this.selectedCompanyId)
			.subscribe(
				res => {
					if (res["UsersGroups"] && res["UsersGroups"].length) {
						this.userGroupList = res["UsersGroups"];
					}
				}); */

				this.translate.get(['HOME.itemsPerPageLabel'])
                .subscribe(data => {
                  this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
                });
        
		// Load data on load
		this.changeCustomerName();

		this.subscribeToGlobalSearchServiceSearchTerm();

	}

	ngAfterViewInit(): void {
		// this.dataSource.sort = this.sort;
	}
  
	ngOnDestroy(): void {
	  this.mobileQuery.removeListener(this._mobileQueryListener);
	  this.globalSearchText = '';
	}

	handleClick() {
		alert('Reset Password');
	}

	getUsers() {
		this.dataSource.data = [];
		this.isRequestCompleted = false;
		this.manageUsersService.getUsers(this.userID, this.countryCode, this.selectedCompanyId)
			.subscribe(
				res => {
					const users = 'Users';
					/* if (res && res[users] && res[users].length) {
						this.dataSource.data = res[users];
						this.isRequestCompleted = true;
					} */
					if (res && res[users] && res[users].length) {
						var userList = [];
						for (var user of res[users]) {
							// user['customerName'] = this.getCustomerNameById(user['CustomerID']);
							// user['UserGroupName'] = this.getUserGroupNameById(user['UserGroup']);
							user['customerName'] = user['CustomerName'];
							user['userGroupName'] = user['UserGroupName'];
							user['imageUrl'] = (user['Photo'] && !user['Photo'].toUpperCase().includes('CDATA')) ? ('data:image/png;base64,' + user['Photo']) : null;
							user['RoleID'] = user['Role'];
							if (user['Role']) {
								let roleCode = user['Role'],
									roleObject = this.roleList.filter(role => role.code === roleCode),
									roleDescription = (roleObject && roleObject.length) ? roleObject[0].description : '';
								user['Role'] = roleDescription;
							}
							userList.push(user);
						}
						this.dataSource.data = userList;
						this.dataSource.paginator = this.paginator;
						this.isRequestCompleted = true;
					}
					this.isRequestCompleted = true;
				});
	}

	/* openDialog() {
	  const dialogConfig = new MatDialogConfig();
	  this.matDialog.open(DialogBodyComponent , dialogConfig);
	} */

	/* openDialog(dialogType: string,headerNumber:string) {
		const dialogRef = this.dialog.open(DialogBodyComponent, {
			data: {
				header: (dialogType === 'resetPassword') ? 'Password Reset Successful' : 'User Unlock Successful',
				message: (dialogType === 'resetPassword') ? 'New Password is sent to you by email, please check.' : ' ',
				buttonText: {
					ok: 'Ok'
				},
				isSuccess: true,
				headerNumber:headerNumber
				
			},
			height: (dialogType === 'resetPassword') ? '37%' : '31%',
			width: (dialogType === 'resetPassword') ? '24%' :'22%',
		});
	} */

	/* openAlertDialog() {
	  const dialogRef = this.dialog.open(AlertDialogComponent,{
	    data:{
	      message: 'HelloWorld',
	      buttonText: {
	        cancel: 'Done'
	      }
	    },
	  });
	} */

	close() {
		// this.dialogRef.close();
	}

	resetPassword(user: User) {
		var userObject = new Object();
		userObject['UserId'] = user.UserID;
		this.manageUsersService.resetPassword(userObject)
			.subscribe(
				response => {
					// this.saveStatusDialog(response, 'reset-password','reset-password-message');
					// this.saveStatusDialog(response, 'reset-password-title','');
					this.resetPasswordStatusDialog(response);
				});
	}

	lockUnlockUser(user: User) {
		var userObject = new Object();
		userObject['UserID'] = user.UserID;
		userObject['Status'] = 'Active';
		this.manageUsersService.lockUnlockUser(userObject)
			.subscribe(
				response => {
					this.saveStatusDialog(response, 'update-user','');
				});
	}

	editUser(user: User) {
		this.updateSelectedItem('Add user');
		const navigationExtras: NavigationExtras = {
			state: {
				emailId: user.UserID,
				customerId: user.CustomerID
			}
		};
		this.router.navigate(["../../app/user-management/add-user"], navigationExtras);
	}

	// Choose Customer Names using select dropdown
	changeCustomerName() {
		this.dataSource.data = [];
		this.isRequestCompleted = false;
		this.manageUsersService.getUsers('', this.countryCode, this.selectedCompanyId)
			.subscribe(
				res => {
					const users = 'Users';
					if (res && res[users] && res[users].length) {
						// this.dataSource.data = res[users];
						var userList = [];
						for (var user of res[users]) {
							// user['customerName'] = this.getCustomerNameById(user['CustomerID']);
							// user['UserGroupName'] = this.getUserGroupNameById(user['UserGroup']);
							user['customerName'] = user['CustomerName'];
							user['userGroupName'] = user['UserGroupName'];
							user['imageUrl'] = (user['Photo'] && !user['Photo'].toUpperCase().includes('CDATA')) ? ('data:image/png;base64,' + user['Photo']) : null;
							user['RoleID'] = user['Role'];
							if (user['Role']) {
								let roleCode = user['Role'],
									roleObject = this.roleList.filter(role => role.code === roleCode),
									roleDescription = (roleObject && roleObject.length) ? roleObject[0].description : '';
								user['Role'] = roleDescription;
							}

							// Field added for global search only
							user['fullName'] = user['FirstName'] + ' ' + user['LastName'];

							userList.push(user);
						}
						this.dataSource.data = userList;
						this.dataSource.paginator = this.paginator;
						this.isRequestCompleted = true;
					}
					this.isRequestCompleted = true;
				});
	}

	getCustomerNameById(customerId: string): string {
		for (var customer of this.customerList) {
			if (customer && customer.CustomerID === customerId) {
				return customer.CustomerName;
			}
    }
    return null;
	}

	getUserGroupNameById(userGroupId: number): string {
		for (var userGroup of this.userGroupList) {
			if (userGroup && userGroup.UserGroupID === userGroupId) {
				return userGroup.UserGroupName;
			}
		}
		return null;
	}

	updateSelectedItem(selectedItem: string) {
		this.userManagementService.updateSelectedItem(selectedItem)
	}

	updateUserStatus(selectedUser: any, statusToBeUpdated: string) {
		if (selectedUser && statusToBeUpdated) {
			selectedUser['UserStatus'] = statusToBeUpdated;
			selectedUser['UserGroup'] = selectedUser['UserGroupID'];
			selectedUser['Country'] = selectedUser['Country_Code'];
			selectedUser['UpdatedBy'] = this.loggedInUserName;
			selectedUser['Role'] = selectedUser['RoleID'];
			selectedUser['Photo'] = (selectedUser['Photo'] && !selectedUser['Photo'].toUpperCase().includes('CDATA')) ? (selectedUser['Photo']) : null;
			this.addUserComponentService.updateUser(selectedUser, selectedUser['PrimaryContact'], selectedUser['SecondaryContact'])
				.subscribe(
					response => {
						this.saveStatusDialog(response, 'update-user', '');
					});
		}
	}

	saveStatusDialog(response: any, headerNumber: string, messageNumber: string) {
		if (response) {
			if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
				this.getUsers();
				this.openSaveUserDialog(true, headerNumber, messageNumber);
			} else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
				this.openSaveUserDialog(false, headerNumber, messageNumber);
			} else {
				this.openSaveUserDialog(false, headerNumber, messageNumber);
			}
		} else {
			this.openSaveUserDialog(false, headerNumber, messageNumber);
		}
	}

	openSaveUserDialog(isSuccess: boolean,headerNumber:string, messageNumber: string) {
		this.dialog.open(DialogBodyComponent, {
			data: {
				header: '',
				message: '',
				headerNumber: isSuccess ? headerNumber + '-success' : headerNumber + '-fail',
				messageNumber: messageNumber,
				buttonText: {
					ok: 'Ok'
				},
				isSuccess: isSuccess,

			},
			height: '37%',
			width: (this.mobileQuery.matches ? '50%' : '22%'),
		});
	}

	getStringWithoutSpaces(stringWithSpaces: string): string {
		let stringWithoutSpaces = '';
		stringWithoutSpaces = stringWithSpaces.replace(/\s/g, '');
		return stringWithoutSpaces;
	}

	reinitializeGlobalSearchComponent() {
	  this.globalSearchText = '';
	  this.showGlobalSearchTextBox = false;
	  this.cd.detectChanges();
	  this.showGlobalSearchTextBox = true;
	  this.cd.detectChanges();
	}
  
	subscribeToGlobalSearchServiceSearchTerm() {

		this.dataSource.filterPredicate = function (data, filter: string): boolean {
			return (data['fullName'].toLowerCase().includes(filter) ||
				data['EmailID'].toLowerCase().includes(filter) ||
				data['userGroupName'].toLowerCase().includes(filter) ||
				data['customerName'].toLowerCase().includes(filter) ||
				data['Role'].toLowerCase().includes(filter) ||
				data['UserStatus'].toLowerCase().includes(filter) ||
				data['UpdatedOn'].toString().toLowerCase().includes(filter));
		};

		// this listens to the input value from the service and does something on change.
		this.globalSearchService.searchTerm.subscribe((newValue: string) => {
			// this is where you would apply your existing filtering.
			this.applyFilter((this.isFirstOnLoad ? '' : newValue));
			this.isFirstOnLoad = false;
		});
	}
  
	applyFilter(filterValue: string) {
	  if (filterValue || filterValue === "") {
		filterValue = filterValue.trim(); // Remove whitespace
		filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
		this.dataSource.filter = filterValue;
		// this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
	  }
	}

	resetPasswordStatusDialog(response: any) {
		if (response && response['ResponseCode'] === 'OSB_OK_200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
			// this.openResetPasswordDialog('Password Reset Sucessful', '', true,'home-reset-password-success');
			this.openResetPasswordDialog('Password Reset Sucessful', '', true, 'reset-password-success');

		}
		else if (response['Description'] === "Please Enter Correct UserId") {
			this.openResetPasswordDialog('', '', false, 'home-correct-user-id');
		} else if (response['Description'] === "Invalid Schema:UserID is blank or Missing or not in proper format ") {
			this.openResetPasswordDialog('', '', false, 'home-correct-user-id-format');
		}
		else if (response && response['ResponseCode'] === '500' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
			this.openResetPasswordDialog('', '', false, 'home-reset-password-fail');
		}
		else if (response && response['ResponseCode'] === 'OSB_ERROR_403') {
			this.openResetPasswordDialog('', '', false, 'home-reset-password-fail-deactive');
		}
		else if (response && response['ResponseCode'] === 'OSB_ERROR_400') {
			this.openResetPasswordDialog('', '', false, 'home-reset-password-fail-incoorect-format');
		}
		else if (response && response['ResponseCode'] === 'OSB_ERROR_404') {
			this.openResetPasswordDialog('', '', false, 'home-reset-password-fail-userId-not-found');
		}
		else {
			this.openResetPasswordDialog('', '', false, 'home-reset-password-fail');
		}
	}

	openResetPasswordDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
		this.dialog.open(DialogBodyComponent, {
			data: {
				header: headerTitle,
				message: message ? message : ' ',
				isSuccess: isSuccess,
				headerNumber: headerNumber,
				buttonText: {
					ok: 'Ok'
				}
			},
			height: '35%',
			width: (this.mobileQuery.matches ? '50%' : '25%'),
		});
	}

}