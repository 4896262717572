import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../user';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AddUserComponentService {

    apiUrl = environment.apiUrl;
    
    constructor(private httpClient: HttpClient) { }

    getCountries() {
        let url = this.apiUrl + '/getCountry';
        return this.httpClient.get(url);
    }
    getCustomerNames(countryCode) {
        let url = this.apiUrl + '/getCustomer';
        return this.httpClient.get(url, {
            params: {
              CountryCode: countryCode,
            }
          });
    }
    createAddUser(user: any, primaryContactWithCountryCode: string, secondaryContactWithCountryCode: string) {

        let url = this.apiUrl + '/CreateUser';
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            })
        };
        let postData = JSON.stringify({
            "Title": null,
            "Country": user.Country,
            "CustomerName": user.CustomerID,
            "Photo": user.Photo,
            "FirstName": user.FirstName,
            "LastName": user.LastName,
            "PrimaryContact": primaryContactWithCountryCode,
            "SecondaryContact": secondaryContactWithCountryCode,
            "EmailID": user.EmailID,
            "Gender": null,
            "AddressLine1": null,
            "Role": user.Role,
            "UserID": user.EmailID,
            "UserGroup": user.UserGroup,
            "AddressLine2": null,
            "State": null,
            "City": null,
            "ZipCode": null,
            "CreatedBy": user.CreatedBy
        });
        return this.httpClient.post<User>(url, postData, httpOptions).pipe();
    }
    createUserForDeliverySite(user: any, primaryContactWithCountryCode: string, secondaryContactWithCountryCode: string, deliverySiteList: any) {

        let url = this.apiUrl + '/CreateUserForDeliverySite';
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            })
        };
        let postData = JSON.stringify({
            "Title" : null,
            "Country" : user.Country,
            "CustomerName" : user.CustomerID,
            "Photo" : user.Photo,
            "FirstName" : user.FirstName,
            "LastName" : user.LastName,
            "PrimaryContact" : primaryContactWithCountryCode,
            "SecondaryContact" : secondaryContactWithCountryCode,
            "EmailID" : user.EmailID,
            "Gender" : null,
            "AddressLine1" : null,
            "Role" : user.Role,
            "UserID" : user.EmailID,
            "UserGroup" : user.UserGroup,
            "AddressLine2" : null,
            "State" : null,
            "City" : null,
            "ZipCode" : null,
            "CreatedBy" : user.CreatedBy,
            "DeliverySiteCollection" : deliverySiteList
        });
        return this.httpClient.post<User>(url, postData, httpOptions).pipe();
    }

    updateUser(user: any, primaryContactWithCountryCode: string, secondaryContactWithCountryCode: string) {
        let url = this.apiUrl + '/updateUser';
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            })
        };
        let postData = JSON.stringify({

            "Title" : null,
            "Country_Code" : user.Country,
            "CustomerID" : user.CustomerID,
            "Photo" : user.Photo,
            "FirstName" : user.FirstName,
            "LastName" : user.LastName,
            "PrimaryContact" : primaryContactWithCountryCode,
            "SecondaryContact" : secondaryContactWithCountryCode,
            "EmailID" : user.EmailID,
            "Gender" : null,
            "AddressLine1" : null,
            "Role" : user.Role,
            "Status" : user.UserStatus,
            "UserID" : user.UserID,
            "UserGroup" : user.UserGroup,
            "AddressLine2" : null,
            "State" : null,
            "City" : null,
            "ZipCode": null,
            "UpdatedBy" : user.UpdatedBy,
        });
        return this.httpClient.put<User>(url, postData, httpOptions).pipe();
    }

        updateUserForSweden(user: any, deliverySiteList: any, primaryContactWithCountryCode: string, secondaryContactWithCountryCode: string) {
        let url = this.apiUrl + '/UpdateUserForDeliverySite';
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            })
        };

        // Old payload used before adding of jde address number
        /* let postData = JSON.stringify({

            "Status" : user.UserStatus,
            "EmailID" : user.EmailID,
            "FirstName" : user.FirstName,
            "ZipCode": null,
            "Title" : null,
            "DeliverySiteCollection": deliverySiteList,
            "Photo" : user.Photo,
            "SecondaryContact" : user.SecondaryContact,
            "CustomerID" : user.CustomerID,
            "Gender" : null,
            "City" : null,
            "PrimaryContact" : user.PrimaryContact,
            "UserGroup" : user.UserGroup,
            "Role" : user.Role,
            "AddressLine2" : null,
            "UpdatedBy" : user.UpdatedBy,
            "Country_Code" : user.Country,
            "AddressLine1" : null,
            "UserID" : user.UserID,
            "State" : null,
            "LastName" : user.LastName
        }); */

        let postData = JSON.stringify({
            "Title": null,
            "Country_Code": user.Country,
            "CustomerID": user.CustomerID,
            "Photo": user.Photo,
            "FirstName": user.FirstName,
            "LastName": user.LastName,
            "PrimaryContact": primaryContactWithCountryCode,
            "SecondaryContact": secondaryContactWithCountryCode,
            "EmailID": user.EmailID,
            "Gender": null,
            "AddressLine1": null,
            "Role": user.Role,
            "Status": user.UserStatus,
            "UserID": user.UserID,
            "UserGroup": user.UserGroup,
            "AddressLine2": null,
            "State": null,
            "City": null,
            "ZipCode": null,
            "UpdatedBy": user.UpdatedBy,
            "DeliverySiteCollection": deliverySiteList
        });
        return this.httpClient.post<User>(url, postData, httpOptions).pipe();
    }

    getUserGroupsByCustomerId(customerID: string, countryCode: string) {
        const url = this.apiUrl + '/getAllUserGroup';
        if (customerID) {
            return this.httpClient
                .get(url, {
                    params: {
                        CustomerID: customerID,
                        CountryCode: countryCode
                    }
                });
        } else {
            return this.httpClient
                .get(url, {
                    params: {
                        CountryCode: countryCode
                    }
                });
        }
    }

    getUserMenuAccess(id){
        const url = this.apiUrl + '/GetMenuByUserGroupID';
        return this.httpClient
            .get(url, {
              params: {
                UserGroupId : id
              }
            })
    }

    getDeliverySite(CustomerId: string, Status: string, countryCode: string) {

        let deliveryUrl = this.apiUrl + '/FetchDeliverySiteService';
        return this.httpClient
            .get(deliveryUrl, {
                params: {
                    CustomerId: CustomerId,
                    ApprovalStage: Status,
                    CountryCode: countryCode
                    //Hardcoded Check @
                    //CustomerName:  'YIT Kotka Ilarila'
                }
            });
    }

    getDeliverySiteForUser(userId: string, countryCode: string, mappingStatus: string, customerId: string) {
        let deliveryUrl = this.apiUrl + '/GetDeliverySiteForUser';
        return this.httpClient
            .get(deliveryUrl, {
                params: {
                    UserID: userId,
                    CountryCode: countryCode,
                    MappingStatus: mappingStatus,
                    CustomerID: customerId 
                }
            });
    }
}