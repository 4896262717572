<div class="container-fluid sidepadding-none">
   <div class='margin-top-5 '>
      <div class="col-lg-12">
         <div class="row pb-2" *ngIf="countryCode === 'FIN'">
             <div class="col-lg-6 toggle_btn_div_mv">
                 <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                     #group="matButtonToggleGroup" [(ngModel)]="currentToggleMenu" value="product-catalogue">
                     <mat-button-toggle value="product-catalogue" ngDefaultControl (click)="toggleButtonClick()">
                         {{'HOME.Productcatalogue'| translate}}
                     </mat-button-toggle>
                     <mat-button-toggle value="quality-certificate" (click)="toggleButtonClick()">
                         {{'HOME.Qualitycertificate'| translate}}
                     </mat-button-toggle>
                 </mat-button-toggle-group>
             </div>
         </div>

         <!-- Commented below code, kept for future reference -->
         <!-- <div class="row pb-2 no-gutters" [ngClass]="{'pt-4': (countryCode !== 'FIN')}">
            <div class="col-lg-4">
            </div>
            <div class="col-lg-2 country-market-label" *ngIf="currentToggleMenu ==='product-catalogue' && countryCode !== 'GBR'">
               <label class="font-bold font-small" for="countryType">{{'HOME.Country'| translate}} {{'HOME.Market'|
                  translate}}</label>
            </div>
            <div class="col-lg-2" *ngIf="currentToggleMenu ==='product-catalogue' && countryCode !== 'GBR'">
               <select class="selectDropdown form-control productType_dropdown_mv " id="CountryCodeId"
                  [(ngModel)]='countryCodeMarket' (change)="changeCountryName($event)">
                  <option *ngFor="let country of countryMarketList" [ngValue]="country.COUNTRY_CODE">
                     {{'HOME.' + country.COUNTRY_NAME | translate}}
                  </option>
               </select>
            </div>
         </div> -->

         <div class="row padding-top no-gutters">

            <!-- Moved Country (Market) dropdown up -->
            <!-- <div class="col-lg-1" *ngIf="currentToggleMenu ==='product-catalogue' && countryCode !== 'GBR'">
               <label class="font-bold font-small" for="countryType">{{'HOME.Country'| translate}} {{'HOME.Market'|
                  translate}}</label>
            </div>
            <div class="col-lg-3 pr-5" *ngIf="currentToggleMenu ==='product-catalogue' && countryCode !== 'GBR'">
               <select class="selectDropdown form-control productType_dropdown_mv " id="CountryCodeId"
                  [(ngModel)]='countryCodeMarket' (change)="changeCountryName($event)">
                  <option *ngFor="let country of countryMarketList" [ngValue]="country.COUNTRY_CODE">
                     {{'HOME.' + country.COUNTRY_NAME | translate}}
                  </option>
               </select>
            </div> -->

            <div class="col-lg-4">
               <div class="input-group rounded">
                  <input type="search" class="form-control rounded global-search-text-box" placeholder="{{'HOME.Search'| translate}}..." aria-label="Search"
                     aria-describedby="search-addon" (keyup)="applyFilter($event.target.value)" [(ngModel)]="globalSearchText"/>

                     <!-- Commented code for search icon, kept for future reference -->
                     <!-- <span class="input-group-text border-0 search-icon" id="search-addon">
                        <i class="fas fa-search search-icon-color"></i>
                     </span> -->

               </div>
            </div>

            <!-- Commented below code, kept for future reference -->
            <!-- <div class="col-lg-2 product-type-label">
               <label class="font-bold font-small" for="productType">{{'HOME.pinfo'| translate}}</label>
            </div>
            <div class="col-lg-3 pl-3">
               <select class="selectDropdown form-control productType_dropdown_mv " [(ngModel)]="productTypeToSearch">
                  <option [value]="All">{{'HOME.All'| translate}}</option>
                  <option *ngFor="let product of productType" [ngValue]="product">
                     {{product}}
                  </option>
               </select>
            </div> -->

            <div class="col-lg-2 country-market-label country-dropdown-mv pr-1" *ngIf="currentToggleMenu ==='product-catalogue' && countryCode !== 'GBR'">
               <label class="font-bold font-small" for="countryType">{{'HOME.Country'| translate}} {{'HOME.Market'|
                  translate}}</label>
            </div>
            <div class="col-lg-2" *ngIf="currentToggleMenu ==='product-catalogue' && countryCode !== 'GBR'">
               <select class="selectDropdown form-control productType_dropdown_mv " id="CountryCodeId"
                  [(ngModel)]='countryCodeMarket' (change)="changeCountryName($event)">
                  <option *ngFor="let country of countryMarketList" [ngValue]="country.COUNTRY_CODE">
                     {{'HOME.' + country.COUNTRY_NAME | translate}}
                  </option>
               </select>
            </div>

            <!-- Product category dropdown not to be shown -->
            <!-- <div class="col-lg-2 align-right ProductCategory_label_mv column-medium">
               <label class="font-bold font-small" for="productSubType">{{'HOME.ProductCategory'| translate}}</label>
            </div>
            <div class="col-lg-2">
               <select class="selectDropdown form-control ProductCategory_dropdown_mv "
                  [(ngModel)]="productSubTypeToSearch">
                  <option [value]="All">{{'HOME.All'| translate}}</option>
                  <option *ngFor="let productSub of productSubType" [ngValue]="productSub">
                     {{productSub}}
                  </option>
               </select>
            </div> -->

            <!-- Commented below code, kept for future reference -->
            <!-- <div class="col-lg-1 pl-3">
                  <button class="findButton" (click)="fetchProductDetailsFromSaleforce()">{{'HOME.Find'| translate}}</button>
            </div> -->

            <div class="col-lg-2 legend-width" [ngClass]="{'legend-width-uk': (countryCode === 'GBR')}" *ngIf="currentToggleMenu === 'product-catalogue'">
               <div class="table-legend">
                  <span>{{'HOME.PDS'| translate}}</span> : {{'HOME.Pdatasheet'| translate}}
               </div>
               <div class="table-legend">
                  <span>{{'HOME.SDS'| translate}}</span> : {{'HOME.Safetydatasheet'| translate}}
               </div>

               <!-- Commented below code, kept for future reference -->
               <!-- <div *ngIf="countryCodeMarket !== 'GBR'" class="table-legend">
                  <span class="pr-2">{{'HOME.CE'| translate}}</span> : {{'HOME.CEmark'| translate}}
               </div>
               <div *ngIf="countryCodeMarket === 'GBR'" class="table-legend">
                  <span class="pr-2">{{'HOME.CA'| translate}}</span> : {{'HOME.CAmark'| translate}}
               </div> -->

               <div class="table-legend">
                  <span class="pr-2">{{'HOME.CE'| translate}}</span> : {{'HOME.CEmark'| translate}}
               </div>

               <div class="table-legend">
                  <span>{{'HOME.DoP'| translate}}</span> : {{'HOME.Declarationofperformance'|
                  translate}}
               </div>
            </div>
         </div>
         <div class="row pt-1">
            <div class="col-12 product-documentation-page-note">

               <!-- Commented code for animated message, kept for future reference -->
               <!-- <span class="revolving-text">
                  {{ bannerMessage }}
               </span> -->

               <span>{{ bannerMessage }}</span>
            
               <!-- Commented below code for animated note, kept for future reference -->
               <!-- <marquee direction="left">
                              {{'HOME.product-documentation-page-note'| translate: { contactUsEmailId: contactUsEmailId, nynasCustomerName: nynasCustomerName } }}
                           </marquee> -->
            
            </div>
         </div>
      </div>
      <div class="col-lg-12">
         <div class='row'>
            <div class='col-lg-12' *ngIf='!displayTable && isRequestCompleted' class="no-records-message pl-3 pt-5">
               <span>{{'HOME.Norecordsfound'| translate}}</span>
            </div>
            <div class="col-lg-12" [hidden]='!(displayTable && isRequestCompleted)'>
               <!--Angular 7 Impl-->
               <mat-table #table [dataSource]="dataSource" matSort class='table-material horizontal_scrollbar '>
                  <!-- Item Column -->
                  <ng-container matColumnDef="product_name">
                     <mat-header-cell class="header-font mat_column_product_name_mv column-large" *matHeaderCellDef
                        mat-sort-header>
                        {{'HOME.Pname'| translate}}
                     </mat-header-cell>
                     <mat-cell *matCellDef="let fetchedDataForItemArray" class="column-large mat_column_product_name_mv ">
                        {{fetchedDataForItemArray.product_name}}
                     </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="Product_type">
                     <!-- With Year Sort<mat-header-cell  class="header-font" *matHeaderCellDef mat-sort-header="Year"> Month-Year -->
                     <mat-header-cell class="header-font mat_column_product_type_mv column-large" *matHeaderCellDef mat-sort-header>
                        {{'HOME.pinfo'|
                        translate}}
                     </mat-header-cell>
                     <mat-cell *matCellDef="let fetchedDataForItemArray" class="column-large mat_column_product_type_mv">
                        {{fetchedDataForItemArray.Product_type}}
                     </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="Product_category">
                     <mat-header-cell class="header-font column-medium" *matHeaderCellDef mat-sort-header> {{'HOME.ProductCategory'| translate}}
                     </mat-header-cell>
                     <mat-cell *matCellDef="let fetchedDataForItemArray" class="column-medium">
                        {{fetchedDataForItemArray.Product_category}}
                     </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="productInformation">
                     <mat-header-cell class="header-font column-medium"
                        [ngClass]="{'hide-table-column': (currentToggleMenu ==='quality-certificate')}" *matHeaderCellDef>
                        <!-- {{'HOME.Pdatasheet'| translate}} -->
                        <span title="{{'HOME.Pdatasheet'| translate}}">
                           {{'HOME.PDS'| translate}}
                        </span>
                     </mat-header-cell>
                     <mat-cell *matCellDef="let fetchedDataForItemArray;let pdsIndex = index;" class="column-medium"
                        [ngClass]="{'hide-table-column': (currentToggleMenu ==='quality-certificate')}">
                        <a class='pdf-content' (click)='showLanguageSelectionPopUp(fetchedDataForItemArray, "PDS")'>
                           <ng-container><span class='font-vsmall font-bold'>
                                 {{'HOME.View'| translate}}
                              </span>
                           </ng-container>
                        </a>
                     </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="SAFETY_DATA">
                     <mat-header-cell class="header-font column-medium"
                        [ngClass]="{'hide-table-column': (currentToggleMenu ==='quality-certificate')}" *matHeaderCellDef>
                        <!-- {{'HOME.Sdata'| translate}} -->
                        <span title="{{'HOME.Safetydatasheet'| translate}}">
                           {{'HOME.SDS'| translate}}
                        </span>
                     </mat-header-cell>
                     <mat-cell *matCellDef="let fetchedDataForItemArray;let sdIndex = index;" class="column-medium"
                        [ngClass]="{'hide-table-column': (currentToggleMenu ==='quality-certificate')}">
                        <a class='pdf-content' (click)='showLanguageSelectionPopUp(fetchedDataForItemArray, "SDS")'>
                           <ng-container><span class='font-vsmall font-bold'>
                                 {{'HOME.View'| translate}}
                              </span>
                           </ng-container>
                        </a>
                     </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="CE_MARK">
                     <mat-header-cell class="header-font column-medium"
                        [ngClass]="{'hide-table-column': (currentToggleMenu ==='quality-certificate')}" *matHeaderCellDef>
                        <!-- {{'HOME.CE-Mark'| translate}} -->

                        <!-- Commented below code, kept for future reference -->
                        <!-- <span *ngIf="countryCodeMarket !== 'GBR'" title="{{'HOME.CEmark'| translate}}">
                           {{'HOME.CE'| translate}}
                        </span>
                        <span *ngIf="countryCodeMarket === 'GBR'" title="{{'HOME.CAmark'| translate}}">
                           {{'HOME.CA'| translate}}
                        </span> -->

                        <span title="{{'HOME.CEmark'| translate}}">
                           {{'HOME.CE'| translate}}
                        </span>

                     </mat-header-cell>
                     <mat-cell *matCellDef="let fetchedDataForItemArray;let ceIndex = index;" class="column-medium"
                        [ngClass]="{'hide-table-column': (currentToggleMenu ==='quality-certificate')}">
                        <a class='pdf-content' (click)='showLanguageSelectionPopUp(fetchedDataForItemArray, "CEMARK")'>
                           <ng-container><span class='font-vsmall font-bold'>
                                 {{'HOME.View'| translate}}
                              </span>
                           </ng-container>
                        </a>
                     </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="PERFORMANCE_REPORT">
                     <mat-header-cell class="header-font mat_column_performance_report_mv column-medium"
                        [ngClass]="{'hide-table-column': (currentToggleMenu ==='quality-certificate')}" *matHeaderCellDef>
                        <!-- {{'HOME.Preport'| translate}} -->
                        <!-- {{'HOME.Declarationofperformance'| translate}} -->
                        <span title="{{'HOME.Declarationofperformance'| translate}}">
                           {{'HOME.DoP'| translate}}
                        </span>
                     </mat-header-cell>
                     <mat-cell *matCellDef="let fetchedDataForItemArray;let pfIndex = index;"
                        class="column-medium mat_column_performance_report_mv"
                        [ngClass]="{'hide-table-column': (currentToggleMenu ==='quality-certificate')}">
                        <a class='pdf-content' (click)='showLanguageSelectionPopUp(fetchedDataForItemArray, "DOP")'>
                           <ng-container><span class='font-vsmall font-bold'>
                                 {{'HOME.View'| translate}}
                              </span>
                           </ng-container>
                        </a>
                     </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="qualityCertificate">
                     <mat-header-cell class="header-font column-medium"
                        [ngClass]="{'hide-table-column': (countryCode === 'SWE' || countryCode === 'NOR' || countryCode === 'GBR' || currentToggleMenu ==='product-catalogue')}"
                        *matHeaderCellDef>
                        {{'HOME.Qualitycertificate'| translate}}
                     </mat-header-cell>
                     <mat-cell
                        [ngClass]="{'hide-table-column': (countryCode === 'SWE' || countryCode === 'NOR' || countryCode === 'GBR' || currentToggleMenu ==='product-catalogue')}"
                        *matCellDef="let fetchedDataForItemArray;let qcIndex = index;" class="column-medium">
                        <!-- <a class='pdf-content'
                               (click)='downloadPdf(qcIndex,fetchedDataForItemArray.qualityCertificate,fetchedDataForItemArray.PRODUCT_NAME,"QC")'>
                               <span class='font-vsmall font-bold'> Download</span>
                               </a> -->
                        <a class='pdf-content' (click)='getQualityCertificate(fetchedDataForItemArray.PRODUCT_ID)'>
                           <span class='font-vsmall font-bold'> {{'HOME.View'| translate}}</span>
                        </a>
                     </mat-cell>
                  </ng-container>
                  <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns;sticky: true">
                  </mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
               </mat-table>
               <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons>
               </mat-paginator>
               <!-- <div class="row buttonGroup">
                            <button class=" downloadButton" type="submit" (click)="downloadBitumenIndexData()">Download</button>
                            </div> -->
            </div>
         </div>
      </div>
   </div>
   <!-- Table View Of products Ends Here-->
</div>
<!-- <div class="container-fluid sidepadding-none  " [hidden]='(productsInformationData.length>0)'>
   {{'HOME.DNA'| translate}}
</div> -->