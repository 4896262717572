<div class="container-fluid">
    <div class="row">
        <div class="col-sm">

            <div class="text-center">
                <span mat-dialog-title class="header-text">{{'HOME.MonthlyPendingForecastReminder'|translate}} </span>
            </div>
            <div class="">
                <label class="month-name-label" for="month"> {{'HOME.Customername'| translate}} : </label>
                <span class="month-name">&nbsp;{{ customerName }}</span>
            </div>
            <!-- <div class="">
                <label class="month-name-label" for="month"> {{'HOME.Month'| translate}} : </label>
                <span class="month-name">&nbsp;{{ month }}</span>
            </div> -->
            <div class="">
                <label class="month-name-label" for="month"> {{'HOME.Month'| translate}} :&nbsp;</label>
                <span *ngFor="let month of months; last as isLast" class="month-name">{{'HOME.' + month | translate}}<span
                        *ngIf="!isLast">,&nbsp;</span>
                </span>
            </div>

            <table>
                <tr class="row-background row-height">
                    <th class="emailId-column">
                        <span class="dialog-table-column-text">
                            {{'HOME.Email'| translate}}
                        </span></th>
                    <th class="selection-column">
                        <span>
                            <input class="checkbox-add-remove" type="checkbox"
                                [(ngModel)]="isAllAdhesiveProductsSelected" (click)="selectAllAdhesiveProductsToSave()"
                                title="{{'HOME.Selecttosaveallproducts'| translate}}">
                        </span>
                    </th>
                </tr>
                <tr *ngFor="let emailId of emailIdList" class="row-height">
                    <td>
                        <span class="dialog-table-row-text">
                            {{emailId['emailId']}}
                        </span>
                    </td>
                    <td>
                        <span>
                            <input class="checkbox-add-remove" type="checkbox" [(ngModel)]="emailId.isSelected"
                                title="{{'HOME.Selecttosaveproduct'| translate}}">
                        </span>
                    </td>
                </tr>
            </table>

            <!-- <table mat-table #firstTable #adhesivePricesTableSort="matSort"
                [dataSource]="dataSourceForAdhesivePricesTable" class=" horizontal_scrollbar adhesive_price"
                matSort>
                <ng-container matColumnDef="emailId">
                    <mat-header-cell class="mat-column-large" *matHeaderCellDef
                        mat-sort-header>
                        {{'HOME.Email'| translate}}
                    </mat-header-cell>
                    <mat-cell class="mat-column-large" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.emailId}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="selection">
                    <mat-header-cell class="mat-column-medium" *matHeaderCellDef>
                        <span>
                            <input class="checkbox-add-remove" type="checkbox"
                                [(ngModel)]="isAllAdhesiveProductsSelected" (click)="selectAllAdhesiveProductsToSave()"
                                title="{{'HOME.Selecttosaveallproducts'| translate}}">
                        </span>
                    </mat-header-cell>
                    <th mat-header-cell *matHeaderCellDef class="header-font mat-column-medium">
                    </th>
                    <mat-cell class="mat-column-medium" *matCellDef="let element">
                        <span>
                            <input class="checkbox-add-remove" type="checkbox" [(ngModel)]="element.isSelected"
                                title="{{'HOME.Selecttosaveproduct'| translate}}">
                        </span>
                    </mat-cell>
                </ng-container>
                <mat-header-row class="table-header"
                    *matHeaderRowDef="displayedColumnsForAdhesivePricesTable; sticky:true">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsForAdhesivePricesTable;">
                </mat-row>
            </table> -->
        </div>
    </div>
    <div class="row pt-3">
        <div class="col-sm">
            <mat-dialog-actions align="center">
                <button class="btn btn-sm btn-primary dialog-footer-button ok-button"
                    (click)="sendEmail()">{{'HOME.SendEmail'| translate}}</button>
                <span class="pl-2">
                    <button class="btn btn-sm btn-primary dialog-footer-button cancel-button" (click)="cancel()"
                        mat-dialog-close tabindex="-1">{{'HOME.Cancel'| translate}}</button>
                </span>
            </mat-dialog-actions>
        </div>
    </div>
</div>