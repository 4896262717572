<div class="row" style="display: flex; justify-content: center;">
  <div class="mt-3" [hidden]="!this.noDisp" style="font-size: larger;">
    {{'HOME.NoData'| translate}}

  </div>
</div>
<div [hidden]="this.noDisp">
  <div class="forecast-div">

    <div class="container">
      <div class="row">
 
        <div class="col-lg-6 toggle_div_mv" style="padding-top:0px">
          <mat-button-toggle-group class="button-group-style toggle_button_mv" name="fontStyle" aria-label="Font Style"
            #group="matButtonToggleGroup" [(ngModel)]="forecastValue" value="rollingWeek">

            <!-- <ng-container *ngIf="countryCode == 'FIN'">
              <mat-button-toggle value="weekly" ngDefaultControl class="" (click)=fetchOnClick()>
                {{'HOME.Weekly'| translate}}
              </mat-button-toggle>
            </ng-container>

            <ng-container *ngIf="showMonthlyForecastEntryTab && (countryCode =='FIN' || countryCode == 'NOR')">
              <mat-button-toggle value="monthly" ngDefaultControl class="" (click)=fetchOnClick()>
                {{'HOME.Monthly'| translate}}
              </mat-button-toggle>
            </ng-container>

            <ng-container *ngIf="countryCode == 'SWE' || countryCode == 'NOR'">
              <mat-button-toggle value="rollingWeek" ngDefaultControl class="" (click)=fetchOnClick()>
                {{'HOME.Rolling'| translate}}
              </mat-button-toggle>
            </ng-container> -->
            
            <!-- Hide weekly forecast page from Finland as well. -->
            <!-- <ng-container *ngIf="countryCode == 'FIN'">
              <mat-button-toggle value="weekly" ngDefaultControl class="" (click)="toggleButtonClick()">
                {{'HOME.Weekly'| translate}}
              </mat-button-toggle>
            </ng-container> -->
            
            <ng-container *ngIf="showMonthlyForecastEntryTab">
              <mat-button-toggle value="monthly" ngDefaultControl class="" (click)="toggleButtonClick()">
                {{'HOME.Monthly'| translate}}
              </mat-button-toggle>
            </ng-container>
            
            <ng-container *ngIf="showRollingFourWeekForecastEntryTab">
              <mat-button-toggle value="rollingWeek" ngDefaultControl class="" (click)="toggleButtonClick()">
                <!-- {{'HOME.Rolling'| translate}} -->
                <!-- For Finland show tab name as Weekly, for other countries show it as Rolling 4 week -->
                <span *ngIf="countryCode === 'FIN'">
                  {{'HOME.Weekly'| translate}}
                </span>
                <span *ngIf="countryCode !== 'FIN'">
                  {{'HOME.RollingFourWeek'| translate}}
                </span>
              </mat-button-toggle>
            </ng-container>
            
            <ng-container *ngIf="showContractualVolumeTab">
              <mat-button-toggle value="contractualVolume" ngDefaultControl (click)="toggleButtonClick()">
                {{'HOME.ContractualVolume'| translate}}
              </mat-button-toggle>
            </ng-container>

          </mat-button-toggle-group>
        </div>
        <div class="col-lg-6 downnload_div_mv"> 
 
          <div class="row downloadHead" *ngIf="!this.mobileQuery.matches">
            <div *ngIf="this.userInfo.RoleID === 'PA' ">
              <div *ngIf="group.value == 'monthly'">
                <!-- <i (click)="monthlyForecastDownloadforAll()" class="fas fa-download downloadIcon downloadBtn"><span
                                class="downloadText">{{'HOME.DownloadAll'| translate}}</span></i> -->
                <i (click)="downloadAllForecastDetails()" class="fas fa-download downloadIcon downloadBtn">
                  <span class="downloadText">{{'HOME.DownloadAll'| translate}}</span>
                </i>
              </div>
              <div *ngIf="group.value === 'contractualVolume'">
                <i (click)="downloadAllContractualVolumeDetails()" class="fas fa-download downloadIcon downloadBtn">
                  <span class="downloadText">{{'HOME.DownloadAll'| translate}}</span>
                </i>
              </div>
            </div>

            <div *ngIf="countryCode === 'NOR' && isLoggedInUserCustomerAdmin">
              <div *ngIf="group.value == 'monthly'">
                <i (click)="fetchAllMonthlyForecastDataForCustomerAdmin()" class="fas fa-download downloadIcon downloadBtn">
                  <span class="downloadText">{{'HOME.DownloadAll'| translate}}</span>
                </i>
              </div>
            </div>
            
            <div class="downloadDiv">
              <!-- <div *ngIf="group.value == 'monthly' || group.value == 'weekly'">
                <i (click)="downloadDefaulterList(group.value)" class="fas fa-download downloadIcon "><span
                    class="downloadText1">{{'HOME.Download'| translate}}</span></i>
              </div> -->

              <div *ngIf="group.value == 'monthly' || group.value == 'weekly' || group.value == 'contractualVolume'">
                <i (click)="download()" class="fas fa-download downloadIcon "><span class="downloadText1">{{'HOME.Download'|
                    translate}}</span></i>
              </div>

              <div *ngIf="group.value == 'rollingWeek' && isLoggedInUserPortalAdmin">
                <i (click)="fetchRollingForecastForAll()" class="fas fa-download downloadIcon "><span
                    class="downloadText1">{{'HOME.DownloadAll'| translate}}</span></i>
              </div>
              <div *ngIf="group.value == 'rollingWeek' && isLoggedInUserCustomerAdmin">
                <i (click)="fetchAllRollingForecastDataForCustomerAdmin()" class="fas fa-download downloadIcon "><span
                    class="downloadText1">{{'HOME.DownloadAll'| translate}}</span></i>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div class="row" style="padding-top: 2%; padding-left: 10px">

        <div class="col-auto customer_name_label_div_mv paddingForLabels" *ngIf="this.role !== 'U'">
          <label for="CustomerId">
            <span class="monthlyLabel customer_name_label_mv "> {{'HOME.Customername'| translate}}
            </span>
          </label>
        </div>
        <div class="col-auto customer_name_Dropdown_div_mv noPadding">
          <div *ngIf="this.role == 'PA'">
            <select class="forecastSelect form-control " id="CustomerId" [(ngModel)]='customerIdUserSelected'
              (change)="this.getDeliverySites()">

              <option *ngFor="let customer of Customers" [ngValue]="customer.CustomerID">
                {{customer.CustomerName}}
              </option>
            </select>
          </div>
          <div *ngIf="this.role == 'A'">
            <input type="text" class="forecastInput" [value]="customerUserSelected" disabled />
          </div>

        </div>
        <div class="col-auto col-xs-7 delivery_Site_label_div_MV paddingForLabels"
          *ngIf="!((countryCode === 'FIN' || countryCode === 'SWE') && (group.value === 'monthly' || group.value === 'contractualVolume'))">
          <label class="monthlyLabel3 delivery_Site_Label_mv "> {{'HOME.Deliverysite'| translate}}
          </label>
        </div>

        <div class="col-auto col-xs-5 delivery_Site_Dropdown_div_MV noPadding"
          *ngIf="!((countryCode === 'FIN' || countryCode === 'SWE') && (group.value === 'monthly' || group.value === 'contractualVolume'))">
          <select [(ngModel)]='delSiteUserSelected' class="forecastSelect form-control delivery_Site_Dropdown_MV"
            (change)="changeOnClick()">
            <option value="">{{'HOME.SELECT'| translate}}</option>
            <option *ngFor="let delSite of delSites" [value]="delSite">{{delSite}}</option>
          </select>
        </div>

        <div *ngIf="group.value == 'weekly' || group.value == 'monthly' || group.value == 'contractualVolume'" class="col-auto monthly_year_label_div_MV paddingForLabels" >
          <label class="monthlyLabel year_label_MV"> {{'HOME.Year'| translate}}
          </label>
        </div>
        <div *ngIf="group.value == 'weekly' || group.value == 'monthly' || group.value == 'contractualVolume'"
          class="col-auto year_dropdown_div_MV noPadding">
          <form [formGroup]="forecastEntryForm ">
            <select formControlName="year" class="forecastSelect year_forecastSelect_MV form-control"
              [ngClass]="{ 'is-invalid': submitted && fields.year.errors }" (change)="changeOnClick()"
              [value]="this.yearUserSelected">
              <option *ngFor="let year of dropDownYear" [value]="year">{{year}}</option>
              <div *ngIf="submitted && fields.year.errors" class="invalid-feedback">
                <div *ngIf="fields.year.errors"> {{'HOME.Enteryear'| translate}}
                </div>
              </div>
            </select>
        
          </form>
        </div>


        <div class="col-auto week_label_div_MV paddingForLabels" *ngIf="group.value == 'weekly'">
          <label class="monthlyLabel week_label_MV"> {{'HOME.Week'| translate}}
          </label>
        </div>
        <div class="col-auto week_dropdown_div_MV noPadding" *ngIf="group.value == 'weekly'">
          <form [formGroup]="forecastEntryForm">
            <input formControlName="week" type="number" class="forecastInput form-control week_dropdown_MV"
              min={{minWeek}} max={{maxWeek}} style="width:45px"
              [ngClass]="{ 'is-invalid': submitted && fields.week.errors && (fields.week.errors.min || fields.week.errors.max || fields.week.errors.pattern) }"
              (change)="changeOnClick()" [value]="weekUserSelected">

            <div *ngIf="submitted && fields.week.errors && (fields.week.errors.min || fields.week.errors.max || fields.week.errors.pattern) "
              class="invalid-feedback">
              <div *ngIf="fields.week.errors.min || fields.week.errors.max"> 
                {{'HOME.Enterweekfrom'| translate}} {{minWeek}} {{'HOME.to'| translate}} {{maxWeek}}
              </div>
              <div *ngIf="fields.week.errors.pattern" >
                <div>{{'HOME.NoDecimalNumber'| translate}}</div>
              </div>
            </div>
          </form>
        </div>


        <div class="col-auto find_div_mv " style="padding-top: 0px;"  [hidden]="group.value == 'weekly'">
          <div class="row" style="float: right;">
            <button class="goButton" type="submit" (click)="fetchOnClick(true)"> {{'HOME.Find'| translate}}
            </button>
          </div>
        </div>

        <div class="col-auto find_weekly_div_mv" style="padding-top: 0px;" *ngIf="group.value == 'weekly'">
          <div class="row" style="float: right;">
            <button class="goButton" type="submit" (click)="fetchOnClick(true)"> {{'HOME.Find'| translate}}
            </button>
          </div>
        </div>



      </div>
      <div class="row pt-3">
        <div class="col-12 note-message">
          <!-- <span *ngIf="group.value == 'weekly' || group.value == 'rollingWeek'">
            {{'HOME.Note1'| translate}}
          </span> -->
          <span
            *ngIf="(group.value == 'weekly' || group.value == 'rollingWeek') && (countryCode === 'NOR' && customerID === '32807')">
            {{'HOME.ForecastLockMessageForNorwayVeidekke'| translate}}
          </span>
          <span
            *ngIf="(group.value == 'weekly' || group.value == 'rollingWeek') && !(countryCode === 'NOR' && customerID === '32807')">
            {{'HOME.Note1'| translate}}
          </span>
          <!-- <span *ngIf="group.value == 'monthly' && countryCode !== 'SWE'">
            {{'HOME.Note2'| translate}}
          </span>
          <span *ngIf="group.value == 'monthly' && countryCode === 'SWE'">
            {{'HOME.MonthlyForecastEntryLockMessageForSweden'| translate}}
          </span> -->
          <span *ngIf="group.value == 'monthly'">
            {{'HOME.MonthlyForecastEntryNote'| translate}}
          </span>
          <span *ngIf="group.value == 'contractualVolume'">
            {{'HOME.ContractualVolumeNote'| translate}}
          </span>
          <!-- This is a placeholder to put any important message on Forecast entry page -->
          <!-- <div *ngIf="countryCode === 'FIN' && customerID !== '267359'" class="warning-message">
            {{'HOME.Hamina_depot_closure_message'| translate}}
          </div> -->
        </div>

      </div>
    </div>

  </div>
  <div>
    <div [hidden]="group.value == 'monthly' || group.value == 'rollingWeek' || group.value == 'contractualVolume'">
      <div class="container">
        <div class="col-lg-12 mt-10 ">
          <div class="row" style="display: flex; justify-content: center;">
            <div class="mt-5" [hidden]="this.displayTableW" style="font-size: larger;">
              {{'HOME.Norecordsfound'| translate}}
            </div>
            <div class="col-lg-12 mt-3" [hidden]='!this.displayTableW' style="padding: 0px">
              <!--Angular 7 Impl-->

              <mat-table #table [dataSource]="this.dataSourceWeekly" #weekSort="matSort"
                class="forecastTable horizontal_scrollbar_weekly " matSort>


                <!-- Item Column -->
                <ng-container matColumnDef="ItemName">
                  <mat-header-cell class="header-font ItemNameWidth" *matHeaderCellDef mat-sort-header
                    [ngClass]="' mat_column_product_name_mv '"> {{'HOME.Pname'| translate}}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray" [ngClass]="' mat_column_product_name_mv '">
                    {{fetchedDataForItemArray.ItemName}}
                  </mat-cell>
                </ng-container>

                <!-- Depot Column -->
                <ng-container matColumnDef="Depot">
                  <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header
                    [ngClass]="' mat_column_depot_mv '"> {{'HOME.Depot'| translate}}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray" [ngClass]="' mat_column_depot_mv '">
                    {{fetchedDataForItemArray.Depot}}
                  </mat-cell>
                </ng-container>


                <!-- Quantity Column -->
                <ng-container matColumnDef="Quantity">
                  <mat-header-cell class="header-font" *matHeaderCellDef [ngClass]="' mat_column_quantity_mv '">
                    {{'HOME.Quantitymetricton'| translate}}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray" [ngClass]="' mat_column_quantity_mv '">
                    <ng-container>
                      <input type="text" [(ngModel)]="fetchedDataForItemArray.Quantity" class="selector"
                        [disabled]="disableWeekForecast(fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Quantity) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Quantity)"
                        class="pl-3 is-invalid-quantity-message">
                        <div>{{'HOME.Entervalidnumberupto3decimalplacesonly'| translate}}</div>
                      </div>

                    </ng-container>
                  </mat-cell>
                </ng-container>


                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsWeek; sticky:true">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsWeek;" (click)="onSelectWeek(row)"
                  (change)="onSelectWeek(row)" [ngClass]="{'onRowSelect': selectedRowIndex == row.RowID}"></mat-row>

              </mat-table>
              <!-- <mat-paginator #paginator   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"  >
                </mat-paginator> -->

              <div class="row buttonGroup">
                <button class=" forecastButton" type="submit"
                  (click)="updateWeeklyForecastNew(this.dataSourceWeekly)">{{'HOME.Save'| translate}}</button>

                <!-- <button class=" forecastButton" type="submit" (click)="testService()">Save</button> -->

                <button class=" forecastButton" type="submit" (click)="fetchData()">{{'HOME.Reset'| translate}}</button>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div [hidden]="this.role == 'A' || this.role == 'U'"> -->
    <!-- <app-monthly-forecast *ngIf="group.value == 'monthly'"></app-monthly-forecast> -->
    <div [hidden]="group.value == 'weekly' ||  group.value == 'rollingWeek' || group.value == 'contractualVolume'">
      <div class="container">
        <div class="col-lg-12 mt-10">
          <div class="row" style="display: flex; justify-content: center;">

            <div class="mt-5" [hidden]="this.displayTableM"
              style="display: flex; justify-content: center; font-size: larger;">
              {{'HOME.Norecordsfound'| translate}}
            </div>

            <div class="col-lg-12 mt-3" [hidden]='!this.displayTableM' style="padding: 0px">
              <!--Angular 7 Impl-->
              <mat-table #table [dataSource]="this.dataSourceMonthly" #monthSort="matSort"
                class="forecastTable horizontal_scrollbar_monthly" matSort>

                <!-- Item Column -->
                <ng-container matColumnDef="ProductName">
                  <mat-header-cell class="header-font mat_column_product_name_monthly_mv" *matHeaderCellDef
                    mat-sort-header (click)="clickSort(this.dataSourceMonthly)"> {{'HOME.Pname'| translate}}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray " class=" mat_column_product_name_monthly_mv ">
                    {{fetchedDataForItemArray.ProductName}}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef class=" mat_column_product_name_monthly_mv " ></mat-footer-cell>
                </ng-container>

                <!-- Depot Column -->
                <ng-container matColumnDef="DepotName">
                  <mat-header-cell class="header-font mat_column_depot_monthly_mv" *matHeaderCellDef mat-sort-header
                    (click)="clickSort(this.dataSourceMonthly)"> {{'HOME.Depot'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray" class=" mat_column_depot_monthly_mv ">
                    {{fetchedDataForItemArray.DepotName}}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef class=" mat_column_depot_monthly_mv " ></mat-footer-cell>
                </ng-container>

                <!-- January Column -->
                <ng-container matColumnDef="Jan">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Jan'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Jan" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(1, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Jan) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Jan)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_jan_mv">
                    {{ getMonthTotal(1) }}</mat-footer-cell>
                </ng-container>
                <!-- Feburary Column -->
                <ng-container matColumnDef="Feb">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Feb'| translate}} </mat-header-cell>

                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Feb" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(2, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Feb) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Feb)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotal(2) }}</mat-footer-cell>
                </ng-container>
                <!-- Mar Column -->
                <ng-container matColumnDef="Mar">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Mar'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Mar" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(3, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Mar) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Mar)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotal(3) }}</mat-footer-cell>
                </ng-container>
                <!-- Apr Column -->
                <ng-container matColumnDef="Apr">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Apr'| translate}} </mat-header-cell>

                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Apr" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(4, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Apr) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Apr)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotal(4) }}</mat-footer-cell>
                </ng-container>
                <!-- May Column -->
                <ng-container matColumnDef="May">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.May'| translate}} </mat-header-cell>

                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.May" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(5, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.May) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.May)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotal(5) }}</mat-footer-cell>
                </ng-container>
                <!-- Jun Column -->
                <ng-container matColumnDef="Jun">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Jun'| translate}} </mat-header-cell>

                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Jun" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(6, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Jun) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Jun)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotal(6) }}</mat-footer-cell>
                </ng-container>
                <!-- Jul Column -->
                <ng-container matColumnDef="Jul">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Jul'| translate}} </mat-header-cell>

                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Jul" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(7, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Jul) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Jul)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotal(7) }}</mat-footer-cell>
                </ng-container>
                <!-- Aug Column -->
                <ng-container matColumnDef="Aug">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Aug'| translate}} </mat-header-cell>

                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Aug" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(8, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Aug) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Aug)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotal(8) }}</mat-footer-cell>
                </ng-container>
                <!-- Sep Column -->
                <ng-container matColumnDef="Sep">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Sep'| translate}} </mat-header-cell>

                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Sep" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(9, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Sep) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Sep)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotal(9) }}</mat-footer-cell>
                </ng-container>
                <!-- Oct Column -->
                <ng-container matColumnDef="Oct">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Oct'| translate}} </mat-header-cell>

                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Oct" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(10, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Oct) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Oct)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotal(10) }}</mat-footer-cell>
                </ng-container>
                <!-- Nov Column -->
                <ng-container matColumnDef="Nov">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Nov'| translate}} </mat-header-cell>

                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Nov" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(11, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Nov) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Nov)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotal(11) }}</mat-footer-cell>
                </ng-container>
                <!-- Dec Column -->
                <ng-container matColumnDef="Dec">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Dec'| translate}} </mat-header-cell>

                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Dec" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableMonthForecast(12, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Dec) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Dec)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotal(12) }}</mat-footer-cell>
                </ng-container>
                <!---->
                <ng-container matColumnDef="Total">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Total'| translate}} </mat-header-cell>

                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    {{getTotal(fetchedDataForItemArray.Jan,fetchedDataForItemArray.Feb,fetchedDataForItemArray.Mar,
                    fetchedDataForItemArray.Apr,fetchedDataForItemArray.May,fetchedDataForItemArray.Jun,
                  fetchedDataForItemArray.Jul,fetchedDataForItemArray.Aug,fetchedDataForItemArray.Sep,
                fetchedDataForItemArray.Oct,fetchedDataForItemArray.Nov,fetchedDataForItemArray.Dec)}}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_total_mv">
                    {{ getMonthTotal(13) }}</mat-footer-cell>
                </ng-container>
                <!---->


                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsMonth"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsMonth;" (click)="onSelectMonth(row)"
                  (change)="onSelectMonth(row)" [ngClass]="{'onRowSelect-class': selectedRowIndexMonth == row.RowID}">
                </mat-row>
                <mat-footer-row class="sticky-footer" *matFooterRowDef="displayedColumnsMonth;"></mat-footer-row>

              </mat-table>

              <div class="row buttonGroup">
                <!-- <div class="col-3"></div> -->
                <button class=" forecastButton" (click)="updateMonthlyForecast(this.dataSourceMonthly)">
                  {{'HOME.Save'| translate}}
                </button>
                <!-- <div class="col-1"></div> -->
                <button class=" forecastButton" type="submit" (click)="fetchData()"> {{'HOME.Reset'| translate}}
                </button>
                <!-- <div class="col-3"></div> -->
                <!-- <button class=" forecastButton" type="submit"
                    (click)="downloadDefaulterList()">{{'HOME.Download'| translate}}</button> -->

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <div [hidden]="group.value =='monthly' || group.value == 'weekly' || group.value == 'contractualVolume'">
      <!-- Rolling week in progress -->
      <div class="container">
        <div class="col-lg-12 mt-10">
          <div class="row" style="display: flex; justify-content: center;">

            <div class="mt-5" [hidden]="this.displayTableR"
              style="display: flex; justify-content: center; font-size: larger;">
              {{'HOME.Norecordsfound'| translate}}
            </div>

            <div class="col-lg-12 mt-3" [hidden]="!this.displayTableR" style="padding: 0px">
              <!--Angular 7 Impl-->
              <mat-table #table [dataSource]="this.dataSourceRolling" #rollingWeekSort="matSort"
                class="forecastTable horizontal_scrollbar_monthly" matSort>


                <ng-container *ngFor="let disCol of displayedColumnsRollingWeek; let colIndex = index"
                  matColumnDef="{{disCol}}">
                  <div *ngIf="!disCol.includes('Week') ">
                    <mat-header-cell class="header-font" *matHeaderCellDef [ngClass]="'mat_column_quantity_mv'"
                      mat-sort-header>
                      {{'HOME.'+disCol| translate}}

                    </mat-header-cell>
                  </div>
                  <div *ngIf="disCol.includes('Week')">
                    <mat-header-cell class="header-font" *matHeaderCellDef [ngClass]="'mat_column_quantity_mv'">
                      {{'HOME.Week'| translate}} {{disCol.substring(5)}}
                    </mat-header-cell>
                  </div>
                  <div *ngIf="!(disCol.includes('Week') || disCol.includes('Total'))">
                    <mat-cell *matCellDef="let fetchedDataForItemArray " class=" mat_column_product_name_monthly_mv ">
                      {{fetchedDataForItemArray[disCol]}}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef class=" mat_column_product_name_monthly_mv " ></mat-footer-cell>
                  </div>

                  <div *ngIf="disCol.includes('Week')">
                    <mat-cell *matCellDef="let fetchedDataForItemArray" [ngClass]="' mat_column_quantity_mv '">
                      <ng-container>
                        <input type="number" [(ngModel)]="fetchedDataForItemArray[disCol]" class="selector"
                          [disabled]="disableRollingWeekForecast(disCol, fetchedDataForItemArray)"
                          [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray[disCol]) }"
                          (change)="changeRollingWeekForecastDetail(disCol, fetchedDataForItemArray)">
                        <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray[disCol])"
                          class="pl-3 is-invalid-quantity-message">
                          <div></div>
                        </div>
                      </ng-container> 
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef [ngClass]="' mat_column_quantity_mv '" ></mat-footer-cell>
                  </div>

                  <div *ngIf="disCol.includes('Total')">
                    <mat-cell *matCellDef="let fetchedDataForItemArray">
                      {{getRollingWeekTotal(fetchedDataForItemArray)}}
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total_cell_rolling_mv">
                      {{ getRollingTotal() }}</mat-footer-cell>
                  </div>
                </ng-container>



                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsRollingWeek"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsRollingWeek;" (click)="onSelectRollingWeek(row)"
                  (change)="onSelectRollingWeek(row)"
                  [ngClass]="{'onRowSelect-class': selectedRowIndexRollingWeek == row.RowID}">
                </mat-row>
                <mat-footer-row class="sticky-footer" *matFooterRowDef="displayedColumnsRollingWeek;"></mat-footer-row>

              </mat-table>

              <div class="row buttonGroup">
                <!-- <div class="col-3"></div> -->
                <button class=" forecastButton" (click)="updateRollingWeekForecastNew()">
                  {{'HOME.Save'| translate}}
                </button>
                <!-- <div class="col-1"></div> -->
                <button class=" forecastButton" type="submit" (click)="fetchData()"> {{'HOME.Reset'| translate}}
                </button>
                <!-- <div class="col-3"></div> -->
                <!-- <button class=" forecastButton" type="submit"
                      (click)="downloadDefaulterList()">{{'HOME.Download'| translate}}</button> -->

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div [hidden]="group.value == 'weekly' ||  group.value == 'rollingWeek' || group.value == 'monthly'">
      <div class="container">
        <div class="col-lg-12 mt-10">
          <div class="row" style="display: flex; justify-content: center;">
            <div class="mt-5" [hidden]="this.displayTableContractualVolume"
              style="display: flex; justify-content: center; font-size: larger;">
              {{'HOME.Norecordsfound'| translate}}
            </div>
            <div class="col-lg-12 mt-3" [hidden]='!this.displayTableContractualVolume' style="padding: 0px">
              <mat-table #table [dataSource]="this.dataSourceContractualVolume" #contractualVolumeSort="matSort"
                class="forecastTable horizontal_scrollbar_monthly" matSort>
                <ng-container matColumnDef="ProductName">
                  <mat-header-cell class="header-font mat_column_product_name_monthly_mv" *matHeaderCellDef mat-sort-header
                    (click)="clickSort(this.dataSourceContractualVolume)"> {{'HOME.Pname'| translate}}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray " class=" mat_column_product_name_monthly_mv ">
                    {{fetchedDataForItemArray.ProductName}}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef class=" mat_column_product_name_monthly_mv "></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="DepotName">
                  <mat-header-cell class="header-font mat_column_depot_monthly_mv" *matHeaderCellDef mat-sort-header
                    (click)="clickSort(this.dataSourceContractualVolume)"> {{'HOME.Depot'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray" class=" mat_column_depot_monthly_mv ">
                    {{fetchedDataForItemArray.DepotName}}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef class=" mat_column_depot_monthly_mv "></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Jan">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Jan'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Jan" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(1, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Jan) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Jan)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_jan_mv">
                    {{ getMonthTotalForContractualVolumeTableData(1) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Feb">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Feb'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Feb" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(2, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Feb) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Feb)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotalForContractualVolumeTableData(2) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Mar">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Mar'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Mar" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(3, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Mar) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Mar)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotalForContractualVolumeTableData(3) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Apr">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Apr'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Apr" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(4, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Apr) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Apr)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotalForContractualVolumeTableData(4) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="May">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.May'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.May" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(5, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.May) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.May)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotalForContractualVolumeTableData(5) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Jun">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Jun'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Jun" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(6, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Jun) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Jun)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotalForContractualVolumeTableData(6) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Jul">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Jul'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Jul" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(7, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Jul) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Jul)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotalForContractualVolumeTableData(7) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Aug">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Aug'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Aug" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(8, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Aug) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Aug)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotalForContractualVolumeTableData(8) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Sep">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Sep'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Sep" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(9, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Sep) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Sep)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotalForContractualVolumeTableData(9) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Oct">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Oct'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Oct" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(10, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Oct) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Oct)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotalForContractualVolumeTableData(10) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Nov">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Nov'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Nov" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(11, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Nov) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Nov)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotalForContractualVolumeTableData(11) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Dec">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Dec'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    <ng-container>
                      <input [(ngModel)]="fetchedDataForItemArray.Dec" type="number" step="0.01" class="selector"
                        placeholder="" [disabled]="disableForecastEntryTextBoxForContractualVolume(12, fetchedDataForItemArray)"
                        [ngClass]="{ 'is-invalid-quantity': isInValidDecimalNumber(fetchedDataForItemArray.Dec) }">
                      <div *ngIf="isInValidDecimalNumber(fetchedDataForItemArray.Dec)"
                        class="pl-3 is-invalid-quantity-message">
                        <div></div>
                      </div>
                    </ng-container>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_mv">
                    {{ getMonthTotalForContractualVolumeTableData(12) }}</mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="Total">
                  <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Total'| translate}} </mat-header-cell>
                  <mat-cell *matCellDef="let fetchedDataForItemArray">
                    {{getTotal(fetchedDataForItemArray.Jan,fetchedDataForItemArray.Feb,fetchedDataForItemArray.Mar,
                    fetchedDataForItemArray.Apr,fetchedDataForItemArray.May,fetchedDataForItemArray.Jun,
                    fetchedDataForItemArray.Jul,fetchedDataForItemArray.Aug,fetchedDataForItemArray.Sep,
                    fetchedDataForItemArray.Oct,fetchedDataForItemArray.Nov,fetchedDataForItemArray.Dec)}}
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef="let fetchedDataForItemArray" class="total-cell table_cell_total_mv">
                    {{ getMonthTotalForContractualVolumeTableData(13) }}</mat-footer-cell>
                </ng-container>
                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsContractualVolume"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsContractualVolume;" (click)="onSelectMonth(row)"
                  (change)="onSelectMonth(row)" [ngClass]="{'onRowSelect-class': selectedRowIndexMonth == row.RowID}">
                </mat-row>
                <mat-footer-row class="sticky-footer" *matFooterRowDef="displayedColumnsContractualVolume;"></mat-footer-row>
              </mat-table>
              <div class="row buttonGroup">
                <button class=" forecastButton" (click)="updateContractualVolumeDataList()">
                  {{'HOME.Save'| translate}}
                </button>
                <button class=" forecastButton" type="submit" (click)="fetchData()"> {{'HOME.Reset'| translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
