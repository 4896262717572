/* import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-body',
  templateUrl: './dialog-body.component.html',
  styleUrls: ['./dialog-body.component.css']
})
export class DialogBodyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

} */

import {
	ChangeDetectorRef, Component,
	Inject, ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-dialog-body',
	templateUrl: './dialog-body.component.html',
	styleUrls: ['./dialog-body.component.css']
})
export class DialogBodyComponent {
	showFooterButtons: boolean;
	header = 'Title';
	message = 'Are you sure?';
	confirmButtonText = 'Yes';
	cancelButtonText = 'Cancel';
	isSuccess: boolean;
	headerNumber: string;
	messagefield1:string;
	headerNumber1: string;
	messagefield2:string;
	headerNumber4: string;
	headerNumber5: string;
	messagefield5:string;
	emailTo:string;
	messagenumber: string;
	refNum:string;
	isWarning: boolean;
	extraParams: any;
	public dataSourceForAdhesivePricesTable = new MatTableDataSource();
	@ViewChild('adhesivePricesTableSort', { static: true }) public adhesivePricesTableSort: MatSort;
	public displayedColumnsForAdhesivePricesTable = ['emailId', 'selection'];
	isAllAdhesiveProductsSelected: boolean;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogRef: MatDialogRef < DialogBodyComponent >,
		private cdRef: ChangeDetectorRef ) {
		if (data) {
			this.header = data.header || this.header;
			this.message = data.message || this.message;
			this.isSuccess = data.isSuccess || this.isSuccess;
			this.isWarning = data.isWarning || this.isWarning;
			this.headerNumber = data.headerNumber;
			this.messagefield1 = data.messagefield1;
			this.headerNumber1 = data.headerNumber1;
			this.messagefield2 = data.messagefield2;
			this.headerNumber4 = data.headerNumber4;
			this.headerNumber5 = data.headerNumber5;
			this.messagefield5 = data.messagefield5;
			this.messagenumber = data.messagenumber;
			this.emailTo = data.emailTo;
			this.extraParams = data.extraParams;
			this.refNum = data.refNum;
			if (data.buttonText) {
        this.showFooterButtons = true;
				this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
				this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
			}
		}
	}

	ngOnInit() {
		this.isAllAdhesiveProductsSelected = true;
		let selectedEmailIdList = [];
		let emailIdObject = {};
		emailIdObject['emailId'] = 'shweta.kadav@lntinfotech.com';
		emailIdObject['isSelected'] = true;
		selectedEmailIdList.push(emailIdObject);

		let emailIdObject1 = {};
		emailIdObject1['emailId'] = 'sayalia.chavan@lntinfotech.com';
		emailIdObject1['isSelected'] = true;
		selectedEmailIdList.push(emailIdObject1);

		let emailIdObject2 = {};
		emailIdObject2['emailId'] = 'varad.mayekar@lntinfotech.com';
		emailIdObject2['isSelected'] = true;
		selectedEmailIdList.push(emailIdObject2);

		let emailIdObject3 = {};
		emailIdObject3['emailId'] = 'sarang.khadilkar@nynas.com';
		emailIdObject3['isSelected'] = true;
		selectedEmailIdList.push(emailIdObject3);

		let emailIdObject4 = {};
		emailIdObject4['emailId'] = 'kapil.sharma@lntinfotech.com';
		emailIdObject4['isSelected'] = true;
		selectedEmailIdList.push(emailIdObject4);

		this.dataSourceForAdhesivePricesTable.data = selectedEmailIdList;
	}
	

	ngAfterViewInit(): void {
	  this.dataSourceForAdhesivePricesTable.sort = this.adhesivePricesTableSort;
	  this.cdRef.detectChanges();
	  this.dataSourceForAdhesivePricesTable.sortingDataAccessor = (item, property) => {
		return item[property]
	  };
	}

	onConfirmClick(): void {
		this.dialogRef.close(true);
	}

	onCancelClick(): void {
		this.dialogRef.close(false);
	}

	selectAllAdhesiveProductsToSave() {
		for (let priceObject of this.dataSourceForAdhesivePricesTable.data) {
		  priceObject['isSelected'] = !this.isAllAdhesiveProductsSelected;
		}
	  }

}