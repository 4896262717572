import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryAnalysisService {

  apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getSalesReportLocation(year: string, countryCode: string, customerId: string) {
    const url = this.apiUrl + '/GetSalesReportLocation';
    return this.httpClient.get(url, {
      params: {
        Year: year,
        Country: countryCode,
        CustomerID: customerId,
      }
    });
  }

  fetchDailyAnalysisDetailsForAll(countryCode: string, customerId: string, jdeAddressNumber: string, filter: string, value1: string, value2: string) {
    let url = this.apiUrl + '/DeliveryAnalysisDataService/FetchDeliveryAnalysisDataForAdmin';
    return this.httpClient
      .get(url, {
        params: {
          CountryCode: countryCode,
          CustomerId: customerId,
          JDEAddressNumber: jdeAddressNumber,
          Filter: filter,
          Value1: value1,
          Value2: value2
        }
      });
  }

  fetchDailyAnalysisDetailsForUser(countryCode: string, startDate: string, endDate: string, userId: string, customerId: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/FetchDailyAnalysisDetails/FetchDailyAnalysisForUser';
    return this.httpClient
      .get(url, {
        params: {
          CountryCode: countryCode,
          User_ID: userId,
          StartDate: startDate,
          EndDate: endDate,
          CustomerID: customerId,
          JDE_Address_Number: jdeAddressNumber
        }
      });
  }

  fetchDailyAnalysisDetails(countryCode: string, customerId: string, jdeAddressNumber: string, filter: string, value1: string, value2: string, userId: string, showUserDataOnly: boolean) {
    if (showUserDataOnly) {
      return this.fetchDailyAnalysisDetailsForUser(countryCode, value1, value2, userId, customerId, jdeAddressNumber);
    } else {
      return this.fetchDailyAnalysisDetailsForAll(countryCode, customerId, jdeAddressNumber, filter, value1, value2);
    }
  }

  fetchDeliverySitesData(userId: string, countryCode: any, status: any, customerId: any, showUserDataOnly: boolean) {
    if (showUserDataOnly) {
      return this.getDeliverySiteListForUser(userId, countryCode, status, customerId);
    } else {
      return this.getDeliverySiteList(customerId, status, countryCode);
    }
  }

  getDeliverySiteList(customerId: string, approvalStage: string, countryCode: string) {
    let url = this.apiUrl + '/FetchDeliverySiteService';
    return this.httpClient
      .get(url, {
        params: {
          CustomerId: customerId,
          ApprovalStage: approvalStage,
          CountryCode: countryCode
        }
      });
  }

  getDeliverySiteListForUser(userId: string, countryCode: any, mappingStatus: any, customerId: any) {
    let url = this.apiUrl + '/GetDeliverySiteForUser';
    return this.httpClient
      .get(url, {
        params: {
          UserID: userId,
          CountryCode: countryCode,
          MappingStatus: "ACTIVE",  // Pass MappingStatus as ACTIVE to get delivery sites mapped to current user
          CustomerID: customerId
        }
      });
  }

}
