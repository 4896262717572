import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppRoutingModule } from '../app-routing.module';
import { AddDeliverySiteComponent } from './add-delivery-site/add-delivery-site.component';
import { AddEditDeliverySiteComponent } from './add-edit-delivery-site/add-edit-delivery-site.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { ModifyDeliverySiteComponent } from './modify-delivery-site/modify-delivery-site.component';
import { MyCompanyComponent } from './my-company.component';
import { MyCompanyService } from './my-company.service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateService} from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ApproveDeliverySiteComponent } from './approve-delivery-site/approve-delivery-site.component';
import { AddDeliverySiteSwedenComponent } from './add-delivery-site-sweden/add-delivery-site-sweden.component';
import { ModifyDeliverySiteSwedenComponent } from './modify-delivery-site-sweden/modify-delivery-site-sweden.component';
import { ApproveDeliverySiteComponentSweden } from './approve-delivery-site-sweden/approve-delivery-site-sweden.component';
import { GlobalSearchModule } from '../global-search/global-search.module';
import { UpdateDeliverySiteProductsComponent } from './update-delivery-site-products/update-delivery-site-products.component';

export function HttpLoaderFactory (http:HttpClient){
  return new  TranslateHttpLoader(http);
}

@NgModule({ 
  declarations: [MyCompanyComponent, AddDeliverySiteSwedenComponent, AddDeliverySiteComponent, CompanyDetailsComponent, ModifyDeliverySiteComponent, ApproveDeliverySiteComponent, ModifyDeliverySiteSwedenComponent, ApproveDeliverySiteComponentSweden, AddEditDeliverySiteComponent, UpdateDeliverySiteProductsComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    GlobalSearchModule,
    MatStepperModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      loader:{
        provide:TranslateLoader,
        useFactory: HttpLoaderFactory,      
        deps:[HttpClient]
      }
    })    
  ],
  exports: [MatIconModule, MatButtonModule],
  entryComponents: [ApproveDeliverySiteComponent, ApproveDeliverySiteComponentSweden, UpdateDeliverySiteProductsComponent],
  providers: [MyCompanyService, DatePipe],
})
export class MyCompanyModule { }
