<div class="container-fluid">
    <div class="row">
        <!-- <div class="col-lg-12 ">


        </div> -->

        <div class="col-lg-12 ">
            <!-- <div class="row header">
                <span> Add / Modify Price Notation</span>
            </div> -->
        </div>
    </div>

    <div class="">
        <div class="col-lg-12 no-gutters">


            <mat-table #table [dataSource]="dataSource" matSort 
            class='table-material forecastTable scroll-table-content scrollbar-table-content  '>

                <ng-container matColumnDef="date">
                    <mat-header-cell class="header-font " *matHeaderCellDef mat-sort-header> Date
                    </mat-header-cell>

                    <mat-cell *matCellDef="let fetchedDataForItemArray">
                        <!-- <mat-form-field class="date-picker" >
                                <input matInput [matDatepicker]="picker" [(ngModel)]="fetchedDataForItemArray.date">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field> -->
                        {{fetchedDataForItemArray.date  | date:'d/M/y'}}
                    </mat-cell>

                </ng-container>

                <ng-container matColumnDef="eur">
                    <mat-header-cell class="header-font " *matHeaderCellDef mat-sort-header> EUR/USD
                    </mat-header-cell>
                    <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.eur}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="edit">
                    <mat-header-cell class="header-font " *matHeaderCellDef> Edit
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class='active-icon-color'>
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <mat-header-cell class="header-font " *matHeaderCellDef>Delete
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class='active-icon-color'>
                            <i class="fas fa-trash" aria-hidden="true"></i>
                        </span>
                    </mat-cell>
                </ng-container>

                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsExchangeRate; sticky:true">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsExchangeRate;"></mat-row>

            </mat-table>
            <mat-paginator   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"   showFirstLastButtons></mat-paginator>

            <div class="row buttonGroup">

                <button class="saveButton" type="submit" (click)="saveUserDetails()">New Entry</button>
                <button class="cancelButton" id="submit" name="submit" (click)="cancelUserDetails()">Save</button>
            </div>

        </div>


    </div>