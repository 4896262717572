<div class="container-fluid">
    <div class="row">
        <div class="col-2">
            <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                #group="matButtonToggleGroup" [(ngModel)]="priceValue" value="product-price">
                <mat-button-toggle value="product-price" ngDefaultControl (click)="toggleButtonClick()">
                    {{'HOME.Productprice'| translate}}
                </mat-button-toggle>
                <mat-button-toggle value="adhesive-price" ngDefaultControl (click)="toggleButtonClick()">
                    {{'HOME.Adhesiveprice'| translate}}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <div class="row  cust_div  no-gutters">
        <div class=" cust_label  ">
            <label for="CustomerId">
                <span class="input-text-header"> {{'HOME.Customername'| translate}}
                </span>
            </label>
        </div>
        <div class=" cust_drop ">
            <select class="input-text addProductPriceSelect customer-dropdown-width cust_drop_mv" id="CustomerId"
                [(ngModel)]='customerIdUserSelected'>
                <option *ngFor="let customer of Customers" [ngValue]="customer.CustomerID">
                    {{customer.CustomerName}}
                </option>
            </select>
        </div>
        <!-- <div class="month_div_mv"> -->
            <label for="month" class="month_label month_label_mv">
                <span class="input-text-header"> {{'HOME.Month'| translate}}
                </span>
            </label>
        <!-- </div> -->
        <div class="month_drop  ">
            <!-- <select class="input-text addProductPriceSelect month-dropdown-width " id="Month"
                [(ngModel)]='monthUserSelected'>
                <option value="01"> {{'HOME.JANUARY'| translate}} </option>
                <option value="02"> {{'HOME.FEBRUARY'| translate}} </option>
                <option value="03"> {{'HOME.MARCH'| translate}} </option>
                <option value="04"> {{'HOME.APRIL'| translate}} </option>
                <option value="05"> {{'HOME.MAY'| translate}} </option>
                <option value="06"> {{'HOME.JUNE'| translate}} </option>
                <option value="07"> {{'HOME.JULY'| translate}} </option>
                <option value="08"> {{'HOME.AUGUST'| translate}} </option>
                <option value="09"> {{'HOME.SEPTEMBER'| translate}} </option>
                <option value="10"> {{'HOME.OCTOBER'| translate}} </option>
                <option value="11"> {{'HOME.NOVEMBER'| translate}} </option>
                <option value="12"> {{'HOME.DECEMBER'| translate}} </option>
            </select> -->

            <select class="input-text addProductPriceSelect month-dropdown-width " id="Month"
                [(ngModel)]='monthUserSelected'>
                <option value="01"> {{'HOME.JAN'| translate}} </option>
                <option value="02"> {{'HOME.FEB'| translate}} </option>
                <option value="03"> {{'HOME.MAR'| translate}} </option>
                <option value="04"> {{'HOME.APR'| translate}} </option>
                <option value="05"> {{'HOME.MAY'| translate}} </option>
                <option value="06"> {{'HOME.JUN'| translate}} </option>
                <option value="07"> {{'HOME.JUL'| translate}} </option>
                <option value="08"> {{'HOME.AUG'| translate}} </option>
                <option value="09"> {{'HOME.SEP'| translate}} </option>
                <option value="10"> {{'HOME.OCT'| translate}} </option>
                <option value="11"> {{'HOME.NOV'| translate}} </option>
                <option value="12"> {{'HOME.DEC'| translate}} </option>
            </select>

        </div>
        <div class=" year_label ">
            <label for="year">
                <span class="input-text-header"> {{'HOME.Year'| translate}}
                </span>
            </label>
        </div>
        <div class="year_drop">
            <select class="input-text addProductPriceSelect year-dropdown-width year_drop_mv" id="Year"
                [(ngModel)]='yearUserSelected'>
                <option *ngFor="let year of yearList" [value]="year">
                    {{year}}
                </option>
            </select>
        </div>
        <div class="find_btn" [hidden]='priceValue !== "product-price"'>
            <button class="findButton findbtn_mv" (click)="findProductPrices()"> {{'HOME.Getprice'| translate}}
            </button>
        </div>
        <div class="find_btn" [hidden]='priceValue !== "adhesive-price"'>
            <button class="findButton findbtn_mv" (click)="findAdhesiveProductPrices()"> {{'HOME.Getprice'| translate}}
            </button>
        </div>
    </div>
    <div class="row pt-3 pl-3">
        <div [hidden]='priceValue !== "product-price"'>
            <!-- <div class="row pt-3 pl-3"> -->
                <!-- <div class="col-12"> -->
                    <div [hidden]="(!isRequestCompleted || (dataSourceForPricesTable.data && dataSourceForPricesTable.data.length))"
                        class="no-records-message pt-3 pl-2"> {{'HOME.Norecordsfound'| translate}}
                    </div>
                    <div [hidden]="(!isRequestCompleted || (!dataSourceForPricesTable.data || (dataSourceForPricesTable.data && !dataSourceForPricesTable.data.length)))"
                        class="price-index-daily-table">
                        <div class="horizontal_scrollbar_price_div_mv">
                            <mat-table #firstTable #pricesTableSort="matSort"
                                [dataSource]="dataSourceForPricesTable"
                                class=" horizontal_scrollbar price-table"
                                [ngClass]="{'price-table-fi': (selectedLanguage === 'fi'), 'price-table': (selectedLanguage !== 'fi'),'price-table-se': (selectedLanguage === 'se')}"
                                matSort>
                                <ng-container matColumnDef="depotName">
                                    <mat-header-cell class="header-font mat-column-large-price" *matHeaderCellDef
                                        mat-sort-header>
                                        {{'HOME.depotname'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large-price" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.depotName}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="productName">
                                    <mat-header-cell class="header-font mat-column-large-price" *matHeaderCellDef
                                        mat-sort-header>
                                        {{'HOME.ProductName'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large-price" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.productName}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="price">
                                    <mat-header-cell class="header-font mat-column-large-price" *matHeaderCellDef
                                        mat-sort-header>
                                        {{'HOME.Productprice'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large-price" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.price | currency: 'EUR':'symbol':undefined:'fr-FR'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="uom">
                                    <mat-header-cell class="header-font mat-column-large-price" *matHeaderCellDef
                                        mat-sort-header>
                                        {{'HOME.UOM'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large-price" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.uom}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="selection">
                                    <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef>
                                        <span>
                                            <input class="checkbox-add-remove" type="checkbox"
                                                [(ngModel)]="isAllProductsSelected" (click)="selectAllProductsToSave()"
                                                title="{{'HOME.Selecttosaveallproducts'| translate}}">
                                        </span>
                                    </mat-header-cell>
                                    <mat-header-cell *matHeaderCellDef
                                        [ngClass]="'mat-column-medium mat-column-actions'">
                                    </mat-header-cell>>
                                    <mat-cell class="mat-column-medium" *matCellDef="let element">
                                        <span>
                                            <input class="checkbox-add-remove" type="checkbox"
                                                [(ngModel)]="element.isSelected"
                                                title="{{'HOME.Selecttosaveproduct'| translate}}">
                                        </span>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row class="table-header"
                                    *matHeaderRowDef="displayedColumnsForPricesTable; sticky:true">
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsForPricesTable;"></mat-row>
                            </mat-table>
                        </div>
                    </div>
                <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>
    <div class="row">
        <div [hidden]='priceValue !== "adhesive-price"'>
            <div class="row pt-3 pl-3">
                <div class="col-12">
                    <div [hidden]="(!isRequestCompleted || (dataSourceForAdhesivePricesTable.data && dataSourceForAdhesivePricesTable.data.length))"
                        class="no-records-message pt-3 pl-2"> {{'HOME.Norecordsfound'| translate}}
                    </div>
                    <div [hidden]="(!isRequestCompleted || (!dataSourceForAdhesivePricesTable.data || (dataSourceForAdhesivePricesTable.data && !dataSourceForAdhesivePricesTable.data.length)))"
                        class="price-index-daily-table">
                        <div class="horizontal_scrollbar_adhesive_div_mv">
                            <table mat-table #firstTable #adhesivePricesTableSort="matSort"
                                [dataSource]="dataSourceForAdhesivePricesTable" 
                                class=" horizontal_scrollbar adhesive_price"
                                [ngClass]="{'adhesive-price-table-fi': (selectedLanguage === 'fi'), 'adhesive-price-table': (selectedLanguage !== 'fi')}"
                                matSort>
                                <ng-container matColumnDef="depotName">
                                    <mat-header-cell class="header-font mat-column-large-adhesive-price"
                                        *matHeaderCellDef mat-sort-header>
                                        {{'HOME.depotname'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large-adhesive-price"
                                        *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.depotName}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="productName">
                                    <mat-header-cell class="header-font mat-column-large-adhesive-price"
                                        *matHeaderCellDef mat-sort-header>
                                        {{'HOME.ProductName'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large-adhesive-price"
                                        *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.productName}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="price">
                                    <mat-header-cell class="header-font mat-column-large-adhesive-price"
                                        *matHeaderCellDef mat-sort-header>
                                        {{'HOME.Productprice'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large-adhesive-price"
                                        *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.price | currency: 'EUR':'symbol':undefined:'fr-FR'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="adhesivePercent">
                                    <mat-header-cell class="header-font mat-column-large-adhesive-price"
                                        *matHeaderCellDef mat-sort-header>
                                        {{'HOME.adhesivePercent'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large-adhesive-price"
                                        *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.adhesivePercent}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="uom">
                                    <mat-header-cell class="header-font mat-column-large-adhesive-price"
                                        *matHeaderCellDef mat-sort-header>
                                        {{'HOME.UOM'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large-adhesive-price"
                                        *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.uom}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="selection">
                                    <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef>
                                        <span>
                                            <input class="checkbox-add-remove" type="checkbox"
                                                [(ngModel)]="isAllAdhesiveProductsSelected"
                                                (click)="selectAllAdhesiveProductsToSave()"
                                                title="{{'HOME.Selecttosaveallproducts'| translate}}">
                                        </span>
                                    </mat-header-cell>
                                    <th mat-header-cell *matHeaderCellDef
                                        [ngClass]="'mat-column-medium mat-column-actions'">
                                    </th>
                                    <mat-cell class="mat-column-medium" *matCellDef="let element">
                                        <span>
                                            <input class="checkbox-add-remove" type="checkbox"
                                                [(ngModel)]="element.isSelected"
                                                title="{{'HOME.Selecttosaveproduct'| translate}}">
                                        </span>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row class="table-header"
                                    *matHeaderRowDef="displayedColumnsForAdhesivePricesTable; sticky:true">
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsForAdhesivePricesTable;">
                                </mat-row>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-5"
        [hidden]="(priceValue !== 'product-price') || (!isRequestCompleted || (!dataSourceForPricesTable.data || (dataSourceForPricesTable.data && !dataSourceForPricesTable.data.length)))">
        <div class="col-sm">
            <button id="btn-save" name="submit" (click)="savePriceData()"
                class="btn btn-primary saveBtn action-button no-gutters">{{'HOME.Save'| translate}}</button>
        </div>
        <div class="col-sm">
            <button id="btn-cancel" name="submit" class="btn btn-primary cancelBtn action-button"
                (click)="resetPriceData()">{{'HOME.Reset'| translate}}</button>
        </div>
    </div>
    <div class="row pt-5 "
        [hidden]="(priceValue !== 'adhesive-price') || (!isRequestCompleted || (!dataSourceForAdhesivePricesTable.data || (dataSourceForAdhesivePricesTable.data && !dataSourceForAdhesivePricesTable.data.length)))">
        <div class="col-sm ">
            <button id="btn-save" name="submit" (click)="saveAdhesivePriceData()"
                class="btn btn-primary saveBtn action-button no-gutters">{{'HOME.Save'| translate}}</button>
        </div>
        <div class="col-sm ">
            <button id="btn-cancel" name="submit" class="btn btn-primary cancelBtn action-button"
                (click)="resetAdhesivePriceData()">{{'HOME.Reset'| translate}}</button>
        </div>
    </div>
</div>