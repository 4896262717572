import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class MyCompanyService {

  private selectedItemSource = new BehaviorSubject('My company');
  currentSelectedItem = this.selectedItemSource.asObservable();
  private subject = new Subject<any>();
  private subjectSweden = new Subject<any>();

  constructor() { }

  updateSelectedItem(selectedItem: string) {
    this.selectedItemSource.next(selectedItem)
  }

  sendFetchDeliverySitesEvent() {
    this.subject.next();
  }
  getFetchDeliverySitesEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  sendFetchDeliverySitesSwedenEvent() {
    this.subjectSweden.next();
  }
  getFetchDeliverySitesSwedenEvent(): Observable<any> {
    return this.subjectSweden.asObservable();
  }

}