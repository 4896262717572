<div class="">

    <div class="container">

        <div class="row">


            <!-- <div class="col-12 companyDetailsHeader">{{'HOME.Companydetails'| translate}} </div> -->

            <!-- <div class="col-4 companyDetailsTile">{{'HOME.CompanyName'| translate}} -->
                <div class="col-12 companyDetailsTile ">{{companyName}}</div>
            <!-- </div> -->
            <!-- <div class="col-4 companyDetailsTile">{{'HOME.Primarycontact'| translate}}
                <div class="col-12 companyDetailsLabel">{{'HOME.Name'| translate}} : <span
                        class="companyDetails">{{contactName}}</span></div>
                <div class="col-12 companyDetailsLabel">{{'HOME.Email'| translate}}: <span
                        class="companyDetails">{{contactEmail}}</span>
                </div>
            </div> -->
                <!-- <div class="col-12 companyDetailsLabel">{{'HOME.Contact'| translate}}: <span
                        class="companyDetails">{{contactPhone}}</span>
                </div> -->
            

            <!-- <div class="col-4 companyDetailsTile">{{'HOME.Billingaddress'| translate}}
                <div class="col-12 companyDetailsLabel">{{'HOME.Line1'| translate}} : <span
                        class="companyDetails">{{AddrLine1}}</span></div>
                <div class="col-12 companyDetailsLabel">{{'HOME.Line2'| translate}}: <span
                        class="companyDetails">{{AddrLine2}}</span></div>
                <div class="col-12 companyDetailsLabel">{{'HOME.City'| translate}}: <span
                        class="companyDetails">{{AddrCity}}</span></div>
                <div class="col-12 companyDetailsLabel">{{'HOME.PINCode'| translate}}: <span
                        class="companyDetails">{{AddrPin}}</span></div>
                <div class="col-12 companyDetailsLabel ">{{'HOME.Country'| translate}}: <span
                        class="companyDetails">{{AddrCountry}}</span>
                </div>
            </div> -->

            <div class="col-10 companyDetailsHeader">{{'HOME.Users'| translate}} </div>
            <div class="col-2 companyDetailsHeader">
                <!-- <a *ngIf= "this.userInfo.RoleName == 'Portal Admin'" routerLink="/user-management/add-user" class="companyDetailsButton">Add &nbsp;</a>
                    <a *ngIf= "this.userInfo.RoleName == 'Portal Admin'" routerLink="/user-management/add-user" class="companyDetailsButton">Modify</a> -->
            </div>
            <div class="col-12 mt-2" [hidden]="displayUserTable" style=" font-size: 12px;">
                {{'HOME.Norecordsfound'| translate}}
            </div>
            <div class="col-12 mt-2 userTable horizontal_scrollbar1" [hidden]='!displayUserTable'>
                <!--Angular 7 Impl-->
                <mat-table #firstTable #firstTableSort="matSort" [dataSource]="myFirstTableData" 
                class="userTable horizontal_scrollbar"
                    matSort>
                    <!-- datasource -->

                    <!-- User Column -->
                    <!-- <ng-container matColumnDef="User">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> User
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.FirstName}}
                        </mat-cell>
                    </ng-container> -->
                    <ng-container matColumnDef="FirstName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-font"
                            [ngClass]="'mat-table-column-header mat_column_user mat-table-column-header-text'">
                            {{'HOME.User'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat-column-name mat-table-column-row-text'">
                            {{fetchedDataForItemArray.FirstName}} {{fetchedDataForItemArray.LastName}}
                        </td>
                    </ng-container>

                    <!-- Email Column -->
                    <!-- <ng-container matColumnDef="Email">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Email
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.EmailID}}
                        </mat-cell>
                    </ng-container> -->

                    <ng-container matColumnDef="EmailID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-font"
                            [ngClass]="'mat-table-column-header mat_column_email mat-table-column-header-text'">
                            {{'HOME.Email'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat_column_email mat-table-column-row-text'">
                            {{fetchedDataForItemArray.EmailID}}
                        </td>
                    </ng-container>



                    <!-- Contact Column -->
                    <!-- <ng-container matColumnDef="Contact">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Contact
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.PrimaryContact}}
                        </mat-cell>
                    </ng-container> -->

                    <ng-container matColumnDef="PrimaryContact">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-font"
                            [ngClass]="'mat-table-column-header mat_column_Contact mat-table-column-header-text'">
                           &nbsp; {{'HOME.Contact'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat_column_Contact mat-table-column-row-text'">
                            {{fetchedDataForItemArray.PrimaryContact}}
                        </td>
                    </ng-container>

                    <!-- Role Column -->
                    <!-- <ng-container matColumnDef="Role">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Role
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.Role}}
                        </mat-cell>
                    </ng-container> -->

                    <ng-container matColumnDef="Role">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-font"
                            [ngClass]="'mat-table-column-header mat_column_Role mat-table-column-header-text'">
                            {{'HOME.Role'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat_column_Role mat-table-column-row-text'">
                            {{fetchedDataForItemArray.Role}}
                        </td>
                    </ng-container>


                    <!-- Group Column -->
                    <!-- <ng-container matColumnDef="Group">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Group
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.UserGroupName}}
                        </mat-cell>
                    </ng-container> -->

                    <ng-container matColumnDef="UserGroupName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-font"
                            [ngClass]="'mat-table-column-header mat_column_Group mat-table-column-header-text'">
                            {{'HOME.Group'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat_column_Group mat-table-column-row-text'">
                            {{fetchedDataForItemArray.UserGroupName}}
                        </td>
                    </ng-container>

                    <!-- Role Column -->
                    <!-- <ng-container matColumnDef="Status">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Status
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.UserStatus}}
                        </mat-cell>
                    </ng-container> -->

                    <ng-container matColumnDef="UserStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-font"
                            [ngClass]="'mat-table-column-header mat_column_Statuss mat-table-column-header-text'">
                            {{'HOME.Status'| translate}}

                        </th>
                        <td mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat_column_Statuss mat-table-column-row-text'">
                            {{fetchedDataForItemArray.UserStatus}}
                        </td>
                    </ng-container>


                    <!-- Role Column -->
                    <!-- <ng-container matColumnDef="LastLoggedIn">
                                    <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Last logged
                                        in
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.LastLoggedIn}}
                                    </mat-cell>
                                </ng-container> -->



                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <mat-paginator #TableOnePaginator="matPaginator" showFirstLastButtons   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"  >

                </mat-paginator>
                <!-- showFirstLastButtons -->


            </div>


            <div class="col-10 companyDetailsHeader">{{'HOME.Deliverysites'| translate}} </div>
            <div *ngIf="isLoggedInUserAdmin" class="col-2 companyDetailsHeader">
                <!-- <a routerLink="/my-company/add-delivery-site" class="companyDetailsButton">Add &nbsp;</a>
                <a routerLink="/my-company/modify-delivery-site" class="companyDetailsButton">Modify</a> -->
            </div>
            <div class="col-12 mt-2" [hidden]="displayDelTable" style="font-size: 12px;">
                {{'HOME.Norecordsfound'| translate}}
            </div>
            <div class="col-12 mt-2 userTable horizontal_scrollbar1" [hidden]='!displayDelTable'>
                <!--Angular 7 Impl-->
                <mat-table #secondTable #secondTableSort="matSort" [dataSource]="mySecondTableData"
                    class="userTable horizontal_scrollbar" matSort>
                    <!-- [dataSource]="dataSourceDelSite"  -->


                    <!-- DeliveryNumber Column -->
                    <!-- <ng-container matColumnDef="DeliverySiteName">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Delivery
                            Site Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.DeliverySiteName}}
                        </mat-cell>
                    </ng-container> -->

                    <ng-container matColumnDef="DeliverySite">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-font"
                            [ngClass]="'mat-table-column-header mat_column_Status mat-table-column-header-text'">
                            {{'HOME.Deliverysite'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat_column_Status mat-table-column-row-text'">
                            {{fetchedDataForItemArray.DeliverySite}}
                        </td>
                    </ng-container>

                    <!-- DeliveryProductName Column -->
                    <!-- <ng-container matColumnDef="DeliveryProductName">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Delivery
                            product name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.DeliveryProductName}}
                        </mat-cell>
                    </ng-container> -->

                    <!-- Status Column -->
                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-font"
                            [ngClass]="'mat-table-column-header mat_column_Status mat-table-column-header-text'">  {{'HOME.Status'| translate}}

                        </th>
                        <td mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat_column_Status mat-table-column-row-text'">
                            {{fetchedDataForItemArray.Status}}
                        </td>
                    </ng-container>

                    <!-- BillingReference Column -->
                    <!-- <ng-container matColumnDef="BillingReference">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Billing
                            reference
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.DeliveryBillRef}}
                        </mat-cell>
                    </ng-container> -->

                    <ng-container matColumnDef="DeliveryBillRef">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-font"
                            [ngClass]="'mat-table-column-header mat_column_Status mat-table-column-header-text'">
                            {{'HOME.BillingReference'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat_column_Status mat-table-column-row-text'">
                            {{fetchedDataForItemArray.BillingReference}}
                        </td>
                    </ng-container>

                    <!-- DeliveryPostalCode Column -->
                    <!-- <ng-container matColumnDef="DeliveryPostalCode">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Delivery
                            postal code
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.DeliveryPostalCode}}
                        </mat-cell>
                    </ng-container> -->

                    <!-- DeliveryPostOffice Column -->
                    <!-- <ng-container matColumnDef="DeliveryPostOffice">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header>Delivery post
                            office
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.DeliveryPostOffice}}
                        </mat-cell>
                    </ng-container> -->

                    <!-- SubscriberEmailAddress Column -->
                    <!-- <ng-container matColumnDef="SubscriberEmailAddress">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Subscriber
                            email address
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.SubscriberEmail}}
                        </mat-cell>
                    </ng-container> -->

                    <!-- CarrierEmailAddress Column -->
                    <!-- <ng-container matColumnDef="CarrierEmailAddress">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Carrier
                            email address
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.CarrierEmail}}
                        </mat-cell>
                    </ng-container> -->

                    <!-- Contact Column -->
                    <ng-container matColumnDef="DS_PrimaryContactNo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-font"
                            [ngClass]="'mat-table-column-header mat_column_Status mat-table-column-header-text'">
                            {{'HOME.Primarycontact'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat_column_Status mat-table-column-row-text'">
                            {{fetchedDataForItemArray.PrimaryContact}}
                        </td>
                    </ng-container>

                    <!-- Address Column -->
                    <!-- <ng-container matColumnDef="Address">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Address
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.DeliveryAddress}}

                            
                        </mat-cell>
                    </ng-container> -->
                    <!-- <span class='active-icon-color'><i class="fa fa-pencil" aria-hidden="true"></i></span> -->

                    <ng-container matColumnDef="DeliveryAddress">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="header-font"
                            [ngClass]="'mat-table-column-header mat_column_Status mat-table-column-header-text'">
                            {{'HOME.Address'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="'mat_column_Status mat-table-column-row-text'">
                            {{fetchedDataForItemArray.AddressLine1}}
                        </td>
                    </ng-container>


                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsDelSite; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsDelSite;"></mat-row>
                </mat-table>
                <mat-paginator #TableTwoPaginator="matPaginator" showFirstLastButtons   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"  >
                </mat-paginator>
                <!-- showFirstLastButtons -->




            </div>

        </div>
    </div>

</div>



<!-- </div>
</div>

</div> -->