import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../user-management/user';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdatePasswordService {

  apiUrl = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private httpClient: HttpClient) { }

  updatePassword(user: any): Observable<User> {
    let url = this.apiUrl + '/updatePassword';
    return this.httpClient.put<User>(url, JSON.stringify(user), this.httpOptions).pipe();
  }

}