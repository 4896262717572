<div class="container-fluid">
    <div class="row">

        <!-- <div class="col-lg-2 col-md-2">

            <div class="sidenav">
                <div>
                    <img class="menu-logo" src="../../../assets/nynas_logo.png">
                </div>
                <div class="sidenavbar">
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Home'}"
                        (click)="showSelectedMenuItem('Home')">
                        <a routerLink="/dashboard" class="menu-list-names text-left">
                            <i class="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Home
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='My Company'}"
                        (click)="showSelectedMenuItem('My Company')">
                        <a routerLink="/my-company" class="menu-list-names text-left">
                            <i class="fa fa-building" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;My company
                        </a>
                    </div>

                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Ordering procedure'}">
                        <a (click)="showSelectedMenuItem('Ordering procedure')"
                            routerLink="/order-management/ordering-procedure" class="menu-list-names text-left">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Ordering
                            procedure
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Delivery guide'}"
                        (click)="showSelectedMenuItem('Delivery guide')">
                        <a routerLink="/product-management/delivery-guide" class="menu-list-names text-left">
                            <i class="fas fa-truck" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Delivery guide
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Nynas updates'}"
                        (click)="showSelectedMenuItem('Nynas updates')">
                        <a routerLink="/nynas-updates" class="menu-list-names text-left">
                            <i class="fa fa-envelope" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Nynas updates
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Contact Us'}"
                        (click)="showSelectedMenuItem('Contact Us')">
                        <a routerLink="/contactus" class="menu-list-names text-left">
                            <i class="fas fa-comment-alt" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Contact us
                        </a>
                    </div>
                    <div *ngIf="isLoggedInUserAdmin" class="menu-item-padding"
                        [ngClass]="{'selected-class': selectedItem ==='User management'}"
                        (click)="showSelectedMenuItem('User management')">
                        <a routerLink="/user-management/manage-users" class="menu-list-names text-left">
                            <i class="fas fa-user" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;User management
                        </a>
                    </div>


                    <div *ngIf="isLoggedInUserAdmin && expandConfigurationFlag" class="menu-item-padding"
                        (click)="expandConfiguration(false)">
                        <a class="menu-list-names text-left">
                            <i class="fas fa-cog" aria-hidden="true"></i>
                            <span>&nbsp;&nbsp;&nbsp;Configuration
                                <i class="fa fa-caret-up"></i>
                            </span>
                        </a>
                        <div *ngIf="isLoggedInUserAdmin" class="menu-item-padding1"
                            [ngClass]="{'selected-class': selectedItem ==='User management'}"
                            (click)="showSelectedMenuItem('Price notation')">
                            <a routerLink="/price-management/exchange-rate" class="menu-list-names text-left">
                                &nbsp;&nbsp;&nbsp;Price Notation
                            </a>
                        </div>
                        <div *ngIf="isLoggedInUserAdmin" class="menu-item-padding1"
                            [ngClass]="{'selected-class': selectedItem ==='User management'}"
                            (click)="showSelectedMenuItem(' Delivery Guide')">
                            <a routerLink="/delivery-guide-admin" class="menu-list-names text-left sub-item-padding">
                                &nbsp;&nbsp;&nbsp;Delivery guide
                            </a>
                        </div>
                        <div *ngIf="isLoggedInUserAdmin" class="menu-item-padding1"
                            [ngClass]="{'selected-class': selectedItem ==='User management'}"
                            (click)="showSelectedMenuItem(' Customer Contract')">
                            <a routerLink="/customer-contract-admin" class="menu-list-names text-left sub-item-padding">
                                &nbsp;&nbsp;&nbsp;Customer Contract
                            </a>
                        </div>

                    </div>
                    <div *ngIf="isLoggedInUserAdmin && !expandConfigurationFlag" class="menu-item-padding"
                        (click)="expandConfiguration(true)">
                        <a class="menu-list-names text-left">
                            <i class="fas fa-cog" aria-hidden="true"></i>
                            <span>&nbsp;&nbsp;&nbsp;Configuration
                                <i class="fa fa-caret-down"></i>
                            </span>
                        </a>

                    </div>

                </div>

            </div>
        </div> -->

        <div class="col-lg-12 col-md-12">
            <!-- <div class="row header">
                <span> Customer Contract</span>
            </div> -->

            <div class="row" style="padding-top: 1%;">

                <!-- <div class="col-1" style="padding: 0px;">
                    <label class="monthlyLabel" for="countryType"> {{'HOME.Country'| translate}}
                    </label>
                </div>
                <div class="col-auto">
                    <mat-form-field appearance="outline">
                        <mat-select disableOptionCentering [(value)]="countrySearch" panelClass="select-card">
                            <mat-select-trigger>
                                <span
                                    class="flag-icon flag-icon-{{countrySearch | lowercase}} flag-size">
                                </span>{{countryList[countrySearch]}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let country of countryList | keyvalue" [value]="country.key">
                                <span
                                    class="flag-icon flag-icon-{{ country.key | lowercase}} flag-size">
                                </span>{{country.value}}
                            </mat-option>
                        </mat-select>
                        <div> </div>
                    </mat-form-field>
                </div> -->

                <!-- <div class="col-1" style="padding: 0px;">
                    <label class="monthlyLabel">Start date</label>
                </div>
                <div class="col-1" style="padding: 8px;">
                    <form [formGroup]="customerContractForm">
                        <select formControlName="startYear" class="forecastSelect form-control"
                            [ngClass]="{ 'is-invalid': submitted && fields.startYear.errors }"
                            (change)="changeOnClick()" [value]="this.startYearUserSelected">
                            <option *ngFor="let startYear of dropDownYear">{{startYear}}</option>

                            <div *ngIf="submitted && fields.startYear.errors" class="invalid-feedback">
                                <div *ngIf="fields.startYear.errors">Enter year</div>
                            </div>
                        </select>
                    </form>
                </div> -->
                <!-- <div class="col-auto">
                    <form [formGroup]="customerContractForm">
                        <div class="form-group">
                            <label for="startDate" style="margin-bottom:0px">
                                <span class="monthlyLabel">Start date</span>
                            </label>


                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" placeholder="" formControlName="startDate">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                            <div *ngIf="submitted && fields.startDate.errors" class="invalid-feedback">
                                <div *ngIf="fields.startDate.errors.required">Start date is
                                    required
                                </div>
                            </div>
                        </div>
                    </form>
                </div> -->
                <!-- <div class="col-auto">
                    <form [formGroup]="customerContractForm">
                        <div class="form-group">
                            <label for="endDate" style="margin-bottom:0px">
                                <span class="monthlyLabel">End date</span>
                        
                            </label>

                            <mat-form-field>
                                <input matInput [matDatepicker]="picker1" placeholder="" formControlName="endDate">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>

                            <div *ngIf="submitted && fields.endDate.errors" class="invalid-feedback">
                                <div *ngIf="fields.endDate.errors.required">End date is
                                    required
                                </div>
                            </div>
                        </div>

                    </form>
                </div> -->
                <!-- <div class="col-1" style="padding: 0px;">
                    <label class="monthlyLabel">End date</label>
                </div>
                <div class="col-1" style="padding: 8px;">
                    <form [formGroup]="customerContractForm">
                        <select formControlName="endYear" class="forecastSelect form-control"
                            [ngClass]="{ 'is-invalid': submitted && fields.endYear.errors }" (change)="changeOnClick()"
                            [value]="this.endYearUserSelected">
                            <option *ngFor="let endYear of dropDownYear">{{endYear}}</option>

                            <div *ngIf="submitted && fields.endYear.errors" class="invalid-feedback">
                                <div *ngIf="fields.endYear.errors">End year</div>
                            </div>
                        </select>
                    </form>
                </div> -->

                <!-- <div class="col-auto" style="padding-top: 8px;">
                    <div class="row" style="float: right">
                        <button class="goButton" type="submit" (click)="fetchOnClick()"> {{'HOME.Find'| translate}}
                        </button>
                    </div>
                </div> -->

            </div>
            <!-- <mat-list>
                <mat-list-item *ngFor="let customer of customers">
                    <h3 matLine> {{customer}} </h3>
                </mat-list-item>
            </mat-list> -->
            <div class="row" style="display: flex;justify-content: center;">
                <div class="col-1"></div>
                <div class="col-3 customertable">
                    <div class="tableLabel"> {{'HOME.Customers'| translate}}
                    </div>
                    <div class="boxDisplay scroll-table-content scrollbar-table-content">
                        <mat-list [hidden]="!displayCustomers">
                            <mat-list-item *ngFor="let customer of customers"
                                [ngClass]="{'is-active': selectedCustomer == customer.CustomerName}"
                                (click)=checkCustomer(customer)> {{ customer.CustomerName }}
                            </mat-list-item>
                        </mat-list>
                        <div class="mt-3" [hidden]="displayCustomers" style="display: flex; justify-content: center">
                            {{'HOME.Norecordsfound'| translate}}

                        </div>
                    </div>

                </div>
                <div class="col-3 depottable">
                    <div class="tableLabel">Depots</div>
                    <div class="boxDisplay scroll-table-content scrollbar-table-content">
                        <mat-list [hidden]="!displayDepots">
                            <mat-list-item *ngFor="let depot of depots"
                                [ngClass]="{'is-active': selectedDepot == depot.DepotName}" (click)=checkDepot(depot) >
                                {{ depot.DepotName }}
                            </mat-list-item>
                        </mat-list>
                        <div class="mt-3" [hidden]="displayDepots" style="display: flex; justify-content: center">
                            {{'HOME.Norecordsfound'| translate}}
                        </div>
                    </div>
                </div>
                <div class="col-3 producttable">
                    <div class="tableLabel">  {{'HOME.Product'| translate}}</div>
                    <div class="boxDisplay scroll-table-content scrollbar-table-content">
                        <mat-list [hidden]="!displayProducts">
                            <mat-list-item *ngFor="let product of products">
                                {{ product.ProductName }}
                                <span class="mat-table-action-icons-plus plus_icon_align">
                                    <i *ngIf="product.mapped == undefined" (click)="productGreen(product)" [ngClass]="{'yellow': product.isSelected}"
                                        class="fa fa-plus-circle fa-lg green" aria-hidden="true" data-toggle="tooltip"
                                        title="Add">
                                    </i>
                                    <i *ngIf="product.mapped == true"
                                        class="fa fa-plus-circle fa-lg yellow" aria-hidden="true">
                                    </i>
                                </span>
                            </mat-list-item>
                        </mat-list>
                        <!-- <mat-selection-list #list [hidden]="!displayProducts">
                            <mat-list-option *ngFor="let product of products"
                                [ngClass]="{'is-active': selectedProduct == product.ProductName}"
                                [selected]="this.product.mapped" (click)=checkProduct(product,list)
                                [value]="product.ProductID">
                                {{ product.ProductName }}
                            </mat-list-option>
                        </mat-selection-list> -->

                        <div class="mt-3" [hidden]="displayProducts" style="display: flex; justify-content: center">
                            {{'HOME.Norecordsfound'| translate}}
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
            </div>


            <div class="row buttonGroup">
                <button class="saveButton" type="submit" (click)="updateCustomerContract()">  {{'HOME.Add'| translate}}</button>
                <button class="saveButton" type="submit" (click)="reset('reset')">  {{'HOME.Reset'| translate}}</button>
            </div>   

            <div class="col-12 tableLabel"> {{'HOME.Existingmappingfor'| translate}}
                {{selectedCustomer}}</div>

            <div class="global-search-text-box">
                <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
            </div>

            <div class="mt-3" [hidden]="displayTable" style="display: flex; justify-content: center; font-size: large;">
                {{'HOME.Norecordsfound'| translate}}

            </div>
            <div class="">
                <div class="col-lg-12 no-gutters" [hidden]='!displayTable'>
                    <mat-table #table [dataSource]="dataSource" matSort class="horizontal_scrollbar table-material">
                        <ng-container matColumnDef="CustomerName">
                            <mat-header-cell class="header-font mat-column-name" *matHeaderCellDef mat-sort-header>  {{'HOME.Customername'| translate}}

                            </mat-header-cell>
                            <mat-cell  *matCellDef="let fetchedDataForItemArray" class="mat-column-name">{{fetchedDataForItemArray.CustomerName}} 
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="DepotName">
                            <mat-header-cell class="header-font mat-column-depot" *matHeaderCellDef mat-sort-header>  {{'HOME.Depot'| translate}}

                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-depot">{{fetchedDataForItemArray.DepotName}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="ProductName">
                            <mat-header-cell class="header-font mat-column-product" *matHeaderCellDef mat-sort-header>  {{'HOME.Product'| translate}}

                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-product">{{fetchedDataForItemArray.ProductName}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Delete">
                            <mat-header-cell class="header-font mat-column-delete1" *matHeaderCellDef>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="mat-column-delete">
                                <span class='active-icon-color' (click)='deleteMapping(element)'>
                                    <i class="fas fa-trash" aria-hidden="true"></i>
                                </span>
                            </mat-cell>
                        </ng-container>





                        <!-- <ng-container matColumnDef="StartDate">
                            <mat-header-cell class="header-font " *matHeaderCellDef mat-sort-header> Start date
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.StartDate}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="EndDate">
                            <mat-header-cell class="header-font " *matHeaderCellDef mat-sort-header> End date
                            </mat-header-cell>
                            <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.EndDate}}
                            </mat-cell>
                        </ng-container> -->

                        <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                        </mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                    </mat-table>
                    <mat-paginator [length]="50" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"  showFirstLastButtons>
                    </mat-paginator>
                </div>


            </div>
        </div>

    </div>
</div>