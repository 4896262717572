export class MonthlyForecast {
    ItemName: string;
    DepotName: string;
    Jan: string;
    Feb: string;
    Mar: string;
    Apr: string;
    May: string;
    Jun: string;
    Jul: string;
    Aug: string;
    Sep: string;
    Oct: string;
    Nov: string;
    Dec: string;
    id?: number;  
}

export class CustomerName {
    name: string;
}
export class DeliverySite {
    name: string;
}

export class WeeklyForecast {
    ItemName: string;
    Depot: string;
    Quantity: string;   
    id?: number;
}

export class PendingForecast {
    CustomerName: string;
    DeliverySite: string;
    Week: string;
    PrimaryContactEmail: string;
    Status: string
}

export class Rolling {
    ItemName: string;
    ItemID: string;
    Depot: string;
    DepotID: string
    Week13: string;
    Week14: string;
    Week15: string;
    Week16: string;
}

// export class Rolling {
//     ProductN: string;
//     DepotN: string;

// }