<div class="container-fluid no-margin-padding no-margin-padding-mv">
    <div class="row">
        <div class="col-sm text-center">
            <span class="approve-delivery-site-title"> {{'HOME.Updateproducts'| translate}}
            </span>
        </div>
    </div>
    <div class="row no-gutters pt-4">
        <div class="col-4">
            <label for="DeliverySiteName">
                <span class="input-text-header"> {{'HOME.DeliverySiteName'| translate}}
                </span>
            </label>
        </div>
        <div class="col-4">
            <label class="label-text">{{ selectedDeliverySite['DeliverySite'] }}</label>
        </div>
    </div>

    <div class="row no-gutters pt-3 pb-1">
        <div class="col-9">
            <div class="input-group rounded">
                <input type="search" class="form-control rounded global-search-text-box"
                    placeholder="{{'HOME.Search'| translate}}..." aria-label="Search" aria-describedby="search-addon"
                    (keyup)="applyFilter($event.target.value)" [(ngModel)]="globalSearchText" />
            </div>
        </div>
    </div>

    <div class="row no-gutters">
        <div class="col-sm">
            <div [hidden]='!displayTable'>
                <mat-table #table [dataSource]="dataSourceForDeliverySiteProducts" #weekSort="matSort" matSort
                    class="table-div-scrollbar">
                    <ng-container matColumnDef="DepotName">
                        <mat-header-cell class="depot-name-column" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Depot'|translate}}
                        </mat-header-cell>
                        <mat-cell class="depot-name-column" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.DepotName}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ProductName">
                        <mat-header-cell class="product-name-column" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Pname'|translate}}
                        </mat-header-cell>
                        <mat-cell class="product-name-column" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.ProductName}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Status">
                        <mat-header-cell class="status-column" *matHeaderCellDef>
                            <!-- {{'HOME.Add/Remove'| translate}} -->

                            <input class="checkbox-add-remove" type="checkbox" [(ngModel)]="isAllProductsSelected"
                                (click)="selectAllProductsToBeAdded()" title="{{'HOME.Selectall'| translate}}">

                        </mat-header-cell>
                        <mat-cell class="status-column" *matCellDef="let fetchedDataForItemArray">
                            <span>
                                <input class="checkbox-add-remove" type="checkbox"
                                    [(ngModel)]="fetchedDataForItemArray.isStatusActive"
                                    title="{{'HOME.SelectProduct'| translate}}">
                            </span>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </div>
            <div [hidden]="displayTable" class="no-records-message pt-4">
                {{'HOME.Norecordsfound'| translate}}
            </div>
        </div>
    </div>
    <div class="row pt-3 close-button-row-mv">
        <div class="col-sm">
            <mat-dialog-actions align="center">
                <span class="pl-2">
                    <button class="btn btn-sm btn-primary dialog-footer-button cancel-button" (click)="save()"
                        mat-dialog-close tabindex="-1">{{'HOME.Save' | translate }}</button>
                </span>
                <span class="pl-2">
                    <button class="btn btn-sm btn-primary dialog-footer-button cancel-button" (click)="cancel()"
                        mat-dialog-close tabindex="-1">{{'HOME.Close' | translate }}</button>
                </span>
            </mat-dialog-actions>
        </div>
    </div>
</div>