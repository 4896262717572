import { Component, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SiteStats } from '../reports';

@Component({
  selector: 'app-portal-usage',
  templateUrl: './portal-usage.component.html',
  styleUrls: ['./portal-usage.component.css']
})
export class PortalUsageComponent implements OnInit {

  
  Stats = ["Userspecific", "Page-specific"];
  displayedColumns: string[] = ['CompanyName', 'Page', 'Visits'];
  dataSource: any;
  displayTable: boolean = true;
  data: any;
  constructor() {
  }
    
    setData(data: any) {
    
    }
 
 
 
  ngOnInit() {
    this.getDummyData();
  }
 
 

  

 
 
 
  getDummyData() {
    const siteStats: SiteStats[] = [
      { CompanyName: "YIT Kotka Ilarila", Page: "Contact us", Visits: "39"},
      { CompanyName: "Bitumen Sealings Ltd.", Page: "Delivery guide", Visits: "39"},
      { CompanyName: "Nordic Waterprooofing", Page: "Product information", Visits: "39" },
      { CompanyName: "YIT Kotka Ilarila", Page: "Order management", Visits: "39" },
      { CompanyName: "Nordic Waterprooofing", Page: "Ordering  procedure", Visits: "38" },
      { CompanyName: "YIT Kotka Ilarila", Page: "Product information",  Visits: "38", },
      { CompanyName: "Bitumen Sealings Ltd.", Page: "Order management", Visits: "39" },
      { CompanyName: "YIT Kotka Ilarila", Page: "Product information", Visits: "38" },
      { CompanyName: "YIT Kotka Ilarila", Page: "Product information",  Visits:"39" },
      { CompanyName: "Bitumen Sealings Ltd.", Page: "Order management",  Visits: "39" },
      { CompanyName: "Nordic Waterprooofing", Page: "Delivery guide",  Visits: "39"},
      { CompanyName: "YIT Kotka Ilarila", Page: "Product information",  Visits: "39"},
      { CompanyName: "Nordic Waterprooofing", Page: "Delivery guide",  Visits: "38"},
      { CompanyName: "YIT Kotka Ilarila", Page: "Delivery guide",  Visits: "38"},
      { CompanyName: "Bitumen Sealings Ltd.",Page: "Order management",  Visits: "39"},
      { CompanyName: "YIT Kotka Ilarila",Page: "Delivery guide",  Visits: "38" },  
      { CompanyName: "YIT Kotka Ilarila", Page: "Order management",  Visits: "39"}
    ];
    this.displayTable = true;
    this.dataSource = new MatTableDataSource(siteStats);
   // console.log(siteStats, "get siteStats");
    // this.dataSource.sort = this.sort;
    this.displayTable = true;
  }
}
