import { MediaMatcher } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../shared-modules/services/shared.service';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { AddPriceIndexService } from './add-price-index.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YY',
  },
  display: {
    dateInput: 'DD-MMM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-add-price-index',
  templateUrl: './add-price-index.component.html',
  styleUrls: ['./add-price-index.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AddPriceIndexComponent implements OnInit, OnDestroy {

  yearList: number[] = [];
  priceIndexForm: FormGroup;
  submitted = false;
  isRequestCompleted: boolean;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('priceIndexDailyTableSort', {static: true}) public priceIndexDailyTableSort: MatSort;
  @ViewChild('priceIndexMonthlyTableSort', {static: true}) public priceIndexMonthlyTableSort: MatSort;
  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  @ViewChild('TableTwoPaginator', { static: true }) tableTwoPaginator: MatPaginator;

  type: string;
  records: string;
  public displayedColumnsForDailyTable = ['IndexDate', 'PriceNotation', 'UOM', 'Rate', 'Actions'];
  public displayedColumnsForMonthlyTable = ['Month', 'Year', 'PriceNotation', 'UOM', 'Rate', 'Actions'];
  public dataSourceForDailyTable = new MatTableDataSource();
  public dataSourceForMonthlyTable = new MatTableDataSource();
  userInfo: any;
  loggedInUserName: string;
  currentToggleMenu: string = 'daily';  
  monthList: string[] = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
  /* uomList: string[] = [ "USD per ton" , "EUR per ton" , "USD/barrel"];
  priceNotationList: string[] = [ "BRENT", "BIHE" , "BIHE : ICE BRENT" ]; */

  uomList: string[] = [];
  priceNotationList: string[] = [];

  isPriceIndexFormEdit: boolean = undefined;
  isRateValueInvalid: boolean;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(private formBuilder: FormBuilder,
    private addPriceIndexService: AddPriceIndexService,
    private sharedService: AppService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService){
    this.userInfo = this.sharedService.getLoginResponse();
    this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cdRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  async ngOnInit() {
    this.priceIndexForm = this.formBuilder.group({
      IndexDate: ['', Validators.required],
      Month: ['', Validators.required],
      Year: ['', Validators.required],
      PriceNotation: ['', Validators.required],
      // Source: ['ECB', Validators.required],
      UOM: ['', Validators.required],
      Rate: ['', Validators.required],
      UpdatedBy: [''],
      CreatedBy: [''],
      UpdatedOn: [''],
      CreatedOn: [''],
      currentIndexDate: ['']
    });

    /* 
    Commented old code, kept for future reference
    // Get Notation and UOM dropdown values from service
    this.getNotationAndUOMList();

    this.getPriceIndexList(this.currentToggleMenu); */

    try {
      await this.getPriceNotationList();
    }
    catch (e) {
    }

    try {
      await this.getUOMList();
    }
    catch (e) {
    }

    try {
      await this.getPriceIndexList(this.currentToggleMenu);
    }
    catch (e) {
    }

    // Populate year dropdown
    let currentYear: number = this.getCurrentYear();
    this.getYearList(currentYear);
    this.translate.get(['HOME.selectedLanguageForDatePicker'])
      .subscribe(data => {
        this.dateAdapter.setLocale(data['HOME.selectedLanguageForDatePicker']);
      });

    this.translate.get(['HOME.itemsPerPageLabel'])
      .subscribe(data => {
        this.tableOnePaginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
      });

  }

  

  ngAfterViewInit(): void {
    this.dataSourceForDailyTable.sort = this.priceIndexDailyTableSort;
    this.dataSourceForMonthlyTable.sort = this.priceIndexMonthlyTableSort;
    this.cdRef.detectChanges();
    this.dataSourceForDailyTable.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'IndexDate': {
          return new Date(item[property]);
        }
        default: return item[property];
      }
    };
    this.dataSourceForMonthlyTable.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Month': {
          if (item[property] && this.getCurrentDate() && this.getCurrentYear()) {
            let sampleDateFromMonth = this.getCurrentDate().substring(0, 2) + '-' + item[property].toUpperCase().substring(0, 3) + '-' + this.getCurrentYear().toString().substring(0, 2);
            return new Date(sampleDateFromMonth);
          }
        }
        default: return item[property];
      }
    };
    this.dataSourceForDailyTable.paginator = this.tableOnePaginator;
    
    this.translate.get(['HOME.itemsPerPageLabel'])
      .subscribe(data => {
        this.tableOnePaginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
        this.tableTwoPaginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  // convenience getter for easy access to form fields
  get fields() {
    return this.priceIndexForm.controls;
  }

  save() {
    this.submitted = true;

    //Set value in hidden fields as per selected toggle menu, to skip required field validation on them
    if (this.currentToggleMenu && this.currentToggleMenu.toUpperCase() === 'DAILY') {
      this.getCurrentMonthAndYear();
      this.priceIndexForm.controls['Month'].setValue(this.priceIndexForm.value['Month'].substring(0, 3).toUpperCase());
    }
    if (this.currentToggleMenu && this.currentToggleMenu.toUpperCase() === 'MONTHLY') {
      this.getCurrentDate();
    }

    this.isRateValueInvalid = this.isInValidDecimalNumber(this.priceIndexForm.value.Rate);
    if (!this.priceIndexForm.valid || this.isRateValueInvalid) {
      this.openSaveUserDialog('There are items that require your attention', 'Please complete all fields marked with *', false,'price-index-message');
      return false;
    }

    this.presetFormValues();
    // this.priceIndexForm.controls['priceIndexType'].setValue(this.currentToggleMenu.toUpperCase());
    this.priceIndexForm.controls['Month'].setValue(this.priceIndexForm.value['Month'].substring(0, 3).toUpperCase());
    this.priceIndexForm.controls['IndexDate'].setValue(this.datePipe.transform(this.priceIndexForm.value.IndexDate, 'yyyy-MM-dd'));

    // Check if it is update or add new index
    if (this.isPriceIndexFormEdit) {
      this.addPriceIndexService.updateIndex(this.priceIndexForm.value, this.currentToggleMenu)
        .subscribe(
          (response: any) => {
            this.saveStatusDialog(response);
          });
    } else {
      this.addPriceIndexService.addPriceIndex(this.priceIndexForm.value, this.currentToggleMenu)
        .subscribe(
          (response: any) => {
            this.saveStatusDialog(response);
          });
    }

  }

  reset() {
    this.submitted = false;
    this.isPriceIndexFormEdit = undefined;
    this.isRateValueInvalid = undefined;
    this.priceIndexForm.reset();
    this.presetFormValues();
    // this.getCurrentMonthAndYear();
    // this.priceIndexForm.controls['Source'].setValue('ECB');
    this.priceIndexForm.controls['PriceNotation'].setValue('');
    this.priceIndexForm.controls['UOM'].setValue('');
    this.priceIndexForm.controls['Month'].setValue('');
    this.priceIndexForm.controls['Year'].setValue('');
  }

  presetFormValues() {
    this.priceIndexForm.controls['UpdatedBy'].setValue(this.loggedInUserName);
    this.priceIndexForm.controls['CreatedBy'].setValue(this.loggedInUserName);
    this.priceIndexForm.controls['UpdatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
    this.priceIndexForm.controls['CreatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
  }

  async getPriceIndexList(toggleButtonMenu: string) {
    this.isRequestCompleted = false;
    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'DAILY') {
      return new Promise<void>((_resolve, _reject) => {
        this.addPriceIndexService.getPriceIndexForTypeDaily()
          .subscribe(
            response => {
              if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
                const priceIndexCollection = 'PriceIndexCollection';
                if (response[priceIndexCollection] && response[priceIndexCollection].length) {
                  var priceIndexCollectionList = [];
                  for (var priceIndexObject of response[priceIndexCollection]) {
                    let currentPriceIndexObject = {};
                    currentPriceIndexObject['IndexDate'] = priceIndexObject['IndexDate'];
                    currentPriceIndexObject['UOM'] = priceIndexObject['UOM'];
                    currentPriceIndexObject['PriceNotation'] = priceIndexObject['PriceNotation'];
                    currentPriceIndexObject['Rate'] = priceIndexObject['Rate'];
                    currentPriceIndexObject['Source'] = priceIndexObject['PriceNotationSource'];
                    priceIndexCollectionList.push(currentPriceIndexObject);
                  }

                  // Reset any data already entered in form
                  this.reset();
                  this.dataSourceForDailyTable.data = priceIndexCollectionList;
                  this.isRequestCompleted = true;
                }
                this.isRequestCompleted = true;
                _resolve();
              }
              else {
                _reject();
              }
              this.isRequestCompleted = true;
            });
      })
    }
    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'MONTHLY') {
      let currentYear: string = this.getCurrentYear().toString();
      return new Promise<void>((_resolve, _reject) => {
        this.addPriceIndexService.getPriceIndexForTypeMonthly(currentYear)
          .subscribe(
            response => {
              if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
                const monthlyIndexCollection = 'MonthlyIndexCollection';
                if (response[monthlyIndexCollection] && response[monthlyIndexCollection].length) {
                  var monthlyIndexCollectionList = [];
                  for (var priceIndexObject of response[monthlyIndexCollection]) {
                    if (priceIndexObject['PriceNotation'] && priceIndexObject['PriceNotation'].length) {
                      for (var priceNotation of priceIndexObject['PriceNotation']) {
                        let currentPriceIndexObject = {};
                        // currentPriceIndexObject['Month'] = this.getFullMonthName(priceIndexObject['Month']);
                        currentPriceIndexObject['Month'] = priceIndexObject['Month'];
                        currentPriceIndexObject['Year'] = priceIndexObject['Year'];
                        currentPriceIndexObject['UOM'] = priceNotation['UOM'];
                        currentPriceIndexObject['PriceNotation'] = priceNotation['Name'];
                        currentPriceIndexObject['Rate'] = priceNotation['Rate'];
                        currentPriceIndexObject['Source'] = priceNotation['Source'];
                        monthlyIndexCollectionList.push(currentPriceIndexObject);
                      }
                    }
                  }

                  // Reset any data already entered in form
                  this.reset();
                  this.dataSourceForMonthlyTable.data = monthlyIndexCollectionList;
                  this.dataSourceForMonthlyTable.paginator = this.tableTwoPaginator;
                  this.isRequestCompleted = true;
                }
                this.isRequestCompleted = true;
                _resolve();
              }
              else {
                _reject();
              }
              this.isRequestCompleted = true;
            });
      })
    }
  }

  editPriceIndex(priceIndex: any) {
    this.isPriceIndexFormEdit = true;
    // let date = new Date(priceIndex.IndexDate), 
   // date.toLocaleDateString("en-GB");
        // indexDate = this.datePipe.transform(date, 'yyyy-MM-dd'); 
      // let  indexDate = moment(new Date(priceIndex.IndexDate)).format('YYYY-MM-DD');

    // Set Month property in form    
    // priceIndex['Month'] = this.getFullMonthName(priceIndex.Month);

    this.priceIndexForm.patchValue(priceIndex);         
    // let date1 = new FormControl(new Date(priceIndex.IndexDate));
    if (priceIndex.IndexDate) {
      this.priceIndexForm.controls['IndexDate'].setValue(this.getCorrectDateFormate(priceIndex.IndexDate));
    }
    this.priceIndexForm.controls['currentIndexDate'].setValue(priceIndex.IndexDate);
  }

  getCorrectDateFormate(dateWtihMonth: any) {
    // get year
    var yy = dateWtihMonth.substr(7, 2);
    var fullYear = (yy < 90) ? '20' + yy : '19' + yy;
    var newdate = dateWtihMonth.substr(0, 7) + fullYear;

    // get month
    var actualDate: any = newdate.split("-");
    var months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    for (var j = 0; j < months.length; j++) {
      if (actualDate[1] == months[j]) {
        let monthNumber = (months.indexOf(months[j]) + 1);
        if (monthNumber < 10) {
          actualDate[1] = '0' + monthNumber;
        } else {
          actualDate[1] = monthNumber;
        }
      }
    }
    /* if (actualDate[0] < 10) {
      actualDate[0] = '0' + actualDate[1];
    } */
    var formattedDate = actualDate[2] + '-' + actualDate[1] + '-' + actualDate[0];
    return formattedDate;
  }

  async toggleButtonClick(toggleMenu: string) {
    this.reset();
    this.currentToggleMenu = toggleMenu;
    // this.priceIndexForm.controls['priceIndexType'].setValue(toggleMenu);
    // this.getCurrentMonthAndYear();

    // Commented old code, kept for future reference
    // this.getPriceIndexList(this.currentToggleMenu);

    try {
      await this.getUOMList();
    }
    catch (e) {
    }

    try {
      await this.getPriceIndexList(this.currentToggleMenu);
    }
    catch (e) {
    }
  }

  getCurrentMonthAndYear() {
    const date = this.sharedService.getCurrentDateTime();

    // Get current year
    let currentYear = date.getUTCFullYear();    

    // Get current month
    var dateArray = date.toISOString().split("-");
    var month_index =  parseInt(dateArray[1],10) - 1;

    this.getYearList(currentYear);
    this.priceIndexForm.controls['Month'].setValue(this.monthList[month_index]);
    this.priceIndexForm.controls['Year'].setValue(currentYear);
  }

  getCurrentDate(): string {
    let currentDate: any;
    const date = this.sharedService.getCurrentDateTime();  
    currentDate = this.datePipe.transform(date, 'yyyy-MM-dd');      
    this.priceIndexForm.controls['IndexDate'].setValue(currentDate);
    return currentDate;
  }

  getCurrentYear(): number {
    const date = this.sharedService.getCurrentDateTime();
    return date.getUTCFullYear();
  }
   
  getYearList(currentYear: number) {
    this.yearList = [];
    this.yearList.push(currentYear - 1, currentYear);
  }

  saveStatusDialog(response: any) {
    /* if (this.priceIndexForm.value['Month']) {
      this.priceIndexForm.controls['Month'].setValue(this.getFullMonthName(this.priceIndexForm.value['Month']));
    } */
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        this.openSaveUserDialog('Price Index Details Saved Successfully', null, true,'price-index-success');        
        this.getPriceIndexList(this.currentToggleMenu);
      } else if (response['Description'] && response['Description'].trim().toUpperCase() === 'DATA ALREADY PRESENT FOR THE GIVEN INPUT.') {
        this.openSaveUserDialog('Failed to Save Price Index Details','', false,'price-index-fail-exist');
      } else if   (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR'){
        this.openSaveUserDialog('Failed to Save Price Index Details', null, false,'price-index-fail');
      }
    } else {
      this.openSaveUserDialog('Failed to Save Price Index Details', null, false,'price-index-fail');
    }
  }

  openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber:string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber:headerNumber
      },
			// height: '37%',
      height: 'auto',
			width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  getFullMonthName(shortMonthName: string): string {
    let fullMonthName: string;    
    for (let month of this.monthList) {
      if (shortMonthName && month && month.toUpperCase().startsWith(shortMonthName.toUpperCase())) {
        fullMonthName = month;
        break;
      }
    }
    return fullMonthName;
  }

  isInValidDecimalNumber(quantity: number): boolean {
    let regex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g); // regex to check if number contains more than 3 decimal places
    return (quantity && !String(quantity).match(regex))
  }

  getDateObject(date: string): any {
    let dateObject = {};
    if (date) {
      let dateArray = date.split('-');
      if (dateArray && dateArray.length && dateArray[0] && dateArray[1] && dateArray[2]) {
        dateObject['date'] = dateArray[0] ? dateArray[0] : '';
        dateObject['month'] = dateArray[1] ? dateArray[1].toUpperCase() : '';
        dateObject['year'] = dateArray[2] ? dateArray[2] : '';
      }

    }
    return dateObject;
  }
  
  async getPriceNotationList() {
    let priceNotationType: string = 'PRICE_NOTATION';
    return new Promise<void>((_resolve, _reject) => {
      this.addPriceIndexService.getDisplayMessage(priceNotationType)
        .subscribe(
          response => {
            if (response && response['ResponseCode'] === '200') {
              if (response['Message'] && response['Message'].split(',') && response['Message'].split(',').length) {
                this.priceNotationList = response['Message'].split(',');
              }
              _resolve();
            }
            else {
              _reject();
            }
          });
    })
  }

  async getUOMList() {
    let UOMtype: string = 'UOM' + '_' + (this.currentToggleMenu ? this.currentToggleMenu.toUpperCase() : '');
    return new Promise<void>((_resolve, _reject) => {
      this.addPriceIndexService.getDisplayMessage(UOMtype)
        .subscribe(
          response => {
            if (response && response['ResponseCode'] === '200') {
              if (response['Message'] && response['Message'].split(',') && response['Message'].split(',').length) {
                this.uomList = response['Message'].split(',');
              }
              _resolve();
            }
            else {
              _reject();
            }
          });
    })
  }

}
