import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { AppService } from '../shared-modules/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UpdatePasswordComponent } from '../update-password/update-password.component';
import { CommonService } from '../order-management/order-management/order-management.component.service';
import { NavigationExtras, Router } from '@angular/router';
import { DashboardService } from './dashboard.component.service';
import { Dashboard } from './home';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import * as moment from 'moment'
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../shared-modules/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  selectedItem: string = 'Home';
  navbarOpen = false;
  userInfo: any;
  name: String;
  emailId: String;
  userId: String;
  isLoggedInUserPortalAdmin: boolean;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isRequestCompleted: boolean;
  public displayedColumns = ['TITLE', 'NYNAS_UPDATE_DATE', 'View'];
  isLoggedInUserAdmin: boolean;
  imageUrl: string;
  ExchangeDate = [];
  ConversionRate = [];
  BrentRate = [];
  averageConversionRate: string;
  averageBrentRate: string;
  averageConversionRateList = [];
  averageBrentRateList = [];
  year: string;
  custId: string;
  Actual_Quantity: number[] = [];
  Forecast_Quantity: number[] = [];
  Order_month: string[] = [];
  Order_month_fin:string[] = [ "tammi","helmi","maalis", "huhti" ,"touko" , "kesä ", "heinä", "elo","syys","loka","marras","joulu"];
  public dataSource = new MatTableDataSource<Dashboard>();
  allowedMenuIdList: string[] = [];
  errorDesc: string = '';
  displayErrorMsg: boolean = true;
  menuAccessList: string[] = [];
  // delSite: string = 'DefaultDelSite';
  accessDeniedTooltip: string = "You do not have access to this Menu. Please contact administrator for access.";
  countryCode: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  expectedPriceChange: string;
  deliverySiteObjectList: any = [];
  HSFOEURPerMT: any = [];
  averageHSFOEURPerMT: any = [];
  averageHSFOEURPerMTList = [];
  days : number ;

  constructor(private translate: TranslateService, private dashboardService: DashboardService, private router: Router, private sharedService: AppService, private dialog: MatDialog, private commonService: CommonService, private cd: ChangeDetectorRef, private media: MediaMatcher) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.days = this.userInfo.DaysToPasswordExpiry;
    //this.days = 8;
    this.imageUrl = ((this.userInfo && this.userInfo.ProfilePhoto && !this.userInfo.ProfilePhoto.toUpperCase().includes('CDATA')) ? ('data:image/png;base64,' + this.userInfo.ProfilePhoto) : null);
    this.name = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.custId = this.userInfo.CustomerID;
    this.userId = this.userInfo.UserID;
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.isLoggedInUserPortalAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA") : false);
    this.countryCode = this.userInfo.CountryCode;
    this.getAllowedMenuIdList(this.userInfo ? this.userInfo.Menus : []);
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cd.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }


  ngOnInit() {
    this.year = moment(this.sharedService.getCurrentDateTime()).format('YYYY');
    this.userId = this.userInfo ? this.userInfo.UserID : '';
    //console.log("DaysToPasswordExpiry" + this.days);
    this.getLineChartData();
    this.getBarChartData();
    this.getNynasUpdates();
    const userList: Dashboard[] = [];
    this.dataSource.data = userList;
    if(this.days < 10){
      //console.log("testing lesser days popup" + this.days + " "+ this.userInfo.DaysToPasswordExpiry);
      //this.openDialog('Please change your password', null, false,'pwd-change-days');
      this.showConfirmationPopUp(this.days ,'pwd-change-days' , 'pwd-change-days-msg');
    }
  }
  
  showConfirmationPopUp(days:number,headerNumber:string, msg : string) {
		//message += 'Are you sure you want to delete this group?';
		const dialogData = new ConfirmDialogModel("Please change your password", '',msg,headerNumber,days);
    
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			// maxWidth: "410px",
			// data: dialogData,
			data: {
				headerNumber:headerNumber ,
				messageNumber: msg,
				count: days
			},
			// height: '50%',
      height: 'auto',
			width: (this.mobileQuery.matches ? '60%' : '24%'),
			// header: 'Delete user group',
			
		});
		dialogRef.afterClosed().subscribe(dialogResult => {
			if (dialogResult) {
			 this.updatePassword();
			}
		});
	}


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getBarChartData() {
    /* if(this.countryCode !== "FIN"){
      this.delSite = '';
    } */
    let deliverySite: string = ''; // Pass delivery site as empty string for all countries
    
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(deliverySite);
    this.dashboardService.getBarChartData(this.year, this.custId , deliverySite, jdeAddressNumber).subscribe(
      res => {
        if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          res["Collection"].forEach(x => {
            this.Actual_Quantity.push(x.Actual_Quantity);
            this.Forecast_Quantity.push(x.Forecast_Quantity);
            // this.Order_month.push(x.Forecast_month);
            this.translate.get(['HOME.'+ x.Forecast_month])
            .subscribe(data => {
              this.Order_month.push(data['HOME.'+ x.Forecast_month]);
            });
            this.displayErrorMsg = false;

          });

        }
        else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
          (res['Description'] ? res['Description'] : null);
          this.errorDesc = res['Description'];
          this.displayErrorMsg = true;
        }
        else {
          (res['Description'] ? res['Description'] : null);
          this.errorDesc = res['Description'];
          this.displayErrorMsg = true;
        }
      });

  }

  getLineChartData() {
    let uom: string = 'USD/MT';
    this.dashboardService.getLineChartData(uom).subscribe(
      res => {
        if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          if (res["PriceIndexAndConversionRateCollection"] && res["PriceIndexAndConversionRateCollection"].length) {
            res["PriceIndexAndConversionRateCollection"].forEach(y => {
              // this.ExchangeDate.push(y.IndexDate);

              this.translate.get(['HOME.' + this.getDateObject(y.IndexDate)['month']])
                .subscribe(data => {
                  this.ExchangeDate.push(this.getDateObject(y.IndexDate)['date']
                    + '-'
                    + data['HOME.' + this.getDateObject(y.IndexDate)['month']]
                    + '-'
                    + this.getDateObject(y.IndexDate)['year']
                  );
                });

              this.ConversionRate.push(y.ConversionRate);
              this.BrentRate.push(undefined);
              y['PriceNotation'].forEach(x => {
                if (x.Name === 'BRENT') {
                  this.BrentRate.pop();
                  y['BrentRate'] = x.Rate;
                  this.BrentRate.push(x.Rate);
                }
              })
              
              this.HSFOEURPerMT.push(undefined);
              y['PriceNotation'].forEach(x => {
                if (x.Name === 'HSFO' && x.UOM === 'USD/MT') {
                  this.HSFOEURPerMT.pop();
                  y['HSFOEURPerMT'] = x.Rate;
                  this.HSFOEURPerMT.push(x.Rate);
                }
              })

              this.displayErrorMsg = true;

            });
            this.getExpectedPriceChange();
          }
        }
        else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
          (res['Description'] ? res['Description'] : null);
          this.errorDesc = res['Description'];
          this.displayErrorMsg = false;
        }
        else {
          (res['Description'] ? res['Description'] : null);
          this.errorDesc = res['Description'];
          this.displayErrorMsg = false;
        }
      });
  }
  validateMenu(menuId: string): boolean {
    return this.menuAccessList.includes(menuId);
  }
  getAllowedMenuIdList(menuList: any) {
    if (menuList && menuList.length) {
      for (var menu of menuList) {
        this.menuAccessList.push(menu.MenuID);
      }
    }
  }


  updatePassword() {
    // this.dialog.open(UpdatePasswordComponent);
    this.dialog.open(UpdatePasswordComponent, {      
			// height: '60%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
      height: 'auto',
      // width: 'auto'
    });
  }
  editUser() {
    const navigationExtras: NavigationExtras = {
      state: {
        emailId: this.userId,
        isMyProfile: true,
				customerId: this.custId
      }
    };
    this.router.navigate(["../../app/my-profile"], navigationExtras);
  }


  getNynasUpdates() {
    this.dataSource.data = [];
    this.isRequestCompleted = false;
    this.dashboardService.getUpdates(this.countryCode)
      .subscribe(
        res => {
          const users = 'getNynasUpdates';

          if (res && res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            // if (false) {
            this.displayErrorMsg = true;
            if (res && res[users] && res[users].length) {
              var userList = [];
              let index = 0;
              for (var user of res[users]) {
                if (index <= 4) {
                  userList.push(user);
                }
                index++;
              }
              this.dataSource.data = userList;
              this.isRequestCompleted = true;
            }
            this.isRequestCompleted = true;

          }
          else if (res && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
            (res['Description'] ? res['Description'] : null);
            this.errorDesc = res['Description'];
            this.displayErrorMsg = false;
          }
          else {
            this.errorDesc = (res && res['Description'] ? res['Description'] : null);
            this.displayErrorMsg = false;

          }

        });
  }
  downloadPdf(pdfUrl, storageType) {
    // let type;
    //console.log(pdfUrl, storageType)
    if (storageType == "Sharepoint") {
      this.downloadPdfSharepoint(pdfUrl)
    } else if (storageType == "External") {
      if (pdfUrl) {
        window.open(pdfUrl, '_blank');
      }
    } else {
      this.openDialog('Sorry! No file available.', null, false,'no-file-available');
    }


  }
  downloadPdfSharepoint(pdfUrl) {
    let pdfData;
    // let filepath = "/Shared%20Documents/General/" + pdfUrl
    let filepath = pdfUrl;

    let sharepointData = {
      "Username": "API.Integration@nynas.onmicrosoft.com",
      "Password": "VIN24jkjXXwdjwnvbnas",
      "Domain": "nynas",
      // "SourceFolderPath": "/sites/GroupCustomerPortal",
      "SourceFolderPath" : "/sites/PortalOperationalDocuments",
      "FilePath": filepath.replace(/ /g, "%20")
    }

    this.dashboardService.getSharepointDoc(sharepointData)
      .subscribe(
        res => {
          if (res["ResponseCode"] == "200") {
            pdfData = res["EncodedFileData"]
            const byteArray = new Uint8Array(atob(pdfData).split('').map(char => char.charCodeAt(0)));
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            let fileUrl = window.URL.createObjectURL(blob);
            window.open(fileUrl);
          } else {
            this.openDialog('Sorry! No file available.', null, false,'no-file-available');
          }
        });
  }
  openDialog(headerTitle: string, message: string, isSuccess: boolean,headerNumber:string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber:headerNumber,
      },
			height: '35%',
			width: (this.mobileQuery.matches ? '50%' : '25%'),
    });
  }
  
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  showSelectedMenuItem(itemName: string) {
    this.selectedItem = itemName;
    this.updateSelectedItem(this.selectedItem);
  }
  //  Changes for barchart start
  public barChartOptions2: ChartOptions = {
    responsive: true,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    tooltips: {
      mode: 'index'
    },
    scales: {

      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 10,

        }
      }], yAxes: [{
        gridLines: {
          drawBorder: true,
          display: false
        },
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 14,

        },        
        scaleLabel: {
          display: true,
          labelString: '(MT)'
        }
      }]
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    }
  };
  public barChartLabels2: Label[] = this.Order_month;
  public barChartType2: ChartType = 'bar';
  public barChartLegend2 = true;
  public barChartPlugins2 = [pluginDataLabels];

  public barChartData2: ChartDataSets[] = [
    /* { data: this.Actual_Quantity, label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Actual' : 'Toteuma'), pointStyle: "line", backgroundColor: '#FFD500', hoverBackgroundColor: '#FFD500', hoverBorderColor: '#FFD500' },
    { data: this.Forecast_Quantity, label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Planned' : 'Ennuste'), pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' } */

    { data: this.Actual_Quantity, label: this.getTitleBySelectedLanguage('Actual'), pointStyle: "line", backgroundColor: '#FFD500', hoverBackgroundColor: '#FFD500', hoverBorderColor: '#FFD500' },
    { data: this.Forecast_Quantity, label: this.getTitleBySelectedLanguage('Planned'), pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' }
  ];

  // Changes for barchart end

  //bar chart data for customer role: start

  public barChartOptions1: ChartOptions = {
    responsive: true,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    tooltips: {
      mode: 'index'
    },
    scales: {

      xAxes: [{

        ticks: {
          fontColor: 'black',
          fontSize: 10
        }
      }], yAxes: [{
        gridLines: {
          drawBorder: true,
          display: false
        },
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 10
        }
      }]
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    }
  };
  public barChartLabels1: Label[] = this.Order_month;
  public barChartType1: ChartType = 'bar';
  public barChartLegend1 = true;
  public barChartPlugins1 = [pluginDataLabels];

  public barChartData1: ChartDataSets[] = [
    { data: this.Forecast_Quantity, label: 'Forecast', pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' }
  ];
  //bar chart data for customer role : end


  //line chart start
  public lineChartData: ChartDataSets[] = [
    { data: this.BrentRate, backgroundColor: 'rgba(0, 0, 0, 0)', label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'ICE Brent notation (USD/bbl)' : 'ICE Brent -noteeraus (USD/bbl)'), pointStyle: 'line', borderWidth: 2 },
    // { data: [1,2, 3,4 ,5 , 6, 7], backgroundColor: 'rgba(0, 0, 0, 0)', label: 'test1', yAxisID: 'y-axis-1', pointStyle: 'line', borderWidth: 2 },
    // { data: this.averageBrentRateList, backgroundColor: 'rgba(0, 0, 0, 0)', label: 'Average Brent Rate', pointStyle: 'dash' , borderWidth: 2 },
    { data: this.ConversionRate, backgroundColor: 'rgba(0, 0, 0, 0)', label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Conversion Rate (USD/EUR)' : 'Vaihtokurssi (USD/EUR)'), yAxisID: 'y-axis-1', pointStyle: 'line', borderWidth: 2 },
    { data: this.averageBrentRateList, backgroundColor: 'rgba(0, 0, 0, 0)', borderDash: [10,5] , label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Average Brent Rate' : 'ICE Brent -noteerausten keskiarvo'), pointStyle: 'dash' , borderWidth: 2 },
    { data: this.averageConversionRateList, backgroundColor: 'rgba(0, 0, 0, 0)', borderDash: [10,5] , label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Average Conversion Rate' : 'Vaihtokurssien keskiarvo'), yAxisID: 'y-axis-1', pointStyle: 'dash' , borderWidth: 2 }
    
  ];
  
  public lineChartLabels: Label[] = this.ExchangeDate;
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,

    plugins: {
      datalabels: {
        display: false,
      }
    },
    tooltips: {
      mode: 'index'
    },
    legend: {
      position: "bottom",
      display: false,
      labels: {
        fontColor: 'black',
        fontSize: 14,
        usePointStyle: true,
        fontStyle: "bold"
      }
    },
    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        // type: 'time',
        // time: {
        //   displayFormats: {
        //     'day': 'MM/DD'
        //   }
        // },

        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "normal",
        }
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'ICE Brent notation (USD/bbl)' : 'ICE Brent -noteeraus (USD/bbl)')
          },
          gridLines: {
            drawBorder: true,
            display: false
          },
          ticks: {
            fontColor: 'black',
            beginAtZero: true,
            fontSize: 14,

            suggestedMax: 100
            // fontFamily: "Frutiger",
          }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Conversion Rate (USD/EUR)' : 'Vaihtokurssi (USD/EUR)')
          },
          gridLines: {
            drawBorder: true,
            display: false
          },
          ticks: {
            beginAtZero: true,
            fontColor: 'black',
            fontSize: 14,

            // fontFamily: "Frutiger",
          }
        }
      ]
    },
    annotation: {},
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#0054A3'
      // pointBackgroundColor: 'rgba(148,159,177,1)',
      //pointBorderColor: '#fff',
      //pointHoverBackgroundColor: '#fff',
      //pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {
      borderColor: '#9F3259'
    },
    {
      borderColor: '#FFD500'
    },
    {
      borderColor: '#69AAA4'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  // public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  //line chart end

  //line chart for Norway and Sweden
  public lineChartData1: ChartDataSets[] = [
    { data: this.BrentRate, backgroundColor: 'rgba(0, 0, 0, 0)', label: 'ICE Brent notation (USD/bbl)', pointStyle: 'line', borderWidth: 2 }
  ]
  public lineChartLabels1: Label[] = this.ExchangeDate;
  public lineChartOptions1: (ChartOptions & { annotation: any }) = {
    responsive: true,

    plugins: {
      datalabels: {
        display: false,
      }
    },
    tooltips: {
      mode: 'index'
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        usePointStyle: true,
        fontStyle: "bold"
      }
    },
    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        // type: 'time',
        // time: {
        //   displayFormats: {
        //     'day': 'MM/DD'
        //   }
        // },

        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "normal",
        }
      }],
      yAxes: [
       
        {
          id: 'y-axis-1',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'ICE Brent notation (USD/bbl)'
          },
          gridLines: {
            drawBorder: true,
            display: false
          },
          ticks: {
            fontColor: 'black',
            beginAtZero: true,
            fontSize: 14,

            suggestedMax: 10
            // fontFamily: "Frutiger",
          }
        }
      ]
    },
    annotation: {},
  };
  public lineChartColors1: Color[] = [
    {
      borderColor: '#FFD500'
      // pointBackgroundColor: 'rgba(148,159,177,1)',
      //pointBorderColor: '#fff',
      //pointHoverBackgroundColor: '#fff',
      //pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {
      borderColor: '#18426a'
    }
  ];
  public lineChartLegend1 = true;
  public lineChartType1 = 'line';
  // public lineChartPlugins = [pluginAnnotations];

 // @ViewChild(BaseChartDirective, { static: true }) chart1: BaseChartDirective;
  //Line chart end for Norway and Sweden

  // Line chart for Finland from year 2021 onwards start
  public lineChartData2: ChartDataSets[] = [
    { data: this.HSFOEURPerMT, backgroundColor: 'rgba(0, 0, 0, 0)', borderDash: [10, 5], label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Cumulative average of daily Platts HSFO 3,5 % FOB Rotterdam Barges notations EUR/MT' : 'Platts HSFO 3,5% FOB Rotterdam Barges -noteerausten kumulatiivinen keskiarvo EUR/t'), pointStyle: 'star', borderWidth: 2 },
    { data: this.averageHSFOEURPerMTList, backgroundColor: 'rgba(0, 0, 0, 0)', label: ((this.sharedService.getSelectedLanguage() !== 'FI' ? 'Average of daily Platts HSFO 3,5 % FOB Rotterdam Barges notations EUR/MT' : 'Platts HSFO 3,5% FOB Rotterdam Barges -noteerausten keskiarvo EUR/t') + ' ' + this.getPreviousMonthNameForSelectedLanguage()), pointStyle: 'line', borderWidth: 2 },
    { data: this.ConversionRate, backgroundColor: 'rgba(0, 0, 0, 0)', borderDash: [10, 5], label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Cumulative average of exchange rates USD/EUR' : 'Vaihtokurssien kumulatiivinen keskiarvo USD/EUR'), yAxisID: 'y-axis-1', pointStyle: 'star', borderWidth: 2 },
    { data: this.averageConversionRateList, backgroundColor: 'rgba(0, 0, 0, 0)', label: ((this.sharedService.getSelectedLanguage() !== 'FI' ? 'Average of exchange rates USD/EUR' : 'Vaihtokurssien keskiarvo USD/EUR') + ' ' + this.getPreviousMonthNameForSelectedLanguage()), yAxisID: 'y-axis-1', pointStyle: 'line', borderWidth: 2 }
  ];
  
  public lineChartLabels2: Label[] = this.ExchangeDate;
  public lineChartOptions2: (ChartOptions & { annotation: any }) = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      }
    },
    tooltips: {
      mode: 'index',
      bodyFontSize: 10,
      bodyFontFamily: 'Arial',
      titleFontSize: 11,
      titleFontFamily: 'Arial',
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += ((label && label.includes('HSFO')) ? (parseFloat(tooltipItem.yLabel.toString()).toFixed(2)) : tooltipItem.yLabel); // Display HSFO upto 2 decimal places
          return label;
        }
      }
    },
    legend: {
      position: "bottom",
      display: false,
      labels: {
        fontColor: 'black',
        fontSize: 10,
        fontFamily: 'Arial',
        usePointStyle: true,
        fontStyle: "bold"
      }
    },
    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 10,
      fontFamily: 'Arial',
      fontColor: "black"
    },
    scales: {
      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 9,
          fontFamily: 'Arial'
        }
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Platts HSFO 3,5% FOB Rotterdam Barges (EUR/MT)' : 'Platts HSFO 3,5% FOB Rotterdam Barges (EUR/t)'),
            fontSize: 10,
            fontFamily: 'Arial',
          },
          gridLines: {
            drawBorder: true,
            display: false
          },
          ticks: {
            fontColor: 'black',

            // To implement dynamic coordinates for Graph Y axis, change beginAtZero to false and comment min, max values

            // beginAtZero: true,

            // Commented old code, kept for future reference
            /* min: 260,
            max: 400, */

            // Commented old code, kept for future reference
            /* min: this.sharedService.getFinlandHomePageGraphAxisLowerLimit(),
            max: this.sharedService.getFinlandHomePageGraphAxisUpperLimit(), */

            min: this.getGraphAxisLimits('HSFO', 'MIN'),
            max: this.getGraphAxisLimits('HSFO', 'MAX'),

            fontSize: 10,
            fontFamily: 'Arial',
            stepSize: 20

            // suggestedMax: 100
          }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Exchange Rate (USD/EUR)' : 'Vaihtokurssi (USD/EUR)'),
            fontSize: 11,
            fontFamily: 'Arial',
          },
          gridLines: {
            drawBorder: true,
            display: false
          },
          ticks: {

            // To implement dynamic coordinates for Graph Y axis, change beginAtZero to false and comment min, max values

            // beginAtZero: true,

            // Commented old code, kept for future reference
            /* min: 1.0,
            max: 1.35, */

            min: this.getGraphAxisLimits('CONVERSIONRATE', 'MIN'),
            max: this.getGraphAxisLimits('CONVERSIONRATE', 'MAX'),

            fontColor: 'black',
            fontSize: 10,
            fontFamily: 'Arial',
          }
        }
      ]
    },
    annotation: {},
  };
  public lineChartColors2: Color[] = [
    {
      borderColor: '#0054A3'
    },
    {
      borderColor: '#9F3259'
    },
    {
      borderColor: '#FFD500'
    },
    {
      borderColor: '#69AAA4'
    }
  ];
  public lineChartLegend2 = true;
  public lineChartType2 = 'line';

  // @ViewChild(BaseChartDirective, { static: true }) chart2: BaseChartDirective;
  // Line chart for Finland from year 2021 onwards end

  //NynasUpdatesCode

  //NynasUpdatesCode
  updateSelectedItem(selectedItem: string) {
    this.commonService.updateSelectedItem(selectedItem)
  }


  getMonthbyLanguage(){

  }
  public getTooltipTitle(menuId: string): string {
    return (this.validateMenu(menuId) ? '' : this.accessDeniedTooltip);
  }

  getDateObject(date: string): any {
    let dateObject = {};
    if (date) {
      let dateArray = date.split('-');
      if (dateArray && dateArray.length && dateArray[0] && dateArray[1] && dateArray[2]) {
        dateObject['date'] = dateArray[0] ? dateArray[0] : '';
        dateObject['month'] = dateArray[1] ? dateArray[1].toUpperCase() : '';
        dateObject['year'] = dateArray[2] ? dateArray[2] : '';
      }

    }
    return dateObject;
  }

  getExpectedPriceChange() {
    if (this.countryCode === 'FIN') {
      let uom: string = 'USD/MT';
      this.dashboardService.fetchAveragePriceRate(uom)
        .subscribe(
          response => {
            if (response && response["ResponseCode"] === "200" && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
              let averageCollectionObject = response["AverageCollection"];
              if (averageCollectionObject && averageCollectionObject.length) {

                // Commented old code in case required in future
                // this.averageBrentRate = averageCollectionObject[0]['AverageBrentRate'];
                // this.expectedPriceChange = averageCollectionObject[0]['ForecastBIHE'];
                // this.averageConversionRate = averageCollectionObject[0]['AverageExchangeRate'];
                // this.averageHSFOEURPerMT = averageCollectionObject[0]['BIHE'];

                this.expectedPriceChange = averageCollectionObject[0]['PriceChange'];
                this.averageConversionRate = (averageCollectionObject[0]['CURRENT_MONTH_DAILY_RATE'] ? averageCollectionObject[0]['CURRENT_MONTH_DAILY_RATE'] : undefined);
                this.averageHSFOEURPerMT = (averageCollectionObject[0]['PAST_MONTH__MONTHLY_HSFO'] ? averageCollectionObject[0]['PAST_MONTH__MONTHLY_HSFO'] : undefined);
 
                this.BrentRate.forEach(() => {
                  this.averageBrentRateList.push(this.averageBrentRate);
                })
                this.ConversionRate.forEach(() => {
                  this.averageConversionRateList.push(this.averageConversionRate);
                })
                this.HSFOEURPerMT.forEach(() => {
                  this.averageHSFOEURPerMTList.push(this.averageHSFOEURPerMT);
                })
              }
            }
          });
    }
  }

  getTitleBySelectedLanguage(title: string): string {
    let translatedTitle: string;
    let selectedLanguage = this.sharedService.getSelectedLanguage();
    if (title === 'Actual') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Actual';
          break;
        case 'FI':
          translatedTitle = 'Toteuma';
          break;
        case 'SE':
          translatedTitle = 'Faktiskt';
          break;
        case 'NR':
          translatedTitle = 'Salg';
          break;
        case 'DA':
          translatedTitle = 'Faktiske';
          break;
        default:
          translatedTitle = 'Actual';
      }
    }
    if (title === 'Planned') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Planned';
          break;
        case 'FI':
          translatedTitle = 'Ennuste';
          break;
        case 'SE':
          translatedTitle = 'Prognostiserat';
          break;
        case 'NR':
          translatedTitle = 'Prognose';
          break;
        case 'DA':
          translatedTitle = 'Planned';
          break;
        default:
          translatedTitle = 'Planned';
      }
    }
    return translatedTitle;
  }
  
  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        if (deliverySiteObject && deliverySiteObject['DeliverySite'] === deliverySiteName) {
          jdeAddressNumber = deliverySiteObject['JdeAddressNumber'];
          break;
        }
      }
    }
    return jdeAddressNumber;
  }

  getPreviousMonthNameForSelectedLanguage() {
    let currentDateTime = this.sharedService.getCurrentDateTime();  // currentDateTime = Mon Feb 01 2021 16:04:31 GMT+0530 (India Standard Time)
    let year = currentDateTime.getFullYear(); // year = 2021
    let month = currentDateTime.getMonth(); // month = 1
    let date = currentDateTime.getDate(); // date = 1
    let previousMonthDate;
    let monthList = [];
    if (this.sharedService.getSelectedLanguage() === 'FI') {  // Use different month name list for months to be translated in graph
      monthList = ["tammikuu", "helmikuu", "maaliskuu", "huhtikuu", "touko", "kesäkuu", "heinäkuu", "elokuu", "syyskuu", "lokakuu", "marraskuu", "joulukuu"];
    } else {
      monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
    if (date <= 5) {  // If today is less than or equal to 5th, get 2 months older month name
      previousMonthDate = new Date(year, month - 2, date);  // previousMonthDate = Tue Dec 01 2020 00:00:00 GMT+0530 (India Standard Time)
    } else {  // If today is greater than 5, get 1 month older month name
      previousMonthDate = new Date(year, month - 1, date);
    }
    return monthList[previousMonthDate.getMonth()]; // December
  }

  getGraphAxisLimits(notationType: string, limitType: string): number {
    // Set +50 / -50 limits for left side Y axis (HSFO)
    if (notationType === 'HSFO' && this.sharedService.getHSFOUSDPerMTList() && this.sharedService.getHSFOUSDPerMTList().length) {
      if (limitType === 'MIN') {
        let minimumRoundedValueOfHSFOUSDPerMT =
          (Math.floor(Math.min.apply(Math, this.sharedService.getHSFOUSDPerMTList()))) ?
            ((Math.floor(Math.min.apply(Math, this.sharedService.getHSFOUSDPerMTList()))) - 50)
            :
            this.sharedService.getFinlandHomePageGraphAxisLowerLimit();
        return this.getPreviousRoundedValue(minimumRoundedValueOfHSFOUSDPerMT, 20);
      }
      if (limitType === 'MAX') {
        let maximumRoundedValueOfHSFOUSDPerMT =
          (Math.floor(Math.max.apply(Math, this.sharedService.getHSFOUSDPerMTList()))) ?
            ((Math.floor(Math.max.apply(Math, this.sharedService.getHSFOUSDPerMTList()))) + 50)
            :
            this.sharedService.getFinlandHomePageGraphAxisUpperLimit();
        return this.getNextRoundedValue(maximumRoundedValueOfHSFOUSDPerMT, 20);
      }
    }

    // Set +0.1 / -0.1 limits for right side Y axis (Conversion rate)
    if (notationType === 'CONVERSIONRATE' && this.sharedService.getConversionRateList() && this.sharedService.getConversionRateList().length) {
      if (limitType === 'MIN') {
        let minimumRoundedValueOfConversionRate =
          Math.min.apply(Math, this.sharedService.getConversionRateList()) ?
            ((Math.min.apply(Math, this.sharedService.getConversionRateList())) - 0.1)
            :
            1;
        return (this.getPreviousRoundedValue((+(minimumRoundedValueOfConversionRate.toFixed(2)) * 100), 5) / 100);
      }
      if (limitType === 'MAX') {
        let maximumRoundedValueOfConversionRate =
          Math.max.apply(Math, this.sharedService.getConversionRateList()) ?
            ((Math.max.apply(Math, this.sharedService.getConversionRateList())) + 0.1)
            :
            1.35;
        return (this.getNextRoundedValue((+(maximumRoundedValueOfConversionRate.toFixed(2)) * 100), 5) / 100);
      }
    }
  }

  getNextRoundedValue(numberToBeRounded: number, roundingFactor: number): number {
    let roundedValue: number = 0;
    for (var number = numberToBeRounded; number < Infinity; number++) {
      if ((number % roundingFactor) === 0) {
        roundedValue = number;
        break;
      }
    }
    return roundedValue;
  }

  getPreviousRoundedValue(numberToBeRounded: number, roundingFactor: number): number {
    let roundedValue: number = 0;
    for (var number = numberToBeRounded; number > 0; number--) {
      if ((number % roundingFactor) === 0) {
        roundedValue = number;
        break;
      }
    }
    return roundedValue;
  }

  /* showHideBannerMessage() {
    if (!this.bannerMessageDisplayed && this.checkIfBannerMessageNeedsToBeDisplayed()) {
      this.bannerMessageDisplayed = true;
      let options = {
        autoClose: false,
        keepAfterRouteChange: true
      };
      let bodyMessageList = ['We are actively monitoring the COVID-19 situation and are continually working to help keep those who rely on our platform healthy and safe.'];
      let footerMessageList = ['Go to Uber\'s COVID-19 hub', 'Read about our Door-to-Door Safety Standard'];
      let title = 'Helping to keep each other safe';
      this.alertService.info(title, bodyMessageList, footerMessageList, options);
    } else if (!this.checkIfBannerMessageNeedsToBeDisplayed()) {
      this.bannerMessageDisplayed = false;
      this.alertService.clear();
      console.log("Cleared banner message");
    }
  }

  checkIfBannerMessageNeedsToBeDisplayed() {
    let startTime: string = "15:56:20";
    let endTime: string = "15:56:26";

    let currentDate = this.sharedService.getLatestDateTime();

    let startDate = new Date(currentDate.getTime());
    startDate.setHours(+startTime.split(":")[0]);
    startDate.setMinutes(+startTime.split(":")[1]);
    startDate.setSeconds(+startTime.split(":")[2]);

    let endDate = new Date(currentDate.getTime());
    endDate.setHours(+endTime.split(":")[0]);
    endDate.setMinutes(+endTime.split(":")[1]);
    endDate.setSeconds(+endTime.split(":")[2]);


    if (startDate < currentDate && endDate > currentDate) {
      return true;
    }
    return false;
  }`

  initialisePollingMechanismForBannerMessageDisplay() {
    let test = this;
    setInterval(function () {
      test.showHideBannerMessage();
    }, 1000);
  } */

  // downloadPdf(pdf: string) {
  //   if (pdf) {
  //     const pdfUrl = 'http://132.145.243.109/productInfo/' + pdf;
  //     window.open(pdfUrl, '_blank');
  //   }
  // }

}
