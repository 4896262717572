import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { HistoryComponent } from '../history/history.component';
import { PendingForecastComponent } from '../pending-forecast/pending-forecast.component';
import { OrderManagementComponent } from '../order-management/order-management.component';
import { OrderingProcedureComponent } from '../ordering-procedure/ordering-procedure.component';
import { AppRoutingModule } from '../../app-routing.module';
import { ForecastEntryComponent } from '../forecast-entry/forecast-entry.component';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateService} from '@ngx-translate/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { PendingForecastDialogComponent } from '../pending-forecast-dialog/pending-forecast-dialog.component';
import { GlobalSearchModule } from 'src/app/global-search/global-search.module';
import { OrderEntryValidationsComponent } from '../order-entry/order-entry-validations/order-entry-validations.component';
import { OrderTrackingComponent } from '../order-tracking/order-tracking.component';
import { OrderEntryComponent } from '../order-entry/order-entry.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

export function HttpLoaderFactory (http:HttpClient){
  return new  TranslateHttpLoader(http);
}

@NgModule({
  declarations: [HistoryComponent, PendingForecastComponent, OrderManagementComponent, ForecastEntryComponent,OrderingProcedureComponent, PendingForecastDialogComponent, OrderEntryComponent, OrderTrackingComponent],
  
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatSortModule,
    MatTableModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    HttpClientModule,
    MatCheckboxModule ,
    TranslateModule,
    MatPaginatorModule,
    MatTooltipModule,
    GlobalSearchModule,
    MatDatepickerModule
  ],
  entryComponents: [PendingForecastDialogComponent, OrderEntryValidationsComponent],
})
export class OrderManagementModule { }
