import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppService } from '../shared-modules/services/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  navbarOpen = false;
  selectedItem: String = 'Forecast v/s Actual';
  userInfo: any;
  name : String;
  selectedLanguage: string = 'EN';
  allowedMenuIdList: string[] = [];

  constructor(private sharedService: AppService, private router: Router,public translate:TranslateService,
    private activatedRoute: ActivatedRoute,private titleService: Title
   ) { 
      this.userInfo = this.sharedService.getLoginResponse();
      this.name =  (this.userInfo ? (this.userInfo.FirstName + " "+ this.userInfo.LastName) : '' );
    this.getAllowedMenuIdList(this.userInfo ? this.userInfo.Menus : []);
    translate.addLangs(['EN','FI']);
    translate.setDefaultLang('EN');
   let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/EN|FI/)? browserLang :"EN")

    }

  ngOnInit() {
    this.selectedItem= this.getHeadingFromRoute();
  }


   updateLanguage(selectedLanguage: string) {
     this.selectedLanguage = selectedLanguage;
    this.translate.use(selectedLanguage);
   }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  showSelectedMenuItem(itemName: String) {
    this.selectedItem = itemName;
  }
  getHeadingFromRoute ():string {
    if(this.router.url=='/reports/forecastvsactual')
    return 'Forecast v/s Actual';
    if(this.router.url=='/reports/portalusage')
    return 'Portal usage';
   
}
  
  validateMenu(menuId: string): boolean {
    return this.allowedMenuIdList.includes(menuId);
  }
  getAllowedMenuIdList(menuList: any) {
    if (menuList && menuList.length) {
      for (var menu of menuList) {
        this.allowedMenuIdList.push(menu.MenuID);
      }
    }
  }

}
