import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { QualityCertificateService } from './quality-certificate.service';
import { ProductInformationService } from '../product-management/product-information/product-information.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { AppService } from '../shared-modules/services/shared.service';

@Component({
  selector: 'app-quality-certificate',
  templateUrl: './quality-certificate.component.html',
  styleUrls: ['./quality-certificate.component.css']
})
export class QualityCertificateComponent implements OnInit, OnDestroy {

  dropDownYear: number[] = [];
  currYear: number;
  yearToSearch: any;
  productId: string;
	mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  userInfo: any;
  countryCode: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<QualityCertificateComponent>,
    private qualityCertificateService: QualityCertificateService,
    private dialog: MatDialog,
    private productInformationService: ProductInformationService,
		private changeDetectorRef: ChangeDetectorRef, 
    private media: MediaMatcher,
    private sharedService: AppService ) {
      this.userInfo = this.sharedService.getLoginResponse();
      this.countryCode = this.userInfo.CountryCode;

    if (data && data.productId) {
      this.productId = data.productId;
    }
    //this.dialogRef.updateSize('300vw', '350vw');
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    /* this.years = ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'];
    this.yearToSearch = this.years[5]; */

    const date = this.sharedService.getCurrentDateTime();
    var result = this.getWeekNumber(date);
    this.currYear = result[0];
    this.getYearList(this.currYear);
    this.yearToSearch = this.currYear;
  }

	ngOnDestroy(): void {
	  this.mobileQuery.removeListener(this._mobileQueryListener);
	}

  getQualityCertificate(): void {
    this.qualityCertificateService.getQualityCertificateUrl(this.productId, this.yearToSearch, this.countryCode)
      .subscribe(
        response => {
          this.getQualityCertificateDialog(response);

        });
    this.dialogRef.close(true);
  }

  getQualityCertificateDialog(response: any) {
    if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] &&
      response['ResponseStatus'].toUpperCase() === 'SUCCESS' && response['QualityCertificate']) {
      this.downloadPdf(response['QualityCertificate']);
    } else if (response && response['ResponseCode'] === '201' && response['ResponseStatus'] &&
      response['ResponseStatus'].toUpperCase() === 'ERROR' && response['Description'] === 'No data found for the given input. ') {
      this.openDialog('No quality certificate available for selected year', '', false, 'NQC');
    } else {
      this.openDialog('Failed to retrieve quality certificate', null, false, 'quality-certificate-fail');
    }
  }

  openDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        isSuccess: isSuccess,
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        }
      },
      height: '39%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  cancel(): void {
    this.dialogRef.close(true);
  }

  /* downloadPdf(pdf: string) {
    const pdfUrl = 'http://132.145.243.109/productInfo/' + pdf;
    window.open(pdfUrl, '_blank');
  } */

  downloadPdf(pdf: string) {
    let pdfData;
    // let filepath = "/Shared%20Documents/General/" + pdf
    let filepath = pdf;

    let sharepointData = {
      "Username": "API.Integration@nynas.onmicrosoft.com",
      "Password": "VIN24jkjXXwdjwnvbnas",
      "Domain": "nynas",
      // "SourceFolderPath": "/sites/GroupCustomerPortal",
      "SourceFolderPath" : "/sites/PortalOperationalDocuments",
      "FilePath": filepath.replace(/ /g, "%20")
    }

    this.productInformationService.getSharepointDoc(sharepointData)
      .subscribe(
        res => {
          if (res["ResponseCode"] == "200") {
            pdfData = res["EncodedFileData"]
            const byteArray = new Uint8Array(atob(pdfData).split('').map(char => char.charCodeAt(0)));
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            let fileUrl = window.URL.createObjectURL(blob);
            window.open(fileUrl);
          } else {
            this.openDialog('Sorry! No file available.', null, false,'no-file-available');

          }
        });
  }

  // Display current year and last 3 years only in dropdown
  getYearList(currentYear: number) {
    this.dropDownYear.push(currentYear - 3, currentYear - 2, currentYear - 1, currentYear);
  }

  getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart
    yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }
}
