<div class="container-fluid sidepadding-none">
    <div class="pt-2 col-lg-12 col-md-12 sales_report_div_mv">
        <div class='row no-gutters'>
            <div class="Customer_name_label_div Customer_name_label_div_mv" *ngIf="this.role == 'PA'">
                <label class="sales_report_label" for="year">
                    <span class="input-text-header"> {{'HOME.Customername'| translate}}</span>
                </label>
            </div>
            <div class="Customer_name_dropdown_div Customer_name_dropdown_div_mv" *ngIf="this.role == 'PA'">
                <select class="sales_report_dropdown sales_report_dropdown_mv" id="CustomerId" [(ngModel)]='customerIdUserSelected'>

                    <option value="">{{'HOME.All'| translate}}</option>
                    <option *ngFor="let customer of Customers" [ngValue]="customer.CustomerID">
                        {{customer.CustomerName}}
                    </option>
                </select>
            </div>

            <div class="year_label_div year_label_div_mv">
                <label class="sales_report_label" for="year">
                    <span class="input-text-header"> {{'HOME.Year'| translate}}</span>
                </label>
            </div>
            <div class="year_dropdown_div year_dropdown_div_mv">
                <select class=" sales_report_dropdown input-text" id="Year" [(ngModel)]='yearUserSelected'>
                    <option *ngFor="let year of yearList" [value]="year">{{year}}</option>
                </select>
            </div>

            <div class="month_label_div month_label_div_mv">
                <label class="sales_report_label" for="month">
                    <span class="input-text-header"> {{'HOME.Month'| translate}}</span>
                </label>
            </div>
            <div class="month_dropdown_div month_dropdown_div_mv">
                <select class="sales_report_dropdown month_dropdown_mv input-text" id="Month" [(ngModel)]='monthUserSelected'>
                    <option value="">{{'HOME.All'| translate}}</option>
                    <option value="01"> {{'HOME.Jan'| translate}} </option>
                    <option value="02"> {{'HOME.Feb'| translate}} </option>
                    <option value="03"> {{'HOME.Mar'| translate}} </option>
                    <option value="04"> {{'HOME.Apr'| translate}} </option>
                    <option value="05"> {{'HOME.May'| translate}} </option>
                    <option value="06"> {{'HOME.Jun'| translate}} </option>
                    <option value="07"> {{'HOME.Jul'| translate}} </option>
                    <option value="08"> {{'HOME.Aug'| translate}} </option>
                    <option value="09"> {{'HOME.Sep'| translate}} </option>
                    <option value="10"> {{'HOME.Oct'| translate}} </option>
                    <option value="11"> {{'HOME.Nov'| translate}} </option>
                    <option value="12"> {{'HOME.Dec'| translate}} </option>
                </select>
            </div>

            <div class="find_btn_div  ">
                <button class="find_btn" type="submit" (click)="findSalesReport()">{{'HOME.Find'| translate}}
                </button>
            </div>
        </div>
    </div>

    <div class="col-lg-12 mt-3 sales_report_table_div_mv">
        <mat-table #table [dataSource]="dataSource" matSort class="forecastTable horizontal_scrollbar" (matSortChange)="sortData($event)">

            <ng-container matColumnDef="CUSTOMER_Name">
                <mat-header-cell class="mat-column-large" *matHeaderCellDef mat-sort-header>
                    {{'HOME.Customername'| translate}}
                </mat-header-cell>
                <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-large">
                    {{fetchedDataForItemArray.CUSTOMER_Name}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Month">
                <mat-header-cell class="mat-column-small" *matHeaderCellDef mat-sort-header>
                    {{'HOME.Month'| translate}}
                </mat-header-cell>
                <mat-cell class="mat-column-small" *matCellDef="let fetchedDataForItemArray">
                    {{'HOME.' + fetchedDataForItemArray.Month | translate}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="YEAR">
                <mat-header-cell class="mat-column-small" *matHeaderCellDef mat-sort-header>
                    {{'HOME.Year'| translate}}
                </mat-header-cell>
                <mat-cell class="mat-column-small" *matCellDef="let fetchedDataForItemArray">
                    {{fetchedDataForItemArray.YEAR}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="LastModifiedDate">
                <mat-header-cell class="mat-column-small" *matHeaderCellDef mat-sort-header>
                    {{'HOME.Updatedon'| translate}}
                </mat-header-cell>
                <mat-cell class="mat-column-small" *matCellDef="let fetchedDataForItemArray">
                    {{fetchedDataForItemArray.LastModifiedDate | date: 'dd/MM/yyyy'}}
                    <!-- {{fetchedDataForItemArray.LastModifiedDate}} -->
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="SALES_REPORT">
                <mat-header-cell *matHeaderCellDef class="mat-column-small">
                    {{'HOME.Download'| translate}}
                </mat-header-cell>
                <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-small">
                    <a class='pdf-content' (click)='downloadExcel(fetchedDataForItemArray)'>
                        <span class='font-vsmall font-bold'>
                            {{'HOME.Download'| translate}}
                        </span>
                    </a>
                </mat-cell>
            </ng-container>

            <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
            </mat-header-row>

            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>

    </div>
</div>