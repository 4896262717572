<div class="">

    <form [formGroup]="addDeliveryForm" (ngSubmit)="onSubmit()">


        <!-- <div class="row pl-1 pt-2 ">
            <div class="label"> <span class="font-bold" for="companyName">Company Name: </span></div>
            <div>
                <select class=" form-control input-text font-bold" formControlName="companyName">
                    <option> YIT Kotka IIlaria</option>
                    <option> Nynas Noe2</option>
                    <option> NCC T1</option>
                </select>
            </div>
        </div> -->

        <div class="container container-align">
            <div class="row pt-1 pb-1">
                <div class="col-md-3 ">
                    <div class="form-group">
                        <label for="CustomerId">
                            <span class="input-text-header"> {{'HOME.Customername'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <div *ngIf="!this.selectedDeliverySite && this.isLoggedInUserPortalAdmin">
                            <select class="form-control input-text addUserSelect" formControlName="CustomerId"
                                [ngClass]="{ 'is-invalid': submitted && fields.CustomerId.errors }" id="CustomerId" (change)="getCustomerNameAbbreviation()">
   
                                <option value=""> {{'HOME.Selectcustomername'| translate}}
                                </option>
                                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                                    {{customer.CustomerName}}
                                </option>
                            </select>
                            <div *ngIf="submitted && fields.CustomerId.errors" class="invalid-feedback">
                                <div *ngIf="fields.CustomerId.errors.required">
                                    {{'HOME.Customernameisrequired'| translate}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.selectedDeliverySite || !this.isLoggedInUserPortalAdmin">
                            <input type="text" class="form-control input-text" formControlName="selectedCustomerName"
                                [readonly]="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container container-align ">
            <div class="row pl-1 pt-2">

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <span class="group-header"> {{'HOME.DeliveryInformation'| translate}}
                    </span>
                </div>
                <div class="underline-data"></div>

            </div>



            <div class="row pl-1 pt-4">

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="delivery_site_name">
                            <span class="input-text-header"> {{'HOME.DeliverySiteName'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <!-- <input type="text" class="form-control input-text" formControlName="DeliverySiteName"
                                            [ngClass]="{ 'is-invalid': submitted && fields.DeliverySiteName.errors }"
                                            id="txtDeliverySiteName" placeholder="{{'HOME.DeliverySiteName'| translate}}"
                                            [attr.disabled]="this.selectedDeliverySite" /> -->
                        <span *ngIf="countryCode === 'FIN'">
                            <input type="text" class="form-control input-text" formControlName="DeliverySiteName"
                                [ngClass]="{ 'is-invalid': submitted && fields.DeliverySiteName.errors }" id="txtDeliverySiteNameFin"
                                placeholder="{{'HOME.disclaimerMessage'| translate}}" [attr.disabled]="this.selectedDeliverySite"
                                (input)="checkDeliverySiteNameLength()" />
                            <div *ngIf="submitted && fields.DeliverySiteName.errors" class="invalid-feedback">
                                <div *ngIf="fields.DeliverySiteName.errors.required">
                                    {{'HOME.Deliverysitenameisreq'| translate}}</div>
                            </div>
                        </span>
                        <span *ngIf="countryCode !== 'FIN'">
                            <input type="text" class="form-control input-text" formControlName="DeliverySiteName"
                                [ngClass]="{ 'is-invalid': submitted && fields.DeliverySiteName.errors }" id="txtDeliverySiteNameNonFin"
                                placeholder="{{'HOME.DeliverySiteName'| translate}}" [attr.disabled]="this.selectedDeliverySite"
                                (input)="checkDeliverySiteNameLength()" />
                            <div *ngIf="submitted && fields.DeliverySiteName.errors" class="invalid-feedback">
                                <div *ngIf="fields.DeliverySiteName.errors.required">
                                    {{'HOME.Deliverysitenameisreq'| translate}}</div>
                            </div>
                        </span>
                        <div *ngIf="isDeliverySiteNameMoreThan32Characters" class="delivery-site-name-error">
                            {{'HOME.delivery-site-name-maximum-length-error'| translate}}
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.BillingReference'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="BillingReference"
                            [ngClass]="{ 'is-invalid': submitted && fields.BillingReference.errors }"
                            id="BillingReference" placeholder="{{'HOME.BillingReference'| translate}}" />
                        <div *ngIf="submitted && fields.BillingReference.errors" class="invalid-feedback">
                            <div *ngIf="fields.BillingReference.errors.required">{{'HOME.Billingrefisreq'| translate}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4" *ngIf="isLoggedInUserPortalAdmin && countryCode !== 'GBR'">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.SendForecastReminder'| translate}}</span>
                        </label>
                        <input class="form-control send-forecast-reminder" type="checkbox" formControlName="SendReminderFlag">
                    </div>
                </div>
                           
                <!-- <div class="col-md-2">
                    <div class="form-group">
                        <label for="primary_contact">
                            <span class="input-text-header"> {{'HOME.Primarycontact'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addUserSelect"
                            formControlName="PrimaryContactCountryCode"
                            [ngClass]="{ 'is-invalid': submitted && fields.PrimaryContactCountryCode.errors }"
                            id="primaryContactCountryCode">
                            <option value=""> {{'HOME.Selectcode'| translate}}
                            </option>
                            <option *ngFor="let country of Countries" [value]="country.id">
                                {{ country.id }} (+ {{ country.code }} )
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.PrimaryContactCountryCode.errors" class="invalid-feedback">
                            <div *ngIf="fields.PrimaryContactCountryCode.errors.required">
                                {{'HOME.Countrycodeisrequired'| translate}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="PrimaryContact">
                            <span>&nbsp;</span>
                        </label>
                        <input type="text" class="form-control input-text2" formControlName="PrimaryContact"
                            [ngClass]="{ 'is-invalid': submitted && fields.PrimaryContact.errors }" id="primary_contact"
                            placeholder="{{'HOME.Primarycontact'| translate}}" />
                        <div *ngIf="submitted && fields.PrimaryContact.errors" class="invalid-feedback">
                            <div *ngIf="fields.PrimaryContact.errors.required">
                                {{'HOME.Primarycontactisrequired'| translate}}</div>
                            <div *ngIf="fields.PrimaryContact.errors.pattern">
                                {{'HOME.Pleaseenternumbersonly'| translate}}
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>


            <div class="row pl-1 pt-2">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.PrimaryEmailAddress'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="SubscriberEmailAddress"
                            [ngClass]="{ 'is-invalid': submitted && fields.SubscriberEmailAddress.errors }"
                            id="txtSubscriberEmailAddress" placeholder="{{'HOME.PrimaryEmailAddress'| translate}}" />
                        <div *ngIf="submitted && fields.SubscriberEmailAddress.errors" class="invalid-feedback">
                            <div *ngIf="fields.SubscriberEmailAddress.errors.required">
                                {{'HOME.Emailisrequired'| translate}}
                            </div>
                            <div *ngIf="fields.SubscriberEmailAddress.errors.email">
                                {{'HOME.Emailmustbeavalidemailaddress'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
                    
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="primary_contact">
                            <span class="input-text-header">   {{'HOME.Primarycontact'| translate}}</span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addUserSelect" formControlName="PrimaryContactCountryCode"
                            [ngClass]="{ 'is-invalid': submitted && fields.PrimaryContactCountryCode.errors }"
                            id="primaryContactCountryCode">
                            <option value="">   {{'HOME.Selectcode'| translate}}</option>
                            <option *ngFor="let country of Countries" [value]="country.id">
                                {{ country.id }} (+ {{ country.code }} )
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.PrimaryContactCountryCode.errors" class="invalid-feedback">
                            <div *ngIf="fields.PrimaryContactCountryCode.errors.required">   {{'HOME.Countrycodeisrequired'| translate}}</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="form-group">
                        <label for="PrimaryContact">
                            <span>&nbsp;</span>
                            <!-- <span class="input-text-header">Primary Contact</span>
                        <span class="mandatory-field pl-1">*</span> -->
                        </label>
                        <input type="text" class="form-control input-text2" formControlName="PrimaryContact"
                            [ngClass]="{ 'is-invalid': submitted && fields.PrimaryContact.errors }" id="primary_contact"
                            placeholder="{{'HOME.Primarycontact'| translate}}" />
                        <div *ngIf="submitted && fields.PrimaryContact.errors" class="invalid-feedback">
                            <div *ngIf="fields.PrimaryContact.errors.required">   {{'HOME.Primarycontactisrequired'| translate}}</div>
                            <div *ngIf="fields.PrimaryContact.errors.pattern">   {{'HOME.Pleaseenternumbersonly'| translate}}</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header">{{'HOME.PrimaryTransportationEmail'| translate}}</span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="CarrierEmailAddress"
                            [ngClass]="{ 'is-invalid': submitted && fields.CarrierEmailAddress.errors }"
                            id="txtCarrierEmailAddress" placeholder=" {{'HOME.PrimaryTransportationEmail'| translate}}" />
                        <div *ngIf="submitted && fields.CarrierEmailAddress.errors" class="invalid-feedback">
                            <div *ngIf="fields.CarrierEmailAddress.errors.required">
                                {{'HOME.Emailisrequired'| translate}}</div>
                            <div *ngIf="fields.CarrierEmailAddress.errors.email">
                                {{'HOME.Emailmustbeavalidemailaddress'| translate}}</div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <!-- CONTACT DETAILS -->
        <div class="container container-align">
            <div class="row pl-1 pt-2">
                <div class="col-sm">
                    <span class="group-header"> {{'HOME.Deliverysiteaddress'| translate}}
                    </span>
                </div>
                <div class="underline-data"></div>

            </div>
            <div class="row pl-1 pt-4">

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="address_line1">
                            <span class="input-text-header"> {{'HOME.Address'| translate}}</span>
                            <span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="AddressLine1"
                            [ngClass]="{ 'is-invalid': submitted && fields.AddressLine1.errors }" id="address_line1"
                            placeholder="{{'HOME.AddressLine1'| translate}}" />
                        <div *ngIf="submitted && fields.AddressLine1.errors" class="invalid-feedback">
                            <div *ngIf="fields.AddressLine1.errors.required"> {{'HOME.AddressLinereq'| translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="address_line2">
                            <span>&nbsp;</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="AddressLine2"
                            id="address_line2" placeholder=" {{'HOME.AddressLine2'| translate}}" />
                    </div>
                </div>
            </div>

            <div class="row pl-1 pt-2">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header">{{'HOME.DeliveryPostalCode'| translate}}</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="DeliveryPostalCode"
                            [ngClass]="{ 'is-invalid': submitted && fields.DeliveryPostalCode.errors }"
                            placeholder="{{'HOME.DeliveryPostalCode'| translate}}"/>
                        <div *ngIf="submitted && fields.DeliveryPostalCode.errors" class="invalid-feedback">
                            <div *ngIf="fields.DeliveryPostalCode.errors.required">
                                {{'HOME.DeliveryPostalCodeIsrequired'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.City'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="City"
                            [ngClass]="{ 'is-invalid': submitted && fields.City.errors }"
                            placeholder="{{'HOME.City'| translate}}" />
                        <div *ngIf="submitted && fields.City.errors" class="invalid-feedback">
                            <div *ngIf="fields.City.errors.required"> {{'HOME.Cityisrequired'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.Country'| translate}}</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="custom-select input-text" formControlName="CountryId"
                            [ngClass]="{ 'is-invalid': submitted && fields.CountryId.errors }" (change)="changeCountryName($event)"
                            placeholder="Country">
                            <option value=""> {{'HOME.Selectcountry'| translate}}</option>
                            <option *ngFor="let country of Countries" [value]="country.id">
                                <!-- {{country.name}} -->
                                {{'HOME.' + country.name | translate}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.CountryId.errors" class="invalid-feedback">
                            <div *ngIf="fields.CountryId.errors.required"> {{'HOME.Countryisrequired'| translate}}</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-3">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header">Delivery Post Office</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="delivery_post_office"
                            [ngClass]="{ 'is-invalid': submitted && fields.delivery_post_office.errors }"
                            placeholder="Delivery Post Office" />
                        <div *ngIf="submitted && fields.delivery_post_office.errors" class="invalid-feedback">
                            <div *ngIf="fields.delivery_post_office.errors.required">Delivery Post Office is required
                            </div>
                            <div *ngIf="fields.delivery_post_office.errors.delivery_post_office">Delivery Post Office
                                must be valid</div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <!-- <div class="container container-align" *ngIf="this.isLoggedInUserPortalAdmin">
            <div class="row pl-1 pt-2">
                <div class="underline-data"></div>
            </div>
            <div class="row pl-1 pt-4">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header">Status</span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="custom-select input-text" formControlName="Status"
                            [ngClass]="{ 'is-invalid': submitted && fields.Status.errors }" placeholder="Status">
                            <option *ngFor="let status of statusList" [value]="status.code">
                                {{status.description}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.Status.errors" class="invalid-feedback">
                            <div *ngIf="fields.Status.errors.required">Status is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header">Status</span>
                            <span class="mandatory-field pl-1">*</span>
                        </label>
                        <div class="form-check">
                            <input class="form-check-input position-static" type="radio" formControlName="Status"
                                value="Approved">
                            <span class="radio-button-text"> {{'HOME.Approved'| translate}}
                            </span>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input position-static" type="radio" formControlName="Status"
                                value="Rejected">
                            <span class="radio-button-text"> {{'HOME.Rejected'| translate}}
                            </span>
                        </div>
                    </div>
        
                </div>
            </div>
        </div> -->


    </form>
    <!--</form? -->

    <br><br>
    <!-- <div class="row"> -->
    <!-- <div class="col-lg-12"> -->
    <div class="row buttonGroup">
        <!-- <div class="col-lg-3">
                    <button id="submit" name="submit" class="btn btn-primary saveBtn 
                                action-button" (click)="saveUserDetails()">Save</button>
                </div> -->
        <button class="forecastSaveButton" type="submit" (click)="saveDeliverySiteDetails()">
            {{'HOME.Submit'| translate}}
        </button>
        <button class="forecastCancelButton" id="submit" name="submit" (click)="cancelDeliverySiteDetails()">
            {{'HOME.Cancel'| translate}}
        </button>

        <!-- </div> -->
        <!-- </div> -->
    </div>



    <!-- </div> -->

</div>
