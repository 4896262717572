import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient) {
  }
  getUpdates(countryCode) {
    const url = this.apiUrl + '/GetNynasUpdates';
    return this.httpClient
      .get(url, {
        params: {
          CountryCode: countryCode
        }
      });
  }

  // Old service not to be used
  /* getLineChartData() {
    let url = this.apiUrl + '/GetPriceIndexAndConversionRate';
    return this.httpClient
      .get(url, {
        params: {
          Type: 'DAILY'
        }
      });
  } */

  getLineChartData(uom: string) {
    let url = this.apiUrl + '/GetPriceIndexAndConversionRate';
    return this.httpClient
      .get(url, {
        params: {
          Type: 'DAILY',
          UOM: uom
        }
      });
  }

  getBarChartData(Year: string, CustomerID: string , DeliverySite: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetPlannedVSActualForAll';
    return this.httpClient
      .get(url, {
        params: {
          Year: Year,
          CustomerID: CustomerID,
          DeliverySite: DeliverySite,
          JDEAddressNumber: jdeAddressNumber
        }
      });
  }

  getSharepointDoc(data) {
    let postData = JSON.stringify(data)
    try {
      let productSharepointUrl = this.apiUrl + '/ReadFileDataFromSharepointService';
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        })
      };
      return this.httpClient
        .post(productSharepointUrl, postData, httpOptions);
    } catch (error) {
      throw new Error(error);
    }
  }

  fetchAveragePriceRate(uom: string) {
    let url = this.apiUrl + '/FetchAveragePriceRate';
    return this.httpClient
      .get(url, {
        params: {
          UOM: uom
        }
      });
  }
  
}
