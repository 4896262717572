import { MediaMatcher } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { AppService } from '../../shared-modules/services/shared.service';
import { DialogBodyComponent } from '../../user-management/dialog-body/dialog-body.component';
import { OrderTrackingService } from '../order-tracking/order-tracking.service';
import { OrderEntryValidationsComponent } from './order-entry-validations/order-entry-validations.component';
import { OrderEntryService } from './order-entry.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YY',
  },
  display: {
    dateInput: 'DD-MMM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
}
@Component({
  selector: 'app-order-entry',
  templateUrl: './order-entry.component.html',
  styleUrls: ['./order-entry.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class OrderEntryComponent implements OnInit {
  addOrderForm: FormGroup;
  submitted = false;
  userInfo: any;
  username: any;
  UserID: any;
  userID: any;
  customerID: any;
  deliverySiteUserSelected: string;
  role: any;
  customerName: string;
  isLoggedInUserAdmin: boolean;
  isLoggedInUserPortalAdmin: boolean;
  isLoggedInUserCustomerUser: boolean;
  loggedInUserId: string;
  loggedInUserName: string;
  countryCode: any;
  customerList: any;
  delSiteList: any;
  productList: any;
  depoList:any;
  // pcCodeList:any;
  uomList:any;
  NynasItemCollectionList: any;
  NynasItemCollectionListEdit: any;
  NynasItemCollectionListReject: any;
  NynasItemCollectionListChange: any;
  jdeAddNum: string;
  productId:string;
  refNumber : string;
  mobileQuery: MediaQueryList;
  productQuantityFlag: boolean = false;
  isRateValueInvalid: boolean;
  receivedConfirmationFromUser: boolean;
  todayDate: Date;
  timeFlag:boolean = false;
  timeFlagLatest:boolean = false;
  timeFlagEarliest:boolean = false;
  
  dateFlag:boolean;
  routeFlag:boolean;
  rowDataFromRoute:any;
  orderStatus:string;
  orderStatusService:string;
  erroMsg:string;
  source:string;
  disableFields:boolean;
  productQuantityWarning:string;
  private _mobileQueryListener: () => void;
  timeHourList: any = [];
  defaultEarliestTimeValue: string;
  defaultLatestTimeValue: string;
  hasDeliveryTimeElapsed: boolean;
  isSameDayDelivery: boolean;
  minimumTimeValueInTimeHourDropdown: number;
  maximumTimeValueInTimeHourDropdown: number;
  isQuantityLessThan14: boolean;
  isQuantityEqualToOrMoreThan29: boolean;
  validationMessageList: any = [];
  @ViewChild('picker', {static: true}) picker;
  isRequestLocked: boolean;
  lockedStatus: string;
  isOrdersAwaitingApprovalTableRow: boolean;
  isRequestedDateOfToday: boolean;
  requestLockedMessage: string = 'This request is in use by another user, please try again later';

  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>> = new EventEmitter();
  lockedByUser: string;
  lockedOn: string;
  hasLoggedInUserLockedThisRequest: boolean;
  loggedInUserLockedRequestMessage: string = 'You have now locked this request for changes. Please click the below close button if no changes are required.';
  isOrderBookFull: boolean;
  orderFullDate: string;

  constructor(private formBuilder: FormBuilder,private router :Router, private dialog: MatDialog, private media: MediaMatcher,private datePipe: DatePipe,
    private orderEntrySer: OrderEntryService, private changeDetectorRef: ChangeDetectorRef, private sharedService: AppService, private ordertrackService: OrderTrackingService) {

    this.userInfo = this.sharedService.getLoginResponse();
    this.username = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.UserID = this.userInfo.EmailID;
    this.userID = (this.userInfo ? this.userInfo.UserID : '');
    this.role = (this.userInfo ? this.userInfo.RoleID : '');
    this.customerID = (this.userInfo ? this.userInfo.CustomerID : '');
    this.customerName = (this.userInfo ? this.userInfo.CustomerName : '');
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.isLoggedInUserPortalAdmin = (this.userInfo ? (this.userInfo.RoleID === 'PA') : false);
    this.isLoggedInUserCustomerUser = (this.userInfo ? (this.userInfo.RoleID === 'U') : false);
    this.loggedInUserId = (this.userInfo ? this.userInfo.UserID : '');
    this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + ' ' + this.userInfo.LastName) : '');
    this.countryCode = this.userInfo.CountryCode;

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state as {
        rowData: any;
        source: string;
        isRequestLocked: boolean;
        lockedStatus: string;
        isOrdersAwaitingApprovalTableRow: boolean;
        lockedByUser: string;
        lockedOn: string;
        hasLoggedInUserLockedThisRequest: boolean;
      };
      if (state && state.rowData) {
        // this.customerID = state.rowData['CustomerID'];
        this.routeFlag = true;
        this.rowDataFromRoute = state.rowData;
        this.source = state.source;
        this.isRequestLocked = state.isRequestLocked;
        this.lockedStatus = state.lockedStatus;
        this.isOrdersAwaitingApprovalTableRow = state.isOrdersAwaitingApprovalTableRow;
        this.lockedByUser = state.lockedByUser;
        this.lockedOn = state.lockedOn;
        this.hasLoggedInUserLockedThisRequest = state.hasLoggedInUserLockedThisRequest;
        if (this.isRequestLocked) {
          this.requestLockedMessage = 'This request is locked by user - ' + this.lockedByUser + ' from ' + this.lockedOn + ' , please try again later.';
        }
      }

    }
    console.log('checking row Data' + JSON.stringify(this.rowDataFromRoute) + this.source);
  }

  

  async ngOnInit() {

    this.addOrderForm = this.formBuilder.group({
      CustomerId: ['', Validators.required],
      requestedDate: ['', Validators.required],
      delsite: ['', Validators.required],
      delSiteEdit:[''],
      prodEdit:[''],
      reqDateEdit:[''],
      ShipToID: [''],
      customerPO: [''],
      latestTime: ['', Validators.required],
      earliestTime: ['', Validators.required],
      depoName:['',Validators.required],
      reqStatus:[''],
      reqStatusDesc:[''],
      orderJDE:[''],
      portalRefNumber:[''],
      orderType:[''],
      frCode:[''],
      // pcCode:['',Validators.required],
      productName: ['', Validators.required],
      productQuantity: ['', Validators.required],
      productUOM: ['TM',Validators.required],
      uomEdit:[''],
      delsiteComments: [''],
      custComments: [''],
      rComments: [''],
      CountryId: [''],
      UpdatedBy: [''],
      CreatedBy: [''],
      CreatedOn: [''],
      UpdatedOn: [''],
      Time: ['']
    });

   
    try {
      await this.getCustomers();
    } catch (e) {
    }
    this.addOrderForm.controls['CustomerId'].setValue(this.customerID);
   if(!this.routeFlag){
    
     // Fetch all delivery site of customer for portal admin and customer admin; while for cusotmer user, fetch delivery sites mapped to user only
     if (this.isLoggedInUserAdmin) {
       try {
         await this.getDeliverySites();
       } catch (e) {
       }
     }
     else if (this.isLoggedInUserCustomerUser) {
       try {
         await this.getDeliverySitesForUSER();
       }
       catch (e) {
       }
     }
  
  }
   else{
     
    try {
      await  this.editForm();
    } catch (e) {
    }
    try {
      await this.getDepotDetails();
    } catch (e) {
    }
    this.addOrderForm.controls['CustomerId'].setValue(this.rowDataFromRoute['CustomerID']);
   }
   try {
    await this.getUOM();
  } catch (e) {
  }
  this.todayDate = new Date();
    this.todayDate.setDate(this.todayDate.getDate());
    this.todayDate.setHours(0,0,0,0);
    if(this.source === 'order' || (this.rowDataFromRoute && this.rowDataFromRoute['Status'] === "03") ){
      this.disableFields = true;
      //this.addOrderForm.disable();
    } else {
      this.disableFields = false;
    }
    if(this.role ==="U" || this.role ==="A"){
      if(this.rowDataFromRoute){
        if(this.rowDataFromRoute['Status'] == "04" || this.rowDataFromRoute['Status'] == "05" || this.rowDataFromRoute['Status'] == "06"){
     
        this.addOrderForm.disable();
        // this.addOrderForm.controls['custComments'].enable();
        // this.addOrderForm.controls['delsiteComments'].enable();
      }
     }
    }

    // Get minimum, maximum and default values of time in Earliest and Latest Time hour dropdowns
    this.loadEarliestAndLatestTimeHourValues(false);

  }

  ngOnDestroy(): void {
    if (this.hasLoggedInUserLockedThisRequest) {
      this.unlockOrder();
    }
  }
  
  async getCustomers() {
    this.customerList = [];
    return new Promise<void>((resolve, reject) => {

      this.orderEntrySer.getCustomerNames(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {

            this.customerList = res["Customers"]

            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  async getDeliverySitesForUSER() {
    this.delSiteList = [];
    this.deliverySiteUserSelected = '';
    return new Promise<void>((resolve, reject) => {

      this.orderEntrySer.getDeliverySitesForUSER(this.loggedInUserId, this.customerID, this.countryCode,).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
          
            //this.delSiteList = res["DeliverySiteCollection"];
            var DeliverySiteCollection1 = [];
            //  this.deliverySiteList= res["DeliverySites"];
           
            for (let deliverySite of res["DeliverySites"]) {
  
              let deliverySiteObj = {};
              deliverySiteObj['DeliverySite'] = deliverySite['DeliverySiteName'];
              deliverySiteObj['JdeAddressNumber'] = deliverySite['JDE_Address_Number'];
              deliverySiteObj['AddressLine1'] = deliverySite['AddressLine1'];
              deliverySiteObj['AddressLine2'] = deliverySite['AddressLine2'];
              deliverySiteObj['DeliveryPostalCode'] = deliverySite['DeliveryPostalCode'];
              deliverySiteObj['City'] = deliverySite['City'];
              DeliverySiteCollection1.push(deliverySiteObj);
            }

            this.delSiteList = DeliverySiteCollection1;

            // this.deliverySiteUserSelected = '';
            resolve();
          }
          else if (res["ResponseCode"] === "201") {
            // reject();
            this.deliverySiteUserSelected = '';
          }
          else {
            reject();
          }
        });
    })

  }
  async productServiceCheck(){

    // Commented old code, kept for future reference
    /* if(this.isLoggedInUserAdmin){
      try {
      await this.getProductDetails();
    } catch (e) {

    }

    }else {
      try {
      await this.getProductDetailsFORUSER();
    }
    catch (e) {
    }
    } */


    // Get products mapped to a delivery site only, for all roles
    try {
      await this.getProductDetailsFORUSER();
    }
    catch (e) {
    }

  }
   async getCustPO(delsiteName :string){
    //console.log("testing delsite Name" + delsiteName);
       this.getJDEAddressNumberByDeliverySiteName(delsiteName);
     // console.log("testing jde number" + this.jdeAddNum);
      this.orderEntrySer.getCustomerPO(this.jdeAddNum).subscribe(
        res => {
          this.addOrderForm.controls['customerPO'].setValue(res['CustomerPO']);
        });
  }
  getOrderType(): void {
    //console.log('testing depoId' + id);

    this.orderEntrySer.fetchOrderType(this.countryCode,this.addOrderForm.value['frCode'],this.addOrderForm.value['depoName']).subscribe(
      res => {
      // console.log(res);
       this.addOrderForm.controls['orderType'].setValue(res['OrderType']);
      });
   }
  async getProductDetailsFORUSER() {
    this.productList= [];
    this.getJDEAddressNumberByDeliverySiteName(this.addOrderForm.value['delsite']);
    // console.log("testJDE add" + this.jdeAddNum);
    this.orderEntrySer.getProductInformationFORUSER(this.customerID,this.countryCode,this.jdeAddNum)
      .subscribe(
        res => {
          if (res["ResponseCode"] == 200) {
            var ProductListCollection = [];
            for(let productList of res['DeliverySite'][0]['Products']){
            if(productList['Status'] === 'ACTIVE') {
            let productListObj = {};
             productListObj['PRODUCT_ID'] =productList['ProductId'];
             productListObj['PRODUCT_NAME'] =productList['ProductName'];
             ProductListCollection.push(productListObj);
            }
            
          }
          this.productList = ProductListCollection;
        }
        });
  }


  async getDeliverySites() {
    this.delSiteList = [];
    this.deliverySiteUserSelected = '';
    return new Promise<void>((resolve, reject) => {

      this.orderEntrySer.getDeliverySites(this.customerID, this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
          
            this.delSiteList = res["DeliverySiteCollection"];
            
            resolve();
          }
          else {
            reject();
          }
        });
    })

  }
  get fields() {
    return this.addOrderForm.controls;
  }

  async getProductDetails() {
    this.productList= [];
    this.orderEntrySer.getProductInformation(this.customerID, this.countryCode)
      .subscribe(
        res => {
          if (res["ResponseCode"] == 200) {
            this.productList = res['GetAllProductResponses'];
            // for (let product of this.productList) {
            //   this.productId = product['PRODUCT_ID'];
            // }
          }
        });
  }
  
  async getDepotDetails() {
    this.depoList = [];
    this.orderEntrySer.getDepotDetails(this.countryCode).subscribe(
      response => {
        if (response["ResponseCode"] == 200) {

          // Commented below code, kept for future reference
          //  this.depoList = res["DepotCollection"];

          // Filter test depots from UI - Hull Depot UK / LBC, Antwerp Depot BE / Harburg Refinery - Nynas / Setup only Depot UK / Setup only Dummy Depot UK
          for (let depot of response["DepotCollection"]) {
            if (depot['DepotID'] && ['1036', '1038', '10635', '99999', '99940'].indexOf(depot['DepotID']) === -1) {
              this.depoList.push(depot);
            }
          }
        }
      });
  }
  
  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string) {
      for (let deliverySiteObject of this.delSiteList) {
        if (deliverySiteObject) {
          let currentDeliverySiteName = (deliverySiteObject['DeliverySite'] || deliverySiteObject['DeliverySiteName']);
          if (currentDeliverySiteName === deliverySiteName) {
            this.jdeAddNum = (deliverySiteObject['JDE_Address_Number'] || deliverySiteObject['JdeAddressNumber']);
            break;
          }
        }
      }
    
  }

  getProductId(productName: string) {
    if(this.productList){
      for (let productObject of this.productList) {
        if (productObject) {
          let currentproductName =  productObject['PRODUCT_NAME'];
          if (currentproductName === productName) {
            this.productId = (productObject['PRODUCT_ID']);
            break;
          }
        }
      }
    }
}
// async getPCCode(){
//   this.pcCodeList = [];
//   this.orderEntrySer.getPCCode(this.countryCode).subscribe(
//     res => {
//       if (res["ResponseCode"] == 200) {
//         this.pcCodeList= res["PC2CodeCollection"];
       
//       }
//     });

// }
async getUOM(){
  this.uomList = [];
  this.orderEntrySer.getUOM(this.countryCode).subscribe(
    res => {
      if (res["ResponseCode"] == 200) {
        this.uomList= res["UOMCollection"];
       //console.log("test list" + JSON.stringify(this.uomList));
       if(this.rowDataFromRoute){
        for( let uom of this.uomList ){
          if(this.rowDataFromRoute['UOM'] == uom.UOM){
           this.addOrderForm.controls['uomEdit'].setValue(uom.UOMDescription);
          }
        }
       }
      
      }
    });

}

  isInValidDecimalNumber(quantity: number): boolean {
    let regex: RegExp = new RegExp('^[0-9]+(.[0-9]{0,3})?$');
    return (quantity && !String(quantity).match(regex))
  }
  async editForm(){
    
   this.customerID = this.addOrderForm.controls['CustomerId'].setValue(this.rowDataFromRoute['CustomerID']);
   this.addOrderForm.controls['delsite'].setValue(this.rowDataFromRoute['DeliverySite']);
   this.addOrderForm.controls['delSiteEdit'].setValue(this.rowDataFromRoute['DeliverySite']);
   this.addOrderForm.controls['reqDateEdit'].setValue(this.rowDataFromRoute['RequestedDate']) ;
   this.addOrderForm.controls['productName'].setValue(this.rowDataFromRoute['ProductName']);
   this.productId = this.rowDataFromRoute['ProductId'];
   this.orderStatus= this.rowDataFromRoute['Status'];
   this.orderStatusService= this.rowDataFromRoute['OrderStatus'];
   this.addOrderForm.controls['prodEdit'].setValue(this.rowDataFromRoute['ProductName']);
   this.addOrderForm.controls['customerPO'].setValue(this.rowDataFromRoute['CustomerPO']);
   this.addOrderForm.controls['latestTime'].setValue(this.rowDataFromRoute['LatestArrivalTime']);
   this.addOrderForm.controls['earliestTime'].setValue(this.rowDataFromRoute['EarliestArrivalTime']);
   this.jdeAddNum = this.rowDataFromRoute['JDEAddressNumber'];
   this.addOrderForm.controls['ShipToID'].setValue(this.jdeAddNum); 
   this.addOrderForm.controls['productQuantity'].setValue(this.rowDataFromRoute['Quantity']);
   this.addOrderForm.controls['requestedDate'].setValue((new Date(this.rowDataFromRoute['RequestedDate'])));
   this.addOrderForm.controls['reqStatusDesc'].setValue(this.rowDataFromRoute['StatusDescription']);
   this.addOrderForm.controls['reqStatus'].setValue(this.rowDataFromRoute['OrderFlag']);  
   this.addOrderForm.controls['orderJDE'].setValue(this.rowDataFromRoute['OrderNumber']);
   this.addOrderForm.controls['portalRefNumber'].setValue(this.rowDataFromRoute['ReferenceNumber']);
   this.addOrderForm.controls['orderType'].setValue(this.rowDataFromRoute['OrderType']);
   this.addOrderForm.controls['frCode'].setValue(this.rowDataFromRoute['FreightCode']);
   this.addOrderForm.controls['delsiteComments'].setValue(this.rowDataFromRoute['DeliveryInstructionsLine1']);
   this.addOrderForm.controls['custComments'].setValue(this.rowDataFromRoute['DeliveryInstructionsLine2']);
   this.addOrderForm.controls['rComments'].setValue(this.rowDataFromRoute['Information']);
   this.addOrderForm.controls['CountryId'].setValue(this.countryCode);
   if(this.rowDataFromRoute['UpdatedOn']){
    this.addOrderForm.controls['UpdatedOn'].setValue((this.rowDataFromRoute['UpdatedOn']));
   }else {
    this.addOrderForm.controls['UpdatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
   }
   this.addOrderForm.controls['UpdatedBy'].setValue(this.loggedInUserName);
   this.addOrderForm.controls['CreatedBy'].setValue(this.rowDataFromRoute['CreatedBy']);
   this.addOrderForm.controls['CreatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
  //  this.addOrderForm.controls['pcCode'].setValue(this.rowDataFromRoute['PC2Code']);
   this.addOrderForm.controls['depoName'].setValue(this.rowDataFromRoute['DepotId']);
   this.getCustomers();
   this.getUOM();
   this.addOrderForm.controls['productUOM'].setValue(this.rowDataFromRoute['UOM']);
   //console.log("test PC code" + this.addOrderForm.value['productUOM']);
   this.getProductId(this.rowDataFromRoute['ProductName']);
  //  this.getUOM();
   this.getJDEAddressNumberByDeliverySiteName(this.rowDataFromRoute['DeliverySite']);
  }
  async saveOrderDetails() {

    this.submitted = true;

    if((this.source === 'request' && this.orderStatus !== "03" )|| !this.routeFlag ){
      
    if(!this.isLoggedInUserPortalAdmin){
      //As per new request validation not neccesary for Nynas admins (CSC Team) - CHG0054427
      // Validate time in all cases
      try {
        await this.validateRequestedDateTime();
      } catch (e) {
      }

      try {
        await this.validateRequestedDate();
      } catch (e) {
      }
    }
      /* this.hasDeliveryTimeElapsed = false;
      this.isSameDayDelivery = false;
      if (this.isDeliveryRequestDateOfToday()) {
        this.isSameDayDelivery = true;
        try {
          await this.checkIfDeliveryTimeHasElapsed();
        } catch (e) {
        }
      } */
      this.isRateValueInvalid = (this.isInValidDecimalNumber(this.addOrderForm.value.productQuantity));

    // Commented old code, kept for future reference
      /* if (this.addOrderForm.value['productQuantity'] < 13) {
        this.productQuantityFlag = true;
        this.productQuantityWarning = 'productlessthan13';
      } */
    
    if(this.addOrderForm.value['productQuantity'] > 29){
      
      this.productQuantityFlag = true;
      this.productQuantityWarning = 'productmorethan29';
    }else{
      this.productQuantityFlag = false;
      this.productQuantityWarning = '';
    }
    if(!this.routeFlag ){
      this.getJDEAddressNumberByDeliverySiteName(this.addOrderForm.value['delsite']);
      this.getProductId(this.addOrderForm.value['productName']);
      this.addOrderForm.controls['ShipToID'].setValue(this.jdeAddNum); 
      this.addOrderForm.controls['requestedDate'].setValue(new Date(this.addOrderForm.value.requestedDate));
      //this.addOrderForm.controls['requestedDate'].setValue(this.datePipe.transform(this.addOrderForm.value.requestedDate, 'yyyy-MM-dd' , 'UTC'));
    }
    if(parseInt(this.addOrderForm.value.latestTime) >24 ){ 
      this.timeFlagLatest = true;
      this.addOrderForm.setErrors({ 'invalid': true });
      this.addOrderForm.invalid;
    }else if(parseInt(this.addOrderForm.value.latestTime) <1){ 
      this.timeFlagLatest = true;
      this.addOrderForm.setErrors({ 'invalid': true });
      this.addOrderForm.invalid;
    }else{
      this.timeFlagLatest = false;
    }

    if(parseInt(this.addOrderForm.value.earliestTime) <1){ 
      this.timeFlagEarliest = true;
      this.addOrderForm.setErrors({ 'invalid': true });
      this.addOrderForm.invalid;
    }else{
      this.timeFlagEarliest = false;
    } 

    if(parseInt(this.addOrderForm.value.latestTime) <= parseInt(this.addOrderForm.value.earliestTime)){
      this.timeFlag = true;
      this.addOrderForm.setErrors({ 'invalid': true });
      this.addOrderForm.invalid;
    }else{
      this.timeFlag = false;
    }
    if(this.addOrderForm.controls['requestedDate'].value < this.todayDate){
      this.dateFlag = true;
    }else{
      this.dateFlag = false;
    }
    if(this.isLoggedInUserPortalAdmin && this.routeFlag){
      this.addOrderForm.controls['depoName'].setValidators([Validators.required]);
      this.addOrderForm.controls['depoName'].updateValueAndValidity();
      // this.addOrderForm.invalid;
      }
      else{
        this.addOrderForm.controls['depoName'].setValidators([]);
      this.addOrderForm.controls['depoName'].updateValueAndValidity();
      }

      this.checkProductQuantityValue();
      if (this.addOrderForm.invalid || this.isRateValueInvalid || this.isQuantityLessThan14 || this.isQuantityEqualToOrMoreThan29) {
        this.openSaveUserDialog('There are items that require your attention', 'Please complete all fields marked with *', false, 'required-fields-validation-message');
        return;

      }
}
  
    this.addOrderForm.controls['CountryId'].setValue(this.countryCode);
    this.addOrderForm.controls['UpdatedBy'].setValue(this.loggedInUserName);
    this.addOrderForm.controls['CreatedBy'].setValue((this.rowDataFromRoute && this.rowDataFromRoute['CreatedBy']) ? this.rowDataFromRoute['CreatedBy'] : this.loggedInUserName);
    this.addOrderForm.controls['UpdatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
    this.addOrderForm.controls['CreatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
    this.showAlertMessageForCreateOrder();
  }
  openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message,
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,

      },
      height: (this.mobileQuery.matches ? '55%' : '40%'),
      // height: (this.mobileQuery.matches ? '37%' : '88%'),
      width: (this.mobileQuery.matches ? '88%' : '27%'),
    });
   
  }
  openSaveOrderDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string,refNum:string) {
    const dialogRef= this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        messagenumber: message,
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,
        refNum: refNum
      },
      height: (this.mobileQuery.matches ? '55%' : '50%'),
      // height: (this.mobileQuery.matches ? '37%' : '88%'),
      width: (this.mobileQuery.matches ? '88%' : '27%'),
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.redirectToOrderTrackingPageWithPreviouslySelectedCustomer();
      }
    });
  }

  showAlertMessageForCreateOrder() {
    let messages: any = [], footerMessage: string;

    // Commented old code, kept for future reference
    /* if (this.productQuantityFlag) {
      messages.push({"type": "warning", "text": "Product quantity is more than 29", "linkedMessage": false, hasChild: false});
    } */

    // if (this.hasDeliveryTimeElapsed) {
    //   messages.push({"type": "warning", "text": "Delivery request time already lapsed", "linkedMessage": false, hasChild: false});
    // }
    // if (this.isSameDayDelivery) {

    //   /* messages.push({"type": "warning", "text": "You are requesting an order for todays date.", "linkedMessage": true, "hasChild": true});
    //   messages.push({"type": "", "text": "If you require a same day order, please call us on 0330 123 0066.", "linkedMessage": false}); */

    //   messages.push({"type": "warning", "text": ["You are requesting an order for todays date.", "If you require a same day order, please call us on 0330 123 0066."], "linkedMessage": true, hasChild: true});

    // }

    if (this.routeFlag && (this.isLoggedInUserPortalAdmin && this.rowDataFromRoute['ReferenceNumber'])) {
      footerMessage = "Are you sure you want to Approve this Order?";
    } else {
      footerMessage = "Are you sure you want to submit this order?";
    }
    const dialogRef = this.dialog.open(OrderEntryValidationsComponent, {
      data: {
        messages: this.validationMessageList,
        footerMessage: footerMessage,
        hideOkButton: (this.validationMessageList && this.validationMessageList.length)
      },
      height: 'auto',
      width: (this.mobileQuery.matches ? 'auto' : '37%'),
      // width: 'auto'
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.receivedConfirmationFromUser = true;
        if (!this.routeFlag) {
          this.createOrder();
        }
        else {
          this.editOrderApprove();
        }
      }
    });
  }

  // Commented old code, kept for future reference
  /* showAlertMessageForCreateOrder() {
    let deliveryTimeElapsedMessage = 'Deliveryrequesttimealreadylapsed', sameDayDeliveryWarningMessage = 'ForsamedayorderdeliverypleasecallCSCteam';
    const dialogRef = this.dialog.open(DialogBodyComponent, {
      data: {
        headerNumber: this.routeFlag && (this.isLoggedInUserPortalAdmin && this.rowDataFromRoute['ReferenceNumber']) ? 'approve-order-detail' : 'confirmation-order-detail',
        message: this.productQuantityFlag ? this.productQuantityWarning : '',
        messagenumber: (this.hasDeliveryTimeElapsed ? deliveryTimeElapsedMessage : (this.isSameDayDelivery ? sameDayDeliveryWarningMessage : '')),
        buttonText: {
          ok: 'Ok'
        },
        isWarning: true,
      },
      //  height: (this.mobileQuery.matches ? '55%' : '50%'),
      height: 'auto',
      // height: (this.mobileQuery.matches ? '37%' : '88%'),
      // width: (this.mobileQuery.matches ? '88%' : '27%'),
      width: (this.mobileQuery.matches ? '88%' : '28%'),
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.receivedConfirmationFromUser = true;
        if(!this.routeFlag){
        this.createOrder();
        }
        else{
          this.editOrderApprove();
        }
      }
    });
  } */

  createOrder() {

   this.NynasItemCollectionList = [];
   let NynasItemCollection = {};
   NynasItemCollection['ItemDescription'] = this.productId;
   NynasItemCollection['Quantity'] = this.addOrderForm.value['productQuantity'];
   NynasItemCollection['QuantityUOM'] = this.addOrderForm.value['productUOM'];

  // Commented below code, kept for future reference
   /* NynasItemCollection['RequestedDate'] = this.addOrderForm.value['requestedDate'];
   var dateVarCreate = new Date(this.addOrderForm.value['requestedDate']);
   dateVarCreate.setDate(dateVarCreate.getDate() + 1);
    NynasItemCollection['RequestedDate'] = dateVarCreate; */

    NynasItemCollection['RequestedDate'] = this.getRequestedDateInProperFormat();

   NynasItemCollection['EarliestArrivalTime'] = this.addOrderForm.value['earliestTime'];
   NynasItemCollection['LatestArrivalTime'] = this.addOrderForm.value['latestTime'];
   NynasItemCollection['LoadingPoint'] = "";
   NynasItemCollection['ModeOfTransport'] = "";
   NynasItemCollection['HaulierTransporter'] = "";
   NynasItemCollection['HeaderBU'] = "";
   NynasItemCollection['RevenueBU'] = "";
   NynasItemCollection['DeliveryInstructionsLine1'] = this.addOrderForm.value['delsiteComments'];
   NynasItemCollection['DeliveryInstructionsLine2'] = this.addOrderForm.value['custComments'];
   NynasItemCollection['Information'] = "";
   this.NynasItemCollectionList.push(NynasItemCollection);
  //  console.log("I am inside createOrder" + JSON.stringify(NynasItemCollectionList));
  //  let postData = JSON.stringify({
    
  //   "BillToName": this.addOrderForm.value['CustomerId'],
  //   "ShipToID":  this.jdeAddNum, 
  //   "Currency":  this.addOrderForm.value['currency'],
  // "CustomerPO":this.addOrderForm.value['customerPO'],
  //   "NynasItemCollection": this.NynasItemCollectionList,
  //   "Status": "01",
  //   "ProcessedFlag": "Order created",
  //   "CountryCode": this.addOrderForm.value['CountryId'],
  //   "CreatedBy": this.addOrderForm.value['CreatedBy'],
  //   "UpdatedBy": this.addOrderForm.value['UpdatedBy'],
  //   "CreatedOn": this.addOrderForm.value['CreatedOn'],
  //   "UpdatedOn": this.addOrderForm.value['UpdatedOn']
  // });
  //  console.log("formdatafinal" + postData);
  
    this.orderEntrySer.createOrder(this.UserID,this.addOrderForm.value,this.NynasItemCollectionList,this.jdeAddNum)
    .subscribe(
      (response: any) => {
        this.orderCreateDialog(response);
      });
  
 }

      
      orderCreateDialog(response: any) {
        if (response) {
          
          if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
             if(!this.routeFlag){
               if(response['OrderDetails']){
                this.refNumber =response['OrderDetails'];
               }
                else {
                  this.refNumber =  response['ReferenceNumber'];
              }
            this.openSaveOrderDialog('order created successfully' ,'order-create-success' , true, 'order-create-success' ,this.refNumber);
              }
              else if(response['Description'] === 'Order Rejected'){
                this.refNumber = response['OrderDetails'];
                this.openSaveOrderDialog('order rejected successfully' ,'order-rejected' , true, 'order-rejected' ,this.refNumber);
              }else if(response['Description'] === 'Order Canceled'){
                this.refNumber = response['OrderDetails'];
                this.openSaveOrderDialog('order rejected successfully' ,'order-rejected' , true, 'order-rejected' ,this.refNumber);
              }
              else{
                if(response['OrderDetails']){
                  this.refNumber =response['OrderDetails'];
                 }
                  else {
                    this.refNumber = "Reference # :" + " " +response['ReferenceNumber'];
                }
                this.openSaveOrderDialog('order updated successfully' ,'order-updated' , true, 'order-updated' ,this.refNumber);
              }
           } 
          else if (response['ResponseCode'] === '201' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === '') {
            this.erroMsg = response['Description'];
            this.openSaveOrderDialog('Failed to place an order' ,this.erroMsg , false, 'unapproved' ,'');
            this.reloadCurrentRoute();
          } 
          else if(response['ResponseStatus'].toUpperCase() === 'JDE_ERROR'){
            this.erroMsg = response['Description'];
            this.openSaveOrderDialog('Failed to place an order' ,this.erroMsg , false, 'unapproved' ,'');
            this.reloadCurrentRoute();
          }
           else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
            this.erroMsg = response['Description'];
            this.openSaveOrderDialog('Failed to place an order' ,this.erroMsg , false, 'unapproved' ,'');
           this.reloadCurrentRoute();
          } else {
            this.erroMsg = response['Description'];
            this.openSaveOrderDialog('Failed to place an order' ,this.erroMsg , false, 'unapproved' ,'');
            this.reloadCurrentRoute();
          }
        } 
      
  }

  

  editOrderApprove(){
    this.NynasItemCollectionListEdit = [];
    let NynasItemCollection = {};
    NynasItemCollection['ItemDescription'] = this.productId;
    NynasItemCollection['FreightCode'] = this.addOrderForm.value['frCode'];
    NynasItemCollection['Quantity'] = this.addOrderForm.value['productQuantity'];
    NynasItemCollection['QuantityUOM'] = this.addOrderForm.value['productUOM'];

    // Commented below code, kept for future reference
    /* NynasItemCollection['RequestedDate'] = this.addOrderForm.value['requestedDate'];
    var dateVar = new Date(this.addOrderForm.value['requestedDate']);
    dateVar.setDate(dateVar.getDate() + 1);
    NynasItemCollection['RequestedDate'] = dateVar; */

    NynasItemCollection['RequestedDate'] = this.getRequestedDateInProperFormat();

    NynasItemCollection['EarliestArrivalTime'] = this.addOrderForm.value['earliestTime'];
    NynasItemCollection['LatestArrivalTime'] = this.addOrderForm.value['latestTime'];
    NynasItemCollection['LoadingPoint'] = this.addOrderForm.value['depoName'];//depoId
    NynasItemCollection['ModeOfTransport'] = "";
    NynasItemCollection['HaulierTransporter'] = "";
    NynasItemCollection['HeaderBU'] = "";
    NynasItemCollection['RevenueBU'] = "";
    NynasItemCollection['DeliveryInstructionsLine1'] = this.addOrderForm.value['delsiteComments'];
    NynasItemCollection['DeliveryInstructionsLine2'] = this.addOrderForm.value['custComments'];
    NynasItemCollection['Information'] = this.addOrderForm.value['rComments'];
    this.NynasItemCollectionListEdit.push(NynasItemCollection);
   
    if(this.source === 'request'){
      if(this.isLoggedInUserPortalAdmin){
        //order approve review
        this.orderStatus = "05";
        this.addOrderForm.controls['UpdatedOn'].setValue((this.rowDataFromRoute['UpdatedOn']));
        this.orderEntrySer.approveOrder(this.addOrderForm.value,this.NynasItemCollectionListEdit,this.orderStatus,this.jdeAddNum).subscribe(
          (response: any) => {
            this.orderCreateDialog(response);
          });
      }
      else if(this.role ==="U" || this.role ==="A"){
        //order update customer
        if(this.orderStatus === "03"){
          // this.orderStatus = "03";
          this.addOrderForm.controls['UpdatedOn'].setValue((this.rowDataFromRoute['UpdatedOn']));
          this.orderEntrySer.orderAmendement(this.addOrderForm.value,this.NynasItemCollectionListEdit,this.orderStatus,this.jdeAddNum,this.userID).subscribe(
            (response: any) => {
              this.changeCreateDialog(response);
            });
        }else {
          this.orderStatus = "02";

          // Set UpdatedOn equal to the value received from Order track page, so service can identify if same order is already updated
          this.addOrderForm.controls['UpdatedOn'].setValue((this.rowDataFromRoute['UpdatedOn']));
          
          this.orderEntrySer.approveOrder(this.addOrderForm.value,this.NynasItemCollectionListEdit,this.orderStatus,this.jdeAddNum).subscribe(
            (response: any) => {
              this.orderCreateDialog(response);
            });
        }
      }
     
    }else if(this.source === 'order'){
      // if(this.isLoggedInUserPortalAdmin){
      //   //order approve review
      //   this.orderStatus = "05";
      //   this.orderEntrySer.approveOrder(this.addOrderForm.value,this.NynasItemCollectionListEdit,this.orderStatus,this.jdeAddNum).subscribe(
      //     (response: any) => {
      //       this.orderCreateDialog(response);
      //     });
      // }
      // else 
      if(this.role ==="U" || this.role ==="A"){
        //order update customer
        this.orderStatus = "03";
        this.addOrderForm.controls['UpdatedOn'].setValue((this.rowDataFromRoute['UpdatedOn']));
        this.orderEntrySer.orderAmendement(this.addOrderForm.value,this.NynasItemCollectionListEdit,this.orderStatus,this.jdeAddNum,this.userID).subscribe(
          (response: any) => {
            this.changeCreateDialog(response);
          });
      }
      

    }
    

  }

  editOrderReject(){
    // this.addOrderForm.controls['depoName'].setValue(' ');
    // if(this.isLoggedInUserPortalAdmin){
    // this.addOrderForm.controls['depoName'].setValidators([]); // or clearValidators()
    // // this.addOrderForm.controls['pcCode'].setValidators([]);
    // //this.addOrderForm.controls['productUOM'].setValidators([]);
    // this.addOrderForm.controls['depoName'].updateValueAndValidity();
    // // this.addOrderForm.controls['pcCode'].updateValueAndValidity();
    // }else {
    //     this.addOrderForm.controls['depoName'].setValue('test');
    //     console.log('passing depo Name test line 833');
    //     // this.addOrderForm.controls['pcCode'].setValue('test');
    //     //this.addOrderForm.controls['productUOM'].setValue('test');
    //   }
    
    //this.submitted = true;
    // if (this.addOrderForm.invalid) {
    //   this.openSaveUserDialog('There are items that require your attention', 'Please complete all fields marked with *', false, 'required-fields-validation-message');
    //   return;

    // }
   
    if(this.isLoggedInUserPortalAdmin){
      //Reject from Reviewer
      this.orderStatus = "06";
    }
    else if(this.role ==="U" || this.role ==="A"){
      //cancel case
      this.orderStatus = "04";
    }
    
    this.NynasItemCollectionListReject = [];
    let NynasItemCollection = {};
    NynasItemCollection['ItemDescription'] = this.productId;
    NynasItemCollection['FreightCode'] = this.addOrderForm.value['frCode'];
    NynasItemCollection['Quantity'] = this.addOrderForm.value['productQuantity'];
    NynasItemCollection['QuantityUOM'] = this.addOrderForm.value['productUOM'];

    // Commented below code, kept for future reference
     /* //NynasItemCollection['RequestedDate'] = this.addOrderForm.value['requestedDate'];
    var dateVarEdit = new Date(this.addOrderForm.value['requestedDate']);
    dateVarEdit.setDate(dateVarEdit.getDate() + 1);
    NynasItemCollection['RequestedDate'] = dateVarEdit; */
  
    NynasItemCollection['RequestedDate'] = this.getRequestedDateInProperFormat();

    NynasItemCollection['EarliestArrivalTime'] = this.addOrderForm.value['earliestTime'];
    NynasItemCollection['LatestArrivalTime'] = this.addOrderForm.value['latestTime'];
    NynasItemCollection['LoadingPoint'] = this.addOrderForm.value['depoName'];//depoId
    NynasItemCollection['ModeOfTransport'] = "";
    NynasItemCollection['HaulierTransporter'] = "";
    NynasItemCollection['HeaderBU'] = "";
    NynasItemCollection['RevenueBU'] = "";
    NynasItemCollection['DeliveryInstructionsLine1'] = this.addOrderForm.value['delsiteComments'];
    NynasItemCollection['DeliveryInstructionsLine2'] = this.addOrderForm.value['custComments'];
    NynasItemCollection['Information'] = this.addOrderForm.value['rComments'];

    // Set UpdatedOn equal to the value received from Order track page, so service can identify if same order is already approved
    this.addOrderForm.controls['UpdatedOn'].setValue((this.rowDataFromRoute['UpdatedOn']));

    this.NynasItemCollectionListReject.push(NynasItemCollection);
    this.orderEntrySer.rejectOrder(this.addOrderForm.value,this.NynasItemCollectionListReject,this.orderStatus,this.jdeAddNum).subscribe(
      (response: any) => {
        this.orderCreateDialog(response);
      });
    
  }

  changeRequest(){
    // this.orderStatus = "04"
    this.NynasItemCollectionListChange = [];
    let NynasItemCollection = {};
    NynasItemCollection['ItemDescription'] = this.productId;
    NynasItemCollection['FreightCode'] = this.addOrderForm.value['frCode'];
    NynasItemCollection['Quantity'] = this.addOrderForm.value['productQuantity'];
    NynasItemCollection['QuantityUOM'] = this.addOrderForm.value['productUOM'];
    
    // Commented below code, kept for future reference
    /* //NynasItemCollection['RequestedDate'] = this.addOrderForm.value['requestedDate'];
    var dateVarChange = new Date(this.addOrderForm.value['requestedDate']);
    dateVarChange.setDate(dateVarChange.getDate() + 1);
    NynasItemCollection['RequestedDate'] = dateVarChange; */
 
    NynasItemCollection['RequestedDate'] = this.getRequestedDateInProperFormat();

    NynasItemCollection['EarliestArrivalTime'] = this.addOrderForm.value['earliestTime'];
    NynasItemCollection['LatestArrivalTime'] = this.addOrderForm.value['latestTime'];
    NynasItemCollection['LoadingPoint'] = this.addOrderForm.value['depoName'];//depoId
    NynasItemCollection['ModeOfTransport'] = "";
    NynasItemCollection['HaulierTransporter'] = "";
    NynasItemCollection['HeaderBU'] = "";
    NynasItemCollection['RevenueBU'] = "";
    NynasItemCollection['DeliveryInstructionsLine1'] = this.addOrderForm.value['delsiteComments'];
    NynasItemCollection['DeliveryInstructionsLine2'] = this.addOrderForm.value['custComments'];
    NynasItemCollection['Information'] = this.addOrderForm.value['rComments'];
    this.NynasItemCollectionListChange.push(NynasItemCollection);
    this.orderEntrySer.rejectOrder(this.addOrderForm.value,this.NynasItemCollectionListChange ,this.orderStatus,this.jdeAddNum).subscribe(
      (response: any) => {
        this.changeCreateDialog(response);
      });
  }

  changeCreateDialog(response : any){
    if (response) {
          
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        if(response['OrderDetails']){
          this.refNumber =response['OrderDetails'];
         }
          else {
            this.refNumber = "Reference # :" + " " +response['ReferenceNumber'];
        }
        if(this.isLoggedInUserPortalAdmin){
          this.openSaveOrderDialog('change order request submit successfully' ,'change-order-success' , true, 'change-order-success' ,this.refNumber);
        }
       else {
        this.openSaveOrderDialog('change order request submit successfully' ,'change-order-successCust' , true, 'change-order-successCust' ,this.refNumber);
       }
        
       } 
      else if (response['ResponseCode'] === '201' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === '') {
        this.erroMsg = response['Description'];
        this.openSaveOrderDialog('Failed to change an order' ,this.erroMsg , false, 'change-order-failure' ,'');
        this.reloadCurrentRoute();
      } 
     
       else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.erroMsg = response['Description'];
        this.openSaveOrderDialog('Failed to change an order' ,this.erroMsg , false, 'change-order-failure' ,'');
       this.reloadCurrentRoute();
      } else {
        this.erroMsg = response['Description'];
        this.openSaveOrderDialog('Failed to change an order' ,this.erroMsg , false, 'change-order-failure' ,'');
        this.reloadCurrentRoute();
      }
    } 

  }
  
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

  cancelOrder() {
    this.redirectToOrderTrackingPageWithPreviouslySelectedCustomer();
  }

  async getEarliestOrLatestTimeHourValues(timeType: string) {
    if (this.addOrderForm && this.addOrderForm.value && this.addOrderForm.value['Time']) {
      let countryCode = this.countryCode ? this.countryCode : '';
      let key = countryCode + '_' + this.addOrderForm.value['Time'] + '_' + timeType; // for example, GBR_ALLDAY_EARLIESTTIMEHOURS is a sample key saved in utility database table
      return new Promise<void>((_resolve, _reject) => {
        this.orderEntrySer.getDisplayMessage(key).subscribe(
          response => {
            if (response && response['ResponseCode'] === '200' && response['Message'] && response['Message'].split(',') && response['Message'].split(',').length === 3) {
              this.minimumTimeValueInTimeHourDropdown = response['Message'].split(',')[0] ? +response['Message'].split(',')[0] : 0;
              this.maximumTimeValueInTimeHourDropdown = response['Message'].split(',')[2] ? +response['Message'].split(',')[2] : 0;
              if (timeType === 'EARLIESTTIMEHOURS') {
                this.defaultEarliestTimeValue = response['Message'].split(',')[1];
              } else if (timeType === 'LATESTTIMEHOURS') {
                this.defaultLatestTimeValue = response['Message'].split(',')[1];
              }
              _resolve();
            }
            else {
              _reject();
            }
          });
      });
    }
  }

  async loadEarliestAndLatestTimeHourValues(isTimeRadioButtonChanged: boolean) {

    // Set default value of Time radio button as All Day when order entry page opened for first time
    if (!isTimeRadioButtonChanged && !this.routeFlag) {
      this.addOrderForm.controls['Time'].setValue('ALLDAY');
    }

    if (isTimeRadioButtonChanged || !this.routeFlag) {
      try {
        await this.getEarliestOrLatestTimeHourValues('EARLIESTTIMEHOURS');
      } catch (e) {
      }
      try {
        await this.getEarliestOrLatestTimeHourValues('LATESTTIMEHOURS');
      } catch (e) {
      }
    }
    this.populateValuesInTimeDropdown(isTimeRadioButtonChanged);
  }

  populateValuesInTimeDropdown(isTimeRadioButtonChanged: boolean) {
    if (!isTimeRadioButtonChanged && this.routeFlag) {
      this.minimumTimeValueInTimeHourDropdown = 1;
      this.maximumTimeValueInTimeHourDropdown = 24;
    }

    // Commented below code, kept for future reference
    /* let minimumTimeValue = this.getMinimumAndMaximumTimeValues('minimum');
    let maximumTimeValue = this.getMinimumAndMaximumTimeValues('maximum'); */
    
    let timeHourObject = {};
    this.timeHourList = [];
    for (var time = this.minimumTimeValueInTimeHourDropdown; time <= this.maximumTimeValueInTimeHourDropdown; time += 1) {
      let timeInString = (time || time === 0) ? time.toString() : '';
      timeHourObject['key'] = timeInString;
      timeHourObject['value'] = this.getTimeInTwoDigits(timeInString);
      this.timeHourList.push(timeHourObject);
      timeHourObject = {};
    }
    if (isTimeRadioButtonChanged || !this.routeFlag) {
      this.addOrderForm.controls['earliestTime'].setValue(this.defaultEarliestTimeValue);
      this.addOrderForm.controls['latestTime'].setValue(this.defaultLatestTimeValue);
    }
  }

  changedSelectedTime() {
    this.loadEarliestAndLatestTimeHourValues(true);
  }

  // Commented below code, kept for future reference
  /* getMinimumAndMaximumTimeValues(valueType: string): number {
    let time = this.addOrderForm.value['Time'];
    let minimumValue: number = 0;
    let maximumValue: number = 24;
    switch (time) {
      case 'ALLDAY':
        minimumValue = 0;
        maximumValue = 24;
        break;
      case 'AM':
        minimumValue = 0;
        maximumValue = 12;
        break;
      case 'PM':
        minimumValue = 12;
        maximumValue = 24;
        break;
      default:
        break;
    }
    if (valueType === 'minimum') {
      return minimumValue;
    } else if (valueType === 'maximum') {
      return maximumValue;
    }
    return 0;
  } */

  async validateRequestedDateTime() {
    let input = this.getTimeInTwoDigits(this.addOrderForm.value['latestTime']);
    let zone = 'Europe/London';
    let requestedDate = moment(this.addOrderForm.value.requestedDate).format('YYYY-MMM-DD').toUpperCase();
    this.validationMessageList = [];
    return new Promise<void>((_resolve, _reject) => {
      this.orderEntrySer.validateTime(input, zone, requestedDate, this.countryCode).subscribe(
        response => {
          if (response && response['ResponseCode'] == 200) {
            // this.hasDeliveryTimeElapsed = (response['StatusBoolean'] !== 'true');
            if (response['outputCollection'] && response['outputCollection'].length && response['outputCollection'][0]) {
              if (response['outputCollection'][0]['Flag']) {
                this.validationMessageList.push(response['outputCollection'][0]['Flag']);
              }
              if (response['outputCollection'][0]['Output']) {
                this.validationMessageList.push(response['outputCollection'][0]['Output']);
              }
            }
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

  getTimeInTwoDigits(time: any): string {
    if ((time || time === 0) && time.toString().length == 1) {
      time = '0' + time;
    }
    return time;
  }

  isDeliveryRequestDateOfToday(): boolean {
    let isDeliveryRequestedDateOfToday: boolean;
    let todaysDate: Date = this.sharedService.getCurrentDateTime() ? new Date(this.sharedService.getCurrentDateTime()) : null;
    if (todaysDate && this.addOrderForm.value['requestedDate']) {
      let requestedDate = new Date(this.addOrderForm.value['requestedDate']);
      todaysDate.setHours(0, 0, 0, 0);
      isDeliveryRequestedDateOfToday = (requestedDate.getTime() === todaysDate.getTime());
    }
    return isDeliveryRequestedDateOfToday;
  }

  rejectOrder() {
    const dialogRef = this.dialog.open(DialogBodyComponent, {
      data: {
        headerNumber: 'reject-order-detail',
        buttonText: {
          ok: 'Ok'
        },
        isWarning: true
      },
      height: (this.mobileQuery.matches ? '55%' : '50%'),
      width: (this.mobileQuery.matches ? '88%' : '27%'),
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.editOrderReject();
      }
    });
  }

  redirectToOrderTrackingPageWithPreviouslySelectedCustomer() {
    let navigationExtras: NavigationExtras = {
      state: {
        selectedCustomerId: ((this.addOrderForm && this.addOrderForm.value && this.addOrderForm.value['CustomerId']) ? this.addOrderForm.value['CustomerId'] : '')
      }
    };
    this.router.navigate(['../../app/order-management/order-tracking'], (this.isLoggedInUserPortalAdmin ? navigationExtras : undefined));
  }

  checkProductQuantityValue() {
    this.isQuantityLessThan14 = ((this.addOrderForm.value['productQuantity'] || this.addOrderForm.value['productQuantity'] == '0') ? (+(this.addOrderForm.value['productQuantity']) < 14) : false);
    this.isQuantityEqualToOrMoreThan29 = (this.addOrderForm.value['productQuantity'] ? (+(this.addOrderForm.value['productQuantity']) >= 29) : false);
    this.isRateValueInvalid = (this.isInValidDecimalNumber(this.addOrderForm.value.productQuantity));
  }

  getRequestedDateInProperFormat(): string {
    let formattedRequestedDate = '';
    if (this.addOrderForm.value['requestedDate']) {
      formattedRequestedDate = moment(this.addOrderForm.value['requestedDate']).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"); // Convert date into format - '2021-11-15T00:00:00.000Z'
    }
    return formattedRequestedDate;
  }
  
  open() {
    this.picker.open();
  }

  checkIfRequestedDateIsOfToday(): void {
    this.isRequestedDateOfToday = false;
    let todaysDate: Date = this.sharedService.getCurrentDateTime() ? new Date(this.sharedService.getCurrentDateTime()) : null;
    if (todaysDate && this.addOrderForm.value['requestedDate']) {
      let requestedDate = new Date(this.addOrderForm.value['requestedDate']);
      todaysDate.setHours(0, 0, 0, 0);
      this.isRequestedDateOfToday = (requestedDate.getTime() === todaysDate.getTime());
    }
  }

  async validateRequestedDate() {
    if(!this.isLoggedInUserPortalAdmin){
    this.isOrderBookFull = false;
    this.orderFullDate = '';
    this.checkIfRequestedDateIsOfToday();
    if (!this.isRequestedDateOfToday) {
      let type: string = 'ORDER_FULL_DATE';
      return new Promise<void>((_resolve, _reject) => {
        this.orderEntrySer.getDisplayMessage(type)
          .subscribe(
            response => {
              if (response && response['ResponseCode'] === '200') {
                this.orderFullDate = response['Message'] ? response['Message'] : '';
                if (this.orderFullDate) {
                  let requestedDate = new Date(this.addOrderForm.value['requestedDate']);
                  let orderFullDate = new Date(this.orderFullDate);
                  this.isOrderBookFull = (requestedDate <= orderFullDate);
                  if (this.isOrderBookFull) {
                    this.validationMessageList.push("Our order book is now full for that date.  Please select another date or call 0330 123 0033");
                  }
                }
                _resolve();
              }
              else {
                _reject();
              }
            });
      });
    }
  }
  }
  
  unlockOrder() {
    let referenceNumbersTobeUnlocked: any = [];
    referenceNumbersTobeUnlocked.push(this.rowDataFromRoute['ReferenceNumber']);
    this.ordertrackService.updateUnlockStatusForOrders(referenceNumbersTobeUnlocked, this.userID).subscribe(
      response => {
        if (response["ResponseCode"] == 200) {
          // Successfully resetted the lock, if still locked else no change
        }
      });
  }

  disableWeekendDates(selectedDeliveryRequestedDate: any): boolean {
    const day = selectedDeliveryRequestedDate.day();
    return (day !== 0 && day !== 6);  // 0 - Saturday, 1 - Sunday
  }

}
