<!-- <h2 mat-dialog-title>Password Reset Successful!</h2>

<mat-dialog-content>

<p> Reset Password link has been sent to you by email </p>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
</mat-dialog-actions> -->

<!-- <h2 mat-dialog-title> {{ header }} </h2> -->
<!-- <div class="float-right cancel-icon" (click)="onConfirmClick()">
	<img src="../../../assets/cancel-icon.png" class="dialog-cancel-icon">
</div> -->
<div class="text-center" *ngIf="isWarning">
    <span class="exclamation-icon">
        <i class="fas fa-exclamation"></i>
    </span>
</div>
<div *ngIf="!isWarning">
	<div class="text-center" *ngIf="isSuccess">
		<img src="../../../assets/success-icon.png" class="dialog-icon">
	</div>
	<div class="text-center" *ngIf="!isSuccess">
		<img src="../../../assets/failure-icon.png" class="dialog-icon">
	</div>
</div>
<!-- <div class="container pad-top-150">
	<div class="row">
		<div class="col-sm">
			<div class="float-right cancel-icon" (click)="onConfirmClick()">
				<img src="../../../assets/cancel-icon.png" class="dialog-icon">
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm">
			<div class="text-center">
				<img src="../../../assets/success-icon.png" class="dialog-icon">
			</div>
		</div>
	</div>
</div> -->
<!-- <div class="container pad-top-150">
	<div class="row">
		<div class="col-sm">
		</div>
		<div class="col-sm">
			<img src="../../../assets/success-icon.png" class="mat-table-action-icons">
		</div>
		<div class="col-sm">
		</div>
	</div>
</div> -->
<div class="container pt-3">
	<div class="row">
		<div class="col-sm">
			<!-- <span mat-dialog-title class="header-text"> {{ header }} </span> -->

			<div class="text-center">
				<!-- <span mat-dialog-title class="header-text"> {{header}} </span> -->
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'Attention'">{{'HOME.Attention'|translate}}  </span>
				<!-- <span mat-dialog-title class="header-text" *ngIf="headerNumber3 === 'Failed'">{{'HOME.Failed'|translate}}  </span> -->
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-delivery-site-save-fail'">{{'HOME.DeliverySiteDetails'|translate}}  </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-delivery-site-save-success'">{{'HOME.success'|translate}}  </span>  
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-delivery-site-save-fail-already-present'">{{'HOME.alreadypresent'|translate}}  </span> 
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'price-index-fail'">{{'HOME.price-index-fail'|translate}}  </span> 
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'price-index-success'">{{'HOME.price-index-success'|translate}}  </span> 
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'price-index-message'">{{'HOME.price-index-message'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'price-index-fail-exist'">{{'HOME.price-index-fail-exist'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-exchange-rate-fail-exist'">{{'HOME.add-exchange-rate-fail-exist'|translate}} </span>


				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-exchange-rate-fail'">{{'HOME.add-exchange-rate-fail'|translate}}  </span> 
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-exchange-rate-success'">{{'HOME.add-exchange-rate-success'|translate}} </span>  
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-exchange-rate-message'">{{'HOME.add-exchange-rate-message'|translate}}  </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'delivery-guide-admin-success'">{{'HOME.delivery-guide-admin-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'delivery-guide-admin-fail'">{{'HOME.delivery-guide-admin-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'contact-delete-fail'">{{'HOME.contact-delete-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'contact-delete-success'">{{'HOME.contact-delete-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'contact-detail-message'">{{'HOME.contact-detail-message'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'contact-detail-success'">{{'HOME.contact-detail-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'nynas-update-fail'">{{'HOME.nynas-update-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'nynas-update-success'">{{'HOME.nynas-update-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'product-document-fail'">{{'HOME.product-document-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'product-document-success'">{{'HOME.product-document-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'product-document-message'">{{'HOME.product-document-message'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'contact-us-message'">{{'HOME.contact-us-message'|translate}} </span>
			

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'contact-delete-message'">{{'HOME.contact-delete-message'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'delete-delsite-confirmation'">{{'HOME.delete-delsite-confirmation'|translate}} </span>
                <span mat-dialog-title class="header-text" *ngIf="headerNumber === 'delsite-delete-success'">{{'HOME.delsite-delete-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'delsite-delete-fail'">{{'HOME.delsite-delete-fail'|translate}} </span>
				
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-user-fail'">{{'HOME.add-user-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-user-message'">{{'HOME.add-user-message'|translate}} </span>

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-user-success'">{{'HOME.add-user-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'edit-success-user'">{{'HOME.edit-success-user'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-user-group-success'">{{'HOME.add-user-group-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-user-group-fail'">{{'HOME.add-user-group-fail'|translate}} </span>

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'bitumen-index-fail'">{{'HOME.bitumen-index-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'bitumen-index-success'">{{'HOME.bitumen-index-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'bitumen-index-message'">{{'HOME.bitumen-index-message'|translate}} </span>

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'c-2-success'">{{'HOME.c-2-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'c-2-p-success'">{{'HOME.c-2-p-success'|translate}} </span>

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'forecast-detail-success'">{{'HOME.forecast-detail-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'forecast-detail-fail'">{{'HOME.forecast-detail-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'forecast-entry-success'">{{'HOME.forecast-entry-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'forecast-entry-fail'">{{'HOME.forecast-entry-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'login-fail'">{{'HOME.login-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'del-site-update-success'">{{'HOME.del-site-update-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'del-site-update-fail'">{{'HOME.del-site-update-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'delete-nynas-updates-confirmation'">{{'HOME.delete-nynas-updates-confirmation'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'no-file-available'">{{'HOME.no-file-available'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'nynas-update-delete-success'">{{'HOME.nynas-update-delete-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'nynas-update-delete-fail'">{{'HOME.nynas-update-delete-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-user-group-fail-exist'">{{'HOME.add-user-group-fail-exist'|translate}} </span>

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'no-data-available'">{{'HOME.no-data-available'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'home-correct-user-id'">{{'HOME.home-correct-user-id'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'home-correct-user-id-format'">{{'HOME.home-correct-user-id-format'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'login-history-fail'">{{'HOME.login-history-fail'|translate}} </span>


				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'Something-went-wrong'">{{'HOME.Something-went-wrong'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'pending-forecast-success'">{{'HOME.pending-forecast-success'|translate}} {{emailTo}}</span>

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'pending-forecast-fail'">{{'HOME.pending-forecast-fail'|translate}} {{emailTo}} </span>

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'finland-pending-monthly-forecast-success'">
					{{'HOME.pending-forecast-success'|translate}}
					<!-- <div *ngFor="let parameter of extraParams">
				                        {{parameter}}
				                    </div> -->
				
					<!-- <table>
				                        <tr>
				                            <th>Email IDs</th>
				                            <th>Select all</th>
				                        </tr>
				                        <tr *ngFor="let parameter of extraParams">
				                            <td>{{parameter}}</td>
				                            <td><input class="checkbox-add-remove" type="checkbox" [(ngModel)]="isSelected"
				                                    title="{{'HOME.Selecttosaveproduct'| translate}}"></td>
				                        </tr>
				                    </table> -->
					<!-- <table mat-table #firstTable #adhesivePricesTableSort="matSort" [dataSource]="dataSourceForAdhesivePricesTable"
				                        class=" horizontal_scrollbar adhesive_price"
				                        [ngClass]="{'adhesive-price-table-fi': (selectedLanguage === 'fi'), 'adhesive-price-table': (selectedLanguage !== 'fi')}"
				                        matSort>
				                        <ng-container matColumnDef="emailId">
				                            <mat-header-cell class="header-font mat-column-large-adhesive-price" *matHeaderCellDef mat-sort-header>
				                                {{'HOME.Email'| translate}}
				                            </mat-header-cell>
				                            <mat-cell class="mat-column-large-adhesive-price" *matCellDef="let fetchedDataForItemArray">
				                                {{fetchedDataForItemArray.emailId}}
				                            </mat-cell>
				                        </ng-container>
				                        <ng-container matColumnDef="selection">
				                            <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef>
				                                <span>
				                                    <input class="checkbox-add-remove" type="checkbox" [(ngModel)]="isAllAdhesiveProductsSelected"
				                                        (click)="selectAllAdhesiveProductsToSave()" title="{{'HOME.Selecttosaveallproducts'| translate}}">
				                                </span>
				                            </mat-header-cell>
				                            <th mat-header-cell *matHeaderCellDef [ngClass]="'mat-column-medium mat-column-actions'">
				                            </th>
				                            <mat-cell class="mat-column-medium" *matCellDef="let element">
				                                <span>
				                                    <input class="checkbox-add-remove" type="checkbox" [(ngModel)]="element.isSelected"
				                                        title="{{'HOME.Selecttosaveproduct'| translate}}">
				                                </span>
				                            </mat-cell>
				                        </ng-container>
				                        <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsForAdhesivePricesTable; sticky:true">
				                        </mat-header-row>
				                        <mat-row *matRowDef="let row; columns: displayedColumnsForAdhesivePricesTable;">
				                        </mat-row>
				                    </table> -->
				
				</span>

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'app-del-site-update'">{{'HOME.app-del-site-update'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'app-del-site-update-fail'">{{'HOME.app-del-site-update-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'app-add-update'">{{'HOME.app-add-update'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'user-group-success'">{{'HOME.user-group-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'user-group-fail'">{{'HOME.user-group-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'update-user-success'">{{'HOME.update-user-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'update-user-fail'">{{'HOME.update-user-fail'|translate}} </span>

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'reset-password-fail'">{{'HOME.reset-password-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'login-fail-password-not-match'">{{'HOME.login-fail-password-not-match'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'login-fail-no-data'">{{'HOME.login-fail-no-data'|translate}} </span>
				
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'login-fail-active-data'">{{'HOME.login-fail-active-data'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-user-message1'">{{'HOME.add-user-message1'|translate}} </span>

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'home-reset-password-fail'">{{'HOME.home-reset-password-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'home-reset-password-fail-deactive'">{{'HOME.home-reset-password-fail-deactive'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'home-reset-password-fail-userId-not-found'">{{'HOME.home-reset-password-fail-userId-not-found'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'home-reset-password-fail-incoorect-format'">{{'HOME.home-reset-password-fail-incoorect-format'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'home-reset-password-success'">{{'HOME.home-reset-password-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'update-password-success'">{{'HOME.update-password-success'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'update-password-fail'">{{'HOME.update-password-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'forecast-message'">{{'HOME.forecast-message'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'NQC'">{{'HOME.NQC'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'quality-certificate-fail'">{{'HOME.quality-certificate-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'data-already-present'">{{'HOME.data-already-present'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-user-fail-exist'">{{'HOME.add-user-fail-exist'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'guest-view-fail'">{{'HOME.guest-view-fail'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'price-save-fail'">{{'HOME.price-save-fail'|translate}}  </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'price-save-success'">{{'HOME.price-save-success'|translate}}  </span> 
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'select-price-to-save'">{{'HOME.select-price-to-save'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'confirmation-portal-admin-user'">{{'HOME.confirmation-portal-admin-user'|translate}} </span>
				<span mat-dialog-title class="message-text-warning" *ngIf="message === 'productlessthan13'">{{'HOME.productlessthan13'|translate}} </span>
				<span mat-dialog-title class="message-text-warning" *ngIf="message === 'productmorethan29'">{{'HOME.productmorethan29'|translate}} </span>
				<span mat-dialog-title class="message-text-warning" *ngIf="messagenumber === 'Deliveryrequesttimealreadylapsed'">{{'HOME.Deliveryrequesttimealreadylapsed'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber ==='approve-order-detail'">{{'HOME.approve-order-detail'|translate}}</span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber ==='reject-order-detail'">{{'HOME.reject-order-detail'|translate}}</span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'confirmation-order-detail'">{{'HOME.confirmation-order-detail'|translate}} </span>

				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'select-delivery-site-to-save'">{{'HOME.select-delivery-site-to-save'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'passwords-do-not-match'">{{'HOME.passwords-do-not-match'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'pwd-pattern'">{{'HOME.pwd-pattern'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'pwd-length'">{{'HOME.pwd-length'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'login-fail-tenattempts'">{{'HOME.login-fail-tenattempts'|translate}} </span>
				<!-- <span mat-dialog-title class="header-text" *ngIf="headerNumber === 'pwd-change-days'">{{'HOME.pwd-change-days'|translate}} </span> -->
				<span mat-dialog-title class="header-text-font" *ngIf="headerNumber === 'invalid-username-password'">{{'HOME.invalid-username-password-title'|translate}} </span>
				<span mat-dialog-title class="header-text-font" *ngIf="headerNumber === 'login-fail-password-expired'">{{'HOME.login-fail-password-expired-title'|translate}} </span>
                <span mat-dialog-title class="header-text-font" *ngIf="headerNumber === 'reset-password-success'">{{'HOME.reset-password-success-title'|translate}} </span>
                <span mat-dialog-title class="header-text-font" *ngIf="headerNumber === 'reset-password-title-success'">{{'HOME.reset-password-success-title'|translate}} </span>                
				<span mat-dialog-title class="header-text-font" *ngIf="headerNumber === 'required-fields-validation-message'">{{'HOME.required-fields-validation-message'|translate}} </span>
				<span mat-dialog-title class="header-text-font" *ngIf="headerNumber === 'complete-required-fields'">{{'HOME.complete-required-fields-header'|translate}} </span>
				<span mat-dialog-title class="header-text-font" *ngIf="headerNumber === 'please-select-delivery-site'">{{'HOME.please-select-delivery-site'|translate}} </span>
				<span mat-dialog-title class="header-text-font" *ngIf="headerNumber === 'note'">{{'HOME.Note'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'contractual-volume-details-saved'">{{'HOME.contractual-volume-details-saved'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'contractual-volume-details-not-saved'">{{'HOME.contractual-volume-details-not-saveds'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'order-create-success'">{{'HOME.successOrder'|translate}}</span> 
				<span mat-dialog-title class="header-text-refNum" *ngIf="headerNumber === 'order-create-success'">{{refNum}}</span> 
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'order-updated'">{{'HOME.updateOrder'|translate}}</span> 
				<span mat-dialog-title class="header-text-refNum" *ngIf="headerNumber === 'order-updated' ">{{refNum}} </span> 
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'order-rejected'">{{'HOME.order-rejected'|translate}}</span> 
				<span mat-dialog-title class="header-text-refNum" *ngIf="headerNumber === 'order-rejected'">{{refNum}} </span> 
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'order-failure'">{{'HOME.failOrder'|translate}}</span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'unapproved'">{{'HOME.unapproved'|translate}}</span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'change-order-failure'">{{'HOME.failureChangeOrder'|translate}}</span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'change-order-success'">{{'HOME.changeOrderSuccess'|translate}}</span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'change-order-successCust'">{{'HOME.changeOrderSuccessCust'|translate}}</span>
				<span mat-dialog-title class="header-text-refNum" *ngIf="headerNumber === 'change-order-success'">{{refNum}}</span> 
				<span mat-dialog-title class="header-text-refNum" *ngIf="headerNumber === 'change-order-successCust'">{{refNum}}</span>
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'Noforecastsnapshotdetailsfound'">{{'HOME.Noforecastsnapshotdetailsfound'|translate}}</span>    
				<span mat-dialog-title class="header-text" *ngIf="headerNumber === 'no-document-found'">{{'HOME.no-document-found'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="header === 'session-expired'">{{'HOME.session-expired-title'|translate}} </span>
				<span mat-dialog-title class="header-text" *ngIf="header === 'order-unlock-success'">{{'HOME.order-unlock-success'|
					translate}} </span>
				<span mat-dialog-title class="header-text-refNum" *ngIf="header === 'order-unlock-success'">{{refNum}}</span>
				<span mat-dialog-title class="header-text" *ngIf="header === 'order-unlock-fail'">{{'HOME.order-unlock-fail'|
					translate}} </span>
				<span mat-dialog-title class="header-text-refNum" *ngIf="header === 'order-unlock-fail'">{{refNum}}</span>
				<span mat-dialog-title class="header-text" *ngIf="header === 'confirm-order-unlock'">{{'HOME.confirm-order-unlock'|
					translate}} </span>
				<span mat-dialog-title class="header-text-refNum" *ngIf="header === 'confirm-order-unlock'">{{refNum}}</span>
				<span mat-dialog-title class="header-text"
					*ngIf="headerNumber === 'delivery-site-product-save-success'">{{'HOME.delivery-site-product-save-success'|translate}}
				</span>
				<span mat-dialog-title class="header-text"
					*ngIf="headerNumber === 'delivery-site-product-save-failure'">{{'HOME.delivery-site-product-save-failure'|translate}}
				</span>
				<span mat-dialog-title class="header-text"
					*ngIf="headerNumber === 'settings-save-success'">{{'HOME.settings-save-success'|translate}} </span>
				<span mat-dialog-title class="header-text"
					*ngIf="headerNumber === 'settings-save-fail'">{{'HOME.settings-save-fail'|translate}} </span>
				<span mat-dialog-title class="header-text"
					*ngIf="headerNumber === 'settings-save-required-type'">{{'HOME.settings-save-required-type'|translate}} </span>
				<span mat-dialog-title class="header-text"
					*ngIf="headerNumber === 'new-delivery-site-creation-success'">{{'HOME.new-delivery-site-creation-success'|translate}}: {{ message
					}} </span>
				<span mat-dialog-title class="header-text"
					*ngIf="headerNumber === 'delivery-site-jde-side-failure-heading'">{{'HOME.delivery-site-jde-side-failure-heading'|translate}}</span>
					<span mat-dialog-title class="header-text"
						*ngIf="headerNumber === 'SomethingwentwrongPleasecontactyourNynasrepresentative'">{{'HOME.Somethingwentwrong'|translate}}
					</span>
					<span mat-dialog-title class="header-text"
						*ngIf="headerNumber === 'SomethingwentwrongPleasecontactyourNynasrepresentative'">{{'HOME.PleasecontactyourNynasrepresentative'|translate}}
					</span>
  			</div>
			<div class="text-center">
				<mat-dialog-content>
					<span class="message-text text-center">
						<span mat-dialog-title class="header-text" *ngIf="messagefield1 === 'Completefields'">{{'HOME.Completefields'|translate}}  </span>
						<!-- <span mat-dialog-title class="header-text" *ngIf="headerNumber === 'add-user-message'">{{'HOME.Completefields'|translate}}  </span> -->
						<span mat-dialog-title class="header-text" *ngIf="messagefield2 === 'add-user-message'">{{'HOME.add-user-message'|translate}}  </span>
						<span mat-dialog-title class="header-text" *ngIf="messagenumber === 'reset-password-message'">{{'HOME.reset-password-message'|translate}} </span>
						<span mat-dialog-title class="message-text" *ngIf="headerNumber === 'login-fail-active-data'">{{'HOME.contact-system-admin'|translate}} </span>
						<span mat-dialog-title class="message-text" *ngIf="headerNumber === 'unapproved'">{{messagenumber}}</span>
				
						<span mat-dialog-title class="message-text-font" *ngIf="headerNumber === 'login-fail-password-expired'">{{'HOME.login-fail-password-expired-message'|translate}} </span>
						<span mat-dialog-title class="message-text-font" *ngIf="headerNumber === 'reset-password-success'">{{'HOME.reset-password-success-message'|translate}} </span>    
						<span mat-dialog-title class="message-text-font" *ngIf="headerNumber === 'invalid-username-password'">{{'HOME.invalid-username-password-message'|translate}} </span>
						<span mat-dialog-title class="message-text-font" *ngIf="message === 'price-change-note'">{{'HOME.price-change-note'|translate}} </span>
						<span mat-dialog-title class="message-text-font" *ngIf="message === 'complete-required-fields'">{{'HOME.complete-required-fields-message'|translate}} </span>
						<span mat-dialog-title class="message-text-font" *ngIf="messagenumber === 'address-line-1-gps-coordinates-empty'">{{'HOME.address-line-1-gps-coordinates-empty'|translate}} </span>
						<span mat-dialog-title class="message-text-font" *ngIf="message === 'session-expired'">{{'HOME.session-expired-message'|translate}} </span>
						<span mat-dialog-title class="message-text-font jde-address-number-text"
							*ngIf="headerNumber === 'delivery-site-jde-side-failure-heading'">{{'HOME.delivery-site-jde-side-failure-message'|translate}}</span>
					</span>
				</mat-dialog-content>
				<mat-dialog-actions align="center" *ngIf="showFooterButtons && !isWarning">
					<!-- <button mat-raised-button color="primary" (click)="onConfirmClick()"
														tabindex="1">{{confirmButtonText}}</button> -->
					<button class="btn btn-sm btn-primary dialog-footer-button"
						(click)="onConfirmClick()">{{confirmButtonText}}</button>
				</mat-dialog-actions>
				<mat-dialog-actions align="center" *ngIf="showFooterButtons && isWarning">
					<button class="btn btn-sm btn-primary dialog-footer-button ok-button"
						(click)="onConfirmClick()">{{'HOME.Ok'| translate}}</button>
					<span class="pl-2">
						<button class="btn btn-sm btn-primary dialog-footer-button cancel-button"
							(click)="onCancelClick()" mat-dialog-close tabindex="-1">{{'HOME.Cancel'| translate}}</button>
					</span>
				</mat-dialog-actions>
			</div>
		</div>
	</div>
</div>
