import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { PendingForecastService } from 'src/app/order-management/pending-forecast/pending-forecast.component.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import * as XLSX from 'xlsx';
import { CommonService } from '../../order-management/order-management/order-management.component.service';
import { BitumenIndexService } from './bitumen-index.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-bitumen-index',
  templateUrl: './bitumen-index.component.html',
  styleUrls: ['./bitumen-index.component.css']
})
export class BitumenIndexComponent implements OnInit {
 //Option 1
 //displayedColumns: string[] = ['month','Year', 'Index_2015', 'Index_2010', 'Index_2005','Index_2000','Index_1995'];
 //Option 2
 displayedColumns: string[] = ['Date','month','Year','Index_2020', 'Index_2015', 'Index_2010', 'Index_2005','Index_2000','Index_1995'];
  dataSource: any;
  displayTable: boolean = true;
  yearToSearch:any;
  Year = this.sharedService.getCurrentDateTime().getFullYear();
  bitumeIndexData: any;
  bitumeIndexDataCopy:any;
  fetchedDataForItemArray = [];
  years:any;
  Forecast: any;
  excelData:any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(private commonService: CommonService,
    private bitumenIndexService :BitumenIndexService,
    private translate: TranslateService,
    private pendingForecastService:PendingForecastService,
    private sharedService: AppService,
    private cd: ChangeDetectorRef,
    private media: MediaMatcher) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => cd.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);   
  }

  ngOnInit() {
    this.years=['All','Current Year','Last 2 Years','Last 3 Years'];
    this.yearToSearch=this.years[0];
    this.bitumeIndexData=[];
    this.getBitumenIndex(); 

    this.translate.get(['HOME.itemsPerPageLabel'])
    .subscribe(data => {
      this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
    });
  }
  getDummyData() {
    // const bitumeIndexData: BitumenIndex[] = [
    //   { Date: "October 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143" },
    //   { Date: "September 2019", Index_2015: "155", Index_2010: "139", Index_2005: "190",Index_2000:'197',Index_1995:'170' },
    //   { Date: "August 2019",  Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "July 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143" },
    //   { Date: "June 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143" },
    //   { Date: "May 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "April 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143" },
    //   { Date: "March 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "Feburary 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "January 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "December 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143" },
    //   { Date: "November 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "October 2018", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"}
    // ];
    // this.displayTable = true;
    // this.excelData=bitumeIndexData;
    // this.dataSource = new MatTableDataSource(bitumeIndexData);
    // console.log(bitumeIndexData, "Bitumen Index Information");
    // this.dataSource.sort = this.sort;
    // this.displayTable = true;
  }

  downloadBitumenIndexData() {
    // Commented old code, kept for future reference
    // this.pendingForecastService.exportAsExcelFile(this.excelData, "BitumenIndex");

    let bitumenIndexExcelData = [];
    let headerList = ['Month', 'Year','Bitumen index (2020=100)', 'Bitumen index (2015=100)', 'Bitumen index (2010=100)', 'Bitumen index (2005=100)', 'Bitumen index (2000=100)', 'Bitumen index (1995=100)'];
    if (this.excelData && this.excelData.length) {
      for (let bitumenIndex of this.excelData) {
        let bitumenIndexObject = {};

        // Translate month name for excel
        this.translate.get(['HOME.' + bitumenIndex['month']])
          .subscribe(data => {
            bitumenIndexObject['Month'] = ((data['HOME.' + bitumenIndex['month']]));
          });

        bitumenIndexObject['Year'] = bitumenIndex['Year'];
        bitumenIndexObject['Bitumen index (2020=100)'] = bitumenIndex['Index_2020'];
        bitumenIndexObject['Bitumen index (2015=100)'] = bitumenIndex['Index_2015'];
        bitumenIndexObject['Bitumen index (2010=100)'] = bitumenIndex['Index_2010'];
        bitumenIndexObject['Bitumen index (2005=100)'] = bitumenIndex['Index_2005'];
        bitumenIndexObject['Bitumen index (2000=100)'] = bitumenIndex['Index_2000'];
        bitumenIndexObject['Bitumen index (1995=100)'] = bitumenIndex['Index_1995'];
        bitumenIndexExcelData.push(bitumenIndexObject);
      }
    }

    // Commented old code, kept for future reference
    // this.downloadExcel(bitumenIndexExcelData, headerList, "BitumenIndex");

    this.sharedService.downloadDataInExcelFile(bitumenIndexExcelData, 'BitumenIndex', headerList);

  }

  downloadExcel(forecastEntryDetails: any, header: any, sheetName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((forecastEntryDetails), { header: header });
    const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const forecastEntryDetailsBlob: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(forecastEntryDetailsBlob, sheetName + EXCEL_EXTENSION);
  }

  getBitumenIndex(){
    this.bitumenIndexService.getBitumenIndex()
			.subscribe(
				res => {
					if (res["ResponseCode"] == 200) {
            this.bitumeIndexData=res['BitumenIndex'];
            this.bitumeIndexDataCopy=this.bitumeIndexData;
            this.excelData=this.bitumeIndexData;
            this.dataSource = new MatTableDataSource(this.bitumeIndexData);
          
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.displayTable = true;
					} else {
            // console.log("response is: ", res['RequestStatus']);
            this.displayTable = false;
					}
				});
  }
  filterIndex(value) {
    let date = this.sharedService.getCurrentDateTime();
    let year = date.getFullYear();
    if (value == 'Current Year')
      this.bitumeIndexDataCopy = this.bitumeIndexData.filter(item => (item.Year == year));
    if (value == 'Last 2 Years')
      this.bitumeIndexDataCopy = this.bitumeIndexData.filter(item => (item.Year >= (year - 2) && item.Year <= year));
    if (value == 'Last 3 Years')
      this.bitumeIndexDataCopy = this.bitumeIndexData.filter(item => (item.Year >= (year - 3) && item.Year <= year));
    if (value == 'All')
      this.bitumeIndexDataCopy = this.bitumeIndexData;
    this.displayTable = true;
    this.excelData=this.bitumeIndexDataCopy;
    this.dataSource = new MatTableDataSource(this.bitumeIndexDataCopy);
  
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.displayTable = true;
  }

  getStringWithoutSpaces(stringWithSpaces: string): string {
    let stringWithoutSpaces = '';
    stringWithoutSpaces = stringWithSpaces.replace(/\s/g, '');
    return stringWithoutSpaces;
  }
}
 
 
