import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { User } from "../user-management/user";
@Injectable({
  providedIn: "root",
})
export class SettingsService {
  apiUrl = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    }),
  };

  constructor(private httpClient: HttpClient) {}

  updateOrderFullDate(date: any) {
    let dateUrl = this.apiUrl + "/UpdateOrderDate/RestService";
    let postdata: any;
    postdata = JSON.stringify({
      description: date.Description,
      countrycode: "GBR",
      type: "ORDER_FULL_DATE",
    });
    return this.httpClient
      .put<User>(dateUrl, postdata, this.httpOptions)
      .pipe();
  }

  // getUtilityData(ID:any){
  //   let utilityData = this.apiUrl + '/GetOrderFullDate/RestService_2';
  //   return this.httpClient.get(utilityData, {params: {
  //     ID: ID
  //   }}).pipe();
  // }

  getUtilityData(type: string) {
    let url = this.apiUrl + "/GetDisplayMessage";
    return this.httpClient.get(url, {
      params: {
        Type: type,
      },
    });
  }
}
