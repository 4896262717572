<div class="container-fluid sidepadding-none" style="padding: 0%;">
    <div class="row">
        <div class="col-lg-12" *ngIf="!this.mobileQuery.matches">
            <div class="downloadHead">
                <i (click)="downloadExcelData()" class="fas fa-download downloadIcon"><span
                        class="downloadText">{{'HOME.Download'| translate}}</span></i>
            </div>
        </div>
        <div class="row" style="display: flex; justify-content: center;">
            <div class="mt-3" [hidden]="!this.noDisp" style="font-size: larger;">
                {{'HOME.NoData'| translate}}

            </div>
        </div>

        <div class="row col-lg-12" [hidden]="this.noDisp" style="">
            <div class="container-fluid">

                <!-- <div class="row">
            <div class="col-12" style="display:flex; justify-content: center;">
                <span *ngIf="this.role == 'Customer User' || this.role == 'Customer Admin'">
                    <label><span class="monthlyLabel">Customer Name: </span>
                        <span style="font-size: 1em;padding-top: 3px;">{{ customerName }}</span>
                    </label>
                </span>
     
                <div *ngIf="this.role == 'Portal Admin'" class="col-12" style="display: flex; justify-content: center;">
                    <label class="monthlyLabel"><span>Customer Name: &nbsp;</span></label>
                    <select [(ngModel)]="customerUserSelected" class="forecastSelect" style="width: 17%;">
                        <option *ngFor="let customers of Customers" [value]="customers">{{customers}}</option>
                    </select>
                </div>
            </div>
        </div> -->

                <div class="row" style="padding-top: 1%;">
                    <div class="col-auto customer_name_label_div_mv paddingForLabels " *ngIf="this.role !== 'U'">
                        <label for="CustomerId" class="cust_name_label">
                            <span class="monthlyLabel ">{{'HOME.Customername'| translate}}
                            </span>
                        </label>
                    </div>
                    <div class="col-auto customer_name_dropdown_div_mv noPadding">
                        <div *ngIf="this.role == 'PA'">
                            <select class="forecastSelect1 form-control" id="CustomerId"
                                [(ngModel)]='customerIdUserSelected' (change)="this.getDeliverySites()">
                                <!-- <option value="">All</option> -->

                                <option value="">{{'HOME.All'| translate}}</option>

                                <option *ngFor="let customer of Customers" [ngValue]="customer.CustomerID">
                                    {{customer.CustomerName}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="this.role == 'A'">
                            <input type="text" class="forecastInput" [value]="customerUserSelected" disabled />
                        </div>

                    </div>

                    <div class="col-auto del_site_label_div_mv paddingForLabels">
                        <label class="monthlyLabel"> {{'HOME.Deliverysite'| translate}}
                        </label>
                    </div>
                    <div class="col-auto del_site_dropdown_div_MV noPadding">

                        <select [(ngModel)]='delSiteUserSelected'
                            class="forecastSelect del_site_dropdown_MV form-control " (change)="changeOnClick()">
                            <!-- <option value="">All</option> -->

                            <option value="">{{'HOME.All'| translate}}</option>

                            <option *ngFor="let delSit of delSite" [value]="delSit">{{delSit}}</option>
                        </select>
                    </div>   

                    <div class="col-auto year_label_mv paddingForLabels">
                        <label class="monthlyLabel yearLabel "> {{'HOME.Year'| translate}}
                        </label>
                    </div>
                    <div class="col-auto year_dropdown_mv noPadding">
                        <form [formGroup]="historyEntryForm">
                            <select formControlName="year" class="forecastSelect2 form-control"
                                [ngClass]="{ 'is-invalid': submitted && fields.year.errors }" (change)="changeOnClick()"
                                [value]="this.yearUserSelected">
                                <option *ngFor="let year of dropDownYear" [value]="year">{{year}}</option>

                                <div *ngIf="submitted && fields.year.errors" class="invalid-feedback">
                                    <div *ngIf="fields.year.errors"> {{'HOME.Enteryear'| translate}}
                                    </div>
                                </div>
                            </select>
                        </form>
                    </div>
                    </div>
                    <div class="row pt-2">
                    <div class="col-auto from_week_label_div_mv paddingForLabels">
                        <label class="monthlyLabel from_week_label_mv"> {{'HOME.Fromweek'| translate}}
                        </label>
                    </div>
                    <div class="col-auto from_week_dropdown_MV noPadding" >
                        <form [formGroup]="historyEntryForm">
                            <input formControlName="fromWeek" type="number" step="0.01"
                                class="forecastInput form-control from_week_dropdown_MV" min={{minWeek}} max={{maxWeek}}
                                (change)="changeOnClick()" style="width: 45px;"
                                [ngClass]="{ 'is-invalid': submitted && fields.fromWeek.errors && (fields.fromWeek.errors.min || fields.fromWeek.errors.max || fields.fromWeek.errors.pattern) }">
                            <div *ngIf="submitted && fields.fromWeek.errors && (fields.fromWeek.errors.min || fields.fromWeek.errors.max || fields.fromWeek.errors.pattern)"
                                class="invalid-feedback">

                                <!-- Commented old code, kept for future reference -->
                                <!-- <div *ngIf="fields.fromWeek.errors.min || fields.fromWeek.errors.max">
                                    {{'HOME.Enterweekfrom'| translate}}

                                    {{minWeek}} {{'HOME.to'| translate}}

                                    {{maxWeek}}</div> -->

                                    <div *ngIf="fields.fromWeek.errors.min || fields.fromWeek.errors.max">
                                        {{'HOME.Enterweekfromto'| translate: { minWeek: minWeek, maxWeek: maxWeek } }}
                                    </div>

                                <div *ngIf="fields.fromWeek.errors.pattern">
                                    <div>{{'HOME.NoDecimalNumber'| translate}}</div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="col-auto to_week_label_div_mv paddingForLabels">
                        <label class="monthlyLabel"> {{'HOME.Toweek'| translate}}
                        </label>
                    </div>
                    <div class="col-auto to_week_input_div_mv noPadding">
                        <form [formGroup]="historyEntryForm">
                            <input formControlName="toWeek" type="number" step="0.01"
                                class="forecastInput form-control to_week_input_mv" min={{minWeek}} max={{maxWeek}}
                                (change)="changeOnClick()" style="width: 45px;"
                                [ngClass]="{ 'is-invalid': submitted && fields.toWeek.errors && (fields.toWeek.errors.min || fields.toWeek.errors.max || fields.toWeek.errors.pattern) }">
                            <div *ngIf="submitted && fields.toWeek.errors && (fields.toWeek.errors.min || fields.toWeek.errors.max || fields.toWeek.errors.pattern)"
                                class="invalid-feedback">
                                
                                <!-- Commented old code, kept for future reference -->
                                <!-- <div *ngIf="fields.toWeek.errors.min || fields.toWeek.errors.max">
                                    {{'HOME.Enterweekfrom'| translate}} {{minWeek}} {{'HOME.to'| translate}}
                                    {{maxWeek}}</div> -->

                                    <div *ngIf="fields.toWeek.errors.min || fields.toWeek.errors.max">
                                        {{'HOME.Enterweekfromto'| translate: { minWeek: minWeek, maxWeek: maxWeek } }}
                                    </div>

                                <div *ngIf="fields.toWeek.errors.pattern">
                                    <div>{{'HOME.NoDecimalNumber'| translate}}</div>
                                </div>
                            </div>
                        </form>
                    </div>


                    <div class="col-auto find-button-mv" style="padding-top: 0px;">
                        <div class="row" style="float: right">
                            <button class="goButton" type="submit"
                                (click)="fetchOnClick()">{{'HOME.Find'| translate}}</button>
                        </div>
                    </div>

                </div>
            </div>
            
            <div class="global-search-text-box">
                <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
            </div>

            <div class="container">
                <div class="col-lg-12 mt-10" style="padding: 0px;">
                    <div class="row" style="display: flex; justify-content: center;">

                        <div class="mt-5" [hidden]="displayTable"
                            style="display: flex; justify-content: center; font-size: larger;">
                            {{'HOME.Norecordsfound'| translate}}
                        </div>

                        <div class="col-lg-12 mt-1" [hidden]='!displayTable'>
                            <!--Angular 7 Impl-->
                            <mat-table #table [dataSource]="dataSource" matSort
                                class="forecastTable horizontal_scrollbar">
                                <!-- Customer Column -->
                                <ng-container matColumnDef="customerName">
                                    <mat-header-cell class="header-font mat-column-customerName" *matHeaderCellDef
                                        mat-sort-header [ngClass]="' mat_column_customerName '">
                                        {{'HOME.Customername'| translate}}

                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let fetchedDataForItemArray"
                                        class="mat_column_customerName mat-column-customerName">
                                        {{fetchedDataForItemArray.customerName}}
                                    </mat-cell>
                                </ng-container>
                                <!-- delivery Site Column -->
                                <ng-container matColumnDef="deliverySite">
                                    <mat-header-cell class="header-font mat-column-deliverysite" *matHeaderCellDef
                                        mat-sort-header [ngClass]="' mat_column_Deliverysite '">
                                        {{'HOME.Deliverysite'| translate}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let fetchedDataForItemArray"
                                        class=" mat_column_Deliverysite mat-column-deliverysite">
                                        {{fetchedDataForItemArray.deliverySite}}
                                    </mat-cell>
                                </ng-container>
                                <!-- Item Column -->
                                <ng-container matColumnDef="productName">
                                    <mat-header-cell class="header-font mat-column-productName" *matHeaderCellDef
                                        mat-sort-header [ngClass]="' mat_column_productName '">
                                        {{'HOME.Pname'| translate}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let fetchedDataForItemArray"
                                        class="mat_column_productName mat-column-productName">
                                        {{fetchedDataForItemArray.productName}}
                                    </mat-cell>
                                </ng-container>


                                <!-- Depot Column -->
                                <ng-container matColumnDef="depotName">
                                    <mat-header-cell class="header-font mat-column-depotName " *matHeaderCellDef
                                        mat-sort-header [ngClass]="' mat_column_depotName '">
                                        {{'HOME.depotname'| translate}}

                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let fetchedDataForItemArray"
                                        class="mat_column_depotName mat-column-depotName">
                                        {{fetchedDataForItemArray.depotName}}
                                    </mat-cell>
                                </ng-container>


                                <!-- Week Column -->
                                <ng-container matColumnDef="week">
                                    <mat-header-cell class="header-font mat-column-week " *matHeaderCellDef
                                        mat-sort-header [ngClass]="' mat_column_week '">
                                        {{'HOME.Week'| translate}}

                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let fetchedDataForItemArray"
                                        class="mat_column_week mat-column-week">
                                        {{fetchedDataForItemArray.week}}
                                    </mat-cell>
                                </ng-container>

                                <!-- Quantity (metric ton) Column -->
                                <ng-container matColumnDef="forecastquantity">
                                    <mat-header-cell class="header-font mat_column_email" *matHeaderCellDef
                                        mat-sort-header [ngClass]="' mat_column_email mat_column_quantity'">
                                        {{'HOME.Quantitymetricton'| translate}}

                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let fetchedDataForItemArray"
                                        class=" mat_column_quantity mat_column_email">
                                        {{fetchedDataForItemArray.forecastquantity}}
                                    </mat-cell>
                                </ng-container>

                                <!-- Sales Quantity Column -->
                                <!-- <ng-container matColumnDef="salesQuantity">
                                    <mat-header-cell class="header-font mat_column_reminder mat-column-salesquantity " *matHeaderCellDef mat-sort-header
                                        [ngClass]="{'hide-table-column': (this.countryCode && this.countryCode.includes('FIN', 'SWE', 'NOR')) }">
                                        {{'HOME.Salesquantity'| translate}}
                                
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let fetchedDataForItemArray" class="mat-column-salesquantity  mat_column_reminder"
                                        [ngClass]="{'hide-table-column': (this.countryCode && this.countryCode.includes('FIN', 'SWE', 'NOR')) }">
                                        {{fetchedDataForItemArray.actualquantity}}
                                    </mat-cell>
                                </ng-container> -->


                                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            </mat-table>

                            <!-- <div class="row buttonGroup">
                            <button class=" forecastButton" type="submit" (click)="saveHistory()">
                                {{'HOME.Download'| translate}}
                            </button>
                        </div> -->


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
