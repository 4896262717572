<div class="container-fluid sidepadding-none padding-10">

    <div class='col-lg-12'>
        <div class='row'>
            <div class="col-lg-2 mt-10 country_label_div_mv">
                <label class="font-bold font-small" for="countryType">{{'HOME.Country'| translate}}</label>
            </div>
            <div class="col-lg-2 mt-10 country_select_dropdown_div_mv" >

                <select class="countrySelect country_select_dropdown_mv" id="CountryCodeId" [(ngModel)]='countryCode'>
                    <option *ngFor="let country of countryList" [ngValue]="country.CountryID">
                        <!-- {{country.CountryName}} -->
                        {{'HOME.' + country.CountryName | translate}}
                    </option>
                </select>

                <!-- <mat-form-field appearance="outline">
                    <mat-select disableOptionCentering [(value)]="countrySearch" panelClass="select-card">
                        <mat-select-trigger>
                            <span
                                class="flag-icon flag-icon-{{countrySearch | lowercase}} flag-size"></span>{{countryList[countrySearch]}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let country of countryList | keyvalue" [value]="country.key">
                            <span
                                class="flag-icon flag-icon-{{ country.key | lowercase}} flag-size"></span>{{country.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
            <div class="col-lg-8" *ngIf="!this.mobileQuery.matches">
                <div class="downloadHead">
                    <i (click)="downloadDeliveryGuideData()" class="fas fa-download downloadIcon"><span class="downloadText">{{'HOME.Download'| translate}}</span></i>
                </div>
            </div>
        </div>
    </div>

    <div class='col-lg-12 mt-15'>
        <div class="row ">


            <div class="col-lg-2 ">
                <label class="font-bold font-small" for="productType">{{'HOME.pinfo'| translate}}</label>
            </div>
            <div class="col-lg-2">
                <select class="selectDropdown form-control" [(ngModel)]="productTypeToSearch">
                    <option value="">{{'HOME.All'| translate}}</option>
                    <option *ngFor="let product of productType" [value]="product">
                        {{ product }}
                    </option>
                </select>
            </div>
            <div class="col-lg-2 align-right">
                <label class="font-bold font-small" for="productSubType">{{'HOME.ProductCategory'| translate}}</label>
            </div>
            <div class="col-lg-2">
                <select class="selectDropdown form-control" [(ngModel)]="productSubTypeToSearch">
                    <!-- <option *ngFor="let productSub of productSubType;  let in = index;"
                       >
                        {{ productSub }}
                    </option> -->
                    
                    <option value="">{{'HOME.All'| translate}}</option>
                    <option *ngFor="let productSub of productSubType;  let in = index;" [ngValue]="productSub">
                        {{'HOME.' + productSub | translate}}
                    </option>
                </select>
            </div>
            <div class="col-lg-1 align-right">
                <label class="font-bold font-small" for="depot">{{'HOME.Depot'| translate}}</label>
            </div>
            <div class="col-lg-2">
                <select class="selectDropdown form-control" [(ngModel)]="depotLocationToSearch">
                    <option value="">{{'HOME.All'| translate}}</option>
                    <option *ngFor="let depot of depotLocation" [value]="depot.DepotName">
                        {{ depot.DepotName }}
                    </option>
                </select>
            </div>
            <div class="col-lg-1 find-button-mv">
                <!-- <button type="button" class="btn filter-btn" for="filter"
                    (click)=filterDeliveryGuide(deliveryGuideData)>
                    <label class="font-bold font-small" for="filter">{{'HOME.Find'| translate}} </label></button> -->
                    <button class="findButton" (click)="getDeliveryGuideDetails()">{{'HOME.Find'| translate}}</button>
            </div>
            
            <div class="global-search-text-box">
                <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
            </div>

        </div>
    </div>
    <!-- <div class="col-lg-12 mt-15 ">
        <span class='header-font font-bold'>Bitumen products for the period 2019</span>
    </div> -->
    <!-- <div *ngFor='let productHeader of productType' class="col-lg-12">

    <span class="bold-font mt-25">
      {{productHeader}}
    </span>
    <div class="underline-data"></div>
    <div class="col-lg-9">



    </div>



  </div> -->


    <div class="col-lg-12 mt-1">
        <div class='row no-gutters'>
            <div class="mt-5" [hidden]="displayTable">
                {{'HOME.Norecordsfound'| translate}}
            </div>
            <!-- <div class='col-lg-12' *ngIf='deliveryGuideDataCopy.length==0'>
                {{'HOME.NDA'| translate}}
            </div> -->
            <div class="col-lg-12 no-gutters" [hidden]='!displayTable'>

                <mat-table #table [dataSource]="dataSource" matSort class=' horizontal_scrollbar table-material'>
                    <ng-container matColumnDef="PRODUCT_NAME">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Pname'| translate}}

                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.PRODUCT_NAME}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="PRODUCT_TYPE">
                        <!-- With Year Sort<mat-header-cell  class="header-font" *matHeaderCellDef mat-sort-header="Year"> Month-Year -->
                        <mat-header-cell class="header-font mat_column_product_type_mv " *matHeaderCellDef mat-sort-header>
                            {{'HOME.pinfo'| translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray" class=" mat_column_product_type_mv " >{{fetchedDataForItemArray.PRODUCT_TYPE}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="PRODUCT_SUB_TYPE">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header>
                            {{'HOME.ProductCategory'| translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.PRODUCT_SUB_TYPE}}

                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="DEPOT_NAME">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Depot'| translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.DEPOT_NAME}}
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="FROM_DATE">
                        <mat-header-cell class="header-font mat_column_available_from_mv " *matHeaderCellDef>{{'HOME.Availaiblefrom'| translate}}

                        </mat-header-cell>
                        <!-- <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.FROM_DATE | date: "dd-MM-y"}} -->
                        <!-- <mat-cell *matCellDef="let fetchedDataForItemArray" class=" mat_column_available_from_mv " >{{fetchedDataForItemArray.FROM_DATE }}
                        </mat-cell> -->

                        <mat-cell *matCellDef="let fetchedDataForItemArray" class=" mat_column_available_from_mv ">
                            <span *ngIf="getDateObject(fetchedDataForItemArray.FROM_DATE)['date']">
                                {{ getDateObject(fetchedDataForItemArray.FROM_DATE)['date'] }}-{{'HOME.' + this.getDateObject(fetchedDataForItemArray.FROM_DATE)['month'] | translate}}-{{ getDateObject(fetchedDataForItemArray.FROM_DATE)['year'] }}
                            </span>
                        </mat-cell>

                        <!-- <mat-cell *matCellDef="let fetchedDataForItemArray">
                                <input matInput [matDatepicker]="picker" placeholder="Choose">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-cell> -->
                    </ng-container>


                    <ng-container matColumnDef="TO_DATE">
                        <mat-header-cell class="header-font mat_column_available_to_mv " *matHeaderCellDef> {{'HOME.Availaibleto'| translate}}
                        </mat-header-cell>
                        <!-- <mat-cell *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.TO_DATE | date: "dd-MM-y"}}
                        </mat-cell> -->
                        <!-- <mat-cell *matCellDef="let fetchedDataForItemArray" class=" mat_column_available_to_mv " >
                            {{fetchedDataForItemArray.TO_DATE }}
                        </mat-cell> -->

                        <mat-cell *matCellDef="let fetchedDataForItemArray" class=" mat_column_available_from_mv ">
                            <span *ngIf="getDateObject(fetchedDataForItemArray.TO_DATE)['date']">
                                {{ getDateObject(fetchedDataForItemArray.TO_DATE)['date'] }}-{{'HOME.' + this.getDateObject(fetchedDataForItemArray.TO_DATE)['month'] | translate}}-{{ getDateObject(fetchedDataForItemArray.TO_DATE)['year'] }}
                            </span>
                        </mat-cell>

                    </ng-container>
                    <mat-header-row class="table-header" *matHeaderRowDef="tableColumns;sticky: true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>
                </mat-table>
                <mat-paginator   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"   showFirstLastButtons></mat-paginator>
                <!-- <div class="row buttonGroup">
                    <button class=" downloadButton" type="submit" (click)="downloadDeliveryGuideData()">{{'HOME.Download'| translate}}</button>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="container-fluid sidepadding-none  " [hidden]='(deliveryGuideData.length>0)'>
    {{'HOME.DataNotAvailable'| translate}}

</div> -->