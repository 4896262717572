import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DailyReportsService {

  apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient) {
  }

  fetchDailyActualSalesForAll(countryCode: string, startDate: string, endDate: string, customerId: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/FetchDailyActualSales/FetchDailySalesForAll';
    return this.httpClient
      .get(url, {
        params: {
          CountryCode: countryCode,
          StartDate: startDate,
          EndDate: endDate,
          CustomerID: customerId,
          JDE_Address_Number: jdeAddressNumber
        }
      });
  }
}
