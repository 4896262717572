<div  class="container-fluid sidepadding-none">
 <div class="col-lg-12 col-md-12 col-sm-12 main-container">
        <div class="background-image">
            <!-- <div class="col-xs-12 col-sm-12 mobile" >
                     <img src="../../assets/nynas-bit.jpg" />
            </div> -->
            <!-- <p-toast position="top-right"></p-toast> -->

            <!-- Put message to be displayed on banner here -->
            <!-- <div class="banner-text">
                Portal will not be available due to maintenance work from 0600 Hrs CET to 0630 Hrs CET on Tuesday 7th September 2021
            </div> -->

            <div class="banner-text" *ngIf="downTimeMessage">
                {{ downTimeMessage }}
            </div>

            <div class="card center-center">
            <div class="input-group ">
                    <span class="font-bold">Welcome to Bitumen Customer Portal</span>
                </div>

                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fa fa-user"></i></span>
                    </div>
                    <input class="form-control" type="email" placeholder="Username" required [(ngModel)]="this.Username"
                        #UsernameRef="ngModel" (keyup.enter)="loginUser()">
                </div>

                <span class="err-msg" *ngIf="UsernameRef.errors && (UsernameRef.dirty || UsernameRef.touched)"
                    [hidden]="!UsernameRef.errors.required">* Please enter username </span>

                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fa fa-lock "></i></span>
                    </div>
                    <input class="form-control" type="password" placeholder="Password" required
                        [(ngModel)]="this.Password" #PasswordRef="ngModel" (keyup.enter)="loginUser()">
                </div>

                <span class="err-msg" *ngIf="PasswordRef.errors && (PasswordRef.dirty || PasswordRef.touched)"
                    [hidden]="!PasswordRef.errors.required">* Please enter password </span>
                <div class="input-group">
                    <button type="submit" class="btn btn-primary btn-block" (click)="loginUser()"
                        (keyup.enter)="loginUser()">SIGN IN</button>
                </div>

                <span class="err-msg" *ngIf='noUserInput'>Username and Password is required !!</span>
                <span class="err-msg" *ngIf='invalidLogin'>Invalid Login Please try again !!</span>
                <div class="input-group">
                    <!-- <a class="reset-password-text" href="#" > Reset/Forgot Password </a> -->
                    <span class="reset-password-text" (click)="openAlertDialog()">Reset/Forgot Password</span>
                    <!-- <span class="disclaimMsg">Customer Portal is designed for best viewing  in Chrome,Safari and Microsoft Edge browser, It might not work as expected in other browser e.g. Internet Explorer.</span> -->

                    <!-- 
                    <span class="dropdown">
                        <a href="#" data-original-title='Hello' class="box-icons languageIcon" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
              
                        <span *ngIf="selectedLanguage === 'EN'">
                          <img src="/assets/uk.png" class="ukFlagDesign">
                        </span>
                        <span *ngIf="selectedLanguage === 'FI'">
                          <img src="/assets/finland.png" class="ukFlagDesign">
                        </span>
              
                        <ul class="dropdown-menu languageDropdown languageDropdownWidth">
              
                          <li><a class="" #langSelect (click)="updateLanguage('EN')" [rel]="EN"
                              [class.active]="'EN' === translate.currentLang">
                              <img src="/assets/uk.png" class="flagDesign">
                              EN
                            </a></li>
              
                          <hr class="hrDesign">
              
                          <li><a class="" #langSelect (click)="updateLanguage('FI')" [rel]="FI"
                              [class.active]="'FI' === translate.currentLang">
                              <img src="/assets/finland.png" class="flagDesign">
                              FI</a></li>
                        </ul>
              
                      </a>
                      </span> -->



                </div>

            </div>
        </div>
    </div>
</div>