import { Component, OnInit } from '@angular/core';
import { AppService } from '../shared-modules/services/shared.service';
import { MyCompanyService } from './my-company.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-company',
  templateUrl: './my-company.component.html',
  styleUrls: ['./my-company.component.css']
})
export class MyCompanyComponent implements OnInit {

  navbarOpen = false;
  selectedItem: string = 'My company';
  userInfo: any;
  name: string;
  // selectedItem: String;

  isLoggedInUserAdmin: boolean;
  allowedMenuIdList: string[] = [];

  constructor(private sharedService: AppService,
              private myCompanyService : MyCompanyService,
              private router: Router) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.name = this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '';
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.getAllowedMenuIdList(this.userInfo ? this.userInfo.Menus : []);
    }
 
  ngOnInit() {
    this.selectedItem = this.getHeadingFromRoute();
    this.myCompanyService.currentSelectedItem.subscribe(selectedItem => this.selectedItem = selectedItem);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  showSelectedMenuItem(itemName: string) {
    this.selectedItem = itemName;
    this.updateSelectedItem(this.selectedItem);
  }

  updateSelectedItem(selectedItem: string) {
		this.myCompanyService.updateSelectedItem(selectedItem)
	}

  getHeadingFromRoute(): string {
    if (this.router.url == '/my-company/company-details')
      return 'My company';
    if (this.router.url == '/my-company/add-delivery-site')
      return 'Add delivery site';
    if (this.router.url == '/my-company/modify-delivery-site')
      return 'Modify delivery';
  }
  
  validateMenu(menuId: string): boolean {
    return this.allowedMenuIdList.includes(menuId);
  }
  getAllowedMenuIdList(menuList: any) {
    if (menuList && menuList.length) {
      for (var menu of menuList) {
        this.allowedMenuIdList.push(menu.MenuID);
      }
    }
  }
  
}
