<div class="container-fluid">
    <div class="pl-3 pt-2 request-locked-message font-color-red request-locked-message-div_mv" *ngIf="isRequestLocked"> {{ requestLockedMessage }} </div>
    <div class="pl-3 pt-2 request-locked-message request-locked-message-div_mv" *ngIf="hasLoggedInUserLockedThisRequest"> {{ loggedInUserLockedRequestMessage }} </div>
    <form [formGroup]="addOrderForm">
        <div class="row">
            <div class="col-lg-6 order_entry_label_div">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv " for="CustomerId">
                        <span class="input-text-header"> {{'HOME.Customername'| translate}}
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>
                    <div class="order_entry_input_div">
                        <div class="pl-1" *ngIf="!routeFlag && this.isLoggedInUserPortalAdmin">
                         
                            <select class="form-control input-text-select addUserSelect" formControlName="CustomerId"
                                [ngClass]="{ 'is-invalid': submitted && fields.CustomerId.errors }" id="CustomerId"
                                [(ngModel)]='customerID'
                                (change)="this.getDeliverySites();this.getProductDetails()" tabindex="10">
                                <!-- <option value=""> {{'HOME.Selectcustomername'| translate}}
                                </option> -->
                                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                                    {{customer.CustomerName}}
                                </option>
                            </select>
                            <!-- disabled="this.loggedInUserId? undefined : true" -->
                            <!-- disabled="this.role === 'U' && undefinded:true" -->
                        </div>
                      
                        <div class="pl-1" *ngIf="(routeFlag || this.role === 'U' || this.role === 'A') "> 
                            <select class="form-control input-text-select addUserSelect" formControlName="CustomerId"
                                [ngClass]="{ 'is-invalid': submitted && fields.CustomerId.errors }" id="CustomerId"
                                disabled="true">
                                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                                    {{customer.CustomerName}}
                                </option>
                            </select>
                        </div>

                        <div *ngIf="submitted && fields.CustomerId.errors" class="invalid-feedback">
                            <div *ngIf="fields.CustomerId.errors.required">
                                This field is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 order_entry_label_div">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="requestedDate">
                        <span class="input-text-header">Delivery Request Date</span>
                        <span class="mandatory-field pl-1">*</span>
                    </label>

                    <!-- <input type="text" class="form-control input-text" formControlName="Date" id="Date"> -->
                    <div *ngIf="!this.disableFields && !isRequestLocked" class="order_entry_input_div">
                        <mat-form-field [hidden]="orderStatusService === 'C'" class="datepicker">
                            <input matInput readonly [matDatepicker]="picker" placeholder="" formControlName="requestedDate" [min]="todayDate"
                                (focus)="picker.open()" tabindex="12" (dateChange)="validateRequestedDate()"
                                [matDatepickerFilter]="disableWeekendDates">
                            <mat-datepicker-toggle matSuffix [for]="picker" tabindex="-1"></mat-datepicker-toggle>
                            <mat-datepicker #picker disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="fields.requestedDate && fields.requestedDate.touched && fields.requestedDate.errors" class="date-isinvalid no-margin-for-message">
                            This field is required
                        </div>
                        <div *ngIf="dateFlag" class="is-invalid-quantity-message no-margin-for-message">
                            <div> Only future date is allowed
                            </div>
                        </div>
                        <div *ngIf="isRequestedDateOfToday" class="is-invalid-quantity-message no-margin-for-message">
                            Requested date should be greater than today
                        </div>
                        <div *ngIf="isOrderBookFull" class="is-invalid-quantity-message no-margin-for-message order-book-full-message">
                            Order Book full until {{ orderFullDate }}. Please select future date
                        </div>
                    </div>

                    <div *ngIf="orderStatusService === 'C' || this.disableFields || isRequestLocked" class="order_entry_input_hidden_div order_entry_input_hidden_div_mv" >
                        <input type="text" class="form-control input-text" formControlName='reqDateEdit' disabled />
                    </div>
                    
                </div>

            </div>
            <div class="col-lg-6 ptop">

                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="delsite">
                        <span class="input-text-header"> {{'HOME.Deliverysite'| translate}}
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>
                    <div *ngIf="!this.routeFlag" class="order_entry_input_div">
                        <select class="form-control input-text-selectdel addUserSelect" formControlName="delsite"
                            [ngClass]="{ 'is-invalid': submitted && fields.delsite.errors }" id="delsite" [(ngModel)]='deliverySiteUserSelected'
                            (change)="productServiceCheck();getCustPO(deliverySiteUserSelected)" tabindex="11">
                            <option value="">Select Delivery site</option>    
                            <option *ngFor="let delsite of delSiteList"
                                title="{{delsite.AddressLine1}}{{delsite.AddressLine2}}{{delsite.DeliveryPostalCode}}{{delsite.City}}"
                              [ngValue]="delsite.DeliverySite">
                                {{delsite.DeliverySite}}
                        </select>
                        <div *ngIf="submitted && fields.delsite.errors" class="invalid-feedback">
                            <div *ngIf="fields.delsite.errors.required">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.routeFlag" class="order_entry_input_div">
                        <input type="text" class="form-control input-text" formControlName='delSiteEdit' disabled />
                    </div>


                </div>
            </div>

            <!-- Commented old code position of Earliest Time hour , kept for future reference -->
            <!-- <div class="col-lg-6 ptop">

                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="earliestTime">
                        <span class="input-text-header"> Earliest Time hour
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>

                    <div class="order_entry_input_div">
                        <input type="number" min="1" max="24" step="1" placeholder="Enter time in 24hour format" class="form-control input-text"
                            formControlName="earliestTime" [attr.disabled] = "this.disableFields?true: undefined"
                            [ngClass]="{ 'is-invalid': submitted && fields.earliestTime.errors }" id="earliestTime" />


                        <div *ngIf="submitted && fields.earliestTime.errors" class="invalid-feedback">
                            <div *ngIf="fields.earliestTime.errors.required">
                                This field is required
                            </div>
                        </div>
                        <div *ngIf="timeFlag" class="is-invalid-quantity-message">
                            <div> Earliest time should not be greater or equal to Latest time
                            </div>
                        </div>
                        <div *ngIf="timeFlagEarliest" class="is-invalid-quantity-message">
                            <div> Earliest time should not be less than 1    
                            </div>
                        </div>
                        
                    </div>
                </div>



            </div> -->

            <div class="col-lg-6 ptop">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="earliestTime">
                        <span class="input-text-header">{{'HOME.Time'| translate}}
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>
                    <!-- <div class="order_entry_input_div">
                        <input type="number" min="1" max="24" step="1" placeholder="Enter time in 24hour format"
                            class="form-control input-text" formControlName="earliestTime"
                            [attr.disabled]="this.disableFields?true: undefined"
                            [ngClass]="{ 'is-invalid': submitted && fields.earliestTime.errors }" id="earliestTime" />
                        <div *ngIf="submitted && fields.earliestTime.errors" class="invalid-feedback">
                            <div *ngIf="fields.earliestTime.errors.required">
                                This field is required
                            </div>
                        </div>
                        <div *ngIf="timeFlag" class="is-invalid-quantity-message">
                            <div> Earliest time should not be greater or equal to Latest time
                            </div>
                        </div>
                        <div *ngIf="timeFlagEarliest" class="is-invalid-quantity-message">
                            <div> Earliest time should not be less than 1
                            </div>
                        </div>
                    </div> -->

                    <div class="order_entry_input_div">
                        <input class="mr-2" type="radio" formControlName="Time" value="ALLDAY" (change)="changedSelectedTime()"
                            [attr.disabled]="(this.disableFields || isRequestLocked) ? true: undefined" tabindex="13"/>
                        <label for="ALLDAY" class="radio-button-text mr-5">{{'HOME.AllDay'| translate}}</label>
                    
                        <input class="mr-2" type="radio" formControlName="Time" value="AM" (change)="changedSelectedTime()"
                            [attr.disabled]="(this.disableFields || isRequestLocked) ? true: undefined" tabindex="-1" />
                        <label for="AM" class="radio-button-text mr-5">{{'HOME.AM'| translate}}</label>
                    
                        <input class="mr-2" type="radio" formControlName="Time" value="PM" (change)="changedSelectedTime()"
                            [attr.disabled]="(this.disableFields || isRequestLocked) ? true: undefined"  tabindex="-1"/>
                        <label for="PM" class="radio-button-text">{{'HOME.PM'| translate}}</label>
                    </div>

                </div>
            </div>

            <div class="col-lg-6 ptop">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="customerPO">
                        <span class="input-text-header"> Customer PO </span>
                    </label>
                    <div class="order_entry_input_div">
                        <input class="form-control input-text" formControlName="customerPO"
                            [attr.disabled]="(this.disableFields || isRequestLocked) ? true: undefined" tabindex="14">
                    </div>
                </div>
            </div>

            <!-- Commented old code position of Latest Time hour , kept for future reference -->
            <!-- <div class="col-lg-6 ptop">

                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="latestTime">
                        <span class="input-text-header">Latest Time hour
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>

                    <div class="order_entry_input_div">
                        <input type="number" class="form-control input-text" formControlName="latestTime"
                            name="latestTime" placeholder="Enter time in 24hour format" step="1" min="1" max="24" [attr.disabled] =  "this.disableFields?true: undefined"
                            [ngClass]="{ 'is-invalid': submitted && fields.latestTime.errors }" id="latestTime" />
                        <div *ngIf="submitted && fields.latestTime.errors" class="invalid-feedback">
                            <div *ngIf="fields.latestTime.errors.required">
                                This field is required
                            </div>
                        </div>
                        <div *ngIf="timeFlagLatest" class="is-invalid-quantity-message">
                            <div> Latest time should not be less than 1 or greater than 24   
                            </div>
                        </div>

                    </div>
                </div>


            </div> -->

            <div class="col-lg-6 ptop">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="earliestTime">
                        <span class="input-text-header"> Earliest Time hour
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>
                    <div class="order_entry_input_div">
                        <!-- <input type="number" min="1" max="24" step="1" placeholder="Enter time in 24hour format"
                            class="form-control input-text" formControlName="earliestTime"
                            [attr.disabled]="this.disableFields?true: undefined"
                            [ngClass]="{ 'is-invalid': submitted && fields.earliestTime.errors }" id="earliestTime" /> -->

                            <select class="form-control input-text-selectdel addUserSelect" formControlName="earliestTime"
                                [attr.disabled]="(this.disableFields || isRequestLocked) ? true : undefined"
                                [ngClass]="{ 'is-invalid': submitted && fields.earliestTime.errors }" id="earliestTime" tabindex="15">
                                <!-- <option value="">{{'HOME.SelectEarliestTimeHour'| translate}}</option> -->
                                <option *ngFor="let timeHour of timeHourList" [ngValue]="timeHour.key">
                                    {{ timeHour.value }}</option>
                            </select>

                        <div *ngIf="submitted && fields.earliestTime.errors" class="invalid-feedback">
                            <div *ngIf="fields.earliestTime.errors.required">
                                This field is required
                            </div>
                        </div>
                        <div *ngIf="timeFlag" class="is-invalid-quantity-message">
                            <div> Earliest time should not be greater or equal to Latest time
                            </div>
                        </div>
                        <div *ngIf="timeFlagEarliest" class="is-invalid-quantity-message">
                            <div> Earliest time should not be less than 1
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 ptop">
                <div class="row form-group">
                    <!-- Empty placeholder to align Latest Time hour on form -->
                </div>
            </div>
            <div class="col-lg-6 ptop">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="latestTime">
                        <span class="input-text-header">Latest Time hour
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>
                    <div class="order_entry_input_div">
                        <!-- <input type="number" class="form-control input-text" formControlName="latestTime" name="latestTime"
                                                                    placeholder="Enter time in 24hour format" step="1" min="1" max="24"
                                                                    [attr.disabled]="this.disableFields?true: undefined"
                                                                    [ngClass]="{ 'is-invalid': submitted && fields.latestTime.errors }" id="latestTime" /> -->
                    
                        <select class="form-control input-text-selectdel addUserSelect" formControlName="latestTime"
                            [attr.disabled]="(this.disableFields || isRequestLocked) ? true : undefined"
                            [ngClass]="{ 'is-invalid': submitted && fields.latestTime.errors }" id="latestTime" tabindex="16">
                            <!-- <option value="">{{'HOME.SelectLatestTimeHour'| translate}}</option> -->
                            <option *ngFor="let timeHour of timeHourList" [ngValue]="timeHour.key">
                                {{ timeHour.value }}</option>
                        </select>
                    
                        <div *ngIf="submitted && fields.latestTime.errors" class="invalid-feedback">
                            <div *ngIf="fields.latestTime.errors.required">
                                This field is required
                            </div>
                        </div>
                        <div *ngIf="timeFlagLatest" class="is-invalid-quantity-message">
                            <div> Latest time should not be less than 1 or greater than 24
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div *ngIf="this.routeFlag && this.isLoggedInUserPortalAdmin"> -->


            <div *ngIf="this.routeFlag && this.isLoggedInUserPortalAdmin" class="col-lg-6 ptop order_entry_label_div">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="depoName">
                        <span class="input-text-header">Loading point
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>
                    <div class="order_entry_input_div">
                        <select class="form-control input-text-selectdel addUserSelect" formControlName="depoName"
                        (change)="getOrderType();" [attr.disabled] =  "(this.disableFields || isRequestLocked) ? true : undefined"
                            [ngClass]="{ 'is-invalid': submitted && fields.depoName.errors }" id="depoName" tabindex="17">
                            <option value="">{{'HOME.SELECT'| translate}}</option>
                            <option *ngFor="let depoName of depoList" [ngValue]="depoName.DepotID">
                                {{depoName.DepotName}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.depoName.errors" class="invalid-feedback">
                            <div *ngIf="fields.depoName.errors.required">
                                This field is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.routeFlag && this.isLoggedInUserPortalAdmin" class="col-lg-6 ptop">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="reqStatusDesc">
                        <span class="input-text-header">Request status</span>
                    </label>

                    <div class="order_entry_input_div">
                        <input type="text" class="form-control input-text" formControlName="reqStatusDesc"
                            name="reqStatusDesc" id="reqStatusDesc" disabled />
                    </div>
                </div>
            </div>
            <div *ngIf="this.routeFlag && this.isLoggedInUserPortalAdmin" class="col-lg-6 ptop">

                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="orderJDE">
                        <span class="input-text-header">Order#</span>
                    </label>

                    <div class="order_entry_input_div">
                        <input type="text" class="form-control input-text" formControlName="orderJDE" name="orderJDE"
                            id="orderJDE" disabled />
                    </div>
                </div>
            </div>
            <div *ngIf="this.routeFlag && this.isLoggedInUserPortalAdmin" class="col-lg-6 ptop">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="portalRefNumber">
                        <span class="input-text-header">Reference#</span>
                    </label>

                    <div class="order_entry_input_div">
                        <input type="text" class="form-control input-text" formControlName="portalRefNumber"
                            name="portalRefNumber" id="portalRefNumber" disabled />
                    </div>
                </div>
            </div>

            <div *ngIf="this.routeFlag && this.isLoggedInUserPortalAdmin" class="col-lg-6 ptop">

                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="orderType">
                        <span class="input-text-header">Order type</span>
                    </label>

                    <div class="order_entry_input_div">
                        <input type="text" class="form-control input-text" formControlName="orderType" name="orderType"
                            id="orderType" disabled />
                    </div>
                </div>
            </div>

            <div *ngIf="this.routeFlag && this.isLoggedInUserPortalAdmin" class="col-lg-6 ptop">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="frCode">
                        <span class="input-text-header">Freight handling code</span>
                    </label>

                    <div class="order_entry_input_div">
                        <input type="text" class="form-control input-text" formControlName="frCode" name="frCode"
                            id="frCode" disabled />
                    </div>
                </div>
            </div>
            <!-- <div *ngIf="this.routeFlag && this.isLoggedInUserPortalAdmin" class="col-lg-6 ptop">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="pcCode">
                        <span class="input-text-header">PC2 code</span>
                    </label>
                    <div class="order_entry_input_div">
                        <select class="form-control input-text-selectdel addUserSelect" formControlName="pcCode"
                            [ngClass]="{ 'is-invalid': submitted && fields.pcCode.errors }" id="pcCode">
                            <option value="">Select PC2 code</option>
                            <option *ngFor="let pcCode of pcCodeList" [ngValue]="pcCode.PC2Code">
                                {{pcCode.PC2CodeDescription}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.pcCode.errors" class="invalid-feedback">
                            <div *ngIf="fields.pcCode.errors.required">
                                This field is required
                            </div>
                        </div>
                    </div>
                    ...not required
                    <div class="order_entry_input_div">
                          <input type="text" class="form-control input-text" formControlName="pcCode"
                              name="pcCode" id="pcCode" />
                      </div>
                </div>
            </div> -->
            <div class="underline-data"></div>

            <div class="col-lg-6 ptop">

                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="productName">
                        <span class="input-text-header"> {{'HOME.Product'| translate}}
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>
                    <div *ngIf="!this.routeFlag" class="order_entry_input_div">
                        <select class="form-control input-text-selectprod addUserSelect" formControlName="productName"
                            [ngClass]="{ 'is-invalid': submitted && fields.productName.errors }" id="productName" tabindex="18">
                            <option value="">Select Product
                            </option>
                            <option *ngFor="let product of productList" [ngValue]="product.PRODUCT_NAME">
                                {{product.PRODUCT_NAME}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.productName.errors" class="invalid-feedback">
                            <div *ngIf="fields.productName.errors.required">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.routeFlag" class="order_entry_input_div">
                        <input type="text" class="form-control input-text" formControlName='prodEdit' disabled />
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-6 ptop">

                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="currency">
                        <span class="input-text-header"> Currency
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>

                    <div class="order_entry_input_div">
                        <input type="text" class="form-control input-text" id="currency" placeholder="GBR"
                            [attr.disabled]="true" id="currency" />
                    </div>



                </div>


            </div> -->

            <div class="col-lg-6 ptop">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="productQuantity">
                        <span class="input-text-header">Product quantity
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>

                    <div class="order_entry_input_div">
                        <input type="number" class="form-control input-text" formControlName="productQuantity" step="any"
                            id="productQuantity" min="0" [attr.disabled]="(this.disableFields || isRequestLocked) ? true : undefined"
                            [ngClass]="{ 'is-invalid': submitted && fields.productQuantity.errors, 'invalid-product-quantity': (isQuantityLessThan14 || isQuantityEqualToOrMoreThan29) }"
                            tabindex="19" (change)="checkProductQuantityValue()" />
                    
                        <div *ngIf="submitted && fields.productQuantity.errors" class="invalid-feedback">
                            <div *ngIf="fields.productQuantity.errors.required">
                                This field is required
                            </div>
                        </div>
                        <div *ngIf="isRateValueInvalid" class="is-invalid-quantity-message">
                            <div>{{'HOME.Entervalidnumberupto3decimalplacesonly'| translate}}</div>
                        </div>
                        <div *ngIf="isQuantityLessThan14" class="is-invalid-quantity-message">
                            {{'HOME.productquantitylessthan14'| translate}}
                        </div>
                        <div *ngIf="isQuantityEqualToOrMoreThan29" class="is-invalid-quantity-message">
                            {{'HOME.productquantityequaltoormorethan29'| translate}}
                        </div>
                    </div>
                </div>


            </div>

            <div class="col-lg-6 ptop">
                <div class="row form-group">
                    <label class="timeCountlabel timeCountlabel_mv" for="productUOM">
                        <span class="input-text-header">Product UOM
                        </span><span class="mandatory-field pl-1">*</span>
                    </label>
                    <div *ngIf="!this.routeFlag" class="order_entry_input_div">
                        <select class="form-control input-text-selectdel addUserSelect" formControlName="productUOM"
                            [ngClass]="{ 'is-invalid': submitted && fields.productUOM.errors }" id="pcCode" disabled="true">
                            <option value="">Select UOM </option>
                            <option *ngFor="let uom of uomList" [ngValue]="uom.UOM">
                                {{uom.UOMDescription}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.productUOM.errors" class="invalid-feedback">
                            <div *ngIf="fields.productUOM.errors.required">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.routeFlag" class="order_entry_input_div">
                        <input formControlName="uomEdit" class="form-control input-text" id="uomEdit"
                            [attr.disabled]="true">
                    </div>
                </div>
            </div>



            <div class="col-lg-12 ptop">

                <div class="row form-group">
                    <label class="commentslabel commentslabel_mv" for="delsiteComments">
                        <span class="input-text-header">Comments on Delivery ticket (max 225 char allowed)</span>
                    </label>
                    <div class="order_entry_comments order_entry_comments_mv">
                        <textarea class="form-control comments input-text" type="text" formControlName="delsiteComments" maxlength="225"
                            [attr.disabled]="(this.disableFields || isRequestLocked) ? true : undefined" id="delsiteComments"
                            tabindex="20"></textarea>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 ptop">

                <div class="row form-group">
                    <label class="commentslabel commentslabel_mv" for="custComments">
                        <span class="input-text-header">General comments
                        </span>
                    </label>
                    <div class="order_entry_comments order_entry_comments_mv">
                        <textarea class="form-control comments comments_mv input-text" type="text" maxlength="2000"
                            formControlName="custComments" id="custComments" tabindex="21"
                            [attr.disabled]="isRequestLocked ? true : undefined"></textarea>

                    </div>
                </div>
            </div>
            <div *ngIf="this.routeFlag && this.isLoggedInUserPortalAdmin" class="underline-data"></div>
            <div *ngIf="this.routeFlag && this.isLoggedInUserPortalAdmin" class="col-lg-12 ptop">

                <div class="row form-group">

                    <label class="commentslabel commentslabel_mv" for="rComments">
                        <span class="input-text-header">Internal Reviewer comments</span>
                    </label>
                    <div class="order_entry_comments order_entry_comments_mv">
                        <textarea class="form-control comments comments_mv input-text" type="text" maxlength="2000" formControlName="rComments"
                            id="rComments" tabindex="22" [attr.disabled]="isRequestLocked ? true : undefined"></textarea>
                    </div>

                </div>

            </div>
        </div>


    </form>
    <div *ngIf="(this.routeFlag && !this.isLoggedInUserPortalAdmin)|| !this.routeFlag" >
        <div class="row buttonGroup">
            <button
                *ngIf="(source !== 'order' && (this.orderStatus !== '04' && this.orderStatus !== '05' && this.orderStatus !== '06')) && !(isRequestLocked && isOrdersAwaitingApprovalTableRow)"
                class="saveButton saveButton_mv" type="submit" (click)='saveOrderDetails()' tabindex="23">
                {{'HOME.Submit'| translate}}
            </button>
            <button
                *ngIf="(isRequestLocked && isOrdersAwaitingApprovalTableRow)"
                class="saveButton saveButton_mv disabled-button" type="submit" (click)='saveOrderDetails()' tabindex="23" disabled>
                {{'HOME.Submit'| translate}}
            </button>
            <div *ngIf="this.source === 'request'">
                <button
                    *ngIf="(this.orderStatus && this.orderStatus !== '04'  && this.orderStatus !== '05' && this.orderStatus !== '06') && !(isRequestLocked && isOrdersAwaitingApprovalTableRow)"
                    class="cancelButton cancelButton_mv delete-button_mv" (click)='rejectOrder()' tabindex="24">
                    <span *ngIf="this.isLoggedInUserPortalAdmin">Reject</span>
                    <span *ngIf="!this.isLoggedInUserPortalAdmin">Delete</span>
                </button>
                <button
                    *ngIf="(isRequestLocked && isOrdersAwaitingApprovalTableRow)"
                    class="cancelButton cancelButton_mv disabled-button delete-button_mv" (click)='rejectOrder()' tabindex="24" disabled>
                    <span *ngIf="this.isLoggedInUserPortalAdmin">Reject</span>
                    <span *ngIf="!this.isLoggedInUserPortalAdmin">Delete</span>
                </button>
            </div>
            <span [ngClass]="{ 'cancel-button': (source === 'order') }">
                <button *ngIf="this.orderStatus !== '04' && this.orderStatus !== '05' && this.orderStatus !== '06' "
                    class="cancelButton cancelButton_mv" (click)='cancelOrder()' tabindex="25">
                    {{'HOME.Close'| translate}}
                </button>
            </span>

        </div>

        <!-- <div *ngIf="this.orderStatus == '02' ">
                <button class="cancelButton cancelButton_mv" (click)='editOrderReject()'>
                    Reject
                </button>
            </div> -->
        <div *ngIf="(orderStatus === '04' || orderStatus === '05' || orderStatus === '06')"
            class="row buttonGroup cancel-button cancel-button_mv">
            <button class="cancelButton cancelButton_mv" (click)='cancelOrder()' tabindex="26">
                {{'HOME.Close'| translate}}
            </button>
        </div>

    </div>
    <div *ngIf="this.routeFlag && this.isLoggedInUserPortalAdmin" > 
    <div *ngIf="this.source === 'request'" class="row buttonGroup" >
        
        <button class="saveButton saveButton_mv disabled-button" type="submit" (click)='saveOrderDetails()'
            *ngIf="(orderStatus === '04' || orderStatus === '05' || orderStatus === '06' || (isRequestLocked && isOrdersAwaitingApprovalTableRow))"
            disabled>
            Approve
        </button>
        
        <button class="saveButton saveButton_mv" type="submit" (click)='saveOrderDetails()'
            *ngIf="!(orderStatus === '04' || orderStatus === '05' || orderStatus === '06' || (isRequestLocked && isOrdersAwaitingApprovalTableRow))"
            tabindex="27">
            Approve
        </button>
        
        <button class="cancelButton cancelButton_mv disabled-button reject-button_mv" (click)='rejectOrder()'
            *ngIf="(orderStatus === '04' || orderStatus === '05' || orderStatus === '06')" disabled>
            <span *ngIf="this.isLoggedInUserPortalAdmin">Reject</span>
            <span *ngIf="!this.isLoggedInUserPortalAdmin">Delete</span>
        </button>
        <button class="cancelButton cancelButton_mv reject-button_mv" (click)='rejectOrder()'
            *ngIf="!(orderStatus === '04' || orderStatus === '05' || orderStatus === '06')" tabindex="28"
            [ngClass]="{ 'disabled-button': isRequestLocked }" [attr.disabled]="isRequestLocked ? true: undefined">
            <span *ngIf="this.isLoggedInUserPortalAdmin">Reject</span>
            <span *ngIf="!this.isLoggedInUserPortalAdmin">Delete</span>
        </button>
       
        <button class="cancelButton cancelButton_mv" (click)='cancelOrder()' tabindex="29">
            {{'HOME.Close'| translate}}
        </button>
    </div>
    <div *ngIf="this.source === 'order'" class="row btnDiv"> 
        <button class="cancelButton cancelButton_mv" (click)='cancelOrder()' tabindex="30">
            {{'HOME.Close'| translate}}
        </button>
     </div>   
    </div>
    

</div>