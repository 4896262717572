<div class="container-fluid">

    <div class="row">

        <div class="col-lg-12 col-md-12 col-sm-12">

            <div class="">


                <div class="contactHeading ">
                    <span>{{'HOME.ContactInfo' | translate}}</span>
                </div>
                <div class="row">
                    <div class="col-lg-4" style="padding: 0px;">
                    </div>
                    <div class="col-lg-4">
                        <div class="col-lg-12 countrySelect_div_mv " style="padding-top: 10px;">
                            <select class="countrySelect" id="CountryCodeId" [(ngModel)]='countryCode' (change)=getContactUs()>
                                <option *ngFor="let country of countryList" [ngValue]="country.CountryID">
                                    <!-- {{country.CountryName}} -->
                                    {{'HOME.' + country.CountryName | translate}}
                                </option>
                            </select>

                        </div>

                        <!-- <mat-form-field class="drpdwn" appearance="outline">
                            <mat-select disableOptionCentering [(value)]="countrySearch" panelClass="select-card">
                                <mat-select-trigger>
                                    <span
                                        class="flag-icon flag-icon-{{countrySearch | lowercase}} flag-size"></span>{{countryList[countrySearch]}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let country of countryList | keyvalue" [value]="country.key">
                                    <span
                                        class="flag-icon flag-icon-{{ country.key | lowercase}} flag-size"></span>{{country.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                    </div>
                    <div class="col-lg-4" style="padding: 0px;">
                    </div>
                </div>
                <div class="row contact  ">
                    <div class="col-lg-4 contact_rows" *ngFor="let item of user">


                        <div class="usr" *ngIf="item.Contact_Image">
                            <img class="contactDetails" src="{{item.Contact_Image}}" />

                        </div>

                        <div class="usr" *ngIf="!item.Contact_Image">

                            <i class="far fa-user userIconPhoto iconStyle" aria-hidden="true"></i>

                        </div>

                        <div>

                            <div><span class="contactInfo">{{item.Contact_Name}}</span></div>
                            <div><span class="contactInfo">{{item.Designation}}</span></div>

                            <!-- Commented old code, kept for future reference -->
                            <!-- <div><span class="contactInfo">{{item.Phone_Number}}</span></div> -->

                            <div *ngFor="let phoneNumber of item.phoneNumberList"><span class="contactInfo">{{ phoneNumber }}</span></div>

                            <div><span class="contactInfo">{{item.Email_Id}}</span></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>