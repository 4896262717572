import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AppService} from '../../shared-modules/services/shared.service';
import { CommonService } from 'src/app/order-management/order-management/order-management.component.service';
@Component({
  selector: 'app-price-management',
  templateUrl: './price-management.component.html',
  styleUrls: ['./price-management.component.css']
})
export class PriceManagementComponent implements OnInit {

  navbarOpen = false;
  selectedItem: String = 'PRODUCT PRICE';
  userInfo: any;
  name : String;
  allowedMenuIdList: string[] = [];
  constructor( private sharedService: AppService,private router: Router,
    private activatedRoute: ActivatedRoute, private commonService: CommonService) {
      this.userInfo = this.sharedService.getLoginResponse();
      this.name =  (this.userInfo ? (this.userInfo.FirstName + " "+ this.userInfo.LastName) : '' );
    this.getAllowedMenuIdList(this.userInfo ? this.userInfo.Menus : []);
     }

  ngOnInit() {
    this.selectedItem= this.getHeadingFromRoute();
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  showSelectedMenuItem(itemName: String) {
    this.selectedItem = itemName;
  }
  updateSelectedItem(selectedItem: string) {
		this.commonService.updateSelectedItem(selectedItem)
  }
  
  getHeadingFromRoute ():string {
    if(this.router.url=='/price-management/price-data')
    return 'Product price';
    if(this.router.url=='/price-management/price-authors')
    return 'Price notation';
    if(this.router.url=='/price-management/bitumen-index')
    return 'Bitumen index';
  }
  
  validateMenu(menuId: string): boolean {
    return this.allowedMenuIdList.includes(menuId);
  }
  getAllowedMenuIdList(menuList: any) {
    if (menuList && menuList.length) {
      for (var menu of menuList) {
        this.allowedMenuIdList.push(menu.MenuID);
      }
    }
  }
}

