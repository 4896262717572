<div class="">
    <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
        <div class="container">
            <div class="row pl-1 pb-1">
                <div class="col-md-5"></div>
                <div class="col-md-4 pl-5 pt-1 pb-2">
                    <div class="avatar-upload" title=" {{'HOME.clicktoupload'| translate}}">
                        <div class="avatar-edit">
                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                                (change)="uploadFile($event)" />
                        </div>
                        <div class="avatar-preview">
                            <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'"
                                (click)="fileInput.click()"> <i class="fas fa-camera edit-image"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>
        </div>
        <div class="container">
            <div class="row pl-1 pt-2">
                <div class="col-sm">
                    <span class="group-header">{{'HOME.Personaldetails'| translate}}</span>
                    <div class="underline-data"></div>
                </div>
            </div>
            <div class="row pl-1 pt-4 no-gutters">
                <!-- <div class="col-md-2">
                    <div class="form-group">
                        <label for="Title">
                            <span class="input-text-header">Title</span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addUserSelect" formControlName="Title"
                            [ngClass]="{ 'is-invalid': submitted && fields.Title.errors }" id="title"
                            (change)="changeTitle($event)">
                            <option value="">Title</option> 
                            <option *ngFor="let title of Titles" [value]="title">
                                {{title}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.Title.errors" class="invalid-feedback">
                            <div *ngIf="fields.Title.errors.required">Title is required</div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-4 col-sm-4">
                    <div class="form-group">
                        <label for="first_name">
                            <span class="input-text-header">{{'HOME.Firstname'| translate}}</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="FirstName"
                            [ngClass]="{ 'is-invalid': submitted && fields.FirstName.errors }" id="first_name"
                            placeholder="{{'HOME.Firstname'| translate}}">
                        <div *ngIf="submitted && fields.FirstName.errors" class="invalid-feedback">
                            <div *ngIf="fields.FirstName.errors.required">{{'HOME.Firstnameisrequired'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4  col-sm-4 pl-1  ">
                    <div class="form-group">
                        <label for="last_name">
                            <span class="input-text-header">{{'HOME.Lastname'| translate}}</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="LastName"
                            [ngClass]="{ 'is-invalid': submitted && fields.LastName.errors }" id="last_name"
                            placeholder="{{'HOME.Lastname'| translate}}">
                        <div *ngIf="submitted && fields.LastName.errors" class="invalid-feedback">
                            <div *ngIf="fields.LastName.errors.required">{{'HOME.Lastnameisrequired'| translate}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pl-1 pt-2 no-gutters">
                <!-- <div class="col-md-2">
                    <div class="form-group">
                        <label for="Gender">
                            <span class="input-text-header">Gender</span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addUserSelect" formControlName="Gender"
                            [ngClass]="{ 'is-invalid': submitted && fields.Gender.errors }"
                            (change)="changeGender($event)">
                            <option value="">Gender</option>
                            <option *ngFor="let gen of Gender" [value]="gen">
                                {{gen}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.Gender.errors" class="invalid-feedback">
                            <div *ngIf="fields.Gender.errors.required">Gender is required</div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header">{{'HOME.Customername'| translate}}</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addUserSelect" formControlName="CustomerID"
                            [ngClass]="{ 'is-invalid': submitted && fields.CustomerID.errors }" id="customers" (change)="changeCustomerName()"
                            [attr.disabled]="this.isMyProfile || this.isLoggedInUserCustomerAdmin || disableCustomerDropdown">
                            <option value="">{{'HOME.Selectcustomername'| translate}}</option>
                            <option *ngFor="let customer of customerList" [value]="customer.CustomerID">
                                {{customer.CustomerName}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.CustomerID.errors" class="invalid-feedback">
                            <div *ngIf="fields.CustomerID.errors.required">{{'HOME.Customernameisrequired'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-3">
            <div class="row pl-1 pt-2">
                <div class="col-sm">
                    <span class="group-header">{{'HOME.Contactdetails'| translate}}</span>
                    <div class="underline-data"></div>
                </div>
            </div>
            <div class="row pl-1 pt-4">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="primary_contact">
                            <span class="input-text-header">{{'HOME.Primarycontact'| translate}}</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addUserSelect"
                            formControlName="PrimaryContactCountryCode"
                            [ngClass]="{ 'is-invalid': submitted && fields.PrimaryContactCountryCode.errors }"
                            id="primaryContactCountryCode">
                            <option value="">{{'HOME.Selectcode'| translate}}</option>
                            <option *ngFor="let country of countryList" [value]="country.id">
                                {{ country.id }} (+ {{ country.code }} )
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.PrimaryContactCountryCode.errors" class="invalid-feedback">
                            <div *ngIf="fields.PrimaryContactCountryCode.errors.required">
                                {{'HOME.Countrycodeisrequired'| translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="primary_contact">
                            <span>&nbsp;</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="PrimaryContact"
                            [ngClass]="{ 'is-invalid': submitted && fields.PrimaryContact.errors }" id="primary_contact"
                            placeholder="{{'HOME.Primarycontact'| translate}}">
                        <div *ngIf="submitted && fields.PrimaryContact.errors" class="invalid-feedback">
                            <div *ngIf="fields.PrimaryContact.errors.required">
                                {{'HOME.Primarycontactisrequired'| translate}}</div>
                            <div *ngIf="fields.PrimaryContact.errors.pattern">
                                {{'HOME.Pleaseenternumbersonly'| translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="primary_contact">
                            <span class="input-text-header">{{'HOME.Secondarycontact'| translate}}</span>
                        </label>
                        <select class="form-control input-text addUserSelect"
                            formControlName="SecondaryContactCountryCode"
                            [ngClass]="{ 'is-invalid': submitted && fields.SecondaryContactCountryCode.errors }"
                            id="secondaryContactCountryCode">
                            <option value="">{{'HOME.Selectcode'| translate}}</option>
                            <option *ngFor="let country of countryList" [value]="country.id">
                                {{ country.id }} (+ {{ country.code }} )
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.SecondaryContactCountryCode.errors" class="invalid-feedback">
                            <div *ngIf="fields.SecondaryContactCountryCode.errors.required">
                                {{'HOME.Countrycodeisrequired'| translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="primary_contact">
                            <span>&nbsp;</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="SecondaryContact"
                            [ngClass]="{ 'is-invalid': submitted && fields.SecondaryContact.errors }"
                            id="secondary_contact" placeholder="{{'HOME.Secondarycontact'| translate}}">
                        <div *ngIf="submitted && fields.SecondaryContact.errors" class="invalid-feedback">
                            <div *ngIf="fields.SecondaryContact.errors.pattern">
                                {{'HOME.Pleaseenternumbersonly'| translate}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pl-1 pt-2">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.Email'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text width-95" formControlName="EmailID"
                            [ngClass]="{ 'is-invalid': submitted && fields.EmailID.errors }" id="email"
                            placeholder="{{'HOME.Email'| translate}}" [attr.disabled]="this.isMyProfile" />
                        <div *ngIf="submitted && fields.EmailID.errors" class="invalid-feedback">
                            <div *ngIf="fields.EmailID.errors.required">{{'HOME.Emailisrequired'| translate}}</div>
                            <div *ngIf="fields.EmailID.errors.email">{{'HOME.Emailmustbeavalidemailaddress'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="country">
                            <span class="input-text-header"> {{'HOME.Country'| translate}}
                            </span>
                        </label>
                        <div class="input-text pt-1"> {{ countryName }}</div>
                    </div>
                </div>
            </div>
            <!-- <div class="row pl-1 pt-4">
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="address_line1">
                            <span class="input-text-header">{{'HOME.Address'| translate}}</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="AddressLine1"
                            id="address_line1" placeholder="{{'HOME.Line1'| translate}} ">
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="address_line2">
                            <span>&nbsp;</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="AddressLine2"
                            id="address_line2" placeholder="{{'HOME.Line2'| translate}}">
                    </div>
                </div>
            </div> -->
            <div class="row pl-1">
                <!-- <div class="col-md-4">
                    <div class="form-group">
                        <label for="State">
                            <span>&nbsp;</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="State"
                            [ngClass]="{ 'is-invalid': submitted && fields.State.errors }" id="state"
                            placeholder="State">
                        <div *ngIf="submitted && fields.State.errors" class="invalid-feedback">
                            <div *ngIf="fields.State.errors.required">State is required</div>
                        </div>
                        <input type="text" class="form-control input-text" formControlName="State" id="state" placeholder="State">
                    </div>
                </div> -->
                <!-- <div class="col-md-2">
                    <div class="form-group">
                        <label for="zip_code">
                            <span>&nbsp;</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="ZipCode" id="zip_code"
                            placeholder="{{'HOME.PINCode'| translate}}">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="City">
                            <span>&nbsp;</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="City" id="city"
                            placeholder="{{'HOME.City'| translate}}">
                    </div>
                </div> -->

                <div class="col-md-3">
                    <!-- <div class="form-group">
                        <label for="Country">
                            <span>&nbsp;</span>
                        </label>
                        <select class="form-control input-text addUserSelect" formControlName="Country"
                            [ngClass]="{ 'is-invalid': submitted && fields.Country.errors }"
                            (change)="changeCountryName($event)" placeholder="Country">
                            <option value="">{{'HOME.Country'| translate}}</option>
                            <option *ngFor="let country of countryList" [value]="country.id">
                                {{country.name}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.Country.errors" class="invalid-feedback">
                            <div *ngIf="fields.Country.errors.required">{{'HOME.Countryisrequired'| translate}}</div>
                        </div>                       
                    </div> -->
                    <!-- <div>
                        <label class="input-text-header" for="country">{{'HOME.Country'| translate}} : </label>
                        <span class="input-text ml-3"> {{ countryName }}</span>
                    </div> -->
                </div>
            </div>
            <!-- <div class="row pl-1">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="Country">
                            <span>&nbsp;</span>
                        </label>
                        <select class="form-control input-text addUserSelect" formControlName="Country"
                            [ngClass]="{ 'is-invalid': submitted && fields.Country.errors }"
                            (change)="changeCountryName($event)" placeholder="Country">
                            <option value="">Country</option>
                            <option *ngFor="let country of countryList" [value]="country.id">
                                {{country.name}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.Country.errors" class="invalid-feedback">
                            <div *ngIf="fields.Country.errors.required">Country is required</div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div *ngIf="!isMyProfile" class="container mt-3">
            <div class="row pl-1 pt-2">
                <div class="col-sm">
                    <span class="group-header">{{'HOME.Userroles&groups'| translate}}</span>
                    <div class="underline-data"></div>
                </div>
            </div>
            <div class="row pl-1 pt-4">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header">{{'HOME.Username'| translate}}</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="UserID" id="username"
                            placeholder="{{'HOME.Username'| translate}}" disabled="disabled">
                    </div>
                </div>
            </div>
            <div class="row pl-1 pt-2">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="user_group">
                            <span class="input-text-header">{{'HOME.Usergroup'| translate}}</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addUserSelect" (change)="menuAccess()"
                            formControlName="UserGroup"
                            [ngClass]="{ 'is-invalid': submitted && fields.UserGroup.errors }">
                            <option value="">{{'HOME.Usergroup'| translate}}</option>
                            <option *ngFor="let userGroup of userGroups" [value]="userGroup.UserGroupID">
                                {{ userGroup.UserGroupName }}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.UserGroup.errors" class="invalid-feedback">
                            <div *ngIf="fields.UserGroup.errors.required">{{'HOME.Usergroupisrequired'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <span class="input-text"> {{'HOME.accesstomenu'| translate}}
                    </span>
                    <div>
                        <span class="input-text-menu" *ngFor="let menu of menuAccessName">
                            {{'HOME.'+getStringWithoutSpaces(menu) | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="row pl-1 pt-2">
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header">{{'HOME.Userroles'| translate}}</span>
                        </label>
                        <div class="form-check">
                            <input class="form-check-input position-static" type="radio" formControlName="Role"
                                value="U">
                            <span class="radio-button-text">{{'HOME.User'| translate}}</span>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input position-static" type="radio" formControlName="Role"
                                value="A">
                            <span class="radio-button-text">{{'HOME.Admin'| translate}}</span>
                        </div>
                        <!-- <div class="form-check" *ngIf="isLoggedInUserPortalAdmin">
                            <input class="form-check-input position-static" type="radio" formControlName="Role"
                                value="PA">
                            <span class="radio-button-text">{{'HOME.padmin'| translate}}</span>
                        </div>
                        <div class="form-check" *ngIf="isLoggedInUserPortalAdmin">
                            <input class="form-check-input position-static" type="radio" formControlName="Role"
                                value="DA">
                            <span class="radio-button-text">{{'HOME.DepotAdmin'| translate}}</span>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-4 mt-2">
                    <div class="form-group">
                        <label for="">
                        </label>
                        <div class="form-check" *ngIf="isLoggedInUserPortalAdmin">
                            <input class="form-check-input position-static" type="radio" formControlName="Role" value="PA">
                            <span class="radio-button-text">{{'HOME.padmin'| translate}}</span>
                        </div>
                        <div class="form-check" *ngIf="isLoggedInUserPortalAdmin">
                            <input class="form-check-input position-static" type="radio" formControlName="Role" value="DA">
                            <span class="radio-button-text">{{'HOME.DepotAdmin'| translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
        <div [hidden]="!(this.countryCode !== 'FIN' && !isMyProfile && showDeliverySitesTable)" class="container mt-3">
            <div class="row pl-1 pt-2">
                <div class="col-sm">
                    <span class="group-header"> {{'HOME.Deliverysites'| translate}}</span>
                    <div class="underline-data"></div>
                </div>
            </div>
            <div class="row">

                <div class="col-lg-12">

                    <div class="add-user-table scrollbar-add-user delSite-menu-table">

                        <table mat-table #table [dataSource]="dataSourceForDeliverySites" matSort>
                            <ng-container matColumnDef="DeliverySiteName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    [ngClass]="'mat-table-column-header mat-column-delivery-site mat-table-column-header-text'">
                                    {{'HOME.Deliverysites'| translate}}
                                </th>
                                <td mat-cell *matCellDef="let element"
                                    [ngClass]="'mat-column-delivery-site mat-table-column-row-text'">
                                    {{element.DeliverySiteName}}</td>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef
                                    [ngClass]="'mat-table-column-header mat-column-add-remove mat-table-column-header-text'">
                                    {{'HOME.Add/Remove'| translate}}

                                </th>
                                <td mat-cell *matCellDef="let element"
                                    [ngClass]="'mat-column-add-remove mat-table-column-row-text'">
                                    <span class="mat-table-actions-padding">
                                        <span>
                                            <input class="checkbox-add-remove" type="checkbox" [(ngModel)]="element.isStatusActive" >
                                        </span>
                                    </span>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>

                    </div>

                </div>

            </div>
        </div>
        <div class="container">
            <div class="row pl-1 pt-2">
                <div class="col-sm">
                    <button id="btn-save" name="submit" (click)="saveUserDetails()"
                        class="btn btn-primary saveBtn action-button no-gutters">{{'HOME.Save'| translate}}</button>
                </div>
                <div class="col-sm">
                    <button id="btn-cancel" name="submit" class="btn btn-primary cancelBtn action-button1"
                        (click)="cancelUserDetails()">{{'HOME.Cancel'| translate}}</button>
                </div>
            </div>
        </div>
</div>