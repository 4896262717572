<div class="container">
    <div class="row">
        <div class="col-sm text-center">
            <span class="approve-icon"><i class="fas fa-check-circle"></i></span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm text-center">
            <span class="approve-delivery-site-title"> {{'HOME.ApproveDeliverySite'| translate}}
            </span>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col-sm">
            <form [formGroup]="approveDeliverySiteForm">
                <div class="form-group">
                    <label><span class="label-text"> {{'HOME.DeliverySiteName'| translate}}
                    </span></label>
                    <!-- <input type="text" class="form-control text-input" formControlName="DeliverySite" [readonly]="true" /> -->
                    <div class="selected-delivery-site-text">{{ this.deliverySiteName }}</div>
                </div>
                <div class="form-group">
                    <label><span class="label-text">{{'HOME.Addressnumber(Depot)'| translate}}</span></label>

                    <!-- Commented below code, kept for future reference -->
                    <!-- <input formControlName="DepotAddressNumber" class="form-control text-input"  
                    [attr.disabled]="(this.selectedDeliverySite.Status && (this.selectedDeliverySite.Status.toUpperCase() === 'APPROVED' || this.selectedDeliverySite.Status.toUpperCase() === 'REJECTED' || this.selectedDeliverySite.Status.toUpperCase() === 'COMPLETED')) ? true : undefined" /> -->

                    <input formControlName="DepotAddressNumber" class="form-control text-input"
                        [attr.disabled]="!(isLoggedInUserPortalAdmin || isLoggedInUserDepotAdmin) ? true : undefined" />

                </div>
                <div class="form-group">
                    <label><span class="label-text">{{'HOME.Addressnumber(Nynas)'| translate}}</span></label>
                
                    <!-- Commented below code, kept for future reference -->
                    <!-- <input formControlName="JdeAddressNumber" class="form-control text-input"  
                                                                    [attr.disabled]="(this.disableJDEAddressNumberInput || (this.selectedDeliverySite.Status && (this.selectedDeliverySite.Status.toUpperCase() === 'APPROVED' || this.selectedDeliverySite.Status.toUpperCase() === 'REJECTED' || this.selectedDeliverySite.Status.toUpperCase() === 'COMPLETED'))) ? true : undefined" /> -->
                
                    <input formControlName="JdeAddressNumber" class="form-control text-input"
                        [attr.disabled]="(!isLoggedInUserPortalAdmin) ? true : undefined" (keypress)="numberOnly($event)"
                        (paste)="numberOnly($event)" />
                    <mat-error *ngIf="(approveDeliverySiteForm.controls['JdeAddressNumber'].hasError('pattern'))" class="error-message">
                        {{'HOME.Pleaseenternumbersonly'| translate}}
                    </mat-error>
                
                </div>

                <!-- Commented below code, kept for future reference -->
                <!-- <div class="form-group">
                    <label><span class="label-text">Status</span></label>
                    <div class="form-check">
                        <input class="form-check-input position-static" type="radio" formControlName="ApprovalStatus" value="Approved"
                            [attr.disabled]="this.disableJDEAddressNumberInput">
                
                        <span class="radio-button-text"> {{'HOME.Approved'| translate}}
                        </span>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input position-static" type="radio" formControlName="ApprovalStatus" value="Rejected"
                            [attr.disabled]="this.disableJDEAddressNumberInput">
                        <span class="radio-button-text"> {{'HOME.Rejected'| translate}}
                        </span>
                    </div>
                </div> -->

            </form>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col-sm text-center">
            <button class="btn btn-sm btn-primary dialog-footer-button" (click)="approveDeliverySite()"> {{'HOME.Save'| translate}}
            </button>
        </div>
        <div class="col-sm text-center cancel-button-padding">
            <button class="btn btn-sm btn-primary dialog-footer-button" (click)="cancel()"> {{'HOME.Cancel'| translate}}
            </button>
        </div>
    </div>
</div>