import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OrderingProcedureService {

    apiUrl = environment.apiUrl;
    constructor(private httpClient: HttpClient) {
    }
    getOrderingProcedure(countryCode) {
        let url = this.apiUrl + '/GetOrderingProcedure?CountryCode=' + countryCode;
        return this.httpClient
            .get(url);
    }

    getCountries() {
        let url = this.apiUrl + '/getCountry';
        return this.httpClient.get(url);
    }
}


