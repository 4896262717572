import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import * as FileSaver from 'file-saver';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { PendingForecastService } from 'src/app/order-management/pending-forecast/pending-forecast.component.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import * as XLSX from 'xlsx';
import { PriceDataService } from './price-authors.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-price-authors',
  templateUrl: './price-authors.component.html',
  styleUrls: ['./price-authors.component.css']
})
export class PriceAuthorsComponent implements OnInit {
  // displayedColumns: string[] = [ 'ExchangeMonth', 'ConversionRate'  , 'BrentRate' , 'BIHERate' , "BIHEICEBRENT"];
  displayedColumns: string[] = [ 'ExchangeMonth', 'ConversionRate'  , 'BrentRate' , 'BIHERate' ];
  dataSource: any;
  displayTable: boolean = true;
  yearToSearch:any;
  Year = this.sharedService.getCurrentDateTime().getFullYear();
  defaulterListData: any;
  fetchedDataForItemArray = [];
  years: any = [];
  Forecast: any;
  excelData:any;
  priceInformationData: any;
  ExchangeMonth:any =[]; 
  MasterMonth: any = ["JAN" , "FEB" , "MAR" ,"APR" , "MAY" , "JUN","JUL" , "AUG", "SEP" ,"OCT" , "NOV", "DEC"];
  ConversionRate :any =[]; 
  BrentRate:any = [];
  BIHERate:any= [];
  // BIHEICEBRENT:any = [];
  type:string ='MONTHLY';
  errorDesc:string= '';
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  HSFOUSDPerMT:any = [];
  HSFOEURPerMT:any = [];
  submitted: boolean = true;

  constructor(private translate: TranslateService, private exchangeBrentData: PriceDataService, private dialog: MatDialog, private pendingForecastService: PendingForecastService, private sharedService: AppService,
    private cd: ChangeDetectorRef,
    private media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cd.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  
  ngOnInit() {
    // this.years=['2020','2019'];
    // this.yearToSearch=this.years[0];

    // get year list in dropdown
    let currentYear = this.sharedService.getCurrentDateTime().getFullYear();
    this.getYearList(currentYear);

    // get current year by default in year dropdown
    this.yearToSearch = currentYear;
    this.getBrentExchangeData();
  }

  getYearList(currentYear: number) {
    this.years.push(currentYear - 1, currentYear);
  }

   filterIndex(value) {
    this.yearToSearch = value;
    
    this.getBrentExchangeData();
   
  }

  getBrentExchangeData(){
    
    this.exchangeBrentData.getLineChartExhangeData(this.type , this.yearToSearch).subscribe(
 
      res => {
        if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          this.submitted = true;
          this.priceInformationData = res["PriceIndexAndConversionRateCollection"];
          if(this.ExchangeMonth.length > 0){
            this.ExchangeMonth =[];
          }
          if(this.BIHERate.length > 0){
            this.BIHERate =[];
          }
          if(this.BrentRate.length > 0){
            this.BrentRate =[];
          }
          // if(this.BIHEICEBRENT.length > 0){
          //   this.BIHEICEBRENT = [];
          // }
          if (this.ConversionRate.length >0 ){
            this.ConversionRate = [];
          }
          if(this.HSFOUSDPerMT.length > 0){
            this.HSFOUSDPerMT =[];
          }
          if(this.HSFOEURPerMT.length > 0){
            this.HSFOEURPerMT =[];
          }
          /* if (this.MasterMonth.length >0 ){
            this.MasterMonth = [];
            // this.MasterMonth = ["JAN" , "FEB" , "MAR" ,"APR" , "MAY" , "JUN","JUL" , "AUG", "SEP" ,"OCT" , "NOV", "DEC"];
          } */
          this.MasterMonth.forEach(month => {
            let priceDataObject = this.getPriceDataByMonth(month);
            //this.ExchangeMonth.push(month);
            this.translate.get(['HOME.'+ month])
            .subscribe(priceDataObject => {
              this.ExchangeMonth.push(priceDataObject['HOME.'+ month]);
            });
            // console.log("test month"+this.ExchangeMonth);
            this.BIHERate.push(priceDataObject['BIHERate']);
            this.BrentRate.push(priceDataObject['BrentRate']);
            // this.BIHEICEBRENT.push(priceDataObject['BRENT : ICE BRENT'])
            this.ConversionRate.push(priceDataObject['ConversionRate']);
            this.HSFOUSDPerMT.push(priceDataObject['HSFOUSDPerMT']);
            this.HSFOEURPerMT.push(priceDataObject['HSFOEURPerMT']);
          })
          
          this.priceInformationData.forEach(y =>{
            //this.ExchangeMonth.push(y.Month);
           
            //this.ConversionRate.push(y.ConversionRate);
            y['PriceNotation'].forEach(x =>{
              if (x.Name === 'BIHE'){
                y['BIHERate'] = x.Rate;
                //this.BIHERate.push(x.Rate);
              }
              if (x.Name === 'BRENT'){
                y['BrentRate'] = x.Rate;
               // this.BrentRate.push(x.Rate);
              }
              if (x.Name === 'BRENT : ICE BRENT'){
                y['BRENT : ICE BRENT'] = x.Rate;
               // this.BrentRate.push(x.Rate);
              }
              if (x.Name === 'HSFO' && x.UOM === 'USD/MT') {
                y['HSFOUSDPerMT'] = x.Rate;
              }
              if (x.Name === 'HSFO' && x.UOM === 'EUR/MT') {
                y['HSFOEURPerMT'] = x.Rate;
              }
            })
           
          })
          // console.log(this.ExchangeMonth)
          this.lineChartLabels = this.ExchangeMonth;
          this.lineChartLabels1 = this.ExchangeMonth;
          this.lineChartLabels = this.ExchangeMonth;
          this.lineChartLabels1 = this.ExchangeMonth;
          this.lineChartData1 = [
            { data: this.BIHERate, label: 'BIHE (€/MT)',  pointStyle:'line' ,  borderWidth:2, backgroundColor: 'rgba(0, 0, 0, 0)'  }
            
          ];
          this.lineChartData = [
            { data: this.BrentRate,  backgroundColor: 'rgba(0, 0, 0, 0)', label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'ICE Brent notation (USD/bbl)' : 'ICE Brent -noteeraus (USD/bbl)'), pointStyle:'line' ,  borderWidth:2},
            { data: this.ConversionRate,  backgroundColor: 'rgba(0, 0, 0, 0)',label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Conversion Rate (USD/EUR)' : 'Vaihtokurssi (USD/EUR)'), yAxisID: 'y-axis-1',pointStyle:'line', borderWidth:2}
          ];
          this.lineChartData2 = [
            { data: this.HSFOUSDPerMT, backgroundColor: 'rgba(0, 0, 0, 0)', label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Monthly average of Platts HSFO 3,5% FOB Rotterdam Barges, USD/MT' : 'Kuukausittainen keskiarvo, Platts HSFO 3,5% FOB Rotterdam Barges, USD/t'), pointStyle: 'line', borderWidth: 2 },
            { data: this.ConversionRate, backgroundColor: 'rgba(0, 0, 0, 0)', label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Monthly average of conversion rate, USD/EUR' : 'Kuukausittainen keskiarvo, vaihtokurssi, USD/EUR'), yAxisID: 'y-axis-1', pointStyle: 'line', borderWidth: 2 }
          ];
          this.lineChartData3 = [
            { data: this.HSFOEURPerMT, label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Monthly average of Platts HSFO 3,5% FOB Rotterdam Barges, EUR/MT' : 'Kuukausittainen keskiarvo, Platts HSFO 3,5% FOB Rotterdam Barges, EUR/t'), pointStyle: 'line', borderWidth: 2, backgroundColor: 'rgba(0, 0, 0, 0)' }

          ];
          // console.log(this.lineChartLabels)
          this.excelData = this.priceInformationData;
          this.dataSource = new MatTableDataSource(this.priceInformationData);
          this.dataSource.sort = this.sort;
          this.displayTable = true;
          
        } 
        else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
          (res['Description'] ? res['Description'] : null);
          this.errorDesc = res['Description'];
          this.displayTable = false;
        }
        else {
           (res['Description'] ? res['Description'] : null)
           this.errorDesc = res['Description'];
          this.displayTable = false;
        }
       
      });
  }


  getPriceDataByMonth(month: any) {
    let priceObject = {};
    /* priceObject['BIHERate'] = 0;
    priceObject['BrentRate'] = 0;
    priceObject['BRENT : ICE BRENT'] = 0;
    priceObject['ConversionRate']= 0 ; */
    priceObject['BIHERate'] = undefined;
    priceObject['BrentRate'] = undefined;
    priceObject['BRENT : ICE BRENT'] = undefined;
    priceObject['ConversionRate']= undefined;
    priceObject['HSFOUSDPerMT']= undefined;
    priceObject['HSFOEURPerMT']= undefined;

    this.priceInformationData.forEach(y =>{
      if( month.toUpperCase() === y.Month.toUpperCase()){
       
        priceObject['ConversionRate'] = y.ConversionRate;
   
      
      y['PriceNotation'].forEach(x =>{
      
        if (x.Name === 'BIHE'){
          priceObject['BIHERate'] = x.Rate
        
        } else {
          // this.BIHERate.push("0");

        }
        if (x.Name === 'BRENT'){
          priceObject['BrentRate'] = x.Rate
          
        } else {
          // this.BIHERate.push("0");

        }
        if (x.Name === 'BRENT : ICE BRENT'){
          priceObject['BRENT : ICE BRENT'] = x.Rate
         
        } else {
         
        }
        if (x.Name === 'HSFO' && x.UOM === 'USD/MT') {
          priceObject['HSFOUSDPerMT'] = x.Rate
        }
        if (x.Name === 'HSFO' && x.UOM === 'EUR/MT') {
          priceObject['HSFOEURPerMT'] = x.Rate
        }
      })
     
      }
      
     
     
    })
    return priceObject;
  }
  openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess
      },
      height: '37%',
      width: '22%',
    });
  }

  downloadBitumenIndexData(){
    this.pendingForecastService.exportAsExcelFile(this.excelData, "priceIndex");
  }

  showPriceChangeWarningPopUp() {
    this.submitted = false;

    // Commented old code kept for future reference
    /* if (this.yearToSearch > 2020) {
      const dialogRef = this.dialog.open(DialogBodyComponent, {
        data: {
          headerNumber: 'note',
          message: 'price-change-note',
          buttonText: {
            ok: 'Ok'
          },
        },
        // height: '37%',
        height: 'auto',
        width: (this.mobileQuery.matches ? '50%' : '22%'),
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.filterIndex(this.yearToSearch);
        }
      });
    } else {
      this.filterIndex(this.yearToSearch);
    } */

    this.filterIndex(this.yearToSearch);

  }

  download() {
    this.exchangeBrentData.getLineChartExhangeData(this.type, this.yearToSearch).subscribe(
      response => {
        if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          let priceIndexAndConversionRateCollection = response["PriceIndexAndConversionRateCollection"];
          if (priceIndexAndConversionRateCollection && priceIndexAndConversionRateCollection.length) {
            let headerList = ['Month', 'Year', 'USD/EUR exchange rate'];
            let priceNotationDownloadExcel = [];
            priceIndexAndConversionRateCollection.forEach((priceIndexAndConversionRateObject: { any: any }) => {
              let priceNotationDownloadExcelObject = {};
              priceNotationDownloadExcelObject['Month'] = priceIndexAndConversionRateObject['Month'];
              priceNotationDownloadExcelObject['Year'] = priceIndexAndConversionRateObject['Year'];
              priceNotationDownloadExcelObject['USD/EUR exchange rate'] = priceIndexAndConversionRateObject['ConversionRate'];

              let priceNotationList: any = priceIndexAndConversionRateObject['PriceNotation'];

              // Reverse object elements, to match latest records first
              Object.keys(priceNotationList).reverse();

              priceNotationList.forEach((priceNotationObject: { any: any }) => {
                if (priceNotationObject['Name'] === 'HSFO' && priceNotationObject['UOM'] === 'USD/MT') {
                  priceNotationDownloadExcelObject['Platts HSFO 3,5% FOB Rotterdam Barges (USD/MT)'] = priceNotationObject['Rate'];
                }
                if (priceNotationObject['Name'] === 'HSFO' && priceNotationObject['UOM'] === 'EUR/MT') {
                  priceNotationDownloadExcelObject['Platts HSFO 3,5% FOB Rotterdam Barges (EUR/MT)'] = priceNotationObject['Rate'];
                }
                if (priceNotationObject['Name'] === 'BRENT') {
                  priceNotationDownloadExcelObject['ICE brent notation (USD/bbl)'] = priceNotationObject['Rate'];
                }
                if (priceNotationObject['Name'] === 'BIHE') {
                  priceNotationDownloadExcelObject['BIHE (EUR/MT)'] = priceNotationObject['Rate'];
                }
              })
              priceNotationDownloadExcel.push(priceNotationDownloadExcelObject);
            })
            if (this.yearToSearch <= 2020) {
              headerList.push('ICE brent notation (USD/bbl)');
              headerList.push('BIHE (EUR/MT)');
            } else {
              headerList.push('Platts HSFO 3,5% FOB Rotterdam Barges (USD/MT)');
              headerList.push('Platts HSFO 3,5% FOB Rotterdam Barges (EUR/MT)');
            }

            let priceNotationList: any = [];
            if (priceNotationDownloadExcel && priceNotationDownloadExcel.length) {
              for (let priceNotatioObject of priceNotationDownloadExcel) {
                let priceNotationObjectForExcelDownload: any = {};

                // Translate month name for excel
                this.translate.get(['HOME.' + priceNotatioObject['Month']])
                  .subscribe(data => {
                    priceNotationObjectForExcelDownload['Month'] = ((data['HOME.' + priceNotatioObject['Month']]));
                  });
                  
                priceNotationObjectForExcelDownload['Year'] = priceNotatioObject['Year'];
                priceNotationObjectForExcelDownload['USD/EUR exchange rate'] = priceNotatioObject['USD/EUR exchange rate'];
                priceNotationObjectForExcelDownload['Platts HSFO 3,5% FOB Rotterdam Barges (USD/MT)'] = priceNotatioObject['Platts HSFO 3,5% FOB Rotterdam Barges (USD/MT)'];
                priceNotationObjectForExcelDownload['Platts HSFO 3,5% FOB Rotterdam Barges (EUR/MT)'] = priceNotatioObject['Platts HSFO 3,5% FOB Rotterdam Barges (EUR/MT)'];
                priceNotationList.push(priceNotationObjectForExcelDownload);
              }
            }

            // Commented old code, kept for future reference
            // this.downloadExcel(priceNotationDownloadExcel, headerList, "Price notation");

            headerList = ["Month", "Year", "USD/EUR exchange rate", "Platts HSFO 3,5% FOB Rotterdam Barges (USD/MT)", "Platts HSFO 3,5% FOB Rotterdam Barges (EUR/MT)"];

            this.sharedService.downloadDataInExcelFile(priceNotationList, 'Price notation', headerList);
          }
        }
      });
  }

  downloadExcel(forecastEntryDetails: any, header: any, sheetName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((forecastEntryDetails), { header: header });
    const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const forecastEntryDetailsBlob: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(forecastEntryDetailsBlob, sheetName + EXCEL_EXTENSION);
  }

  getGraphAxisLowerLimit(notationType: string, limitType: string): number {
    // Set +50 / -50 limits for left side Y axis (HSFO)
    if (notationType === 'HSFO_USD/MT' && this.sharedService.getMonthlyHSFOUSDPerMTList() && this.sharedService.getMonthlyHSFOUSDPerMTList().length) {
      if (limitType === 'MIN') {
        let minimumRoundedValueOfHSFOUSDPerMT =
          (Math.floor(Math.min.apply(Math, this.sharedService.getMonthlyHSFOUSDPerMTList()))) ?
            ((Math.floor(Math.min.apply(Math, this.sharedService.getMonthlyHSFOUSDPerMTList()))) - 50)
            :
            150;
        return this.getPreviousRoundedValue(minimumRoundedValueOfHSFOUSDPerMT, 50);
      }
      if (limitType === 'MAX') {
        let maximumRoundedValueOfHSFOUSDPerMT =
          (Math.floor(Math.max.apply(Math, this.sharedService.getMonthlyHSFOUSDPerMTList()))) ?
            ((Math.floor(Math.max.apply(Math, this.sharedService.getMonthlyHSFOUSDPerMTList()))) + 50)
            :
            500;
        return this.getNextRoundedValue(maximumRoundedValueOfHSFOUSDPerMT, 50);
      }
    }
    if (notationType === 'HSFO_EUR/MT' && this.sharedService.getMonthlyHSFOEURPerMTList() && this.sharedService.getMonthlyHSFOEURPerMTList().length) {
      if (limitType === 'MIN') {
        let minimumRoundedValueOfHSFOUSDPerMT =
          (Math.floor(Math.min.apply(Math, this.sharedService.getMonthlyHSFOEURPerMTList()))) ?
            ((Math.floor(Math.min.apply(Math, this.sharedService.getMonthlyHSFOEURPerMTList()))) - 50)
            :
            150;
        return this.getPreviousRoundedValue(minimumRoundedValueOfHSFOUSDPerMT, 50);
      }
      if (limitType === 'MAX') {
        let maximumRoundedValueOfHSFOUSDPerMT =
          (Math.floor(Math.max.apply(Math, this.sharedService.getMonthlyHSFOEURPerMTList()))) ?
            ((Math.floor(Math.max.apply(Math, this.sharedService.getMonthlyHSFOEURPerMTList()))) + 50)
            :
            500;
        return this.getNextRoundedValue(maximumRoundedValueOfHSFOUSDPerMT, 50);
      }
    }

    // Set +0.1 / -0.1 limits for right side Y axis (Conversion rate)
    if (notationType === 'CONVERSIONRATE' && this.sharedService.getMonthlyConversionRateList() && this.sharedService.getMonthlyConversionRateList().length) {
      if (limitType === 'MIN') {
        let minimumRoundedValueOfConversionRate =
          Math.min.apply(Math, this.sharedService.getMonthlyConversionRateList()) ?
            ((Math.min.apply(Math, this.sharedService.getMonthlyConversionRateList())) - 0.1)
            :
            0.8;
        return (this.getPreviousRoundedValue((+(minimumRoundedValueOfConversionRate.toFixed(2)) * 100), 5) / 100);
      }
      if (limitType === 'MAX') {
        let maximumRoundedValueOfConversionRate =
          Math.max.apply(Math, this.sharedService.getMonthlyConversionRateList()) ?
            ((Math.max.apply(Math, this.sharedService.getMonthlyConversionRateList())) + 0.1)
            :
            0.2;
        return (this.getNextRoundedValue((+(maximumRoundedValueOfConversionRate.toFixed(2)) * 100), 5) / 100);
      }
    }
  }

  getNextRoundedValue(numberToBeRounded: number, roundingFactor: number): number {
    let roundedValue: number = 0;
    for (var number = numberToBeRounded; number < Infinity; number++) {
      if ((number % roundingFactor) === 0) {
        roundedValue = number;
        break;
      }
    }
    return roundedValue;
  }

  getPreviousRoundedValue(numberToBeRounded: number, roundingFactor: number): number {
    let roundedValue: number = 0;
    for (var number = numberToBeRounded; number > 0; number--) {
      if ((number % roundingFactor) === 0) {
        roundedValue = number;
        break;
      }
    }
    return roundedValue;
  }

  //line chart right start
  public lineChartData: ChartDataSets[] = [
    { data: this.BrentRate,  backgroundColor: 'rgba(0, 0, 0, 0)', label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'ICE Brent notation (USD/bbl)' : 'ICE Brent -noteeraus (USD/bbl)'), pointStyle:'line' ,  borderWidth:2},
    { data: this.ConversionRate,  backgroundColor: 'rgba(0, 0, 0, 0)',label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Conversion Rate (USD/EUR)' : 'Vaihtokurssi (USD/EUR)'), yAxisID: 'y-axis-1',pointStyle:'line', borderWidth:2}
  ];
  public lineChartLabels: Label[] = this.ExchangeMonth;

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
   
    responsive: true,
   
    plugins: {
      datalabels: {
        display: false,
      }
    }, 
    tooltips: {
      mode: 'index'
  },
  legend:{
    position: "bottom",
    display: false,
   
    labels:{
      fontColor: 'black',
      fontSize: 14,
      fontStyle: "bold",
      usePointStyle: true,
      
    }
},
    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        // type: 'time',
        // time: {
        //   displayFormats: {
        //     'day': 'MM/DD'
        //   }
        // },
        stacked: true,
        
        
        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "normal",
        }
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'ICE Brent notation (USD/bbl)' : 'ICE Brent -noteeraus (USD/bbl)')
          },
          stacked: true,
          type: 'linear',
          gridLines: {
            drawBorder: true,
            display: false
        },
          ticks: {
            fontColor: 'black',
            beginAtZero: true,
            fontSize: 10,
           
            suggestedMax: 100
          }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Conversion Rate (USD/EUR)' : 'Vaihtokurssi (USD/EUR)')
          },
          stacked: true,
          type: 'linear',
          gridLines: {
            drawBorder: true,
            display: false
        },
          ticks: {
            fontColor: 'black',
            fontSize: 10,
           
            beginAtZero: true,
          }
        }
      ]
    },
    annotation: { },
  };
  public lineChartColors: Color[] = [
    { 
      borderColor: '#FFD500'
    },
    { 
    borderColor: '#0054A3'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  //line chart right end
 
//Line chart 2
  //line chart start
  public lineChartData1: ChartDataSets[] = [
    { data: this.BIHERate, label: 'BIHE (€/MT)',  pointStyle:'line' ,  borderWidth:2, backgroundColor: 'rgba(0, 0, 0, 0)'  }
    
  ];
  public lineChartLabels1: Label[] = this.ExchangeMonth;
  public lineChartOptions1: (ChartOptions & { annotation: any }) = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
        
      }
     
    },
    
    legend:{
      position: "bottom",
      display: false,
     
      labels:{
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
        
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.

      xAxes: [{
        gridLines: {
          //lineWidth: 0
      },
       stacked: true,
        
        ticks: {
          fontColor: 'black',
          fontSize: 10
        }
      }],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'BIHE (€ / MT)'
          },
          stacked: true,
          type: 'linear',
          gridLines: {
            drawBorder: true,
            display: false
        },
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            fontColor: 'black',
            beginAtZero: true,
            fontSize: 10,
            suggestedMax: 100
          }
          
        }
      ]
    },
    annotation: { },
  };
  public lineChartColors1: Color[] = [
    
    { 
    borderColor: '#18426a'
    }
  ];
  public lineChartLegend1 = true;
  public lineChartType1 = 'line';

  // First line chart for year 2021 onwards start
  public lineChartData2: ChartDataSets[] = [
    { data: this.HSFOUSDPerMT, backgroundColor: 'rgba(0, 0, 0, 0)', label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Monthly average of Platts HSFO 3,5% FOB Rotterdam Barges, USD/MT' : 'Kuukausittainen keskiarvo, Platts HSFO 3,5% FOB Rotterdam Barges, USD/t'), pointStyle: 'line', borderWidth: 2},
    { data: this.ConversionRate, backgroundColor: 'rgba(0, 0, 0, 0)', label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Monthly average of conversion rate, USD/EUR' : 'Kuukausittainen keskiarvo, vaihtokurssi, USD/EUR'), yAxisID: 'y-axis-1', pointStyle: 'line', borderWidth: 2 }
  ];
  public lineChartLabels2: Label[] = this.ExchangeMonth;
  public lineChartOptions2: (ChartOptions & { annotation: any }) = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      }
    },
    tooltips: {
      mode: 'index',
      bodyFontSize: (this.sharedService.getIsMobileDevice() ? 7 : 10),
      bodyFontFamily: 'Arial',
      titleFontSize: (this.sharedService.getIsMobileDevice() ? 8 : 11),
      titleFontFamily: 'Arial',
    },
    legend: {
      position: "bottom",
      display: false,

      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    scales: {
      xAxes: [{
        stacked: true,
        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "normal",
        }
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Monthly average of Platts HSFO 3,5% FOB Rotterdam Barges, USD/MT' : 'Kuukausittainen keskiarvo, Platts HSFO 3,5% FOB Rotterdam Barges, USD/t'),
            fontSize: 6
          },
          stacked: true,
          type: 'linear',
          gridLines: {
            drawBorder: true,
            display: false
          },
          ticks: {
            fontColor: 'black',
            beginAtZero: true,
            fontSize: 10,
            // suggestedMax: 100,

            // Commented old code, kept for future reference
            /* min: 150,
            max: 500 */

            min: this.getGraphAxisLowerLimit('HSFO_USD/MT', 'MIN'),
            max: this.getGraphAxisLowerLimit('HSFO_USD/MT', 'MAX'),
          }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Monthly average of conversion rate, USD/EUR' : 'Kuukausittainen keskiarvo, vaihtokurssi, USD/EUR'),
            fontSize: 6
          },
          stacked: true,
          type: 'linear',
          gridLines: {
            drawBorder: true,
            display: false
          },
          ticks: {
            fontColor: 'black',
            fontSize: 10,
            beginAtZero: true,

            // Commented old code, kept for future reference
            /* min: 0.8,
            max: 0.2 */

            min: this.getGraphAxisLowerLimit('CONVERSIONRATE', 'MIN'),
            max: this.getGraphAxisLowerLimit('CONVERSIONRATE', 'MAX'),
          }
        }
      ]
    },
    annotation: {},
  };
  public lineChartColors2: Color[] = [
    {
      borderColor: '#FFD500'
    },
    {
      borderColor: '#0054A3'
    }
  ];
  public lineChartLegend2 = true;
  public lineChartType2 = 'line';
  // First line chart for year 2021 onwards end

  // Second line chart for year 2021 onwards start
  public lineChartData3: ChartDataSets[] = [
    { data: this.HSFOEURPerMT, label: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Monthly average of Platts HSFO 3,5% FOB Rotterdam Barges, EUR/MT' : 'Kuukausittainen keskiarvo, Platts HSFO 3,5% FOB Rotterdam Barges, EUR/t'), pointStyle: 'line', borderWidth: 2, backgroundColor: 'rgba(0, 0, 0, 0)' }

  ];
  public lineChartLabels3: Label[] = this.ExchangeMonth;
  public lineChartOptions3: (ChartOptions & { annotation: any }) = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      }
    },
    tooltips: {
      mode: 'index',
      bodyFontSize: (this.sharedService.getIsMobileDevice() ? 7 : 10),
      bodyFontFamily: 'Arial',
      titleFontSize: (this.sharedService.getIsMobileDevice() ? 8 : 11),
      titleFontFamily: 'Arial',
    },
    legend: {
      position: "bottom",
      display: false,
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
        },
        stacked: true,
        ticks: {
          fontColor: 'black',
          fontSize: 10
        }
      }],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: (this.sharedService.getSelectedLanguage() !== 'FI' ? 'Monthly average of Platts HSFO 3,5% FOB Rotterdam Barges, EUR/MT' : 'Kuukausittainen keskiarvo, Platts HSFO 3,5% FOB Rotterdam Barges, EUR/t'),
            fontSize: 6
          },
          stacked: true,
          type: 'linear',
          gridLines: {
            drawBorder: true,
            display: false
          },
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            fontColor: 'black',
            beginAtZero: true,
            fontSize: 10,
            // suggestedMax: 100,

            // Commented old code, kept for future reference
            /* min: 150,
            max: 500 */

            min: this.getGraphAxisLowerLimit('HSFO_EUR/MT', 'MIN'),
            max: this.getGraphAxisLowerLimit('HSFO_EUR/MT', 'MAX'),
          }
        }
      ]
    },
    annotation: {},
  };
  public lineChartColors3: Color[] = [
    {
      borderColor: '#18426a'
    }
  ];
  public lineChartLegend3 = true;
  public lineChartType3 = 'line';
  // Second line chart for year 2021 onwards end

}
 
 
