import { Component ,OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';

import { Subscription }   from 'rxjs';
import { AppService } from './shared-modules/services/shared.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'NynasCustomerPortal';
  selectedLanguage: string = 'EN'; // set default language as English before login
  showHead: boolean = false;
  hideCookie:boolean = false;
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
   
 

  ngOnInit() {
   // on route change to '/login', set the variable showHead to false
   this.router.navigate(['']);

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
     
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
       
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
       
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        this.hideCookie = true;
        this.sharedService.setCookie(true);
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        
        // Translate cookie bar to the selected language
        this.translateCookieBar();

        // Reset original cookie flag in order to translate cookie bar on language change
        this.sharedService.setCookie(false);
      });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
  }
  constructor(private router: Router,public translate:TranslateService,private ccService: NgcCookieConsentService , private sharedService : AppService) {
    translate.addLangs(['EN','FI']);
    translate.setDefaultLang('EN');
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/EN|FI/)? browserLang :"EN");
    // on route change to '/login', set the variable showHead to false
      // router.events.forEach((event) => {
      //   if (event instanceof NavigationStart) {
      //     if (event['url'] === '/login' || event['url'] === '/') {
      //       this.showHead = false;
      //     } else {
      //       this.showHead = true;
      //     }
      //   }
      // }); 

      this.translateCookieBar();

      // Moved to method - translateCookieBar
      /* translate .get(['HOME.header', 'HOME.messagecookie', 'HOME.dismiss', 'HOME.allow', 'HOME.deny', 'HOME.link', 'HOME.policy'])
      .subscribe(data => {
 
        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // Override default messages with the translated ones
         this.ccService.getConfig().content.header = data['HOME.header'];
        this.ccService.getConfig().content.messagecookie = data['HOME.messagecookie'];
        this.ccService.getConfig().content.dismiss = data['HOME.dismiss'];
        this.ccService.getConfig().content.allow = data['HOME.allow'];
        this.ccService.getConfig().content.deny = data['HOME.deny'];
        this.ccService.getConfig().content.link = data['HOME.link'];
        this.ccService.getConfig().content.policy = data['HOME.policy'];
      
 
        this.ccService.destroy();//remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      }); */
  }



    
    updateLanguage(selectedLanguage: string) {
      this.selectedLanguage = selectedLanguage;
      this.translate.use(selectedLanguage);
    }

    ngOnDestroy() {
      // unsubscribe to cookieconsent observables to prevent memory leaks
      this.popupOpenSubscription.unsubscribe();
      this.popupCloseSubscription.unsubscribe();
      this.initializeSubscription.unsubscribe();
      this.statusChangeSubscription.unsubscribe();
      this.revokeChoiceSubscription.unsubscribe();
      this.noCookieLawSubscription.unsubscribe();
    }

  translateCookieBar() {
    this.translate.get(['HOME.header', 'HOME.messagecookie', 'HOME.dismiss', 'HOME.allow', 'HOME.deny', 'HOME.link', 'HOME.policy'])
      .subscribe(data => {
        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        this.ccService.getConfig().content.header = data['HOME.header'];
        this.ccService.getConfig().content.messagecookie = data['HOME.messagecookie'];
        this.ccService.getConfig().content.dismiss = data['HOME.dismiss'];
        this.ccService.getConfig().content.allow = data['HOME.allow'];
        this.ccService.getConfig().content.deny = data['HOME.deny'];
        this.ccService.getConfig().content.link = data['HOME.link'];
        this.ccService.getConfig().content.policy = data['HOME.policy'];
        this.ccService.destroy();
        this.ccService.init(this.ccService.getConfig());
      });
  }
}


