<div class="row ">
    <div class="mt-3" [hidden]="!this.noDisp">
        {{'HOME.NDA'| translate}}
    </div>
</div>


<div [hidden]="this.noDisp">


    <div class="container container-align">


        <div class="col-lg-12 sideData  ">
            <!-- <span class="monthavg">{{'HOME.Monthlyaverageof'| translate}} {{this.currentAvgMonth}} - {{this.currentAvgYear}}:</span> -->

            <!-- <span class="monthavg">{{'HOME.Monthlyaverageof'| translate}}
                <span *ngIf="currentAvgMonth === 'January'"> {{'HOME.JANUARY'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'February'"> {{'HOME.FEBRUARY'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'March'"> {{'HOME.MARCH'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'April'"> {{'HOME.APRIL'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'May'"> {{'HOME.MAY'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'June'"> {{'HOME.JUNE'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'July'"> {{'HOME.JULY'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'August'"> {{'HOME.AUGUST'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'September'"> {{'HOME.SEPTEMBER'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'October'"> {{'HOME.OCTOBER'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'November'"> {{'HOME.NOVEMBER'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'December'"> {{'HOME.DECEMBER'| translate}} </span>
                - {{this.currentAvgYear}}:
            </span> -->

            <!-- Commented old logic to display month name, since it does not work in iphone -->
            <!-- <span class="monthavg">{{'HOME.Monthlyaverageof'| translate}}
                <span *ngIf="currentAvgMonth === 'January'"> {{'HOME.Jan'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'February'"> {{'HOME.Feb'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'March'"> {{'HOME.Mar'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'April'"> {{'HOME.Apr'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'May'"> {{'HOME.May'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'June'"> {{'HOME.Jun'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'July'"> {{'HOME.Jul'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'August'"> {{'HOME.Aug'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'September'"> {{'HOME.Sep'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'October'"> {{'HOME.Oct'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'November'"> {{'HOME.Nov'| translate}} </span>
                <span *ngIf="currentAvgMonth === 'December'"> {{'HOME.Dec'| translate}} </span>
                - {{this.currentAvgYear}}:
            </span> -->


                <span class="monthavg">{{'HOME.Monthlyaverageof'| translate}} {{'HOME.' + this.getPreviousMonthName() |
                    translate}} - {{this.currentAvgYear}}:</span>

            <div class="row Bihedata">
                <div class="row col-5 Exchange_Rate label_MV">
                    <!-- <span class="col-10 ">
                        {{'HOME.USD/EUR'| translate}}
                    </span>
                    <span class="col-2  ">
                       {{ConversionRate | toNumber | number: '1.2-4'}}
                    </span> -->

                    <div class="col-10 Exchange_Rate_label_mv">
                        {{'HOME.USD/EUR'| translate}}
                    </div>
                    <div class="col-2  Exchange_Rate_number_mv">
                       {{ConversionRate | toNumber | number: '1.2-4'}}
                    </div>
                </div>                 
                <div class="row col-7 Brent_Indexed_ICE BI_margin_MV">
                    <span class="col-10 BI_MV " *ngIf="this.yearUserSelected <= 2020">
                        {{'HOME.BrentIndex'| translate}}
                    </span>
                    <span class="col-10 BI_MV " *ngIf="this.yearUserSelected > 2020">
                        {{'HOME.PlattsHSFO35FOBRotterdamBargesUSDperMT'| translate}}
                    </span>
                    <span class="col-2 Ice_brent" *ngIf="this.yearUserSelected <= 2020">
                        {{ ICEBrentRate | toNumber | number: '1.2-3' }}
                    </span>
                    <span class="col-2 Ice_brent" *ngIf="this.yearUserSelected > 2020">
                        {{ HSFOUSDPerMT | toNumber | number: '1.2-3' }}
                    </span>
                    <!-- <div class="col-10 BI_MV">
                        {{'HOME.BrentIndex'| translate}}
                    </div>
                    <div class="col-2  ">
                        {{ ICEBrentRate | toNumber | number: '1.2-3' }}
                    </div> -->
                </div>
            </div>
            <div class="row Bihedata">
                <div class="row col-5 ICE_Front ice_MV">
                    <!-- <span class="col-10 ice_padding_MV">
                        {{'HOME.ICE'| translate}}
                    </span>
                    <span class="col-2  ">
                        {{ BrentRate | toNumber | number: '1.2-3' }}
                    </span> -->
                    <div class="col-10 ice_padding_MV ICE_Front_label_mv" *ngIf="this.yearUserSelected <= 2020">
                        {{'HOME.ICE'| translate}}
                    </div>
                    <div class="col-2 ICE_Front_number_mv " *ngIf="this.yearUserSelected <= 2020">
                        {{ BrentRate | toNumber | number: '1.2-3' }}
                    </div>
                </div>
                <div class="row col-7 Brent_Indexed brent_MV">
                    <!-- <span class="col-10 brent_padding_MV">
                        {{'HOME.Brentrate1'| translate}}

                    </span>
                    <span class="col-2 brent_number ">
                        {{ BIHERate | toNumber | number: '1.2-2'}}
                    </span> -->
                    <div class="col-10 brent_padding_MV Brent_Indexed_mv" *ngIf="this.yearUserSelected <= 2020">
                        {{'HOME.Brentrate1'| translate}}
                    </div>
                    <span class="col-2 brent_number " *ngIf="this.yearUserSelected <= 2020">
                        {{ BIHERate | toNumber | number: '1.2-2'}}
                    </span>
                    <div class="col-10 brent_padding_MV Brent_Indexed_mv" *ngIf="this.yearUserSelected > 2020">
                        {{'HOME.PlattsHSFO35FOBRotterdamBargesEURperMT'| translate}}
                    
                    </div>
                    <div class="col-2 brent_number Brent_Indexed_mv" *ngIf="this.yearUserSelected > 2020">
                        {{ HSFOEURPerMT | toNumber | number: '1.2-2'}}
                    </div>
                </div>
            </div>

        </div>
        <div class="row toggle">
            <div class="col-lg-4 mr-5">
                <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                    #group="matButtonToggleGroup" [(ngModel)]="priceValue" value="product-prices">
                    <mat-button-toggle value="product-prices" ngDefaultControl class="" (click)="toggleFuntion()" >
                        {{'HOME.Productprice'| translate}}
                    </mat-button-toggle>
                    <mat-button-toggle value="adhesive-price" ngDefaultControl class="" (click)="toggleFuntion()" >

                        {{'HOME.Adhesiveprice'| translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="cust_align_MV" *ngIf="this.role !== 'U'">
                <label for="CustomerId">
                    <span class="monthlyLabel"> {{'HOME.Customername'| translate}}
                    </span>
                </label>
            </div>
            <div class="col-auto cust_drop_MV">
                <div *ngIf="this.role == 'PA'">
                    <select class="forecastSelect form-control" id="CustomerId" [(ngModel)]='customerIdUserSelected'
                        (change)="changeOnClick()">
                        <option *ngFor="let customer of Customers" [ngValue]="customer.CustomerID">
                            {{customer.CustomerName}}
                        </option>
                    </select>
                </div>
                <div *ngIf="this.role == 'A'">
                    <input type="text" class="forecastInput" [value]="customerUserSelected" disabled />
                </div>

            </div>
            <div class="month_label_MV">
                <label class="monthlyLabel2"> {{'HOME.Month'| translate}}
                </label>
            </div>
            <div class="col-auto month_dropdown_MV ">
                <select class="forecastSelect1 form-control" [(ngModel)]="this.currentMonth" (change)="monthAvgDropdown()">
                    <!-- <option *ngFor="let month of months" [value]="month">{{month}}</option> -->
                    <!-- <option value="January"> {{'HOME.JANUARY'| translate}} </option>
                    <option value="February"> {{'HOME.FEBRUARY'| translate}} </option>
                    <option value="March"> {{'HOME.MARCH'| translate}} </option>
                    <option value="April"> {{'HOME.APRIL'| translate}} </option>
                    <option value="May"> {{'HOME.MAY'| translate}} </option>
                    <option value="June"> {{'HOME.JUNE'| translate}} </option>
                    <option value="July"> {{'HOME.JULY'| translate}} </option>
                    <option value="August"> {{'HOME.AUGUST'| translate}} </option>
                    <option value="September"> {{'HOME.SEPTEMBER'| translate}} </option>
                    <option value="October"> {{'HOME.OCTOBER'| translate}} </option>
                    <option value="November"> {{'HOME.NOVEMBER'| translate}} </option>
                    <option value="December"> {{'HOME.DECEMBER'| translate}} </option> -->
                    <option value="January"> {{'HOME.Jan'| translate}} </option>
                    <option value="February"> {{'HOME.Feb'| translate}} </option>
                    <option value="March"> {{'HOME.Mar'| translate}} </option>
                    <option value="April"> {{'HOME.Apr'| translate}} </option>
                    <option value="May"> {{'HOME.May'| translate}} </option>
                    <option value="June"> {{'HOME.Jun'| translate}} </option>
                    <option value="July"> {{'HOME.Jul'| translate}} </option>
                    <option value="August"> {{'HOME.Aug'| translate}} </option>
                    <option value="September"> {{'HOME.Sep'| translate}} </option>
                    <option value="October"> {{'HOME.Oct'| translate}} </option>
                    <option value="November"> {{'HOME.Nov'| translate}} </option>
                    <option value="December"> {{'HOME.Dec'| translate}} </option>
                </select>

            </div>
            <div class="col-auto year_label_MV " style="padding: 0%; ">
                <label class="monthlyLabel1"> {{'HOME.Year'| translate}}
                </label>
            </div>
            <div class="col-auto year_drop_MV" style="padding: 0%;">

                <select [(ngModel)]=" this.yearUserSelected" class="yearSelect form-control" (change)="monthAvgDropdown()">
                    <option *ngFor="let year of dropDownYear" [value]="year">{{year}}</option>

                </select>

            </div>

            <!-- Commented below code, kept for future reference -->
            <!-- <div class="col-auto find_MV" style="padding-top: 0%;">
                <div class="row">
                    <button class="goButton" type="submit" (click)="fetchData1(yearUserSelected, currentMonth)"> {{'HOME.Find'| translate}}
                    </button>
                </div>
            </div> -->

        </div>
        <div class="row" style="padding-top: 1%;">



        </div>
        <div class="row prodPriceInfo">
            <span>
                {{'HOME.*Price€/MT'| translate}}
            </span>
            
        </div>
            
        <div class="global-search-text-box" [hidden]='priceValue === "adhesive-price"'>
            <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
        </div>
     
            <!-- <span [hidden]='priceValue !== "adhesive-price"' class="row prodPriceInfoAd">
                {{'HOME.AdhesivepercentisContentm'| translate}}
            </span> -->
            
      

        <!-- <div>
            <app-product-prices *ngIf="group.value == 'product-prices'"></app-product-prices>

            <app-adhesive-price *ngIf="group.value == 'adhesive-price'"></app-adhesive-price>

        </div> -->
    </div>

    <div class=""  [hidden]='priceValue !== "product-prices"'>

        <div class="container table1">
            <div class="col-lg-12 table2">
                <div class="row no-gutters">
                    <div class="mt-5" [hidden]="displayTable" style="font-size: larger;">
                        {{'HOME.Norecordsfound'| translate}}
                    </div>
                    <div class="col-lg-12 no-gutters align-table" [hidden]='!displayTable'>

                        <mat-table 
                        #firsttable 
                        #productPricesTableSort="matSort"
                        [dataSource]="dataSource2" matSort
                            class='table-material forecastTable horizontal_scrollbar'>


                            <ng-container *ngFor="let disCol of displayedColumnsProductPrice; let colIndex = index"
                                matColumnDef="{{disCol}}">
                                <mat-header-cell class="header-font header" *matHeaderCellDef mat-sort-header>
                                    <!-- {{disCol }}  -->
                                    <span *ngIf='disCol === "ProductName"'> {{'HOME.Pname'| translate}}</span>
                                    <span *ngIf='disCol !== "ProductName"'>{{disCol}} </span> 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let item ">
                                    <ng-container *ngIf='disCol!="ProductName"'>
                                        {{item[disCol] | currency: 'EUR':'symbol':undefined:'fr-FR'}}
                                    </ng-container>
                                    <ng-container *ngIf='disCol=="ProductName"'>{{item[disCol] }}
                                    </ng-container>
                                </mat-cell>
                            </ng-container>



                            <mat-header-row class="table-header"
                                *matHeaderRowDef="displayedColumnsProductPrice; sticky:true">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsProductPrice;"></mat-row>

                        </mat-table>
                        <mat-paginator #TableOnePaginator="matPaginator"   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"   showFirstLastButtons></mat-paginator>

                        <div class="row buttonGroup">
                            <button class="cancelButton" id="submit" name="submit" (click)="downloadProductPriceData()">
                                {{'HOME.Download'| translate}}</button>
                        </div>

                    </div>

                </div>

            </div>
        </div>

    </div>
    <!-- adhesive price value -->
    <div class=""  [hidden]='priceValue !== "adhesive-price"'>
        <div class="container table1">
            <div class="col-lg-12 table2">
                <div class="row no-gutters">
                    <div class="mt-5" [hidden]="displayTable1" style="font-size: larger;">
                        {{'HOME.Norecordsfound'| translate}}
                    </div>
                    <div class="col-lg-12 no-gutters align-table" [hidden]='!displayTable1'>


                        <mat-table #secondtable 
                        #adhesiveProductPriceTableSort ="matSort"
                        [dataSource]="dataSource1" matSort
                            class='table-material forecastTable horizontal_scrollbar'>




                            <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index"
                                matColumnDef="{{disCol}}">
                                <mat-header-cell class="header-font header" *matHeaderCellDef mat-sort-header>
                                    <span *ngIf='disCol === "AdhesivePercent"'> {{'HOME.contentMPercent'| translate}}</span>
                                   <span *ngIf='disCol !== "AdhesivePercent"'>{{disCol}} </span> 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let item ">
                                    <ng-container *ngIf='disCol!="AdhesivePercent"'>
                                        {{item[disCol] | currency: 'EUR':'symbol':undefined:'fr-FR'}}
                                    </ng-container>
                                    <ng-container *ngIf='disCol=="AdhesivePercent"'>{{item[disCol] |number }}
                                    </ng-container>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns;sticky: true">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>

                        <mat-paginator #TableTwoPaginator="matPaginator"   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"  showFirstLastButtons></mat-paginator>

                        <div class="row buttonGroup">
                            <button class=" cancelButton" type="submit" (click)="downloadAdhesiveProductPriceData()">
                                {{'HOME.Download'| translate}}
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>