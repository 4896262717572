import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../shared-modules/services/shared.service';
import { AuthService } from './auth.service';

@Injectable()
export class RoleGuard implements CanActivate {
  userInfo: any;

  constructor(private _authService: AuthService, private _router: Router, private sharedService: AppService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Uncomment once token is obtained in _authService
    /* const user = this._authService.decode();

    if (user.Role === next.data.role) {
      return true;
    } */

    this.userInfo = this.sharedService.getLoginResponse();

    if (!this.userInfo) {
      // navigate to login page if not authenticated
      this._router.navigate(['/login']);
      return false;
    }

    // navigate to respective page, only if role matches with logged in user role or if role is not assigned to that page
    /* if (this.userInfo && ((next.data.role && this.userInfo.RoleID === next.data.role) || !next.data.role)) {
      return true;
    } */

    // navigate to respective page, if role is not assigned to that page
    if (!next.data.role) {
      return true;
    }

    // navigate to respective page, only if role matches with logged in user role
    if (next.data.role && next.data.role.length) {
      for (var role of next.data.role) {
        if (this.userInfo.RoleID === role) {
          return true;
        }
      }
    }

    // navigate to login page, if access to page not allowed for the logged in user
    this._router.navigate(['/login']);
    return false;
  }

}