import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ForecastActualMonthlySwedenService {

  apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient) {
  }
  getCustomerList(countryCode) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }
  getDeliverySiteForUser(UserID: string, CountryCode: any, MappingStatus: any, CustomerID: any) {

    let delSiteUrl = this.apiUrl + '/GetDeliverySiteForUser';

    return this.httpClient
      .get(delSiteUrl, {
        params: {
          UserID: UserID,
          CountryCode: CountryCode,
          MappingStatus: MappingStatus,
          CustomerID: CustomerID
        }
      });
  }

  getDeliverySite(CustomerId: string, Status: string, countryCode: string) {

    let deliveryUrl = this.apiUrl + '/FetchDeliverySiteService';
    return this.httpClient
      .get(deliveryUrl, {
        params: {
          CustomerId: CustomerId,
          ApprovalStage: Status,
          CountryCode: countryCode
          //Hardcoded Check @
          //CustomerName:  'YIT Kotka Ilarila'
        }
      });
  }

  getTableDataForDepotAndProduct(CustomerID: string, Year: string, countryCode: any, DeliverySite: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetPlannedVsActualByDepotAndProduct';
    return this.httpClient
      .get(url, {
        params: {
          CustomerID: CustomerID,
          Year: Year,
          CountryCode: countryCode,
          DeliverySite: DeliverySite,
          JDEAddressNumber: jdeAddressNumber
        }
      });

  }

  getBarChartData(Year: string, CustomerID: string, DeliverySite: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetPlannedVSActualForAll';
    return this.httpClient
      .get(url, {
        params: {
          Year: Year,
          CustomerID: CustomerID,
          DeliverySite: DeliverySite,
          JDEAddressNumber: jdeAddressNumber
        }
      });
  }

  getTableDataForAll(Year: string, CustomerID: string, DeliverySite: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetPlannedVsActualForAll_Table';
    return this.httpClient
      .get(url, {
        params: {
          Year: Year,
          CustomerID: CustomerID,
          DeliverySite: DeliverySite,
          JDEAddressNumber: jdeAddressNumber
        }
      });
  }

  getTableDataForDepot(Year: string, CustomerID: string, DeliverySite: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetPlannedVsActualByDepot';
    return this.httpClient
      .get(url, {
        params: {
          Customer_ID: CustomerID,
          Year: Year,
          DeliverySite: DeliverySite,
          JDEAddressNumber: jdeAddressNumber
        }
      });

  }

  getTableDataForProduct(Year: string, CustomerID: string, countryCode: any, DeliverySite: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetPlannedVsActualForProduct';
    return this.httpClient
      .get(url, {
        params: {
          Customer_ID: CustomerID,
          Year: Year,
          CountryCode: countryCode,
          DeliverySite: DeliverySite,
          JDEAddressNumber: jdeAddressNumber
        }
      });

  }

  getTableDataForDelSite(Year: string, CustomerID: string) {
    let url = this.apiUrl + '/GetPlannedVsActualByDeliverySite';
    return this.httpClient
      .get(url, {
        params: {
          Customer_ID: CustomerID,
          Year: Year,

        }
      });

  }

  getCustomerList1(countryCode) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }

  public exportAsExcelFileForAll(json: any[], excelFileName: string): void {
    if (json) {
      const  worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  public exportAsExcelFileForProduct(json: any[], excelFileName: string): void {
    if (json) {
      const  worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json),{header:["ProductName","Type","Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Total"]});
      
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  public exportAsExcelFileForDepot(json: any[], excelFileName: string): void {
    if (json) {
      const  worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json),{header:["DepotName","Type","Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Total"]});
      
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  public exportAsExcelFileForDeliverySite(json: any[], excelFileName: string): void {
    if (json) {
      const  worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json),{header:["DeliverySite","Type","Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Total"]});
      
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  public exportAsExcelFileForProductAndDepot(json: any[], excelFileName: string): void {
    if (json) {
      const  worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json),{header:["ProductName","DepotName","Type","Jan", "Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec","Total"]});
      
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const Report: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(Report, fileName + EXCEL_EXTENSION);
  }


}
