import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ProductInformationFilterPipe } from '../../shared-modules/pipes/product-information-filter.pipe';
import { saveAs } from 'file-saver/dist/FileSaver';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductInformationService } from "./product-information.service";
import { DialogBodyComponent } from '../../user-management/dialog-body/dialog-body.component';
import { QualityCertificateComponent } from 'src/app/quality-certificate/quality-certificate.component';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-information',
  templateUrl: './product-information.component.html',
  styleUrls: ['./product-information.component.css']
})
export class ProductInformationComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  productsInformationData: any;
  productsInformationDataCopy: any;
  productType: any;
  productSubType: any;
  depotLocation: any;
  view = 'Grid';
  productTypeToSearch: any;
  productSubTypeToSearch: any;
  depotLocationToSearch: any;
  displayIndex = 1;
  displayedColumns: string[] = ['PRODUCT_NAME', 'PRODUCT_TYPE', 'PRODUCT_SUB_TYPE', 'productInformation', 'SAFETY_DATA', 'CE_MARK', 'PERFORMANCE_REPORT', 'qualityCertificate'];
  dataSource: any;
  displayTable: boolean = true;
  displayView: boolean = true;
  productData: any;
  userInfo: any;
  username: string;
  role: any;
  customerID: any;
  customerName: any;
  isLoggedInUserAdmin: boolean;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  All: string = 'All';
  countryCode: any;
  constructor(private productInformationService: ProductInformationService,
    private dialog: MatDialog, private filterData: ProductInformationFilterPipe,
    private sharedService: AppService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private media: MediaMatcher
  ) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.username = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.role = (this.userInfo ? this.userInfo.RoleID : '');
    this.customerID = (this.userInfo ? this.userInfo.CustomerID : '');
    this.customerName = (this.userInfo ? this.userInfo.CustomerName : '');
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.countryCode = this.userInfo.CountryCode;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    //this.callSharepoint();
    this.depotLocationToSearch = '';
    this.productSubType = ['All', 'Regular', 'Extra', 'Premium'];
    this.productType = ['All', 'Paving Bitumen', 'Adhesive Bitumen', 'Bituminous solutions and fluxed Bitumens'];
    this.depotLocation = ['All', 'Naantali', 'Hamina', 'Rauma'];
    this.productTypeToSearch = 'All';
    this.productSubTypeToSearch = 'All';
    this.productsInformationData = [];
    this.productsInformationDataCopy = this.productsInformationData;
    this.getProductInformation();

    this.translate.get(['HOME.itemsPerPageLabel'])
      .subscribe(data => {
        this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getProductInformation() {
    this.productInformationService.getProductDropdownData()
      .subscribe(
        res => {
          if (res["ResponseCode"] == 200) {
            let productTypesWithAll = res['PRODUCT_TYPES'];
            productTypesWithAll.shift();
            this.productType = productTypesWithAll; // Remove word 'All' using shift method, for translation
            // this.productSubType=res['PRODUCT_SUBTYPES'];
            // console.log('Response',this.productsInformationData);
            let prodSubTypeWithAll = this.productSubType;
            prodSubTypeWithAll.shift();
            this.productSubType = prodSubTypeWithAll;
            this.productTypeToSearch = 'All';
            this.productSubTypeToSearch = 'All';

            this.displayTable = true;
          } else {
            this.displayTable = false;
          }
        });
    this.productInformationService.getProductInformation(this.customerID, this.countryCode)
      .subscribe(
        res => {
          if (res["ResponseCode"] == 200) {
            this.productsInformationData = res['GetAllProductResponses'];
            // console.log('Response',this.productsInformationData);
            this.productsInformationDataCopy = this.productsInformationData;
            this.dataSource = new MatTableDataSource(this.productsInformationDataCopy);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.displayTable = true;
          } else {
            this.displayTable = false;
          }
        });
  }
  /* countryCode(customerID: any, countryCode: any) {
    throw new Error("Method not implemented.");
  } */

  filterProducts(value) {
    this.productsInformationDataCopy = this.filterData.transform(value, (this.productTypeToSearch ? this.productTypeToSearch.trim() : this.productTypeToSearch), (this.productSubTypeToSearch ? this.productSubTypeToSearch.trim() : this.productSubTypeToSearch), '');
    this.displayIndex = 1;
    this.dataSource = new MatTableDataSource(this.productsInformationDataCopy);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.displayTable = true;
  }
  checkDisplayFlag(value) {

    if (value / 6 < this.displayIndex) {
      return 'display-data';
    }
    else {
      return 'hide-data';
    }

  }
  displayMoreProducts(data) {
    this.displayIndex = this.displayIndex + 1;

  }


  downloadPdf(pdf) {
    let pdfData;
    // let filepath = "/Shared%20Documents/General/" + pdf
    let filepath = pdf;

    let sharepointData = {
      "Username": "API.Integration@nynas.onmicrosoft.com",
      "Password": "VIN24jkjXXwdjwnvbnas",
      "Domain": "nynas",
      // "SourceFolderPath" : "/sites/GroupCustomerPortal",
      "SourceFolderPath": "/sites/PortalOperationalDocuments",
      "FilePath": filepath.replace(/ /g, "%20")
    }


    this.productInformationService.getSharepointDoc(sharepointData)
      .subscribe(
        res => {
          if (res["ResponseCode"] == "200") {
            pdfData = res["EncodedFileData"]
            // console.log(pdfData)
            //pdf='%PDF-1.7%����115 0 obj<</Linearized 1/L ...';
            const byteArray = new Uint8Array(atob(pdfData).split('').map(char => char.charCodeAt(0)));
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            if (this.checkIfBrowserIsIE()) {
              const nav = (window.navigator as any);
              if (nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(blob, 'data.pdf');
              }
              //window.navigator.msSaveOrOpenBlob(blob, 'data.pdf');
            } else {
              let fileUrl = window.URL.createObjectURL(blob);
              window.open(fileUrl);
            }

          } else {
            this.openDialog('Sorry! No file available.', null, false, 'no-file-available');

          }

        });


    // if (pdf != '') {
    //   const fileName = productName + '_' + pdfType;
    //   const pdfUrl = 'http://132.145.243.109/productInfo/' + pdf;
    //   window.open(pdfUrl, '_blank');
    //   // let Url='https://www.nynas.fi/Aspx/Products/'+pdf+'?PID='+(pIndex+3);
    //   // window.open(Url, '_blank');
    //   // alert('Downloading'+fileName+'from Url'+Url);
    //   //saveAs(pdfUrl,fileName);
    //   // Option one passing the Url
    // }
  }

  openDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,
      },
      height: '39%',
      width: (this.mobileQuery.matches ? '70%' : '25%'),
    });
  }

  getQualityCertificate(productId: string) {

    // this.downloadPdf(pIndex,pdf,productName,pdfType);
    // console.log("Product ",productToSearch);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      productId: productId
    };
    // dialogConfig.height = '22%';
    // dialogConfig.width = '22%';
    dialogConfig.height = (this.mobileQuery.matches ? '30%' : '22%');
    dialogConfig.width = (this.mobileQuery.matches ? '50%' : '22%');

    this.dialog.open(QualityCertificateComponent, dialogConfig);

  }

  checkIfBrowserIsIE(): boolean {
    var isBrowserIE = false;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) { // If Internet Explorer browser
      isBrowserIE = true;
    }
    return isBrowserIE;
  }

}