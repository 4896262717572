<a mat-list-item [ngStyle]="{'padding-left': (depth * 15) + 'px','display':displayAccess ? 'block' : 'none'}"
  (click)="onItemSelected(item)"
  [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
  class="menu-list-item">
  <i class="icon-button" [ngClass]='item.iconName' aria-hidden="true"></i>
  <!-- <span [hidden]="item.displayName !== 'Contact US'" class="menu-list-names">
  {{'HOME.displayNameContactUs'| translate}} </span> 
  <span [hidden]="item.displayName !== 'Nynas Updates'" class="menu-list-names">
  {{'HOME.displayNameNynasUpdates'| translate}} </span>  -->
  {{item.displayName}}

  <!-- <span [hidden]="!item.displayName3" class="menu-list-names">
   
      {{'HOME.item.displayName3'|translate}} </span> -->
  <span [hidden]="!item.displayName1" class="menu-list-names">
    {{'HOME.item.displayName1'|translate}} </span>
  <span [hidden]="!item.displayName2" class="menu-list-names">
    {{'HOME.item.displayName2'|translate}} </span>
  <span [hidden]="!item.displayName3" class="menu-list-names">
    {{'HOME.item.displayName3'|translate}} </span>
  <span [hidden]="!item.displayName4" class="menu-list-names">
    {{'HOME.item.displayName4'|translate}} </span>
  <span [hidden]="!item.displayName5" class="menu-list-names">
    {{'HOME.item.displayName5'|translate}} </span>
  <span [hidden]="!item.displayName6" class="menu-list-names">
    {{'HOME.item.displayName6'|translate}} </span>
  <span [hidden]="!item.displayName7" class="menu-list-names">
    {{'HOME.item.displayName7'|translate}} </span>
  <span [hidden]="!item.displayName8" class="menu-list-names">
    {{'HOME.item.displayName8'|translate}} </span>
  <span [hidden]="!item.displayName9" class="menu-list-names">
    {{'HOME.item.displayName9'|translate}} </span>
  <span [hidden]="!item.displayName10" class="menu-list-names">
    {{'HOME.item.displayName10'|translate}} </span>
  <span [hidden]="!item.displayName11" class="menu-list-names">
    {{'HOME.item.displayName11'|translate}} </span>
  <span [hidden]="!item.displayName12" class="menu-list-names">
    {{'HOME.item.displayName12'|translate}} </span>
  <span [hidden]="!item.displayName13" class="menu-list-names">
    {{'HOME.item.displayName13'|translate}} </span>
  <span [hidden]="!item.displayName14" class="menu-list-names">
    {{'HOME.item.displayName14'|translate}} </span>
  <span [hidden]="!item.displayName15" class="menu-list-names">
    {{'HOME.item.displayName15'|translate}} </span>
  <span [hidden]="!item.displayName16" class="menu-list-names">
    {{'HOME.item.displayName16'|translate}} </span>
  <span [hidden]="!item.displayName17" class="menu-list-names">
    {{'HOME.item.displayName17'|translate}} </span>
  <span [hidden]="!item.displayName18" class="menu-list-names">
    {{'HOME.item.displayName18'|translate}} </span>
  <span [hidden]="!item.displayName19" class="menu-list-names">
    {{'HOME.item.displayName19'|translate}} </span>
  <span [hidden]="!item.displayName20" class="menu-list-names">
    {{'HOME.item.displayName20'|translate}} </span>
  <span [hidden]="!item.displayName21" class="menu-list-names">
    {{'HOME.item.displayName21'|translate}} </span>
  <span [hidden]="!item.displayName22" class="menu-list-names">
    {{'HOME.item.displayName22'|translate}} </span>
  <span [hidden]="!item.displayName23" class="menu-list-names">
    {{'HOME.item.displayName23'|translate}} </span>
  <span [hidden]="!item.displayName24" class="menu-list-names">
    {{'HOME.item.displayName24'|translate}} </span>
  <span [hidden]="!item.displayName25" class="menu-list-names">
    {{'HOME.item.displayName25'|translate}} </span>
  <span [hidden]="!item.displayName26" class="menu-list-names">
    {{'HOME.item.displayName26'|translate}} </span>
  <span [hidden]="!item.displayName27" class="menu-list-names">
    {{'HOME.item.displayName27'|translate}} </span>
  <span [hidden]="!item.displayName28" class="menu-list-names">
    {{'HOME.item.displayName28'|translate}} </span>
  <span [hidden]="!item.displayName30" class="menu-list-names">
    {{'HOME.item.displayName30'|translate}} </span>
  <span [hidden]="!item.displayName31" class="menu-list-names">
    {{'HOME.item.displayName31'|translate}} </span>
  <span [hidden]="!item.displayName32" class="menu-list-names">
    {{'HOME.item.displayName32'|translate}} </span>
  <span [hidden]="!item.displayName33" class="menu-list-names">
    {{'HOME.item.displayName33'|translate}} </span>
  <span [hidden]="!item.displayName34" class="menu-list-names">
    {{'HOME.item.displayName34'|translate}} </span>
  <span [hidden]="!item.displayName35" class="menu-list-names">
    {{'HOME.item.displayName35'|translate}} </span>
  <span [hidden]="!item.displayName36" class="menu-list-names">
    {{'HOME.item.displayName36'|translate}} </span>
  <span [hidden]="!item.displayName37" class="menu-list-names">
    {{'HOME.item.displayName37'|translate}} </span>
  <span [hidden]="!item.displayName38" class="menu-list-names">
    {{'HOME.item.displayName38'|translate}} </span>
  <span [hidden]="!item.displayName39" class="menu-list-names">
    {{'HOME.item.displayName39'|translate}} </span>
  <span [hidden]="!item.displayName40" class="menu-list-names">
    {{'HOME.item.displayName40'|translate}} </span>
  <span [hidden]="!item.displayName41" class="menu-list-names">
    {{'HOME.item.displayName41'|translate}} </span>
  <span [hidden]="!item.displayName42" class="menu-list-names">
    {{'HOME.item.displayName42'|translate}} </span>
  <span [hidden]="!item.displayName43" class="menu-list-names">
    {{'HOME.item.displayName43'|translate}} </span>
  <span [hidden]="!item.displayName44" class="menu-list-names">
    {{'HOME.item.displayName44'|translate}} </span>
  <span [hidden]="!item.displayName45" class="menu-list-names">
    {{'HOME.item.displayName45'|translate}} </span>
  <span [hidden]="!item.displayName46" class="menu-list-names">
    {{'HOME.item.displayName46'|translate}} </span>
  <span [hidden]="!item.displayName47" class="menu-list-names">
    {{'HOME.item.displayName47'|translate}} </span>
  <span [hidden]="!item.displayName48" class="menu-list-names">
    {{'HOME.item.displayName48'|translate}} </span>
  <span [hidden]="!item.displayName50" class="menu-list-names">
    {{'HOME.item.displayName50'|translate}} </span>
  <span [hidden]="!item.displayName51" class="menu-list-names">
    {{'HOME.item.displayName51'|translate}} </span>
  <span [hidden]="!item.displayName52" class="menu-list-names">
    {{'HOME.item.displayName52'|translate}} </span>
  <span [hidden]="!item.displayName53" class="menu-list-names">
    {{'HOME.item.displayName53'|translate}} </span>
  <span [hidden]="!item.displayName54" class="menu-list-names">
    {{'HOME.item.displayName54'|translate}} </span>
  <span [hidden]="!item.displayName55" class="menu-list-names">
    {{'HOME.item.displayName55'|translate}} </span>
    <span [hidden]="!item.displayName56" class="menu-list-names">
      {{'HOME.item.displayName56'|translate}} </span>
      <span [hidden]="!item.displayName57" class="menu-list-names">
        {{'HOME.item.displayName57'|translate}} </span>
        <span [hidden]="!item.displayName58" class="menu-list-names">
          {{'HOME.item.displayName58'|translate}} </span>
        <span [hidden]="!item.displayName60" class="menu-list-names">
          {{'HOME.item.displayName60'|translate}} </span>

  <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <!-- <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon> -->
    <i class="material-icons">{{expanded ? "expand_less" : "expand_more"}}</i>
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-item (pageTitle)='displayRoute($event)' *ngFor="let child of item.children" [item]="child"
    [depth]="depth+1">
  </app-menu-list-item>
</div>