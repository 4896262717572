import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from '../app-routing.module';
import { AddUserGroupComponent } from './add-user-group/add-user-group.component';
import { AddUserComponent } from './add-user/add-user.component';
import { DialogBodyComponent } from './dialog-body/dialog-body.component';
import { ManageUserGroupComponent } from './manage-user-group/manage-user-group.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserManagementService } from './user-management.service';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatPaginatorModule} from '@angular/material/paginator';
import { TranslateService} from '@ngx-translate/core';
import { GlobalSearchModule } from '../global-search/global-search.module';

export function HttpLoaderFactory (http:HttpClient){
  return new  TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    UserManagementComponent,
    ManageUsersComponent,
    AddUserComponent,
    ManageUserGroupComponent,
    AddUserGroupComponent,
    DialogBodyComponent
    ],
  imports: [
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule,  
    FormsModule,
    HttpClientModule,
    MatTableModule,
    MatDialogModule,
    NgSelectModule,
    MatSortModule,
    MatPaginatorModule,
    GlobalSearchModule,
    TranslateModule.forRoot({
      loader:{
        provide:TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps:[HttpClient]
      }
    })
  ],
  entryComponents: [DialogBodyComponent, ManageUsersComponent],
  providers: [UserManagementService],
})
export class UserManagementModule { }
