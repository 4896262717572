<div class="container-fluid page-top-margin-mv">
            
    <!-- <div class="global-search-text-box margin-right-one">
        <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
    </div> -->

    <!-- Commented below code, kept for future reference -->
    <!-- <div class="row">

        <mat-button-toggle-group name="fontStyle" class="toggleBtn toggleBtn_mv" aria-label="Font Style" value="closed">
            <mat-button-toggle class="openOrderTile openOrderTile_mv" value="open" (click)="toggleButtonClick('open')">
                <span class="openOderCount">Orders</span>

            </mat-button-toggle>
            <mat-button-toggle class="closedOrderTile closedOrderTile_mv" value="closed"
                (click)="toggleButtonClick('closed')">
                <span class="closedOderCount">Request</span>

            </mat-button-toggle>

        </mat-button-toggle-group>


    </div> -->

    <!-- Filters for Portal request -->
    <div class="row" *ngIf="isLoggedInUserPortalAdmin">
        <div class="pl-3 custDropdown customer_name_label_mv">
            <label for="customerName">
                <span class="customer-name-label">{{'HOME.Customername'| translate}}
                </span>
            </label>
        </div>
        <div class="customer_name_dropdown_div customer_name_dropdown_div_mv">
            <select class="customer-name-dropdown customer_name_dropdown_width" id="customer-name"
                [(ngModel)]='customerIdUserSelected' (change)="changeCustomerName()">
                <option value="">{{'HOME.All'| translate}}</option>
                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                    {{customer.CustomerName}}
                </option>
            </select>
        </div>
        <!-- <div *ngIf="!isLoggedInUserPortalAdmin" class="pl-1 customer_name_dropdown_div customer_name_dropdown_div_mv">
            <input type="text" class="customer-name-textbox" [(ngModel)]='customerName' disabled />
        </div>

        <div class="difflabel delivery_site_label_div_mv ">
            <label for="deliverysite">
                <span class="customer-name-label">{{'HOME.Deliverysite'| translate}}
                </span>
            </label>
        </div>
        <div class="delivery_site_dropdown_div delivery_site_dropdown_div_mv">
            <select class="customer-name-dropdown" id="delivery-site" [(ngModel)]='portalDeliverySiteUserSelected'>
                <option value="">{{'HOME.All'| translate}}</option>
                <option *ngFor="let deliverySite of portalDeliverySiteList"
                    title="{{deliverySite.AddressLine1 }}{{deliverySite.AddressLine2 }}{{deliverySite.DeliveryPostalCode }}{{deliverySiteCity}}"
                    [value]="deliverySite.DeliverySite">
                    {{deliverySite.DeliverySite}}
                </option>
            </select>
        </div>
        <div class="pl-3 refNumCommon difflabel portRefNum_label_div_mv">
            <label for="refNum">
                <span class="customer-name-label">Reference#</span>
            </label>
        </div>
        <div class="portRefNum portRefNum_input_div_mv">
            <input type="text" class="customer-name-textbox" [(ngModel)]='refNum' />
        </div>
        <div class="findBtn findBtn_mv">
            <button class="goButton" type="submit" (click)="getTableData()">
                {{'HOME.Find'| translate}}
            </button>
        </div> -->
    </div>
    <!-- <div class="row pt-2" *ngIf="isLoggedInUserPortalAdmin">
        <div class="pl-1 custDropdown customer_name_label_mv">
            <label for="requestStatus">
                <span class="customer-name-label">Request status</span>
            </label>
        </div>
        <div class="pl-3">
            <input class="mr-2" type="radio" [(ngModel)]='selectedRequestStatus' value="O" name="request" />
            <label for="Open" class="radio-button-text mr-4">Open</label>
    
            <input class="mr-2" type="radio" [(ngModel)]='selectedRequestStatus' value="C" name="request" />
            <label for="Closed" class="radio-button-text mr-4">Closed</label>
    
            <input class="mr-2" type="radio" [(ngModel)]='selectedRequestStatus' value="" name="request" />
            <label for="All" class="radio-button-text">All</label>
        </div>
    </div> -->

    <!-- Commented below code, kept for future reference -->
    <!-- <div class="row pt-1">
        <div class="prod delivery_site_label_div_mv prod">
            <label for="productName">
                <span class="customer-name-label">{{'HOME.Pname'| translate}}
                </span>
            </label>
        </div>
        <div class="prodValue delivery_site_dropdown_div_mv product_name_dropdown_div_mv">
            <select class="customer-name-dropdown" id="productList" [(ngModel)]='selectedProductId'>
                <option value="">{{'HOME.All'| translate}}</option>
                <option *ngFor="let product of productList" [value]="product.PRODUCT_ID">{{product.PRODUCT_NAME}}
                </option>
            </select>
        </div>
        <div class="pl-3 pl-3 refNumCommon difflabel portRefNum_label_div_mv">
            <label for="refNum">
                <span class="customer-name-label">Reference#</span>
            </label>

        </div>
        <div class="pl-1 portRefNum portRefNum_input_div_mv">
            <input type="text" class="customer-name-textbox" [(ngModel)]='refNum' />
        </div>
        <div class="delivery_site_label_div_mv statusDropdownLabel">
            <label for="orderStatus">
                <span class="customer-name-label">Request status</span>
            </label>
        </div>
        <div class="pl-3 pl-3 statusDropDown statusDropDown_mv">
            <select class="customer-name-dropdown" id="productList" [(ngModel)]='selectedStatus'>
                <option value="O">Open</option>
                <option value="C">Closed</option>
                <option value="">{{'HOME.All'| translate}}</option>
            </select>
        </div>
        <div class="findBtn findBtn_mv">
            <button class="goButton" type="submit" (click)="getTableData()">
                {{'HOME.Find'| translate}}
            </button>
        </div>
    </div> -->

    <!-- table2 -->
    <div class="box-shadow">

        <div class="row pt-2">
            <div class="col-8 table-heading-title-div-mv"><span class="table-heading-title">Orders awaiting approval</span></div>
        
            <!-- <div class="global-search-text-box margin-right-one">
                                                                                                <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
                                                                                            </div> -->
        
            <div class="col-3" *ngIf='displayTableTwo && !portalDataRequestPending'>
                <input type="search" class="form-control rounded search-text-box" placeholder="{{'HOME.Search'| translate}}..."
                    aria-label="Search" aria-describedby="search-addon"
                    (keyup)="applyFilter('portal-open-request', $event.target.value)"
                    [(ngModel)]="globalSearchTextForPortalOpenRequest" />
            </div>
            <div class="col-3" *ngIf='(!displayTableTwo || portalDataRequestPending)'>
                <!-- This is an empty placeholder to avoid alignment issues -->
            </div>
            <div class="col-1 refresh-icon" title="Refresh data"
                (click)="refreshData('portal-open-request')"><i class="fas fa-sync-alt"></i></div>
        </div>

        <div class="row pt-3" *ngIf="!displayTableTwo && !portalDataRequestPending">
            <div class="col-lg-12 pb-3">
                <div class="mt-5" class="no-records-message">
                    <span>There are no orders/request awaiting approval.</span>
                </div>
            </div>
        </div>
        <div class="row pt-3" *ngIf="portalDataRequestPending">
            <div class="col-lg-12 pb-3">
                <div class="mt-5" class="no-records-message">
                    <span class="loading">Please wait, loading data </span>
                </div>
            </div>
        </div>

        <!-- Commented below code, kept for future reference -->
        <!-- <div class="row" [hidden]='currentToggleMenu === "open" || !displayTableTwo'>
            <span class="textOrderDetail totalOrder totalOrder_mv">Total open request - {{orderCreationCount + changeReqCount}}</span>
            <span class="textOrderDetail countText countText_creation_mv">Open order creation request - {{orderCreationCount}}</span>
            <span class="textOrderDetail countText countText_change_mv">Open order change request - {{changeReqCount}}</span>
        </div> -->
        
        <!-- Request table -->
        <div class="row tableSpace" [hidden]='!displayTableTwo || portalDataRequestPending'>
            <div class="col-lg-12 mt-1 tableWide table_div_mv">
                <mat-table #table [dataSource]="dataSourceTable2"
                [ngClass]="{'horizontal_scrollbar': (isLoggedInUserPortalAdmin), 'horizontal_scrollbar_user_role': !(isLoggedInUserPortalAdmin)}"
                    #closedOrderTableSort="matSort" matSort>

                    <!-- <ng-container matColumnDef="portalRefNum"> -->
                    <ng-container matColumnDef="ReferenceNumber">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                        [ngClass]="{'mat-column-small2': (isLoggedInUserPortalAdmin), 'mat-column-small2_user_role': !(isLoggedInUserPortalAdmin)}">
                            Reference#
                        </mat-header-cell>
                        <mat-cell
                            [ngClass]="{'mat-column-small2': (isLoggedInUserPortalAdmin), 'mat-column-small2_user_role': !(isLoggedInUserPortalAdmin)}"
                            *matCellDef="let fetchedDataForItemArray">
                            <!-- {{fetchedDataForItemArray.ReferenceNumber}} -->
                            <a class="clickRoute" matTooltip="Click to view details" href="javascript:void(0)"
                                (click)="getRow(fetchedDataForItemArray,'request', true)">
                                {{fetchedDataForItemArray.ReferenceNumber}}</a>
                            <!-- <a *ngIf='fetchedDataForItemArray.OpenClosed === "O"'
                                                            (click)="getRow(fetchedDataForItemArray,'request')" matTooltip="Click to view details"
                                                            href="javascript:void(0)">
                                                            {{fetchedDataForItemArray.ReferenceNumber}}</a>
                                                        <span *ngIf='fetchedDataForItemArray.OpenClosed === "C"'>
                                                            {{fetchedDataForItemArray.ReferenceNumber}}</span>  -->
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="OrderNumber">
                        <mat-header-cell class="mat-column-small" *matHeaderCellDef
                            [ngClass]="{'hide-table-column': !(isLoggedInUserPortalAdmin)}">
                            Order#
                        </mat-header-cell>
                        <mat-cell class="mat-column-small" *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="{'hide-table-column': !(isLoggedInUserPortalAdmin)}">
                            {{fetchedDataForItemArray.OrderNumber}} {{fetchedDataForItemArray.OrderType}}
                        </mat-cell>
                    </ng-container>

                    <!-- <ng-container matColumnDef="delSite"> -->
                    <ng-container matColumnDef="DeliverySite">
                        <mat-header-cell [ngClass]="{'mat-column-large2': (isLoggedInUserPortalAdmin), 'mat-column-large2_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Deliverysite'| translate}}
                        </mat-header-cell>
                        <mat-cell [ngClass]="{'mat-column-large2': (isLoggedInUserPortalAdmin), 'mat-column-large2_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.DeliverySite}}

                        </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="status"> -->
                    <ng-container matColumnDef="StatusDescription">
                        <mat-header-cell class="mat-column-large" *matHeaderCellDef mat-sort-header
                            [ngClass]="{'hide-table-column': !(isLoggedInUserPortalAdmin)}">
                            Status
                        </mat-header-cell>
                        <mat-cell class="mat-column-large" *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="{'hide-table-column': !(isLoggedInUserPortalAdmin)}">
                            {{fetchedDataForItemArray.StatusDescription}}
                        </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="orderDate"> -->
                    <ng-container matColumnDef="OrderDate">
                        <!-- <mat-header-cell class="column_medium"  *matHeaderCellDef mat-sort-header> -->
                        <mat-header-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                            <div class="align-text-left">Order Date</div>
                        </mat-header-cell>
                        <mat-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.OrderDate}}
                        </mat-cell>
                    </ng-container>

                    <!-- <ng-container matColumnDef="orderBy"> -->
                    <ng-container matColumnDef="CreatedBy">
                        <mat-header-cell [ngClass]="{'mat-column-medium2': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                            Ordered By
                        </mat-header-cell>
                        <mat-cell [ngClass]="{'mat-column-medium2': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.CreatedBy}}
                        </mat-cell>
                    </ng-container>

                    <!-- <ng-container matColumnDef="delReqDate"> -->
                    <ng-container matColumnDef="RequestedDate">
                        <mat-header-cell [ngClass]="{'mat-column-medium2': (isLoggedInUserPortalAdmin), 'mat-column-small2_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                            <div class="align-text-left">Delivery Request Date</div>
                        </mat-header-cell>
                        <mat-cell [ngClass]="{'mat-column-medium2': (isLoggedInUserPortalAdmin), 'mat-column-small2_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.RequestedDate}}
                        </mat-cell>
                    </ng-container>

                    <!-- <ng-container matColumnDef="product"> -->
                    <ng-container matColumnDef="ProductName">
                        <mat-header-cell
                            [ngClass]="{'mat-column-medium': (isLoggedInUserPortalAdmin), 'mat-column-medium_user_role': !(isLoggedInUserPortalAdmin)}"
                            *matHeaderCellDef mat-sort-header>
                            <div class="align-text-left">Product name</div>
                        </mat-header-cell>
                        <mat-cell
                            [ngClass]="{'mat-column-medium': (isLoggedInUserPortalAdmin), 'mat-column-medium_user_role': !(isLoggedInUserPortalAdmin)}"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.ProductName}}
                        </mat-cell>
                    </ng-container>

                    <!-- <ng-container matColumnDef="quantity"> -->
                    <ng-container matColumnDef="Quantity">
                        <mat-header-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                            <div class="align-text-left">Quantity MT</div>
                        </mat-header-cell>
                        <mat-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.Quantity }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="reqTime">
                        <mat-header-cell [ngClass]="{'mat-column-small2': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef>
                            Requested Time
                        </mat-header-cell>
                        <mat-cell [ngClass]="{'mat-column-small2': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                            <!-- {{fetchedDataForItemArray.EarliestArrivalTime}}:00-{{fetchedDataForItemArray.LatestArrivalTime}}:00 -->
                            <span *ngIf="fetchedDataForItemArray.EarliestArrivalTime">{{fetchedDataForItemArray.EarliestArrivalTime}}:00 - </span>
                                <span *ngIf="fetchedDataForItemArray.LatestArrivalTime">{{fetchedDataForItemArray.LatestArrivalTime}}:00</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="custPO">
                        <mat-header-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef>
                            Customer PO#
                        </mat-header-cell>
                        <mat-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.CustomerPO}}
                        </mat-cell>
                    </ng-container>



                    <!-- <ng-container matColumnDef="jdeNum"> -->
                    <!-- <ng-container matColumnDef="OrderNumber">
                        <mat-header-cell class="columnStatus" *matHeaderCellDef mat-sort-header>
                            Order#
                        </mat-header-cell>
                        <mat-cell class="columnStatus" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.OrderNumber}} {{fetchedDataForItemArray.OrderType}}
                        </mat-cell>
                    </ng-container> -->

                    <!-- <ng-container matColumnDef="orderSource"> -->
                    <ng-container matColumnDef="OrderFlag">
                        <mat-header-cell
                            [ngClass]="{'hide-table-column': !(isLoggedInUserPortalAdmin), 'mat-column-small5': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}"
                            *matHeaderCellDef mat-sort-header>
                            <div class="align-text-left">Order Source</div>
                        </mat-header-cell>
                        <mat-cell
                            [ngClass]="{'hide-table-column': !(isLoggedInUserPortalAdmin), 'mat-column-small5': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.OrderFlag}}
                        </mat-cell>
                    </ng-container>

                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsTwo; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsTwo;"
                        [ngClass]="{'row-top-border': row.showRowTopBorder}">
                    </mat-row>
                </mat-table>
                <!-- <mat-paginator class="paginator paginator_mv" #dataSourceTable2paginator="matPaginator" [length]="100" [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons></mat-paginator> -->
            </div>
        </div>
    </div>

    <!-- <hr class="page-separator"> -->

    <!-- Filters for JDE orders -->    
    <!-- <div class="row" *ngIf="isLoggedInUserPortalAdmin">
        <div class="pl-1 custDropdown customer_name_label_mv">
            <label for="customerName">
                <span class="customer-name-label">{{'HOME.Customername'| translate}}
                </span>
            </label>
        </div>
        <div class="customer_name_dropdown_div customer_name_dropdown_div_mv" *ngIf="isLoggedInUserPortalAdmin">
            <select class="customer-name-dropdown customer_name_dropdown_width" id="customer-name"
                [(ngModel)]='jdeCustomerIdUserSelected' (change)="this.getDeliverySites('jde')">
                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                    {{customer.CustomerName}}
                </option>
            </select>
        </div>
        <div *ngIf="!isLoggedInUserPortalAdmin" class="pl-1 customer_name_dropdown_div customer_name_dropdown_div_mv">
            <input type="text" class="customer-name-textbox" [(ngModel)]='customerName' disabled />
        </div>

        <div class="difflabel delivery_site_label_div_mv ">
            <label for="deliverysite">
                <span class="customer-name-label">{{'HOME.Deliverysite'| translate}}
                </span>
            </label>
        </div>
        <div class="delivery_site_dropdown_div delivery_site_dropdown_div_mv">
            <select class="customer-name-dropdown" id="delivery-site" [(ngModel)]='jdeDeliverySiteUserSelected'>
                <option value="">{{'HOME.All'| translate}}</option>
                <option *ngFor="let deliverySite of jdeDeliverySiteList"
                    title="{{deliverySite.AddressLine1 }}{{deliverySite.AddressLine2 }}{{deliverySite.DeliveryPostalCode }}{{deliverySiteCity}}"
                    [value]="deliverySite.DeliverySite">
                    {{deliverySite.DeliverySite}}
                </option>
            </select>
        </div>
        <div class="pl-3 order-number difflabel portRefNum_label_div_mv">
            <label for="refNum">
                <span class="customer-name-label">Order#</span>
            </label>
        </div>
        <div class="jdeOrderNumber portRefNum_input_div_mv">
            <input type="text" class="customer-name-textbox" [(ngModel)]='orderNumber' />
        </div>
        <div class="findBtn findBtn_mv">
            <button class="goButton" type="submit" (click)="getJDETableData()">
                {{'HOME.Find'| translate}}
            </button>
        </div>
    </div> -->
    <!-- <div class="row pt-2">
        <div class="pl-1 custDropdown customer_name_label_mv">
            <label for="orderStatus">
                <span class="customer-name-label">Order status</span>
            </label>
        </div>
        <div class="pl-4">
            <input class="mr-2" type="radio" [(ngModel)]='selectedOrderStatus' value="O" name="order" />
            <label for="Open" class="radio-button-text mr-4">Open</label>
    
            <input class="mr-2" type="radio" [(ngModel)]='selectedOrderStatus' value="C" name="order" />
            <label for="Closed" class="radio-button-text mr-4">Closed</label>
    
            <input class="mr-2" type="radio" [(ngModel)]='selectedOrderStatus' value="A" name="order" />
            <label for="All" class="radio-button-text">All</label>
        </div>
    </div> -->

    <!-- Commented below code, kept for future reference -->
    <!-- <div class="row pt-1">
        <div class="prod delivery_site_label_div_mv prod">
            <label for="productName">
                <span class="customer-name-label">{{'HOME.Pname'| translate}}
                </span>
            </label>
        </div>
        <div class="prodValue delivery_site_dropdown_div_mv product_name_dropdown_div_mv">
            <select class="customer-name-dropdown" id="productList" [(ngModel)]='selectedProductId'>
                <option value="">{{'HOME.All'| translate}}</option>
                <option *ngFor="let product of productList" [value]="product.PRODUCT_ID">{{product.PRODUCT_NAME}}
                </option>
            </select>
        </div>
        <div class="pl-3 pl-3 refNumCommon difflabel portRefNum_label_div_mv">
            <label for="refNum">
                <span class="customer-name-label">Order#</span>
            </label>
    
        </div>
        <div class="pl-1 portRefNum portRefNum_input_div_mv">
            <input type="text" class="customer-name-textbox" [(ngModel)]='refNum' />
        </div>
        <div class="delivery_site_label_div_mv statusDropdownLabel">
            <label for="orderStatus">
                <span class="customer-name-label">Order status</span>
            </label>
        </div>
        <div class="pl-3 pl-3 statusDropDown statusDropDown_mv">
            <select class="customer-name-dropdown" id="productList" [(ngModel)]='selectedStatus'>
                <option value="O">Open</option>
                <option value="C">Closed</option>
                <option value="A">{{'HOME.All'| translate}}</option>
            </select>
        </div>
        <div class="findBtn findBtn_mv">
            <button class="goButton" type="submit"
                (click)="getJDETableData()">{{'HOME.Find'| translate}}
            </button>
        </div>
    </div> -->

    <!-- <div *ngIf="isReqCompleted"> -->
        <div class="box-shadow">      

            <!-- <div class="table-heading-title mt-2">Approved Orders</div> -->

            <div class="row pt-2 mt-3">
                <div class="col-8 table-heading-title-div-mv"><span class="table-heading-title">Approved Orders</span></div>
            
                <!-- <div class="global-search-text-box margin-right-one">
                                            <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
                                        </div> -->
            
                <div class="col-3" *ngIf='!(!displayTableOne || jdeDataRequestPending)'>
                    <input type="search" class="form-control rounded search-text-box" placeholder="{{'HOME.Search'| translate}}..."
                        aria-label="Search" aria-describedby="search-addon"
                        (keyup)="applyFilter('jde-all-request', $event.target.value)" [(ngModel)]="globalSearchTextForJDEOrders" />
                </div>
                <div class="col-3" *ngIf='(!displayTableOne || jdeDataRequestPending)'>
                    <!-- This is an empty placeholder to avoid alignment issues -->
                </div>
                <div class="col-1 refresh-icon" title="Refresh data" (click)="refreshData('jde-all-request')"><i
                        class="fas fa-sync-alt"></i></div>
            </div>

            <div class="row pt-3" *ngIf="jdeDataRequestPending">
                <div class="col-lg-12 pb-3">
                    <div class="mt-5" class="no-records-message">
                        <span class="loading">Please wait, loading data </span>
                    </div>
                </div>
            </div>
            <div [hidden]="!isReqCompleted">
                <div class="row pt-3" *ngIf="!displayTableOne && !jdeDataRequestPending">
                    <div class="col-lg-12 pb-3">
                        <div class="mt-5" class="no-records-message">
                            <span>{{'HOME.Norecordsfound'| translate}}</span>
                        </div>
                    </div>
                </div>
                <div [hidden]='!displayTableOne'>
        
                    <!-- Commented below code, kept for future reference -->
                    <!-- <div class="row">
                        <span class="textOrderDetail totalOrder totalOrder_mv">Total orders - {{jdeClosedOrderCount +
                            jdeOpenOrderCount}}</span>
                        <span class="textOrderDetail countTextJde countText_change_mv">Open order - {{jdeOpenOrderCount}}</span>
                        <span class="textOrderDetail countText countText_creation_mv">Closed order - {{jdeClosedOrderCount}}</span>
                    </div> -->
        
                    <!-- Orders table -->
                    <div class="row jdeTable" [hidden]='!displayTableOne || jdeDataRequestPending'>
                        <div class="col-lg-12 mt-1 tableWide table_div_mv">
                            <mat-table #table #openOrderTableSort="matSort" [dataSource]="dataSourceTable1"
                            [ngClass]="{'horizontal_scrollbar_jde': (isLoggedInUserPortalAdmin), 'horizontal_scrollbar_jde_user_role': !(isLoggedInUserPortalAdmin)}" matSort>
        
                                <ng-container matColumnDef="PortalRefNumber">
                                    <mat-header-cell class="mat-column-small3" *matHeaderCellDef mat-sort-header>
                                        Reference#
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-small3" *matCellDef="let fetchedDataForItemArray">
                                        <a class="clickRoute" matTooltip="Click to view details" href="javascript:void(0)"
                                            (click)="getRow(fetchedDataForItemArray,'order', false)">
                                            {{fetchedDataForItemArray.PortalRefNumber}}</a>
                                    </mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="OrderNumber">
                                    <mat-header-cell class="mat-column-small4" *matHeaderCellDef mat-sort-header>
                                        Order#
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-small4" *matCellDef="let fetchedDataForItemArray">
                                        <!-- (click)="getRow(fetchedDataForItemArray,'order')" -->
                                        <a *ngIf='fetchedDataForItemArray.OpenClosed === "O"' (click)="getRow(fetchedDataForItemArray,'order', false)"
                                            matTooltip="Click to view details" href="javascript:void(0)">
                                            {{fetchedDataForItemArray.OrderNumber}} {{fetchedDataForItemArray. OrderType}}
                                        </a>
                                        <span *ngIf='fetchedDataForItemArray.OpenClosed === "C"'>
                                            {{fetchedDataForItemArray.OrderNumber}} {{fetchedDataForItemArray.
                                            OrderType}}</span>
                                    </mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="DeliverySite">
                                    <!-- <mat-header-cell class="columnDelSiteJde" *matHeaderCellDef mat-sort-header> -->
                                    <mat-header-cell [ngClass]="{'mat-column-large2': (isLoggedInUserPortalAdmin), 'mat-column-large2_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                                        {{'HOME.Deliverysite'| translate}}
                                    </mat-header-cell>
                                    <mat-cell [ngClass]="{'mat-column-large2': (isLoggedInUserPortalAdmin), 'mat-column-large2_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.DeliverySite}}
                                    </mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="StatusDescription">
                                    <mat-header-cell class="mat-column-small3" *matHeaderCellDef>
                                        Status
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-small3" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.StatusDescription}}
                                    </mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="OrderDate">
                                    <!-- <mat-header-cell *matHeaderCellDef mat-sort-header> -->
                                    <mat-header-cell [ngClass]="{'mat-column-small4': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                                        <div class="align-text-left">Order Date</div>
                                    </mat-header-cell>
                                    <mat-cell [ngClass]="{'mat-column-small4': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.OrderDate}}
                                    </mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="OrderedByDesc">
                                    <mat-header-cell class="mat-column-medium2" *matHeaderCellDef mat-sort-header>
                                        Ordered By
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-medium2" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.OrderedByDesc}}
                                    </mat-cell>
                                </ng-container>
        
                                <!-- <ng-container matColumnDef="OrderType">
                                            <mat-header-cell *matHeaderCellDef>
                                                Order Type
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let fetchedDataForItemArray">
                                                {{fetchedDataForItemArray. OrderType}}
                                            </mat-cell>
                                        </ng-container> -->
        
                                <ng-container matColumnDef="RequestedDate">
                                    <mat-header-cell [ngClass]="{'mat-column-medium2': (isLoggedInUserPortalAdmin), 'mat-column-small2_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                                        <div class="align-text-left">Delivery Request Date</div>
                                    </mat-header-cell>
                                    <mat-cell [ngClass]="{'mat-column-medium2': (isLoggedInUserPortalAdmin), 'mat-column-small2_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.RequestedDate}}
                                    </mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="ProductName">
                                    <mat-header-cell class="mat-column-medium" *matHeaderCellDef mat-sort-header>
                                        <div class="align-text-left">Product name</div>
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.ProductName}}
                                    </mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="QuantityOrdered">
                                    <mat-header-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                                        <div class="align-text-left">Quantity MT</div>
                                    </mat-header-cell>
                                    <mat-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.Quantity}}
                                    </mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="reqTime">
                                    <mat-header-cell [ngClass]="{'mat-column-small2': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef>
                                        Requested Time
                                    </mat-header-cell>
                                    <mat-cell [ngClass]="{'mat-column-small2': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                                        <!-- {{fetchedDataForItemArray.RequestedTime}} -->
                                        <span
                                            *ngIf="fetchedDataForItemArray.EarliestArrivalTime">{{fetchedDataForItemArray.EarliestArrivalTime}}:00
                                            -</span>
                                        <span
                                            *ngIf="fetchedDataForItemArray.LatestArrivalTime">{{fetchedDataForItemArray.LatestArrivalTime}}:00</span>
                                    </mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="custPO">
                                    <mat-header-cell
                                        [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}"
                                        *matHeaderCellDef>
                                        Customer PO#
                                    </mat-header-cell>
                                    <mat-cell
                                        [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}"
                                        *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.CustomerPO}}
                                    </mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="SourceSystem">
                                    <mat-header-cell
                                        [ngClass]="{'hide-table-column': !(isLoggedInUserPortalAdmin), 'mat-column-small5': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}"
                                        *matHeaderCellDef mat-sort-header>
                                        <div class="align-text-left">Order Source</div>
                                    </mat-header-cell>
                                    <mat-cell
                                        [ngClass]="{'hide-table-column': !(isLoggedInUserPortalAdmin), 'mat-column-small5': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}"
                                        *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.SourceSystem}}
                                    </mat-cell>
                                </ng-container>
        
                                <ng-container matColumnDef="holdCode">
                                    <mat-header-cell [ngClass]="{'mat-column-small6': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef>
                                        Hold code
                                    </mat-header-cell>
                                    <mat-cell [ngClass]="{'mat-column-small6': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.HoldCode}}
                                    </mat-cell>
                                </ng-container>
        
                                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsOne; sticky:true">
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsOne;"
                                    [ngClass]="{'row-top-border': row.showRowTopBorder}">
                                </mat-row>
                            </mat-table>
                            <!-- <mat-paginator class="paginator paginator_mv" #dataSourceTable1paginator="matPaginator" [length]="100" [pageSize]="10"
                                [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons>
                            </mat-paginator> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <hr class="page-separator"> -->
        
        <div class="box-shadow">

            <!-- <div class="table-heading-title mt-2">Cancelled / Rejected requests</div> -->

            <div class="row pt-2 mt-3">
                <div class="col-8 table-heading-title-div-mv"><span class="table-heading-title">Cancelled / Rejected requests</span></div>
            
                <!-- <div class="global-search-text-box margin-right-one">
                                            <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
                                        </div> -->
            
                <div class="col-3" *ngIf='!(!displayTableThree || portalCancelledAndRejetedDataRequestPending)'>
                    <input type="search" class="form-control rounded search-text-box" placeholder="{{'HOME.Search'| translate}}..."
                        aria-label="Search" aria-describedby="search-addon"
                        (keyup)="applyFilter('portal-cancelled-rejected-request', $event.target.value)"
                        [(ngModel)]="globalSearchTextForPortalCancelledRejectedRequest" />
                </div>
                <div class="col-3" *ngIf='(!displayTableThree || portalCancelledAndRejetedDataRequestPending)'>
                    <!-- This is an empty placeholder to avoid alignment issues -->
                </div>
                <div class="col-1 refresh-icon" title="Refresh data" (click)="refreshData('portal-cancelled-rejected-request')"><i
                        class="fas fa-sync-alt"></i>
                </div>
            </div>

            <div class="row pt-3" *ngIf="!displayTableThree && !portalCancelledAndRejetedDataRequestPending">
                <div class="col-lg-12 pb-3">
                    <div class="mt-5" class="no-records-message">
                        <span>{{'HOME.Norecordsfound'| translate}}</span>
                    </div>
                </div>
            </div>
            <div class="row pt-3" *ngIf="portalCancelledAndRejetedDataRequestPending">
                <div class="col-lg-12 pb-3">
                    <div class="mt-5" class="no-records-message">
                        <span class="loading">Please wait, loading data </span>
                    </div>
                </div>
            </div>
            <div class="row tableSpace" [hidden]='!displayTableThree || portalCancelledAndRejetedDataRequestPending'>
                <div class="col-lg-12 mt-1 tableWide table_div_mv">
                    <mat-table #table [dataSource]="dataSourceTable3" [ngClass]="{'horizontal_scrollbar': (isLoggedInUserPortalAdmin), 'horizontal_scrollbar_user_role': !(isLoggedInUserPortalAdmin)}"
                        #cancelledAndRejectedRequestTableSort="matSort" matSort>
                        <ng-container matColumnDef="ReferenceNumber">
                            <mat-header-cell
                                [ngClass]="{'mat-column-small2': (isLoggedInUserPortalAdmin), 'mat-column-small2_user_role': !(isLoggedInUserPortalAdmin)}"
                                *matHeaderCellDef mat-sort-header>
                                Reference#
                            </mat-header-cell>
                            <mat-cell
                                [ngClass]="{'mat-column-small2': (isLoggedInUserPortalAdmin), 'mat-column-small2_user_role': !(isLoggedInUserPortalAdmin)}"
                                *matCellDef="let fetchedDataForItemArray">
                                <a class="clickRoute" matTooltip="Click to view details" href="javascript:void(0)"
                                    (click)="getRow(fetchedDataForItemArray,'request', false)">
                                    {{fetchedDataForItemArray.ReferenceNumber}}</a>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="OrderNumber">
                            <mat-header-cell
                                [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin), 'hide-table-column': !(isLoggedInUserPortalAdmin)}"
                                *matHeaderCellDef>
                                Order#
                            </mat-header-cell>
                            <mat-cell
                                [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin), 'hide-table-column': !(isLoggedInUserPortalAdmin)}"
                                *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.OrderNumber}} {{fetchedDataForItemArray.OrderType}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="DeliverySite">
                            <mat-header-cell
                                [ngClass]="{'mat-column-large2': (isLoggedInUserPortalAdmin), 'mat-column-large2_user_role': !(isLoggedInUserPortalAdmin)}"
                                *matHeaderCellDef mat-sort-header>
                                {{'HOME.Deliverysite'| translate}}
                            </mat-header-cell>
                            <mat-cell
                                [ngClass]="{'mat-column-large2': (isLoggedInUserPortalAdmin), 'mat-column-large2_user_role': !(isLoggedInUserPortalAdmin)}"
                                *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.DeliverySite}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="StatusDescription">
                            <mat-header-cell class="mat-column-large" *matHeaderCellDef mat-sort-header>
                                Status
                            </mat-header-cell>
                            <mat-cell class="mat-column-large" *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.StatusDescription}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="OrderDate">
                            <mat-header-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                                <div class="align-text-left">Order Date</div>
                            </mat-header-cell>
                            <mat-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.OrderDate}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="CreatedBy">
                            <mat-header-cell [ngClass]="{'mat-column-medium2': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                                Ordered By
                            </mat-header-cell>
                            <mat-cell [ngClass]="{'mat-column-medium2': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.CreatedBy}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="RequestedDate">
                            <mat-header-cell
                                [ngClass]="{'mat-column-medium2': (isLoggedInUserPortalAdmin), 'mat-column-small2_user_role': !(isLoggedInUserPortalAdmin)}"
                                *matHeaderCellDef mat-sort-header>
                                <div class="align-text-left">Delivery Request Date</div>
                            </mat-header-cell>
                            <mat-cell
                                [ngClass]="{'mat-column-medium2': (isLoggedInUserPortalAdmin), 'mat-column-small2_user_role': !(isLoggedInUserPortalAdmin)}"
                                *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.RequestedDate}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="ProductName">
                            <mat-header-cell
                                [ngClass]="{'mat-column-medium': (isLoggedInUserPortalAdmin), 'mat-column-medium_user_role': !(isLoggedInUserPortalAdmin)}"
                                *matHeaderCellDef mat-sort-header>
                                <div class="align-text-left">Product name</div>
                            </mat-header-cell>
                            <mat-cell
                                [ngClass]="{'mat-column-medium': (isLoggedInUserPortalAdmin), 'mat-column-medium_user_role': !(isLoggedInUserPortalAdmin)}"
                                *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.ProductName}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Quantity">
                            <mat-header-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef mat-sort-header>
                                <div class="align-text-left">Quantity MT</div>
                            </mat-header-cell>
                            <mat-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.Quantity }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="reqTime">
                            <mat-header-cell [ngClass]="{'mat-column-small2': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef>
                                Requested Time
                            </mat-header-cell>
                            <mat-cell [ngClass]="{'mat-column-small2': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                                <span
                                    *ngIf="fetchedDataForItemArray.EarliestArrivalTime">{{fetchedDataForItemArray.EarliestArrivalTime}}:00
                                    - </span>
                                <span
                                    *ngIf="fetchedDataForItemArray.LatestArrivalTime">{{fetchedDataForItemArray.LatestArrivalTime}}:00</span>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="custPO">
                            <mat-header-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matHeaderCellDef>
                                Customer PO#
                            </mat-header-cell>
                            <mat-cell [ngClass]="{'mat-column-small': (isLoggedInUserPortalAdmin), 'mat-column-small3_user_role': !(isLoggedInUserPortalAdmin)}" *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.CustomerPO}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="OrderFlag">
                            <mat-header-cell
                                [ngClass]="{'hide-table-column': !(isLoggedInUserPortalAdmin), 'mat-column-small5': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}"
                                *matHeaderCellDef mat-sort-header>
                                <div class="align-text-left">Order Source</div>
                            </mat-header-cell>
                            <mat-cell
                                [ngClass]="{'hide-table-column': !(isLoggedInUserPortalAdmin), 'mat-column-small5': (isLoggedInUserPortalAdmin), 'mat-column-small_user_role': !(isLoggedInUserPortalAdmin)}"
                                *matCellDef="let fetchedDataForItemArray">
                                {{fetchedDataForItemArray.OrderFlag}}
                            </mat-cell>
                        </ng-container>
                        <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsThree; sticky:true">
                        </mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsThree;"
                            [ngClass]="{'row-top-border': row.showRowTopBorder}">
                        </mat-row>
                    </mat-table>
                    <!-- <mat-paginator class="paginator paginator_mv" #dataSourceTable3paginator="matPaginator" [length]="100" [pageSize]="10"
                        [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons></mat-paginator> -->
                </div>
            </div>
        </div>

</div>