import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataSets, ChartOptions, ChartType, Chart } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as FileSaver from 'file-saver';
import { BaseChartDirective, Label } from 'ng2-charts';
import { HistoryService } from 'src/app/order-management/history/history.component.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import * as XLSX from 'xlsx';
import { ForecastActualReportService } from '../forecastactual/forecastactual.component.service';
import { ForecastactualweeklyswedenService } from '../forecastactualweeklysweden/forecastactualweeklysweden.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-contractual-volume-report',
  templateUrl: './contractual-volume-report.component.html',
  styleUrls: ['./contractual-volume-report.component.css']
})
export class ContractualVolumeReportComponent implements OnInit {

  currentToggleMenu: string = 'reports';
  customerIdUserSelected: string;
  userInfo: any;
  countryCode: any;
  customerList = [];
  yearUserSelected: string;
  yearList: any = [];
  fromWeek: string = '';
  toWeek: string = '';
  viewUserSelected: string = 'All';
  displayTable: boolean;
  displayGraph: boolean;
  isRequestCompleted: boolean;
  dataSource: any;
  displayedColumns = ['Depot name', 'Product name', 'Delivery site', 'Type', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total'];
  customerId: string;
  customerName: string;
  isLoggedInUserPortalAdmin: boolean;
  Order_month: any = [];
  Actual_Quantity: any = [];
  Forecast_Quantity: any = [];
  Contractual_Volume_Quantity: any = [];
  Contractual_Volume_Quantity1: any = [];
  Contractual_Volume_Quantity2: any = [];
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  showDepotNameColumn: boolean;
  showProductNameColumn: boolean;
  showDeliverySiteColumn: boolean;
  // monthsList: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  deliverySiteUserSelected: string;
  deliverySiteList: any = [];

  lastRowValueObject: any = {
    CustomerName: '',
    DeliverySiteName: '',
    DepotName: '',
    ProductName: ''
  };
  deliverySiteObjectList: any = [];
  role: string;
  userID: string;

  constructor(private forecastactualweeklyswedenService: ForecastactualweeklyswedenService,
    private sharedService: AppService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private media: MediaMatcher,
    private historyService: HistoryService,
    private forecastActualService: ForecastActualReportService,
    private translate: TranslateService) {
    this.userInfo = this.sharedService.getLoginResponse();
    if (this.userInfo) {
      this.countryCode = (this.userInfo.CountryCode ? this.userInfo.CountryCode : '');
      this.customerId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
      this.customerName = (this.userInfo.CustomerName ? this.userInfo.CustomerName : '');
      this.isLoggedInUserPortalAdmin = (this.userInfo.RoleID === 'PA');
      this.role = (this.userInfo ? this.userInfo.RoleID : '');
      this.userID = (this.userInfo ? this.userInfo.UserID : '');
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => cd.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }
  }

  async ngOnInit() {
    this.getYearList();

    try {
      await this.getCustomerList();
    }
    catch (e) {
    }

    if (this.countryCode === 'FIN' || this.countryCode === 'SWE') {
      // Do not set any delivery site for Finland and Sweden monthly reports
      // this.deliverySiteUserSelected = 'DefaultDelSite';
      this.deliverySiteUserSelected = '';
    } else {
      try {
        await this.getDeliverySites();
      }
      catch (e) {
      }
    }

    try {
      this.getReportsForTable();
    }
    catch (e) {
    }
  }

  async toggleButtonClick(toggleMenu: string) {
    this.currentToggleMenu = toggleMenu;

    // get yearlist with current year by default in dropdown
    this.getYearList();

    // Set logged user customer by default, in customer name dropdown
    this.customerIdUserSelected = this.customerId;

    this.viewUserSelected = 'All';
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;

    if (this.countryCode === 'FIN' || this.countryCode === 'SWE') {
      // Do not set any delivery site for Finland monthly reports
      // this.deliverySiteUserSelected = 'DefaultDelSite';
      this.deliverySiteUserSelected = '';
    } else {
      try {
        await this.getDeliverySites();
      }
      catch (e) {
      }
    }

    if (this.currentToggleMenu === 'reports') {
      this.getReportsForTable();
    } else if (this.currentToggleMenu === 'graph') {
      this.getReportsForGraph();
    }
  }

  // Commented old code to fetch delivery sites, since customer users should access only those delivery sites assigned to them
/* async getDeliverySites() {
  if (this.countryCode === 'NOR' || this.countryCode === 'DNK') {
    this.deliverySiteList = [];
    this.deliverySiteObjectList = [];
    return new Promise<void>((resolve, reject) => {
      let isWeekly = 'NO'; // Set isWeekly parameter as NO, when delivery sites needed in monthly data
      this.historyService.getHistDeliverySite(this.customerIdUserSelected, this.countryCode, isWeekly).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            for (let deliverySiteObject of response["DeliverySiteCollection"]) {
              this.deliverySiteObjectList.push(deliverySiteObject);
              this.deliverySiteList.push(deliverySiteObject["DeliverySite"]);
            }
            // Set first delivery site by default, in delivery site dropdown
            this.deliverySiteUserSelected = (this.deliverySiteList && this.deliverySiteList.length) ? this.deliverySiteList[0] : '';
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }
} */

  async getDeliverySites() {
    if (this.countryCode === 'NOR' || this.countryCode === 'DNK') {
      this.deliverySiteList = [];
      this.deliverySiteObjectList = [];
      if (this.role === "A" || this.role === "PA") {  // For portal admin and customer admins, show all delivery sites in dropdown
        return new Promise<void>((resolve, reject) => {
          let isWeekly = 'NO'; // Set isWeekly parameter as NO, when delivery sites needed in monthly data
          this.historyService.getHistDeliverySite(this.customerIdUserSelected, this.countryCode, isWeekly).subscribe(
            response => {
              if (response["ResponseCode"] === "200") {
                for (let deliverySiteObject of response["DeliverySiteCollection"]) {
                  this.deliverySiteObjectList.push(deliverySiteObject);
                  this.deliverySiteList.push(deliverySiteObject["DeliverySite"]);
                }
                // Set first delivery site by default, in delivery site dropdown
                this.deliverySiteUserSelected = (this.deliverySiteList && this.deliverySiteList.length) ? this.deliverySiteList[0] : '';
                resolve();
              }
              else {
                reject();
              }
            });
        })
      } else {
        return new Promise<void>((resolve, reject) => { // Customer users are allocated with specific delivery sites, so show only those delivery sites in dropdown
          this.forecastactualweeklyswedenService.getDeliverySiteForUserForTable(this.userID, this.countryCode, "ACTIVE", this.customerIdUserSelected).subscribe(
            response => {
              if (response["ResponseCode"] === "200") {
                for (let deliverySiteObject of response["DeliverySites"]) {
                  this.deliverySiteObjectList.push(deliverySiteObject);
                  this.deliverySiteList.push(deliverySiteObject["DeliverySiteName"]);
                }
                // Set first delivery site by default, in delivery site dropdown
                this.deliverySiteUserSelected = (this.deliverySiteList && this.deliverySiteList.length) ? this.deliverySiteList[0] : '';
                resolve();
              }
              else {
                reject();
              }
            });
        })
      }
    }
  }

  async getCustomerList() {
    this.customerList = [];
    return new Promise((resolve, reject) => {
      this.forecastactualweeklyswedenService.getCustomerList(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.customerList = res["Customers"];

            // Set logged user customer by default, in customer name dropdown
            this.customerIdUserSelected = this.customerId;
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  getYearList() {
    const date = this.sharedService.getCurrentDateTime();
    this.yearUserSelected = ((date.getUTCFullYear()) ? date.getUTCFullYear().toString() : '');
    this.yearList = [];
    this.yearList.push(Number(this.yearUserSelected) - 1, this.yearUserSelected);

    //Remove year 2019 from year dropdown for Finland users, temporary fix until their data is corrected, to be deployed only in Prod
    /* if (this.countryCode === 'FIN') {
      this.yearList = this.yearList.filter(function (year: number) { return year > 2019; });
    } */
  }

  getWeekNumber(date: any) {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    let yearStart: any = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
    return [date.getUTCFullYear(), weekNo];
  }

  getReportsForTable() {
    if (this.viewUserSelected == "All") {
      this.getReportsForTableByViewAll();
    } else if (this.viewUserSelected == "By depot") {
      this.getReportsForTableByViewDepot();
    } else if (this.viewUserSelected == "By product") {
      this.getReportsForTableByViewProduct();
    } else if (this.viewUserSelected == "By delivery site") {
      this.getReportsForTableByViewDeliverySite();
    } else if (this.viewUserSelected == "By product and depot") {
      this.getReportsForTableByViewProductAndDepot();
    }
  }

  getReportsForTableByViewAll() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;

    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForAll(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var test = this;
        response["ActualVsPlannedByAll"].forEach(function (arrayItem) {
          var x = arrayItem;
          let forecastActualData = [];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast']['Jan'], x['TypeCollectionForecast']['Feb'], x['TypeCollectionForecast']['Mar'], x['TypeCollectionForecast']['Apr'], x['TypeCollectionForecast']['May'], x['TypeCollectionForecast']['Jun'], x['TypeCollectionForecast']['Jul'], x['TypeCollectionForecast']['Aug'], x['TypeCollectionForecast']['Sep'], x['TypeCollectionForecast']['Oct'], x['TypeCollectionForecast']['Nov'], x['TypeCollectionForecast']['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual']['Jan'], x['TypeCollectionActual']['Feb'], x['TypeCollectionActual']['Mar'], x['TypeCollectionActual']['Apr'], x['TypeCollectionActual']['May'], x['TypeCollectionActual']['Jun'], x['TypeCollectionActual']['Jul'], x['TypeCollectionActual']['Aug'], x['TypeCollectionActual']['Sep'], x['TypeCollectionActual']['Oct'], x['TypeCollectionActual']['Nov'], x['TypeCollectionActual']['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          x['TypeCollectionForecast']['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          x['TypeCollectionActual']['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          x['TypeCollectionDiffernce']['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;

          forecastActualData.push(x['TypeCollectionForecast']);
          forecastActualData.push(x['TypeCollectionActual']);
          forecastActualData.push(x['TypeCollectionDiffernce']);
          test.dataSource = new MatTableDataSource(forecastActualData);
          test.displayTable = true;
          test.isRequestCompleted = true;
        });
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.displayTable = false;
        this.isRequestCompleted = true;
      }
      else {
        this.displayTable = false;
        this.isRequestCompleted = true;
      }

    });

  }

  getReportsForTableByViewDepot() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;

    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForDepot(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(res => {
      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = res["ActualVsPlannedByDepot"]["DepotCollection"];
        var test = this;
        var forecastActualData = [];

        this.resetLastRowValueObject();

        productData.forEach(function (arrayItem) {
          var x = arrayItem;
          let array1 = x['TypeCollectionForecast'][0];
          let array2 = x['TypeCollectionActual'][0];
          let array3 = x['TypeCollectionDifference'][0];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);

          if (test.lastRowValueObject['DepotName'] !== (x.DepotName)) {
            array1['DepotName'] = x.DepotName;
            test.lastRowValueObject['DepotName'] = x.DepotName;
          }
          array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;

          // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
          if (array1['DepotName'] && forecastActualData && forecastActualData.length) {
            array1['showRowTopBorder'] = true;
          }

          forecastActualData.push(array1);
          forecastActualData.push(array2);
          forecastActualData.push(array3);

        });
        this.dataSource = new MatTableDataSource(forecastActualData);
        this.displayTable = true;
        this.isRequestCompleted = true;
        this.showDepotNameColumn = true;
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.displayTable = false;
        this.isRequestCompleted = true;
      }
      else {
        this.displayTable = false;
        this.isRequestCompleted = true;
      }

    });
  }

  resetLastRowValueObject(): void {
    for (let key in this.lastRowValueObject) {
      this.lastRowValueObject[key] = '';
    }
  }

  getReportsForTableByViewProduct() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;

    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForProduct(this.yearUserSelected, this.customerIdUserSelected, this.countryCode, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = res["ActualVsPlannedByProduct"]["ProductCollection"];
        var test = this;
        var forecastActualData = [];
        this.resetLastRowValueObject();
        productData.forEach(function (arrayItem) {
          var x = arrayItem;
          let array1 = x['TypeCollectionForecast'][0];
          let array2 = x['TypeCollectionActual'][0];
          let array3 = x['TypeCollectionDifference'][0];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          array1['ProductName'] = x.ProductName;

          if (test.lastRowValueObject['ProductName'] !== (x.ProductName)) {
            array1['ProductName'] = x.ProductName;
            test.lastRowValueObject['ProductName'] = x.ProductName;
          }

          array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;

          if (array1['ProductName'] && forecastActualData && forecastActualData.length) {
            array1['showRowTopBorder'] = true;
          }

          forecastActualData.push(array1);
          forecastActualData.push(array2);
          forecastActualData.push(array3);
        });

        this.dataSource = new MatTableDataSource(forecastActualData);
        this.displayTable = true;
        this.isRequestCompleted = true;
        this.showProductNameColumn = true;
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.displayTable = false;
        this.isRequestCompleted = true;
      }
      else {
        this.displayTable = false;
        this.isRequestCompleted = true;
      }

    });
  }

  getReportsForTableByViewDeliverySite() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;

    this.forecastActualService.getTableDataForDelSite(this.yearUserSelected, this.customerIdUserSelected).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = res["ActualVsPlannedByDeliverySite"]["DeliverySiteCollection"];
        var test = this;
        var forecastActualData = [];

        // Added filter for delivery site selected in dropdown temporarily, until delivery site parameter is passed to service
        productData.forEach(function (arrayItem) {
          if (test.countryCode === 'FIN' || test.countryCode === 'SWE' || test.deliverySiteUserSelected === arrayItem['DeliverySite']) {
            var x = arrayItem;
            let array1 = x['TypeCollectionActual'][0];
            let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
            array1['DeliverySite'] = x.DeliverySite;
            array1['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
            forecastActualData.push(array1);
          }
        });
        this.dataSource = new MatTableDataSource(forecastActualData);
        this.displayTable = (forecastActualData && forecastActualData.length > 0);

        this.isRequestCompleted = true;
        this.showDeliverySiteColumn = true;
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.displayTable = false;
        this.isRequestCompleted = true;
      }
      else {
        this.displayTable = false;
        this.isRequestCompleted = true;
      }

    });

  }

  getReportsForTableByViewProductAndDepot() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;

    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForDepotAndProduct(this.customerIdUserSelected, this.yearUserSelected, this.countryCode, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var depotData = res["DepotCollection"];
        var test = this;
        var forecastActualData = [];

        depotData.forEach(function (depotItem) {
          var productData = depotItem["ProductCollection"];

          productData.forEach(function (arrayItem) {
            var x = arrayItem;

            let array1 = x['TypeCollectionForecast'][0];
            let array2 = x['TypeCollectionActual'][0];
            let array3 = x['TypeCollectionDifference'][0];
            let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
            let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
            let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);

            array1['ProductName'] = x.ProductName;
            array1['DepotName'] = depotItem.DepotName;

            array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
            array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
            // array3['Total'] = diffenceTotalPer ? diffenceTotalPer.toString() : 0;
            array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;

            forecastActualData.push(array1);
            forecastActualData.push(array2);
            forecastActualData.push(array3);
          })
        });
        this.dataSource = new MatTableDataSource(forecastActualData);
        this.displayTable = true;
        this.isRequestCompleted = true;
        this.showProductNameColumn = true;
        this.showDepotNameColumn = true;
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.displayTable = false;
        this.isRequestCompleted = true;
      }
      else {
        this.displayTable = false;
        this.isRequestCompleted = true;
      }

    });
  }

  getReportsForExcelByViewAll(headerList: any) {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForAll(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var test = this;
        let excelAllReportsData = [];
        response["ActualVsPlannedByAll"].forEach(function (arrayItem) {
          var x = arrayItem;
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast']['Jan'], x['TypeCollectionForecast']['Feb'], x['TypeCollectionForecast']['Mar'], x['TypeCollectionForecast']['Apr'], x['TypeCollectionForecast']['May'], x['TypeCollectionForecast']['Jun'], x['TypeCollectionForecast']['Jul'], x['TypeCollectionForecast']['Aug'], x['TypeCollectionForecast']['Sep'], x['TypeCollectionForecast']['Oct'], x['TypeCollectionForecast']['Nov'], x['TypeCollectionForecast']['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual']['Jan'], x['TypeCollectionActual']['Feb'], x['TypeCollectionActual']['Mar'], x['TypeCollectionActual']['Apr'], x['TypeCollectionActual']['May'], x['TypeCollectionActual']['Jun'], x['TypeCollectionActual']['Jul'], x['TypeCollectionActual']['Aug'], x['TypeCollectionActual']['Sep'], x['TypeCollectionActual']['Oct'], x['TypeCollectionActual']['Nov'], x['TypeCollectionActual']['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          x['TypeCollectionForecast']['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          x['TypeCollectionActual']['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          x['TypeCollectionDiffernce']['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;

          excelAllReportsData.push(x['TypeCollectionForecast']);
          excelAllReportsData.push(x['TypeCollectionActual']);
          excelAllReportsData.push(x['TypeCollectionDiffernce']);
        });

        /* for (let reportData of excelAllReportsData) {
          for (let month of this.monthsList) {
            if (reportData[month] || reportData[month] == 0) {
              reportData[month] = +reportData[month];
            }
          }
        } */
        for (let reportData of excelAllReportsData) {
          for (let headerColumn of headerList) {
            if (reportData[headerColumn] || reportData[headerColumn] == 0) {
              reportData[headerColumn] = +reportData[headerColumn];
            }
          }
        }
        headerList.unshift("Type");
        this.exportDataInExcelFile(excelAllReportsData, 'Reports by all', headerList);
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'NO DATA FOUND') {
        this.showStatusDialog(false, 'no-data-available');
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
      else {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
    });
  }

  getReportsForExcelByViewDepot(headerList: any) {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForDepot(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = response["ActualVsPlannedByDepot"]["DepotCollection"];
        var test = this;
        var excelDepotReportsData = [];

        productData.forEach(function (arrayItem) {
          var x = arrayItem;
          let array1 = x['TypeCollectionForecast'][0];
          let array2 = x['TypeCollectionActual'][0];
          let array3 = x['TypeCollectionDifference'][0];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          array1['DepotName'] = x.DepotName;
          array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;


          excelDepotReportsData.push(array1);
          excelDepotReportsData.push(array2);
          excelDepotReportsData.push(array3);
        });

        /* for (let reportData of excelDepotReportsData) {
          for (let month of this.monthsList) {
            if (reportData[month] || reportData[month] == 0) {
              reportData[month] = +reportData[month];
            }
          }
        } */
        for (let reportData of excelDepotReportsData) {
          for (let headerColumn of headerList) {
            if (reportData[headerColumn] || reportData[headerColumn] == 0) {
              reportData[headerColumn] = +reportData[headerColumn];
            }
          }
        }
        headerList.unshift("Type");
        headerList.unshift("DepotName");
        this.exportDataInExcelFile(excelDepotReportsData, 'Reports by depot', headerList);
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'NO DATA FOUND') {
        this.showStatusDialog(false, 'no-data-available');
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
      else {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
    });
  }

  getReportsForExcelByViewProduct(headerList: any) {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForProduct(this.yearUserSelected, this.customerIdUserSelected, this.countryCode, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = response["ActualVsPlannedByProduct"]["ProductCollection"];
        var test = this;
        var excelProductReportsData = [];
        productData.forEach(function (arrayItem) {
          var x = arrayItem;
          let array1 = x['TypeCollectionForecast'][0];
          let array2 = x['TypeCollectionActual'][0];
          let array3 = x['TypeCollectionDifference'][0];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          array1['ProductName'] = x.ProductName;
          array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;
          excelProductReportsData.push(array1);
          excelProductReportsData.push(array2);
          excelProductReportsData.push(array3);
        });

        /* for (let reportData of excelProductReportsData) {
          for (let month of this.monthsList) {
            if (reportData[month] || reportData[month] == 0) {
              reportData[month] = +reportData[month];
            }
          }
        } */
        for (let reportData of excelProductReportsData) {
          for (let headerColumn of headerList) {
            if (reportData[headerColumn] || reportData[headerColumn] == 0) {
              reportData[headerColumn] = +reportData[headerColumn];
            }
          }
        }
        headerList.unshift("Type");
        headerList.unshift("ProductName");
        this.exportDataInExcelFile(excelProductReportsData, 'Reports by product', headerList);
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'NO DATA FOUND') {
        this.showStatusDialog(false, 'no-data-available');
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
      else {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
    });
  }

  getReportsForExcelByViewDeliverySite(headerList: any) {
    this.forecastActualService.getTableDataForDelSite(this.yearUserSelected, this.customerIdUserSelected).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = response["ActualVsPlannedByDeliverySite"]["DeliverySiteCollection"];
        var test = this;
        var excelDeliverySiteReportsData = [];

        // Added filter for delivery site selected in dropdown temporarily, until delivery site parameter is passed to service
        productData.forEach(function (arrayItem) {
          if (test.countryCode === 'FIN' || test.countryCode === 'SWE' || test.deliverySiteUserSelected === arrayItem['DeliverySite']) {
            var x = arrayItem;
            let array1 = x['TypeCollectionActual'][0];
            let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
            array1['DeliverySite'] = x.DeliverySite;
            array1['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
            excelDeliverySiteReportsData.push(array1);
          }
        });

        /* for (let reportData of excelDeliverySiteReportsData) {
          for (let month of this.monthsList) {
            if (reportData[month] || reportData[month] == 0) {
              reportData[month] = +reportData[month];
            }
          }
        } */
        for (let reportData of excelDeliverySiteReportsData) {
          for (let headerColumn of headerList) {
            if (reportData[headerColumn] || reportData[headerColumn] == 0) {
              reportData[headerColumn] = +reportData[headerColumn];
            }
          }
        }
        headerList.unshift("Type");
        headerList.unshift("DeliverySite");
        this.exportDataInExcelFile(excelDeliverySiteReportsData, 'Reports by delivery site', headerList);
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'NO RECORDS FOUND') {
        this.showStatusDialog(false, 'no-data-available');
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
      else {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
    });
  }

  getReportsForExcelByViewProductAndDepot(headerList: any) {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForDepotAndProduct(this.customerIdUserSelected, this.yearUserSelected, this.countryCode, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {

        var depotData = response["DepotCollection"];
        var test = this;
        var excelProductAndDepotReportsData = [];

        depotData.forEach(function (depotItem) {
          var productData = depotItem["ProductCollection"];

          productData.forEach(function (arrayItem) {
            var x = arrayItem;

            let array1 = x['TypeCollectionForecast'][0];
            let array2 = x['TypeCollectionActual'][0];
            let array3 = x['TypeCollectionDifference'][0];
            let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
            let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
            let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);

            array1['ProductName'] = x.ProductName;
            array1['DepotName'] = depotItem.DepotName;

            array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
            array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
            // array3['Total'] = diffenceTotalPer ? diffenceTotalPer.toString() : 0;
            array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;
            
            excelProductAndDepotReportsData.push(array1);
            excelProductAndDepotReportsData.push(array2);
            excelProductAndDepotReportsData.push(array3);
          })
        });

        /* for (let reportData of excelProductAndDepotReportsData) {
          for (let month of this.monthsList) {
            if (reportData[month] || reportData[month] == 0) {
              reportData[month] = +reportData[month];
            }
          }
        } */
        for (let reportData of excelProductAndDepotReportsData) {
          for (let headerColumn of headerList) {
            if (reportData[headerColumn] || reportData[headerColumn] == 0) {
              reportData[headerColumn] = +reportData[headerColumn];
            }
          }
        }
        headerList.unshift("Type");
        headerList.unshift("DepotName");
        headerList.unshift("ProductName");
        this.exportDataInExcelFile(excelProductAndDepotReportsData, 'Reports by product and depot', headerList);
      }
      else if (response['Description'] && response['Description'].toUpperCase() === 'NO DATA FOUND.') {
        this.showStatusDialog(false, 'no-data-available');
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
      else {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
    });
  }

  downloadReports() {
    let headerList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total"];
    if (this.viewUserSelected == "All") {
      this.getReportsForExcelByViewAll(headerList);
    } else if (this.viewUserSelected == "By depot") {
      this.getReportsForExcelByViewDepot(headerList);
    } else if (this.viewUserSelected == "By product") {
      this.getReportsForExcelByViewProduct(headerList);
    } else if (this.viewUserSelected == "By delivery site") {
      this.getReportsForExcelByViewDeliverySite(headerList);
    } else if (this.viewUserSelected == "By product and depot") {
      this.getReportsForExcelByViewProductAndDepot(headerList);
    }
  }

  exportDataInExcelFile(data: any[], excelFileName: string, headerList: any): void {
    if (data) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((data), { header: headerList });
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const Report: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(Report, excelFileName + EXCEL_EXTENSION);
    }
  }

  getReports() {
    if (this.currentToggleMenu === 'reports') {
      this.getReportsForTable();
    } else if (this.currentToggleMenu === 'graph') {
      this.getReportsForGraph();
    }
  }

  getReportsForGraph() {
    this.Order_month.splice(0, 52);
    this.Actual_Quantity.splice(0, 52);
    this.Forecast_Quantity.splice(0, 52);
    this.Contractual_Volume_Quantity.splice(0, 52);
    this.Contractual_Volume_Quantity1.splice(0, 52);
    this.Contractual_Volume_Quantity2.splice(0, 52);
    this.getBarChartData();
  }

  getBarChartData() {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getBarChartData(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(
      response => {
        if (response["ResponseCode"] == '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          if (this.Order_month.length > 0) {
            this.Order_month = [];
          }

          let Actual_Quantity1 = [100, 80, 90, 90, 80, 100, 80, 80, 100, 80];
          let Forecast_Quantity1 = [100, 110, 90, 80, 80, 70, 60, 50, 60, 60];
          let Contractual_Volume_Quantitya = [90, 80, 110, 120, 100, 80, 90, 90, 80, 100];
          let Contractual_Volume_Quantity1a = [10, 0, -22, -33, -25, 20, -13, -13, 20, -25];
          let Contractual_Volume_Quantity2a = [0, -38, 0, 11, 0, 30, 25, 38, 40, 25];
          let index = 0;

          response["Collection"].forEach(x => {

            /* let actualQuantity = parseInt(x.Actual_Quantity);
            let forecastQuantity = parseInt(x.Forecast_Quantity);
            let contractualVolumeQuantity = ((parseInt(x.Actual_Quantity) + parseInt(x.Forecast_Quantity)) / 2); */

            /* this.Actual_Quantity.push(parseInt(x.Actual_Quantity));
            this.Forecast_Quantity.push(parseInt(x.Forecast_Quantity));
            this.Contractual_Volume_Quantity.push((parseInt(x.Actual_Quantity) + parseInt(x.Forecast_Quantity)) / 2);
            this.Contractual_Volume_Quantity1.push(100 * Math.abs((contractualVolumeQuantity - actualQuantity) / ((contractualVolumeQuantity + actualQuantity) / 2)));
            this.Contractual_Volume_Quantity2.push(100 * Math.abs((forecastQuantity - actualQuantity) / ((forecastQuantity + actualQuantity) / 2))); */

            /* this.Actual_Quantity = ['100','80','90','90','80','100','80','80','100','80'];
            this.Forecast_Quantity = ['100','110','90','80','80','70','60','50','60','60'];
            this.Contractual_Volume_Quantity = ['90','80','110','120','100','80','90','90','80','100'];
            this.Contractual_Volume_Quantity1 = ['10','0','-22','-33','-25','20','-13','-13','20','-25'];
            this.Contractual_Volume_Quantity2 = ['0','-38','0','11','0','30','25','38','40','25']; */

            this.Actual_Quantity.push(Actual_Quantity1[index]);
            this.Forecast_Quantity.push(Forecast_Quantity1[index]);
            this.Contractual_Volume_Quantity.push(Contractual_Volume_Quantitya[index]);
            this.Contractual_Volume_Quantity1.push(Contractual_Volume_Quantity1a[index]);
            this.Contractual_Volume_Quantity2.push(Contractual_Volume_Quantity2a[index]);

            this.translate.get(['HOME.' + x.Forecast_month])
              .subscribe(data => {
                this.Order_month.push(data['HOME.' + x.Forecast_month]);
              });

              index++;
          });

          this.displayGraph = true;
          this.isRequestCompleted = true;
        }
        else if (response['ResponseCode'] && response['ResponseCode'] === '201') {
          // when no records returned by service
          this.displayGraph = false;
          this.isRequestCompleted = true;
        }
        else {
          this.displayGraph = false;
          this.isRequestCompleted = true;
          this.showStatusDialog(false, 'Something-went-wrong');
        }
      });

  }

  getTitleBySelectedLanguage(title: string): string {
    let translatedTitle: string;
    let selectedLanguage = this.sharedService.getSelectedLanguage();
    if (title === 'Actual') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Actual';
          break;
        case 'FI':
          translatedTitle = 'Toteuma';
          break;
        case 'SE':
          translatedTitle = 'Faktiskt';
          break;
        case 'NR':
          translatedTitle = 'Salg';
          break;
        default:
          translatedTitle = 'Actual';
      }
    }
    if (title === 'Planned') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Planned';
          break;
        case 'FI':
          translatedTitle = 'Ennuste';
          break;
        case 'SE':
          translatedTitle = 'Prognostiserat';
          break;
        case 'NR':
          translatedTitle = 'Prognose';
          break;
        default:
          translatedTitle = 'Planned';
      }
    }
    if (title === 'Contractual volume') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Contractual volume';
          break;
        case 'FI':
          translatedTitle = 'Sopimusmäärä';
          break;
        case 'SE':
          translatedTitle = 'Avtalsenlig volym';
          break;
        case 'NR':
          translatedTitle = 'Kontraktert volum';
          break;
        default:
          translatedTitle = 'Contractual volume';
      }
    }
    return translatedTitle;
  }

  showStatusDialog(isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,

      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  getTotal(jan: string, feb: string, mar: string, apr: string, may: string, jun: string, jul: string, aug: string, sep: string, oct: string, nov: string, dec: string) {
    var totalValue = parseInt(jan) + parseInt(feb) + parseInt(mar) + parseInt(apr) + parseInt(may) +
      parseInt(jun) + parseInt(jul) + parseInt(aug) + parseInt(sep) + parseInt(oct) + parseInt(nov) +
      parseInt(dec);
    return totalValue;
  }

  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        if (deliverySiteObject && deliverySiteObject['DeliverySite'] === deliverySiteName) {
          jdeAddressNumber = deliverySiteObject['JDE_ADDRESS_NUMBER'];
          break;
        }
      }
    }
    return jdeAddressNumber;
  }

  //  Changes for barchart start
  
  public barChartLabels2: Label[] = this.Order_month;
  public barChartType2: ChartType = 'bar';
  public barChartLegend2 = true;
  public barChartPlugins2 = [pluginDataLabels];

  public barChartData2: ChartDataSets[] = [

    { data: this.Contractual_Volume_Quantity1, label: 'Average of Diff (Planned v/s Actual)', pointStyle: "circle", backgroundColor: '#3C3C3C', hoverBackgroundColor: '#3C3C3C', hoverBorderColor: '#3C3C3C', type: "line", borderColor: "#3C3C3C", fill: false, yAxisID: 'y-axis-1', borderWidth: 2, lineTension: 0, barThickness: 10 },
    { data: this.Contractual_Volume_Quantity2, label: 'Average of Diff (Contractual v/s Actual)', pointStyle: "circle", backgroundColor: '#9F3259', hoverBackgroundColor: '#9F3259', hoverBorderColor: '#9F3259', type: "line", borderColor: "#9F3259", fill: false, yAxisID: 'y-axis-1', borderWidth: 2, lineTension: 0, barThickness: 10 },
    { data: this.Actual_Quantity, label: 'Sum of Actual', pointStyle: "line", backgroundColor: '#FFD500', hoverBackgroundColor: '#FFD500', hoverBorderColor: '#FFD500', yAxisID: 'y-axis-0', barPercentage: 1, categoryPercentage: 1 },
    { data: this.Forecast_Quantity, label: 'Sum of Planned', pointStyle: "line", backgroundColor: '#69AAA4', hoverBackgroundColor: '#69AAA4', hoverBorderColor: '#69AAA4', yAxisID: 'y-axis-0', barPercentage: 1, categoryPercentage: 1 },
    { data: this.Contractual_Volume_Quantity, label: 'Sum of Contractual', pointStyle: "line", backgroundColor: '#919191', hoverBackgroundColor: '#919191', hoverBorderColor: '#919191', yAxisID: 'y-axis-0', barPercentage: 1, categoryPercentage: 1 }
  ];

  public barChartOptions2: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 12,
      fontColor: "black"
    },
    // tooltips: {
    //   mode: 'index'
    // },
    scales: {

      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 12,
          fontStyle: "bold",
          minRotation: 0,
          padding: 10
        }
      }], 
      
      /* yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 12,
          fontStyle: "bold"
        }
      }] */

      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'MT',
            fontSize: 12,
            fontFamily: 'Arial',
            fontStyle: 'bold',
            fontColor: 'black',
          },
          gridLines: {
            drawBorder: true,
            display: false
          },
          ticks: {
            fontColor: 'black',
            fontSize: 10,
            fontFamily: 'Arial',
            fontStyle: 'bold',

            // To implement dynamic coordinates for Graph Y axis, change beginAtZero to false and comment min, max values

            beginAtZero: true,
            // min: 230,
            // max: 330,

            // suggestedMax: 100
          }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: '%',
            fontSize: 12,
            fontFamily: 'Arial',
            fontStyle: 'bold',
            fontColor: 'black',
          },
          gridLines: {
            drawBorder: true,
            display: false
          },
          ticks: {
            fontColor: 'black',
            fontSize: 10,
            fontFamily: 'Arial',
            fontStyle: 'bold',

            // To implement dynamic coordinates for Graph Y axis, change beginAtZero to false and comment min, max values

            beginAtZero: false,
            // min: 1.0,
            // max: 1.35,
          },
        }
      ]
      
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 12,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    },

    tooltips: {
      mode: 'index',
      bodyFontSize: 10,
      bodyFontFamily: 'Arial',
      titleFontSize: 11,
      titleFontFamily: 'Arial',
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ' : ' + tooltipItem.yLabel + (label.includes('Average') ? '%' : '');
          }
          return label;
        }
      }
    },

    /* animation: {
      onComplete: function () {
        var ctx = this.chart.ctx;
        // ctx.font = this.scale.font;
        // ctx.fillStyle = this.scale.textColor
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";

        this.barChartData2.forEach(function (dataset) {
          dataset.points.forEach(function (points) {
            ctx.fillText(points.value, points.x, points.y - 10);
          });
        })
      }
    } */

    hover: {
      animationDuration: 0
    },
    animation: {
      duration: 1,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        // ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);

        ctx.font = Chart.helpers.fontString(8, 'bold', 'Arial', 'black');

        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data + (dataset['label'].includes('Average') ? '%' : ''), bar._model.x, bar._model.y - 5);
          });
        });
      }
    }

  };

  // Changes for barchart end

  //bar chart data for customer role: start

  public barChartOptions1: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    tooltips: {
      mode: 'index'
    },
    scales: {

      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "bold"
        }
      }], 
      
      yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 14,
          fontStyle: "bold"
        }
      }]

    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    }
  };
  public barChartLabels1: Label[] = this.Order_month;
  public barChartType1: ChartType = 'bar';
  public barChartLegend1 = true;
  public barChartPlugins1 = [pluginDataLabels];

  public barChartData1: ChartDataSets[] = [
    { data: this.Forecast_Quantity, label: 'Forecast', pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' }
  ];
  //bar chart data for customer role : end

  //  Changes for pie chart 1 start

  public barChartOptions3: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,

    // title: {
    //   display: true,
    //   position: "top",
    //   text: "",
    //   fontSize: 14,
    //   fontColor: "black"
    // },
    // tooltips: {
    //   mode: 'index'
    // },
    // scales: {

    //   xAxes: [{
    //     ticks: {
    //       fontColor: 'black',
    //       fontSize: 10,
    //       fontStyle: "bold"
    //     }
    //   }], yAxes: [{
    //     ticks: {
    //       beginAtZero: true,
    //       fontColor: 'black',
    //       fontSize: 14,
    //       fontStyle: "bold"
    //     }
    //   }]
    // },
    legend: {
      position: "bottom",
      display: false,
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      labels: {
        position: 'outside'
      },
      datalabels: {
        display: false,
        // align: 'bottom',
        // backgroundColor: '#ccc',
        // borderRadius: 3,
        font: {
          size: 10,
        }
      },
    }
  };
  public barChartLabels3: Label[] = this.Order_month;
  public barChartType3: ChartType = 'pie';
  public barChartLegend3 = true;
  public barChartPlugins3 = [pluginDataLabels];

  public barChartData3: ChartDataSets[] = [
    { data: this.Actual_Quantity, label: this.getTitleBySelectedLanguage('Actual'), backgroundColor: ['#3C3C3C', '#D8E2EE', '#9F3259', '#69AAA4', '#0054A3', '#FFD500', '#919191', '#3C3C3C', '#D8E2EE', '#9F3259', '#69AAA4', '#0054A3'] },
    // { data: this.Forecast_Quantity, label: this.getTitleBySelectedLanguage('Planned'), pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' },
    // { data: this.Contractual_Volume_Quantity, label: this.getTitleBySelectedLanguage('Contractual volume'), pointStyle: "line", backgroundColor: '#9F3259', hoverBackgroundColor: '#9F3259', hoverBorderColor: '#9F3259' }
  ];

  // Changes for pie chart 1 end

  //  Changes for pie chart 2 start

  public barChartOptions4: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      position: "bottom",
      display: false,
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      labels: {
        position: 'outside'
      },
      datalabels: {
        display: false,
        font: {
          size: 10,
        }
      },
    }
  };
  public barChartLabels4: Label[] = this.Order_month;
  public barChartType4: ChartType = 'pie';
  public barChartLegend4 = true;
  public barChartPlugins4 = [pluginDataLabels];

  public barChartData4: ChartDataSets[] = [
    { data: this.Forecast_Quantity, label: this.getTitleBySelectedLanguage('Planned'), backgroundColor: ['#3C3C3C', '#D8E2EE', '#9F3259', '#69AAA4', '#0054A3', '#FFD500', '#919191', '#3C3C3C', '#D8E2EE', '#9F3259', '#69AAA4', '#0054A3'] },
  ];

  // Changes for pie chart 2 end

  //  Changes for pie chart 3 start

  public barChartOptions5: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      position: "bottom",
      display: false,
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      labels: {
        position: 'outside'
      },
      datalabels: {
        display: false,
        font: {
          size: 10,
        }
      },
    }
  };
  public barChartLabels5: Label[] = this.Order_month;
  public barChartType5: ChartType = 'pie';
  public barChartLegend5 = true;
  public barChartPlugins5 = [pluginDataLabels];

  public barChartData5: ChartDataSets[] = [
    { data: this.Contractual_Volume_Quantity, label: this.getTitleBySelectedLanguage('Contractual volume'), backgroundColor: ['#3C3C3C', '#D8E2EE', '#9F3259', '#69AAA4', '#0054A3', '#FFD500', '#919191', '#3C3C3C', '#D8E2EE', '#9F3259', '#69AAA4', '#0054A3'] },
  ];

  // Changes for pie chart 3 end

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

}


