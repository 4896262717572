import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from '../../user-management/dialog-body/dialog-body.component';
import { AddDeliverySiteComponentService } from '../add-delivery-site/add-delivery-site.component.service';
import { DatePipe } from '@angular/common';
import { MediaMatcher } from '@angular/cdk/layout';
import { MyCompanyService } from '../my-company.service';

@Component({
  selector: 'app-approve-delivery-site',
  templateUrl: './approve-delivery-site.component.html',
  styleUrls: ['./approve-delivery-site.component.css']
})
export class ApproveDeliverySiteComponent implements OnInit, OnDestroy {

  message: string = "";
  cancelButtonText = "Cancel";
  approveDeliverySiteForm: FormGroup;
  selectedDeliverySite: any;
  userInfo: any;
  loggedInUserId: string;
  deliverySiteName: string;
  disableJDEAddressNumberInput: boolean;
  isLoggedInUserPortalAdmin: boolean;
  isLoggedInUserDepotAdmin: boolean;
  loggedInUserName: string;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  countryCode: string;
  hideJDEAddressNumberField: boolean;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ApproveDeliverySiteComponent>,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private addDeliverySiteComponentService: AddDeliverySiteComponentService,
    private sharedService: AppService,
    private datePipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef, 
    private media: MediaMatcher,
    private myCompanyService: MyCompanyService) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.loggedInUserId = (this.userInfo ? this.userInfo.UserID : '');
    this.isLoggedInUserPortalAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA") : false);
    this.isLoggedInUserDepotAdmin = (this.userInfo ? (this.userInfo.RoleID === "DA") : false);
    this.countryCode = this.userInfo.CountryCode;
    if (data) {
      this.selectedDeliverySite = data.selectedDeliverySite;
      this.hideJDEAddressNumberField = data.hideJDEAddressNumberField;
    }
    this.dialogRef.updateSize('300vw', '400vw');
		this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.approveDeliverySiteForm = this.formBuilder.group({
      DepotAddressNumber: ['', Validators.nullValidator],
      JdeAddressNumber: ['', [Validators.nullValidator, Validators.pattern('^[0-9]+$')]],
      ApprovalStatus: ['Approved', Validators.nullValidator],
      ApprovalStage: ['', Validators.nullValidator],
      UpdatedBy: ['', Validators.nullValidator],
      Type: [''],
      CustomerId: [''],
      DeliverySite: [''],
      CountryId: [''],
      UpdatedOn: ['']
    });
    this.approveDeliverySiteForm.patchValue(this.selectedDeliverySite);
    this.approveDeliverySiteForm.controls['ApprovalStage'].setValue(this.selectedDeliverySite['ApprovalStage']);
    this.approveDeliverySiteForm.controls['UpdatedBy'].setValue(this.loggedInUserName);
    this.approveDeliverySiteForm.controls['CustomerId'].setValue(this.selectedDeliverySite['CustomerId']);
    this.approveDeliverySiteForm.controls['DeliverySite'].setValue(this.selectedDeliverySite['DeliverySite']);
    this.approveDeliverySiteForm.controls['CountryId'].setValue(this.selectedDeliverySite['CountryId']);
    this.approveDeliverySiteForm.controls['UpdatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
    this.approveDeliverySiteForm.controls['Type'].setValue('JDE_CONTACT');
    this.deliverySiteName = this.selectedDeliverySite ? this.selectedDeliverySite.DeliverySite : '';
    if (this.selectedDeliverySite && this.selectedDeliverySite['Status'] === "Rejected") {
      this.approveDeliverySiteForm.controls['ApprovalStatus'].setValue(this.selectedDeliverySite['Status']);  
    }
    if (this.approveDeliverySiteForm && this.approveDeliverySiteForm.value) {
      const formValue = this.approveDeliverySiteForm.value;
      this.disableJDEAddressNumberInput = (formValue.DepotAddressNumber && this.isLoggedInUserPortalAdmin) ? undefined : true;
    } else {
      this.disableJDEAddressNumberInput = true;
    }

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  async approveDeliverySite() {
    // Check if it is UpdateDeliverySite or UpdateDepotStatus or UpdateJDEStatus
    /* if (this.approveDeliverySiteForm.controls['DepotAddressNumber'].value && !this.approveDeliverySiteForm.controls['JdeAddressNumber'].value) {
      // Set value for field not present in UI form      
      this.approveDeliverySiteForm.controls['ApprovalStatus'].setValue('Approved');
      this.addDeliverySiteComponentService.updateDepotStatus(this.approveDeliverySiteForm.value)
        .subscribe(
          (response: any) => {
            this.approveDeliverySiteStatusDialog(response);
          });
    } else if (this.approveDeliverySiteForm.controls['DepotAddressNumber'].value && (this.approveDeliverySiteForm.controls['JdeAddressNumber'].value || this.approveDeliverySiteForm.controls['ApprovalStatus'].value === "Rejected")) {
      this.addDeliverySiteComponentService.updateJDEStatus(this.approveDeliverySiteForm.value)
        .subscribe(
          (response: any) => {
            this.approveDeliverySiteStatusDialog(response);
          });
    } */

    /* Commented below code, kept for future reference */
    /*   if (this.approveDeliverySiteForm.controls['DepotAddressNumber'].value && (this.approveDeliverySiteForm.controls['JdeAddressNumber'].value || this.approveDeliverySiteForm.controls['ApprovalStatus'].value === "Rejected")) {
        this.addDeliverySiteComponentService.updateJDEStatus(this.approveDeliverySiteForm.value)
          .subscribe(
            (response: any) => {
              // this.approveDeliverySiteStatusDialog(response);
  
              this.sendEmailNotification('DS_JDENUMBER_CHANGE');
  
            });
      } else if (this.approveDeliverySiteForm.controls['DepotAddressNumber'].value && !this.approveDeliverySiteForm.controls['JdeAddressNumber'].value) {
        // Set value for field not present in UI form      
        this.approveDeliverySiteForm.controls['ApprovalStatus'].setValue('Approved');
        this.addDeliverySiteComponentService.updateDepotStatus(this.approveDeliverySiteForm.value)
          .subscribe(
            (response: any) => {
              // this.approveDeliverySiteStatusDialog(response);
  
              this.sendEmailNotification('DS_DEPOTNUMBER_CHANGE');
  
            });
      } else if (!this.approveDeliverySiteForm.controls['DepotAddressNumber'].value) {
        this.openApproveDeliverySiteDialog('Please enter: Address number (Depot) ', false,'app-add-update');
      } else {
        this.openApproveDeliverySiteDialog('Please enter required fields.',  false,'app-add-update');
      } */



    let existingDeliverSiteDepotAddressNumber: string = this.selectedDeliverySite['DepotAddressNumber'];
    let existingDeliverSiteJDEAddressNumber: string = this.selectedDeliverySite['JdeAddressNumber'];
    let updatedDeliverSiteDepotAddressNumber: string = this.approveDeliverySiteForm.value['DepotAddressNumber'];
    let updatedDeliverSiteJDEAddressNumber: string = this.approveDeliverySiteForm.value['JdeAddressNumber'];

    if ((existingDeliverSiteDepotAddressNumber === updatedDeliverSiteDepotAddressNumber) && (existingDeliverSiteJDEAddressNumber === updatedDeliverSiteJDEAddressNumber)) {
      this.openApproveDeliverySiteDialog('No data change. Kindly update and save', false, 'forecast-message');
    } else if (this.approveDeliverySiteForm.valid) {
      if ((existingDeliverSiteDepotAddressNumber !== updatedDeliverSiteDepotAddressNumber) && (existingDeliverSiteJDEAddressNumber !== updatedDeliverSiteJDEAddressNumber)) {
        try {
          await this.updateDepotAddressNumber();
        } catch (e) {
        }
        try {
          await this.updateJDEAddressNumber();
        } catch (e) {
        }
      } else if (existingDeliverSiteDepotAddressNumber !== updatedDeliverSiteDepotAddressNumber) {
        try {
          await this.updateDepotAddressNumber();
        } catch (e) {
        }
      } else if (existingDeliverSiteJDEAddressNumber !== updatedDeliverSiteJDEAddressNumber) {
        try {
          await this.updateJDEAddressNumber();
        } catch (e) {
        }
      }
    }

  }

  approveDeliverySiteStatusDialog(response: any) {
    this.cancel();
    if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
      this.dialog.closeAll();
      this.openApproveDeliverySiteDialog('Delivery site updated successfully',true,'app-del-site-update');
      this.myCompanyService.sendFetchDeliverySitesEvent();
    } else if (response && response['ResponseCode'] === '201' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
      this.openApproveDeliverySiteDialog('Failed to Approve Delivery Site', false,'app-del-site-update-fail');
    } else {
      this.openApproveDeliverySiteDialog('Failed to Approve Delivery Site', false,'app-del-site-update-fail');
    }
  }

  openApproveDeliverySiteDialog(headerTitle: string, isSuccess: boolean,headerNumber:string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: ' ',
        isSuccess: isSuccess,
        headerNumber:headerNumber,
        buttonText: {
          ok: 'Ok'
        }
      },
      height: '39%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  cancel(): void {
    this.dialogRef.close(true);
  }

  sendEmailNotification(response: any, key: string): void {
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        this.selectedDeliverySite['DeliverySiteName'] = this.approveDeliverySiteForm.value['DeliverySite'];
        this.selectedDeliverySite['DepotAddressNumber'] = this.approveDeliverySiteForm.value['DepotAddressNumber'];
        this.selectedDeliverySite['JdeAddressNumber'] = this.approveDeliverySiteForm.value['JdeAddressNumber'];
        this.addDeliverySiteComponentService.emailService(key, this.countryCode, this.selectedDeliverySite, '', '', null)
          .subscribe(
            (response: any) => {
              this.approveDeliverySiteStatusDialog(response);
            });
      } else {
        this.openApproveDeliverySiteDialog('Failed to Approve Delivery Site', false, 'app-del-site-update-fail');
      }
    } else {
      this.openApproveDeliverySiteDialog('Failed to Approve Delivery Site', false, 'app-del-site-update-fail');
    }
  }

  async updateDepotAddressNumber() {
    return new Promise<void>((resolve, reject) => {
      // Set value for field not present in UI form      
      this.approveDeliverySiteForm.controls['ApprovalStatus'].setValue('Approved');
      this.addDeliverySiteComponentService.updateDepotStatus(this.approveDeliverySiteForm.value)
        .subscribe(
          (response: any) => {
            if (response) {
              this.sendEmailNotification(response, 'DS_DEPOTNUMBER_CHANGE');
              resolve();
            }
            else {
              reject();
            }
          });
    })
  }

  async updateJDEAddressNumber() {
    return new Promise<void>((resolve, reject) => {
      this.addDeliverySiteComponentService.updateJDEStatus(this.approveDeliverySiteForm.value)
        .subscribe(
          (response: any) => {
            if (response) {
              this.sendEmailNotification(response, 'DS_JDENUMBER_CHANGE');
              resolve();
            }
            else {
              reject();
            }
          });
    })
  }

  numberOnly(event: any) {
    let result: any;
    let patternToAcceptOnlyNumbers = /^([0-9])*$/;
    let enteredText: string;
    if (event && event.clipboardData && event.clipboardData.getData('Text')) {
      enteredText = event.clipboardData.getData('Text');
    }
    if (event && event.key) {
      enteredText = event.key;
    }
    result = patternToAcceptOnlyNumbers.test(enteredText);
    return result;
  }

}
