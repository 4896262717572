import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AppService } from '../shared-modules/services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ContactUsService } from './contact-us.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { TranslateService } from '@ngx-translate/core';
import { MediaMatcher } from '@angular/cdk/layout';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit, OnDestroy {
  selectedItem: String = 'Contact Us';
  navbarOpen = false;
  countrySearch: any;
  countryList: any;
  userInfo: any;
  name: String;

  // isLoggedInUserAdmin: boolean;
  user: any;
  public displayedColumns = ['Contact_Image', 'Contact_Name', 'Designation', 'Phone_Number', 'Email_Id'];
  allowedMenuIdList: string[] = [];
  countryCode: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private contactUsService: ContactUsService,
    private dialog: MatDialog,
    private sharedService: AppService,
    public translate: TranslateService,
    private cd: ChangeDetectorRef,
    private media: MediaMatcher
  ) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.name = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    // this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.getAllowedMenuIdList(this.userInfo ? this.userInfo.Menus : []);
    this.countryCode = this.userInfo.CountryCode;

    this.user = [

      //Uncomment to display data if services are down
      // {'photo': '/assets/images/Maria.jpg',
      //   'sname': 'Helena Remes', 'designation': 'Technical Support Engineer', 
      // 'contact': '+358 40 826 0312', 'email': 'helena.remes@nynas.com' },

      // {'Contact_Image': '/assets/images/MRSL.jpg', 'Contact_Name': 'Helena Remes', 
      // 'Designation': 'Technical Support Engineer', 'Phone_Number': '+358 40 826 0312', 
      // 'Email_Id': 'helena.remes@nynas.com' },  

      // {'photo': '/assets/images/Maria.jpg',
      // 'sname': 'Helena Remes', 'designation': 'Technical Support Engineer', 
      // 'Phone_Number': '+358 40 826 0312', 'email': 'helena.remes@nynas.com' },  

      // {'photo': '/assets/images/Maria.jpg',
      // 'sname': 'Helena Remes', 'designation': 'Technical Support Engineer', 
      // 'contact': '+358 40 826 0312', 'email': 'helena.remes@nynas.com' },  

      // {'photo': '/assets/images/MRSL.jpg',
      // 'sname': 'Helena Remes', 'designation': 'Technical Support Engineer', 
      // 'contact': '+358 40 826 0312', 'email': 'helena.remes@nynas.com' },  

    ];
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cd.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnInit() {
    this.countryList = { fi: 'Finland' };
    this.countrySearch = 'fi';
    this.getContactUs();
    this.getCountry()
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  showSelectedMenuItem(itemName: String) {
    this.selectedItem = itemName;

  }
  getCountry() {
    this.countryList = [];
    this.contactUsService.getCountries().subscribe(
      res => {
        if (res["ResponseCode"] === "200") {
          this.countryList = res["Countries"]
        }
      });

  }
  getContactUs() {
    this.user = [];

    this.contactUsService.getContactUs(this.countryCode)
      .subscribe(
        res => {
          let user = 'ContactDetails';

          if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            // if (false) {
            if (res && res[user] && res[user].length) {
              // this.user = res[user]; 
              var userList = [];
              for (user of res[user]) {
                let image = user['Contact_Image'];
                user['Contact_Image'] = image ? ('data:image/png;base64,' + image) : '';
                user['phoneNumberList'] = user['Phone_Number'] ? user['Phone_Number'].split(',') : [];
                userList.push(user);
              }
              this.user = userList;
              // this.dataSource.data = userList;
              // this.isRequestCompleted = true;
            }

          }
          else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
            // else if (true) {
            this.openSaveUserDialog('No Data available', '', false,'no-data-available');
          }
          else {
            this.openSaveUserDialog('No Data available', '', false,'no-data-available');
          }

        });
  }

  validateMenu(menuId: string): boolean {
    return this.allowedMenuIdList.includes(menuId);
  }
  getAllowedMenuIdList(menuList: any) {
    if (menuList && menuList.length) {
      for (var menu of menuList) {
        this.allowedMenuIdList.push(menu.MenuID);
      }
    }
  }

  openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber
      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

}
