<div class="container-fluid">
    <div class="col-lg-12 col-md-12 mb-3 ">
        <div class='row no-gutters'>
            <!-- <form [formGroup]="loginhistoryForm"> -->
            <!-- <div class="Customer_name_label_div  " *ngIf="this.role == 'PA'"> -->
                <!-- <div class="form-group"> -->
            <div class=" Customer_name_label_div  ">
                <label class="login_history_label">
                    <span class="input-text-header"> {{'HOME.Customername'| translate}}</span>
                </label>
                </div>
            <!-- </div> -->
            
            <div class="Customer_name_div_dropdown_div  " >
                <select class="login_history_dropdown "for="companyName"  [(ngModel)]="CustomerID"  >

                    <option value="">{{'HOME.All'| translate}}</option>
                    
                     
                    <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                        {{customer.CustomerName}}
                    </option>
                </select>
               
            </div>

            <div class="From_date_div  " >
                <label for="From_date">
                    <span class="login_history_label"> {{'HOME.FromDate'| translate}}</span>                    
                </label>
                <mat-form-field class="datepicker ">
                    <input matInput [matDatepicker]="picker" placeholder="" [(ngModel)]='FromDate' >
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              
            </div>           

            <div class="find_btn_div  ">
                <button class="find_btn"  (click)="getLoginHistory()">{{'HOME.Find'| translate}}
                </button>
            </div>
           
        </div>
    </div>
    <div class="col-lg-12 mt-10 ">
        <div class='row no-gutters'>
             <div class="mt-5" [hidden]="displayTable">
                {{'HOME.Norecordsfound'| translate}}
            </div> 
            <div class="col-lg-12" [hidden]='!displayTable'>
               
                <!--Angular 7 Impl-->
                <mat-table #table [dataSource]="dataSource" matSort class="forecastTable horizontal_scrollbar">


                    <ng-container matColumnDef="USER_NAME">
                        <mat-header-cell class="mat-column-history" *matHeaderCellDef> Name </mat-header-cell>
                        <mat-cell  class="mat-column-history" *matCellDef="let fetchedDataForItemArray"> {{fetchedDataForItemArray.USER_NAME}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="USER_ID">
                        <mat-header-cell  class="mat-column-history" *matHeaderCellDef> Email </mat-header-cell>
                        <mat-cell  class="mat-column-history-email"  *matCellDef="let fetchedDataForItemArray"> {{fetchedDataForItemArray.USER_ID}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="STATUS">
                        <mat-header-cell class="mat-column-history"  *matHeaderCellDef> Login status </mat-header-cell>
                        <mat-cell class="mat-column-history-status"  *matCellDef="let fetchedDataForItemArray"> {{fetchedDataForItemArray.STATUS}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="LAST_LOGIN_TIME">
                        <mat-header-cell class="mat-column-history-login"  *matHeaderCellDef> Last login date </mat-header-cell>
                        <mat-cell class="mat-column-history-login" *matCellDef="let fetchedDataForItemArray"> {{fetchedDataForItemArray.LAST_LOGIN_TIME |date:'yyyy-MM-dd HH:mm:ss' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="MESSAGE">
                        <mat-header-cell class="mat-column-history" *matHeaderCellDef> Message </mat-header-cell>
                        <mat-cell class="mat-column-history"  *matCellDef="let fetchedDataForItemArray"> {{fetchedDataForItemArray.MESSAGE}} </mat-cell>
                    </ng-container>


                 


                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns;sticky: true">
                    </mat-header-row>

                    <mat-row *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>
                   
                </mat-table>

                <mat-paginator  [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons>
                </mat-paginator> 
                <!-- <div class="row buttonGroup">
                    <button class=" downloadButton" type="submit" (click)="downloadBitumenIndexData()">{{'HOME.Download'| translate}}</button>
                </div> -->
            </div>
        </div>
    </div>
</div>