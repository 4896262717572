<div class="container">
    <div class="row pt-1 pb-1 no-gutters">
        <div class="col-md-6 pt-1">
            <label class="year-title" for="year"> {{'HOME.Year'| translate}}
            </label>
        </div>
        <div class="col-md-6">
            <select class="selectDropdown" [(ngModel)]="yearToSearch">
                <option *ngFor="let year of dropDownYear">{{year}}</option>
            </select>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col-sm text-center">
            <button class="btn btn-sm btn-primary dialog-footer-button" (click)="getQualityCertificate()">
                {{'HOME.View'| translate}}
            </button>
        </div>
        <div class="col-sm text-center cancel-button-padding">
            <button class="btn btn-sm btn-primary dialog-footer-button" (click)="cancel()"> {{'HOME.Cancel'| translate}}
            </button>
        </div>
    </div>
</div>