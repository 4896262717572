
<div class="container-fluid sidepadding-none padding-10 scroll-table-content scrollbar-table-content">

        <div class='col-lg-12'>
                <div class='row'>
                  <div class="col-lg-1 mt-10 align-right">
                    <label class="font-bold " for="countryType">{{'HOME.Country'|translate}}</label>
                  </div>
                  <div class="col-lg-4">
                     
                    <!-- <select class="select-card" [(ngModel)]="countrySearch">
                      <option *ngFor="let country of countryList" [value]="country.name">
                        {{country.name}}  <span class='flag-icon flag-icon-us'></span>
                      </option>
                    </select> -->
                    <!-- <div class="bfh-selectbox bfh-countries" data-country="US" data-flags="true">
                      </div> -->
                    <!-- <select class="select-card selectpicker" data-flag="true"></select> -->
                    <mat-form-field appearance="outline">
                        <mat-select  disableOptionCentering   [(value)]="countrySearch"  panelClass="select-card">
                            <mat-select-trigger>
                                <span class="flag-icon flag-icon-{{countrySearch | lowercase}} flag-size"></span>{{countryList[countrySearch]}}
                              </mat-select-trigger>
                          <mat-option *ngFor="let country of countryList | keyvalue" [value]="country.key">
                            <span class="flag-icon flag-icon-{{ country.key | lowercase}} flag-size"></span>{{country.value}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  </div>
                </div>
              </div>

        <div  class="col-lg-12 mt-25 mb-20">
        <span class='header-font'> </span>
        </div>
    <div *ngFor='let tableInfo of tableInformation' class="col-lg-12">

        <span class="bold-font mt-25">
            {{tableInfo.productType}}
        </span>
        <div class="underline-data"></div>
        <div class="col-lg-9">

            <table class="table table-striped  borderless">

                <thead >
                    <tr>
                <th  class="table-header" 
                [ngClass]="getTableCSS(tableInfo)" ></th>
                  <ng-container  *ngFor='let tableHeaders of tableInfo.tableColumns | keyvalue' >
                     <ng-container *ngFor="let header of tableHeaders.value | keyvalue"> 
                         <th class="table-header"  [ngClass]="getTableCSS(tableInfo)">{{header.value }}</th></ng-container>
                  </ng-container>
                  
                  
                </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let data of tableInfo.tabledata ;let i=index;">
                        <ng-container *ngFor='let rowsData of data.productsData;let i=index;'>
                            <tr *ngIf='i==0'>

                                <td  class='text-center' [ngClass]="{'regular-color': data.productSubType=='Regular', 'extra-color': data.productSubType=='Extra' }" 
                                
                                [attr.rowspan]="data.productsData.length" >{{data.productSubType|uppercase }}</td>
                                <ng-container *ngFor="let tableHeaders of tableInfo.tableColumns | keyvalue " >
                                    <ng-container *ngFor="let header of tableHeaders.value | keyvalue">
                                        <td class="table-data ">{{rowsData[header.key] }}</td></ng-container>
                                    </ng-container>
                            </tr>

                            <tr *ngIf='i!=0'>
                                <ng-container *ngFor="let tableHeaders of tableInfo.tableColumns | keyvalue ">
                                    <ng-container >
                                        <ng-container *ngFor="let header of tableHeaders.value | keyvalue">
                                            <td class="table-data">{{rowsData[header.key] }}</td></ng-container>
                                    </ng-container>


                                </ng-container>
                            </tr>

                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
            <ng-container *ngIf='tableInfo.staticInformation'>
                <div   class='static-info' [innerHTML]='tableInfo.staticInformation'></div>
                </ng-container>
        </div>



    </div>
</div>

