export class Country {
	id: string;
	name: string;
	code: number;

	constructor(id: string, name: string, code: number) {
		this.id = id;
		this.name = name;
		this.code = code;
	}
}