import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../order-management/order-management/order-management.component.service';
import { AppService } from '../shared-modules/services/shared.service';
import { FormBuilder, AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeliveryGuideAdminService } from './delivery-guide-admin.service';
import { TableData } from './table-data.model';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSearchService } from '../global-search/global-search.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YY',
  },
  display: {
    dateInput: 'DD-MMM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};
 
@Component({
  selector: 'app-delivery-guide-admin',
  templateUrl: './delivery-guide-admin.component.html',
  styleUrls: ['./delivery-guide-admin.component.css'],
  providers: [
  // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
 
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
 
})
export class DeliveryGuideAdminComponent implements OnInit, OnDestroy {
  companyName: any;
  deliveryGuideForm: FormGroup;
 
  wareHouseData: any;
  countrySearch: any;
  countryList: any;
  submitted = false;
 
  selectedItem: string = '';
  navbarOpen = false;
  userInfo: any;
  name: String;
  isLoggedInUserAdmin: boolean;
  expandConfigurationFlag: boolean;
 
  displayTable: boolean = true;
  dataSource = new MatTableDataSource();
 
 
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
 
  displayedColumnsExchangeRate: string[] = ['PRODUCT_NAME', 'DEPOT_NAME', 'FROM_DATE', 'TO_DATE', 'edit'];
  deliveryGuideData: any;
  deliveryGuideDataCopy: any;
 
  // data: TableData[] = [{ id: 1, PRODUCT_NAME: 'Nynas/50/70', DEPOT_NAME: 'Hamina', FROM_DATE: 'hbh', TO_DATE: 'vghvhj'}];
  dataSource1 = new BehaviorSubject<AbstractControl[]>([]);
  // displayColumns = ['from', 'to'];
  rows: FormArray = this.formBuilder.array([]);
  form: FormGroup = this.formBuilder.group({ 'delivery_guide': this.rows });
  isDeliveryGuideFormEdit: boolean = undefined;
  date: any;
  countryCode: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;
 
  constructor(private formBuilder: FormBuilder, private sharedService: AppService,
    private datePipe: DatePipe, private deliveryGuideService: DeliveryGuideAdminService,
    private addDeliveryGuideService: DeliveryGuideAdminService,
    private commonService: CommonService, private router: Router,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private media: MediaMatcher,
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService,
		private globalSearchService: GlobalSearchService) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.name = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
    this.countryCode = this.userInfo.CountryCode;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cd.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
 
  ngOnInit() {
 
    this.date = this.sharedService.getCurrentDateTime();

    this.date.toLocaleDateString("en-GB");
    this.date = this.datePipe.transform(this.sharedService.getCurrentDateTime(), 'dd-MM-yyyy');
    
    this.countryList = { fi: 'Finland' };
    this.countrySearch = 'fi';
 
    // this.getDummydata();   
 
    this.deliveryGuideData = [];
    this.deliveryGuideDataCopy = this.deliveryGuideData;
    this.getDeliveryGuideAdmin();
 
    this.deliveryGuideForm = this.formBuilder.group({
      FROM_DATE: ['', Validators.required],
      TO_DATE: ['', Validators.required],
      PRODUCT_NAME: [''],
      DEPOT_NAME: [''],
      PRODUCT_ID: [''],
      DEPOT_ID: [''],
      countryCode: [this.userInfo.CountryCode]
 
    });
 
    this.translate.get(['HOME.itemsPerPageLabel'])
    .subscribe(data => {
      this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
    });

    this.translate.get(['HOME.selectedLanguageForDatePicker'])
    .subscribe(data => {
      this.dateAdapter.setLocale(data['HOME.selectedLanguageForDatePicker']);
    });
        
    this.subscribeToGlobalSearchServiceSearchTerm();
 
  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.globalSearchText = '';
  }
 
  save() {
    this.submitted = true;
    if (!this.deliveryGuideForm.valid) {
      this.openSaveUserDialog('There are items that require your attention ', 'Please complete all fields marked with *', false, 'add-user-message');
      return false;
    }
 
    // this.presetFormValues();
    // this.priceIndexForm.controls['priceIndexType'].setValue(this.currentToggleMenu.toUpperCase());
    // this.priceIndexForm.controls['Month'].setValue(this.priceIndexForm.value['Month'].substring(0, 3).toUpperCase());
 
    this.deliveryGuideForm.controls['FROM_DATE'].setValue(this.datePipe.transform(this.deliveryGuideForm.value.FROM_DATE, 'yyyy-MM-dd'));
    this.deliveryGuideForm.controls['TO_DATE'].setValue(this.datePipe.transform(this.deliveryGuideForm.value.TO_DATE, 'yyyy-MM-dd'));
    this.deliveryGuideForm.controls['countryCode'].setValue(this.countryCode);
 
    // Check if it is update or add new index
    if (this.isDeliveryGuideFormEdit) {
      this.addDeliveryGuideService.updateDeliveryGuide(this.deliveryGuideForm.value)
        .subscribe(
          (response: any) => {
            this.saveStatusDialog(response);
          });
    } else {
      // this.addPriceIndexService.addPriceIndex(this.priceIndexForm.value, this.currentToggleMenu)
      //   .subscribe(
      //     (response: any) => {
      //       this.saveStatusDialog(response);
      //     });
    }
 
  }
 
  get fields() {
    return this.deliveryGuideForm.controls;
  }
 
  reset() {
    this.submitted = false;
    this.isDeliveryGuideFormEdit = undefined;
 
    this.deliveryGuideForm.reset();
 
    // this.getCurrentMonthAndYear();
 
    this.deliveryGuideForm.controls['FROM_DATE'].setValue('');
    this.deliveryGuideForm.controls['TO_DATE'].setValue('');
 
  }
 
 
  editDeliveryGuide(deliveryGuideIndex: any) {
    this.isDeliveryGuideFormEdit = true;
    //  var date = deliveryGuideIndex.TO_DATE,
    //       date1 = deliveryGuideIndex.FROM_DATE,
    //       TO_DATE = this.datePipe.transform(date, 'yyyy-MM-dd');
    //  const FROM_DATE = this.datePipe.transform(date1, 'yyyy-MM-dd');
 
    this.deliveryGuideForm.patchValue(deliveryGuideIndex);
     this.deliveryGuideForm.controls['FROM_DATE'].setValue(this.getCorrectDateFormate(deliveryGuideIndex.FROM_DATE));
     this.deliveryGuideForm.controls['TO_DATE'].setValue(this.getCorrectDateFormate(deliveryGuideIndex.TO_DATE));
    //  this.deliveryGuideForm.controls['PrimaryContactCountryCode'].setValue(['Country_Code']);
  }  
 
  getCorrectDateFormate(dateWtihMonth: any) {
    // get year
    var yy = dateWtihMonth.substr(7, 2);
    var fullYear = (yy < 90) ? '20' + yy : '19' + yy;
    var newdate = dateWtihMonth.substr(0, 7) + fullYear;

    // get month
    var actualDate: any = newdate.split("-");
    var months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    for (var j = 0; j < months.length; j++) {
      if (actualDate[1] == months[j]) {
        let monthNumber = (months.indexOf(months[j]) + 1);
        if (monthNumber < 10) {
          actualDate[1] = '0' + monthNumber;
        } else {
          actualDate[1] = monthNumber;
        }
      }
    }
    /* if (actualDate[0] < 10) {
      actualDate[0] = '0' + actualDate[1];
    } */
    var formattedDate = actualDate[2] + '-' + actualDate[1] + '-' + actualDate[0];
    return formattedDate;
  }
 
  getDeliveryGuideAdmin() {
    let isAvailable: string = '';
    this.deliveryGuideService.getDeliveryGuideAdmin(this.countryCode, isAvailable)
      .subscribe(
        res => {
          if (res["ResponseCode"] == 200) {          
            /* if (res['GetDeliveryGuideResponses'] && res['GetDeliveryGuideResponses'].length) {
              var deliveryGuideList = [];
              for (var deliveryGuide of res['GetDeliveryGuideResponses']) {
                deliveryGuide['FROM_DATE'] = this.datePipe.transform(deliveryGuide['FROM_DATE'], 'dd-MON-yy');
                deliveryGuideList.push(deliveryGuide);
              }
            } */            
            this.deliveryGuideData = res['GetDeliveryGuideResponses'];
            this.deliveryGuideDataCopy = this.deliveryGuideData;
 
            //this.excelData=this.deliveryGuideDataCopy;
            this.dataSource = new MatTableDataSource(this.deliveryGuideDataCopy);
 
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.displayTable = true;
          } else {
            // console.log("response is: ", res['RequestStatus']);
            this.displayTable = false;
          }
 
        }
      );
  }
 
 
  addRow(d?: TableData, noUpdate?: boolean) {
 
    this.dataSource.data.push({
      PRODUCT_ID: "25445",
      PRODUCT_TYPE: "Penetration Grade Bitumen",
      PRODUCT_SUB_TYPE: "Regular",
      PRODUCT_NAME: "Nynas 500/650",
      FROM_DATE: "",
      TO_DATE: "",
      DEPOT_ID: 23520,
      DEPOT_NAME: "Rauma",
      COUNTRY_CODE: "FIN",
      COUNTRY_NAME: "Finland"
    });
 
    /* const row = this._fb.group({
      PRODUCT_NAME: [' '],
      DEPOT_NAME: [' '],
      FROM_DATE: [' '],
      TO_DATE: [' ']
    });
    this.rows.push(row);
    if (!noUpdate) { this.updateView(); } */
  }
  updateView() {
    this.dataSource1.next(this.rows.controls);
  }
 
  updateSelectedItem(selectedItem: string) {
    this.commonService.updateSelectedItem(selectedItem)
  }
 
  expandConfiguration(expandConfiguration: boolean) {
    this.expandConfigurationFlag = expandConfiguration;
  }
  getCountry(data) {
    return this.countryList
  }
 
  saveStatusDialog(response: any) {
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        this.openSaveUserDialog('Delivery guide Details Saved Successfully', null, true,'delivery-guide-admin-success');        
        this.getDeliveryGuideAdmin();
      } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.openSaveUserDialog('Failed to Save Delivery guide Details', (response['Description'] ? response['Description'] : null), false,'price-index-admin-fail');
      } else {
        this.openSaveUserDialog('Failed to Save Delivery guide Details', null, false,'delivery-guide-admin-fail');
      }
    } else {
      this.openSaveUserDialog('Failed to Save Delivery guide Details', null, false,'delivery-guide-admin-fail');
    }
  }
 
  openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean,headerNumber:string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber:headerNumber
      },
			height: '37%',
			width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  getDateObject(date: string): any {
    let dateObject = {};
    if (date) {
      let dateArray = date.split('-');
      if (dateArray && dateArray.length && dateArray[0] && dateArray[1] && dateArray[2]) {
        dateObject['date'] = dateArray[0] ? dateArray[0] : '';
        dateObject['month'] = dateArray[1] ? dateArray[1].toUpperCase() : '';
        dateObject['year'] = dateArray[2] ? dateArray[2] : '';
      }

    }
    return dateObject;
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.cd.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.cd.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      // this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }
 
}