<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
 
 
        </div>
    </div>
 
    <div class='col-lg-12'>
 
 
        <form [formGroup]="deliveryGuideForm">
            <div class='row no-gutters' style="padding-top: 1%;">
 
                <div class="col-2 product_MV">
                    <div class="form-group">
                        <div class="">
                            <label for="PRODUCT_NAME">
                                <span class="input-text-header monthlyLabel"> {{'HOME.Pname'| translate}}
                                </span>
                            </label>
                        </div>
                        <div class="">
                            <input type="text" class="form-control input-text forecastSelect"
                                formControlName="PRODUCT_NAME" disabled>
                        </div>
                    </div>
 
                </div>
                <div class="col-2 depot_MV">
                    <div class="form-group">
                        <div class="col">
                            <label for="DEPOT_NAME">
                                <span class="input-text-header monthlyLabel"> {{'HOME.Depot'| translate}}
                                </span>
                            </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control input-text forecastSelect"
                                formControlName="DEPOT_NAME" disabled>
                        </div>
                    </div>
                </div>
 
 
                <div class="col-3 av_from_MV pt-1 margin-left-1">
                    <div class="form-group">
                        <div class="col">
                            <label for="FROM_DATE">
                                <span class="input-text-header monthlyLabel"> {{'HOME.Availablefrom'| translate}}
                                </span><span
                                    class="mandatory-field pl-1">*</span>
                            </label>
                        </div>
                        <div class="col">
                            <!-- <input type="text" class="form-control input-text forecastSelect" placeholder=""> -->
                            <mat-form-field [ngClass]="{'disabled-date-picker': isDeliveryGuideFormEdit}">
                                <input matInput [matDatepicker]="picker" placeholder="" formControlName="FROM_DATE">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="submitted && fields.FROM_DATE.errors" class="date-isinvalid">
                                <div *ngIf="fields.FROM_DATE.errors.required && !fields.FROM_DATE.errors.matDatepickerParse">
                                    {{'HOME.Dateisrequired'| translate}}
                                </div>
                                <div *ngIf="fields.FROM_DATE.errors.required && fields.FROM_DATE.errors.matDatepickerParse">
                                    {{'HOME.Dateisinvalid'| translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 av_to_MV pt-1 margin-left-4">
                    <div class="form-group">
                        <div class="col">
                            <label for="TO_DATE">
                                <span class="input-text-header monthlyLabel"> {{'HOME.Availableto'| translate}}
                                </span>
                                <span class="mandatory-field pl-1">*</span>
                            </label>
                        </div>
                        <div class="col">
                            <!-- <input type="text" class="form-control input-text forecastSelect" placeholder=""> -->
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker1" placeholder="" formControlName="TO_DATE">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="submitted && fields.TO_DATE.errors" class="date-isinvalid">
                                <div *ngIf="fields.TO_DATE.errors.required && !fields.TO_DATE.errors.matDatepickerParse">
                                    {{'HOME.Dateisrequired'| translate}}
                                </div>
                                <div *ngIf="fields.TO_DATE.errors.required && fields.TO_DATE.errors.matDatepickerParse">
                                    {{'HOME.Dateisinvalid'| translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
 
                <div class="col-2">
                    <div>
 
                        <span></span>
                    </div>
 
 
                    <div class="buttonGroup " style="margin-top: 11%; display: block;">
 
                        <button class="saveButton" type="submit" (click)="save()"> {{'HOME.Save'| translate}}
                        </button>
                        <button class="resetButton " id="submit" name="submit" (click)="reset()"> {{'HOME.Reset'| translate}}
                        </button>
                    </div>
                </div>
            </div>
        </form>
 
    </div>
    <br>
            
    <div class="global-search-text-box">
        <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
    </div>
 
    <div class=" ">
        <div class="col-lg-12 no-gutters">
 
 
            <mat-table #table [dataSource]="dataSource" matSort class=" horizontal_scrollbar">



                <ng-container matColumnDef="PRODUCT_NAME">
                    <mat-header-cell class="header-font " *matHeaderCellDef mat-sort-header>  {{'HOME.Pname'| translate}}

                    </mat-header-cell>
                    <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.PRODUCT_NAME}}
                    </mat-cell>
                </ng-container>
 
                <ng-container matColumnDef="DEPOT_NAME">
                    <mat-header-cell class="header-font  " *matHeaderCellDef mat-sort-header>  {{'HOME.Depot'| translate}}

                    </mat-header-cell>
                    <mat-cell class=" mat-column-medium " *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.DEPOT_NAME}}
                    </mat-cell>
                </ng-container>
 
                <ng-container matColumnDef="FROM_DATE">
                    <mat-header-cell class="header-font  " *matHeaderCellDef mat-sort-header>  {{'HOME.Availablefrom'| translate}}

                    </mat-header-cell>
                    <!-- <mat-cell class=" mat-column-medium " *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.FROM_DATE }}
                    </mat-cell> -->

                    <mat-cell class=" mat-column-medium " *matCellDef="let fetchedDataForItemArray">
                        <span *ngIf="getDateObject(fetchedDataForItemArray.FROM_DATE)['date']">
                            {{ getDateObject(fetchedDataForItemArray.FROM_DATE)['date'] }}-{{'HOME.' + this.getDateObject(fetchedDataForItemArray.FROM_DATE)['month'] | translate}}-{{ getDateObject(fetchedDataForItemArray.FROM_DATE)['year'] }}
                        </span>
                    </mat-cell>

                </ng-container>
 
                <ng-container matColumnDef="TO_DATE">
                    <mat-header-cell class="header-font " *matHeaderCellDef mat-sort-header>  {{'HOME.Availableto'| translate}}

                    </mat-header-cell>
                    <!-- <mat-cell class=" mat-column-medium " *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.TO_DATE }}
                    </mat-cell> -->

                    <mat-cell class=" mat-column-medium " *matCellDef="let fetchedDataForItemArray">
                        <span *ngIf="getDateObject(fetchedDataForItemArray.TO_DATE)['date']">
                            {{ getDateObject(fetchedDataForItemArray.TO_DATE)['date'] }}-{{'HOME.' + this.getDateObject(fetchedDataForItemArray.TO_DATE)['month'] | translate}}-{{ getDateObject(fetchedDataForItemArray.TO_DATE)['year'] }}
                        </span>
                    </mat-cell>

                </ng-container>
 
 
                <ng-container matColumnDef="edit">
                    <mat-header-cell class="header-font " *matHeaderCellDef> 
                    </mat-header-cell>
                    <th mat-header-cell *matHeaderCellDef
                    [ngClass]="'mat-table-column-header mat-column-actions'">
                </th>
                    <mat-cell class="mat-column-medium " *matCellDef="let element">
                        <span class=' price-index-edit-icon' (click)='editDeliveryGuide(element)'
                            title=" {{'HOME.Editdeliveryguide'| translate}} ">
                            <i class="far fa-edit" aria-hidden="true"></i>
                        </span>
                    </mat-cell>
                    
                </ng-container>
 
                <!-- <ng-container matColumnDef="delete">
                    <mat-header-cell class="header-font " *matHeaderCellDef>Delete
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class='active-icon-color'>
                            <i class="fas fa-trash" aria-hidden="true"></i>
                        </span>
                    </mat-cell>
                </ng-container> -->
 
                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsExchangeRate; sticky:true">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsExchangeRate;"></mat-row>
 
            </mat-table>
            <mat-paginator  [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"  showFirstLastButtons></mat-paginator>


        </div>
 
 
    </div>