<mat-horizontal-stepper [linear]="isLinear" #stepper labelPosition="bottom"
    (selectionChange)="stepperSelectionChange($event)">

    <ng-template matStepperIcon="edit">
        <i class="fas fa-check"></i>
    </ng-template>
    <ng-template matStepperIcon="Information">
        <i class="fas fa-info"></i>
    </ng-template>
    <ng-template matStepperIcon="Address">
        <i class="fas fa-map-marker-alt"></i>
    </ng-template>
    <ng-template matStepperIcon="Notification">
        <i class="far fa-bell"></i>
    </ng-template>
    <!-- <ng-template matStepperIcon="Product">
        <i class="fab fa-product-hunt"></i>
    </ng-template> -->
    <ng-template matStepperIcon="Summary">
        <i class="fas fa-check"></i>
    </ng-template>

    <!-- information code -->
    <mat-step [stepControl]="formInformationGroup" state="Information">
        <ng-template matStepLabel>
            <p class="stepper-label">{{'HOME.Information'| translate}}</p>
        </ng-template>
        <div>
            <form [formGroup]="formInformationGroup">
                <div class="example-form">
                    <div class="container-fluid container-information-address-section">
                        <div class="row no-gutters pt-1">
                            <div class="col-3 width_div_mv  ">
                                <label for="CustomerId" class="field-label field_label_mv">
                                    <span class="input-text-header"> {{'HOME.Customername'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv" *ngIf="isLoggedInUserPortalAdmin">
                                <select class="form-control input_align_mv input-text dropdown-list" id="CustomerId"
                                    formControlName="CustomerId" (change)="changedSelectedCustomer()"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Information'] && formInformationGroup.controls['CustomerId'].hasError('required')) }"
                                    [attr.disabled]="(isEditDeliverySite) ? true : undefined">
                                    <option value=""> {{'HOME.SELECT'| translate}}
                                    </option>
                                    <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                                        {{customer.CustomerName}}
                                    </option>
                                </select>
                                <mat-error
                                    *ngIf="(submittedFlagObject['Information'] && formInformationGroup.controls['CustomerId'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                            </div>
                            <div class="col-3 width_div_mv input_box" *ngIf="!isLoggedInUserPortalAdmin">
                                <label class="label-text form-control disabled_field_color">{{
                                    getCustomerNameById(formInformationGroup.controls['CustomerId'].value) }}</label>
                            </div>
                            <div class="col-3 width_div_mv pl-3">
                                <label for="DeliverySite" class="field-label">
                                    <span class="input-text-header"> {{'HOME.DeliverySiteName'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.DeliverySiteNameTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <input type="text" class="form-control input-text" formControlName="DeliverySite" id="txtDeliverySiteName"
                                    placeholder="{{'HOME.DeliverySiteName'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Information'] && formInformationGroup.controls['DeliverySite'].hasError('required')) }" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Information'] && formInformationGroup.controls['DeliverySite'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error *ngIf="(formInformationGroup.controls['DeliverySite'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.delivery-site-name-maximum-length-error'| translate}}
                                </mat-error>
                            </div>
                        </div>
                        <div class="row no-gutters pt-4">
                            <div class="col-3 width_div_mv">
                                <label for="PlantType" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Planttype'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv" *ngIf="selectedLanguageCode !== 'EN'">
                                <select class="form-control input_align_mv input-text dropdown-list" id="plantType" formControlName="PlantType"
                                    [attr.disabled]="(isEditDeliverySite) ? true : undefined">
                                    <option *ngFor="let plantType of plantTypes" [ngValue]="plantType.PlantTypeCode">
                                        {{'HOME.PlanttypeDescriptionFor_' + plantType.PlantTypeCode | translate}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-3 width_div_mv" *ngIf="selectedLanguageCode === 'EN'">
                                <select class="form-control input_align_mv input-text dropdown-list" id="plantType" formControlName="PlantType"
                                    [attr.disabled]="(isEditDeliverySite) ? true : undefined">
                                    <option *ngFor="let plantType of plantTypes" [ngValue]="plantType.PlantTypeCode">
                                        {{ plantType.PlantTypeDesc }}
                                    </option>
                                </select>
                            </div>
                            <div class="pl-3 col-3 width_div_mv">
                                <label for="Incoterms" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Incoterms'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{ incotermsInfoIconTooltip }}"  matTooltipClass="custom-tooltip-class"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv" *ngIf="!(isEditDeliverySite && selectedDeliverySite['FreightCode'])">
                                <select class="form-control input-text dropdown-list" id="incoterms" formControlName="Incoterms"
                                    [attr.disabled]="((incotermsList && incotermsList.length && incotermsList.length === 1) ?  true : undefined)"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Information'] && formInformationGroup.controls['Incoterms'].hasError('required')) }"
                                    title="{{ getTooltipForSelectedIncoterms(formInformationGroup.controls['Incoterms']) }}">
                                    <option value="">{{'HOME.SELECT'| translate}}</option>
                                    <option *ngFor="let incoterms of incotermsList" [ngValue]="incoterms.FreightHandlingId"
                                        title="{{incoterms.tooltip}}">
                                        {{incoterms.FreightHandlingDesc2}}
                                    </option>
                                </select>
                                <mat-error
                                    *ngIf="(submittedFlagObject['Information'] && formInformationGroup.controls['Incoterms'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                            </div>
                            <div class="col-3 width_div_mv input_box" *ngIf="(isEditDeliverySite && selectedDeliverySite['FreightCode'])">
                                <label class="label-text form-control disabled_field_color"
                                    title="{{ getTooltipForSelectedIncoterms(formSummaryGroup.controls['Incoterms']) }}">{{
                                    getIncotermsDescriptionById(formSummaryGroup.value) }}</label>
                            </div>
                        </div>
                        <div class="row no-gutters pt-4">
                            <div class="col-3 width_div_mv">
                                <label for="BillingReference" class="field-label">
                                    <span class="input-text-header"> {{'HOME.BillingReference'| translate}}
                                    </span>
                                    <span *ngIf="countryCode === 'SWE' || countryCode === 'NOR'" class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.BillingReferenceTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <input type="text" class="form-control input_align_mv input-text" formControlName="BillingReference"
                                    id="txtBillingReference" placeholder="{{'HOME.BillingReference'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Information'] && ((formInformationGroup.controls['BillingReference'].hasError('maxlength')) || (formInformationGroup.controls['BillingReference'].hasError('required')))) }" />
                                <mat-error *ngIf="(formInformationGroup.controls['BillingReference'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                                <mat-error
                                    *ngIf="(submittedFlagObject['Information'] && formInformationGroup.controls['BillingReference'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                            </div>
                            <div class="col-3 width_div_mv pl-3 pr-3 ">
                                <label for="ExpectedFirstdeliverydate" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Expectedfirstdeliverydate'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-2 width_div_mv datepicker_width" *ngIf="!isEditDeliverySite">
                                <mat-form-field class="datepicker">
                                    <input matInput readonly [matDatepicker]="picker" placeholder=""
                                        formControlName="ExpectedFirstDeliveryDate" [min]="dateOfToday">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                                </mat-form-field>
                                <!-- <mat-error
                                    *ngIf="(submittedFlagObject['Information'] && formInformationGroup.hasError('expectedFirstDeliveryDateIsLessThanToday'))"
                                    class="error-message date-error-message">
                                    {{'HOME.Onlycurrentorfuturedatesareallowed'| translate}}
                                </mat-error> -->
                                <mat-error *ngIf="showInvalidExpectedDeliveryDateMessage" class="warning-message">
                                    {{'HOME.Onlycurrentorfuturedatesareallowed'| translate}}
                                </mat-error>
                            </div>
                            <div class="col-3 width_div_mv input_box" *ngIf="isEditDeliverySite">
                                <label class="label-text form-control disabled_field_color">{{
                                    getDateInCorrectFormat(formInformationGroup.controls['ExpectedFirstDeliveryDate'].value)
                                    }}</label>
                            </div>
                        </div>
                        <div class="row no-gutters pt-3 ">
                            <div class="col-3 width_div_mv" *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <label for="LoadingDepot" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Loadingdepot'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv" *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <input type="text" class="form-control input-text" formControlName="LoadingDepot"
                                    id="txtLoadingDepot" placeholder="{{'HOME.Loadingdepot'| translate}}" />
                            </div>
                        </div>
                    </div>

                </div>
                <br />
                <button mat-raised-button color="primary" matStepperNext class="navigation-button"
                    (click)="navigateToNextPage('Information')">{{'HOME.Next'| translate}}</button>
            </form>
        </div>
    </mat-step>
    <!-- address code -->
    <mat-step [stepControl]="formAddressGroup" state="Address">
        <ng-template matStepLabel>
            <p class="stepper-label">{{'HOME.Address'| translate}}</p>
        </ng-template>
        <div>
            <form [formGroup]="formAddressGroup">
                <div class="example-form">
                    <div class="container-fluid container-information-address-section">
                        <div class="row no-gutters pt-1">
                            <div class="col-3 width_div_mv">
                                <label for="AddressLine1" class="field-label" *ngIf="selectedLanguageCode === 'FI'">
                                    <span class="input-text-header"> {{'HOME.AddressLine1'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                                <label for="AddressLine1" class="field-label" *ngIf="selectedLanguageCode !== 'FI'">
                                    <div class="input-text-header"> {{'HOME.AddressLine1'| translate}} /
                                    </div>
                                    <span class="input-text-header"> {{'HOME.GPSCoordinates'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.GPScoordinatesTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv" *ngIf="selectedLanguageCode === 'FI'">
                                <input type="text" class="form-control input-text" formControlName="AddressLine1" id="txtAddressLine1"
                                    placeholder="{{'HOME.AddressLine1'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Address'] && (formAddressGroup.controls['AddressLine1'].hasError('required') || formAddressGroup.controls['AddressLine1'].hasError('maxlength'))) }" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Address'] && formAddressGroup.controls['AddressLine1'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error *ngIf="(formAddressGroup.controls['AddressLine1'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv" *ngIf="selectedLanguageCode !== 'FI'">
                                <input type="text" class="form-control input-text" formControlName="AddressLine1" id="txtAddressLine1"
                                    placeholder="{{'HOME.AddressLine1'| translate}} / {{'HOME.GPSCoordinates'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Address'] && (formAddressGroup.controls['AddressLine1'].hasError('required') || formAddressGroup.controls['AddressLine1'].hasError('maxlength'))) }" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Address'] && formAddressGroup.controls['AddressLine1'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error *ngIf="(formAddressGroup.controls['AddressLine1'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                            </div>

                            <!-- Combined Address line 1 and GPS coordinates fields -->
                            <!-- <div class="col-3 width_div_mv">
                                <label for="GPSCoordinates" class="field-label">
                                    <span class="input-text-header"> {{'HOME.GPSCoordinates'| translate}}
                                    </span>
                                </label>
                                <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.GPScoordinatesTooltip'| translate}}"></i>
                            </div>
                            <div class="col-3 width_div_mv">
                                <input type="text" class="form-control input-text" formControlName="GPSCoordinates" id="txtGPSCoordinates"
                                    placeholder="{{'HOME.GPSCoordinates'| translate}}" />
                            
                                Comment below code
                                <mat-error
                                    *ngIf="(submittedFlagObject['Address'] && formAddressGroup.hasError('addressLine1AndGPSCoordinatesAreEmpty'))"
                                    class="error-message">
                                    {{'HOME.address-line-1-gps-coordinates-empty'| translate}}
                                </mat-error>
                            
                            </div> -->

                            <div class="col-3 width_div_mv">
                                <label for="AddressLine2" class="field-label">
                                    <span class="input-text-header">{{'HOME.AddressLine2'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <input type="text" class="form-control input-text" formControlName="AddressLine2" id="txtAddressLine2"
                                    placeholder="{{'HOME.AddressLine2'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Address'] && (formAddressGroup.controls['AddressLine2'].hasError('maxlength'))) }" />
                                <mat-error *ngIf="(formAddressGroup.controls['AddressLine2'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                            </div>

                        </div>
                        <div class="row no-gutters" [ngClass]="{ 'pt-3': (selectedLanguageCode === 'FI') }">
                            <div class="col-3 width_div_mv">
                                <label for="City" class="field-label">
                                    <span class="input-text-header"> {{'HOME.City'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv ">
                                <input type="text" class="form-control input-text" formControlName="City" id="txtCity"
                                    placeholder="{{'HOME.City'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Address'] && (formAddressGroup.controls['City'].hasError('required') || formAddressGroup.controls['City'].hasError('maxlength'))) }" />
                                <mat-error *ngIf="(submittedFlagObject['Address'] && formAddressGroup.controls['City'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error *ngIf="(formAddressGroup.controls['City'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 25 } }}
                                </mat-error>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label for="DeliveryPostalCode" class="field-label">
                                    <span class="input-text-header"> {{'HOME.DeliveryPostalCode'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <input type="text" class="form-control input-text" formControlName="DeliveryPostalCode"
                                    id="txtDeliveryPostalCode" placeholder="{{'HOME.DeliveryPostalCode'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Address'] && (formAddressGroup.controls['DeliveryPostalCode'].hasError('required') || formAddressGroup.controls['DeliveryPostalCode'].hasError('maxlength'))), 'is-warning': (showInvalidPostalCodeMessage) }"
                                    (change)="validatePostalCode($event.target.value)" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Address'] && formAddressGroup.controls['DeliveryPostalCode'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error *ngIf="showInvalidPostalCodeMessage">
                                    <div class="postal-code-warning-message">
                                        {{'HOME.Deliverypostalcodealreadypresent'| translate}}
                                    </div>
                                </mat-error>
                                <mat-error *ngIf="(formAddressGroup.controls['DeliveryPostalCode'].hasError('maxlength'))"
                                    class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 12 } }}
                                </mat-error>
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-3 width_div_mv">
                                <label for="Country" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Country'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <!-- <select class="form-control input-text dropdown-list" id="CountryId"
                                    formControlName="CountryId"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Address'] && formAddressGroup.controls['CountryId'].hasError('required')) }"
                                    [attr.disabled]="true">
                                    <option value=""> {{'HOME.SELECT'| translate}}
                                    </option>
                                    <option *ngFor="let country of countryList" [value]="country.id">
                                        {{'HOME.' + country.name | translate}}
                                    </option>
                                </select>
                                <mat-error
                                    *ngIf="(submittedFlagObject['Address'] && formAddressGroup.controls['CountryId'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error> -->
                                <!-- <input type="text" class="form-control input-text" formControlName="CountryName"
                                    id="txtCountryName" [attr.disabled]="true" /> -->
                                <label class="label-text form-control disabled_field_color">{{
                                    getCountryNameById(formAddressGroup.controls['Country'].value) }}</label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label for="PrimaryContactName" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Primarycontactpersonname'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.PrimarycontactpersonnameTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <input type="text" class="form-control input-text" formControlName="PrimaryContactName" id="txtPrimaryContactPerson"
                                    placeholder="{{'HOME.Primarycontactpersonname'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Address'] && (formAddressGroup.controls['PrimaryContactName'].hasError('maxlength'))) }" />
                                <mat-error *ngIf="(formAddressGroup.controls['PrimaryContactName'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-3 width_div_mv">
                                <label for="PrimaryContactEmailAddress" class="field-label">
                                    <span class="input-text-header"> {{'HOME.PrimaryEmailAddress'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.PrimaryEmailAddressTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <input type="text" class="form-control input-text"
                                    formControlName="PrimaryContactEmailAddress" id="txtPrimaryEmailAddress"
                                    placeholder="{{'HOME.PrimaryEmailAddress'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Address'] && (formAddressGroup.controls['PrimaryContactEmailAddress'].hasError('required') || formAddressGroup.controls['PrimaryContactEmailAddress'].hasError('maxlength'))) }" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Address'] && formAddressGroup.controls['PrimaryContactEmailAddress'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error
                                    *ngIf="(submittedFlagObject['Address'] && formAddressGroup.controls['PrimaryContactEmailAddress'].hasError('email'))"
                                    class="error-message">
                                    {{'HOME.Emailmustbeavalidemailaddress'| translate}}
                                </mat-error>
                                <mat-error
                                    *ngIf="(formAddressGroup.controls['PrimaryContactEmailAddress'].hasError('maxlength'))"
                                    class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 256 } }}
                                </mat-error>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label for="PrimaryContactCountryCode" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Contactphonenumber'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.ContactnumberTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-1 contact_country_code_input_width_mv country-code-width">
                                <select class="form-control input-text dropdown-list" id="3" formControlName="PrimaryContactCountryCode">
                                    <option *ngFor="let country of countryList" [value]="country.id">
                                        {{ country.id }} (+ {{ country.code }} )
                                    </option>
                                </select>
                            </div>
                            <div class="col-1 contact_phone_number_input_width_mv primary-contact-width pl-1">
                                <input type="text" class="form-control input-text phone-number-textbox" formControlName="ContactNumber"
                                    id="primary_contact" placeholder="{{'HOME.PhoneNumber'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Address'] && (formAddressGroup.controls['ContactNumber'].hasError('maxlength'))) }" />
                                <mat-error *ngIf="(formAddressGroup.controls['ContactNumber'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 20 } }}
                                </mat-error>
                                <mat-error *ngIf="(formAddressGroup.controls['ContactNumber'].hasError('pattern'))" class="error-message">
                                    {{'HOME.Pleaseenternumbersonly'| translate}}
                                </mat-error>
                            </div>
                        </div>

                        <!-- <div class="row no-gutters pt-3">
                            
                        </div> -->

                    </div>
                </div>
                <br />
                <button mat-raised-button color="primary" matStepperNext class="ml-2 navigation-button"
                    (click)="navigateToNextPage('Address')">{{'HOME.Next'| translate}}</button>
                <button mat-raised-button color="primary" matStepperPrevious class="navigation-button"
                    (click)="navigateToPreviousPage('Address')">{{'HOME.Back'|
                    translate}}</button>
            </form>
        </div>
    </mat-step>
    <!-- notification code -->
    <mat-step [stepControl]="formNotificationGroup" state="Notification">
        <ng-template matStepLabel>
            <p class="stepper-label">{{'HOME.Notification'| translate}}</p>
        </ng-template>
        <div>
            <form [formGroup]="formNotificationGroup">
                <div class="example-form">
                    <div class="container-fluid container-notification-section">
                        <div class="row no-gutters pt-4">
                            <div class="col-2 width_Send_Forecast_Reminder_div_mv width-22" *ngIf="countryCode != 'GBR' && isLoggedInUserPortalAdmin">
                                <label for="SendForecastReminder" class="field-label">
                                    <span class="input-text-header"> {{'HOME.SendForecastReminder'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.SendForecastReminderTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-0 width_Send_Forecast_Reminder_checkbox_div_mv input_align_mv send-forecast-reminder-checkbox"
                                *ngIf="countryCode != 'GBR' && isLoggedInUserPortalAdmin">
                                <input class="form-control form-checkbox" type="checkbox" formControlName="SendForecastReminder">
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-2 width_div_mv width-22" *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <label for="NotifyHaulier" class="field-label">
                                    <span class="input-text-header"> {{'HOME.NotifyHaulier'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.NotifyHaulierTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-1 width_div_mv notify-haulier-textbox"
                                *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <input class="form-control form-checkbox" type="checkbox"
                                    formControlName="NotifyHaulier">
                            </div>

                            <div *ngIf="countryCode != 'GBR'" class="col-2 width_div_mv"
                                [ngClass]="{ 'haulier-name-label-notification': (countryCode != 'FIN' && countryCode != 'GBR'), 'haulier-name-label-finland': !(countryCode != 'FIN' && countryCode != 'GBR'), 'width-22': !(countryCode != 'FIN' && countryCode != 'GBR') }">
                                <label for="HaulierName">
                                    <span class="input-text-header"> {{'HOME.Hauliername'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv" *ngIf="countryCode != 'GBR'"
                                [ngClass]="{ 'pl-1': (countryCode != 'FIN' && countryCode != 'GBR') }">
                                <input type="text" class="form-control input-text" formControlName="HaulierName"
                                    id="txtHauliername" placeholder="{{'HOME.Hauliername'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Notification'] && (formNotificationGroup.controls['HaulierName'].hasError('maxlength'))) }" />
                                <mat-error *ngIf="(formNotificationGroup.controls['HaulierName'].hasError('maxlength'))"
                                    class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                            </div>

                            <div class="col-2 width_div_mv haulier-email-id-div-notification" *ngIf="countryCode != 'GBR'"
                                [ngClass]="{  'width-22': !(countryCode != 'FIN' && countryCode != 'GBR') }">
                                <label for="HaulierEmailId" class="field-label">
                                    <span class="input-text-header"> {{'HOME.HaulierEmailId'| translate}}
                                    </span>
                                    <span *ngIf="(formNotificationGroup.controls['NotifyHaulier'].value || countryCode === 'FIN')"
                                        class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-2 width_div_mv" *ngIf="countryCode != 'GBR'">
                                <input type="text" class="form-control input-text haulier-email-id-textbox"
                                    formControlName="HaulierEmailId" id="txtHaulierEmailId"
                                    placeholder="{{'HOME.HaulierEmailId'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Notification'] && (formNotificationGroup.controls['HaulierEmailId'].hasError('maxlength'))) }" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Notification'] && formNotificationGroup.hasError('haulierEmailIdIsEmpty'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error
                                    *ngIf="(submittedFlagObject['Notification'] && formNotificationGroup.controls['HaulierEmailId'].hasError('email'))"
                                    class="error-message">
                                    {{'HOME.Emailmustbeavalidemailaddress'| translate}}
                                </mat-error>
                                <mat-error
                                    *ngIf="(formNotificationGroup.controls['HaulierEmailId'].hasError('maxlength'))"
                                    class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 256 } }}
                                </mat-error>
                            </div>
                        </div>
                        <hr *ngIf="countryCode != 'GBR'" class="section-separator separator-width">
                        <div class="row no-gutters" [ngClass]="{ 'pt-4': (countryCode != 'GBR') }">
                            <div class="col-2 width_div_mv width-22">
                                <label for="Customertextinfo" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Additionaltextinfo'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <textarea class="form-control text-area-height-customer input-text" type="text"
                                    formControlName="CustomerTextInfo" id="customerTextInfo" maxlength="250"></textarea>
                                <span class="input-text-header">{{'HOME.characters-remaining'| translate}}</span>
                                <span class="input-text-header normal-text"> {{ 250 -
                                    (formNotificationGroup.controls['CustomerTextInfo'].value?.length)}}</span>
                                <mat-error *ngIf="(formNotificationGroup.controls['CustomerTextInfo'].value?.length === 250)"
                                    class="error-message">
                                    {{'HOME.Max250charactersallowed'| translate}}
                                </mat-error>
                            </div>
                            <div class="col-2 width_div_mv width-22" *ngIf="isLoggedInUserPortalAdmin"
                                [ngClass]="{ 'internal-text-info-uk': (countryCode === 'GBR') }">
                                <label for="Internaltextinfo" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Internaltextinfo'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.InternaltextinfoTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-2 width_div_mv internal-text-info-div" *ngIf="isLoggedInUserPortalAdmin">
                                <textarea class="form-control text-area-height-internal input-text" type="text"
                                    formControlName="InternalTextInfo" id="internalTextInfo" maxlength="2000"></textarea>
                                <span class="input-text-header">{{'HOME.characters-remaining'| translate}}</span>
                                <span class="input-text-header normal-text"> {{ 2000 -
                                    (formNotificationGroup.controls['InternalTextInfo'].value?.length)}}</span>
                                <mat-error *ngIf="(formNotificationGroup.controls['InternalTextInfo'].value?.length === 2000)"
                                    class="error-message">
                                    {{'HOME.Max2000charactersallowed'| translate}}
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <button mat-raised-button color="primary" matStepperNext class="ml-2 navigation-button"
                    (click)="navigateToNextPage('Notification')">{{'HOME.Next'| translate}}</button>
                <button mat-raised-button color="primary" matStepperPrevious class="navigation-button"
                    (click)="navigateToPreviousPage('Notification')">{{'HOME.Back'|
                    translate}}</button>
            </form>
        </div>
    </mat-step>
    <!-- <mat-step *ngIf="countryCode != 'FIN'" [stepControl]="" state="Product">
        <ng-template matStepLabel>
            <p (click)="updateSummaryModel()" class="stepper-label">Product</p>
        </ng-template>
        <div [hidden]='!displayTable' class="pt-3">
            <mat-table #table [dataSource]="dataSource" #weekSort="matSort" matSort>
                <ng-container matColumnDef="DepotName">
                    <mat-header-cell class="depot-name-column" *matHeaderCellDef mat-sort-header>
                        {{'HOME.Depot'|translate}}
                    </mat-header-cell>
                    <mat-cell class="depot-name-column" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.DepotName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ProductName">
                    <mat-header-cell class="product-name-column" *matHeaderCellDef mat-sort-header>
                        {{'HOME.Pname'|translate}}
                    </mat-header-cell>
                    <mat-cell class="product-name-column" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.ProductName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Status">
                    <mat-header-cell class="status-column" *matHeaderCellDef>
                        {{'HOME.Add/Remove'| translate}}
                    </mat-header-cell>
                    <mat-cell class="status-column" *matCellDef="let fetchedDataForItemArray">
                        <span>
                            <input class="checkbox-add-remove" type="checkbox"
                                [(ngModel)]="fetchedDataForItemArray.isStatusActive">
                        </span>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
        <div [hidden]="displayTable" class="no-records-message">
            {{'HOME.Norecordsfound'| translate}}
        </div>
        <br />
        <button mat-raised-button color="primary" matStepperNext class="ml-2 navigation-button">Next</button>
        <button mat-raised-button color="primary" matStepperPrevious class="navigation-button">Back</button>
    </mat-step> -->
    <!-- summary_code_modify -->
    <mat-step [stepControl]="formSummaryGroup" state="Summary" *ngIf="isEditDeliverySite">
        <ng-template matStepLabel>
            <p class="stepper-label">{{'HOME.Summary'| translate}}</p>
        </ng-template>
        <div>
            <form [formGroup]="formSummaryGroup">
                <div class="example-form">
                    <div class="container-fluid no-left-padding">
                        <p class="section-header">{{'HOME.Information'| translate}}</p>
                        <div class="row no-gutters pt-1">
                            <div class="col-2 width_div_mv pl-">
                                <label for="CustomerId" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Customername'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv input_box">
                                <label class="label-text form-control disabled_field_color">{{
                                    getCustomerNameById(formSummaryGroup.controls['CustomerId'].value) }}</label>
                            </div>
                            <div class="col-0">
                            </div>

                            <div class="col-3 width_div_mv">
                                <label for="DeliverySite" class="field-label">
                                    <span class="input-text-header"> {{'HOME.DeliverySiteName'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.DeliverySiteNameTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_box">
                                <input type="text" class="form-control input-text" formControlName="DeliverySite" id="txtDeliverySiteName"
                                    placeholder="{{'HOME.DeliverySiteName'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && formSummaryGroup.controls['DeliverySite'].hasError('required')) }" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Summary'] && formSummaryGroup.controls['DeliverySite'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error *ngIf="(formSummaryGroup.controls['DeliverySite'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.delivery-site-name-maximum-length-error'| translate}}
                                </mat-error>
                            </div>
                        </div>
                        <div class="row no-gutters pt-4">
                            <div class="col-2 width_div_mv pl-">
                                <label for="BillingReference" class="field-label">
                                    <span class="input-text-header"> {{'HOME.BillingReference'| translate}}
                                    </span>
                                    <span *ngIf="countryCode === 'SWE' || countryCode === 'NOR'" class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.BillingReferenceTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv input_box ">
                                <input type="text" class="form-control input-text" formControlName="BillingReference" id="txtBillingReference"
                                    placeholder="{{'HOME.BillingReference'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && ((formSummaryGroup.controls['BillingReference'].hasError('maxlength')) || (formSummaryGroup.controls['BillingReference'].hasError('required')))) }" />
                                <mat-error *ngIf="(formSummaryGroup.controls['BillingReference'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                                <mat-error
                                    *ngIf="(submittedFlagObject['Information'] && formSummaryGroup.controls['BillingReference'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                            </div>

                            <div class="col-3 width_div_mv">
                                <label for="ExpectedFirstdeliverydate" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Expectedfirstdeliverydate'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv  input_box">
                                <label class="label-text form-control disabled_field_color">{{
                                    getDateInCorrectFormat(formSummaryGroup.controls['ExpectedFirstDeliveryDate'].value)
                                    }}</label>
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-2 width_div_mv">
                                <label for="PlantType" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Planttype'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv input_box">
                                <label class="label-text form-control disabled_field_color">{{
                                    getPlantTypeNameByCode(formSummaryGroup.controls['PlantType'].value) }}</label>
                            </div>

                            <div class="col-3 width_div_mv">
                                <label for="Incoterms" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Incoterms'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{ incotermsInfoIconTooltip }}"
                                        matTooltipClass="custom-tooltip-class"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv" *ngIf="!(isEditDeliverySite && selectedDeliverySite['FreightCode'])">
                                <select class="form-control input-text dropdown-list" id="incoterms" formControlName="Incoterms"
                                    [attr.disabled]="((incotermsList && incotermsList.length && incotermsList.length === 1) ?  true : undefined)"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && formSummaryGroup.controls['Incoterms'].hasError('required')) }"
                                    title="{{ getTooltipForSelectedIncoterms(formSummaryGroup.controls['Incoterms']) }}">
                                    <option value="">{{'HOME.SELECT'| translate}}</option>
                                    <option *ngFor="let incoterms of incotermsList" [ngValue]="incoterms.FreightHandlingId"
                                        title="{{incoterms.tooltip}}">
                                        {{incoterms.FreightHandlingDesc2}}
                                    </option>
                                </select>
                                <mat-error *ngIf="(submittedFlagObject['Summary'] && formSummaryGroup.controls['Incoterms'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                            </div>
                            <div class="col-3 width_div_mv input_box" *ngIf="(isEditDeliverySite && selectedDeliverySite['FreightCode'])">
                                <label class="label-text form-control disabled_field_color"
                                    title="{{ getTooltipForSelectedIncoterms(formSummaryGroup.controls['Incoterms']) }}">{{
                                    getIncotermsDescriptionById(formSummaryGroup.value) }}</label>
                            </div>
                            <div class="col-1">
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-2 width_div_mv" *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <label for="LoadingDepot" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Loadingdepot'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_box"
                                *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <input type="text" class="form-control input-text" formControlName="LoadingDepot"
                                    id="txtLoadingDepot" placeholder="{{'HOME.Loadingdepot'| translate}}" />
                            </div>

                            <!-- <div class="col-2" *ngIf="isEditDeliverySite">
                                <label for="JdeAddressNumber" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Addressnumber(JDE)'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 input_box" *ngIf="isEditDeliverySite && enableFormControl === 'JdeAddressNumber'">
                                <input type="text" class="form-control input-text" formControlName="JdeAddressNumber"
                                    id="txtLoadingDepot" placeholder="{{'HOME.Addressnumber(JDE)'| translate}}" />
                            </div>
                            <div class="col-3 input_box" *ngIf="isEditDeliverySite && enableFormControl !== 'JdeAddressNumber'">
                                <label class="label-text form-control">{{ formSummaryGroup.controls['JdeAddressNumber'].value
                                    }}</label>
                            </div>
                            <div class="col-1" >
                            </div>

                            <div class="col-2 ml- " *ngIf="countryCode === 'FIN' && isEditDeliverySite">
                                <label for="DepotAddressNumber" class="field-label">
                                    <span class="input-text-header"> {{'HOME.AddressnumberDepot'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 input_box"
                                *ngIf="countryCode === 'FIN' && isEditDeliverySite && enableFormControl !== 'DepotAddressNumber'">
                                <label class="label-text form-control">{{ formSummaryGroup.controls['DepotAddressNumber'].value
                                    }}</label>
                            </div>
                            <div class="col-1 extra_space_for_edit_icon_align" >
                            </div> -->
                        </div>
                    </div>
                    <hr class="section-separator">
                    <div class="container-fluid no-left-padding">
                        <p class="section-header">{{'HOME.Address'| translate}}</p>
                        <div class="row no-gutters pt-1">
                            <div class="col-2 width_div_mv width-22">
                                <label for="AddressLine1" class="field-label" *ngIf="selectedLanguageCode === 'FI'">
                                    <span class="input-text-header"> {{'HOME.AddressLine1'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                                <label for="AddressLine1" class="field-label" *ngIf="selectedLanguageCode !== 'FI'">
                                    <div class="input-text-header"> {{'HOME.AddressLine1'| translate}} /
                                    </div>
                                    <span class="input-text-header"> {{'HOME.GPSCoordinates'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.GPScoordinatesTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv input_box" *ngIf="selectedLanguageCode === 'FI'">
                                <input type="text" class="form-control input-text" formControlName="AddressLine1" id="txtAddressLine1"
                                    placeholder="{{'HOME.AddressLine1'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && (formSummaryGroup.controls['AddressLine1'].hasError('required') || formSummaryGroup.controls['AddressLine1'].hasError('maxlength'))) }" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Summary'] && formSummaryGroup.controls['AddressLine1'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error *ngIf="(formSummaryGroup.controls['AddressLine1'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv input_box" *ngIf="selectedLanguageCode !== 'FI'">
                                <input type="text" class="form-control input-text" formControlName="AddressLine1" id="txtAddressLine1"
                                    placeholder="{{'HOME.AddressLine1'| translate}} / {{'HOME.GPSCoordinates'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && (formSummaryGroup.controls['AddressLine1'].hasError('required') || formSummaryGroup.controls['AddressLine1'].hasError('maxlength'))) }" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Summary'] && formSummaryGroup.controls['AddressLine1'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error *ngIf="(formSummaryGroup.controls['AddressLine1'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                            </div>
                            <div class="col-0">
                            </div>

                            <!-- Combined Address line 1 and GPS coordinates fields -->
                            <!-- <div class="col-2 width_div_mv">
                                <label for="GPSCoordinates" class="field-label">
                                    <span class="input-text-header"> {{'HOME.GPSCoordinates'| translate}}
                                    </span>
                                </label>
                                <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.GPScoordinatesTooltip'| translate}}"></i>
                            </div>
                            <div class="col-3 width_div_mv ">
                                <input type="text" class="form-control input-text" formControlName="GPSCoordinates" id="txtGPSCoordinates"
                                    placeholder="{{'HOME.GPSCoordinates'| translate}}" />
                            </div>
                            <div class="col-1">
                            </div> -->

                            <div class="col-2 width_div_mv width-25">
                                <label for="AddressLine2" class="field-label">
                                    <span class="input-text-header">{{'HOME.AddressLine2'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv input_box">
                                <input type="text" class="form-control input-text" formControlName="AddressLine2" id="txtAddressLine2"
                                    placeholder="{{'HOME.AddressLine2'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && (formSummaryGroup.controls['AddressLine2'].hasError('maxlength'))) }" />
                                <mat-error *ngIf="(formSummaryGroup.controls['AddressLine2'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                            </div>
                            <div class="col-1">
                            </div>

                        </div>
                        <div class="row no-gutters" [ngClass]="{ 'pt-3': (selectedLanguageCode === 'FI') }">
                            <div class="col-2 width_div_mv width-22">
                                <label for="City" class="field-label">
                                    <span class="input-text-header"> {{'HOME.City'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv ">
                                <input type="text" class="form-control input-text" formControlName="City" id="txtCity"
                                    placeholder="{{'HOME.City'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && (formSummaryGroup.controls['City'].hasError('required') || formSummaryGroup.controls['City'].hasError('maxlength'))) }" />
                                <mat-error *ngIf="(submittedFlagObject['Summary'] && formSummaryGroup.controls['City'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error *ngIf="(formSummaryGroup.controls['City'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 25 } }}
                                </mat-error>
                            </div>
                            <div class="col-0">
                            </div>
                            <div class="col-2 width_div_mv width-25">
                                <label for="DeliveryPostalCode" class="field-label">
                                    <span class="input-text-header"> {{'HOME.DeliveryPostalCode'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <input type="text" class="form-control input-text" formControlName="DeliveryPostalCode"
                                    id="txtDeliveryPostalCode" placeholder="{{'HOME.DeliveryPostalCode'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && (formSummaryGroup.controls['DeliveryPostalCode'].hasError('required') || formSummaryGroup.controls['DeliveryPostalCode'].hasError('maxlength'))), 'is-warning': (showInvalidPostalCodeMessage) }"
                                    (change)="validatePostalCode($event.target.value)" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Summary'] && formSummaryGroup.controls['DeliveryPostalCode'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error *ngIf="showInvalidPostalCodeMessage">
                                    <div class="postal-code-warning-message">
                                        {{'HOME.Deliverypostalcodealreadypresent'| translate}}
                                    </div>
                                </mat-error>
                                <mat-error *ngIf="(formSummaryGroup.controls['DeliveryPostalCode'].hasError('maxlength'))"
                                    class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 12 } }}
                                </mat-error>
                            </div>
                            <div class="col-1">
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-2 width_div_mv width-22">
                                <label for="Country" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Country'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv  input_box ">
                                <label class="label-text form-control disabled_field_color">{{
                                    getCountryNameById(formSummaryGroup.controls['Country'].value) }}</label>
                            </div>
                            <div class="col-0">
                            </div>
                            <div class="col-2 width_div_mv width-25">
                                <label for="PrimaryContactName" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Primarycontactpersonname'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.PrimarycontactpersonnameTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv input_box">
                                <input type="text" class="form-control input-text" formControlName="PrimaryContactName" id="txtPrimaryContactPerson"
                                    placeholder="{{'HOME.Primarycontactpersonname'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && (formSummaryGroup.controls['PrimaryContactName'].hasError('maxlength'))) }" />
                                <mat-error *ngIf="(formSummaryGroup.controls['PrimaryContactName'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-2 width_div_mv width-22">
                                <label for="PrimaryContactEmailAddress" class="field-label">
                                    <span class="input-text-header"> {{'HOME.PrimaryEmailAddress'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.PrimaryEmailAddressTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_box primary-email-address-summary">
                                <input type="text" class="form-control input-text" formControlName="PrimaryContactEmailAddress"
                                    id="txtPrimaryEmailAddress" placeholder="{{'HOME.PrimaryEmailAddress'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && formSummaryGroup.controls['PrimaryContactEmailAddress'].hasError('required')) }" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Summary'] && formSummaryGroup.controls['PrimaryContactEmailAddress'].hasError('required'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error
                                    *ngIf="(submittedFlagObject['Summary'] && formSummaryGroup.controls['PrimaryContactEmailAddress'].hasError('email'))"
                                    class="error-message">
                                    {{'HOME.Emailmustbeavalidemailaddress'| translate}}
                                </mat-error>
                                <mat-error *ngIf="(formSummaryGroup.controls['PrimaryContactEmailAddress'].hasError('maxlength'))"
                                    class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 256 } }}
                                </mat-error>
                            </div>
                            <div class="col-3 width_div_mv width-25">
                                <label for="PrimaryContactCountryCode" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Contactphonenumber'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.ContactnumberTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-1 contact_country_code_input_width_mv input_box country-code-width">
                                <select class="form-control input-text dropdown-list" id="contactNumber"
                                    formControlName="PrimaryContactCountryCode">
                                    <option *ngFor="let country of countryList" [value]="country.id">
                                        {{ country.id }} (+ {{ country.code }} )
                                    </option>
                                </select>
                            </div>
                            <div class="col-2 contact_phone_number_input_width_mv input_align_mv input_box primary-contact-width pl-1">
                                <input type="text" class="form-control input-text phone-number-textbox-summary" formControlName="ContactNumber"
                                    id="primary_contact" placeholder="{{'HOME.PhoneNumber'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && (formSummaryGroup.controls['ContactNumber'].hasError('maxlength'))) }" />
                                <mat-error *ngIf="(formSummaryGroup.controls['ContactNumber'].hasError('maxlength'))" class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 20 } }}
                                </mat-error>
                                <mat-error *ngIf="(formSummaryGroup.controls['ContactNumber'].hasError('pattern'))" class="error-message">
                                    {{'HOME.Pleaseenternumbersonly'| translate}}
                                </mat-error>
                            </div>
                        </div>

                        <!-- <div class="row no-gutters pt-3">
                        </div> -->

                    </div>
                    <hr class="section-separator">
                    <div class="container-fluid no-left-padding">
                        <p class="section-header">{{'HOME.Notification'| translate}}</p>
                        <div class="row no-gutters pt-3">
                            <!-- Notify_Haulier  -->
                            <div class="col-2 width_div_mv pl-2" *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <label for="NotifyHaulier" class="field-label">
                                    <span class="input-text-header"> {{'HOME.NotifyHaulier'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.NotifyHaulierTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-0 width_div_mv input_align_mv"
                                *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <input class="form-control form-checkbox" type="checkbox"
                                    formControlName="NotifyHaulier">
                            </div>
                            <!-- Haulier_name -->
                            <div *ngIf="countryCode != 'GBR'" class="col-2 width_div_mv width-22"
                                [ngClass]="{ 'haulier-name-label': (countryCode != 'FIN' && countryCode != 'GBR'), 'width-22': !(countryCode != 'FIN' && countryCode != 'GBR') }">
                                <label for="HaulierName" class="field-label HaulierName_padding_summary_modify  ">
                                    <span class="input-text-header"> {{'HOME.Hauliername'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv input_box" *ngIf="countryCode != 'GBR'"
                                [ngClass]="{ 'pl-2': (countryCode != 'FIN' && countryCode != 'GBR') }">
                                <input type="text" class="form-control input-text" formControlName="HaulierName"
                                    id="txtHauliername" placeholder="{{'HOME.Hauliername'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && (formSummaryGroup.controls['HaulierName'].hasError('maxlength'))) }" />
                                <mat-error *ngIf="(formSummaryGroup.controls['HaulierName'].hasError('maxlength'))"
                                    class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 40 } }}
                                </mat-error>
                            </div>
                            <!-- Haulier_EmailId -->
                            <div class="col-2 width_div_mv pl-4 haulier-email-id-div" *ngIf="countryCode != 'GBR'">
                                <label for="HaulierEmailId" class="field-label">
                                    <span class="input-text-header"> {{'HOME.HaulierEmailId'| translate}}
                                    </span>
                                    <span class="mandatory-field"
                                        *ngIf="(formSummaryGroup.controls['NotifyHaulier'].value || countryCode === 'FIN')">*</span>
                                </label>
                            </div>
                            <div class="col-2 width_div_mv input_box haulier-email-id-value"
                                *ngIf="countryCode != 'GBR'">
                                <input type="text" class="form-control input-text" formControlName="HaulierEmailId"
                                    id="txtHaulierEmailId" placeholder="{{'HOME.HaulierEmailId'| translate}}"
                                    [ngClass]="{ 'is-invalid': (submittedFlagObject['Summary'] && (formSummaryGroup.controls['HaulierEmailId'].hasError('maxlength'))) }" />
                                <mat-error
                                    *ngIf="(submittedFlagObject['Summary'] && formSummaryGroup.hasError('haulierEmailIdIsEmpty'))"
                                    class="error-message">
                                    {{'HOME.Thisfieldisrequired'| translate}}
                                </mat-error>
                                <mat-error
                                    *ngIf="(submittedFlagObject['Summary'] && formSummaryGroup.controls['HaulierEmailId'].hasError('email'))"
                                    class="error-message">
                                    {{'HOME.Emailmustbeavalidemailaddress'| translate}}
                                </mat-error>
                                <mat-error *ngIf="(formSummaryGroup.controls['HaulierEmailId'].hasError('maxlength'))"
                                    class="error-message">
                                    {{'HOME.Maxcharactersallowed'| translate: { numberOfCharacters: 256 } }}
                                </mat-error>
                            </div>
                        </div>
                        <div class="row width_Send_Forecast_Reminder_div_mv no-gutters pt-4">
                            <div class="col-2 width_Send_Forecast_Reminder_div_mv width-22"
                                *ngIf="countryCode != 'GBR' && isLoggedInUserPortalAdmin">
                                <label for="SendForecastReminder" class="field-label">
                                    <span class="input-text-header"> {{'HOME.SendForecastReminder'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.SendForecastReminderTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_Send_Forecast_Reminder_checkbox_div_mv input_box"
                                *ngIf="countryCode != 'GBR' && isLoggedInUserPortalAdmin">
                                <input class="form-control form-checkbox" type="checkbox"
                                    formControlName="SendForecastReminder">
                            </div>
                        </div>
                        <div class="row no-gutters" [ngClass]="{ 'pt-3': (countryCode != 'GBR') }">
                            <div class="col-2 width_div_mv width-22">
                                <label for="Customertextinfo" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Additionaltextinfo'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <textarea class="form-control text-area-height-customer input-text" type="text"
                                    formControlName="CustomerTextInfo" id="customerTextInfo" maxlength="250"></textarea>
                                <span class="input-text-header">{{'HOME.characters-remaining'| translate}}</span>
                                <span class="input-text-header normal-text"> {{ 250 -
                                    (formSummaryGroup.controls['CustomerTextInfo'].value?.length)}}</span>
                                <mat-error *ngIf="(formSummaryGroup.controls['CustomerTextInfo'].value?.length === 250)" class="error-message">
                                    {{'HOME.Max250charactersallowed'| translate}}
                                </mat-error>
                            </div>
                            <!-- <div class="col-1 extra_space_for_edit_icon_align" >
                                                    </div> -->
                            <div class="col-2 pl-4 width_div_mv " *ngIf="isLoggedInUserPortalAdmin">
                                <label for="Internaltextinfo" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Internaltextinfo'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.InternaltextinfoTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <!-- <div class="col-3 input_box internal-text-info-value " *ngIf="isLoggedInUserPortalAdmin">
                                                        <textarea class="form-control input-text" type="text" formControlName="InternalTextInfo" id="internalTextInfo"
                                                            maxlength="2000"></textarea>
                                                        <span class="input-text-header">{{'HOME.characters-remaining'| translate}}</span>
                                                        <span class="input-text-header normal-text"> {{ 2000 -
                                                            (formSummaryGroup.controls['InternalTextInfo'].value?.length)}}</span>
                                                        <mat-error *ngIf="(formSummaryGroup.controls['InternalTextInfo'].value?.length === 2000)" class="error-message">
                                                            {{'HOME.Max2000charactersallowed'| translate}}
                                                        </mat-error>
                                                    </div> -->
                        
                            <div class="col-4 width_div_mv  input_box internal-text-info-value" *ngIf="isLoggedInUserPortalAdmin">
                                <textarea class="form-control text-area-height-internal input-text" type="text"
                                    formControlName="InternalTextInfo" id="internalTextInfo" maxlength="2000"></textarea>
                                <span class="input-text-header">{{'HOME.characters-remaining'| translate}}</span>
                                <span class="input-text-header normal-text"> {{ 2000 -
                                    (formSummaryGroup.controls['InternalTextInfo'].value?.length)}}</span>
                                <mat-error *ngIf="(formSummaryGroup.controls['InternalTextInfo'].value?.length === 2000)" class="error-message">
                                    {{'HOME.Max2000charactersallowed'| translate}}
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </form>
        </div>
        <div>
            <button mat-raised-button color="accent" type="submit" class="navigation-button ml-2"
                (click)="saveDeliverySiteDetails()">{{'HOME.Submit'| translate}}</button> &nbsp;
            &nbsp;
            <button mat-raised-button color="primary" matStepperPrevious class="navigation-button"
                (click)="navigateToPreviousPage('Summary')">{{'HOME.Back'|
                translate}}</button> &nbsp;
            &nbsp;
            <!-- <button mat-raised-button color="warn" (click)="stepper.reset()"
                class="navigation-button mr-2">Reset</button> -->
        </div>
    </mat-step>
    <!-- summary_code -->
    <mat-step [stepControl]="formSummaryGroup" state="Summary" *ngIf="!isEditDeliverySite">
        <ng-template matStepLabel>
            <p class="stepper-label">{{'HOME.Summary'| translate}}</p>
        </ng-template>
        <div>
            <form [formGroup]="formSummaryGroup">
                <div class="example-form">
                    <div class="container-fluid no-left-padding">
                        <p class="section-header">{{'HOME.Information'| translate}}</p>
                        <div class="row no-gutters pt-1">
                            <div class="col-3 width_div_mv">
                                <label for="CustomerId" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Customername'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <label class="label-text form-control">{{
                                    getCustomerNameById(formSummaryGroup.controls['CustomerId'].value) }}</label>
                            </div>
                            <div class="col-3 width_div_mv ">
                                <label for="DeliverySite" class="field-label">
                                    <span class="input-text-header"> {{'HOME.DeliverySiteName'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.DeliverySiteNameTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label class="label-text form-control">{{
                                    formSummaryGroup.controls['DeliverySite'].value
                                    }}</label>
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-3 width_div_mv">
                                <label for="BillingReference" class="field-label">
                                    <span class="input-text-header"> {{'HOME.BillingReference'| translate}}
                                    </span>
                                    <span *ngIf="countryCode === 'SWE' || countryCode === 'NOR'" class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.BillingReferenceTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <label class="label-text form-control text-overflow-ellipsis" title="{{
                                                                                            formSummaryGroup.controls['BillingReference'].value
                                                                                            }}">{{
                                    formSummaryGroup.controls['BillingReference'].value
                                    }}</label>
                            </div>
                            <div class="col-3 width_div_mv ">
                                <label for="ExpectedFirstdeliverydate"
                                    class="field-label ExpectedFirstdeliverydate_padding">
                                    <span class="input-text-header"> {{'HOME.Expectedfirstdeliverydate'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label class="label-text form-control">{{
                                    getDateInCorrectFormat(formSummaryGroup.controls['ExpectedFirstDeliveryDate'].value)
                                    }}</label>
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-3 width_div_mv">
                                <label for="PlantType" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Planttype'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <label class="label-text form-control">{{
                                    getPlantTypeNameByCode(formSummaryGroup.controls['PlantType'].value) }}</label>
                            </div>

                            <div class="col-3 width_div_mv">
                                <label for="Incoterms" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Incoterms'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{ incotermsInfoIconTooltip }}"
                                        matTooltipClass="custom-tooltip-class"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label class="label-text form-control"
                                    title="{{ getTooltipForSelectedIncoterms(formSummaryGroup.controls['Incoterms']) }}">{{
                                    getIncotermsDescriptionById(formSummaryGroup.value)
                                    }}</label>
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-3 width_div_mv" *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <label for="LoadingDepot" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Loadingdepot'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv " *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <label class="label-text form-control">{{
                                    formSummaryGroup.controls['LoadingDepot'].value
                                    }}</label>
                            </div>
                        </div>
                    </div>
                    <hr class="section-separator">
                    <div class="container-fluid no-left-padding">
                        <!-- address -->
                        <p class="section-header">{{'HOME.Address'| translate}}</p>
                        <div class="row no-gutters pt-1">
                            <div class="col-3 width_div_mv">
                                <label for="AddressLine1" class="field-label" *ngIf="selectedLanguageCode === 'FI'">
                                    <span class="input-text-header"> {{'HOME.AddressLine1'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                                <label for="AddressLine1" class="field-label" *ngIf="selectedLanguageCode !== 'FI'">
                                    <div class="input-text-header"> {{'HOME.AddressLine1'| translate}} /
                                    </div>
                                    <span class="input-text-header"> {{'HOME.GPSCoordinates'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.GPScoordinatesTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <label class="label-text form-control text-overflow-ellipsis"
                                    title="{{
                                                                                            formSummaryGroup.controls['AddressLine1'].value }}">{{
                                    formSummaryGroup.controls['AddressLine1'].value }}</label>
                            </div>

                            <!-- Combined Address line 1 and GPS coordinates fields -->
                            <!-- <div class="col-3 width_div_mv ">
                                <label for="GPSCoordinates" class="field-label">
                                    <span class="input-text-header"> {{'HOME.GPSCoordinates'| translate}}
                                    </span>
                                </label>
                                <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.GPScoordinatesTooltip'| translate}}"></i>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <label class="label-text form-control">{{
                                    formSummaryGroup.controls['GPSCoordinates'].value
                                    }}</label>
                            </div> -->

                            <div class="col-3 width_div_mv">
                                <label for="AddressLine2" class="field-label">
                                    <span class="input-text-header">{{'HOME.AddressLine2'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <label class="label-text form-control text-overflow-ellipsis" title="{{formSummaryGroup.controls['AddressLine2'].value }}">
                                    {{ formSummaryGroup.controls['AddressLine2'].value }}
                                </label>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col-3 width_div_mv ">
                                <label for="City" class="field-label">
                                    <span class="input-text-header"> {{'HOME.City'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <label class="label-text form-control">{{ formSummaryGroup.controls['City'].value
                                    }}</label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label for="DeliveryPostalCode" class="field-label">
                                    <span class="input-text-header"> {{'HOME.DeliveryPostalCode'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <label class="label-text form-control">{{
                                    formSummaryGroup.controls['DeliveryPostalCode'].value
                                    }}</label>
                            </div>
                        </div>
                        <div class="row no-gutters pt-1">
                            <div class="col-3 width_div_mv">
                                <label for="Country" class="field-label ">
                                    <span class="input-text-header"> {{'HOME.Country'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label class="label-text form-control">{{
                                    getCountryNameById(formSummaryGroup.controls['Country'].value) }}</label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label for="PrimaryContactName" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Primarycontactpersonname'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.PrimarycontactpersonnameTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv">
                                <label class="label-text form-control text-overflow-ellipsis"
                                    title="{{formSummaryGroup.controls['PrimaryContactName'].value}}">
                                    {{ formSummaryGroup.controls['PrimaryContactName'].value }}
                                </label>
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-3 width_div_mv">
                                <label for="PrimaryContactEmailAddress" class="field-label">
                                    <span class="input-text-header"> {{'HOME.PrimaryEmailAddress'| translate}}
                                    </span>
                                    <span class="mandatory-field">*</span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.PrimaryEmailAddressTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label class="label-text form-control">
                                    {{ formSummaryGroup.controls['PrimaryContactEmailAddress'].value }}
                                </label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label for="PrimaryContactCountryCode" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Contactphonenumber'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.ContactnumberTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv">
                                <label class="label-text form-control">
                                    <span *ngIf="formSummaryGroup.controls['ContactNumber'].value">
                                        {{
                                        getCountryPhoneCodeByCountryId(formSummaryGroup.controls['PrimaryContactCountryCode'].value)
                                        +
                                        ' ' + formSummaryGroup.controls['ContactNumber'].value }}
                                    </span>
                                </label>
                            </div>
                        </div>

                        <!-- <div class="row no-gutters pt-3">
                        </div> -->

                    </div>
                    <hr class="section-separator">
                    <!-- Notification -->
                    <div class="container-fluid no-left-padding">
                        <p class="section-header">{{'HOME.Notification'| translate}}</p>
                        <div class="row no-gutters pt-3">
                            <div class="col-3 width_div_mv" *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <label for="NotifyHaulier" class="field-label">
                                    <span class="input-text-header"> {{'HOME.NotifyHaulier'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.NotifyHaulierTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv" *ngIf="countryCode != 'FIN' && countryCode != 'GBR'">
                                <label class="label-text form-control">{{
                                    getYesNoForBooleanFields(formSummaryGroup.controls['NotifyHaulier'].value)
                                    }}</label>
                            </div>

                            <div *ngIf="countryCode != 'GBR'" class="col-3 width_div_mv"
                                [ngClass]="{ 'haulier-name-label': (countryCode != 'FIN' && countryCode != 'GBR') }">
                                <label for="HaulierName" class="HaulierName_padding field-label ">
                                    <span class="input-text-header"> {{'HOME.Hauliername'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div *ngIf="countryCode != 'GBR'" class="col-3 width_div_mv">
                                <label class="label-text form-control">{{
                                    formSummaryGroup.controls['HaulierName'].value
                                    }}</label>
                            </div>
                        </div>
                        <div class="row no-gutters pt-3">
                            <div class="col-3 width_div_mv" *ngIf="countryCode != 'GBR' && isLoggedInUserPortalAdmin">
                                <label for="SendForecastReminder" class="field-label">
                                    <span class="input-text-header"> {{'HOME.SendForecastReminder'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.SendForecastReminderTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv"
                                *ngIf="countryCode != 'GBR' && isLoggedInUserPortalAdmin">
                                <label class="label-text form-control">{{
                                    getYesNoForBooleanFields(formSummaryGroup.controls['SendForecastReminder'].value)
                                    }}</label>
                            </div>

                            <div class="col-3 width_div_mv haulier-email-id-div" *ngIf="countryCode != 'GBR'">
                                <label for="HaulierEmailId" class="field-label">
                                    <span class="input-text-header"> {{'HOME.HaulierEmailId'| translate}}
                                    </span>
                                    <span class="mandatory-field"
                                        *ngIf="(formSummaryGroup.controls['NotifyHaulier'].value || countryCode === 'FIN')">*</span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv haulier-email-id-value" *ngIf="countryCode != 'GBR'">
                                <label class="label-text form-control">{{
                                    formSummaryGroup.controls['HaulierEmailId'].value
                                    }}</label>
                            </div>
                        </div>
                        <div class="row no-gutters" [ngClass]="{ 'pt-3': (countryCode != 'GBR') }">
                            <div class="col-3 width_div_mv pl-0">
                                <label for="Customertextinfo" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Additionaltextinfo'| translate}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv fields-border">
                                <label class="label-text text-area-word_wrap">{{
                                    formSummaryGroup.controls['CustomerTextInfo'].value
                                    }}</label>
                            </div>
                            <div class="col-3 width_div_mv pl-0 " *ngIf="isLoggedInUserPortalAdmin">
                                <label for="Internaltextinfo" class="field-label">
                                    <span class="input-text-header"> {{'HOME.Internaltextinfo'| translate}}
                                    </span>
                                    <i class="fa fa-info-circle info-icon" matTooltip="{{'HOME.InternaltextinfoTooltip'| translate}}"></i>
                                </label>
                            </div>
                            <div class="col-3 width_div_mv input_align_mv fields-border" *ngIf="isLoggedInUserPortalAdmin">
                                <label class="label-text text-area-word_wrap">{{
                                    formSummaryGroup.controls['InternalTextInfo'].value
                                    }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </form>
        </div>
        <div>
            <button mat-raised-button color="accent" type="submit" class="navigation-button ml-2"
                (click)="saveDeliverySiteDetails()">{{'HOME.Submit'| translate}}</button> &nbsp;
            &nbsp;
            <button mat-raised-button color="primary" matStepperPrevious class="navigation-button">{{'HOME.Back'|
                translate}}</button> &nbsp;
            &nbsp;
            <!-- <button mat-raised-button color="warn" (click)="stepper.reset()"
                class="navigation-button mr-2">Reset</button> -->
        </div>
    </mat-step>
</mat-horizontal-stepper>