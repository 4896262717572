<div class="container" *ngIf="isLoggedInUserPortalAdmin">
    <div class="row pt-1 pb-1 no-gutters">
        <!-- <div class="col-md-12"> -->
        <label class="company-name-label" for="companyName"> {{'HOME.CompanyName'| translate}}
            : </label>
        <select class="company-name-dropdown form-control" [(ngModel)]="selectedCompanyId"
            title=" {{'HOME.SelectCompanyNametofilterrecords'| translate}}">
            <!-- <option value="">All</option> -->

            <option value="">{{'HOME.All'| translate}}</option>

            <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                {{customer.CustomerName}}
            </option>
        </select>
        <span class="pl-3">
            <button type="button" class="btn filter-button" for="filter" (click)='changeCustomerName()'>
                <label class="font-bold font-small" for="filter"> {{'HOME.Find'| translate}}
                </label></button>
        </span>
        
        <!-- </span> -->
        <!-- <span *ngIf="!isLoggedInUserPortalAdmin" class="pl-3">
                <span class="selected-company-name-text">{{ selectedCompanyName }}</span>
            </span> -->
        <!-- </div> -->
    </div>

</div>

<div class="global-search-text-box">
    <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
</div>

<div class="container-fluid pt-1 table-container">
    <div class="row">
        <div class="col-sm">
            <div [hidden]="(!isRequestCompleted || (dataSource.data && dataSource.data.length))"
                class="no-records-message pt-3 pl-1"> {{'HOME.Norecordsfound'| translate}}!
            </div>
            <div [hidden]="(!isRequestCompleted || (!dataSource.data || (dataSource.data && !dataSource.data.length)))"
                class="">
                <div class="horizontal_scrollbar_div">
                    <mat-table [dataSource]="dataSource" class="horizontal_scrollbar" matSort>
                        <ng-container matColumnDef="UserGroupName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                [ngClass]="'mat-table-column-header mat-column-user-group-name mat-column-name mat-table-column-header-text'">
                                {{'HOME.Usergroupname'| translate}}
                            </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="'mat-column-name mat-table-column-row-text'">
                                {{element.UserGroupName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="customerName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                [ngClass]="'mat-table-column-header mat-column-user-group-name mat-table-column-header-text'">
                                {{'HOME.CompanyName'| translate}}

                            </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="'mat-column-customer-name mat-table-column-row-text'">
                                {{ element.customerName }} </td>
                        </ng-container>
                        <ng-container matColumnDef="LastModifiedDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                [ngClass]="'mat-table-column-header mat-column-user-group-name mat-table-column-header-text'">
                                {{'HOME.Modify'| translate}}
                            </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="'mat-column-last-modified-time mat-table-column-row-text'">
                                {{element.LastModifiedDate | date: 'MM/dd/yyyy' }} </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef
                                [ngClass]="'mat-table-column-header mat-column-user-group-actions mat-column-user-group-actions'">
                            </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="'mat-column-actions'">
                                <span (click)='editUserGroup(element)' class="mat-table-actions-padding"
                                    title="{{'HOME.Editusergroup'| translate}} ">
                                    <span class='active-icon-color'> <i class="fa fa-cog" aria-hidden="true"></i></span>
                                </span>
                                <span (click)='showDeleteUserGroupPopUp(element)' class="mat-table-actions-padding pl-4"
                                    title="{{'HOME.Deleteusergroup'| translate}}">
                                    <span class='active-icon-color'> <i class="fas fa-trash"
                                            aria-hidden="true"></i></span>
                                </span>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </mat-table>
                </div>
                <mat-paginator   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"   showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>