<div class="container-fluid sidepadding-none">
  <div>

    <div class="row ">
      <div class="col-lg-12 col-md-12">
        <div class='row no-gutters'>
          <!-- <div class="col-lg-2 col-md-2"></div> -->

          <div class="col-lg-12 col-md-12 testing">
            <div class="row">
              <label class="font-bold font-small customer_name_label_div_mv"
                for="customerName">{{'HOME.Customername'| translate}}</label>
              <select class="selectDropdownYear" [(ngModel)]="selectedCustomerIdForGraph"
                [ngClass]="{'disabled-dropdown': !isLoggedInUserPortalAdmin}">
                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                  {{customer.CustomerName}}
                </option>
              </select>
              


              <label class="font-bold font-small year-label-width" for="productType">{{'HOME.Year'| translate}}</label>
              <select class="selectDropdownYear" [(ngModel)]="yearToSearch">

                <option *ngFor="let year of years" [value]="year">
                  {{ year }}
                </option>
              </select>

              <button type="button" class="btn filter-button" for="filter" (click)='filterIndex(yearToSearch)'>
                <label class="font-bold " for="filter">{{'HOME.Find'| translate}}</label></button>
            </div>
          </div>
          <!-- <div class="col-lg-2 col-md-2"></div> -->
        </div>
      </div>
      <div class="col-lg-2 col-md-2">

      </div>
      <div class="col-lg-8 col-md-8">
        <div class="mt-5" [hidden]="!displayErrorMsg" class="errMsg">
          <span>{{'HOME.Norecordsfound'| translate}}</span>
        </div>
        <div id="bar1" *ngIf="(userInfo.RoleID === 'U')">
          <canvas baseChart [datasets]="barChartData1" [labels]="barChartLabels1" [options]="barChartOptions1"
            [plugins]="barChartPlugins1" [legend]="barChartLegend1" [chartType]="barChartType1">
          </canvas>
        </div>
        <div id="bar2" *ngIf="(userInfo.RoleID === 'PA' || userInfo.RoleID === 'A' )">
          <canvas baseChart [datasets]="barChartData2" [labels]="barChartLabels2" [options]="barChartOptions2"
            [plugins]="barChartPlugins2" [legend]="barChartLegend2" [chartType]="barChartType2">
          </canvas>
        </div>
      </div>
      <div class="col-lg-2 col-md-2">

      </div>


    </div>
    <hr>
    <div class="row">
      <div class="col-lg-12" [hidden]='!displayTable'>
        <div class="downloadHead">
          <i (click)="downloadData()" class="fas fa-download downloadIcon"><span
              class="downloadText">{{'HOME.Download'| translate}}</span></i>
        </div>
      </div>
    </div>
    <div class="col-lg-12">

      <div class='row no-gutters'>
        <label class="font-bold font-small customer_name_label_div_mv"
          for="customerName">{{'HOME.Customername'| translate}}</label>
        <select class="selectDropdownYear" [(ngModel)]="selectedCustomerIdForTable"
          [ngClass]="{'disabled-dropdown': !isLoggedInUserPortalAdmin}">
          <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
            {{customer.CustomerName}}
          </option>
        </select>
 
        <!-- year -->
        <label class="font-bold font-small year2-margin-style" for="yearForTable">{{'HOME.Year'| translate}}</label>
        <select class="selectDropdownYear dropdown-padding-style" style="margin-right: 117px"
          [(ngModel)]="yearToSearchForTable">

          <option *ngFor="let yearForTable of yearsForTable" [value]="yearForTable">
            {{ yearForTable }}
          </option>
        </select>

        <!-- dropdown -->
        <label class="font-small view-by-width" for="reportsTable">{{'HOME.Viewby'| translate}}:</label>
        <select class="selectDropdownYear dropdown-padding-style" [(ngModel)]="dataToSearch">

          <option *ngFor="let param of filterParam" [value]="param">
            {{ 'HOME.' + getStringWithoutSpaces(param) | translate }}
          </option>
        </select>

        <!-- find button -->
        <button type="button" class="btn filter-button" for="filter"
          (click)='filterIndexForTable(yearToSearchForTable , dataToSearch )'>
          <label class="font-bold " for="filter">{{'HOME.Find'| translate}}</label></button>
      </div>



    </div>

    <div class="col-lg-12">
      <div class="mt-5" [hidden]="displayTable" class="errMsg">
        <span>{{'HOME.Norecordsfound'| translate}}</span>
      </div>

    </div>
    <!-- by ALL start-->
    <div class="col-12 mt-2 reportTable horizontal_scrollbar_div_MV" *ngIf="viewBy === 'All'" [hidden]='!displayTable'>
      <!--Angular 7 Impl-->
      <mat-table #table [dataSource]="dataSource" class="reportTable horizontal_scrollbar_mv" matSort>
        <!-- DeliveryNumber Column -->
        <ng-container matColumnDef="Type">
          <mat-header-cell class="header-fonttype" *matHeaderCellDef mat-sort-header> {{'HOME.Type'| translate}}
          </mat-header-cell>
          <mat-cell class="header-fonttype" *matCellDef="let fetchedDataForItemArray">
            <!-- {{fetchedDataForItemArray.Type}} -->
            {{'HOME.' + fetchedDataForItemArray.Type | translate}}
          </mat-cell>
        </ng-container>

        <!-- January Column -->
        <ng-container matColumnDef="Jan">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Jan'| translate}} </mat-header-cell>
          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Jan}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <!-- Feburary Column -->
        <ng-container matColumnDef="Feb">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Feb'| translate}} </mat-header-cell>

          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Feb}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <!-- Mar Column -->
        <ng-container matColumnDef="Mar">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Mar'| translate}} </mat-header-cell>
          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Mar}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <!-- Apr Column -->
        <ng-container matColumnDef="Apr">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Apr'| translate}} </mat-header-cell>
          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Apr}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <!-- May Column -->
        <ng-container matColumnDef="May">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.May'| translate}} </mat-header-cell>

          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.May}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <!-- Jun Column -->
        <ng-container matColumnDef="Jun">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Jun'| translate}}
          </mat-header-cell>

          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Jun}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <!-- Jul Column -->
        <ng-container matColumnDef="Jul">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Jul'| translate}}
          </mat-header-cell>

          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Jul}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <!-- Aug Column -->
        <ng-container matColumnDef="Aug">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Aug'| translate}}
          </mat-header-cell>
          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Aug}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <!-- Sep Column -->
        <ng-container matColumnDef="Sep">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Sep'| translate}}
          </mat-header-cell>

          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Sep}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <!-- Oct Column -->
        <ng-container matColumnDef="Oct">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Oct'| translate}}
          </mat-header-cell>

          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Oct}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <!-- Nov Column -->
        <ng-container matColumnDef="Nov">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Nov'| translate}}
          </mat-header-cell>

          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Nov}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <!-- Dec Column -->
        <ng-container matColumnDef="Dec">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Dec'| translate}}
          </mat-header-cell>

          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Dec}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Total">
          <mat-header-cell class="header-font" *matHeaderCellDef> {{'HOME.Total'| translate}}
          </mat-header-cell>
          <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray">
            {{fetchedDataForItemArray.Total  | number: '1.0-0'}}<span
              *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <!-- <div class="row buttonGroup">
          <button class=" downloadButton" type="submit" (click)="downloadData()"> {{'HOME.Download'| translate}}</button>
      </div> -->
    </div>
    <!-- by all end -->

    <!-- bydepot start -->

    <div class="col-12 mt-2 reportTable horizontal_scrollbar_div_MV" *ngIf="viewBy === 'By depot'"
      [hidden]='!displayTable'>

      <mat-table #table [dataSource]="dataSource1" class="reportTable horizontal_scrollbar_mv" matSort>

        <ng-container matColumnDef="Depot name">
          <mat-header-cell class="header-fonttypeforDepo" *matHeaderCellDef mat-sort-header> {{'HOME.DepotName'| translate}}
          </mat-header-cell>
          <mat-cell class="header-fonttypeforDepo" *matCellDef="let DepotData">
            {{DepotData.DepotName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Type">
          <mat-header-cell class="header-fonttypeforDepo" *matHeaderCellDef mat-sort-header> {{'HOME.Type'| translate}}
          </mat-header-cell>
          <mat-cell class="header-fonttypeforDepo" *matCellDef="let DepotData">
            <!-- {{DepotData.Type}} -->
            {{'HOME.' + DepotData.Type | translate}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="Jan">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Jan'| translate}} </mat-header-cell>
          fe
          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Jan}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Feb">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Feb'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Feb}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Mar">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Mar'| translate}}</mat-header-cell>
          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Mar}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Apr">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Apr'| translate}} </mat-header-cell>
          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Apr}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="May">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.May'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.May}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Jun">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Jun'| translate}} </mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Jun}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Jul">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Jul'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Jul}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Aug">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Aug'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Aug}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Sep">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Sep'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Sep}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Oct">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Oct'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Oct}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Nov">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Nov'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Nov}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Dec">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Dec'| translate}} </mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Dec}}<span *ngIf='DepotDataType === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Total">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Total'| translate}} </mat-header-cell>
          <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
            {{DepotData.Total  | number: '1.0-0'}}<span *ngIf='DepotData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnfordepot; sticky:true">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnfordepot;"></mat-row>
      </mat-table>
      <!-- <div class="row buttonGroup">
        <button class=" downloadButton" type="submit" (click)="downloadData()"> {{'HOME.Download'| translate}}</button>
    </div> -->

    </div>

    <!-- bydepot end -->

    <!-- by product start  -->

    <div class="col-12 mt-2 reportTable horizontal_scrollbar_div_MV" *ngIf="viewBy === 'By product'"
      [hidden]='!displayTable'>

      <mat-table #table [dataSource]="dataSource2" class="reportTable  horizontal_scrollbar_mv " matSort>

        <ng-container matColumnDef="Product name">
          <mat-header-cell class="header-fonttypeforDepo" *matHeaderCellDef mat-sort-header>{{'HOME.Pname'| translate}}
          </mat-header-cell>
          <mat-cell class="header-fonttypeforDepo" *matCellDef="let ProductData">
            {{ProductData.ProductName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Type">
          <mat-header-cell class="header-fonttypeforDepo mat-column-type" *matHeaderCellDef mat-sort-header> {{'HOME.Type'| translate}}
          </mat-header-cell>
          <mat-cell class="header-fonttypeforDepo mat-column-type" *matCellDef="let ProductData">
            <!-- {{ProductData.Type}} -->
            {{'HOME.' + ProductData.Type | translate}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="Jan">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Jan'| translate}}
          </mat-header-cell>fe
          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Jan}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Feb">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Feb'| translate}}
          </mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Feb}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Mar">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Mar'| translate}}
          </mat-header-cell>
          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Mar}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Apr">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Apr'| translate}} </mat-header-cell>
          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Apr}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="May">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.May'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.May}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Jun">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Jun'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Jun}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Jul">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Jul'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Jul}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Aug">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Aug'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Aug}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>



        <ng-container matColumnDef="Sep">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Sep'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Sep}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Oct">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Oct'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Oct}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Nov">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Nov'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Nov}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Dec">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Dec'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Dec}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Total">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Total'| translate}}
          </mat-header-cell>
          <mat-cell class="header-fontDepoMonth" *matCellDef="let ProductData">
            {{ProductData.Total  | number: '1.0-0'}}<span *ngIf='ProductData.Type === "Difference" '> % </span>
          </mat-cell>
        </ng-container>

        <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnforproduct; sticky:true">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnforproduct;"></mat-row>
      </mat-table>

      <!-- <div class="row buttonGroup">
        <button class=" downloadButton" type="submit" (click)="downloadData()"> {{'HOME.Download'| translate}}</button>
    </div> -->

    </div>

    <!-- by product end -->

    <!-- by del site Start -->

    <div class="col-12 mt-2 reportTable horizontal_scrollbar_div_MV" *ngIf="viewBy === 'By delivery site'"
      [hidden]='!displayTable'>

      <mat-table #table [dataSource]="dataSource3" class=" reportTable horizontal_scrollbar_mv" matSort>

        <ng-container matColumnDef="Delivery site">
          <mat-header-cell class="header-fonttypeforDepo" *matHeaderCellDef mat-sort-header>{{'HOME.Deliverysite'| translate}}
          </mat-header-cell>
          <mat-cell class="header-fonttypeforDepo" *matCellDef="let DelSiteData">
            {{DelSiteData.DeliverySite}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Type">
          <mat-header-cell class="header-fonttypeforDepo" *matHeaderCellDef mat-sort-header> {{'HOME.Type'| translate}}
          </mat-header-cell>
          <mat-cell class="header-fonttypeforDepo" *matCellDef="let DelSiteData">
            <!-- {{DelSiteData.Type}} -->
            {{'HOME.' + DelSiteData.Type | translate}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="Jan">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Jan'| translate}} </mat-header-cell>
          fe
          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.Jan }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Feb">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Feb'| translate}} </mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.Feb }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Mar">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Mar'| translate}} </mat-header-cell>
          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.Mar }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Apr">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Apr'| translate}}</mat-header-cell>
          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.Apr}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="May">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.May'| translate}} </mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.May}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Jun">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Jun'| translate}} </mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.Jun}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Jul">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Jul'| translate}} </mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.Jul}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Aug">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Aug'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.Aug}}
          </mat-cell>
        </ng-container>



        <ng-container matColumnDef="Sep">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Sep'| translate}} </mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.Sep}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Oct">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Oct'| translate}} </mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.Oct}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Nov">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef> {{'HOME.Nov'| translate}}</mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{ DelSiteData.Nov}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Dec">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Dec'| translate}} </mat-header-cell>

          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.Dec}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Total">
          <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Total'| translate}}</mat-header-cell>
          <mat-cell class="header-fontDepoMonth" *matCellDef="let DelSiteData">
            {{DelSiteData.Total  | number: '1.0-0'}}
          </mat-cell>
        </ng-container>

        <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnfordelsite; sticky:true">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnfordelsite;"></mat-row>
      </mat-table>
      <!-- <div class="row buttonGroup">
        <button class=" downloadButton" type="submit" (click)="downloadData()"> {{'HOME.Download'| translate}}</button>
    </div> -->

    </div>
    <!-- by del site End-->

  </div>
</div>