<div class="container-fluid pad-left-0">
    <div class="row">
        <div class="col-lg-6 toggle_btn_div_mv">
            <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                #group="matButtonToggleGroup" [(ngModel)]="currentToggleMenu" value="reports">
                <mat-button-toggle value="reports" ngDefaultControl (click)="toggleButtonClick('reports')">
                    {{'HOME.Reports'| translate}}
                </mat-button-toggle>
                <mat-button-toggle value="graph" (click)="toggleButtonClick('graph')">
                    {{'HOME.graph'| translate}}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-lg-6 download_btn_div_mv" *ngIf="!this.mobileQuery.matches">
            <div class="downloadDiv ">
                <div *ngIf="currentToggleMenu === 'reports' && displayTable">
                    <i (click)="downloadReports()" class="fas fa-download downloadIcon "><span
                            class="downloadText">{{'HOME.Download'| translate}}</span></i>
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-1" *ngIf="currentToggleMenu === 'reports'">
        <div class="pl-3 customer_name_label_div_mv">
            <label for="customerName">
                <span class="customer-name-label">{{'HOME.Customername'| translate}}
                </span>
            </label>
        </div>
        <div class="pl-1" *ngIf="isLoggedInUserPortalAdmin">
            <select class="customer-name-dropdown" id="customer-name" [(ngModel)]='customerIdUserSelected'
                (change)="this.getDeliverySites()">
                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                    {{customer.CustomerName}}
                </option>
            </select>
        </div>
        <div class="pl-1" *ngIf="!isLoggedInUserPortalAdmin">
            <input type="text" class="customer-name-textbox" [(ngModel)]='customerName' disabled />
        </div>

        <div class="delivery_site_label_div_mv pl-3" *ngIf="!(countryCode === 'FIN' || countryCode === 'SWE')">
            <label for=" deliverysite">
                <span class="customer-name-label">{{'HOME.Deliverysite'| translate}}
                </span>
            </label>
        </div>
        <div class="delivery_site_dropdown_div delivery_site_dropdown_div_mv" *ngIf="!(countryCode === 'FIN' || countryCode === 'SWE')">
            <select class="customer-name-dropdown" id="delivery-site" [(ngModel)]='deliverySiteUserSelected'>
                <option *ngFor="let deliverySite of deliverySiteList" [value]="deliverySite">{{deliverySite}}
                </option>
            </select>
        </div>
        <div class="pl-3 viewBy_label_div_mv">
            <label for="viewBy">
                <span class="customer-name-label">{{'HOME.Viewby'| translate}}
                </span>
            </label>
        </div>
        <div class="pl-1 viewBy_dropdown_div_mv">
            <select class="customer-name-dropdown" id="viewBy" [(ngModel)]='viewUserSelected'>
                <option value="All">{{'HOME.All'| translate}}</option>
                <option value="By product"> {{'HOME.Byproduct'| translate}} </option>
                <option value="By depot"> {{'HOME.Bydepot'| translate}} </option>
                <option value="By delivery site"> {{'HOME.Bydeliverysite'| translate}} </option>
                <option value="By product and depot">
                    {{'HOME.Byproductanddepot'| translate}} </option>
            </select>
        </div>
        <div class="pl-3 year_label_div_mv">
            <label for="year">
                <span class="customer-name-label">{{'HOME.Year'| translate}}
                </span>
            </label>
        </div>
        <div class="pl-1 year_dropdown_div_mv">
            <select class="customer-name-dropdown" id="year" [(ngModel)]='yearUserSelected'>
                <option *ngFor="let year of yearList" [value]="year">{{year}}
                </option>
            </select>
        </div>
        <div class="pl-3">
            <button class="findButton" (click)="getReports()">{{'HOME.Find'| translate}}</button>
        </div>
            
        <!-- Commented below code, kept for future reference -->
        <div class="global-search-text-box">
            <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
        </div>
        
    </div>
    <div class="row pt-3" *ngIf="currentToggleMenu === 'graph'">
        <div class="pl-3 customer_name_label_graph_div_mv">
            <label for="customerName">
                <span class="customer-name-label">{{'HOME.Customername'| translate}}
                </span>
            </label>
        </div>
        <div class="pl-1" *ngIf="isLoggedInUserPortalAdmin">
            <select class="customer-name-dropdown" id="customer-name" [(ngModel)]='customerIdUserSelected'
                (change)="this.getDeliverySites()">
                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                    {{customer.CustomerName}}
                </option>
            </select>
        </div>
        <div class="pl-1" *ngIf="!isLoggedInUserPortalAdmin">
            <input type="text" class="customer-name-textbox" [(ngModel)]='customerName' disabled />
        </div>
        <div class="delivery_site_label_div_mv pl-3" *ngIf="!(countryCode === 'FIN' || countryCode === 'SWE')">
            <label for="deliverysite">
                <span class="customer-name-label">{{'HOME.Deliverysite'| translate}}
                </span>
            </label>
        </div>
        <div class="delivery_site_dropdown_div delivery_site_dropdown_div_mv" *ngIf="!(countryCode === 'FIN' || countryCode === 'SWE')">
            <select class="customer-name-dropdown" id="delivery-site" [(ngModel)]='deliverySiteUserSelected'>
                <option *ngFor="let deliverySite of deliverySiteList" [value]="deliverySite">{{deliverySite}}
                </option>
            </select>
        </div>
        <div class="pl-3 year_label_graph_div_mv">
            <label for="year">
                <span class="customer-name-label">{{'HOME.Year'| translate}}
                </span>
            </label>
        </div>
        <div class="pl-1 year_dropdown_graph_div_mv">
            <select class="customer-name-dropdown" id="year" [(ngModel)]='yearUserSelected'>
                <option *ngFor="let year of yearList" [value]="year">{{year}}
                </option>
            </select>
        </div>
        <div class="pl-3">
            <button class="findButton" (click)="getReports()">{{'HOME.Find'| translate}}</button>
        </div>
    </div>
    <div class="row pt-2" *ngIf="currentToggleMenu === 'reports' && !displayTable && isRequestCompleted">
        <div class="col-lg-12 mt-3">
            <div class="mt-5" class="no-records-message">
                <span>{{'HOME.Norecordsfound'| translate}}</span>
            </div>
        </div>
    </div>
    <div class="row pt-2" *ngIf="currentToggleMenu === 'graph' && !displayGraph && isRequestCompleted">
        <div class="col-lg-12 mt-3">
            <div class="mt-5" class="no-records-message">
                <span>{{'HOME.Norecordsfound'| translate}}</span>
            </div>
        </div>
    </div>
    <div class="row" [hidden]='currentToggleMenu === "graph" || !displayTable'>
        <div class="col-lg-12 mt-1 table_div_mv">
            <mat-table #table [dataSource]="dataSource" class=" horizontal_scrollbar_mv">
                <ng-container matColumnDef="Depot name">
                    <mat-header-cell class="column-large" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showDepotNameColumn)}">
                        {{'HOME.DepotName'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-large" *matCellDef="let DepotData"
                        [ngClass]="{'hide-table-column': !(showDepotNameColumn)}">
                        {{DepotData.DepotName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Product name">
                    <mat-header-cell class="column-large" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showProductNameColumn)}">
                        {{'HOME.Pname'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-large" *matCellDef="let ProductData"
                        [ngClass]="{'hide-table-column': !(showProductNameColumn)}">
                        {{ProductData.ProductName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Delivery site">
                    <mat-header-cell class="column-large" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showDeliverySiteColumn)}">
                        {{'HOME.Deliverysite'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-large" *matCellDef="let DelSiteData"
                        [ngClass]="{'hide-table-column': !(showDeliverySiteColumn)}">
                        {{DelSiteData.DeliverySite}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Type">
                    <mat-header-cell class="column-medium" *matHeaderCellDef>
                        <!-- Do not display any heading to this column -->
                        <!-- {{'HOME.Type'| translate}} -->
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{'HOME.' + fetchedDataForItemArray.Type | translate}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Jan">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Jan'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Jan}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Feb">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Feb'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Feb}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Mar">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Mar'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Mar}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Apr">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Apr'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Apr}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="May">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.May'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.May}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Jun">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Jun'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Jun}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Jul">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Jul'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Jul}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Aug">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Aug'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Aug}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Sep">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Sep'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Sep}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Oct">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Oct'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Oct}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Nov">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Nov'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Nov}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Dec">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Dec'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Dec}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> %
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Total">
                    <mat-header-cell class="column-medium" *matHeaderCellDef> {{'HOME.Total'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column-medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.Total}}<span *ngIf='fetchedDataForItemArray.Type === "Difference" '> % </span>
                    </mat-cell>
                </ng-container>
                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"
                    [ngClass]="{'row-top-border': row.showRowTopBorder}">
                </mat-row>
            </mat-table>
        </div>
    </div>
    <div class="row" *ngIf="currentToggleMenu === 'graph' && displayGraph && isRequestCompleted">
        <div class="col-lg-8 col-md-8">
            <div id="bar1" *ngIf="(userInfo.RoleID === 'U')">
                <canvas baseChart [datasets]="barChartData1" [labels]="barChartLabels1" [options]="barChartOptions1"
                    [plugins]="barChartPlugins1" [legend]="barChartLegend1" [chartType]="barChartType1">
                </canvas>
            </div>
            <div id="bar2" *ngIf="(userInfo.RoleID === 'PA' || userInfo.RoleID === 'A' )">
                <canvas baseChart [datasets]="barChartData2" [labels]="barChartLabels2" [options]="barChartOptions2"
                    [plugins]="barChartPlugins2" [legend]="barChartLegend2" [chartType]="barChartType2">
                </canvas>
            </div>
        </div>
    </div>
</div>