<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6">
            <!-- <mat-button-toggle-group class="button-group-style toggle_button_mv" name="fontStyle"
                aria-label="Font Style" #group="matButtonToggleGroup" [(ngModel)]="forecastActualValue" value="weekly">
                <ng-container>
                    <mat-button-toggle value="weekly" ngDefaultControl>
                        {{'HOME.Weekly'| translate}}
                    </mat-button-toggle>
                </ng-container>
                <ng-container>
                    <mat-button-toggle value="monthly" ngDefaultControl>
                        {{'HOME.Monthly'| translate}}
                    </mat-button-toggle>
                </ng-container>
            </mat-button-toggle-group> -->
        </div>
    </div>
</div>


<!-- <app-forecastactualweeklyfinland *ngIf="forecastActualValue === 'weekly'">
</app-forecastactualweeklyfinland> -->
<app-forecastactual *ngIf="forecastActualValue === 'monthly'">
</app-forecastactual>
