<div class="container-fluid">
    <div class="row">

        <div class="col-lg-2 col-md-2">

            <div class="sidenav">
                <div>
                    <img class="menu-logo" src="../../../assets/nynas_logo.png">
                </div>
                <!-- <div>
                    <p class="text-center menu-heading">Customer Portal</p>
                </div> -->
                <div class="sidenavbar">
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Home'}"
                        (click)="showSelectedMenuItem('Home')">
                        <a routerLink="/dashboard" class="menu-list-names text-left">
                            <i class="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Home
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='My company'}"
                        (click)="showSelectedMenuItem('My company')">
                        <a [routerLink]="['company-details']"
                            [ngClass]="{'selected-class-text': selectedItem === 'My company'}"
                            class="menu-list-names text-left">
                            <i class="fa fa-building" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;My company
                        </a>
                    </div>
                    <div *ngIf="isLoggedInUserAdmin && validateMenu('10')" class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Add delivery site'}"
                        (click)="showSelectedMenuItem('Add delivery site')">
                        <a [routerLink]="['add-delivery-site']"
                            [ngClass]="{'selected-class-text': selectedItem === 'Add delivery site'}"
                            class="menu-list-names text-left">
                            <i class="fa fa-file-text" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Add delivery site
                        </a>
                    </div>
                    <div *ngIf="isLoggedInUserAdmin && validateMenu('11')" class="menu-item-padding"
                        [ngClass]="{'selected-class': selectedItem ==='Modify delivery site'}"
                        (click)="showSelectedMenuItem('Modify delivery site')">
                        <a [routerLink]="['modify-delivery-site']"
                            [ngClass]="{'selected-class-text': selectedItem === 'Modify delivery'}"
                            class="menu-list-names text-left">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Modify delivery
                            site
                        </a>
                    </div>

                </div>
                
                <!-- <div class="sideInfo">
                    <div> <span class="sideInfoLabel">User name: </span><span>{{name}}</span></div>
                    <div><span class="sideInfoLabel">Company name:</span><span>{{userInfo?.CustomerName}}</span></div>
                    <div><span class="sideInfoLabel">User group: </span><span>{{userInfo?.UserGroupName}}</span></div>
                    <div><span class="sideInfoLabel">Login time: </span><span></span>{{userInfo?.LastLoginTime}}</div> 
                </div> -->
            </div>
        </div>

        <div class="col-lg-10 col-md-10">
            <div class="row header">

                <br>
                <span>{{selectedItem }}</span>
            </div>
            <div class="row">
                <div class='col-lg-12 col-md-12'>
                    <div class="main ">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-10 col-md-10">
            <div class="row header">
                <br>
                <span>{{selectedItem}}</span>
            </div>
        </div> -->
        <!-- <div class='row'>
            <div class="col-lg-12 col-md-12 ">
                <div class="main scroll-table-content scrollbar-table-content">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div> -->

    </div>
</div>