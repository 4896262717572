<div class="container-fluid">
    <div class="">
        <div class="row">
            <div class="col-2 ">
                <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                    #group="matButtonToggleGroup" value="daily" [(ngModel)]="currentToggleMenu">
                    <mat-button-toggle value="daily" ngDefaultControl (click)="toggleButtonClick('daily')">
                        {{'HOME.Daily'| translate}}

                    </mat-button-toggle>
                    <mat-button-toggle value="monthly" ngDefaultControl (click)="toggleButtonClick('monthly')">
                        {{'HOME.Monthly'| translate}}

                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="daily_form" [hidden]='currentToggleMenu !== "daily"'>
            <form [formGroup]="addExchangeRateForm">

                <!-- [ngClass]="{'margin-left-5': currentToggleMenu === 'monthly', 'margin-top-0' : currentToggleMenu === 'daily'}" -->
                <div class=" row pl-3  pt-2 no-gutters">
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="source">
                                <span class="input-text-header"> {{'HOME.Source'| translate}}
                                </span><span class="mandatory-field pl-1">*</span>
                            </label>
                            <div class="">

                                <!-- <input type="radio" formControlName="Source" id="radio-nordea" value="Nordea" />
                                <span class="radio-button-text" for="Nordea">Nordea</span>

                                <input type="radio" formControlName="Source" id="radio-ecb" value="ECB" class="ml-4" />
                                <span class="radio-button-text" for="ECB">ECB</span> -->

                                <input type="radio" formControlName="Source" id="radio-ecb-daily" value="ECB" />
                                <span class="radio-button-text" for="ECB">ECB</span>

                                <input type="radio" formControlName="Source" id="radio-nordea-daily" value="NORDEA" class="ml-4" />
                                <span class="radio-button-text" for="NORDEA">NORDEA</span>


                            </div>
                        </div>
                    </div>

                    <div class="col-md-2 mr-1 pl-0 no-gutters">
                        <div class="form-group ">
                            <label for="IndexDate">
                                <span class="input-text-header"> {{'HOME.Date'| translate}}
                                </span><span
                                    class="mandatory-field pl-1 pr-1">*</span>
                            </label>
                            <mat-form-field [ngClass]="{'disabled-date-picker': isBitumenIndexFormEdit}"
                            class= "datepicker">
                                <input matInput [matDatepicker]="picker" placeholder="" 
                                formControlName="IndexDate">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <!-- <div *ngIf="submitted && fields.IndexDate.errors" class="date-isinvalid">
                                <div *ngIf="fields.IndexDate.errors.required">{{'HOME.Dateisrequired'| translate}}

                                </div>
                            </div> -->
                            <div *ngIf="submitted && fields.IndexDate.errors" class="date-isinvalid">
                                <div *ngIf="fields.IndexDate.errors.required && !fields.IndexDate.errors.matDatepickerParse">
                                    {{'HOME.Dateisrequired'| translate}}
                                </div>
                                <div *ngIf="fields.IndexDate.errors.required && fields.IndexDate.errors.matDatepickerParse">
                                    {{'HOME.Dateisinvalid'| translate}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-2 ">
                        <div class="form-group">
                            <label for="Rate">
                                <span class="input-text-header"> {{'HOME.Rate'| translate}} USD/EUR </span>
                                <span class="mandatory-field pl-1">*</span>
                            </label>
                            <input type="number" class="form-control input-text" formControlName="Rate"
                                [ngClass]="{ 'is-invalid': submitted && fields.Rate.errors }" id="txt_rate-daily"
                                placeholder="{{'HOME.Rate'| translate}}">
                            <div *ngIf="submitted && fields.Rate.errors" class="invalid-feedback">
                                <div *ngIf="fields.Rate.errors.required"> {{'HOME.rateisreq'| translate}}

                                </div>
                            </div>
                            <div *ngIf="isRateValueInvalid" class="is-invalid-quantity-message">
                                <div>{{'HOME.Entervalidnumberupto3decimalplacesonly'| translate}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-1 pl-3 ">
                        <button id="btn-save" class="btn btn-sm btn-primary add-price-buttons saveBtnMonthly"
                            (click)="save()">Save</button>
                    </div>

                    <div class="col-md-1 ">
                    </div>

                    <div class="col-md-1 pl-3 ">
                        <button id="btn-reset" class="btn btn-sm btn-primary add-price-buttons resetBtn"
                            (click)="reset()">Reset</button>
                    </div> -->

                </div>
                <div class="row pl-1 ">
                    <div class="col-sm">
                        <button id="btn-save-daily" class="btn btn-sm btn-primary add_exchange_rate_buttons saveBtn"
                            (click)="save()">{{'HOME.Save'| translate}}
                        </button>
                    </div>
                    <div class="col-sm">
                        <button id="btn-reset-daily" class="btn btn-sm btn-primary add_exchange_rate_buttons resetBtn"
                            (click)="reset()">{{'HOME.Reset'| translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>


    <div class="row daily_table pt-3 pb-5" [hidden]='currentToggleMenu !== "daily"'>
        <div class="col-sm">
            <div [hidden]="(!isRequestCompleted || (dataSourceForDailyTable.data && dataSourceForDailyTable.data.length))"
                class="no-records-message pt-3 pl-2"> {{'HOME.Norecordsfound'| translate}}
            </div>
            <div [hidden]="(!isRequestCompleted || (!dataSourceForDailyTable.data || (dataSourceForDailyTable.data && !dataSourceForDailyTable.data.length)))"
                class="price-index-daily-table">
                <!--Angular 7 Impl-->
                <!-- <mat-table #table [dataSource]="dataSourceForDailyTable" class="userTable" matSort> -->
                <div class="horizontal_scrollbar_div">
                    <table mat-table #firstTable #exchangeRateDailyTableSort="matSort" [dataSource]="dataSourceForDailyTable"
                    class="userTable horizontal_scrollbar" matSort>
                    <ng-container matColumnDef="IndexDate">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Date'| translate}}

                        </mat-header-cell>
                        <!-- <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.IndexDate}}
                        </mat-cell> -->

                        <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                            {{ getDateObject(fetchedDataForItemArray.IndexDate)['date'] }}-{{'HOME.' + this.getDateObject(fetchedDataForItemArray.IndexDate)['month'] | translate}}-{{ getDateObject(fetchedDataForItemArray.IndexDate)['year'] }}
                        </mat-cell>

                    </ng-container>

                    <ng-container matColumnDef="Source">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Source'| translate}}

                        </mat-header-cell>
                        <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.Source}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Rate">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Rate'| translate}}

                        </mat-header-cell>
                        <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.Rate | number: '1.2-4'}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef>
                        </mat-header-cell>
                        <th mat-header-cell *matHeaderCellDef [ngClass]="'mat-table-column-header mat-column-actions'">
                        </th>
                        <mat-cell class="mat-column-medium" *matCellDef="let element">
                            <span (click)='editExchangeRate(element)' title=" {{'HOME.Editpriceindex1'| translate}}"
                            class='exchange-rate-edit-icon'>
                                <i class="far fa-edit" aria-hidden="true"></i>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsForDailyTable; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsForDailyTable;"></mat-row>

                    <!-- </mat-table> -->
                </table>
            </div>
                <mat-paginator #TableOnePaginator="matPaginator" [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>


    <!-- *ngIf="group.value == 'monthly'" -->
    <div class="monthly_form " [hidden]='currentToggleMenu !== "monthly"'>
        <form [formGroup]="addExchangeRateForm">
            <div class=" row pl-3 pt-2 no-gutters">
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="source">
                            <span class="input-text-header">{{'HOME.Source'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <div class="">
                            <!-- <input type="radio" formControlName="Source" id="radio-nordea" value="Nordea" />
                                <span class="radio-button-text" for="Nordea">Nordea</span>

                                <input type="radio" formControlName="Source" id="radio-ecb" value="ECB" class="ml-4" />
                                <span class="radio-button-text" for="ECB">ECB</span> -->

                                <input type="radio" formControlName="Source" id="radio-ecb-monthly" value="ECB" />
                                <span class="radio-button-text" for="ECB">ECB</span>

                                <input type="radio" formControlName="Source" id="radio-nordea-monthly" value="NORDEA" class="ml-4" />
                                <span class="radio-button-text" for="NORDEA">NORDEA</span>

                        </div>
                    </div>
                </div>
                <div class="col-md-2 ">
                    <div class="form-group">
                        <label for="month">
                            <span class="input-text-header">{{'HOME.Month'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addPriceIndexSelect month_dropdown"
                            formControlName="Month" [ngClass]="{ 'is-invalid': submitted && fields.Month.errors }"
                            id="Month" [attr.disabled]="isBitumenIndexFormEdit">
                            <option value=""> {{'HOME.Selectmonth'| translate}}
                            </option>
                            <!-- <option *ngFor="let month of monthList" [value]="month">
                                {{month}}
                            </option> -->

                            <!-- <option value="January"> {{'HOME.JANUARY'| translate}} </option>
                            <option value="February"> {{'HOME.FEBRUARY'| translate}} </option>
                            <option value="March"> {{'HOME.MARCH'| translate}} </option>
                            <option value="April"> {{'HOME.APRIL'| translate}} </option>
                            <option value="May"> {{'HOME.MAY'| translate}} </option>
                            <option value="June"> {{'HOME.JUNE'| translate}} </option>
                            <option value="July"> {{'HOME.JULY'| translate}} </option>
                            <option value="August"> {{'HOME.AUGUST'| translate}} </option>
                            <option value="September"> {{'HOME.SEPTEMBER'| translate}} </option>
                            <option value="October"> {{'HOME.OCTOBER'| translate}} </option>
                            <option value="November"> {{'HOME.NOVEMBER'| translate}} </option>
                            <option value="December"> {{'HOME.DECEMBER'| translate}} </option> -->

                            <option value="JAN"> {{'HOME.JAN'| translate}} </option>
                            <option value="FEB"> {{'HOME.FEB'| translate}} </option>
                            <option value="MAR"> {{'HOME.MAR'| translate}} </option>
                            <option value="APR"> {{'HOME.APR'| translate}} </option>
                            <option value="MAY"> {{'HOME.MAY'| translate}} </option>
                            <option value="JUN"> {{'HOME.JUN'| translate}} </option>
                            <option value="JUL"> {{'HOME.JUL'| translate}} </option>
                            <option value="AUG"> {{'HOME.AUG'| translate}} </option>
                            <option value="SEP"> {{'HOME.SEP'| translate}} </option>
                            <option value="OCT"> {{'HOME.OCT'| translate}} </option>
                            <option value="NOV"> {{'HOME.NOV'| translate}} </option>
                            <option value="DEC"> {{'HOME.DEC'| translate}} </option>

                        </select>
                        <div *ngIf="submitted && fields.Month.errors" class="invalid-feedback">
                            <div *ngIf="fields.Month.errors.required">{{'HOME.Monthisrequired'| translate}}

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 margin-left-1">
                    <div class="form-group">
                        <label for="year">
                            <span class="input-text-header">{{'HOME.Year'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addPriceIndexSelect year_dropdown" formControlName="Year"
                            [ngClass]="{ 'is-invalid': submitted && fields.Year.errors }" id="Year"
                            [attr.disabled]="isBitumenIndexFormEdit">
                            <option value="">{{'HOME.SelectYear'| translate}}
                            </option>
                            <option *ngFor="let year of yearList" [value]="year">
                                {{year}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.Year.errors" class="invalid-feedback">
                            <div *ngIf="fields.Year.errors.required">{{'HOME.Yearisreq'| translate}}
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 pl-0">
                    <div class="form-group">
                        <label for="Rate">
                            <span class="input-text-header"> {{'HOME.Rate'| translate}} USD/EUR </span>
                            <span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="number" class="form-control input-text rate_dropdown" formControlName="Rate"
                            [ngClass]="{ 'is-invalid': submitted && fields.Rate.errors }" id="txt_rate-monthly"
                            placeholder="Rate">
                        <div *ngIf="submitted && fields.Rate.errors" class="invalid-feedback">
                            <div *ngIf="fields.Rate.errors.required">{{'HOME.rateisreq'| translate}}

                            </div>
                        </div>
                        <div *ngIf="isRateValueInvalid" class="is-invalid-quantity-message">
                            <div>{{'HOME.Entervalidnumberupto4decimalplacesonly'| translate}}</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-1 pl-3 ">
                    <button id="btn-save" class="btn btn-sm btn-primary add-price-buttons saveBtnMonthly"
                        (click)="save()">Save</button>
                </div>
                <div class="col-md-1 ">
                </div>
                <div class="col-md-1 pl-3 ">
                    <button id="btn-reset" class="btn btn-sm btn-primary add-price-buttons resetBtn"
                        (click)="reset()">Reset</button>
                </div> -->
            </div>
            <div class="row pl-1 pt-3">
                <div class="col-sm">
                    <button id="btn-save-monthly" class="btn btn-sm btn-primary add_exchange_rate_buttons saveBtn"
                        (click)="save()">{{'HOME.Save'| translate}}
                    </button>
                </div>
                <div class="col-sm">
                    <button id="btn-reset-monthly" class="btn btn-sm btn-primary add_exchange_rate_buttons resetBtn"
                        (click)="reset()">{{'HOME.Reset'| translate}}
                    </button>
                </div>
            </div>
        </form>
    </div>

    <!-- <div class="row daily_table pt-3 pb-5" [hidden]='currentToggleMenu !== "daily"'>
        <div class="col-sm">
            <div [hidden]="(!isRequestCompleted || (dataSourceForDailyTable.data && dataSourceForDailyTable.data.length))"
                class="no-records-message pt-3 pl-2">  -->

    <div class="monthly_table " [hidden]='currentToggleMenu !== "monthly"'>
        <div class="daily_table pt-3 pl-3 pb-5">
            <div [hidden]="(!isRequestCompleted || (dataSourceForMonthlyTable.data && dataSourceForMonthlyTable.data.length))"
                class="no-records-message pt-3 pl-2"> {{'HOME.Norecordsfound'| translate}}
            </div>
            <div [hidden]="(!isRequestCompleted || (!dataSourceForMonthlyTable.data || (dataSourceForMonthlyTable.data && !dataSourceForMonthlyTable.data.length)))"
                class="price-index-monthly-table scrollbar-manage-user">
                <!-- <mat-table #table [dataSource]="dataSourceForMonthlyTable" class="userTable" matSort> -->
               <div class="horizontal_scrollbar_div">
                    <table mat-table #secondTable #exchangeRateMonthlyTableSort="matSort"
                    [dataSource]="dataSourceForMonthlyTable" class="userTable horizontal_scrollbar_monthly" matSort>
                    <ng-container matColumnDef="Month">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Month'| translate}}

                        </mat-header-cell>
                        <!-- <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.Month}}
                        </mat-cell> -->

                        <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                            {{'HOME.' + fetchedDataForItemArray.Month | translate}}
                        </mat-cell>

                    </ng-container>
                    <ng-container matColumnDef="Year">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Year'| translate}}

                        </mat-header-cell>
                        <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.Year}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Source">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Source'| translate}}

                        </mat-header-cell>
                        <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.Source}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Rate">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>
                            {{'HOME.ExchangeRate'| translate}}

                        </mat-header-cell>
                        <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.Rate | number: '1.2-4'}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef>
                        </mat-header-cell>
                        <th mat-header-cell *matHeaderCellDef [ngClass]="'mat-table-column-header mat-column-actions'">
                        </th>
                        <mat-cell class="mat-column-medium" *matCellDef="let element">
                            <span (click)='editExchangeRate(element)' title="{{'HOME.Editpriceindex1'| translate}}  "
                                class='exchange-rate-edit-icon'>
                                <i class="far fa-edit" aria-hidden="true"></i>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row class="table-header"
                        *matHeaderRowDef="displayedColumnsForMonthlyTable; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsForMonthlyTable;"></mat-row>
                    <!-- </mat-table> -->
                </table>
                </div>
                <mat-paginator #TableTwoPaginator="matPaginator" [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>

</div>
