import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    apiUrl = environment.apiUrl;
    menu: any;

    private selectedItemSource = new BehaviorSubject('Forecast entry');
    currentSelectedItem = this.selectedItemSource.asObservable();
    constructor(private httpClient: HttpClient) { }



    updateSelectedItem(selectedItem: string) {
        this.selectedItemSource.next(selectedItem);
        this.currentSelectedItem = this.selectedItemSource.asObservable();
    }
    getSelectedItem() {
        return this.selectedItemSource.asObservable();
    }
    // fetchCustomerNamesService(UserName: any, Role: any) {
    //     let customerUrl = this.apiUrl + '/Bitumi_FetchCustomerNamesService';
    //     return this.httpClient
    //         .get(customerUrl, {
    //             params: {
    //                 UserName: UserName,
    //                 Role: Role
    //             }
    //         });

    // }

    getDeliverySite(CustomerId: string, Status: string, countryCode: string) {

        let deliveryUrl = this.apiUrl + '/FetchDeliverySiteService';
        return this.httpClient
            .get(deliveryUrl, {
                params: {
                    CustomerId: CustomerId,
                    ApprovalStage: Status,
                    CountryCode: countryCode
                    //Hardcoded Check @
                    //CustomerName:  'YIT Kotka Ilarila'
                }
            });
    }

    // For SWEDEN and NORWAY
    getDeliverySiteForUser(UserID: string, CountryCode: any, MappingStatus: any, CustomerID: any) {
        let delSiteUrl = this.apiUrl + '/GetDeliverySiteForUser';

        return this.httpClient
            .get(delSiteUrl, {
                params: {
                    UserID: UserID,
                    CountryCode: CountryCode,
                    MappingStatus: MappingStatus, 
                    CustomerID: CustomerID
                }
            });
    }

    async sendMail(data) {
        let postData = JSON.stringify(data)
        try {
            let mailUrl = this.apiUrl + '/CommonNotificationOSBService';
            const httpOptions = {
                headers: new HttpHeaders({
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/xml'
                })
            };
            return await this.httpClient
                .post(mailUrl, postData, httpOptions);
        } catch (error) {
            throw new Error(error);
        }

    }

    setSelectedMenu(menu) {
        this.menu = menu;
    }
    getSelectedMenu() {
        return this.menu;
    }

}

