import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NynasUpdatesService } from 'src/app/nynas-updates/nynas-updates.service';
import { ForecastEntryService } from 'src/app/order-management/forecast-entry/forecast-entry.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from '../../user-management/dialog-body/dialog-body.component';
import { SalesReportService } from './sales-report.service';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css']
})

export class SalesReportComponent implements OnInit {

  dataSource: any;
  displayedColumns: string[] = ['CUSTOMER_Name', 'Month', 'YEAR', 'LastModifiedDate', 'SALES_REPORT'];
  currYear: number;
  yearList: number[] = [];
  yearUserSelected: number;
  monthUserSelected: string;
  isRequestCompleted: boolean;
  countryCode: string;
  userInfo: any;
  role: any;
  Customers = [];
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  loggedInUserName: string;
  customerIdUserSelected: any;
  monthsListObject: any = {};
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private forecastEntryService: ForecastEntryService,
    private salesReportService: SalesReportService,
    private sharedService: AppService,
    private nynasUpdateService: NynasUpdatesService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
  ) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.countryCode = (this.userInfo ? (this.userInfo.CountryCode) : '');
    this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.role = this.userInfo.RoleID;
  }

  async ngOnInit() {
    let currentYear: number = this.getCurrentYear();
    this.getYearList(currentYear);
    this.yearUserSelected = currentYear;
    this.monthUserSelected = ((this.userInfo && (this.userInfo.RoleID === "PA")) ? this.getCurrentMonth() : '');
    this.getMonthsListObject();

    try {
      await this.getCustomers()
    } catch (e) {
    }

    // Get Shipped deliveries list populated by default on page load
    this.findSalesReport();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getCurrentYear(): number {
    const date = this.sharedService.getCurrentDateTime();
    return date.getUTCFullYear();
  }

  getYearList(currYear: number) {
    this.yearList = [];
    this.yearList.push(currYear - 1, currYear);
  }

  getMonthsListObject() {
    this.monthsListObject['01'] = 'JAN';
    this.monthsListObject['02'] = 'FEB';
    this.monthsListObject['03'] = 'MAR';
    this.monthsListObject['04'] = 'APR';
    this.monthsListObject['05'] = 'MAY';
    this.monthsListObject['06'] = 'JUN';
    this.monthsListObject['07'] = 'JUL';
    this.monthsListObject['08'] = 'AUG';
    this.monthsListObject['09'] = 'SEP';
    this.monthsListObject['10'] = 'OCT';
    this.monthsListObject['11'] = 'NOV';
    this.monthsListObject['12'] = 'DEC';
  }

  getCurrentMonth(): string {
    const date = this.sharedService.getCurrentDateTime();
    let monthCode: string;
    monthCode = ((date.getMonth() + 1) < 10) ? ('0' + (date.getMonth() + 1).toString()) : (date.getMonth() + 1).toString();
    return monthCode;
  }

  findSalesReport() {
    this.isRequestCompleted = false;
    this.dataSource = new MatTableDataSource([]);
    this.salesReportService.getSalesReportLocation(this.yearUserSelected.toString(), this.countryCode, this.customerIdUserSelected)
      .subscribe(
        response => {
          if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let filteredData = [];
            for (var report of response['SalesReportCollection']) {
              if (!this.monthUserSelected || (this.monthUserSelected && this.monthsListObject && this.monthsListObject[this.monthUserSelected] === report['Month'])) {
                // LastModifiedDate format handled in html file
                /* if (report['LastModifiedDate']) {
                  report['LastModifiedDate'] = moment(report['LastModifiedDate']).format('DD/MM/YYYY');
                } */
                filteredData.push(report);
              }
            }
            this.dataSource = new MatTableDataSource(filteredData);
            this.dataSource.sort = this.sort;
            this.isRequestCompleted = true;
          }
          this.isRequestCompleted = true;
        });
  }

  downloadExcel(data: any) {
    if (data['PATH']) {
      this.downloadExcelSharepoint(data['PATH'], data['CUSTOMER_Name'], data['YEAR'], data['Month'])
    } else {
      this.openDialog('Sorry! No file available.', null, false, 'no-file-available');
    }
  }

  downloadExcelSharepoint(path: string, customerName: string, year: string, month: string) {
    let pdfData: string;
    let filepath = path;
    let sharepointData = {
      "Username": "API.Integration@nynas.onmicrosoft.com",
      "Password": "VIN24jkjXXwdjwnvbnas",
      "Domain": "nynas",
      "SourceFolderPath": "/sites/PortalOperationalDocuments",
      "FilePath": filepath.replace(/ /g, "%20")
    }

    this.nynasUpdateService.getSharepointDoc(sharepointData)
      .subscribe(
        res => {
          if (res["ResponseCode"] == "200") {
            pdfData = res["EncodedFileData"]
            const byteArray = new Uint8Array(atob(pdfData).split('').map(char => char.charCodeAt(0)));
            const a = document.createElement('a');
            const blob = new Blob([byteArray], { type: 'text/csv' });

            if (this.checkIfBrowserIsIE()) {
              window.navigator.msSaveOrOpenBlob(blob, (customerName + '_' + year + '_' + month + '.csv'));
            } else {
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = customerName + '_' + year + '_' + month + '.csv';
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();
            }
            
          } else {
            this.openDialog('Sorry! No file available.', null, false, 'no-file-available');
          }
        });
  }

  openDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,
      },
      height: '35%',
      width: (this.mobileQuery.matches ? '50%' : '25%'),
    });
  }

  async getCustomers() {
    this.Customers = [];
    return new Promise<void>((resolve, reject) => {
      this.forecastEntryService.getCustomerNames(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.Customers = res["Customers"]
            if (this.userInfo) {
              this.customerIdUserSelected = (this.userInfo.RoleID === "PA") ? '' : (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
            } else {
              this.customerIdUserSelected = '';
            }
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }
 
  checkIfBrowserIsIE(): boolean {
    var isBrowserIE = false;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) { // If Internet Explorer browser
      isBrowserIE = true;
    }
    return isBrowserIE;
  }

  sortData(sort: Sort) {
    let sortedData = this.dataSource.data.sort((row1: any, row2: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active === 'Month') {
        // Apply custom sorting for months, months converted into date format and then sorted, eg., JAN => 01-JAN-YYYY
        return compare(row1[sort.active] ? (new Date('01-' + row1[sort.active] + this.yearUserSelected.toString()).getMonth() + 1) : 0, row2[sort.active] ? (new Date('01-' + row2[sort.active] + this.yearUserSelected.toString()).getMonth() + 1) : 0, isAsc);
      } else if (sort.active === 'YEAR') {
        // Apply sorting for numeric rows of year
        return compare(row1[sort.active] ? +row1[sort.active] : 0, row2[sort.active] ? +row2[sort.active] : 0, isAsc);
      }
      // Apply sorting for remaining text rows
      return compare(row1[sort.active] ? row1[sort.active].toUpperCase() : '', row2[sort.active] ? row2[sort.active].toUpperCase() : '', isAsc);
    });

    // Update table dataSource with the sorted records
    this.dataSource = new MatTableDataSource(sortedData);
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
