<div class="container-fluid pad-left-0">
    <div class="col-lg-12 col-md-12">
        <div class="row">
            <div class="col-2 pt-2">
                <mat-button-toggle-group class="button-group-style toggle_style" name="fontStyle"
                    aria-label="Font Style" #group="matButtonToggleGroup" [(ngModel)]="currentToggleMenu"
                    value="product_document">
                    <mat-button-toggle value="product_document" ngDefaultControl
                        (click)="toggleButtonClick('product_document')">
                        {{'HOME.ProductDocuments'| translate}}

                    </mat-button-toggle>
                    <mat-button-toggle value="nynas_updates" (click)="toggleButtonClick('nynas_updates')">
                        {{'HOME.NynasUpdate'| translate}}

                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>


        <form [formGroup]="productForm">
            <div class="row">

                <div class="col-md-5"></div>
                <div class="col-md-4 pl-5 pt-1 pb-2"></div>

                <div class="col-md-12 pt-1" [hidden]='currentToggleMenu === "nynas_updates"'>
                    <div class="form-group">
                        <label for="product">
                            <span class="input-text-header"> {{'HOME.Product'| translate}}
                            </span>
                            <span class="mandatory-field">*</span>
                        </label>

                        <select class="form-control product_input_mv input-text addPriceIndexSelect product-dropdown-width"
                            formControlName="PRODUCT_ID" id="product" (change)="changeProductName()">
                            <option value=""> {{'HOME.SelectProduct'| translate}}
                            </option>
                            <!-- <option *ngFor="let product of productList" [value]="product">
                                {{product}}
                            </option> -->
                            <option *ngFor="let product of productList" [value]="product.PRODUCT_ID">
                                {{product.PRODUCT_NAME}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-6" [hidden]='currentToggleMenu === "nynas_updates"'>

                    <!-- Commented below code since product documents are now fetched from SalesForce, code kept for future reference -->
                    <!-- <div class="form-group">
                        <label for="PRODUCT_INFO">
                            <span class="input-text-header"> {{'HOME.ProductDatasheet'| translate}}
                            </span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="PRODUCT_INFO"
                            id="PRODUCT_INFO">

                    </div>

                    <div class="form-group">
                        <label for="SAFETY_DATA">
                            <span class="input-text-header"> {{'HOME.SafetyData'| translate}}
                            </span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="SAFETY_DATA"
                            id="SAFETY_DATA">
                    </div>

                    <div class="form-group">
                        <label for="CE_MARK">
                            <span class="input-text-header">{{'HOME.CE-Mark'| translate}}</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="CE_MARK" id="CE_Mark">
                    </div>

                    <div class="form-group">
                        <label for="PERFORMANCE_REPORT">
                            <span class="input-text-header">{{'HOME.PerformanceReport'| translate}}</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="PERFORMANCE_REPORT"
                            id="Performance_Report">
                    </div> -->

                    <div class="row no-gutters">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="year">
                                    <span class="input-text-header">{{'HOME.Qualityc'| translate}}</span>
                                    <span class="mandatory-field">&nbsp;*</span>
                                </label>

                                <select class="form-control input-text addPriceIndexSelect year-dropdown-width"
                                    formControlName="Year" id="Year" (change)="changeYear()"
                                    [(ngModel)]="selectedYear">
                                    <option value="">{{'HOME.SelectYear'| translate}}</option>
                                    <option *ngFor="let year of yearList" [value]="year">
                                        {{year}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-8">
                            <div class="form-group">
                        
                                <!-- Commented below code since product documents are now fetched from SalesForce, code kept for future reference -->
                                <!-- <label for="QualityCertificate">
                                    <span *ngIf="selectedYear===''">&nbsp;</span>
                                    <span class="mandatory-field pl-1" *ngIf="selectedYear!=''">
                                        *
                                    </span>
                                </label> -->
                        
                                <!-- Placholder for aligning Quality Certificate text box -->
                                <label>
                                    <span>&nbsp;</span>
                                </label>
                        
                                <input type="text" class="form-control input-text" formControlName="QualityCertificate" id="QualityCertificate">
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </form>



        <div class="row pl-1 pt-5" [hidden]='currentToggleMenu === "nynas_updates"'>
            <div class="col-sm saveBtn_div_mv">
                <button id="btn-save" class="btn btn-sm btn-primary add-price-buttons saveBtn"
                    (click)="saveProduct()">{{'HOME.Save'| translate}}</button>
            </div>

            <div class="col-sm">
                <button id="btn-cancel" name="cancel" class="btn btn-sm btn-primary add-price-buttons cancelBtn"
                    (click)="resetProduct()">{{'HOME.Reset'| translate}}</button>
            </div>
        </div>





        <div class="container">
            <form [formGroup]="nynasUpdateForm">
                <div class="row">
                    <div class="col-md-2" [hidden]='currentToggleMenu === "product_document"'>
                        <div class="form-group">
                            <label for="NYNAS_UPDATE_DATE">
                                <span class="input-text-header">{{'HOME.Date'| translate}}</span>
                                <span class="mandatory-field pl-1">*</span>
                            </label>

                            <!-- <input type="text" class="form-control input-text" formControlName="Date" id="Date"> -->
                            <mat-form-field [ngClass]="{'disabled-date-picker': isPriceIndexFormEdit}"
                                class="datepicker">
                                <input matInput [matDatepicker]="picker" placeholder=""
                                    formControlName="NYNAS_UPDATE_DATE">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>                 
                            <div *ngIf="submitted && nynasUpdateFormFields.NYNAS_UPDATE_DATE.errors" class="date-isinvalid">
                                <div *ngIf="nynasUpdateFormFields.NYNAS_UPDATE_DATE.errors.required && !nynasUpdateFormFields.NYNAS_UPDATE_DATE.errors.matDatepickerParse">
                                    {{'HOME.Dateisrequired'| translate}}
                                </div>
                                <div *ngIf="nynasUpdateFormFields.NYNAS_UPDATE_DATE.errors.required && nynasUpdateFormFields.NYNAS_UPDATE_DATE.errors.matDatepickerParse">
                                    {{'HOME.Dateisinvalid'| translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-2" [hidden]='currentToggleMenu === "product_document"'>
                            <div class="form-group">
                                <label for="STORAGE_TYPE">
                                    <span class="input-text-header">Source</span> <span class="mandatory-field pl-1">*</span>
                                </label>
                                <div class="col-lg-3 col-md-3 nynas_updates_dropdown" style="padding:0px">
                                    <select class="selectDropdown form-control" formControlName="STORAGE_TYPE" id="STORAGE_TYPE">
                                        <option value="">Select</option>
                                        <option *ngFor="let selectStorage of selectStorageType" [value]="selectStorage">
                                            {{ selectStorage }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div> -->
                    <div class="col-md-2" [hidden]='currentToggleMenu === "product_document"'>
                        <div class="form-group">
                            <label for="TYPE">
                                <span class="input-text-header">{{'HOME.Type'| translate}}</span> <span
                                    class="mandatory-field pl-1">*</span>
                            </label>
                            <!-- <input type="text" class="form-control input-text" formControlName="TYPE" id="TYPE"> -->


                            <div class="col-lg-3 col-md-3 nynas_updates_dropdown" style="padding:0px">
                                <select class="selectDropdown form-control" formControlName="TYPE" id="TYPE" [attr.disabled]="isPriceIndexFormEdit">
                                    <option value="">{{'HOME.Selecttype'| translate}}</option>
                                    <option *ngFor="let select of selectType" [value]="select">
                                        <!-- {{ select }} -->
                                        {{ 'HOME.' + getStringWithoutSpaces(select) | translate }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4" [hidden]='currentToggleMenu === "product_document"'>
                        <div class="form-group">
                            <label for="TITLE">
                                <span class="input-text-header">{{'HOME.Title'| translate}}</span>
                            </label>
                            <input type="text" class="form-control input-text" formControlName="TITLE" id="TITLE">
                        </div>
                    </div>

                    <div class="col-md-4" [hidden]='currentToggleMenu === "product_document"'>
                        <div class="form-group">
                            <label for="MESSAGE">
                                <span class="input-text-header">{{'HOME.Message'| translate}}</span> <span
                                    class="mandatory-field pl-1">*</span>
                            </label>
                            <input type="text" class="form-control input-text" formControlName="MESSAGE" id="MESSAGE">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6" [hidden]='currentToggleMenu === "product_document"'>
                        <div class="form-group">
                            <label for="DOUCUMENT_URL">
                                <span class="input-text-header"> {{'HOME.DocumentURL'| translate}}
                                </span>
                            </label>
                            <input type="text" class="form-control input-text" formControlName="DOUCUMENT_URL"
                                id="DOUCUMENT_URL">
                        </div>
                    </div>
                    <div class="col-md-6" [hidden]='currentToggleMenu === "product_document"'>
                        <div class="form-group">
                            <label for="source">
                                <span class="input-text-header">Source</span><span class="mandatory-field pl-1">*</span>
                            </label>
                            <div>
                                <input type="radio" formControlName="STORAGE_TYPE" id="radio-sharepoint" value="Sharepoint" />
                                <span class="radio-button-text" for="Sharepoint">Sharepoint</span>
                            
                                <input type="radio" formControlName="STORAGE_TYPE" id="radio-external" value="External" class="ml-4" />
                                <span class="radio-button-text" for="External">{{'HOME.External'| translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>


            <div class="row pl-1 pt-2" [hidden]='currentToggleMenu === "product_document"'>
                <div class="col-sm">
                    <button id="btn-save1" class="btn btn-sm btn-primary add-price-buttons saveBtn"
                        (click)="saveNynasUpdates()"> {{'HOME.Save'| translate}}
                    </button>
                </div>

                <div class="col-sm">
                    <button id="btn-cancel1" name="cancel" class="btn btn-sm btn-primary add-price-buttons cancelBtn"
                        (click)="resetNynasUpdate()"> {{'HOME.Reset'| translate}}
                    </button>
                </div>
            </div>

            <div class="col-md-12  " [hidden]='currentToggleMenu === "product_document"' style="padding:0px">
                <div class="horizontal_scrollbar_div">
                    <table mat-table #table [dataSource]="dataSource" matSort
                        class=" horizontal_scrollbar table-material ">

                        <ng-container matColumnDef="NYNAS_UPDATE_DATE">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                [ngClass]="'mat-table-column-header mat-column-Date1 mat-table-column-header-text product_MV'">
                                {{'HOME.Date'| translate}}
                            </mat-header-cell>
                            <!-- <mat-cell *matCellDef="let element" [ngClass]="'mat-column-Date mat-table-column-row-text product_MV'">
                            {{element.NYNAS_UPDATE_DATE}}</mat-cell> -->

                            <mat-cell *matCellDef="let element"
                                [ngClass]="'mat-column-Date mat-table-column-row-text product_MV'">
                                {{ getDateObject(element.NYNAS_UPDATE_DATE)['date'] }}-{{'HOME.' + this.getDateObject(element.NYNAS_UPDATE_DATE)['month'] | translate}}-{{ getDateObject(element.NYNAS_UPDATE_DATE)['year'] }}
                            </mat-cell>

                        </ng-container>

                        <ng-container matColumnDef="TYPE">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                [ngClass]="'mat-table-column-header mat-column-Type1 mat-table-column-header-text depot_MV'">
                                {{'HOME.Type'| translate}}
                            </mat-header-cell>>
                            <mat-cell *matCellDef="let element" [ngClass]="'mat-column-Type1 mat-table-column-row-text depot_MV'">
                                <!-- {{ element.TYPE }}  -->
                                {{ 'HOME.' + getStringWithoutSpaces(element.TYPE) | translate }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="TITLE">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                [ngClass]="'mat-table-column-header mat-column-Title1 mat-table-column-header-text av_from_MV'">
                                {{'HOME.Title'| translate}}</mat-header-cell>>
                            <!-- <th mat-header-cell *matHeaderCellDef> CustomerName </th> -->
                            <mat-cell *matCellDef="let element"
                                [ngClass]="'mat-column-Title1 mat-table-column-row-text av_from_MV'">
                                {{ element.TITLE }} </mat-cell>>
                        </ng-container>

                        <!-- mat-sort-header -->
                        <ng-container matColumnDef="MESSAGE">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                [ngClass]="'mat-table-column-header mat-column-user-Message1 mat-table-column-header-text av_from_MV'">
                                {{'HOME.Message'| translate}} </mat-header-cell>>
                            <!-- <th mat-header-cell *matHeaderCellDef> UserRole </th> -->
                            <mat-cell *matCellDef="let element"
                                [ngClass]="'mat-column-user-Message mat-table-column-row-text av_from_MV'">
                                {{element.MESSAGE}} </mat-cell>>
                        </ng-container>

                        <ng-container matColumnDef="Download">
                            <mat-header-cell *matHeaderCellDef
                                [ngClass]="'mat-table-column-header mat-column-user-Download1 mat-table-column-header-text av_from_MV'">
                            </mat-header-cell>
                            <!-- <th mat-header-cell *matHeaderCellDef> UserRole </th> -->
                            <mat-cell *matCellDef="let element"
                                [ngClass]="'mat-column-user-Download mat-table-column-row-text download-button av_from_MV'">
                                <a class='pdf-content'
                                    (click)='downloadPdf(element.DOUCUMENT_URL, element.STORAGE_TYPE)'>
                                    <ng-container *ngIf='element.DOUCUMENT_URL!=""'><span class='font-vsmall font-bold'>
                                            {{'HOME.View'| translate}}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf='element.DOUCUMENT_URL==""'><span class='font-vsmall font-bold'>
                                            NA
                                        </span>
                                    </ng-container>
                                </a>
                            </mat-cell>>
                        </ng-container>


                        <ng-container matColumnDef="Edit">
                            <mat-header-cell
                                [ngClass]="'mat-table-column-header mat-column-user-Download1 mat-table-column-header-text av_from_MV'"
                                *matHeaderCellDef> {{'HOME.Edit'| translate}}


                            </mat-header-cell>>
                            <mat-cell
                                [ngClass]="'mat-column-user-Download mat-table-column-row-text download-button av_from_MV'"
                                *matCellDef="let row" (click)='selectRow(row)'>
                                <span class='active-icon' title="{{'HOME.Edit'| translate}}">
                                    <i class="far fa-edit" aria-hidden="true"></i>
                                </span>
                            </mat-cell>>
                        </ng-container>

                        <ng-container matColumnDef="Delete">
                            <mat-header-cell
                                [ngClass]="'mat-table-column-header mat-column-user-Download1 mat-table-column-header-text av_from_MV'"
                                *matHeaderCellDef>{{'HOME.Delete'| translate}}


                            </mat-header-cell>>
                            <mat-cell [ngClass]="'mat-column-user-Download mat-table-column-row-text download-button av_from_MV'"
                                *matCellDef="let row" (click)='deleteNynasUpdate(row)'>
                                <span class='active-icon' title="{{'HOME.Delete'| translate}}">
                                    <i class="fas fa-trash" aria-hidden="true"></i>
                                </span>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                        </mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                    </table>
                </div>
                <mat-paginator   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"   showFirstLastButtons></mat-paginator>
            </div>



        </div>



    </div>
</div>