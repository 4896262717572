<div class="container" *ngIf="isLoggedInUserPortalAdmin">
    <div class="row pt-1 pb-1 no-gutters">
        <!-- <div class="col-md-12"> -->
            <label class="company-name-label" for="companyName"> {{'HOME.CompanyName'| translate}} : </label>
                <select class="company-name-dropdown  form-control"
                    [(ngModel)]="selectedCompanyId" title=" {{'HOME.manage-user-title1'| translate}} ">
                    <option value="">{{'HOME.All'| translate}}</option>
                    <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                        {{customer.CustomerName}}
                    </option>
                </select>
                <span class="pl-3">
                    <button type="button" class="btn filter-button" for="filter"
                        (click)='changeCustomerName()'>
                        <label class="font-bold font-small" for="filter"> {{'HOME.Find'| translate}}</label></button>
                </span>
                
            <!-- </span> -->
            <!-- <span *ngIf="!isLoggedInUserPortalAdmin" class="pl-3">
                <span class="selected-company-name-text">{{ selectedCompanyName }}</span>
            </span> -->
        <!-- </div> -->
    </div>
    
</div>
<div class="global-search-text-box">
    <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
</div>
<div class="container-fluid pt-1 table-container">
    <div class="row">
        <div class="col-sm">                
            <div [hidden]="(!isRequestCompleted || (dataSource.data && dataSource.data.length))" class="no-records-message pt-3 pl-2"> {{'HOME.Norecordsfound'| translate}}
            </div>
            <div [hidden]="(!isRequestCompleted || (!dataSource.data || (dataSource.data && !dataSource.data.length)))" class="">
                <div class= "horizontal_scrollbar_div">
                <table mat-table [dataSource]="dataSource" class="horizontal_scrollbar" matSort>
                    <ng-container matColumnDef="UserPhoto">
                        <th mat-header-cell *matHeaderCellDef
                            [ngClass]="'mat-table-column-header mat-column-user-photo'"> </th>
                        <td mat-cell *matCellDef="let element"
                            [ngClass]="'mat-column-user-photo mat-table-column-user-photo-icon'">
                            <span *ngIf="element.imageUrl">
                                <img class="manage-user-image" src={{element.imageUrl}} />
                            </span>
                            <span class="manage-user-image" *ngIf="!element.imageUrl">
                                <i class="fa fa-user-circle" aria-hidden="true"></i> 
                            </span>
 
                            <!-- <i class="fa fa-user-circle" aria-hidden="true"></i> &nbsp;&nbsp; -->
                            <!-- <span id="imagePreview" [style.backgroundImage]="'url('+ test +')'">
                                <i class="fa fa-user-circle" aria-hidden="true"></i>
                                <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'"
                                (click)="fileInput.click()"> 
                                </div>
                            </span> &nbsp;&nbsp; -->
                            <!-- <div id="imagePreview" [style.backgroundImage]="'url(' + element.imageUrl + ')'">
                            </div> &nbsp;&nbsp; -->
                            <!-- <img src="{data:image/gif;base64,element.Photo }" 
                                [ngStyle]="{'background-image': 'url(' + post.avatar + ')', 'background-size': 'cover'  }"/> -->
                        </td>
                    </ng-container>
 
                    <!-- <table mat-table [dataSource]="dataSource"> -->
                    <ng-container matColumnDef="FirstName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [ngClass]="'mat-table-column-header mat-column-name mat-table-column-header-text'">  {{'HOME.Name'| translate}}
                        </th>
                        <!-- <th mat-header-cell *matHeaderCellDef> UserID </th>  -->
                        <td mat-cell *matCellDef="let element" [ngClass]="'mat-column-name mat-table-column-row-text'">
                            {{element.FirstName}} {{element.LastName}}</td>
                    </ng-container>
 
                    <ng-container matColumnDef="EmailID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [ngClass]="'mat-table-column-header mat-column-email mat-table-column-header-text'">  {{'HOME.Email'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="'mat-column-email mat-table-column-row-text'">
                            {{element.EmailID}}</td>
                    </ng-container>
 
                    <ng-container matColumnDef="userGroupName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [ngClass]="'mat-table-column-header mat-column-group mat-table-column-header-text'">  {{'HOME.Group'| translate}}
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="'mat-column-group mat-table-column-row-text'">
                            {{ element.userGroupName }} </td>
                    </ng-container>
 
                    <ng-container matColumnDef="customerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [ngClass]="'mat-table-column-header mat-column-customer-name mat-table-column-header-text'">
                            {{'HOME.CompanyName'| translate}} </th>
                        <!-- <th mat-header-cell *matHeaderCellDef> CustomerName </th> -->
                        <td mat-cell *matCellDef="let element"
                            [ngClass]="'mat-column-customer-name mat-table-column-row-text'">
                            {{ element.customerName }} </td>
                    </ng-container>
 
                    <ng-container matColumnDef="Role">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [ngClass]="'mat-table-column-header mat-column-user-role mat-table-column-header-text'">
                            {{'HOME.Role'| translate}} </th>
                        <!-- <th mat-header-cell *matHeaderCellDef> UserRole </th> -->
                        <!-- <td mat-cell *matCellDef="let element"
                                                [ngClass]="'mat-column-user-role mat-table-column-row-text'">
                                                {{element.Role}} </td> -->
                        <td mat-cell *matCellDef="let element" [ngClass]="'mat-column-user-role mat-table-column-row-text'">
                            {{'HOME.'+getStringWithoutSpaces(element.Role) | translate }}</td>
                    </ng-container>
 
                    <ng-container matColumnDef="UserStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [ngClass]="'mat-table-column-header mat-column-user-status mat-table-column-header-text'">
                            {{'HOME.Status'| translate}} </th>
                        <!-- <th mat-header-cell *matHeaderCellDef> UserStatus </th> -->
                        <!-- <td mat-cell *matCellDef="let element" [ngClass]="'mat-column-user-status mat-table-column-row-text mat-table-user-status-active'"> {{element.UserStatus}} </td> -->
                        <!-- <td mat-cell *matCellDef="let element"
                                                [ngClass]="{'mat-column-user-status': true, 'mat-table-column-row-text': true, 'mat-table-user-status-active': element.UserStatus === 'Active', 'mat-table-user-status-inactive': element.UserStatus !== 'Active'}">
                                                {{element.UserStatus}} </td> -->
                        <td mat-cell *matCellDef="let element"
                            [ngClass]="{'mat-column-user-status': true, 'mat-table-column-row-text': true, 'mat-table-user-status-active': element.UserStatus === 'Active', 'mat-table-user-status-inactive': element.UserStatus !== 'Active'}">
                            {{'HOME.'+ element.UserStatus | translate }}</td>
                    </ng-container>
 
                    <ng-container matColumnDef="UpdatedOn">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [ngClass]="'mat-table-column-header mat-column-last-modified-time mat-table-column-header-text'">
                            {{'HOME.Modify'| translate}} </th>
                        <!-- <th mat-header-cell *matHeaderCellDef> LastModifiedTime </th> -->
                        <td mat-cell *matCellDef="let element"
                            [ngClass]="'mat-column-last-modified-time mat-table-column-row-text'">
                            {{element.UpdatedOn | date: 'MM/dd/yyyy' }} </td>
                    </ng-container>
 
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef [ngClass]="'mat-table-column-header mat-column-actions'">
                        </th>
                        <!-- <th mat-header-cell *matHeaderCellDef> LastModifiedTime </th> -->
                        <td mat-cell *matCellDef="let element" [ngClass]="'mat-column-actions'">
                            <span (click)='resetPassword(element)' class="mat-table-actions-padding"
                                title=" {{'HOME.ResetPassword'| translate}}">
                                <span class='active-icon-color'><i class="fas fa-key"></i></span>
                                <!-- <img src="../../../assets/key-icon.png" class="mat-table-action-icons"> -->
                            </span>
                            <span *ngIf="element.UserStatus === 'Active'" (click)='updateUserStatus(element, "Deactive")' title=" {{'HOME.Deactivateuser'| translate}}"
                                class="mat-table-actions-padding active-icon-color">
                                <i class="fas fa-toggle-on"></i>
                            </span>
                            <span *ngIf="element.UserStatus !== 'Active'" (click)='updateUserStatus(element, "Active")' title="{{'HOME.activateuser'| translate}}"
                                class="mat-table-actions-padding active-icon-color">
                                <i class="fas fa-toggle-off"></i>
                            </span>
                            <span *ngIf="element.UserStatus === 'Active-Locked'"
                                (click)='lockUnlockUser(element)' title="{{'HOME.Unlockuser'| translate}}" class="mat-table-actions-padding">
                                <span class='mat-table-user-status-inactive'><i class="fa fa-lock"
                                        aria-hidden="true"></i></span>
                                <!-- <img src="../../../assets/lock-icon.png" class="mat-table-action-icons mat-table-user-status-inactive"> -->
                            </span>
                            <span *ngIf="element.UserStatus !== 'Active-Locked'"
                                class="mat-table-actions-padding">
                                <span class='inactive-icon-color'><i class="fa fa-unlock-alt "
                                        aria-hidden="true"></i></span>
                                <!-- <img src="../../../assets/unlock-icon.png" class="mat-table-action-icons disable-click"> -->
                            </span>
                            <!-- <i class="fa fa-unlock-alt" aria-hidden="true"></i>&nbsp;&nbsp; -->
                            <!-- <span (click)='editUser(element)' class="mat-table-actions-padding" title="Edit User">
                                <span class='active-icon-color'> <i class="fa fa-cog " aria-hidden="true"></i></span>
                            </span> -->
                            <span (click)='editUser(element)' class="mat-table-actions-padding" title=" {{'HOME.EditUser'| translate}}
                            ">
                                <span class='active-icon-color'> <i class="fa fa-cog " aria-hidden="true"></i></span>
                            </span>
                        </td>
                    </ng-container>
 
                    <!-- <ng-container matColumnDef="details">
                      <th mat-header-cell *matHeaderCellDef> Details </th>
                      <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" (click)="redirectToDetails(element.id)">
                            <mat-icon class="mat-18">reorder</mat-icon>
                        </button>
                      </td>
                    </ng-container> -->
 
                    <!-- <ng-container matColumnDef="update">
                        <th mat-header-cell *matHeaderCellDef> Update </th>
                        <td mat-cell *matCellDef="let element">
                          <button mat-icon-button color="accent" (click)="redirectToUpdate(element.id)">
                              <mat-icon class="mat-18">system_update</mat-icon>
                          </button>
                        </td>
                      </ng-container>
 
                      <ng-container matColumnDef="delete">
                          <th mat-header-cell *matHeaderCellDef> Delete </th>
                          <td mat-cell *matCellDef="let element">
                            <button mat-icon-button color="warn" (click)="redirectToDelete(element.id)">
                                <mat-icon class="mat-18">delete</mat-icon>
                            </button>
                          </td>
                        </ng-container> -->
 
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            
                <mat-paginator [length]="50" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"  showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>  
</div>