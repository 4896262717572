import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { CustomerContractService } from 'src/app/customer-contract/customer-contract.service';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';
import { PendingForecastService } from 'src/app/order-management/pending-forecast/pending-forecast.component.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import * as XLSX from 'xlsx';
import { DistributionDataFilterPipe } from '../../shared-modules/pipes/distribution-data-filter.pipe';
import { DeliveryGuideService } from './delivery-guide.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-delivery-guide',
  templateUrl: './delivery-guide.component.html',
  styleUrls: ['./delivery-guide.component.css']
})
export class DeliveryGuideComponent implements OnInit {
  productTypeToSearch: any;
  productSubTypeToSearch: any;
  depotLocationToSearch: any;
  deliveryGuideData: any;
  deliveryGuideDataCopy: any;
  productType: any;
  productSubType: any;
  depotLocation: any;
  distributionData: any;
  tableColumns: any;
  countrySearch: any;
  countryList: any;
  dataSource: any;
  displayTable: boolean = true;
  fetchedDataForItemArray = [];
  excelData: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  userInfo: any;
  countryCode: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  customerId: string;
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;

  constructor(private filterData: DistributionDataFilterPipe,
    private deliveryGuideService: DeliveryGuideService,
    private sharedService: AppService,
    private translate: TranslateService,
    private pendingForecastService: PendingForecastService,
    private customerContractService: CustomerContractService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private dialog: MatDialog,
		private globalSearchService: GlobalSearchService) {
    this.userInfo = this.sharedService.getLoginResponse();
    if (this.userInfo) {
      this.countryCode = (this.userInfo.CountryCode ? this.userInfo.CountryCode : '');
      this.customerId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }
  }

  async ngOnInit() {

    this.tableColumns = ['PRODUCT_NAME', 'PRODUCT_TYPE', 'PRODUCT_SUB_TYPE', 'DEPOT_NAME', 'FROM_DATE', 'TO_DATE'];

    try {
      await this.getCountry();
    }
    catch (e) {
    }

    try {
      await this.getProductDropdownData();
    }
    catch (e) {
    }

    try {
      await this.getDepotDetails();
    }
    catch (e) {
    }

    try {
      await this.getDeliveryGuideDetails();
    }
    catch (e) {
    }

    this.translate.get(['HOME.itemsPerPageLabel'])
    .subscribe(data => {
      this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
    });

    this.subscribeToGlobalSearchServiceSearchTerm();

  }
	  
	ngOnDestroy(): void {
    this.globalSearchText = '';
	}

  async getCountry() {
    this.countryList = [];
    return new Promise<void>((_resolve, _reject) => {
      this.deliveryGuideService.getCountries().subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.countryList = res["Countries"];
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

  async getProductDropdownData() {
    return new Promise<void>((_resolve, _reject) => {
      this.deliveryGuideService.getDeliveryDropdownData()
        .subscribe(
          response => {
            if (response["ResponseCode"] == 200) {
              let productTypesWithAll = response['PRODUCT_TYPES'];
              productTypesWithAll.shift(); // Remove word 'All' using shift method, for translation
              this.productType = productTypesWithAll;
              let prodSubTypeWithAll = response['PRODUCT_SUBTYPES'];
              prodSubTypeWithAll.shift(); // Remove word 'All' using shift method, for translation
              this.productSubType = prodSubTypeWithAll;
              this.productTypeToSearch = '';
              this.productSubTypeToSearch = '';
              _resolve();
            }
            else {
              _reject();
            }
          });
    });
  }

  downloadDeliveryGuideData() {
    // this.exportAsExcelFile(this.excelData, "DeliveryGuide");

    let productType = this.productTypeToSearch, productCategory = this.productSubTypeToSearch, depot = this.depotLocationToSearch;
    this.deliveryGuideData = [];
    this.deliveryGuideDataCopy = [];
    let isAvailable: string = 'YES';
    return new Promise<void>((_resolve, _reject) => {
      this.deliveryGuideService.getDeliveryGuide(this.countryCode, isAvailable)
        .subscribe(
          response => {
            if (response && response["ResponseCode"] == 200) {
              let deliveryGuideList = [];
              let finlandListOutDatedProductIds: any = ['67045', '61972', '25475'];

              // Commented old code, kept for future reference
              /* for (let deliveryGuide of response['GetDeliveryGuideResponses']) {
                // INC0131525 - Product Nyspec 190 Peab FI (Short item number 66405) will be shown in delivery guide only for Peab customer
                if (this.countryCode !== 'FIN' || (this.countryCode === 'FIN' && this.customerId === '267359') || (this.countryCode === 'FIN' && this.customerId !== '267359' && deliveryGuide['PRODUCT_ID'] !== '66405')) {
                  if ((productType === "" || productType === deliveryGuide['PRODUCT_TYPE']) &&
                    (productCategory === "" || productCategory === deliveryGuide['PRODUCT_SUB_TYPE']) &&
                    (depot === "" || depot === deliveryGuide['DEPOT_NAME'])) {
                    deliveryGuideList.push(deliveryGuide);
                  }
                }
              } */

              if (response['GetDeliveryGuideResponses'] && response['GetDeliveryGuideResponses'].length) {
                for (let deliveryGuide of response['GetDeliveryGuideResponses']) {

                  // INC0131525 - Product Nyspec 190 Peab FI (Short item number 66405) will be shown in delivery guide only for Peab customer
                  if (this.countryCode !== 'FIN' || (this.countryCode === 'FIN' && this.customerId === '267359') || (this.countryCode === 'FIN' && this.customerId !== '267359' && deliveryGuide['PRODUCT_ID'] !== '66405')) {

                    // INC0151321 - Products Nytop 60, Nytop 210 & Nyspec 50/70-IND are no longer sold in Finland, they can be deleted from the delivery guide page.
                    if (this.countryCode !== 'FIN' || (this.countryCode === 'FIN' && !finlandListOutDatedProductIds.includes(deliveryGuide['PRODUCT_ID']))) {
                      if ((productType === "" || productType === deliveryGuide['PRODUCT_TYPE']) &&
                        (productCategory === "" || productCategory === deliveryGuide['PRODUCT_SUB_TYPE']) &&
                        (depot === "" || depot === deliveryGuide['DEPOT_NAME'])) {
                        let deliveryGuideObject = {};
                        deliveryGuideObject['Product name'] = deliveryGuide['PRODUCT_NAME'];
                        deliveryGuideObject['Product type'] = deliveryGuide['PRODUCT_TYPE'];
                        deliveryGuideObject['Product category'] = deliveryGuide['PRODUCT_SUB_TYPE'];
                        deliveryGuideObject['Depot name'] = deliveryGuide['DEPOT_NAME'];
                        deliveryGuideObject['Available from'] = deliveryGuide['FROM_DATE'];
                        deliveryGuideObject['Available to'] = deliveryGuide['TO_DATE'];
                        deliveryGuideList.push(deliveryGuideObject);
                      }
                    }
                  }
                }
              }

              _resolve();

              // Commented old code, kept for future reference
              // this.exportAsExcelFile(deliveryGuideList, "DeliveryGuide");

              let headerList = ["Product name", "Product type", "Product category", "Depot name", "Available from", "Available to"];

              this.sharedService.downloadDataInExcelFile(deliveryGuideList, 'DeliveryGuide', headerList);
            } else {
              _reject();
              this.showStatusDialog(false, 'Something-went-wrong');
            }
          });
    });
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json),
        { header: [] });

      const workbook: XLSX.WorkBook = { Sheets: { 'Data in excel': worksheet }, SheetNames: ['Data in excel'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const ForecastStatus: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(ForecastStatus, fileName + this.sharedService.getCurrentDateTime().getTime() + EXCEL_EXTENSION);
  }

  // filter data based on Ui select dropdown 
  filterDeliveryGuide(value) {
    this.deliveryGuideDataCopy = this.filterData.transform(value, (this.productTypeToSearch ? this.productTypeToSearch.trim() : this.productTypeToSearch), (this.productSubTypeToSearch ? this.productSubTypeToSearch.trim() : this.productSubTypeToSearch), this.depotLocationToSearch);
    this.dataSource = new MatTableDataSource(this.deliveryGuideDataCopy);
    this.excelData = this.deliveryGuideDataCopy;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this.displayTable = true;
  }
  getTableCSS(data) {
    let flag = true;
    data.tabledata.forEach(element => {
      if (element.productSubType == 'Regular') {
        flag = false;
      }
    });

    if (flag) { return 'extra-color'; } else { return 'regular-color'; }
  }

  getDateObject(date: string): any {
    let dateObject = {};
    if (date) {
      let dateArray = date.split('-');
      if (dateArray && dateArray.length && dateArray[0] && dateArray[1] && dateArray[2]) {
        dateObject['date'] = dateArray[0] ? dateArray[0] : '';
        dateObject['month'] = dateArray[1] ? dateArray[1].toUpperCase() : '';
        dateObject['year'] = dateArray[2] ? dateArray[2] : '';
      }

    }
    return dateObject;
  }

  async getDeliveryGuideDetails() {
    let productType = this.productTypeToSearch, productCategory = this.productSubTypeToSearch, depot = this.depotLocationToSearch;
    this.deliveryGuideData = [];
    this.deliveryGuideDataCopy = [];
    let isAvailable: string = 'YES';
    return new Promise<void>((_resolve, _reject) => {
      this.deliveryGuideService.getDeliveryGuide(this.countryCode, isAvailable)
        .subscribe(
          response => {
            if (response["ResponseCode"] == 200) {
              let deliveryGuideList = [];
              let finlandListOutDatedProductIds: any = ['67045', '61972', '25475'];
              for (let deliveryGuide of response['GetDeliveryGuideResponses']) {

                // INC0131525 - Product Nyspec 190 Peab FI (Short item number 66405) will be shown in delivery guide only for Peab customer
                if (this.countryCode !== 'FIN' || (this.countryCode === 'FIN' && this.customerId === '267359') || (this.countryCode === 'FIN' && this.customerId !== '267359' && deliveryGuide['PRODUCT_ID'] !== '66405')) {

                  // INC0151321 - Products Nytop 60, Nytop 210 & Nyspec 50/70-IND are no longer sold in Finland, they can be deleted from the delivery guide page.
                  if (this.countryCode !== 'FIN' || (this.countryCode === 'FIN' && !finlandListOutDatedProductIds.includes(deliveryGuide['PRODUCT_ID']))) {
                    if ((productType === "" || productType === deliveryGuide['PRODUCT_TYPE']) &&
                      (productCategory === "" || productCategory === deliveryGuide['PRODUCT_SUB_TYPE']) &&
                      (depot === "" || depot === deliveryGuide['DEPOT_NAME'])) {
                      deliveryGuideList.push(deliveryGuide);
                    }
                  }
                }
              }
              this.deliveryGuideData = deliveryGuideList;
              this.deliveryGuideDataCopy = this.deliveryGuideData;
              this.dataSource = new MatTableDataSource(this.deliveryGuideDataCopy);
              this.excelData = this.deliveryGuideDataCopy;
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
              this.displayTable = (deliveryGuideList && deliveryGuideList.length && deliveryGuideList.length > 0);
              _resolve();
            }
            else {
              this.displayTable = false;
              _reject();
            }
          });
    });
  }

  getDepotDetails() {
    this.depotLocation = [];
    this.depotLocationToSearch = '';
    let isAvailableDepot: string = 'YES';
    return new Promise<void>((_resolve, _reject) => {
      this.customerContractService.fetchDepotDetails(this.countryCode, isAvailableDepot)
        .subscribe(
          res => {
            if (res["ResponseCode"] == 200) {
              this.depotLocation = res["DepotCollection"];
              this.depotLocationToSearch = '';
              _resolve();
            }
            else {
              _reject();
            }
          });
    });
  }

  showStatusDialog(isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,

      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.changeDetectorRef.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.changeDetectorRef.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      // this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }


}