<div class="">

    <form [formGroup]="addDeliveryForm" (ngSubmit)="onSubmit()">
        <div class="container container-align">
            <div class="row pt-1 pb-1">
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="CustomerId">
                            <span class="input-text-header"> {{'HOME.Customername'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <div *ngIf="!this.selectedDeliverySite && this.isLoggedInUserPortalAdmin">
                            <select class="form-control input-text addUserSelect" formControlName="CustomerId"
                                (change)="onChange()"
                                [ngClass]="{ 'is-invalid': submitted && fields.CustomerId.errors }" id="CustomerId">
                                <option value=""> {{'HOME.Selectcustomername'| translate}}
                                </option>
                                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                                    {{customer.CustomerName}}
                                </option>
                            </select>
                            <div *ngIf="submitted && fields.CustomerId.errors" class="invalid-feedback">
                                <div *ngIf="fields.CustomerId.errors.required">
                                    {{'HOME.Customernameisrequired'| translate}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.selectedDeliverySite || !this.isLoggedInUserPortalAdmin">
                            <input type="text" class="form-control input-text" formControlName="selectedCustomerName"
                                [readonly]="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container container-align ">
            <div class="row pl-1 pt-2">

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <span class="group-header"> {{'HOME.DeliveryInformation'| translate}}
                    </span>
                </div>
                <div class="underline-data"></div>

            </div>



            <div class="row pl-1 pt-4">

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="delivery_site_name">
                            <span class="input-text-header"> {{'HOME.Deliverysites'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="DeliverySiteName"
                            [ngClass]="{ 'is-invalid': submitted && fields.DeliverySiteName.errors }" id="txtDeliverySiteName"
                            placeholder="{{'HOME.DeliverySiteName'| translate}}" [attr.disabled]="this.selectedDeliverySite"
                            (input)="checkDeliverySiteNameLength()" />
                        <div *ngIf="submitted && fields.DeliverySiteName.errors" class="invalid-feedback">
                            <div *ngIf="fields.DeliverySiteName.errors.required">
                                {{'HOME.Deliverysitenameisreq'| translate}}
                            </div>
                        </div>
                        <div *ngIf="isDeliverySiteNameMoreThan32Characters" class="delivery-site-name-error">
                            {{'HOME.delivery-site-name-maximum-length-error'| translate}}
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.BillingReference'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="BillingReference"
                            [ngClass]="{ 'is-invalid': submitted && fields.BillingReference.errors }"
                            id="BillingReference" placeholder="{{'HOME.BillingReference'| translate}}" />
                        <div *ngIf="submitted && fields.BillingReference.errors" class="invalid-feedback">
                            <div *ngIf="fields.BillingReference.errors.required">{{'HOME.Billingrefisreq'| translate}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4" *ngIf="isLoggedInUserPortalAdmin && countryCode !== 'GBR'">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.SendForecastReminder'| translate}}</span>
                        </label>
                        <input class="form-control send-forecast-reminder" type="checkbox" formControlName="SendReminderFlag">
                    </div>
                </div>

            </div>


            <div class="row pl-1 pt-2">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.PrimaryEmailAddress'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="SubscriberEmailAddress"
                            [ngClass]="{ 'is-invalid': submitted && fields.SubscriberEmailAddress.errors }"
                            id="txtSubscriberEmailAddress" placeholder="{{'HOME.PrimaryEmailAddress'| translate}}" />
                        <div *ngIf="submitted && fields.SubscriberEmailAddress.errors" class="invalid-feedback">
                            <div *ngIf="fields.SubscriberEmailAddress.errors.required">
                                {{'HOME.Emailisrequired'| translate}}
                            </div>
                            <div *ngIf="fields.SubscriberEmailAddress.errors.email">
                                {{'HOME.Emailmustbeavalidemailaddress'| translate}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="primary_contact">
                            <span class="input-text-header"> {{'HOME.Primarycontact'| translate}}</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>
                        <select class="form-control input-text addUserSelect"
                            formControlName="PrimaryContactCountryCode"
                            [ngClass]="{ 'is-invalid': submitted && fields.PrimaryContactCountryCode.errors }"
                            id="primaryContactCountryCode">
                            <option value=""> {{'HOME.Selectcode'| translate}}</option>
                            <option *ngFor="let country of Countries" [value]="country.id">
                                {{ country.id }} (+ {{ country.code }} )
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.PrimaryContactCountryCode.errors" class="invalid-feedback">
                            <div *ngIf="fields.PrimaryContactCountryCode.errors.required">
                                {{'HOME.Countrycodeisrequired'| translate}}</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="PrimaryContact">
                            <span>&nbsp;</span>
                        </label>
                        <input type="text" class="form-control input-text2" formControlName="PrimaryContact"
                            [ngClass]="{ 'is-invalid': submitted && fields.PrimaryContact.errors }" id="primary_contact"
                            placeholder="{{'HOME.Primarycontact'| translate}}" />
                        <div *ngIf="submitted && fields.PrimaryContact.errors" class="invalid-feedback">
                            <div *ngIf="fields.PrimaryContact.errors.required">
                                {{'HOME.Primarycontactisrequired'| translate}}</div>
                            <div *ngIf="fields.PrimaryContact.errors.pattern">
                                {{'HOME.Pleaseenternumbersonly'| translate}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row pl-1 pt-2">
                <div class="col-md-1 notify-haulier-div">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.NotifyHaulier'| translate}}
                            </span>
                        </label>
                        <input class="form-control checkbox-notify-haulier" type="checkbox" formControlName="NotifyHaulier"
                            (change)="updateNotifyHaulier()">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="delivery_site_name">
                            <span class="input-text-header"> {{'HOME.HaulierEmailId'| translate}}
                            </span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="HaulierEmailId"
                            [ngClass]="{ 'is-invalid': submitted && fields.HaulierEmailId.errors }" id="txtHaulierEmailId"
                            placeholder="{{'HOME.HaulierEmailId'| translate}}" [attr.disabled]="this.disableNotifyHaulier" />
                        <div *ngIf="submitted && fields.HaulierEmailId.errors" class="invalid-feedback">
                            <div *ngIf="fields.HaulierEmailId.errors.email">
                                {{'HOME.Emailmustbeavalidemailaddress'| translate}}
                            </div>
                        </div>
                        <div *ngIf="submitted && this.isHaulierEmailIdEmpty()" class="invalid-feedback-email-id">
                            {{'HOME.Emailisrequired'| translate}}
                        </div>
                    </div>
                </div>
                <div class="col-md-5" *ngIf="isLoggedInUserPortalAdmin">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.SecondaryHaulierEmailId'| translate}}
                            </span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="SecondaryHaulierEmailId"
                            [ngClass]="{ 'is-invalid': submitted && (this.isSecondaryHaulierEmailIdsInValid() || this.isSecondaryHaulierEmailIdsMoreThanFour()) }"
                            id="txtSecondaryHaulierEmailId" placeholder="{{'HOME.SecondaryHaulierEmailId'| translate}}"
                            [attr.disabled]="this.disableNotifyHaulier"
                            title="{{'HOME.SecondaryHaulierEmailIdHoverMessage'| translate}}" />
                        <div *ngIf="submitted && this.isSecondaryHaulierEmailIdsInValid()" class="invalid-feedback-email-id">
                            {{'HOME.Emailmustbeavalidemailaddress'| translate}}
                        </div>
                        <div *ngIf="submitted && this.isSecondaryHaulierEmailIdsMoreThanFour()" class="invalid-feedback-email-id">
                            {{'HOME.maximum-four-email-ids-allowed'| translate}}
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- CONTACT DETAILS -->
        <div class="container container-align">
            <div class="row pl-1 pt-2">
                <div class="col-sm">
                    <span class="group-header"> {{'HOME.Deliverysiteaddress'| translate}}
                    </span>
                </div>
                <div class="underline-data"></div>

            </div>
            <div class="row pl-1 pt-4">

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="address_line1">
                            <span class="input-text-header"> {{'HOME.Address'| translate}}</span>
                            <span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="AddressLine1"
                            [ngClass]="{ 'is-invalid': submitted && fields.AddressLine1.errors }" id="address_line1"
                            placeholder="{{'HOME.linereq'| translate}}" />
                        <div *ngIf="submitted && fields.AddressLine1.errors" class="invalid-feedback">
                            <div *ngIf="fields.AddressLine1.errors.required"> {{'HOME.AddressLinereq'| translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="address_line2">
                            <span>&nbsp;</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="AddressLine2"
                            id="address_line2" placeholder=" {{'HOME.linereq'| translate}}" />
                    </div>
                </div>
            </div>

            <div class="row pl-1 pt-2">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header">{{'HOME.DeliveryPostalCode'| translate}}</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="DeliveryPostalCode"
                            [ngClass]="{ 'is-invalid': submitted && fields.DeliveryPostalCode.errors }"
                            placeholder="{{'HOME.DeliveryPostalCode'| translate}}" />
                        <div *ngIf="submitted && fields.DeliveryPostalCode.errors" class="invalid-feedback">
                            <div *ngIf="fields.DeliveryPostalCode.errors.required">
                                {{'HOME.DeliveryPostalCodeIsrequired'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.City'| translate}}
                            </span><span class="mandatory-field pl-1">*</span>
                        </label>
                        <input type="text" class="form-control input-text" formControlName="City"
                            [ngClass]="{ 'is-invalid': submitted && fields.City.errors }"
                            placeholder="{{'HOME.City'| translate}}" />
                        <div *ngIf="submitted && fields.City.errors" class="invalid-feedback">
                            <div *ngIf="fields.City.errors.required"> {{'HOME.Cityisrequired'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="customer_name">
                            <span class="input-text-header"> {{'HOME.Country'| translate}}</span><span
                                class="mandatory-field pl-1">*</span>
                        </label>

                        <select class="custom-select input-text" formControlName="CountryId"
                            [ngClass]="{ 'is-invalid': submitted && fields.CountryId.errors }"
                            (change)="changeCountryName($event)" placeholder="Country">
                            <option value=""> {{'HOME.Selectcountry'| translate}}</option>
                            <option *ngFor="let country of Countries" [value]="country.id">
                                {{country.name}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fields.CountryId.errors" class="invalid-feedback">
                            <div *ngIf="fields.CountryId.errors.required"> {{'HOME.Countryisrequired'| translate}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
        <br><br>
    <div class="mt-3" [hidden]="displayTable" style="display: flex; justify-content: center; font-size: large;">
        {{'HOME.Norecordsfound'| translate}}

    </div>

        <div class="container">
            <div class="col-lg-12 mt-10" [hidden]='!displayTable'>
                <div class="row" style="display: flex; justify-content: center;">

                    <div class="col-lg-12 mt-3" style="padding: 0px">


                        <mat-table #table [dataSource]="dataSource" #weekSort="matSort"
                            class="forecastTable horizontal_scrollbar_weekly " matSort>



                            <ng-container matColumnDef="DepotName">
                                <mat-header-cell class="header-font ItemNameWidth" *matHeaderCellDef mat-sort-header
                                    [ngClass]="' mat_column_product_name_mv '">{{'HOME.Depot'|translate}}
                                </mat-header-cell>
                                <mat-cell class="header-font ItemNameWidth" *matCellDef="let fetchedDataForItemArray"
                                    [ngClass]="' mat_column_product_name_mv '">
                                    {{fetchedDataForItemArray.DepotName}}
                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="ProductName">
                                <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header
                                    [ngClass]="' mat_column_depot_mv '">{{'HOME.Pname'|translate}}
                                </mat-header-cell>
                                <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray" [ngClass]="' mat_column_depot_mv '">
                                    {{fetchedDataForItemArray.ProductName}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="ACTION">
                                <mat-header-cell class="header-font" *matHeaderCellDef
                                    [ngClass]="' mat_column_quantity_mv '">{{'HOME.Add/Remove'| translate}}
                                </mat-header-cell>
                                <mat-cell class="header-font" *matCellDef="let fetchedDataForItemArray"
                                    [ngClass]="' mat_column_quantity_mv '">

                                        <span>
                                            <input class="checkbox-add-remove" type="checkbox" [(ngModel)]="fetchedDataForItemArray.isStatusActive">
                                        </span>
                                    
                                </mat-cell>
                            </ng-container>
                            <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                        </mat-table>

                    </div>
                </div>
            </div>
        </div>



  

    <br><br><br><br><br>


    <div class="row buttonGroup">
        <button class="forecastSaveButton" type="submit" (click)="saveDeliverySiteDetails()">
            {{'HOME.Submit'| translate}}
        </button>
        <button class="forecastCancelButton" id="submit" name="submit" (click)="cancelDeliverySiteDetails()">
            {{'HOME.Cancel'| translate}}
        </button>
    </div>
</div>