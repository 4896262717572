import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppService } from '../shared-modules/services/shared.service';
import { CommonService } from '../order-management/order-management/order-management.component.service';
import { TranslateService } from '@ngx-translate/core';

export interface DummyData {
  // date: number;
  eur: number;
//  date: string;
date: Date;

}

@Component({
  selector: 'app-price-notation-admin',
  templateUrl: './price-notation-admin.component.html',
  styleUrls: ['./price-notation-admin.component.css']
})
export class PriceNotationAdminComponent implements OnInit {
  displayTable: boolean = true;
  dataSource: any;
  eur: number;


  selectedItem: string = '';
  navbarOpen = false;
  userInfo: any;
  name: String;
  isLoggedInUserAdmin: boolean;
  expandConfigurationFlag: boolean;

  


  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumnsExchangeRate: string[] = ['date', 'eur', 'edit', 'delete'];

  constructor(
    private router: Router, 
    private sharedService: AppService,  
    private translate: TranslateService,
    private commonService: CommonService) { 
    this.userInfo = this.sharedService.getLoginResponse();
    this.name = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
  }

  ngOnInit() {
    this.getDummydata();   

    this.translate.get(['HOME.itemsPerPageLabel'])
    .subscribe(data => {
      this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
    });
  }

  getDummydata() {
    const test_data: DummyData[] = [
      { eur: 1.213, date: this.sharedService.getCurrentDateTime()},
      { eur: 1.245, date: this.sharedService.getCurrentDateTime()},
      { eur: 1.278 , date: this.sharedService.getCurrentDateTime()},
      { eur: 1.897, date: this.sharedService.getCurrentDateTime() },
      { eur: 1.876 , date: this.sharedService.getCurrentDateTime()},
      { eur: 1.554 , date: this.sharedService.getCurrentDateTime()},
      { eur: 1.554 , date: this.sharedService.getCurrentDateTime()},
      { eur: 1.554 , date: this.sharedService.getCurrentDateTime()},
      { eur: 1.554 , date: this.sharedService.getCurrentDateTime()},
      { eur: 1.564 , date: this.sharedService.getCurrentDateTime()},
      { eur: 1.454 , date: this.sharedService.getCurrentDateTime()},
      { eur: 1.674 , date: this.sharedService.getCurrentDateTime()},
      // { eur: 1.764 },
      // { eur: 1.904 }
    ];
    this.displayTable = true;
    this.dataSource = new MatTableDataSource(test_data);
    // console.log(test_data, "get exchange rate");
    this.dataSource.sort = this.sort;
    this.displayTable = true;
    this.dataSource.paginator = this.paginator;
  }

  updateSelectedItem(selectedItem: string) {
    this.commonService.updateSelectedItem(selectedItem)
  }

  expandConfiguration(expandConfiguration: boolean) {
    this.expandConfigurationFlag = expandConfiguration;
  }

  saveUserDetails() {
    // added this function to fix ng build prod errors
  }

  cancelUserDetails() {
    // added this function to fix ng build prod errors
  }

}
