<div class="container-fluid">
    <div class="">
        <div class="row pl-1 no-gutters">
            <div class="col-3 type_div_width_mv">
                <div class="form-group">
                    <label for="IndexDate">
                        <span class="input-text-header"> {{'HOME.Type'| translate}}
                        </span>
                    </label>
                    <input type="text" class="form-control type_input_width_mv input-text" [(ngModel)]="type" id="txtType"
                        placeholder="{{'HOME.Type'| translate}}" disabled="true" />
                    <!-- <div class="label-text">
                            {{ type }}
                        </div> -->
                </div>
            </div>
            <div class="pl-5 col-5 description_div_width_mv">
                <div class="form-group">
                    <label for="IndexDate">
                        <span class="input-text-header"> {{'HOME.Description'| translate}}
                        </span>
                    </label>
                    <div *ngIf="!fieldType">
                        <input type="text" class="form-control description_input_width_mv input-text" [(ngModel)]="description" id="txtDescription"
                            placeholder="{{'HOME.Description'| translate}}" disabled="true" />
                    </div>
                    <div *ngIf="fieldType === 'text'">
                        <input type="text" class="form-control description_input_width_mv input-text" [(ngModel)]="description" id="txtDescription"
                            placeholder="{{'HOME.Description'| translate}}" />
                    </div>
                    <div *ngIf="fieldType === 'calendar'" class="datepicker-width">
                        <mat-form-field class="datepicker">
                            <input matInput readonly [matDatepicker]="picker" placeholder="" [(ngModel)]="description">
                            <mat-datepicker-toggle matSuffix [for]="picker" tabindex="-1"></mat-datepicker-toggle>
                            <mat-datepicker #picker disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div *ngIf="fieldType === 'multi-line-text-email'">
                        <div *ngFor="let multiLineTextEmail of multiLineTextEmails; let i = index; let last = last"
                            [ngClass]="{'pt-2': (i > 0) }">
                            <div class="container-fluid padding-left-none">
                                <div class="row">
                                    <div class="col-10">
                                        <input type="text" class="form-control input-text"
                                            [(ngModel)]="savedMultiLineTextEmails[i]" id="txtDescription"
                                            placeholder="{{'HOME.Description'| translate}}"
                                            [ngClass]="{'is-invalid-email-id-entered': (submitted && validateEmailId(savedMultiLineTextEmails[i])) }" />
                                    </div>
                                    <div class="col-0 pt-1">
                                        <span class="minus-icon" title="{{'HOME.Delete'| translate}}"
                                            (click)="removeField(i)">
                                            <i class="fas fa-minus-circle"></i>
                                        </span>
                                        <span *ngIf="last" class="plus-icon pl-2" title="{{'HOME.Add'| translate}}"
                                            (click)="addField(i)">
                                            <i class="fas fa-plus-circle"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <span *ngIf="submitted && validateEmailId(savedMultiLineTextEmails[i])"
                                        class="invalid-email-id pl-3">
                                        {{'HOME.Emailmustbeavalidemailaddress'| translate}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="fieldType === 'checkbox'">
                        <input type="checkbox" class="form-control form-checkbox" [(ngModel)]="description"
                            id="chkDescription" />
                    </div>
                    <div *ngIf="fieldType === 'radio'">
                        <input class="mr-2 radio-input" type="radio" [(ngModel)]="description" value="YES" />
                        <label for="YES" class="radio-button-text mr-5">{{'HOME.Yes'| translate}}</label>

                        <input class="mr-2 radio-input" type="radio" [(ngModel)]="description" value="NO" />
                        <label for="NO" class="radio-button-text mr-5">{{'HOME.No'| translate}}</label>
                    </div>
                    <div *ngIf="fieldType === 'textarea'">
                        <textarea class="form-control text-area-height-internal input-text" type="text"
                            [(ngModel)]="description" id="textareaDescription"></textarea>
                    </div>
                    <div *ngIf="fieldType === 'email'">
                        <div class="container-fluid padding-left-none">
                            <div class="row">
                                <div class="col-12">
                                    <input type="text" class="form-control input-text" [(ngModel)]="description"
                                        id="txtDescription" placeholder="{{'HOME.Description'| translate}}"
                                        [ngClass]="{'is-invalid-email-id-entered': (submitted && validateEmailId(description)) }" />
                                </div>
                            </div>
                            <div class="row">
                                <span *ngIf="submitted && validateEmailId(description)" class="invalid-email-id pl-3">
                                    {{'HOME.Emailmustbeavalidemailaddress'| translate}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 pl-5 buttons_div_width_mv buttons-padding-top">
                <button id="btn-save" class="btn btn-sm btn-primary button-class saveButton" (click)="save()">
                    {{'HOME.Save'| translate}}
                </button>
                <button id="btn-reset" class="btn btn-sm btn-primary button-class cancelButton ml-2" (click)="reset()">
                    {{'HOME.Reset'| translate}}
                </button>
            </div>
        </div>
        <!-- <div class="row pl-1 pt-2">
            <div class="col-sm">
                <button id="btn-save" class="btn btn-sm btn-primary button-class saveButton" (click)="save()">
                    {{'HOME.Save'| translate}}
                </button>
            </div>
            <div class="col-sm">
                <button id="btn-reset" class="btn btn-sm btn-primary button-class cancelButton" (click)="reset()">
                    {{'HOME.Reset'| translate}}
                </button>
            </div>
        </div> -->
    </div>
    <div class="global-search-text-box">
        <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
    </div>
    <div>
        <div class="row pt-3 pb-5">
            <div class="col-sm">
                <div [hidden]="(!isRequestCompleted || (dataSource.data && dataSource.data.length))"
                    class="no-records-message pt-3 pl-2"> {{'HOME.Norecordsfound'| translate}}
                </div>
                <div [hidden]="(!isRequestCompleted || (!dataSource.data || (dataSource.data && !dataSource.data.length)))"
                    class="price-index-daily-table">
                    <div class="horizontal_scrollbar_div_nynas_updates_mv">
                        <table mat-table #sort="matSort" [dataSource]="dataSource"
                            class="settings-table horizontal_scrollbar" matSort>
                            <ng-container matColumnDef="Type">
                                <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef
                                    mat-sort-header>
                                    {{'HOME.Type'| translate}}
                                </mat-header-cell>
                                <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray">
                                    {{fetchedDataForItemArray.Type}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Description">
                                <mat-header-cell class="header-font mat-column-large" *matHeaderCellDef mat-sort-header>
                                    {{'HOME.Description'| translate}}
                                </mat-header-cell>
                                <mat-cell class="mat-column-large" *matCellDef="let fetchedDataForItemArray">
                                    <span
                                        *ngIf="!(fetchedDataForItemArray['FieldType'] === 'checkbox' || fetchedDataForItemArray['FieldType'] === 'radio' || fetchedDataForItemArray['FieldType'] === 'calendar')">
                                        {{fetchedDataForItemArray.Description}}
                                    </span>
                                    <span
                                        *ngIf="(fetchedDataForItemArray['FieldType'] === 'checkbox' || fetchedDataForItemArray['FieldType'] === 'radio')">
                                        {{ getYesNoForBooleanFields(fetchedDataForItemArray.Description) }}
                                    </span>
                                    <span *ngIf="(fetchedDataForItemArray['FieldType'] === 'calendar')">
                                        {{ getTranslatedDate(fetchedDataForItemArray.Description) }}
                                    </span>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Actions">
                                <mat-header-cell class="header-font mat-column-small" *matHeaderCellDef>
                                </mat-header-cell>
                                <th mat-header-cell *matHeaderCellDef
                                    [ngClass]="'mat-table-column-header mat-column-actions'">
                                </th>
                                <mat-cell class="mat-column-small" *matCellDef="let element">
                                    <span (click)='editRecord(element)' title=" {{'HOME.Edit'| translate}}"
                                        class='edit-icon'>
                                        <i class="far fa-edit" aria-hidden="true"></i>
                                    </span>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </table>
                    </div>
                    <!-- <mat-paginator #paginator="matPaginator" [length]="100" [pageSize]="10"
                        [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons>
                    </mat-paginator> -->
                </div>
            </div>
        </div>
    </div>
</div>