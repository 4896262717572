import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../user-management/user';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QualityCertificateService {

  apiUrl = environment.apiUrl;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private httpClient: HttpClient) { }

 
  getQualityCertificateUrl(productId: string, Year: string, countryCode: string) {
    const serviceUrl = this.apiUrl + '/GetQualityCertificate';
    return this.httpClient
        .get(serviceUrl, {
            params: {
              ProductID:productId,
              Year:Year,
              CountryCode: countryCode
            }
        });
}

}