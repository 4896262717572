<div class="container-fluid">

    <div class="row">
       
      
            <span>{{selectedItem }}</span> 
       
        <div class="col-lg-12 col-md-12">
            
            <router-outlet></router-outlet>
        </div>
    </div>
</div>