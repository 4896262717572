import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as FileSaver from 'file-saver';
import { BaseChartDirective, Label } from 'ng2-charts';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';
import { HistoryService } from 'src/app/order-management/history/history.component.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import * as XLSX from 'xlsx';
import { ForecastActualReportService } from '../forecastactual/forecastactual.component.service';
import { ForecastactualweeklyswedenService } from '../forecastactualweeklysweden/forecastactualweeklysweden.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.css']
})
export class MonthlyReportComponent implements OnInit {

  currentToggleMenu: string = 'reports';
  customerIdUserSelected: string;
  userInfo: any;
  countryCode: any;
  customerList = [];
  yearUserSelected: string;
  yearList: any = [];
  fromWeek: string = '';
  toWeek: string = '';
  viewUserSelected: string = 'All';
  displayTable: boolean;
  displayGraph: boolean;
  isRequestCompleted: boolean;
  dataSource: any;
  displayedColumns = ['Depot name', 'Product name', 'Delivery site', 'Type', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total'];
  customerId: string;
  customerName: string;
  isLoggedInUserPortalAdmin: boolean;
  Order_month: any = [];
  Actual_Quantity: any = [];
  Forecast_Quantity: any = [];
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  showDepotNameColumn: boolean;
  showProductNameColumn: boolean;
  showDeliverySiteColumn: boolean;
  // monthsList: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  deliverySiteUserSelected: string;
  deliverySiteList: any = [];

  lastRowValueObject: any = {
    CustomerName: '',
    DeliverySiteName: '',
    DepotName: '',
    ProductName: ''
  };
  deliverySiteObjectList: any = [];
  role: string;
  userID: string;
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;

  constructor(private forecastactualweeklyswedenService: ForecastactualweeklyswedenService,
    private sharedService: AppService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private media: MediaMatcher,
    private historyService: HistoryService,
    private forecastActualService: ForecastActualReportService,
    private translate: TranslateService,
		private globalSearchService: GlobalSearchService) {
    this.userInfo = this.sharedService.getLoginResponse();
    if (this.userInfo) {
      this.countryCode = (this.userInfo.CountryCode ? this.userInfo.CountryCode : '');
      this.customerId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
      this.customerName = (this.userInfo.CustomerName ? this.userInfo.CustomerName : '');
      this.isLoggedInUserPortalAdmin = (this.userInfo.RoleID === 'PA');
      this.role = (this.userInfo ? this.userInfo.RoleID : '');
      this.userID = (this.userInfo ? this.userInfo.UserID : '');
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => cd.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }
  }

  async ngOnInit() {
    this.getYearList();

    try {
      await this.getCustomerList();
    }
    catch (e) {
    }

    if (this.countryCode === 'FIN' || this.countryCode === 'SWE') {
      // Do not set any delivery site for Finland and Sweden monthly reports
      // this.deliverySiteUserSelected = 'DefaultDelSite';
      this.deliverySiteUserSelected = '';
    } else {
      try {
        await this.getDeliverySites();
      }
      catch (e) {
      }
    }

    try {
      this.getReportsForTable();
    }
    catch (e) {
    }
        
    this.subscribeToGlobalSearchServiceSearchTerm();

  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.globalSearchText = '';
  }

  async toggleButtonClick(toggleMenu: string) {
    this.currentToggleMenu = toggleMenu;

    // get yearlist with current year by default in dropdown
    this.getYearList();

    // Set logged user customer by default, in customer name dropdown
    this.customerIdUserSelected = this.customerId;

    this.viewUserSelected = 'All';
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;

    if (this.countryCode === 'FIN' || this.countryCode === 'SWE') {
      // Do not set any delivery site for Finland monthly reports
      // this.deliverySiteUserSelected = 'DefaultDelSite';
      this.deliverySiteUserSelected = '';
    } else {
      try {
        await this.getDeliverySites();
      }
      catch (e) {
      }
    }

    if (this.currentToggleMenu === 'reports') {
      this.getReportsForTable();
    } else if (this.currentToggleMenu === 'graph') {
      this.getReportsForGraph();
    }
  }

  // Commented old code to fetch delivery sites, since customer users should access only those delivery sites assigned to them
/* async getDeliverySites() {
  if (this.countryCode === 'NOR' || this.countryCode === 'DNK') {
    this.deliverySiteList = [];
    this.deliverySiteObjectList = [];
    return new Promise<void>((resolve, reject) => {
      let isWeekly = 'NO'; // Set isWeekly parameter as NO, when delivery sites needed in monthly data
      this.historyService.getHistDeliverySite(this.customerIdUserSelected, this.countryCode, isWeekly).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            for (let deliverySiteObject of response["DeliverySiteCollection"]) {
              this.deliverySiteObjectList.push(deliverySiteObject);
              this.deliverySiteList.push(deliverySiteObject["DeliverySite"]);
            }
            // Set first delivery site by default, in delivery site dropdown
            this.deliverySiteUserSelected = (this.deliverySiteList && this.deliverySiteList.length) ? this.deliverySiteList[0] : '';
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }
} */

  async getDeliverySites() {
    if (this.countryCode === 'NOR' || this.countryCode === 'DNK') {
      this.deliverySiteList = [];
      this.deliverySiteObjectList = [];
      if (this.role === "A" || this.role === "PA") {  // For portal admin and customer admins, show all delivery sites in dropdown
        return new Promise<void>((resolve, reject) => {
          let isWeekly = 'NO'; // Set isWeekly parameter as NO, when delivery sites needed in monthly data
          this.historyService.getHistDeliverySite(this.customerIdUserSelected, this.countryCode, isWeekly).subscribe(
            response => {
              if (response["ResponseCode"] === "200") {
                for (let deliverySiteObject of response["DeliverySiteCollection"]) {
                  this.deliverySiteObjectList.push(deliverySiteObject);
                  this.deliverySiteList.push(deliverySiteObject["DeliverySite"]);
                }
                // Set first delivery site by default, in delivery site dropdown
                this.deliverySiteUserSelected = (this.deliverySiteList && this.deliverySiteList.length) ? this.deliverySiteList[0] : '';
                resolve();
              }
              else {
                reject();
              }
            });
        })
      } else {
        return new Promise<void>((resolve, reject) => { // Customer users are allocated with specific delivery sites, so show only those delivery sites in dropdown
          this.forecastactualweeklyswedenService.getDeliverySiteForUserForTable(this.userID, this.countryCode, "ACTIVE", this.customerIdUserSelected).subscribe(
            response => {
              if (response["ResponseCode"] === "200") {
                for (let deliverySiteObject of response["DeliverySites"]) {
                  this.deliverySiteObjectList.push(deliverySiteObject);
                  this.deliverySiteList.push(deliverySiteObject["DeliverySiteName"]);
                }
                // Set first delivery site by default, in delivery site dropdown
                this.deliverySiteUserSelected = (this.deliverySiteList && this.deliverySiteList.length) ? this.deliverySiteList[0] : '';
                resolve();
              }
              else {
                reject();
              }
            });
        })
      }
    }
  }

  async getCustomerList() {
    this.customerList = [];
    return new Promise((resolve, reject) => {
      this.forecastactualweeklyswedenService.getCustomerList(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.customerList = res["Customers"];

            // Set logged user customer by default, in customer name dropdown
            this.customerIdUserSelected = this.customerId;
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  getYearList() {
    const date = this.sharedService.getCurrentDateTime();
    this.yearUserSelected = ((date.getUTCFullYear()) ? date.getUTCFullYear().toString() : '');
    this.yearList = [];
    this.yearList.push(Number(this.yearUserSelected) - 1, this.yearUserSelected);

    //Remove year 2019 from year dropdown for Finland users, temporary fix until their data is corrected, to be deployed only in Prod
    /* if (this.countryCode === 'FIN') {
      this.yearList = this.yearList.filter(function (year: number) { return year > 2019; });
    } */
  }

  getWeekNumber(date: any) {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    let yearStart: any = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
    return [date.getUTCFullYear(), weekNo];
  }

  getReportsForTable() {
    if (this.viewUserSelected == "All") {
      this.getReportsForTableByViewAll();
    } else if (this.viewUserSelected == "By depot") {
      this.getReportsForTableByViewDepot();
    } else if (this.viewUserSelected == "By product") {
      this.getReportsForTableByViewProduct();
    } else if (this.viewUserSelected == "By delivery site") {
      this.getReportsForTableByViewDeliverySite();
    } else if (this.viewUserSelected == "By product and depot") {
      this.getReportsForTableByViewProductAndDepot();
    }
  }

  getReportsForTableByViewAll() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;
    this.showGlobalSearchTextBox = false;
    this.reinitializeGlobalSearchComponent();

    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);

    // For Norway, do not display any data in table, if no delivery sites are present in dropdown
    if (this.countryCode === 'NOR' && (this.deliverySiteObjectList && this.deliverySiteObjectList.length === 0)) {
      this.showStatusDialog(false, 'please-select-delivery-site');
      return;
    }

    this.forecastActualService.getTableDataForAll(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var test = this;
        response["ActualVsPlannedByAll"].forEach(function (arrayItem) {
          var x = arrayItem;
          let forecastActualData = [];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast']['Jan'], x['TypeCollectionForecast']['Feb'], x['TypeCollectionForecast']['Mar'], x['TypeCollectionForecast']['Apr'], x['TypeCollectionForecast']['May'], x['TypeCollectionForecast']['Jun'], x['TypeCollectionForecast']['Jul'], x['TypeCollectionForecast']['Aug'], x['TypeCollectionForecast']['Sep'], x['TypeCollectionForecast']['Oct'], x['TypeCollectionForecast']['Nov'], x['TypeCollectionForecast']['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual']['Jan'], x['TypeCollectionActual']['Feb'], x['TypeCollectionActual']['Mar'], x['TypeCollectionActual']['Apr'], x['TypeCollectionActual']['May'], x['TypeCollectionActual']['Jun'], x['TypeCollectionActual']['Jul'], x['TypeCollectionActual']['Aug'], x['TypeCollectionActual']['Sep'], x['TypeCollectionActual']['Oct'], x['TypeCollectionActual']['Nov'], x['TypeCollectionActual']['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          x['TypeCollectionForecast']['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          x['TypeCollectionActual']['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          x['TypeCollectionDiffernce']['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;

          forecastActualData.push(x['TypeCollectionForecast']);
          forecastActualData.push(x['TypeCollectionActual']);
          forecastActualData.push(x['TypeCollectionDiffernce']);
          test.dataSource = new MatTableDataSource(forecastActualData);
          test.displayTable = true;
          test.isRequestCompleted = true;
          test.showGlobalSearchTextBox = (forecastActualData && forecastActualData.length > 0);
          test.subscribeToGlobalSearchServiceSearchTerm();
        });
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.displayTable = false;
        this.isRequestCompleted = true;
        this.showGlobalSearchTextBox = false;
      }
      else {
        this.displayTable = false;
        this.isRequestCompleted = true;
        this.showGlobalSearchTextBox = false;
      }

    });

  }

  getReportsForTableByViewDepot() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;
    this.showGlobalSearchTextBox = false;
    this.reinitializeGlobalSearchComponent();

    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);

    // For Norway, do not display any data in table, if no delivery sites are present in dropdown
    if (this.countryCode === 'NOR' && (this.deliverySiteObjectList && this.deliverySiteObjectList.length === 0)) {
      this.showStatusDialog(false, 'please-select-delivery-site');
      return;
    }

    this.forecastActualService.getTableDataForDepot(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(res => {
      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = res["ActualVsPlannedByDepot"]["DepotCollection"];
        var test = this;
        var forecastActualData = [];

        this.resetLastRowValueObject();

        productData.forEach(function (arrayItem) {
          var x = arrayItem;
          let array1 = x['TypeCollectionForecast'][0];
          let array2 = x['TypeCollectionActual'][0];
          let array3 = x['TypeCollectionDifference'][0];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);

          if (test.lastRowValueObject['DepotName'] !== (x.DepotName)) {
            array1['DepotName'] = x.DepotName;
            test.lastRowValueObject['DepotName'] = x.DepotName;
          }
          array1['ActualDepotName'] = x.DepotName;
          array2['ActualDepotName'] = x.DepotName;
          array3['ActualDepotName'] = x.DepotName;

          array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;

          // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
          if (array1['DepotName'] && forecastActualData && forecastActualData.length) {
            array1['showRowTopBorder'] = true;
          }

          forecastActualData.push(array1);
          forecastActualData.push(array2);
          forecastActualData.push(array3);

        });
        this.dataSource = new MatTableDataSource(forecastActualData);
        this.displayTable = true;
        this.isRequestCompleted = true;
        this.showDepotNameColumn = true;
        this.showGlobalSearchTextBox = (forecastActualData && forecastActualData.length > 0);
        this.subscribeToGlobalSearchServiceSearchTerm();
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.displayTable = false;
        this.isRequestCompleted = true;
        this.showGlobalSearchTextBox = false;
      }
      else {
        this.displayTable = false;
        this.isRequestCompleted = true;
        this.showGlobalSearchTextBox = false;
      }

    });
  }

  resetLastRowValueObject(): void {
    for (let key in this.lastRowValueObject) {
      this.lastRowValueObject[key] = '';
    }
  }

  getReportsForTableByViewProduct() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;
    this.showGlobalSearchTextBox = false;
    this.reinitializeGlobalSearchComponent();

    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);

    // For Norway, do not display any data in table, if no delivery sites are present in dropdown
    if (this.countryCode === 'NOR' && (this.deliverySiteObjectList && this.deliverySiteObjectList.length === 0)) {
      this.showStatusDialog(false, 'please-select-delivery-site');
      return;
    }

    this.forecastActualService.getTableDataForProduct(this.yearUserSelected, this.customerIdUserSelected, this.countryCode, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = res["ActualVsPlannedByProduct"]["ProductCollection"];
        var test = this;
        var forecastActualData = [];
        this.resetLastRowValueObject();
        productData.forEach(function (arrayItem) {
          var x = arrayItem;
          let array1 = x['TypeCollectionForecast'][0];
          let array2 = x['TypeCollectionActual'][0];
          let array3 = x['TypeCollectionDifference'][0];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          array1['ProductName'] = x.ProductName;

          if (test.lastRowValueObject['ProductName'] !== (x.ProductName)) {
            array1['ProductName'] = x.ProductName;
            test.lastRowValueObject['ProductName'] = x.ProductName;
          }
          // product will not be shown for actual and difference, it is used only for global search
          array1['ActualProductName'] = x.ProductName;
          array2['ActualProductName'] = x.ProductName;
          array3['ActualProductName'] = x.ProductName;


          array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;

          if (array1['ProductName'] && forecastActualData && forecastActualData.length) {
            array1['showRowTopBorder'] = true;
          }

          forecastActualData.push(array1);
          forecastActualData.push(array2);
          forecastActualData.push(array3);
        });

        this.dataSource = new MatTableDataSource(forecastActualData);
        this.displayTable = true;
        this.isRequestCompleted = true;
        this.showProductNameColumn = true;
        this.showGlobalSearchTextBox = (forecastActualData && forecastActualData.length > 0);
        this.subscribeToGlobalSearchServiceSearchTerm();
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.displayTable = false;
        this.isRequestCompleted = true;
        this.showGlobalSearchTextBox = false;
      }
      else {
        this.displayTable = false;
        this.isRequestCompleted = true;
        this.showGlobalSearchTextBox = false;
      }

    });
  }

  getReportsForTableByViewDeliverySite() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;
    this.showGlobalSearchTextBox = false;
    this.reinitializeGlobalSearchComponent();

    // For Norway, do not display any data in table, if no delivery sites are present in dropdown
    if (this.countryCode === 'NOR' && (this.deliverySiteObjectList && this.deliverySiteObjectList.length === 0)) {
      this.showStatusDialog(false, 'please-select-delivery-site');
      return;
    }

    this.forecastActualService.getTableDataForDelSite(this.yearUserSelected, this.customerIdUserSelected).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = res["ActualVsPlannedByDeliverySite"]["DeliverySiteCollection"];
        var test = this;
        var forecastActualData = [];

        // Added filter for delivery site selected in dropdown temporarily, until delivery site parameter is passed to service
        productData.forEach(function (arrayItem) {
          if (test.countryCode === 'FIN' || test.countryCode === 'SWE' || (test.countryCode === 'NOR' && test.deliverySiteUserSelected && arrayItem['DeliverySite'] && arrayItem['DeliverySite'].toUpperCase().includes(test.deliverySiteUserSelected.toUpperCase()))) {
            var x = arrayItem;
            let array1 = x['TypeCollectionActual'][0];
            let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
            array1['DeliverySite'] = x.DeliverySite;
            array1['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
            forecastActualData.push(array1);
          }
        });
        this.dataSource = new MatTableDataSource(forecastActualData);
        this.displayTable = (forecastActualData && forecastActualData.length > 0);

        this.isRequestCompleted = true;
        this.showDeliverySiteColumn = true;
        this.showGlobalSearchTextBox = (forecastActualData && forecastActualData.length > 0);
        this.subscribeToGlobalSearchServiceSearchTerm();
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.displayTable = false;
        this.isRequestCompleted = true;
        this.showGlobalSearchTextBox = false;
      }
      else {
        this.displayTable = false;
        this.isRequestCompleted = true;
        this.showGlobalSearchTextBox = false;
      }

    });

  }

  getReportsForTableByViewProductAndDepot() {
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;
    this.showGlobalSearchTextBox = false;
    this.reinitializeGlobalSearchComponent();

    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);

    // For Norway, do not display any data in table, if no delivery sites are present in dropdown
    if (this.countryCode === 'NOR' && (this.deliverySiteObjectList && this.deliverySiteObjectList.length === 0)) {
      this.showStatusDialog(false, 'please-select-delivery-site');
      return;
    }

    this.forecastActualService.getTableDataForDepotAndProduct(this.customerIdUserSelected, this.yearUserSelected, this.countryCode, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var depotData = res["DepotCollection"];
        var test = this;
        var forecastActualData = [];

        depotData.forEach(function (depotItem) {
          var productData = depotItem["ProductCollection"];

          productData.forEach(function (arrayItem) {
            var x = arrayItem;

            let array1 = x['TypeCollectionForecast'][0];
            let array2 = x['TypeCollectionActual'][0];
            let array3 = x['TypeCollectionDifference'][0];
            let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
            let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
            let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);

            array1['ProductName'] = x.ProductName;
            array1['DepotName'] = depotItem.DepotName;

            array1['ActualProductName'] = x.ProductName;
            array1['ActualDepotName'] = depotItem.DepotName;
            array2['ActualProductName'] = x.ProductName;
            array2['ActualDepotName'] = depotItem.DepotName;
            array3['ActualProductName'] = x.ProductName;
            array3['ActualDepotName'] = depotItem.DepotName;

            array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
            array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
            // array3['Total'] = diffenceTotalPer ? diffenceTotalPer.toString() : 0;
            array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;

            forecastActualData.push(array1);
            forecastActualData.push(array2);
            forecastActualData.push(array3);
          })
        });
        this.dataSource = new MatTableDataSource(forecastActualData);
        this.displayTable = true;
        this.isRequestCompleted = true;
        this.showProductNameColumn = true;
        this.showDepotNameColumn = true;
        this.showGlobalSearchTextBox = (forecastActualData && forecastActualData.length > 0);
        this.subscribeToGlobalSearchServiceSearchTerm();
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.displayTable = false;
        this.isRequestCompleted = true;
        this.showGlobalSearchTextBox = false;
      }
      else {
        this.displayTable = false;
        this.isRequestCompleted = true;
        this.showGlobalSearchTextBox = false;
      }

    });
  }

  getReportsForExcelByViewAll(headerList: any) {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForAll(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var test = this;
        let excelAllReportsData = [];
        response["ActualVsPlannedByAll"].forEach(function (arrayItem) {
          var x = arrayItem;
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast']['Jan'], x['TypeCollectionForecast']['Feb'], x['TypeCollectionForecast']['Mar'], x['TypeCollectionForecast']['Apr'], x['TypeCollectionForecast']['May'], x['TypeCollectionForecast']['Jun'], x['TypeCollectionForecast']['Jul'], x['TypeCollectionForecast']['Aug'], x['TypeCollectionForecast']['Sep'], x['TypeCollectionForecast']['Oct'], x['TypeCollectionForecast']['Nov'], x['TypeCollectionForecast']['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual']['Jan'], x['TypeCollectionActual']['Feb'], x['TypeCollectionActual']['Mar'], x['TypeCollectionActual']['Apr'], x['TypeCollectionActual']['May'], x['TypeCollectionActual']['Jun'], x['TypeCollectionActual']['Jul'], x['TypeCollectionActual']['Aug'], x['TypeCollectionActual']['Sep'], x['TypeCollectionActual']['Oct'], x['TypeCollectionActual']['Nov'], x['TypeCollectionActual']['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          x['TypeCollectionForecast']['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          x['TypeCollectionActual']['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          x['TypeCollectionDiffernce']['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;

          excelAllReportsData.push(x['TypeCollectionForecast']);
          excelAllReportsData.push(x['TypeCollectionActual']);
          excelAllReportsData.push(x['TypeCollectionDiffernce']);
        });

        /* for (let reportData of excelAllReportsData) {
          for (let month of this.monthsList) {
            if (reportData[month] || reportData[month] == 0) {
              reportData[month] = +reportData[month];
            }
          }
        } */
        for (let reportData of excelAllReportsData) {

          // Translate type for excel file
          this.getTranslatedTypeForExcelFile(reportData);

          for (let headerColumn of headerList) {
            if (reportData[headerColumn] || reportData[headerColumn] == 0) {
              reportData[headerColumn] = +reportData[headerColumn];
            }
          }
        }
        headerList.unshift("Type");

        // Commented old code, kept for future reference
        // this.exportDataInExcelFile(excelAllReportsData, 'Reports by all', headerList);
    
        this.sharedService.downloadDataInExcelFile(excelAllReportsData, 'Reports by all', headerList);

      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'NO DATA FOUND') {
        this.showStatusDialog(false, 'no-data-available');
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
      else {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
    });
  }

  getReportsForExcelByViewDepot(headerList: any) {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForDepot(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = response["ActualVsPlannedByDepot"]["DepotCollection"];
        var test = this;
        var excelDepotReportsData = [];

        productData.forEach(function (arrayItem) {
          var x = arrayItem;
          let array1 = x['TypeCollectionForecast'][0];
          let array2 = x['TypeCollectionActual'][0];
          let array3 = x['TypeCollectionDifference'][0];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          array1['DepotName'] = x.DepotName;
          array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;


          excelDepotReportsData.push(array1);
          excelDepotReportsData.push(array2);
          excelDepotReportsData.push(array3);
        });

        /* for (let reportData of excelDepotReportsData) {
          for (let month of this.monthsList) {
            if (reportData[month] || reportData[month] == 0) {
              reportData[month] = +reportData[month];
            }
          }
        } */
        for (let reportData of excelDepotReportsData) {

          // Translate type for excel file
          this.getTranslatedTypeForExcelFile(reportData);

          for (let headerColumn of headerList) {
            if (reportData[headerColumn] || reportData[headerColumn] == 0) {
              reportData[headerColumn] = +reportData[headerColumn];
            }
          }
        }
        headerList.unshift("Type");
        headerList.unshift("DepotName");

        let reportByDepotList: any = [];
        if (excelDepotReportsData && excelDepotReportsData.length) {
          for (let reportsByDepotObject of excelDepotReportsData) {
            let reportObject: any = {};
            reportObject['Depot name'] = reportsByDepotObject['DepotName'];
            reportObject['Type'] = reportsByDepotObject['Type'];
            reportObject['Jan'] = reportsByDepotObject['Jan'];
            reportObject['Feb'] = reportsByDepotObject['Feb'];
            reportObject['Mar'] = reportsByDepotObject['Mar'];
            reportObject['Apr'] = reportsByDepotObject['Apr'];
            reportObject['May'] = reportsByDepotObject['May'];
            reportObject['Jun'] = reportsByDepotObject['Jun'];
            reportObject['Jul'] = reportsByDepotObject['Jul'];
            reportObject['Aug'] = reportsByDepotObject['Aug'];
            reportObject['Sep'] = reportsByDepotObject['Sep'];
            reportObject['Oct'] = reportsByDepotObject['Oct'];
            reportObject['Nov'] = reportsByDepotObject['Nov'];
            reportObject['Dec'] = reportsByDepotObject['Dec'];
            reportObject['Total'] = reportsByDepotObject['Total'];
            reportByDepotList.push(reportObject);
          }
        }

        // Commented old code, kept for future reference
        // this.exportDataInExcelFile(excelDepotReportsData, 'Reports by depot', headerList);

        headerList = ["Depot name", "Type", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total"];
    
        this.sharedService.downloadDataInExcelFile(reportByDepotList, 'Reports by depot', headerList);

      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'NO DATA FOUND') {
        this.showStatusDialog(false, 'no-data-available');
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
      else {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
    });
  }

  getReportsForExcelByViewProduct(headerList: any) {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForProduct(this.yearUserSelected, this.customerIdUserSelected, this.countryCode, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = response["ActualVsPlannedByProduct"]["ProductCollection"];
        var test = this;
        var excelProductReportsData = [];
        productData.forEach(function (arrayItem) {
          var x = arrayItem;
          let array1 = x['TypeCollectionForecast'][0];
          let array2 = x['TypeCollectionActual'][0];
          let array3 = x['TypeCollectionDifference'][0];
          let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
          let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
          let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);
          array1['ProductName'] = x.ProductName;
          array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
          array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
          array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;
          excelProductReportsData.push(array1);
          excelProductReportsData.push(array2);
          excelProductReportsData.push(array3);
        });

        /* for (let reportData of excelProductReportsData) {
          for (let month of this.monthsList) {
            if (reportData[month] || reportData[month] == 0) {
              reportData[month] = +reportData[month];
            }
          }
        } */
        for (let reportData of excelProductReportsData) {

          // Translate type for excel file
          this.getTranslatedTypeForExcelFile(reportData);

          for (let headerColumn of headerList) {
            if (reportData[headerColumn] || reportData[headerColumn] == 0) {
              reportData[headerColumn] = +reportData[headerColumn];
            }
          }
        }
        headerList.unshift("Type");
        headerList.unshift("ProductName");

        let reportByProductList: any = [];
        if (excelProductReportsData && excelProductReportsData.length) {
          for (let reportsByProductObject of excelProductReportsData) {
            let reportObject: any = {};
            reportObject['Product name'] = reportsByProductObject['ProductName'];
            reportObject['Type'] = reportsByProductObject['Type'];
            reportObject['Jan'] = reportsByProductObject['Jan'];
            reportObject['Feb'] = reportsByProductObject['Feb'];
            reportObject['Mar'] = reportsByProductObject['Mar'];
            reportObject['Apr'] = reportsByProductObject['Apr'];
            reportObject['May'] = reportsByProductObject['May'];
            reportObject['Jun'] = reportsByProductObject['Jun'];
            reportObject['Jul'] = reportsByProductObject['Jul'];
            reportObject['Aug'] = reportsByProductObject['Aug'];
            reportObject['Sep'] = reportsByProductObject['Sep'];
            reportObject['Oct'] = reportsByProductObject['Oct'];
            reportObject['Nov'] = reportsByProductObject['Nov'];
            reportObject['Dec'] = reportsByProductObject['Dec'];
            reportObject['Total'] = reportsByProductObject['Total'];
            reportByProductList.push(reportObject);
          }
        }

        // Commented old code, kept for future reference
        // this.exportDataInExcelFile(excelProductReportsData, 'Reports by product', headerList);

        headerList = ["Product name", "Type", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total"];
    
        this.sharedService.downloadDataInExcelFile(reportByProductList, 'Reports by product', headerList);

      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'NO DATA FOUND') {
        this.showStatusDialog(false, 'no-data-available');
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
      else {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
    });
  }

  getReportsForExcelByViewDeliverySite(headerList: any) {
    this.forecastActualService.getTableDataForDelSite(this.yearUserSelected, this.customerIdUserSelected).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var productData = response["ActualVsPlannedByDeliverySite"]["DeliverySiteCollection"];
        var test = this;
        var excelDeliverySiteReportsData = [];

        // Added filter for delivery site selected in dropdown temporarily, until delivery site parameter is passed to service
        productData.forEach(function (arrayItem) {
          if (test.countryCode === 'FIN' || test.countryCode === 'SWE' || (test.countryCode === 'NOR' && test.deliverySiteUserSelected && arrayItem['DeliverySite'] && arrayItem['DeliverySite'].toUpperCase().includes(test.deliverySiteUserSelected.toUpperCase()))) {
            var x = arrayItem;
            let array1 = x['TypeCollectionActual'][0];
            let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
            array1['DeliverySite'] = x.DeliverySite;
            array1['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
            excelDeliverySiteReportsData.push(array1);
          }
        });

        /* for (let reportData of excelDeliverySiteReportsData) {
          for (let month of this.monthsList) {
            if (reportData[month] || reportData[month] == 0) {
              reportData[month] = +reportData[month];
            }
          }
        } */
        for (let reportData of excelDeliverySiteReportsData) {

          // Translate type for excel file
          this.getTranslatedTypeForExcelFile(reportData);

          for (let headerColumn of headerList) {
            if (reportData[headerColumn] || reportData[headerColumn] == 0) {
              reportData[headerColumn] = +reportData[headerColumn];
            }
          }
        }
        headerList.unshift("Type");
        headerList.unshift("DeliverySite");

        let reportByDeliverySiteList: any = [];
        if (excelDeliverySiteReportsData && excelDeliverySiteReportsData.length) {
          for (let reportsByDeliverySiteObject of excelDeliverySiteReportsData) {
            let reportObject: any = {};
            reportObject['Delivery site'] = reportsByDeliverySiteObject['DeliverySite'];
            reportObject['Type'] = reportsByDeliverySiteObject['Type'];
            reportObject['Jan'] = reportsByDeliverySiteObject['Jan'];
            reportObject['Feb'] = reportsByDeliverySiteObject['Feb'];
            reportObject['Mar'] = reportsByDeliverySiteObject['Mar'];
            reportObject['Apr'] = reportsByDeliverySiteObject['Apr'];
            reportObject['May'] = reportsByDeliverySiteObject['May'];
            reportObject['Jun'] = reportsByDeliverySiteObject['Jun'];
            reportObject['Jul'] = reportsByDeliverySiteObject['Jul'];
            reportObject['Aug'] = reportsByDeliverySiteObject['Aug'];
            reportObject['Sep'] = reportsByDeliverySiteObject['Sep'];
            reportObject['Oct'] = reportsByDeliverySiteObject['Oct'];
            reportObject['Nov'] = reportsByDeliverySiteObject['Nov'];
            reportObject['Dec'] = reportsByDeliverySiteObject['Dec'];
            reportObject['Total'] = reportsByDeliverySiteObject['Total'];
            reportByDeliverySiteList.push(reportObject);
          }
        }

        // Commented old code, kept for future reference
        // this.exportDataInExcelFile(excelDeliverySiteReportsData, 'Reports by delivery site', headerList);

        headerList = ["Delivery site", "Type", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total"];
    
        this.sharedService.downloadDataInExcelFile(reportByDeliverySiteList, 'Reports by delivery site', headerList);
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'NO RECORDS FOUND') {
        this.showStatusDialog(false, 'no-data-available');
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
      else {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
    });
  }

  getReportsForExcelByViewProductAndDepot(headerList: any) {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
    this.forecastActualService.getTableDataForDepotAndProduct(this.customerIdUserSelected, this.yearUserSelected, this.countryCode, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(response => {
      if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {

        var depotData = response["DepotCollection"];
        var test = this;
        var excelProductAndDepotReportsData = [];

        depotData.forEach(function (depotItem) {
          var productData = depotItem["ProductCollection"];

          productData.forEach(function (arrayItem) {
            var x = arrayItem;

            let array1 = x['TypeCollectionForecast'][0];
            let array2 = x['TypeCollectionActual'][0];
            let array3 = x['TypeCollectionDifference'][0];
            let TypeCollectionForecastTotal = test.getTotal(x['TypeCollectionForecast'][0]['Jan'], x['TypeCollectionForecast'][0]['Feb'], x['TypeCollectionForecast'][0]['Mar'], x['TypeCollectionForecast'][0]['Apr'], x['TypeCollectionForecast'][0]['May'], x['TypeCollectionForecast'][0]['Jun'], x['TypeCollectionForecast'][0]['Jul'], x['TypeCollectionForecast'][0]['Aug'], x['TypeCollectionForecast'][0]['Sep'], x['TypeCollectionForecast'][0]['Oct'], x['TypeCollectionForecast'][0]['Nov'], x['TypeCollectionForecast'][0]['Dec']);
            let TypeCollectionActualTotal = test.getTotal(x['TypeCollectionActual'][0]['Jan'], x['TypeCollectionActual'][0]['Feb'], x['TypeCollectionActual'][0]['Mar'], x['TypeCollectionActual'][0]['Apr'], x['TypeCollectionActual'][0]['May'], x['TypeCollectionActual'][0]['Jun'], x['TypeCollectionActual'][0]['Jul'], x['TypeCollectionActual'][0]['Aug'], x['TypeCollectionActual'][0]['Sep'], x['TypeCollectionActual'][0]['Oct'], x['TypeCollectionActual'][0]['Nov'], x['TypeCollectionActual'][0]['Dec']);
            let diffenceTotalPer = (TypeCollectionForecastTotal ? ((TypeCollectionActualTotal - TypeCollectionForecastTotal) / TypeCollectionForecastTotal * 100) : 0);

            array1['ProductName'] = x.ProductName;
            array1['DepotName'] = depotItem.DepotName;

            array1['Total'] = TypeCollectionForecastTotal ? TypeCollectionForecastTotal.toString() : 0;
            array2['Total'] = TypeCollectionActualTotal ? TypeCollectionActualTotal.toString() : 0;
            // array3['Total'] = diffenceTotalPer ? diffenceTotalPer.toString() : 0;
            array3['Total'] = diffenceTotalPer.toString() ? Number.parseFloat(diffenceTotalPer.toString()).toFixed(0) : 0;
            
            excelProductAndDepotReportsData.push(array1);
            excelProductAndDepotReportsData.push(array2);
            excelProductAndDepotReportsData.push(array3);
          })
        });

        /* for (let reportData of excelProductAndDepotReportsData) {
          for (let month of this.monthsList) {
            if (reportData[month] || reportData[month] == 0) {
              reportData[month] = +reportData[month];
            }
          }
        } */
        for (let reportData of excelProductAndDepotReportsData) {

          // Translate type for excel file
          this.getTranslatedTypeForExcelFile(reportData);

          for (let headerColumn of headerList) {
            if (reportData[headerColumn] || reportData[headerColumn] == 0) {
              reportData[headerColumn] = +reportData[headerColumn];
            }
          }
        }
        headerList.unshift("Type");
        headerList.unshift("DepotName");
        headerList.unshift("ProductName");

        let reportByProductAndDepotList: any = [];
        if (excelProductAndDepotReportsData && excelProductAndDepotReportsData.length) {
          for (let reportsByProductAndDepotObject of excelProductAndDepotReportsData) {
            let reportObject: any = {};
            reportObject['Depot name'] = reportsByProductAndDepotObject['DepotName'];
            reportObject['Product name'] = reportsByProductAndDepotObject['ProductName'];
            reportObject['Type'] = reportsByProductAndDepotObject['Type'];
            reportObject['Jan'] = reportsByProductAndDepotObject['Jan'];
            reportObject['Feb'] = reportsByProductAndDepotObject['Feb'];
            reportObject['Mar'] = reportsByProductAndDepotObject['Mar'];
            reportObject['Apr'] = reportsByProductAndDepotObject['Apr'];
            reportObject['May'] = reportsByProductAndDepotObject['May'];
            reportObject['Jun'] = reportsByProductAndDepotObject['Jun'];
            reportObject['Jul'] = reportsByProductAndDepotObject['Jul'];
            reportObject['Aug'] = reportsByProductAndDepotObject['Aug'];
            reportObject['Sep'] = reportsByProductAndDepotObject['Sep'];
            reportObject['Oct'] = reportsByProductAndDepotObject['Oct'];
            reportObject['Nov'] = reportsByProductAndDepotObject['Nov'];
            reportObject['Dec'] = reportsByProductAndDepotObject['Dec'];
            reportObject['Total'] = reportsByProductAndDepotObject['Total'];
            reportByProductAndDepotList.push(reportObject);
          }
        }

        // Commented old code, kept for future reference
        // this.exportDataInExcelFile(excelProductAndDepotReportsData, 'Reports by product and depot', headerList);

        headerList = ["Depot name", "Product name", "Type", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total"];
    
        this.sharedService.downloadDataInExcelFile(reportByProductAndDepotList, 'Reports by product and depot', headerList);

      }
      else if (response['Description'] && response['Description'].toUpperCase() === 'NO DATA FOUND.') {
        this.showStatusDialog(false, 'no-data-available');
      }
      else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
      else {
        this.showStatusDialog(false, 'Something-went-wrong');
      }
    });
  }

  downloadReports() {
    let headerList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total"];
    if (this.viewUserSelected == "All") {
      this.getReportsForExcelByViewAll(headerList);
    } else if (this.viewUserSelected == "By depot") {
      this.getReportsForExcelByViewDepot(headerList);
    } else if (this.viewUserSelected == "By product") {
      this.getReportsForExcelByViewProduct(headerList);
    } else if (this.viewUserSelected == "By delivery site") {
      this.getReportsForExcelByViewDeliverySite(headerList);
    } else if (this.viewUserSelected == "By product and depot") {
      this.getReportsForExcelByViewProductAndDepot(headerList);
    }
  }

  exportDataInExcelFile(data: any[], excelFileName: string, headerList: any): void {
    if (data) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((data), { header: headerList });
      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const Report: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(Report, excelFileName + EXCEL_EXTENSION);
    }
  }

  getReports() {
    if (this.currentToggleMenu === 'reports') {
      this.getReportsForTable();
    } else if (this.currentToggleMenu === 'graph') {
      this.getReportsForGraph();
    }
  }

  getReportsForGraph() {
    this.Order_month.splice(0, 52);
    this.Actual_Quantity.splice(0, 52);
    this.Forecast_Quantity.splice(0, 52);
    this.getBarChartData();
  }

  getBarChartData() {
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);

    // For Norway, do not display any graph, if no delivery sites are present in dropdown
    if (this.countryCode === 'NOR' && (this.deliverySiteObjectList && this.deliverySiteObjectList.length === 0)) {
      this.showStatusDialog(false, 'please-select-delivery-site');
      return;
    }

    this.forecastActualService.getBarChartData(this.yearUserSelected, this.customerIdUserSelected, this.deliverySiteUserSelected, jdeAddressNumber).subscribe(
      response => {
        if (response["ResponseCode"] == '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          if (this.Order_month.length > 0) {
            this.Order_month = [];
          }
          response["Collection"].forEach(x => {
            this.Actual_Quantity.push(parseInt(x.Actual_Quantity));
            this.Forecast_Quantity.push(parseInt(x.Forecast_Quantity));
            this.translate.get(['HOME.' + x.Forecast_month])
              .subscribe(data => {
                this.Order_month.push(data['HOME.' + x.Forecast_month]);
              });
          });
          this.displayGraph = true;
          this.isRequestCompleted = true;
        }
        else if (response['ResponseCode'] && response['ResponseCode'] === '201') {
          // when no records returned by service
          this.displayGraph = false;
          this.isRequestCompleted = true;
        }
        else {
          this.displayGraph = false;
          this.isRequestCompleted = true;
          this.showStatusDialog(false, 'Something-went-wrong');
        }
      });

  }

  getTitleBySelectedLanguage(title: string): string {
    let translatedTitle: string;
    let selectedLanguage = this.sharedService.getSelectedLanguage();
    if (title === 'Actual') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Actual';
          break;
        case 'FI':
          translatedTitle = 'Toteuma';
          break;
        case 'SE':
          translatedTitle = 'Faktiskt';
          break;
        case 'NR':
          translatedTitle = 'Salg';
          break;
        default:
          translatedTitle = 'Actual';
      }
    }
    if (title === 'Planned') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Planned';
          break;
        case 'FI':
          translatedTitle = 'Ennuste';
          break;
        case 'SE':
          translatedTitle = 'Prognostiserat';
          break;
        case 'NR':
          translatedTitle = 'Prognose';
          break;
        default:
          translatedTitle = 'Planned';
      }
    }
    return translatedTitle;
  }

  showStatusDialog(isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,

      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  getTotal(jan: string, feb: string, mar: string, apr: string, may: string, jun: string, jul: string, aug: string, sep: string, oct: string, nov: string, dec: string) {
    var totalValue = parseInt(jan) + parseInt(feb) + parseInt(mar) + parseInt(apr) + parseInt(may) +
      parseInt(jun) + parseInt(jul) + parseInt(aug) + parseInt(sep) + parseInt(oct) + parseInt(nov) +
      parseInt(dec);
    return totalValue;
  }

  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        if (deliverySiteObject && deliverySiteObject['DeliverySite'] === deliverySiteName) {
          jdeAddressNumber = deliverySiteObject['JDE_ADDRESS_NUMBER'];
          break;
        }
      }
    }
    return jdeAddressNumber;
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.cd.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.cd.detectChanges();
    this.isFirstOnLoad = true;
  }

  subscribeToGlobalSearchServiceSearchTerm() {

    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      return ((data['ActualProductName'] ? data['ActualProductName'].toLowerCase().includes(filter) : data['ActualProductName']) ||
        (data['ActualDepotName'] ? data['ActualDepotName'].toLowerCase().includes(filter) : data['ActualDepotName']) ||
        (data['DeliverySite'] ? data['DeliverySite'].toLowerCase().includes(filter) : data['DeliverySite']));
    };

    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string): void {
    if (this.viewUserSelected !== 'All' && (filterValue || filterValue === "")) {
      if (filterValue && ("FORECAST".includes(filterValue.toUpperCase()) || "ACTUAL".includes(filterValue.toUpperCase()) || "DIFFERENCE".includes(filterValue.toUpperCase()))) {
        filterValue = "";
      }
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }

  getTranslatedTypeForExcelFile(reportData: any): void {
    if (reportData && reportData['Type']) {
      let type: string = reportData['Type'];
      this.translate.get(['HOME.' + type])
        .subscribe(data => {
          reportData['Type'] = (data['HOME.' + type]);
        });
    }
  }

  //  Changes for barchart start
  public barChartOptions2: ChartOptions = {
    responsive: true,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    tooltips: {
      mode: 'index'
    },
    scales: {

      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "bold"
        }
      }], yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 14,
          fontStyle: "bold"
        }
      }]
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    }
  };
  public barChartLabels2: Label[] = this.Order_month;
  public barChartType2: ChartType = 'bar';
  public barChartLegend2 = true;
  public barChartPlugins2 = [pluginDataLabels];

  public barChartData2: ChartDataSets[] = [
    { data: this.Actual_Quantity, label: this.getTitleBySelectedLanguage('Actual'), pointStyle: "line", backgroundColor: '#FFD500', hoverBackgroundColor: '#FFD500', hoverBorderColor: '#FFD500' },
    { data: this.Forecast_Quantity, label: this.getTitleBySelectedLanguage('Planned'), pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' }
  ];

  // Changes for barchart end

  //bar chart data for customer role: start

  public barChartOptions1: ChartOptions = {
    responsive: true,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    tooltips: {
      mode: 'index'
    },
    scales: {

      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "bold"
        }
      }], yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 14,
          fontStyle: "bold"
        }
      }]
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    }
  };
  public barChartLabels1: Label[] = this.Order_month;
  public barChartType1: ChartType = 'bar';
  public barChartLegend1 = true;
  public barChartPlugins1 = [pluginDataLabels];

  public barChartData1: ChartDataSets[] = [
    { data: this.Forecast_Quantity, label: 'Forecast', pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' }
  ];
  //bar chart data for customer role : end

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

}

