import { MediaMatcher } from '@angular/cdk/layout';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';
import { NynasUpdatesService } from 'src/app/nynas-updates/nynas-updates.service';
import { ForecastEntryService } from 'src/app/order-management/forecast-entry/forecast-entry.service';
import { HistoryService } from 'src/app/order-management/history/history.component.service';
import { CommonService } from 'src/app/order-management/order-management/order-management.component.service';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import * as XLSX from 'xlsx';
import { DialogBodyComponent } from '../../user-management/dialog-body/dialog-body.component';
import { ForecastactualweeklyswedenService } from '../forecastactualweeklysweden/forecastactualweeklysweden.service';
import { ShippedDeliveriesService } from './shipped-deliveries.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YY',
  },
  display: {
    dateInput: 'DD-MMM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-shipped-deliveries',
  templateUrl: './shipped-deliveries.component.html',
  styleUrls: ['./shipped-deliveries.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DecimalPipe
  ]
})

export class ShippedDeliveriesComponent implements OnInit {

  dataSource: any;
  displayedColumnsShippedDeliveries: string[] = ['CUSTOMER_Name', 'Month', 'YEAR', 'LastModifiedDate', 'SALES_REPORT'];
  currYear: number;
  yearList: number[] = [];
  yearUserSelected: number;
  monthUserSelected: string;
  isRequestCompleted: boolean;
  countryCode: string;
  userInfo: any;
  Customers = [];
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  loggedInUserName: string;
  monthsListObject: any = {};
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  currentToggleMenu: string;
  displayTable: boolean;
  customerIdUserSelected: string;
  deliverySiteList: any = [];
  deliverySiteUserSelected: string;
  customerList = [];
  customerId: string;
  customerName: string;
  isLoggedInUserPortalAdmin: boolean;
  isLoggedInUserAdmin: boolean;
  role: string;
  userID: string;
  deliverySiteObjectList: any = [];
  viewUserSelected: string = 'By deliverysite';
  showDepotNameColumn: boolean;
  showProductNameColumn: boolean;
  showDeliverySiteColumn: boolean;
  showProdDepotColumn: boolean;
  showUK: boolean;
  startDate: Date;
  endDate: Date;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumnsDailyReports: any = ['DELIVERY_TICKET', 'CUSTOMER_NAME', 'CUSTOMER_PO', 'DEPOT_NAME', 'ACTUAL_SHIP_DATE', 'DELIVERY_SITE', 'PRODUCT_NAME', 'SHIPPED_QTY', 'productTypeId', 'INVOICE_NUMBER', 'INVOICE_DATE', 'INCOTERMS', 'ORDER_NUMBER', 'ORDER_TYPE', 'ORIGINAL_ORDER_NUMBER', 'ADHESIVE_CONTENT', 'TEMPERATURE', 'REQUESTED_DATE', 'INVOICE_NUMBER_UK', 'ENGINE_VEHICLE_NUMBER', 'COMPARTMENT_VEHICLE_NUMBER', 'ORIGINAL_ORDER_NUMBER_UK'];
  @ViewChild('dailyReportTableSort', { static: true }) public dailyReportTableSort: MatSort;
  january012020Date: any = new Date('01/01/2020');
  fromDateIsOlderThan1stJanuary2020: boolean;
  isFindButtonClicked: boolean;
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
  selectedLanguage: string;
  isFirstOnLoad: boolean = true;
  searchBy: string;

  constructor(
    private forecastEntryService: ForecastEntryService,
    private shippedDeliveriesService: ShippedDeliveriesService,
    private sharedService: AppService,
    private nynasUpdateService: NynasUpdatesService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private forecastactualweeklyswedenService: ForecastactualweeklyswedenService,
    private cd: ChangeDetectorRef,
    private historyService: HistoryService,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<any>,
    private translate: TranslateService,
    private commonService: CommonService,
    private decimalPipe: DecimalPipe,
		private globalSearchService: GlobalSearchService
  ) {
    this.userInfo = this.sharedService.getLoginResponse();
    if (this.userInfo) {
      this.countryCode = (this.userInfo.CountryCode ? this.userInfo.CountryCode : '');
      this.customerId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
      this.customerName = (this.userInfo.CustomerName ? this.userInfo.CustomerName : '');
      this.isLoggedInUserPortalAdmin = (this.userInfo.RoleID === 'PA');
      this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
      this.role = (this.userInfo ? this.userInfo.RoleID : '');
      this.userID = (this.userInfo ? this.userInfo.UserID : '');
      this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => cd.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }
  }

  async ngOnInit() {
    this.viewUserSelected = 'All';
    this.translate.get(['HOME.selectedLanguageForDatePicker'])
      .subscribe(data => {
        this.dateAdapter.setLocale(data['HOME.selectedLanguageForDatePicker']);
      });
    if (this.countryCode === 'GBR') {
      this.showUK = false;
    }
    else {
      this.showUK = true;
    }
    this.toggleButtonClick(this.getDefaultTabToBeDisplayed());
        
    this.subscribeToGlobalSearchServiceSearchTerm();

    try {
      await this.getSelectedLanguageCode();
    }
    catch (e) {
    }

    this.getDefaultValueForSearchBy();

  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.globalSearchText = '';
  }

  async toggleButtonClick(toggleMenu: string) {
    this.isFindButtonClicked = false;
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.currentToggleMenu = toggleMenu;
    if (this.currentToggleMenu === 'daily-reports') {
      this.getDefaultFromAndToDate();
      this.viewUserSelected = 'All';
      this.translate.get(['HOME.selectedLanguageForDatePicker'])
        .subscribe(data => {
          this.dateAdapter.setLocale(data['HOME.selectedLanguageForDatePicker']);
        });
      try {
        await this.getCustomerList();
      }
      catch (e) {
      }

      try {
        await this.getDeliverySites();
      }
      catch (e) {
      }
      /* if (this.isLoggedInUserPortalAdmin ) {
      //   this.customerIdUserSelected = '';
      // } else {
        this.customerIdUserSelected = this.customerId;
      } */

      // Load data on load
      this.getReportsForAll();

      //   this.showDeliverySiteColumn = true;
      //   this.showDepotNameColumn = false;
      // this.showProductNameColumn = false;
      // this.showDeliverySiteColumn = false;
      // this.showProdDepotColumn = false;
      if (this.countryCode === 'GBR') {
        this.showUK = false;
      }
      else {
        this.showUK = true;
      }

      /* this.translate.get(['HOME.itemsPerPageLabel'])
        .subscribe(data => {
          this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
        }); */
    } else if (this.currentToggleMenu === 'shipped-deliveries') {
      let currentYear: number = this.getCurrentYear();
      this.getYearList(currentYear);
      this.yearUserSelected = currentYear;
      this.monthUserSelected = ((this.userInfo && (this.userInfo.RoleID === "PA")) ? this.getCurrentMonth() : '');
      this.getMonthsListObject();

      try {
        await this.getCustomers()
      } catch (e) {
      }

      // Get Shipped deliveries list populated by default on page load
      // this.findSalesReport();
    }
  }

  getCurrentYear(): number {
    const date = this.sharedService.getCurrentDateTime();
    return date.getUTCFullYear();
  }

  getYearList(currYear: number) {
    this.yearList = [];
    this.yearList.push(currYear - 1, currYear);
  }

  getMonthsListObject() {
    this.monthsListObject['01'] = 'JAN';
    this.monthsListObject['02'] = 'FEB';
    this.monthsListObject['03'] = 'MAR';
    this.monthsListObject['04'] = 'APR';
    this.monthsListObject['05'] = 'MAY';
    this.monthsListObject['06'] = 'JUN';
    this.monthsListObject['07'] = 'JUL';
    this.monthsListObject['08'] = 'AUG';
    this.monthsListObject['09'] = 'SEP';
    this.monthsListObject['10'] = 'OCT';
    this.monthsListObject['11'] = 'NOV';
    this.monthsListObject['12'] = 'DEC';
  }

  getCurrentMonth(): string {
    const date = this.sharedService.getCurrentDateTime();
    let monthCode: string;
    monthCode = ((date.getMonth() + 1) < 10) ? ('0' + (date.getMonth() + 1).toString()) : (date.getMonth() + 1).toString();
    return monthCode;
  }

  findSalesReport() {
    this.isRequestCompleted = false;
    this.dataSource = new MatTableDataSource([]);
    this.shippedDeliveriesService.getSalesReportLocation(this.yearUserSelected.toString(), this.countryCode, this.customerIdUserSelected)
      .subscribe(
        response => {
          if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let filteredData = [];
            for (var report of response['SalesReportCollection']) {
              if (!this.monthUserSelected || (this.monthUserSelected && this.monthsListObject && this.monthsListObject[this.monthUserSelected] === report['Month'])) {
                // LastModifiedDate format handled in html file
                /* if (report['LastModifiedDate']) {
                  report['LastModifiedDate'] = moment(report['LastModifiedDate']).format('DD/MM/YYYY');
                } */
                filteredData.push(report);
              }
            }
            this.dataSource = new MatTableDataSource(filteredData);
            this.dataSource.sort = this.sort;
            this.isRequestCompleted = true;
          }
          this.isRequestCompleted = true;
        });
  }

  downloadExcel(data: any) {
    if (data['PATH']) {
      this.downloadExcelSharepoint(data['PATH'], data['CUSTOMER_Name'], data['YEAR'], data['Month'])
    } else {
      this.openDialog('Sorry! No file available.', null, false, 'no-file-available');
    }
  }

  downloadExcelSharepoint(path: string, customerName: string, year: string, month: string) {
    let pdfData: string;
    let filepath = path;
    let sharepointData = {
      "Username": "API.Integration@nynas.onmicrosoft.com",
      "Password": "VIN24jkjXXwdjwnvbnas",
      "Domain": "nynas",
      "SourceFolderPath": "/sites/PortalOperationalDocuments",
      "FilePath": filepath.replace(/ /g, "%20")
    }

    this.nynasUpdateService.getSharepointDoc(sharepointData)
      .subscribe(
        res => {
          if (res["ResponseCode"] == "200") {
            pdfData = res["EncodedFileData"]
            const byteArray = new Uint8Array(atob(pdfData).split('').map(char => char.charCodeAt(0)));
            const a = document.createElement('a');
            const blob = new Blob([byteArray], { type: 'text/csv' });

            if (this.checkIfBrowserIsIE()) {
              const nav = (window.navigator as any);
              if (nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(blob, (customerName + '_' + year + '_' + month + '.csv'));
              }
              
             // window.navigator.msSaveOrOpenBlob(blob, (customerName + '_' + year + '_' + month + '.csv'));
            } else {
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = customerName + '_' + year + '_' + month + '.csv';
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();
            }

          } else {
            this.openDialog('Sorry! No file available.', null, false, 'no-file-available');
          }
        });
  }

  openDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,
      },
      height: '35%',
      width: (this.mobileQuery.matches ? '50%' : '25%'),
    });
  }

  async getCustomers() {
    this.Customers = [];
    return new Promise<void>((resolve, reject) => {
      this.forecastEntryService.getCustomerNames(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.Customers = res["Customers"]
            if (this.userInfo) {
              this.customerIdUserSelected = (this.userInfo.RoleID === "PA") ? '' : (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
            } else {
              this.customerIdUserSelected = '';
            }
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  checkIfBrowserIsIE(): boolean {
    var isBrowserIE = false;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) { // If Internet Explorer browser
      isBrowserIE = true;
    }
    return isBrowserIE;
  }

  sortData(sort: Sort) {
    let sortedData = this.dataSource.data.sort((row1: any, row2: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active === 'Month') {
        // Apply custom sorting for months, months converted into date format and then sorted, eg., JAN => 01-JAN-YYYY
        return compare(row1[sort.active] ? (new Date('01-' + row1[sort.active] + this.yearUserSelected.toString()).getMonth() + 1) : 0, row2[sort.active] ? (new Date('01-' + row2[sort.active] + this.yearUserSelected.toString()).getMonth() + 1) : 0, isAsc);
      } else if (sort.active === 'YEAR') {
        // Apply sorting for numeric rows of year
        return compare(row1[sort.active] ? +row1[sort.active] : 0, row2[sort.active] ? +row2[sort.active] : 0, isAsc);
      }
      // Apply sorting for remaining text rows
      return compare(row1[sort.active] ? row1[sort.active].toUpperCase() : '', row2[sort.active] ? row2[sort.active].toUpperCase() : '', isAsc);
    });

    // Update table dataSource with the sorted records
    this.dataSource = new MatTableDataSource(sortedData);
  }

  async getCustomerList() {
    this.customerList = [];
    return new Promise<void>((resolve, reject) => {
      this.forecastactualweeklyswedenService.getCustomerList(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.customerList = res["Customers"]
            /* if (this.isLoggedInUserPortalAdmin) {
            //   this.customerIdUserSelected = '';
            // } else {
              this.customerIdUserSelected = this.customerId;
            } */
            this.customerIdUserSelected = this.customerId;
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  async getDeliverySites() {
    this.deliverySiteList = [];
    this.deliverySiteObjectList = [];
    return new Promise<void>((resolve, reject) => {
      let showUserDataOnly = ((this.countryCode === 'SWE' || this.countryCode === 'NOR' || this.countryCode === 'GBR' || this.countryCode === 'DNK') && (this.userInfo && this.userInfo.RoleID === "U"));
      this.shippedDeliveriesService.fetchDeliverySitesData(this.userID, this.countryCode, "", this.customerIdUserSelected, showUserDataOnly).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            let deliverySiteList = (response["DeliverySiteCollection"] || response["DeliverySites"]);
            for (let deliverySiteObject of deliverySiteList) {
              this.deliverySiteObjectList.push(deliverySiteObject);
              this.deliverySiteList.push(deliverySiteObject["DeliverySite"] || deliverySiteObject["DeliverySiteName"]);
              this.deliverySiteUserSelected = '';
            }
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  getReportsForTable() {
    if (this.viewUserSelected == "All") {
      this.getReportsForAll();
    }
    if (this.viewUserSelected == "By deliverysite") {
      this.getReportsForTableByDS();
    } else if (this.viewUserSelected == "By product") {
      this.getReportsForTableByViewProduct();
    } else if (this.viewUserSelected == "By depot") {
      this.getReportsForTableByViewDepot();
    } else if (this.viewUserSelected == "By product and depot") {
      this.getReportsForTableByViewProductAndDepot();
    }
  }

  getReportsForAll() {
    /* this.showDeliverySiteColumn = true;
    this.showDepotNameColumn = true;
  this.showProductNameColumn = true; */
    this.dataSource = new MatTableDataSource([]);
    this.displayTable = false;
    this.isRequestCompleted = false;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;
    this.showDeliverySiteColumn = false;

    if (!this.validateFromAndToDate()) {
      return;
    }

    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      let showUserDataOnly = ((this.countryCode === 'SWE' || this.countryCode === 'NOR' || this.countryCode === 'GBR' || this.countryCode === 'DNK') && (this.userInfo && this.userInfo.RoleID === "U"));
      this.shippedDeliveriesService.fetchDailyReportsData(this.countryCode, this.searchBy, this.getFormattedDate(this.startDate), this.getFormattedDate(this.endDate), this.userID, this.customerIdUserSelected, jdeAddressNumber, showUserDataOnly).subscribe
        (response => {

          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let dailyReportForAll = [];
            let dailyReportObject = {};
            let customerCollection = response["CustomerCollection"];

            if (customerCollection && customerCollection.length) {
              for (let customerObject of customerCollection) {
                if (customerObject && customerObject['DeliverySiteCollection'] && customerObject['DeliverySiteCollection'].length) {
                  let customerName = customerObject['CUSTOMER_NAME'];
                  for (let deliverySiteObject of customerObject['DeliverySiteCollection']) {
                    if (deliverySiteObject && deliverySiteObject['DetailsCollection'] && deliverySiteObject['DetailsCollection'].length) {
                      let deliverySiteName = deliverySiteObject['DELIVERY_SITE'];
                      // let jdeAddressNumber = deliverySiteObject['JDE_ADDRESS_NUMBER'];
                      for (let detailsObject of deliverySiteObject['DetailsCollection']) {
                        dailyReportObject['CUSTOMER_NAME'] = customerName;
                        dailyReportObject['DELIVERY_SITE'] = deliverySiteName;
                        dailyReportObject['PRODUCT_NAME'] = detailsObject['PRODUCT_NAME'];
                        dailyReportObject['DEPOT_NAME'] = detailsObject['DEPOT_NAME'];
                        // dailyReportObject['JDE_ADDRESS_NUMBER'] = jdeAddressNumber;
                        dailyReportObject['CUSTOMER_PO'] = detailsObject['CUSTOMER_PO'];
                        dailyReportObject['SHIPPED_QTY'] = detailsObject['SHIPPED_QTY'];
                        dailyReportObject['ACTUAL_SHIP_DATE'] = detailsObject['ACTUAL_SHIP_DATE'];
                        dailyReportObject['ORDER_DATE'] = detailsObject['ORDER_DATE'];
                        dailyReportObject['ORDER_TYPE'] = detailsObject['ORDER_TYPE'];
                        dailyReportObject['ORDER_NUMBER'] = detailsObject['ORDER_NUMBER'];
                        // dailyReportObject['DELIVERY_GROUP_NAME'] = detailsObject['DELIVERY_GROUP_NAMEOdown'];
                        dailyReportObject['INVOICE_NUMBER'] = detailsObject['INVOICE_NUMBER'];

                        // Do not show invoice date if its value is "01-JAN-00"
                        if (detailsObject['INVOICE_DATE'] && detailsObject['INVOICE_DATE'] !== "01-JAN-00") {
                          dailyReportObject['INVOICE_DATE'] = detailsObject['INVOICE_DATE'];
                        }

                        dailyReportObject['INCOTERMS'] = detailsObject['INCOTERMS'];
                        dailyReportObject['ADHESIVE_CONTENT'] = detailsObject['ADHESIVE_CONTENT'];
                        dailyReportObject['TEMPERATURE'] = detailsObject['TEMPERATURE'];
                        dailyReportObject['productTypeId'] = detailsObject['productTypeId'];
                        dailyReportObject['REQUESTED_DATE'] = detailsObject['REQUESTED_DATE'];
                        dailyReportObject['ORIGINAL_ORDER_NUMBER'] = detailsObject['ORIGINAL_ORDER_NUMBER'];
                        dailyReportObject['ORIGINAL_ORDER_NUMBER_UK'] = detailsObject['ORIGINAL_ORDER_NUMBER'];
                        dailyReportObject['INVOICE_NUMBER_UK'] = detailsObject['INVOICE_NUMBER'];
                        dailyReportObject['DELIVERY_TICKET'] = detailsObject['DELIVERY_TICKET'];
                        dailyReportObject['ENGINE_VEHICLE_NUMBER'] = detailsObject['ENGINE_VEHICLE_NUMBER'];
                        dailyReportObject['COMPARTMENT_VEHICLE_NUMBER'] = detailsObject['COMPARTMENT_VEHICLE_NUMBER'];
                        dailyReportForAll.push(dailyReportObject);
                        dailyReportObject = {};
                      }
                    }
                  }
                }
              }
            }

            // Reset already present data, used to prevent repeated records displayed in table rows for customer, delivery site, depot and product names.
            // this.resetLastRowValueObject();



            // for (let weekData of weekCollection) {
            //   let dailyObject = {};
            //   /* if (this.lastRowValueObject['CustomerName'] !== weekData['CustomerName']) {
            //     weekObject['CustomerName'] = weekData['CustomerName'];
            //     this.lastRowValueObject['CustomerName'] = weekData['CustomerName'];
            //   } */
            //   dailyObject['CustomerNameFromResponse'] = weekData['CustomerName']; // Needed for sorting, not for display
            //   dailyObject['DeliverySiteName'] = weekData['DeliverySiteName'];
            //   dailyObject['ProductName'] = weekData['ProductName'];
            //   dailyObject['DepotName'] = weekData['DepotName'];
            //   dailyObject['WeekNumber'] = weekData['WeekNumber'];
            //   dailyObject['forecastquantity'] = weekData['forecastquantity'];
            //   dailyObject['FIRST_SNAPSHOT_QUANTITY'] = weekData['FIRST_SNAPSHOT_QUANTITY'];
            //   dailyObject['actualquantity'] = weekData['actualquantity'];
            //   dailyObject['difference'] = weekData['difference'];
            //   dailyObject['CustomerID'] = weekData['CustomerID'];
            //   dailyObject['DepoId'] = weekData['DepoId'];
            //   dailyObject['ProductId'] = weekData['ProductId'];

            //   // Set flag showRowTopBorder to true for all group of rows, except first row group, to show line separator
            //   /* if (dailyObject['CustomerName'] && dailyReportForAll && dailyReportForAll.length) {
            //     dailyObject['showRowTopBorder'] = true;
            //   } */

            //   dailyReportForAll.push(dailyObject);
            // }
            this.dataSource = new MatTableDataSource(dailyReportForAll);
            this.dataSource.sort = this.dailyReportTableSort;
            this.dataSource.paginator = this.paginator;

            // No need to convert to lower case when date object is passed
            /* this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
              if (data[sortHeaderId] && sortHeaderId !== 'ActualShipDate' && sortHeaderId !== 'InvoiceDate') {
                return data[sortHeaderId].toLocaleLowerCase();
              }
              return  data[sortHeaderId];
            }; */
            
            this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
              if (data[sortHeaderId] && sortHeaderId !== 'ACTUAL_SHIP_DATE' && sortHeaderId !== 'INVOICE_DATE' && sortHeaderId !== 'REQUESTED_DATE') {
                return data[sortHeaderId].toLocaleLowerCase();
              } else if (data[sortHeaderId] && (sortHeaderId === 'ACTUAL_SHIP_DATE' || sortHeaderId === 'INVOICE_DATE' || sortHeaderId === 'REQUESTED_DATE')) {
                let date = data[sortHeaderId] ? data[sortHeaderId] : '';

                // Commented old code, kept for future reference
                // return new Date(data[sortHeaderId].split("-").reverse().join("-"));

                return new Date(date);
              }
              return data[sortHeaderId];
            };

            this.displayTable = (dailyReportForAll && dailyReportForAll.length && dailyReportForAll.length > 0);
            this.isRequestCompleted = true;
            this.showDepotNameColumn = true;
            this.showProductNameColumn = true;
            this.showDeliverySiteColumn = true;
            this.isFindButtonClicked = true;

            resolve();

          } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
          else {
            this.displayTable = false;
            this.isRequestCompleted = true;
            reject();
          }
        })
    });
  }

  getReportsForTableByDS() {
    this.showDeliverySiteColumn = false;
    this.showDepotNameColumn = true;
    this.showProductNameColumn = true;
    //this.showDeliverySiteColumn = true;
    this.showProdDepotColumn = true;

  }

  getReportsForTableByViewProduct() {
    this.showDeliverySiteColumn = true;
    this.showDepotNameColumn = true;
    this.showProductNameColumn = false;

  }

  getReportsForTableByViewDepot() {
    this.showDeliverySiteColumn = true;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = true;


  }

  getReportsForTableByViewProductAndDepot() {
    this.showDeliverySiteColumn = true;
    this.showDepotNameColumn = false;
    this.showProductNameColumn = false;

  }

  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        let deliverySiteObjectName = (deliverySiteObject['DeliverySite'] || deliverySiteObject['DeliverySiteName']);
        if (deliverySiteObject && deliverySiteObjectName && deliverySiteObjectName === deliverySiteName) {
          jdeAddressNumber = (deliverySiteObject['JdeAddressNumber'] || deliverySiteObject['JDE_Address_Number']);
          break;
        }
      }
    }
    return jdeAddressNumber;
  }

  getFormattedDate(date: Date): string {
    if (date) {
      var datePipe = new DatePipe("en-US");
      return datePipe.transform(new Date(date), 'dd-MMM-yy').toLocaleUpperCase();
    }
    return '';
  }

  getDefaultFromAndToDate() {
    let currentDateTime = this.sharedService.getCurrentDateTime();
    let currentYear = currentDateTime.getFullYear();
    let currentMonth = currentDateTime.getMonth() + 1;
    this.startDate = new Date(currentMonth + '/01/' + currentYear); // Get date of 1st January of current year
    this.endDate = currentDateTime;
  }

  downloadReportsData() {
    if (!this.validateFromAndToDate()) {
      return;
    }
    return new Promise<void>((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.deliverySiteUserSelected);
      let customerName: string;
      let showUserDataOnly = ((this.countryCode === 'SWE' || this.countryCode === 'NOR' || this.countryCode === 'GBR' || this.countryCode === 'DNK') && (this.userInfo && this.userInfo.RoleID === "U"));
      this.shippedDeliveriesService.fetchDailyReportsData(this.countryCode, this.searchBy, this.getFormattedDate(this.startDate), this.getFormattedDate(this.endDate), this.userID, this.customerIdUserSelected, jdeAddressNumber, showUserDataOnly).subscribe
        (response => {
          if (response["ResponseCode"] == 200 && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            let dailyReportForAll = [];
            let dailyReportObject = {};
            let customerCollection = response["CustomerCollection"];
            if (customerCollection && customerCollection.length) {
              for (let customerObject of customerCollection) {
                if (customerObject && customerObject['DeliverySiteCollection'] && customerObject['DeliverySiteCollection'].length) {
                  customerName = customerObject['CUSTOMER_NAME'];
                  for (let deliverySiteObject of customerObject['DeliverySiteCollection']) {
                    if (deliverySiteObject && deliverySiteObject['DetailsCollection'] && deliverySiteObject['DetailsCollection'].length) {
                      let deliverySiteName = deliverySiteObject['DELIVERY_SITE'];
                      // let jdeAddressNumber = deliverySiteObject['JDE_ADDRESS_NUMBER'];
                      for (let detailsObject of deliverySiteObject['DetailsCollection']) {
                        dailyReportObject['Delivery Place'] = deliverySiteName;
                        dailyReportObject['Product'] = detailsObject['PRODUCT_NAME'];
                        // dailyReportObject['Nynas Delivery Place'] = jdeAddressNumber;

                        if (this.countryCode === 'SWE' || this.countryCode === 'DNK') {
                          dailyReportObject['Delivery ticket number'] = detailsObject['CUSTOMER_PO'];
                        } else {
                          dailyReportObject['Customer PO number'] = detailsObject['CUSTOMER_PO'];
                        }

                        // Commented below code, kept for future reference
                        // dailyReportObject['Quantity MT'] = detailsObject['SHIPPED_QTY'] ? this.decimalPipe.transform(detailsObject['SHIPPED_QTY'], '', 'fr-FR') : '';
                        // dailyReportObject['Actual ship date'] = detailsObject['ACTUAL_SHIP_DATE'];

                        dailyReportObject['Quantity MT'] = detailsObject['SHIPPED_QTY'] ? parseFloat(detailsObject['SHIPPED_QTY']) : '';

                        if (this.countryCode === 'SWE' || this.countryCode === 'DNK') {
                          dailyReportObject['Ship date'] = new Date(detailsObject['ACTUAL_SHIP_DATE']);
                        } else {
                          dailyReportObject['Actual ship date'] = new Date(detailsObject['ACTUAL_SHIP_DATE']);
                        }

                        dailyReportObject['Nynas Order Number'] = detailsObject['ORDER_NUMBER'];


                        // Adhesive content % & Temperature columns to be shown for Finland only
                        if (this.countryCode === 'FIN') {
                          dailyReportObject['Adhesive content %'] = detailsObject['ADHESIVE_CONTENT'];
                          dailyReportObject['Temperature'] = detailsObject['TEMPERATURE'];
                        }

                        // Some columns not to be shown for UK
                        if (this.countryCode !== 'GBR') {
                          dailyReportObject['Customer name'] = customerName;
                          dailyReportObject['Loading Point'] = detailsObject['DEPOT_NAME'];
                          dailyReportObject['Type of product'] = detailsObject['productTypeId'];
                          dailyReportObject['Invoice number'] = detailsObject['INVOICE_NUMBER'];

                          // Commented below code, kept for future reference
                          // dailyReportObject['Invoice Date'] = detailsObject['INVOICE_DATE'];
                          // dailyReportObject['Invoice Date'] = new Date(detailsObject['INVOICE_DATE']);

                          // Do not show invoice date if its value is "01-JAN-00"
                          if (detailsObject['INVOICE_DATE'] && detailsObject['INVOICE_DATE'] !== "01-JAN-00") {
                            dailyReportObject['Invoice Date'] = new Date(detailsObject['INVOICE_DATE']);
                          }

                          dailyReportObject['Incoterms'] = detailsObject['INCOTERMS'];
                          dailyReportObject['Nynas Order type'] = detailsObject['ORDER_TYPE'];
                          // dailyReportObject['Group name'] = detailsObject['DELIVERY_GROUP_NAME'];
                          dailyReportObject['Nynas Original order number'] = detailsObject['ORIGINAL_ORDER_NUMBER'];
                        }

                        // Requested date column to be shown for UK only
                        if (this.countryCode === 'GBR') {
                          // Commented below code, kept for future reference
                          // dailyReportObject['Requested date'] = detailsObject['REQUESTED_DATE'];

                          dailyReportObject['Requested date'] = new Date(detailsObject['REQUESTED_DATE']);
                          dailyReportObject['Invoice number'] = detailsObject['INVOICE_NUMBER'];
                          dailyReportObject['Delivery ticket number'] = detailsObject['DELIVERY_TICKET'];
                          dailyReportObject['Nynas original order number'] = detailsObject['ORIGINAL_ORDER_NUMBER'];

                        }

                        // Engine number and Compartment vehicle number columns to be shown for Sweden and Denmark only
                        if (this.countryCode === 'SWE' || this.countryCode === 'DNK') {
                          dailyReportObject['Engine number'] = detailsObject['ENGINE_VEHICLE_NUMBER'];
                          dailyReportObject['Compartment vehicle number'] = detailsObject['COMPARTMENT_VEHICLE_NUMBER'];
                        }

                        dailyReportForAll.push(dailyReportObject);
                        dailyReportObject = {};
                      }
                    }
                  }
                }
              }
            }

            // header list for Finland
            let headerList = ['Customer name', 'Customer PO number', 'Loading Point', 'Actual ship date', 'Delivery Place', 'Product', 'Quantity MT', 'Type of product', 'Invoice number', 'Invoice Date', 'Incoterms', 'Nynas Order Number', 'Nynas Order type', 'Nynas Original order number', 'Adhesive content %', 'Temperature'];

            // Adhesive content % & Temperature columns not to be shown for Sweden, Norway and Denmark
            if (this.countryCode === 'SWE' || this.countryCode === 'NOR' || this.countryCode === 'DNK') {
              headerList = ['Customer name', 'Customer PO number', 'Loading Point', 'Actual ship date', 'Delivery Place', 'Product', 'Quantity MT', 'Type of product', 'Invoice number', 'Invoice Date', 'Incoterms', 'Nynas Order Number', 'Nynas Order type', 'Nynas Original order number'];
            }

            // Engine number and Compartment vehicle number columns to be shown for Sweden and Denmark only
            if (this.countryCode === 'SWE' || this.countryCode === 'DNK') {
              headerList = ['Customer name', 'Delivery ticket number', 'Loading Point', 'Ship date', 'Delivery Place', 'Product', 'Quantity MT', 'Type of product', 'Invoice number', 'Invoice Date', 'Incoterms', 'Nynas Order Number', 'Nynas Order type', 'Nynas Original order number', 'Engine number', 'Compartment vehicle number'];
            }

            // Some columns not to be shown for UK
            if (this.countryCode === 'GBR') {
              headerList = ['Delivery ticket number', 'Customer PO number', 'Actual ship date', 'Delivery Place', 'Product', 'Quantity MT', 'Nynas Order Number', 'Requested date', 'Invoice number', 'Nynas original order number'];
            }

            let shippedDeliveriesList: any = [];
            if (dailyReportForAll && dailyReportForAll.length) {
              for (let dailyReportObject of dailyReportForAll) {
                let reportObject: any = {};
                for (let header of headerList) {
                  reportObject[header] = dailyReportObject[header];
                }
                shippedDeliveriesList.push(reportObject);
              }
            }
    
            // Commented old code, kept for future reference
            // this.exportDataInExcelFile(dailyReportForAll, 'Daily reports', headerList);
    
            // headerList = ["Depot name", "Product name", "Type", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total"];
        
            this.sharedService.downloadDataInExcelFile(shippedDeliveriesList, 'Daily reports', headerList);

            resolve();
          }
          else {
            this.showStatusDialog(false, 'Something-went-wrong');
            reject();
          }
        })
    });
  }

  exportDataInExcelFile(data: any[], excelFileName: string, header: any): void {
    if (data) {

      // Commented below code, kept for future reference
      // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((data), { header: header });

      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header: header, dateNF: 'DD-MMM-YY;@', cellDates: true });

      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const Report: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(Report, excelFileName + EXCEL_EXTENSION);
    }
  }

  showStatusDialog(isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        headerNumber: headerNumber,
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,

      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  validateFromAndToDate(): boolean {
    this.fromDateIsOlderThan1stJanuary2020 = (this.startDate && new Date(this.startDate) < this.january012020Date);
    return ((this.startDate !== null && this.endDate !== null) && !this.fromDateIsOlderThan1stJanuary2020);
  }

  checkIfMenuIdIsAllowedToLoggedInUser(menuId: string): boolean {
    let menuAccessList = [];
    if (this.userInfo && this.userInfo.Menus && this.userInfo.Menus.length) {
      for (let menu of this.userInfo.Menus) {
        menuAccessList.push(menu.MenuID);
      }
    }
    return (menuAccessList.includes(menuId));

    // Commented below code, kept for future reference, previously Daily reports page was shown for portal admin role only
    // return this.isLoggedInUserPortalAdmin;
  }

  getDefaultTabToBeDisplayed(): string {

    // Commented below code, kept for future reference
    /* let defaultTabName: string;
    if (this.checkIfMenuIdIsAllowedToLoggedInUser('50')) {
      defaultTabName = 'daily-reports';
    } else if (this.checkIfMenuIdIsAllowedToLoggedInUser('47')) {
      defaultTabName = 'shipped-deliveries';
    }
    return defaultTabName; */

    // Commented below code, kept for future reference, previously Daily reports page was shown for portal admin role only
    /* if (this.isLoggedInUserPortalAdmin) {
      return 'daily-reports';
    }
    return 'shipped-deliveries'; */

    return 'daily-reports';
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.cd.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.cd.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
      // this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }

  async getSelectedLanguageCode() {
    return new Promise<void>((_resolve, _reject) => {
      this.translate.get(['HOME.languageCode'])
        .subscribe(data => {
          if (data) {
            this.selectedLanguage = data['HOME.languageCode'];  // Get language code of EN, FI, SE or NR, as per flag selected on page
            _resolve();
          }
          else {
            _reject();
          }
        });
    });
  }

  getDefaultValueForSearchBy() {
    if (this.countryCode === "GBR") {
      this.searchBy = "REQUESTED_DATE";
    } else {
      this.searchBy = "ACTUAL_SHIP_DATE";
    }
  }

  
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
