<div class="container-fluid no-margin-padding-mv">
    <div class="row">
        <div class="col-sm">
            <div class="text-center">
                <span mat-dialog-title class="header-text pt-3">{{'HOME.Forecastevolution'| translate}}</span>
            </div>
            <div class="text-right pb-2">
                <span [hidden]="countryCode === 'SWE'" class="actual-quantity-text">{{'HOME.Actual(metric_ton)'| translate}} : {{
                    actualQuantity }}</span>
                <span [hidden]="countryCode !== 'SWE'" class="actual-quantity-text">{{'HOME.ActualMT'| translate}} : {{
                    actualQuantity }}</span>
            </div>
            <mat-table #table [dataSource]="dataSource" class="horizontal_scrollbar">
                <ng-container matColumnDef="CUSTOMER_NAME">
                    <mat-header-cell class="customerNameColumn" *matHeaderCellDef>
                        {{'HOME.Customername'| translate}}
                    </mat-header-cell>
                    <mat-cell class="customerNameColumn" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.CUSTOMER_NAME}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DELIVERY_SITE">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.Deliverysite'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.DELIVERY_SITE}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DEPOT_NAME">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.depotname'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.DEPOT_NAME}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="PRODUCT_NAME">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.Pname'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.PRODUCT_NAME}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="PLANNED_QUANTITY">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.ForecastMT'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.PLANNED_QUANTITY}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="WEEK">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.Week'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.WEEK}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SNAPSHOT_WEEK">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.Forecastatweek'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.SNAPSHOT_WEEK}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SNAP_Number">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.Snapnumber'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.SNAP_Number}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="FORECAST_ACCURACY">
                    <mat-header-cell class="column_medium"
                        *matHeaderCellDef>
                        {{'HOME.Forecastaccuracy'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium"
                        *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.FORECAST_ACCURACY}}
                    </mat-cell>
                </ng-container>
                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
            </mat-table>
        </div>
    </div>
    <div class="row pt-3 close-button-row-mv">
        <div class="col-sm">
            <mat-dialog-actions align="center">
                <span class="pl-2">
                    <button class="btn btn-sm btn-primary dialog-footer-button cancel-button" (click)="cancel()"
                        mat-dialog-close tabindex="-1">{{'HOME.Close'| translate}}</button>
                </span>
            </mat-dialog-actions>
        </div>
    </div>
</div>