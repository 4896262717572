
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-2 col-md-2">

            <div class="sidenav">
                <div>
                    <img class="menu-logo" src="../../../assets/nynas_logo.png">
                </div>
                <!-- <div>
                    <p class="text-center menu-heading">Customer Portal</p>
                </div> -->
                <!-- <div class="menu-sub-div">
                    <p class="text-center menu-sub-heading">PRODUCT</p>
                </div> -->
                <div class="sidenavbar">
                    <div class="menu-item-padding">
                        <a routerLink="/dashboard" class="menu-list-names text-left">
                            <i class="fa fa-home" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;Home
                        </a>
                    </div>
                    <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Product information'}"
                        (click)="showSelectedMenuItem('Product information')">
                        <a [routerLink]="['product-information']"
                            [ngClass]="{'selected-class-text': selectedItem === 'Product information'}"
                            class="menu-list-names text-left">
                            <i class="fab    fa-product-hunt" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Product information
                        </a>
                    </div>
                    

                    <!-- <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Product maintenance'}"
                        (click)="showSelectedMenuItem('Product maintenance')">
                        <a [routerLink]="['product-maintenance']"
                            [ngClass]="{'selected-class-text': selectedItem === 'Product maintenance'}"
                            class="menu-list-names text-left">
                            <i class="fab  fa-product-hunt" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Product maintenance
                        </a>
                    </div> -->

                    <!-- <div class="menu-item-padding"
                        [ngClass]="{'selected-class': selectedItem ==='DISTRIBUTION PROGRAMME'}"
                        (click)="showSelectedMenuItem('DISTRIBUTION PROGRAMME')">
                        <a [routerLink]="['distribution-program']"
                            [ngClass]="{'selected-class-text': selectedItem === 'DISTRIBUTION PROGRAMME'}"
                            class="menu-list-names text-left">
                            <i class="fas fa-truck" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Distribution Programme
                        </a>
                    </div> -->
                    <div class="menu-item-padding"
                    [ngClass]="{'selected-class': selectedItem ==='Delivery guide'}"
                    (click)="showSelectedMenuItem('Delivery guide')"
                    *ngIf="validateMenu('14')">
                    <a [routerLink]="['delivery-guide']"
                        [ngClass]="{'selected-class-text': selectedItem ==='Delivery guide'}"
                        class="menu-list-names text-left">
                        <i class="fas fa-truck" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Delivery guide
                    </a>
                </div>
                   
                    <!-- <div class="menu-item-padding" [ngClass]="{'selected-class': selectedItem ==='Ordering procedure'}"
                        (click)="showSelectedMenuItem('Ordering procedure')">
                        <a [routerLink]="['ordering-procedure']"
                            [ngClass]="{'selected-class-text': selectedItem === 'Ordering procedure'}"
                            class="menu-list-names text-left">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Ordering procedure
                        </a>
                    </div> -->





                </div>
                <!-- <div class="sideInfo">
                    <div> <span class="sideInfoLabel">User name: </span><span >{{name}}</span></div>
                    <div><span class="sideInfoLabel">Company name:</span><span>{{userInfo?.CustomerName}}</span></div>
                    <div><span class="sideInfoLabel">User group: </span><span>{{userInfo?.UserGroupName}}</span></div>
                    <div><span class="sideInfoLabel">Login time: </span><span>{{userInfo?.LastLoginTime | date:'yyyy-MM-dd HH:mm:ss' }}</span></div>
                </div> -->
            </div>

        </div>
        <div class="col-lg-12 col-md-12">
            <div class="row header">

                <br>
                <span>{{selectedItem}}</span>
            </div>
            <div class="row">
                <div class='col-lg-12 col-md-12'>
                    <div class="main ">
                        <router-outlet></router-outlet>
                    </div>
                    </div>
                    </div>
        </div>
       
        
      
       
      
    </div>

</div>
