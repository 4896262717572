import { MediaMatcher } from '@angular/cdk/layout';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared-modules/components/confirm-dialog/confirm-dialog.component';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { Customer } from 'src/app/user-management/customer';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import { UserGroup } from 'src/app/user-management/user-group';
import * as XLSX from 'xlsx';
import { AddDeliverySiteComponentService } from '../add-delivery-site/add-delivery-site.component.service';
import { Country } from '../add-delivery-site/country';
import { AddEditDeliverySiteService } from '../add-edit-delivery-site/add-edit-delivery-site.service';
import { ApproveDeliverySiteComponent } from '../approve-delivery-site/approve-delivery-site.component';
import { MyCompanyService } from '../my-company.service';
import { UpdateDeliverySiteProductsComponent } from '../update-delivery-site-products/update-delivery-site-products.component';
import { DelSite } from './DelSite';
import { ModifyDeliverySiteService } from './modify-delivery-site.service';
 
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YY',
  },
  display: {
    dateInput: 'DD-MMM-YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-modify-delivery-site',
  templateUrl: './modify-delivery-site.component.html',
  styleUrls: ['./modify-delivery-site.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DecimalPipe
  ]
})
export class ModifyDeliverySiteComponent implements OnInit, OnDestroy {
 
  companyName: string = "YIT Kotka Illaria"
  contactName: string = "Ingrid Bergman";
  contactEmail: string = "Ingrid.Bergman@nynas.com";
  contactPhone: string = "+46-8-520 923 00";
  AddrLine1: string = "Saga Matkat Oy";
  AddrLine2: string = "Saga Tours Ltd., Albertinkatu";
  AddrCity: string = "Helsinki";
  AddrState: string = "Uusimaa";
  AddrPin: string = "00180";
  AddrCountry: string = "Finland";
 
  userID = '';
  country = '';
  customerName = '';
  companyId: string = "101"
  customerList: Customer[] = [];
  userGroupList: UserGroup[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: any;
  public displayedColumnsDelSite = ['DeliverySite', 'BillingReference', 'PrimaryContact', 'Address', 'Status',
                                    'ApprovalStage', 'UpdatedOn', 'Actions'];
  userData: any;
  isRequestCompleted: boolean;
  // dataSourceDelSite: any;
  delSiteData: any;
  selectedCompanyId: string = "";
  public dataSourceDelSite = new MatTableDataSource();
  userInfo: any;
  isLoggedInUserPortalAdmin: boolean;
  isLoggedInUserAdmin: boolean;
  countryCode: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  isLoggedInUserDepotAdmin: boolean;
  selectedStatus: string = "";
  statusList: any;
  fetchDeliverySitesEventSubscription: Subscription;
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;
  role: string;
  countryList: Country[] = [];
 
  constructor(private modifyDeliverySiteService: ModifyDeliverySiteService,
    private router: Router,
    private sharedService: AppService,
    private myCompanyService : MyCompanyService,
    private dialog: MatDialog,
    private addDeliverySiteComponentService: AddDeliverySiteComponentService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService, 
    private media: MediaMatcher,
		private globalSearchService: GlobalSearchService,
    private datePipe: DatePipe,
    private addEditDeliverySiteService: AddEditDeliverySiteService) { 
      this.userInfo = this.sharedService.getLoginResponse();
      this.isLoggedInUserPortalAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA") : false);
      this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
      this.countryCode= this.userInfo.CountryCode;
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
      this.isLoggedInUserDepotAdmin = (this.userInfo ? (this.userInfo.RoleID === "DA") : false);
      this.role = (this.userInfo ? this.userInfo.RoleID : '');
      this.fetchDeliverySitesEventSubscription = this.myCompanyService.getFetchDeliverySitesEvent().subscribe(() => {
        this.fetchDeliverySites();
      })
    }
 
  async ngOnInit() {
    /* this.delSiteData = await this.getDelSite();
    this.dataSourceDelSite = new MatTableDataSource(this.delSiteData);
    this.dataSourceDelSite.sort = this.sort; */
 
    this.statusList = ['Active', 'Inactive'];
 
    if (this.isLoggedInUserPortalAdmin || this.isLoggedInUserDepotAdmin) {
      //get customer names
      this.modifyDeliverySiteService.getCustomerList(this.countryCode)
        .subscribe(
          (response: any) => {
            if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
              for (let index of response["Customers"]) {
                this.customerList.push(new Customer(index["CustomerID"], index["CustomerName"]));
              }
            }
          });
    } else {
      this.selectedCompanyId =  (this.userInfo && this.userInfo.CustomerID) ? this.userInfo.CustomerID : '';

      // Commented old code, kept for future reference
      // this.fetchDeliverySites();
    }
 
    this.translate.get(['HOME.itemsPerPageLabel'])
                .subscribe(data => {
                  this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
                });

    // Load data on load
    this.fetchDeliverySites();

    try {
      await this.getCountryList();
    }
    catch (e) {
    }

    this.subscribeToGlobalSearchServiceSearchTerm();
  }
 
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    // unsubscribe to ensure no memory leaks
    this.fetchDeliverySitesEventSubscription.unsubscribe();
  }
 
  editDeliverySite(selectedDeliverySite: DelSite){
        this.updateSelectedItem('Add delivery site');
    const navigationExtras: NavigationExtras = {
            state: {
        selectedDeliverySite: selectedDeliverySite
            }
        };
        this.router.navigate(["../../app/my-company/add-delivery-site"], navigationExtras);
    }
 
  editDeliverySiteNew(selectedDeliverySite: DelSite) {
    this.updateSelectedItem('Add delivery site');
    const navigationExtras: NavigationExtras = {
      state: {
        selectedDeliverySite: selectedDeliverySite
      }
    };
    this.router.navigate(["../../app/my-company/add-edit-delivery-site"], navigationExtras);
  }

  async getDelSite() {
    // return new Promise((resolve, reject) => {
      var fetchDelSite: DelSite[] = [
        { DepotAddressNumber: "121456",
          DeliverySite: 'Yit Bitumen', JdeAddressNumber: "201645", DeliveryPostalCode: '54001',
          SubscriberEmailAddress: 'hannu@nynas.com', CarrierEmailAddress: 'xyz@carrier.com', Address: 'Saga Matkat Oy, Saga Tours Ltd., Albertinkatu 36 B,  00180 Helinski, Finland',
          Status: 'Approved', ApprovalStage: 'Draft to be sent to depot', Modify: []
        },
        // {
        //   DeliveryNumber: "1", DeliveryProductName: 'Yit Bitumen', BillingReference: "201645", DeliveryPostalCode: '54001',
        //   DeliveryPostOffice: 'Oulu', SubscriberEmailAddress: 'hannu@nynas.com', CarrierEmailAddress: 'xyz@carrier.com', Address: 'Saga Matkat Oy, Saga Tours Ltd., Albertinkatu 36 B,  00180 Helinski, Finland'
        // },
      ];
      //var fetchMonthlyForecastArr = fetchedDataForItemArray
    return fetchDelSite;
    // })
  }
 
  fetchDeliverySites() {
    let statusToFilter = this.selectedStatus;
    this.dataSourceDelSite.data = [];
    this.isRequestCompleted = false;
    this.modifyDeliverySiteService.getDeliverySites(this.selectedCompanyId, this.countryCode)
      .subscribe(
        (response: any) => {
          if (true) {
            if (response['DeliverySiteCollection'] && response['DeliverySiteCollection'].length) {
              let deliverySiteList = [];
              for (let deliverySite of response['DeliverySiteCollection']) {
                if (statusToFilter === "" || statusToFilter === deliverySite['ApprovalStage']) {
                  deliverySite.Address = (deliverySite.AddressLine1 ? (deliverySite.AddressLine1 + ', ') : '') +
                    (deliverySite.AddressLine2 ? (deliverySite.AddressLine2 + ', ') : '') +
                    (deliverySite.City ? (deliverySite.City + ', ') : '') +
                    (deliverySite.CountryId ? (deliverySite.CountryId + ' - ') : '') +
                    (deliverySite.DeliveryPostalCode ? (deliverySite.DeliveryPostalCode + '.') : '');

                  // Remove country name short code from phone nunber, instead use area code like +358 for Finland
                  if (deliverySite.PrimaryContact) {
                    let primaryContactWithCountryCode = deliverySite.PrimaryContact;
                    primaryContactWithCountryCode = primaryContactWithCountryCode.replace("FIN", "+358 ");
                    primaryContactWithCountryCode = primaryContactWithCountryCode.replace("SWE", "+46 ");
                    primaryContactWithCountryCode = primaryContactWithCountryCode.replace("NOR", "+47 ");
                    primaryContactWithCountryCode = primaryContactWithCountryCode.replace("EST", "+372 ");
                    primaryContactWithCountryCode = primaryContactWithCountryCode.replace("DNK", "+45 ");
                    primaryContactWithCountryCode = primaryContactWithCountryCode.replace("GBR", "+44 ");
                    deliverySite['PrimaryContactWithAreaCode'] = primaryContactWithCountryCode;
                  }

                  // Show updated on date
                  deliverySite['UpdatedOn'] = ((deliverySite['UpdatedOn'] && deliverySite['UpdatedOn'].split(':') && deliverySite['UpdatedOn'].split(':').length && deliverySite['UpdatedOn'].split(':')[0]) ? new Date(deliverySite['UpdatedOn'].split(':')[0]) : '');

                  deliverySiteList.push(deliverySite);
                }
              }
              this.dataSourceDelSite.data = deliverySiteList;
              this.dataSourceDelSite.sort = this.sort;
              this.dataSourceDelSite.paginator = this.paginator;

              // No need to convert to lower case when date object is passed
              this.dataSourceDelSite.sortingDataAccessor = (data, sortHeaderId) => {
                if (data[sortHeaderId] && sortHeaderId !== 'UpdatedOn') {
                  return data[sortHeaderId].toLocaleLowerCase();
                }
                return data[sortHeaderId];
              };

              this.isRequestCompleted = true;
            } else {
              this.isRequestCompleted = true;
            }
          } else {
            this.isRequestCompleted = true;
          }
        });
  }
 
    updateSelectedItem(selectedItem: string) {
        this.myCompanyService.updateSelectedItem(selectedItem)
  }

  approveDeliverySite(selectedDeliverySite: DelSite, hideJDEAddressNumberField: boolean) {
    this.dialog.open(ApproveDeliverySiteComponent, {
      // height: '83%',
      height: 'auto',
      // width: (this.mobileQuery.matches ? '70%' : '22%'),
      width: 'auto',
      data: { selectedDeliverySite: selectedDeliverySite, hideJDEAddressNumberField: hideJDEAddressNumberField },
    });
  }
 
  // openDialog(dialogType: string) {
    //     const dialogRef = this.dialog.open(DialogBodyComponent, {
    //         data: {
    //             header: (dialogType === 'resetPassword') ? 'Password Reset Successful' : 'User Unlock Successful',
    //             message: (dialogType === 'resetPassword') ? 'New Password is sent to you by email, please check.' : ' ',
    //             buttonText: {
    //                 ok: 'Ok'
    //             },
    //             isSuccess: true
    //         },
    //         height: (dialogType === 'resetPassword') ? '37%' : '31%',
    //         width: (dialogType === 'resetPassword') ? '24%' :'22%',
    //     });
    // }
 
  updateDeliverySiteStatus(selectedDeliverySite: any, stageToBeUpdated: string) {

    this.showConfirmationPopUp(selectedDeliverySite, 'deliverySite-statusChange-confirmation', stageToBeUpdated);

    // Commented old code, kept for future reference
    /* if (selectedDeliverySite && stageToBeUpdated) {
      selectedDeliverySite['ApprovalStage'] = stageToBeUpdated;
      this.addDeliverySiteComponentService.updateDeliverySiteStatus(selectedDeliverySite)
        .subscribe(
          (response: any) => {
            // this.showStatusDialog(response);

            this.sendEmailNotification('DS_STATUS_UPDATE', selectedDeliverySite);
          });
    } */
  }
 
  showStatusDialog(response: any) {
    if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
      this.openStatusDialog('Delivery site status updated successfully', null, true,'del-site-update-success');
      this.fetchDeliverySites();
    } else {
      this.openStatusDialog('Failed to update delivery site status', null, false,'del-site-update-fail');
    }
  }
 
 
    openStatusDialog(headerTitle: string, message: string, isSuccess: boolean,headerNumber:string) {
        this.dialog.open(DialogBodyComponent, {
            data: {
                header: headerTitle,
                message: message ? message : ' ',
                buttonText: {
                    ok: 'Ok'
                },
        isSuccess: isSuccess,
        headerNumber:headerNumber
            },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
        });
  }
 
  deleteDelSite(selectedDeliverySite:any) {
 
    this.showConfirmationPopUp(selectedDeliverySite, 'delete-delsite-confirmation', null);
 
    /* if (confirm('Are you sure to delete?')) {
      this.contactUsAdminService.deleteContactUs(id).subscribe(res => {
        this.deleteStatusDialog(res);
        this.getContactUs();
      });
    } */
  }

  showConfirmationPopUp(selectedDeliverySite: any, headerNumber: string, stageToBeUpdated: string) {
    const dialogData = new ConfirmDialogModel("Delete delivery site", '', '', '', null);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      // maxWidth: "410px",
      // data: dialogData,
      data: {
        headerNumber: headerNumber,
        messageNumber: '',
        count: null
      },
      height: '37%',
      width: (this.mobileQuery.matches ? '60%' : '24%'),
      // header: 'Delete user group',

    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {

        // Commented old code, kept for future reference
        /* this.modifyDeliverySiteService.deleteDelSite(selectedDeliverySite.DeliverySite ,selectedDeliverySite.CustomerId, selectedDeliverySite.CountryId).subscribe(res => {
          this.deleteStatusDialog(res);
          this.fetchDeliverySites();
        }); */

        if (headerNumber === 'delete-delsite-confirmation') {
          this.modifyDeliverySiteService.deleteDelSite(selectedDeliverySite.DeliverySite, selectedDeliverySite.CustomerId, selectedDeliverySite.CountryId).subscribe(res => {
            this.deleteStatusDialog(res);
            this.fetchDeliverySites();
          });
        } else if (headerNumber === 'deliverySite-statusChange-confirmation') {
          if (selectedDeliverySite && stageToBeUpdated) {
            selectedDeliverySite['ApprovalStage'] = stageToBeUpdated;
            selectedDeliverySite['Status'] = stageToBeUpdated;
            this.addDeliverySiteComponentService.updateDeliverySiteStatus(selectedDeliverySite)
              .subscribe(
                (response: any) => {
                  if (response) {
                    this.sendEmailNotification('DS_STATUS_UPDATE', selectedDeliverySite);
                  }
                });
          }
        }
      }
    });
  }
  
  deleteStatusDialog(response: any) {
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        this.openSaveUserDialog('Delivery site deleted successfully', null, true,'delsite-delete-success');
      } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.openSaveUserDialog('Failed to delete delivery site', (response['Description'] ? response['Description'] : null), false,'delsite-delete-fail');
      } else {
        this.openSaveUserDialog('Failed to delete delivery site', null, false,'delsite-delete-fail');
      }
    } else {
      this.openSaveUserDialog('Failed to delete delivery site', null, false,'delsite-delete-fail');
    }
  }
 
  openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean,headerNumber:string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber:headerNumber
      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  downloadExcel(deliverySiteList: any, header: any, sheetName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(deliverySiteList, { header: header, dateNF: 'DD-MMM-YY;@', cellDates: true });
    const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const forecastEntryDetailsBlob: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(forecastEntryDetailsBlob, sheetName + EXCEL_EXTENSION);
  }

  download() {
    return new Promise<void>((resolve, reject) => {
      this.modifyDeliverySiteService.getDeliverySites(this.selectedCompanyId, this.countryCode).subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            if (response["DeliverySiteCollection"] && response["DeliverySiteCollection"].length) {
              let headerList: any = [];

              if (this.countryCode === 'FIN') {
                headerList = [
                  "Customer name",
                  "Delivery site name",
                  "Billing reference",
                  "Expected first delivery date",
                  "Plant type",
                  "Incoterms",
                  "Address line 1",
                  "GPS coordinates",
                  "Address line 2",
                  "City",
                  "Delivery postal code",
                  "Country",
                  "Primary contact person name",
                  "Primary email address",
                  "Contact number",
                  "Haulier name",
                  "Haulier email id",
                  "Send forecast reminder",
                  "Additional text info",
                  "Internal text info",
                  "Status",
                  "Stage",
                  "JDE Address Number",
                  "Depot Address Number",
                  "Created on",
                  "Created by",
                  "Updated on",
                  "Updated by"
                ];
              } else if (this.countryCode === 'GBR') {
                headerList = [
                  "Customer name",
                  "Delivery site name",
                  "Billing reference",
                  "Expected first delivery date",
                  "Plant type",
                  "Incoterms",
                  "Address line 1",
                  "GPS coordinates",
                  "Address line 2",
                  "City",
                  "Delivery postal code",
                  "Country",
                  "Primary contact person name",
                  "Primary email address",
                  "Contact number",
                  "Additional text info",
                  "Internal text info",
                  "Status",
                  "Stage",
                  "JDE Address Number",
                  "Created on",
                  "Created by",
                  "Updated on",
                  "Updated by"
                ];
              } else {  // SWE, NOR, DNK
                headerList = [
                  "Customer name",
                  "Delivery site name",
                  "Billing reference",
                  "Expected first delivery date",
                  "Plant type",
                  "Incoterms",
                  "Loading depot",
                  "Address line 1",
                  "GPS coordinates",
                  "Address line 2",
                  "City",
                  "Delivery postal code",
                  "Country",
                  "Primary contact person name",
                  "Primary email address",
                  "Contact number",
                  "Notify haulier",
                  "Haulier name",
                  "Haulier email id",
                  "Send forecast reminder",
                  "Additional text info",
                  "Internal text info",
                  "Status",
                  "Stage",
                  "JDE Address Number",
                  "Created on",
                  "Created by",
                  "Updated on",
                  "Updated by"
                ];
              }

              if (!this.isLoggedInUserPortalAdmin) {
                headerList = this.filterElementsFromArray(headerList, ["Internal text info", "Send forecast reminder"]);
              }

              let deliverySiteListForDownloadExcel = [];
              for (let monthlyRowData of response["DeliverySiteCollection"]) {
                let rowObject = {};
                for (let header of headerList) {
                  if (header === "Customer name") {
                    rowObject[header] = monthlyRowData['CustomerName'];
                  } else if (header === "Delivery site name") {
                    rowObject[header] = monthlyRowData['DeliverySite'];
                  } else if (header === "Billing reference") {
                    rowObject[header] = monthlyRowData['BillingReference'];
                  } else if (header === "Expected first delivery date") {
                    rowObject[header] = (monthlyRowData['ExpectedFirstDeliveryDate'] ? new Date(monthlyRowData['ExpectedFirstDeliveryDate']) : '');
                  } else if (header === "Plant type") {
                    rowObject[header] = monthlyRowData['PlantTypeDescription'];
                  } else if (header === "Incoterms") {
                    rowObject[header] = monthlyRowData['FreightHandlingDescription'];
                  } else if (header === "Loading depot") {
                    rowObject[header] = monthlyRowData['LoadingDepot'];
                  } else if (header === "Address line 1") {
                    rowObject[header] = monthlyRowData['AddressLine1'];
                  } else if (header === "Address line 2") {
                    rowObject[header] = monthlyRowData['AddressLine2'];
                  } else if (header === "GPS coordinates") {
                    rowObject[header] = monthlyRowData['GPSCoordinates'];
                  } else if (header === "City") {
                    rowObject[header] = monthlyRowData['City'];
                  } else if (header === "Delivery postal code") {
                    rowObject[header] = monthlyRowData['DeliveryPostalCode'];
                  } else if (header === "Country") {
                    rowObject[header] = this.getCountryNameById(monthlyRowData['CountryId']);
                  } else if (header === "Primary contact person name") {
                    rowObject[header] = monthlyRowData['PrimaryContactName'];
                  } else if (header === "Primary email address") {
                    rowObject[header] = monthlyRowData['SubscriberEmailAddress'];
                  } else if (header === "Contact number") {
                    if (monthlyRowData['PrimaryContact']) {
                      let primaryContactWithCountryCode = monthlyRowData['PrimaryContact'];
                      primaryContactWithCountryCode = primaryContactWithCountryCode.replace("FIN", "+358 ");
                      primaryContactWithCountryCode = primaryContactWithCountryCode.replace("SWE", "+46 ");
                      primaryContactWithCountryCode = primaryContactWithCountryCode.replace("NOR", "+47 ");
                      primaryContactWithCountryCode = primaryContactWithCountryCode.replace("EST", "+372 ");
                      primaryContactWithCountryCode = primaryContactWithCountryCode.replace("DNK", "+45 ");
                      primaryContactWithCountryCode = primaryContactWithCountryCode.replace("GBR", "+44 ");
                      rowObject[header] = primaryContactWithCountryCode;
                    } else {
                      rowObject[header] = "";
                    }
                  } else if (header === "Notify haulier") {
                    rowObject[header] = this.getYesNoForBooleanFields(monthlyRowData['NotifyHaulier']);
                  } else if (header === "Haulier name") {
                    rowObject[header] = monthlyRowData['HaulierName'];
                  } else if (header === "Haulier email id") {
                    rowObject[header] = monthlyRowData['HaulierEmailId'];
                  } else if (header === "Send forecast reminder" && this.countryCode !== 'GBR') {
                    rowObject[header] = this.getYesNoForBooleanFields(monthlyRowData['SendReminderFlag']);
                  } else if (header === "Billing reference") {
                    rowObject[header] = monthlyRowData['BillingReference'];
                  } else if (header === "Status") {
                    rowObject[header] = this.getTranslatedText(monthlyRowData['Status']);
                  } else if (header === "Stage") {
                    rowObject[header] = this.getTranslatedText(monthlyRowData['ApprovalStage']);
                  } else if (header === "Additional text info") {
                    rowObject[header] = monthlyRowData['CustomerTextInfo'];
                  } else if (header === "Internal text info") {
                    rowObject[header] = monthlyRowData['Information'];
                  } else if (header === "JDE Address Number") {
                    rowObject[header] = monthlyRowData['JdeAddressNumber'];
                  } else if (header === "Depot Address Number") {
                    rowObject[header] = monthlyRowData['DepotAddressNumber'];
                  } else if (header === "Created on") {
                    rowObject[header] = monthlyRowData['CreatedOn'];
                  } else if (header === "Created by") {
                    rowObject[header] = monthlyRowData['CreatedBy'];
                  } else if (header === "Updated on") {
                    rowObject[header] = monthlyRowData['UpdatedOn'];
                  } else if (header === "Updated by") {
                    rowObject[header] = monthlyRowData['UpdatedBy'];
                  }
                }
                deliverySiteListForDownloadExcel.push(rowObject);
              }

              // Commented old code, kept for future reference
              // this.downloadExcel(weeklyForecastDetailsToDownloadExcel, headerList, "Modify Dellivery Site Details");
          
              this.sharedService.downloadDataInExcelFile(deliverySiteListForDownloadExcel, 'Modify Dellivery Site Details', headerList);
              
            } else {
              this.openSaveUserDialog('No data available', '', false, 'no-data-available');
            }
            resolve();
          } else {
            this.openSaveUserDialog('Something went wrong', '', false, 'Something-went-wrong');
            reject();
          }
        });
    }
    )
  }

  sendEmailNotification(key: string, selectedDeliverySite: any): void {
    selectedDeliverySite['DeliverySiteName'] = selectedDeliverySite['DeliverySite'];
    this.addDeliverySiteComponentService.emailService(key, this.countryCode, selectedDeliverySite, '', '', null)
      .subscribe(
        (response: any) => {
          this.showStatusDialog(response);
        });
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.changeDetectorRef.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.changeDetectorRef.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSourceDelSite.filter = filterValue;
      // this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }
 
  showProductPopUp(selectedDeliverySite: any) {
    this.dialog.open(UpdateDeliverySiteProductsComponent, {
      height: '83%',
      width: (this.mobileQuery.matches ? '70%' : '22%'),
      data: { selectedDeliverySite: selectedDeliverySite }
    });
  }

  getDateInCorrectFormat(date: string) {
    return ((date && this.datePipe.transform(date, 'dd-MMM-yy', null, 'en-US').toUpperCase()) ? this.datePipe.transform(date, 'dd-MMM-yy', null, 'en-US').toUpperCase() : '');
  }

  getCountryNameById(countryId: string): string {
    for (var country of this.countryList) {
      if (country && country.id === countryId) {
        return country.name;
      }
    }
    return null;
  }

  async getCountryList() {
    this.countryList = [];
    return new Promise<void>((resolve, reject) => {
      this.addEditDeliverySiteService.getCountries().subscribe(
        response => {
          if (response["ResponseCode"] === "200") {
            response["Countries"].forEach((country: any) => {
              var countryCode: number = 0;
              if (country["CountryID"] === 'FIN') {
                countryCode = 358;
              } else if (country["CountryID"] === 'SWE') {
                countryCode = 46;
              } else if (country["CountryID"] === 'NOR') {
                countryCode = 47;
              } else if (country["CountryID"] === 'EST') {
                countryCode = 372;
              } else if (country["CountryID"] === 'DNK') {
                countryCode = 45;
              } else if (country["CountryID"] === 'GBR') {
                countryCode = 44;
              }
              this.countryList.push(new Country(country["CountryID"], country["CountryName"], countryCode));
            });
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }

  getYesNoForBooleanFields(value: string): string {
    if (value && value === "TRUE") {
      return "Yes";
    }
    return "No";
  }

  filterElementsFromArray(array: any, filerElements: any): any {
    for (let element of filerElements) {
      const index = array.indexOf(element);
      if (index > -1) {
        array.splice(index, 1);
      }
    }
    return array;
  }

  getTranslatedText(textToBeTranslated: string): string {
    let translateObject: any = {};
    translateObject['originalText'] = textToBeTranslated;
    translateObject['translatedText'] = '';
    this.sharedService.getTranslatedText(translateObject);
    return translateObject['translatedText'];
  }
 
}