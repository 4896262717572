<div class="container">

        <!-- <select class="form-control input-text addUserSelect" id="siteStats"
        (change)="changeTitle($event)">
        <option value="">Stats</option>
        <option *ngFor="let siteStats of Stats" [value]="siteStats">
            {{siteStats}}
        </option>
    </select> -->
    <div class="row">
        <div class="col-12" style="padding: 0px;">
            <div class="mt-5" [hidden]="displayTable"
                style="display: flex; justify-content: center; font-size: larger;">
                No Records Found
            </div>
            <div class="mt-3 forecastTable" [hidden]='!displayTable'>
                <!--Angular 7 Impl-->
                <mat-table #table [dataSource]="dataSource" matSort style="overflow: auto; height: 60%; margin-left:2%" class="scroll-table-content scrollbar-table-content">

                    <!-- Item Column -->
                    <ng-container matColumnDef="CompanyName">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Company Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.CompanyName}}
                        </mat-cell>
                    </ng-container>

                    <!-- Delivery Site Column -->
                    <ng-container matColumnDef="Page">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Page
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.Page}}
                        </mat-cell>
                    </ng-container>

                    <!-- Customer Contact Column -->
                    <ng-container matColumnDef="Visits">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header> Visits
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.Visits}}
                        </mat-cell>
                    </ng-container>]
                    
                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

            </div>
        </div>
    </div>
</div>
