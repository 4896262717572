import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddEchangeRateAdminService {

  apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  updateExchangeRate(exchangeRate: any, toggleButtonMenu: string) {

    let url = this.apiUrl + '/UpdateExchangeRate';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };
    let postData: any;
    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'DAILY') {
      postData = JSON.stringify(
        {
          "ExchangeRateCollection": [ {
            "Type" : toggleButtonMenu.toUpperCase(),
            "Date" : exchangeRate.IndexDate,
            "ConversionRate" : exchangeRate.Rate,
            "FromCurrency" : "USD",
            "ToCurrency" : "EUR",
            "Source" : exchangeRate.Source,
            "CREATED_BY": exchangeRate.CreatedBy,
            "CREATED_ON": exchangeRate.CreatedOn,
            "UPDATED_BY": exchangeRate.UpdatedBy,
            "UPDATED_ON": exchangeRate.UpdatedOn
          } ]
        }
      );
    }
    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'MONTHLY') {
      postData = JSON.stringify(
        {
          "ExchangeRateCollection": [ {
            "Type" : toggleButtonMenu.toUpperCase(),
            "Month": exchangeRate.Month,
            "Year": exchangeRate.Year,
            "ConversionRate" : exchangeRate.Rate,
            "FromCurrency" : "USD",
            "ToCurrency" : "EUR",
            "Source" : exchangeRate.Source,
            "CREATED_BY": exchangeRate.CreatedBy,
            "CREATED_ON": exchangeRate.CreatedOn,
            "UPDATED_BY": exchangeRate.UpdatedBy,
            "UPDATED_ON": exchangeRate.UpdatedOn
          } ]
        }
      );
    }
    return this.httpClient.post(url, postData, httpOptions).pipe();
  }

  addExchangeRate(exchangeRate: any, toggleButtonMenu: string) {

    let url = this.apiUrl + '/AddExchangeRate';
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    };
    let postData: any;
    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'DAILY') {
      postData = JSON.stringify(
        {
          "ExchangeRateCollection": [ {
            "Type" : toggleButtonMenu.toUpperCase(),
            "Date" : exchangeRate.IndexDate,
            "ConversionRate" : exchangeRate.Rate,
            "FromCurrency" : "USD",
            "ToCurrency" : "EUR",
            "Source" : exchangeRate.Source,
            "CREATED_BY": exchangeRate.CreatedBy,
            "CREATED_ON": exchangeRate.CreatedOn,
            "UPDATED_BY": exchangeRate.UpdatedBy,
            "UPDATED_ON": exchangeRate.UpdatedOn
          } ]
        }
      );
    }
    if (toggleButtonMenu && toggleButtonMenu.toUpperCase() === 'MONTHLY') {
      postData = JSON.stringify(
        {
          "ExchangeRateCollection": [ {
            "Type" : toggleButtonMenu.toUpperCase(),
            "Month": exchangeRate.Month,
            "Year": exchangeRate.Year,
            "ConversionRate" : exchangeRate.Rate,
            "FromCurrency" : "USD",
            "ToCurrency" : "EUR",
            "Source" : exchangeRate.Source,
            "CREATED_BY": exchangeRate.CreatedBy,
            "CREATED_ON": exchangeRate.CreatedOn,
            "UPDATED_BY": exchangeRate.UpdatedBy,
            "UPDATED_ON": exchangeRate.UpdatedOn
          } ]
        }
      );
    }
    return this.httpClient.post(url, postData, httpOptions).pipe();
  }


  getExchangeRateForTypeMonthly(year: string) {
    const url = this.apiUrl + '/GetMonthlyExchangeRate';
    return this.httpClient
      .get(url, {
        params: {
          Year: year
        }
      });
  }

  getExchangeRateForTypeDaily() {
    let type = 'DAILY';
    let records = '365';
    const url = this.apiUrl + '/GetDailyExchangeRate';
    return this.httpClient
      .get(url, {
        params: {
          Type: type,
          Records: records
        }
      });
  }
}


