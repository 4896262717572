import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { ForecastActual } from '../reports';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { CommonService } from 'src/app/order-management/order-management/order-management.component.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import * as moment from 'moment'
import { Customer } from 'src/app/user-management/customer';
import { TranslateService } from '@ngx-translate/core';
import { ForecastactualweeklyswedenService } from './forecastactualweeklysweden.service';
import { CustomerName } from 'src/app/order-management/forecast';

@Component({
  selector: 'app-forecastactualweeklysweden',
  templateUrl: './forecastactualweeklysweden.component.html',
  styleUrls: ['./forecastactualweeklysweden.component.css']
})
export class ForecastactualweeklyswedenComponent implements OnInit {

  public displayedColumns = ['CustomerName','WeekNumber', 'ForecastQuantity','ActualQuantity', 'Difference'];
  public displayedColumnfordepot = ['CustomerName', 'Depot name' ,'WeekNumber', 'ForecastQuantity','ActualQuantity', 'Difference'];
  public displayedColumnforproduct = ['CustomerName','Product name', 'WeekNumber', 'ForecastQuantity','ActualQuantity', 'Difference'];
  public displayedColumnfordelsite = ['CustomerName', 'Delivery site' ,'WeekNumber', 'ForecastQuantity','ActualQuantity', 'Difference'];
  public displayedColumnforproductanddepot = ['CustomerName','Product name', 'Depot name','WeekNumber', 'ForecastQuantity','ActualQuantity', 'Difference' ];
  navbarOpen = false;
  year: string;
  custId: string;       
  excelDataForALL: any;
  selectedCustomerIdForGraph: string;
  selectedCustomerIdForTable: string;
  Actual_Quantity: any = [];   
  Forecast_Quantity: any = [];
  Order_month: any = [];
  selectedItem: String = 'Forecast v/s Actual';
  forecastActualData: ForecastActual[];
  dataSource: any;
  dataSource1: any;
  dataSource2: any;
  dataSource3: any;
  displayTable: boolean;
  userInfo: any;
  isLoggedInUserPortalAdmin: boolean;
  dataToSearch: any;
  filterParam: any;
  errorDesc: string = '';
  displayErrorMsg: boolean = true;
  displayErrorMsgTable: boolean = false;
  years: any;
  yearToSearch: any;
  yearsForTable: any;
  yearToSearchForTable: any;
  BarChartDataInfo: any;
  viewBy: any;
  delSitesGraph = [];
  user: any;

  // Year = this.sharedService.getCurrentDateTime().getFullYear();
  customerList: Customer[] = [];
  UserID: any;
  customerIdUserSelected: any;
  countryCode: any;
  pendData: any;
  delSiteUserSelectedForGraph: string = '';
  delSitesTable: any[];
  delSiteUserSelectedForTable: string;
  reports_tableByAll: unknown[];
  role: any;
  excelReportData: any;
  Forecast_week: string[];
  weeklyTableForAll: any[];
  deliverySiteObjectList: any = [];


  constructor(private translate: TranslateService, private sharedService: AppService,
    private forecastActualService: ForecastactualweeklyswedenService, private dialog: MatDialog,
    private commonService: CommonService) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.custId = (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
    this.UserID = (this.userInfo ? this.userInfo.UserID : '');
    this.selectedCustomerIdForGraph = this.custId;
    this.selectedCustomerIdForTable = this.custId;
    this.isLoggedInUserPortalAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA") : false);
    this.countryCode = this.userInfo.CountryCode;
    this.role = (this.userInfo ? this.userInfo.RoleID : '');
  }
     
  async ngOnInit() {
    // Populate year dropdown
    let currentYear: number = this.getCurrentYear();
    this.getYearList(currentYear);

    // this.year = moment(this.sharedService.getCurrentDateTime()).format('YYYY');
    // this.years = ['2020'];
    this.yearToSearch = this.years[1];
    // this.yearsForTable = ['2020'];
    this.yearToSearchForTable = this.years[1];
    this.filterParam = ['All', 'By product', 'By depot', 'By delivery site','By product and depot'];
    this.dataToSearch = this.filterParam[0];
    // this.Actual_Quantity = [''];
    // this.Forecast_Quantity = [''];
    //get customer list
    this.getCustomerList();

    //get del sites
    try {
    await this.getDeliverySitesForGraph();
    }
    catch (e) {
    }

    try {
    await this.getDeliverySitesForTable();
    }
    catch (e) {
    }

    this.getBarChartData();

    try{
      await this.getForecastActualTable();
    }
    catch (e)
    {

    }
    this.filterIndexForTable(this.yearToSearch,'All');
 }
   
 getYearList(currYear: number) {
   this.years = [];
   this.years.push(currYear - 1, currYear);
   this.yearsForTable = [];
   this.yearsForTable.push(currYear - 1, currYear);
 }

 getCurrentYear(): number {
   const date = this.sharedService.getCurrentDateTime();
   return date.getUTCFullYear();
 }

  filterIndex(value) {
    this.Order_month.splice(0, 52);
    
    this.yearToSearch = value;
    // this.delSiteUserSelected = value;
    this.Actual_Quantity.splice(0, 52);
    this.Forecast_Quantity.splice(0, 52);
    // this.Order_month.splice(0,12);
    this.getBarChartData();
    // this.Forecast_week = [];
    //this.getForecastActualTable();
  }
  filterIndexForTable(year, searchParam) {
    this.yearToSearchForTable = year;
    this.viewBy = searchParam;
    if (this.viewBy == "All") {
      this.getForecastActualTable();
    }
    if (this.viewBy == "By depot") {
      this.getforecatActualTableByDepot();
    }
    if (this.viewBy == "By product") {
      this.getforecatActualTableByProduct();

    }
    if (this.viewBy == "By delivery site") {
      this.getforecatActualTableByDelSite();

    }
    if (this.viewBy == "By product and depot") {
      this.getforecastActualTableByProductandDepot();
    }
  }


  async getBarChartData() {
    // this.Order_month = [];
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelectedForGraph);
        this.forecastActualService.getBarChartData(this.yearToSearch, this.selectedCustomerIdForGraph, this.delSiteUserSelectedForGraph, '1', '53', jdeAddressNumber).subscribe(

      res => {
        if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
          if (this.Order_month.length > 0) {
            this.Order_month = [];
            // this.Actual_Quantity = [];
            // this.Forecast_Quantity = [];
          }

          res["Collection"].forEach(x => {
            this.Actual_Quantity.push(parseInt(x.Actual_Quantity));
            this.Forecast_Quantity.push(parseInt(x.Forecast_Quantity));
            //this.Order_month.push(x.Forecast_month);
            // this.translate.get(['HOME.'+ x.Forecast_week])
            this.Order_month.push(x.Forecast_week)
            // .subscribe(data => {
            //   this.Order_month.push(data['HOME.'+ x.Forecast_week]);
            // });
            this.displayErrorMsg = false;
          });

        }
        else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
          (res['Description'] ? res['Description'] : null);
          this.errorDesc = res['Description'];
          this.displayErrorMsg = true;
        }
        else {
          (res['Description'] ? res['Description'] : null);
          this.errorDesc = res['Description'];
          this.displayErrorMsg = true;
        }
      });

  }
  openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess
      },
      height: '37%',
      width: '22%',
    });
  }

  //  Changes for barchart start
  public barChartOptions2: ChartOptions = {
    responsive: true,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    tooltips: {
      mode: 'index'
    },
    scales: {

      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "bold"
        }
      }], yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 14,
          fontStyle: "bold"
        }
      }]
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    }
  };
  public barChartLabels2: Label[] = this.Order_month;
  public barChartType2: ChartType = 'bar';
  public barChartLegend2 = true;
  public barChartPlugins2 = [pluginDataLabels];

  public barChartData2: ChartDataSets[] = [
    /* { data: this.Actual_Quantity, label: 'Actual', pointStyle: "line", backgroundColor: '#FFD500', hoverBackgroundColor: '#FFD500', hoverBorderColor: '#FFD500' },
    { data: this.Forecast_Quantity, label: 'Planned', pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' } */

    { data: this.Actual_Quantity, label: this.getTitleBySelectedLanguage('Actual'), pointStyle: "line", backgroundColor: '#FFD500', hoverBackgroundColor: '#FFD500', hoverBorderColor: '#FFD500' },
    { data: this.Forecast_Quantity, label: this.getTitleBySelectedLanguage('Planned'), pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' }
  ];

  // Changes for barchart end

  //bar chart data for customer role: start

  public barChartOptions1: ChartOptions = {
    responsive: true,

    title: {
      display: true,
      position: "top",
      text: "",
      fontSize: 14,
      fontColor: "black"
    },
    tooltips: {
      mode: 'index'
    },
    scales: {

      xAxes: [{
        ticks: {
          fontColor: 'black',
          fontSize: 10,
          fontStyle: "bold"
        }
      }], yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          fontSize: 14,
          fontStyle: "bold"
        }
      }]
    },
    legend: {
      position: "bottom",
      labels: {
        fontColor: 'black',
        fontSize: 14,
        fontStyle: "bold",
        usePointStyle: true,
      }
    },
    plugins: {
      datalabels: {
        display: false,

      }
    }
  };
  public barChartLabels1: Label[] = this.Order_month;
  public barChartType1: ChartType = 'bar';
  public barChartLegend1 = true;
  public barChartPlugins1 = [pluginDataLabels];

  public barChartData1: ChartDataSets[] = [
    { data: this.Forecast_Quantity, label: 'Forecast', pointStyle: "line", backgroundColor: '#18426a', hoverBackgroundColor: '#18426a', hoverBorderColor: '#18426a' }
  ];
  //bar chart data for customer role : end


  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  showSelectedMenuItem(itemName: String) {
    this.selectedItem = itemName;
  }

  async getForecastActualTable() {
    this.weeklyTableForAll = [];
    let customername = this.getCustomerNamebyId(this.selectedCustomerIdForTable);
    
    return new Promise((resolve, reject) => {
      let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelectedForTable);
    this.forecastActualService.getTableDataForAll(this.yearToSearchForTable, this.selectedCustomerIdForTable, this.delSiteUserSelectedForTable, jdeAddressNumber).subscribe
    (res => {
      
      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        for (let week of res["WeeklyPlannedVsActualForAll_Table"][0]["WeekCollection"]) {
          week['CustomerName']= customername;
          this.weeklyTableForAll.push(week)
          
        }
        
       
        this.excelDataForALL = this.weeklyTableForAll;
        this.dataSource = new MatTableDataSource(this.weeklyTableForAll);
        this.dataSource.sort = this.sort;
        this.displayTable = true
        resolve();

      }
      
      
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        (res['Description'] ? res['Description'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }
      else {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
        reject();
      }

   
    

    })});



  }


  getTotal(jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec) {
    var totalValue = parseInt(jan) + parseInt(feb) + parseInt(mar) + parseInt(apr) + parseInt(may) +
      parseInt(jun) + parseInt(jul) + parseInt(aug) + parseInt(sep) + parseInt(oct) + parseInt(nov) +
      parseInt(dec);
    return totalValue;
  }
  getforecatActualTableByDepot() {
   
    let customername = this.getCustomerNamebyId(this.selectedCustomerIdForTable);
    
    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelectedForTable);
    this.forecastActualService.getTableDataForDepot(this.yearToSearchForTable,this.selectedCustomerIdForTable,this.delSiteUserSelectedForTable, jdeAddressNumber).subscribe(res => {
      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
    
        var weekData = res["WeeklyPlannedVsActualForDepot"]["DepotCollection"][0]["WeekCollection"];
        var depotData = res["WeeklyPlannedVsActualForDepot"]["DepotCollection"];
        var reports_tableByDepot = [];
        
       
        depotData.forEach(function (depotItem) {
          var depotName = depotItem['DepotName'];
          var weekData = depotItem["WeekCollection"];

          weekData.forEach(function (arrayItem) {

            arrayItem['DepotName'] = depotName;
            arrayItem['CustomerName'] = customername;
            
            reports_tableByDepot.push(arrayItem);
            // var weekNumber = arrayItem['WeekNumber'];

           
           
          })
        });
        
        this.dataSource1 = new MatTableDataSource(reports_tableByDepot);
        this.excelDataForALL = reports_tableByDepot;
        this.dataSource1.sort = this.sort;
        this.displayTable = true;
      }
    
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);

        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }
      else {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }

    });
  }

  getforecatActualTableByProduct() {

    let customername = this.getCustomerNamebyId(this.selectedCustomerIdForTable);

    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelectedForTable);
    this.forecastActualService.getTableDataForProduct(this.yearToSearchForTable, this.selectedCustomerIdForTable, this.countryCode, this.delSiteUserSelectedForTable, jdeAddressNumber).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var weekData = res["WeeklyPlannedVsActualForProduct"]["ProductCollection"][0]["WeekCollection"];
        var productData = res["WeeklyPlannedVsActualForProduct"]["ProductCollection"];
        var reports_tableByProduct = [];
       
        productData.forEach(function (productItem) {
          var weekData = productItem["WeekCollection"];
          var productName = productItem['ProductName'];   
          
          weekData.forEach(function (arrayItem) {
            // var weekNumber = arrayItem['WeekNumber'];

            // var array1 = arrayItem['TypeCollectionForecast'][0];
            // var array2 = arrayItem['TypeCollectionActual'][0];
            // var array3 = arrayItem['TypeCollectionDifference'][0];

            // let TypeCollectionForecast = arrayItem['TypeCollectionForecast'][0]['Quantity'];
            // let TypeCollectionActual = arrayItem['TypeCollectionActual'][0]['Quantity'];
            // let TypeCollectionDifference = arrayItem['TypeCollectionDifference'][0]['Quantity'];

            arrayItem['ProductName'] = productName;
            arrayItem['CustomerName'] = customername;
            // array1['ProductName'] = productName;
            // array1['WeekNumber'] = weekNumber;

            // array1['Forecast'] = TypeCollectionForecast ;
            // array2['Actual'] = TypeCollectionActual ;
            // array3['Difference'] = TypeCollectionDifference ;         
        
            reports_tableByProduct.push(arrayItem);
            // reports_tableByProduct.push(array1);
            // reports_tableByProduct.push(array2);
            // reports_tableByProduct.push(array3);
          })
        });

        //   var TypeCollectionForecast = productItem['TypeCollectionForecast'];
        //  var TypeCollectionActual = productItem['TypeCollectionActual'];
        //  var TypeCollectionDifference = productItem['TypeCollectionDifference'];


        //  TypeCollectionForecast[0]['WeekCollection'].forEach(function(arrayItem){
        //   arrayItem['Type'] = TypeCollectionForecast[0]['Type'];
        //   arrayItem['ProductName']= productItem.ProductName;
        //   reports_tableByProduct.push(arrayItem);
        //  });

        //  TypeCollectionActual[0]['WeekCollection'].forEach(function(arrayItem){
        //  arrayItem['Type'] = TypeCollectionActual[0]['Type'];

        //  reports_tableByProduct.push(arrayItem);
        //  });

        //  TypeCollectionDifference[0]['WeekCollection'].forEach(function(arrayItem){
        //   arrayItem['Type'] = TypeCollectionDifference[0]['Type'];

        //   reports_tableByProduct.push(arrayItem);
        //  }) });

        this.dataSource2 = new MatTableDataSource(reports_tableByProduct);
        this.excelDataForALL = reports_tableByProduct;
        this.dataSource2.sort = this.sort;
        this.displayTable = true;
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }
      else {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }

    });
  }
// --------------------------------------------------------------------------------------
  getforecatActualTableByDelSite() {
    let customername = this.getCustomerNamebyId(this.selectedCustomerIdForTable)

    this.forecastActualService.getTableDataForDelSite(this.yearToSearchForTable, this.selectedCustomerIdForTable, this.countryCode, this.delSiteUserSelectedForTable).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var weekData = res["WeeklyPlannedVsActualByDeliverySite"]["DeliverySiteCollection"][0]["WeekCollection"];
        var deliveryData = res["WeeklyPlannedVsActualByDeliverySite"]["DeliverySiteCollection"];
        var reports_tableByDeliverySite = [];

        deliveryData.forEach(function (productItem) {
          var weekData = productItem["WeekCollection"];
          var deliverySite = productItem['DeliverySite'];
          weekData.forEach(function (arrayItem) {
           
            arrayItem['DeliverySite'] = deliverySite;
            arrayItem['CustomerName'] = customername;
            
            
            reports_tableByDeliverySite.push(arrayItem);
           
          })
        });

        this.dataSource3 = new MatTableDataSource(reports_tableByDeliverySite);
        this.excelDataForALL = reports_tableByDeliverySite;
        this.dataSource3.sort = this.sort;
        this.displayTable = true;
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }
      else {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }

    });

  }

  getforecastActualTableByProductandDepot() {
    let customername = this.getCustomerNamebyId(this.selectedCustomerIdForTable)

    let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelectedForTable);
    this.forecastActualService.getTableDataForDepotAndProduct(this.selectedCustomerIdForTable, this.yearToSearchForTable, this.countryCode, this.delSiteUserSelectedForTable, jdeAddressNumber).subscribe(res => {

      if (res["ResponseCode"] == 200 && res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        var weekData = res["DepotCollection"][0]["ProductCollection"][0]["WeekCollection"];
        var productData = res["DepotCollection"]["ProductCollection"];
        var depotData = res['DepotCollection'];
        var reports_tableByProductAndDepot = [];

        depotData.forEach(function(depotItem){
          var productData = depotItem['ProductCollection'];
          var depotName = depotItem['DepotName'];
        productData.forEach(function (productItem) {
          var weekData = productItem["WeekCollection"];
          var productName = productItem['ProductName'];
          weekData.forEach(function (arrayItem) {
            
            arrayItem['ProductName'] = productName;
            arrayItem['CustomerName'] = customername;
            arrayItem['DepotName'] = depotName;

           reports_tableByProductAndDepot.push(arrayItem);
            
          })
          })
        });



        this.dataSource2 = new MatTableDataSource(reports_tableByProductAndDepot);
        this.excelDataForALL = reports_tableByProductAndDepot;
        this.dataSource2.sort = this.sort;
        this.displayTable = true;
      }
      else if (res['ResponseStatus'] && res['ResponseStatus'].toUpperCase() === 'ERROR') {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }
      else {
        (res['ResponseStatus'] ? res['ResponseStatus'] : null);
        //this.errorDescTable = res['Description'];
        this.displayErrorMsgTable = true;
        this.displayTable = false;
      }

    });
  }  


  downloadData() {
    let excelReportData = [];
    let cloned = this.excelDataForALL.map(x => Object.assign({}, x));

     for (let data of cloned) {
      if (data['forecastquantity']) {
        data['forecastquantity'] = +data['forecastquantity'];
      }

      if (data['actualquantity']) {
        data['actualquantity'] = +data['actualquantity'];
      }

      excelReportData.push(data);
    } 
    
    
    if (this.viewBy == "All") {
      this.forecastActualService.exportAsExcelFileForAll(excelReportData, "Weekly_Reports_by_All");
    }

    if (this.viewBy == "By product") {
      this.forecastActualService.exportAsExcelFileForProduct(excelReportData, "Weekly _Reports_by_Product");
    }

    if (this.viewBy == "By depot") {
      this.forecastActualService.exportAsExcelFileForDepot(excelReportData, "Weekly_Reports_by_Depot");
    }

    if (this.viewBy == "By delivery site") {
      this.forecastActualService.exportAsExcelFileForDeliverySite(excelReportData, "Weekly_Reports_by_DeliverySite");
    }
    if (this.viewBy == "By product and depot") {
      this.forecastActualService.exportAsExcelFileForProductAndDepot(excelReportData, "Weekly_Reports_by_ProductAndDepot");
    }

  } 
   


  async getCustomerList() {
    this.forecastActualService.getCustomerList(this.countryCode)
      .subscribe(
        response => {
          if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            if (response["Customers"] && response["Customers"].length) {
              for (let customer of response["Customers"]) {
                this.customerList.push(new Customer(customer["CustomerID"], customer["CustomerName"]));
              }
            }
          }
        });
  }

  getCustomerNamebyId(id:string)
  {
    for (let customer of this.customerList)
    {
      if(id === customer.CustomerID)
      {
        return customer.CustomerName;
      }
    }

  }

  // countryCode(countryCode: any) {
  //   throw new Error("Method not implemented.");
  // }

  async getDeliverySitesForGraph() {
    //this.delSiteUserSelectedForGraph = '';
    this.delSitesGraph = [];
    if (this.role == "A" || this.role == "PA") {
      return new Promise((resolve, reject) => {
        this.forecastActualService.getDeliverySite(this.selectedCustomerIdForGraph, "Inactive", this.countryCode).subscribe(
          res => {
            if (res["ResponseCode"] === "200") {
              for (let index of res["DeliverySiteCollection"]) {
                this.delSitesGraph.push(index["DeliverySite"])
              }
              this.delSiteUserSelectedForGraph = this.delSitesGraph[0]
              resolve();
            }
            else {
              reject();
            }
          });
      })
    } else {
      return new Promise((resolve, reject) => {
        this.forecastActualService.getDeliverySiteForUser(this.UserID, this.countryCode, "ACTIVE", this.selectedCustomerIdForGraph).subscribe(
          response => {
            if (response["ResponseCode"] === "200") {
              for (let deliverySiteObject of response["DeliverySites"]) {
                this.deliverySiteObjectList.push(deliverySiteObject);
                this.delSitesGraph.push(deliverySiteObject["DeliverySiteName"]);
              }
              this.delSiteUserSelectedForGraph = this.delSitesGraph[0]
              resolve();
            }
            else {
              reject();
            }
          });
      })
    }
  }

  async getDeliverySitesForTable() {
    this.delSitesTable = [];
    if (this.role == "A" || this.role == "PA") {
      return new Promise((resolve, reject) => {
        this.forecastActualService.getDeliverySiteForTable(this.selectedCustomerIdForTable, "Inactive", this.countryCode).subscribe(
          res => {
            if (res["ResponseCode"] === "200") {
              for (let index of res["DeliverySiteCollection"]) {
                this.delSitesTable.push(index["DeliverySite"])
              }
              this.delSiteUserSelectedForTable = this.delSitesTable[0]
              resolve();
            }
            else {
              reject();
            }
          });
      })

    } else {

      return new Promise((resolve, reject) => {
        this.forecastActualService.getDeliverySiteForUserForTable(this.UserID, this.countryCode, "ACTIVE", this.selectedCustomerIdForTable).subscribe(
          response => {
            if (response["ResponseCode"] === "200") {
              for (let deliverySiteObject of response["DeliverySites"]) {
                this.deliverySiteObjectList.push(deliverySiteObject);
                this.delSitesTable.push(deliverySiteObject["DeliverySiteName"]);
              }
              this.delSiteUserSelectedForTable = this.delSitesTable[0];
              resolve()
            }
            else {
              reject();
            }
          });
      })
    }
  }

  

  getStringWithoutSpaces(stringWithSpaces: string): string {
    let stringWithoutSpaces = '';
    stringWithoutSpaces = stringWithSpaces.replace(/\s/g, '');
    return stringWithoutSpaces;
  }

  getTitleBySelectedLanguage(title: string): string {
    let translatedTitle: string;
    let selectedLanguage = this.sharedService.getSelectedLanguage();
    if (title === 'Actual') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Actual';
          break;
        case 'FI':
          translatedTitle = 'Toteuma';
          break;
        case 'SE':
          translatedTitle = 'Faktiskt';
          break;
        case 'NR':
          translatedTitle = 'Salg';
          break;
        default:
          translatedTitle = 'Actual';
      }
    }
    if (title === 'Planned') {
      switch (selectedLanguage) {
        case 'EN':
          translatedTitle = 'Planned';
          break;
        case 'FI':
          translatedTitle = 'Ennuste';
          break;
        case 'SE':
          translatedTitle = 'Prognostiserat';
          break;
        case 'NR':
          translatedTitle = 'Prognose';
          break;
        default:
          translatedTitle = 'Planned';
      }
    }
    return translatedTitle;
  }
  
  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        if (deliverySiteObject && deliverySiteObject['DeliverySite'] === deliverySiteName) {
          jdeAddressNumber = deliverySiteObject['JdeAddressNumber'];
          break;
        }
      }
    }
    return jdeAddressNumber;
  }


}



