import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'forecastStatus'
})
export class ForecastStatusPipe implements PipeTransform {

  transform(value: any, delSite: string): any {
    if ((!delSite || delSite == 'undefined')) {
         
          return value;
        }
        else {
          return value.filter(value => (value.DeliverySite.includes(delSite)));
        }
      }

}
