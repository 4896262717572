<div class="container-fluid pad-left-0">
    <div class="row">
        <div class="col-lg-6 toggle_button_div_mv">
            <!-- <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                #group="matButtonToggleGroup" [(ngModel)]="currentToggleMenu" value="reports">
                <mat-button-toggle value="reports" ngDefaultControl (click)="toggleButtonClick('reports')">
                    {{'HOME.Reports'| translate}}
                </mat-button-toggle>
                <mat-button-toggle value="graph" (click)="toggleButtonClick('graph')">
                    {{'HOME.graph'| translate}}
                </mat-button-toggle>
            </mat-button-toggle-group> -->
        </div>
        <div class="col-lg-6 download_button_div_mv" *ngIf="!this.mobileQuery.matches">
            <div class="downloadDiv pt-2">
                <div *ngIf="displayTable">
                    <i (click)="downloadReportsData()" class="fas fa-download downloadIcon "><span
                            class="downloadText">{{'HOME.Download'| translate}}</span></i>
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-1" >
        <div class="pl-3 ">
            <label for="customerName">
                <span class="customer-name-label">{{'HOME.Customername'| translate}}
                </span>
            </label>
        </div>
        <div class=" customer_name_dropdown_div customer_name_dropdown_div_mv " *ngIf="isLoggedInUserPortalAdmin">
            <select class="customer-name-dropdown customer_name_dropdown_width" id="customer-name"
                [(ngModel)]='customerIdUserSelected' (change)="this.getDeliverySites()">
                <!-- <option value="">{{'HOME.All'| translate}}</option> -->
                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                    {{customer.CustomerName}}
                </option>
            </select>
        </div>
        <div class="pl-1" *ngIf="!isLoggedInUserPortalAdmin">
            <input type="text" class="customer-name-textbox pr-5" [(ngModel)]='customerName' disabled />
        </div>

        <div class="delivery_site_label_div_mv pl-4">
            <label for="deliverysite">
                <span class="customer-name-label">{{'HOME.Deliverysite'| translate}}
                </span>
            </label>
        </div>
        <div class="delivery_site_dropdown_div delivery_site_dropdown_div_mv">
            <select class="customer-name-dropdown" id="delivery-site" [(ngModel)]='deliverySiteUserSelected'>
                <option value="">{{'HOME.All'| translate}}</option>
                <option *ngFor="let deliverySite of deliverySiteList" [value]="deliverySite">{{deliverySite}}
                </option>
            </select>
        </div>
    </div>
    <div class="row pt-3 no-gutters" >
        <!-- Commented code for View dropdown, kept for future reference -->
        <!-- <div class="col-lg-1 viewBy_label_div viewBy_label_div_mv">
                    <label for="viewBy">
                        <span class="customer-name-label">{{'HOME.Viewby'| translate}}
                        </span>
                    </label>
                </div>
                <div class="col-lg-2 viewBy_input_div viewBy_input_div_mv">
                    <select class="customer-name-dropdown viewBy_input_width viewBy_input_width_mv" id="viewBy"
                        [(ngModel)]='viewUserSelected'>
                        <option value="All">{{'HOME.All'| translate}}</option>
                        <option value="By deliverysite">{{'HOME.Bydeliverysite'| translate}}</option>
                        <option value="By product"> {{'HOME.Byproduct'| translate}} </option>
                        <option value="By depot"> {{'HOME.Bydepot'| translate}} </option>
                        <option value="By product and depot"> {{'HOME.Byproductanddepot'| translate}} </option>
                    </select>
                </div> -->

        <div class="col-lg-1 pl-3 fromDate">
            <label for="FromDate">
                <span class="customer-name-label">{{'HOME.FromDate'| translate}}
                </span>
            </label>
        </div>
        <div class="col-lg-2 pl-4">
        <!-- <mat-form-field appearance="fill">
            <mat-label>{{'HOME.FromDate'| translate}}</mat-label>
            <input matInput [matDatepicker]="picker1" [(ngModel)]='startDate'>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field> -->
                        <mat-form-field class="datepicker">
                            <input matInput [matDatepicker]="picker" placeholder="" [(ngModel)]='startDate' [min]="january012020Date">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="startDate == null" class="invalid-date">
                            {{'HOME.FromDateIsRequired'| translate}}
                        </div>
                        <div *ngIf="fromDateIsOlderThan1stJanuary2020" class="invalid-date">
                            {{'HOME.FromDateIsOlderThan1stJanuary2020'| translate}}
                        </div>
        </div>
        <div class="col-lg-1 toDate pl-4">
            <label for="ToDate">
                <span class="customer-name-label">{{'HOME.ToDate'| translate}}
                </span>
            </label>
        </div>
          <div class="col-lg-2 pl-4">
          <!-- <mat-form-field appearance="fill">
            <mat-label>{{'HOME.ToDate'| translate}}</mat-label>
            <input matInput [matDatepicker]="picker2" [(ngModel)]='endDate'>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field> -->
                        <mat-form-field class="datepicker">
                            <input matInput [matDatepicker]="picker1" placeholder="" [(ngModel)]='endDate'>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="endDate == null" class="invalid-date">
                            {{'HOME.ToDateIsRequired'| translate}}
                        </div>
        </div>
        
        <div class="col-lg-1 findButton_div_mv pl-4">
            <button class="findButton" (click)="getReportsForTable()">{{'HOME.Find'| translate}}</button>
        </div>
    </div>

   
  
    
    <div class="row pt-3" *ngIf=" !displayTable && isFindButtonClicked">
        <div class="col-lg-12 mt-3">
            <div class="mt-5" class="no-records-message">
                <span>{{'HOME.Norecordsfound'| translate}}</span>
            </div>
        </div>
    </div>
    
    <div class="row" [hidden]='!displayTable'>
        <div class="col-lg-12 mt-3 table_div_mv">

            <div class="daily-reports-container mat-elevation-z8">
            
            <mat-table #table [dataSource]="dataSource" [ngClass]="{'uk-mat-table': (countryCode === 'GBR'), 'non-uk-mat-table': (countryCode !== 'GBR') }" class="horizontal_scrollbar" #dailyReportTableSort="matSort" matSort>
                <ng-container matColumnDef="CUSTOMER_NAME">
                    <mat-header-cell class="column_medium" [ngClass]="{'customer-name-column': (countryCode === 'GBR') }" *matHeaderCellDef mat-sort-header>
                        {{'HOME.Customername'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium"  [ngClass]="{'customer-name-column': (countryCode === 'GBR') }" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.CUSTOMER_NAME }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CUSTOMER_PO">
                    <mat-header-cell class="column_medium" [ngClass]="{'customer-po-number-uk-column': (countryCode === 'GBR'), 'customer-po-number-column': (countryCode !== 'GBR') }" *matHeaderCellDef mat-sort-header>
                        {{'HOME.CustomerPOnumber'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" [ngClass]="{'customer-po-number-uk-column': (countryCode === 'GBR'), 'customer-po-number-column': (countryCode !== 'GBR') }" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.CUSTOMER_PO }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DEPOT_NAME">
                    <mat-header-cell class="column_medium column-small loading-point-column"
                        [ngClass]="{'hide-table-column': !(showUK) || !(showDepotNameColumn) }" *matHeaderCellDef mat-sort-header>
                        {{'HOME.LoadingPoint'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium column-small loading-point-column"
                        [ngClass]="{'hide-table-column': !(showUK) || !(showDepotNameColumn) }"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.DEPOT_NAME }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ACTUAL_SHIP_DATE">
                    <mat-header-cell class="column_medium" [ngClass]="{'actual-ship-date-uk-column': (countryCode === 'GBR'), 'actual-ship-date-column': (countryCode !== 'GBR') }" *matHeaderCellDef mat-sort-header>
                        {{'HOME.ActualShipDate'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" [ngClass]="{'actual-ship-date-uk-column': (countryCode === 'GBR'), 'actual-ship-date-column': (countryCode !== 'GBR') }" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ACTUAL_SHIP_DATE }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DELIVERY_SITE">
                    <mat-header-cell class="column_medium delivery-place-column" [ngClass]="{'hide-table-column': !(showDeliverySiteColumn), 'delivery-place-uk-column': (countryCode === 'GBR')}"
                        *matHeaderCellDef mat-sort-header>
                        {{'HOME.DeliveryPlace'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium delivery-place-column" [ngClass]="{'hide-table-column': !(showDeliverySiteColumn), 'delivery-place-uk-column': (countryCode === 'GBR') }"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.DELIVERY_SITE }}
                    </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="DELIVERY_GROUP_NAME">
                    <mat-header-cell class="column_medium" [ngClass]="{'hide-table-column': !(showDeliverySiteColumn)}"
                        *matHeaderCellDef mat-sort-header>
                        {{'HOME.Groupname'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" [ngClass]="{'hide-table-column': !(showDeliverySiteColumn) }"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.DELIVERY_GROUP_NAME }}
                    </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="PRODUCT_NAME">
                    <mat-header-cell class="column_medVarOne product-column" [ngClass]="{'hide-table-column': !(showProductNameColumn) }"
                        *matHeaderCellDef mat-sort-header>
                        {{'HOME.Product'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medVarOne product-column" [ngClass]="{'hide-table-column': !(showProductNameColumn) }"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.PRODUCT_NAME }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SHIPPED_QTY">
                    <mat-header-cell class="column_medVarOne column-small" [ngClass]="{'quantity-uk-column': (countryCode === 'GBR'), 'quantity-column': (countryCode !== 'GBR') }" *matHeaderCellDef>
                        {{'HOME.QuantityMT'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medVarOne column-small" [ngClass]="{'quantity-uk-column': (countryCode === 'GBR'), 'quantity-column': (countryCode !== 'GBR') }" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.SHIPPED_QTY }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="productTypeId">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium" *matHeaderCellDef>
                        {{'HOME.Typeofproduct'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" [ngClass]="{'hide-table-column': !(showUK)}"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.productTypeId }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="INVOICE_NUMBER">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium invoice-number" *matHeaderCellDef>
                        {{'HOME.Invoicenumber'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium invoice-number"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.INVOICE_NUMBER }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="INVOICE_DATE">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium invoice-date" *matHeaderCellDef
                        mat-sort-header>
                        {{'HOME.Invoicedate'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium invoice-date"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.INVOICE_DATE }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="INCOTERMS">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medVarOne column-small incoterms-column"
                        *matHeaderCellDef>
                        {{'HOME.Incoterms'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medVarOne column-small incoterms-column"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.INCOTERMS }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ORDER_NUMBER">
                    <mat-header-cell class="column_medium nynas-order-number" [ngClass]="{'nynas-order-number-uk-column': (countryCode === 'GBR') }" *matHeaderCellDef mat-sort-header>
                        {{'HOME.NynasOrderNumber'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium nynas-order-number" [ngClass]="{'nynas-order-number-uk-column': (countryCode === 'GBR') }" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ORDER_NUMBER }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ORDER_TYPE">
                    <mat-header-cell class="column_medium nynas-order-type" *matHeaderCellDef [ngClass]="{'hide-table-column': !(showUK)}">
                        {{'HOME.NynasOrderType'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium nynas-order-type" [ngClass]="{'hide-table-column': !(showUK)}"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ORDER_TYPE }}
                    </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="JDE_ADDRESS_NUMBER">
                    <mat-header-cell class="column_medium" *matHeaderCellDef mat-sort-header>
                        {{'HOME.NynasDeliveryPlace'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.JDE_ADDRESS_NUMBER }}
                    </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="ORIGINAL_ORDER_NUMBER">
                    <mat-header-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium" *matHeaderCellDef>
                        {{'HOME.NynasOriginalordernumber'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': !(showUK)}" class="column_medium"
                        *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ORIGINAL_ORDER_NUMBER }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ADHESIVE_CONTENT">
                    <mat-header-cell [ngClass]="{'hide-table-column': (!showUK || (countryCode === 'SWE' || countryCode === 'NOR'))}"
                        class="column_medium addisive-content" *matHeaderCellDef>
                        {{'HOME.AddisivecontentPercentage'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': (!showUK || (countryCode === 'SWE' || countryCode === 'NOR'))}"
                        class="column_medium addisive-content" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.ADHESIVE_CONTENT }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="TEMPERATURE">
                    <mat-header-cell [ngClass]="{'hide-table-column': (!showUK || (countryCode === 'SWE' || countryCode === 'NOR'))}"
                        class="column_medVarOne column-small" *matHeaderCellDef>
                        {{'HOME.Temperature'| translate}}
                    </mat-header-cell>
                    <mat-cell [ngClass]="{'hide-table-column': (!showUK || (countryCode === 'SWE' || countryCode === 'NOR'))}"
                        class="column_medVarOne column-small" *matCellDef="let fetchedDataForItemArray">
                        {{ fetchedDataForItemArray.TEMPERATURE }}
                    </mat-cell>
                </ng-container>
                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'row-top-border': row.showRowTopBorder}">
                </mat-row>
            </mat-table>

        </div>

            <!-- <mat-paginator [length]="50" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons>
            </mat-paginator> -->
            
        </div>
    </div>
    <!-- <div class="row" *ngIf="currentToggleMenu === 'graph' && displayGraph && isRequestCompleted">
        <div class="col-lg-8 col-md-8 graph_div">
            <div id="bar1" *ngIf="(userInfo.RoleID === 'U')">
                <canvas baseChart [datasets]="barChartData1" [labels]="barChartLabels1" [options]="barChartOptions1"
                    [plugins]="barChartPlugins1" [legend]="barChartLegend1" [chartType]="barChartType1">
                </canvas>
            </div>
            <div id="bar2" *ngIf="(userInfo.RoleID === 'PA' || userInfo.RoleID === 'A' )">
                <canvas baseChart [datasets]="barChartData2" [labels]="barChartLabels2" [options]="barChartOptions2"
                    [plugins]="barChartPlugins2" [legend]="barChartLegend2" [chartType]="barChartType2">
                </canvas>
            </div>
        </div>
    </div> -->
</div>