import { SelectionModel } from '@angular/cdk/collections';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import { ManageUsersService } from 'src/app/user-management/manage-users/manage-users.service';
import * as XLSX from 'xlsx';
import { PendingForecast } from '../forecast';
import { ForecastEntryService } from '../forecast-entry/forecast-entry.service';
import { CommonService } from '../order-management/order-management.component.service';
import { PendingForecastDialogComponent } from '../pending-forecast-dialog/pending-forecast-dialog.component';
import { PendingForecastService } from './pending-forecast.component.service';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from 'src/app/global-search/global-search.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-pending-forecast',
  templateUrl: './pending-forecast.component.html',
  styleUrls: ['./pending-forecast.component.css']
})
export class PendingForecastComponent implements OnInit, OnDestroy {
  // yearList: any = ['2020']
  // yearList: number[] = [];
  customerID: any;
  status: any;
  week: any;
  public   dataSource = new MatTableDataSource();
  public dataSourceMontly =new MatTableDataSource();

  public displayedColumns: string[] = ['CustomerName', 'DeliverySite', 'SubscriberEmail', 'Week', 'Status', 'SendReminder', 'EnterForecast'];
  public displayedColumnsForMonthlyTable: string[] = ['CustomerName', 'DeliverySite', 'SubscriberEmail', 'Jan', 'Feb', 'Mar', 'Apr',
    'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // @ViewChild(MatSort, { static: true }) sort: MatSort; 
  @ViewChild('WeeklyTableSort', { static: true }) public WeeklyTableSort: MatSort;
  @ViewChild('MontlyTableSort', { static: true }) public MontlyTableSort: MatSort;

  displayTable: boolean = true;
  role: any;

  currentToggleMenu: string = 'weekly';

  //Week = this.currentWeekNumber();
  year = this.sharedService.getCurrentDateTime().getFullYear();
  dropDownYear = [];
  forecastStatusData: any;
  fetchedDataForItemArray = [];
  Forecast: any;
  selection = new SelectionModel<PendingForecast>(true, []);
  weekUserSelected: any;
  customerUserSelected: any;
  delSiteUserSelected: any;
  pendingRowSelectData: any;
  data: any;
  forecastStatusArr: any = [];
  forecastStatusMontly: any = [];
  dropDownStatus = ['All', 'Pending', 'Completed'];
  dropDownStatusForServicePayload = ['ALL', 'Pending', 'Completed'];
  pendingStatusSelected: string;
  userInfo: any;
  username: string;
  customerName: any;
  currYear: any;
  currWeek: any;
  minWeek: number;

  // Setting maxWeek to 53, since we have only 52 (+ 1) weeks every year
  maxWeek: number = 53;

  forecastStatusForm: FormGroup;
  dropDownWeek = [];
  submitted: boolean;


  noDisp: boolean;
  Customers: any[];
  customerIdUserSelected: any;
  updateDelSites: any[];
  downloadMonthlyData: any ;
  delSite: any[];
  fetchedDataForItemArrayCopy: any;
  // qaUrl = environment.qaUrl;
  // prodUrl = environment.prodUrl;
  countryCode: any;
  translatedMail: any;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  UserID: any;
  wishFrom: string;
  yearUserSelected: any;
  isLoggedInUserAdmin: boolean;
  deliverySiteObjectList: any = [];
	globalSearchText: string;
	showGlobalSearchTextBox: boolean = true;
  isFirstOnLoad: boolean = true;

  constructor(private commonService: CommonService,
    public dialog: MatDialog,
    private forecastEntryService: ForecastEntryService,
    private pendingForecastService: PendingForecastService,
    private sharedService: AppService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private manageUsersService: ManageUsersService,
		private globalSearchService: GlobalSearchService) {
    if (this.sharedService.getLoginResponse() == undefined) {
      this.noDisp = true
    } else {
      this.userInfo = this.sharedService.getLoginResponse();
      this.username = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
      this.role = (this.userInfo ? this.userInfo.RoleID : '');
      this.customerID = (this.userInfo ? this.userInfo.CustomerID : '');
      this.customerName = (this.userInfo ? this.userInfo.CustomerName : '');
      this.isLoggedInUserAdmin = (this.userInfo ? (this.userInfo.RoleID === "PA" || this.userInfo.RoleID === "A") : false);
      this.week = this.forecastEntryService.getWeek()
      this.countryCode = this.userInfo.CountryCode;
      this.noDisp = false
      this.UserID = (this.userInfo ? this.userInfo.UserID : '');
    }
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  get fields() {
    return this.forecastStatusForm.controls;
  }

  async ngOnInit() {
    //set curr week n year number
    const date = this.sharedService.getCurrentDateTime();
    var result = this.getWeekNumber(date)
    this.currYear = result[0];
    this.currWeek = result[1];
    let nextWeekNumber: number = this.currWeek + 1;

    if (this.currWeek === 53) {
      nextWeekNumber = 1;
      this.currYear += 1;
    }

    //set min and max number of weeks
    this.minWeek = 1;

    // Setting maxWeek to 53, since we have only 52 (+ 1) weeks every year
    // this.maxWeek = this.getNumberOfWeeks(this.sharedService.getCurrentDateTime())

    // week list
    // this.getWeekList(this.currWeek);
    // year list
    this.getYearList(this.currYear);


   // form control
   let regexNum = "^[0-9]*$";
   this.forecastStatusForm = this.formBuilder.group({
     week: [nextWeekNumber, [Validators.required, Validators.min(this.minWeek), Validators.max(this.maxWeek), Validators.pattern(regexNum)]],
     year: [this.currYear, Validators.required],
     // week: [this.currWeek + 1, Validators.required],
   });



    //set week in service
    this.weekUserSelected = this.forecastStatusForm.value.week;
    //set year in service
    this.yearUserSelected = this.forecastStatusForm.value.year;

    this.pendingStatusSelected = (this.role === "U" ? this.dropDownStatus[0] : this.dropDownStatusForServicePayload[0] );
    // get customers names
    await this.getCustomers();

    //get del sites
    try {
      await this.getDeliverySites();
    } catch (error) {
      // console.log(error)
    }
    this.customerUserSelected = this.customerName;
    // this.customerIdUserSelected = this.customerID;

    try {
      await this.fetchOnClick();
      //await this.getForecastStatusMonthly();
    } catch (error) {
      // console.log(error)
    }

    try {
      //await this.getForecastStatus();
      await this.getForecastStatusMonthly();
    } catch (error) {
      // console.log(error)
    }


    // this.getForecastStatus();
    //this.getDummyData();
    if (this.userInfo) {
      this.customerIdUserSelected = (this.userInfo.RoleID === "PA") ? '' : (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
      // this.delSiteUserSelected = '';
    } else {
      this.customerIdUserSelected = '';
      // this.delSiteUserSelected = '';
    }

    if (this.countryCode == "FIN") {
      this.wishFrom = "Nynas Oy"
    } else if (this.countryCode == "NOR") {
      this.wishFrom = "Nynas AS"
    } else {
      this.wishFrom = "Nynas Support Helpdesk"
    }

		this.subscribeToGlobalSearchServiceSearchTerm();

  }

  ngAfterViewInit(): void {
    // this.dataSource.sort = this.sort;
    
  
    this.dataSource.sort = this.WeeklyTableSort;
    this.dataSourceMontly.sort = this.MontlyTableSort;
    this.changeDetectorRef.detectChanges();	
		this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
			if (data[sortHeaderId]) {
				return data[sortHeaderId].toLocaleLowerCase()
			}
			return null;
		};	
		this.dataSourceMontly.sortingDataAccessor = (data, sortHeaderId) => {
			if (data[sortHeaderId]) {
				return data[sortHeaderId].toLocaleLowerCase()
			}
			return null;
		};

  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.globalSearchText = '';
  }
  
  getYearList(currYear) {
    this.dropDownYear.push(currYear - 1, currYear, currYear + 1)
  }
  async getCustomers() {
    this.Customers = [];
    return new Promise((resolve, reject) => {
      this.forecastEntryService.getCustomerNames(this.countryCode).subscribe(
        res => {
          if (res["ResponseCode"] === "200") {
            this.Customers = res["Customers"]
            // this.customerUserSelected = this.pendingForecastService.getPCustomerName() ? this.pendingForecastService.getPCustomerName() : this.customerName;
            // this.customerUserSelected = this.customerName;
            // this.customerIdUserSelected = this.customerID;
            if (this.userInfo) {
              this.customerIdUserSelected = (this.userInfo.RoleID === "PA") ? '' : (this.userInfo.CustomerID ? this.userInfo.CustomerID : '');
            } else {
              this.customerIdUserSelected = '';
            }
            resolve();
          }
          else {
            reject();
          }
        });
    })
  }
  async getDeliverySites() {
    this.delSite = [];
    this.delSiteUserSelected = "";
    this.deliverySiteObjectList = [];
    if (this.countryCode == "SWE" || this.countryCode == "NOR") {
      if (this.role == "PA" || this.role == "A") {
        return new Promise((resolve, reject) => {
          this.commonService.getDeliverySite(this.customerIdUserSelected, "Inactive", this.countryCode).subscribe(
            response => {
              if (response["ResponseCode"] === "200") {
                for (let deliverySiteObject of response["DeliverySiteCollection"]) {
                  this.deliverySiteObjectList.push(deliverySiteObject);
                  this.delSite.push(deliverySiteObject["DeliverySite"]);
                  this.delSiteUserSelected = '';
                }
                resolve();
              }
              else {
                reject();
              }
            });
        })
      } else {
        return new Promise((resolve, reject) => {
          this.commonService.getDeliverySiteForUser(this.UserID, this.countryCode, "ACTIVE", this.customerIdUserSelected).subscribe(
            response => {
              if (response["ResponseCode"] === "200") {
                for (let deliverySiteObject of response["DeliverySites"]) {
                  this.deliverySiteObjectList.push(deliverySiteObject);
                  this.delSite.push(deliverySiteObject["DeliverySiteName"]);
                  this.delSiteUserSelected = '';
                }
                this.forecastEntryService.setDeliverySite(this.delSiteUserSelected)
                resolve();
              }
              else {
                reject();
              }
            });
        })
      }


    } else {
      return new Promise((resolve, reject) => {
        this.commonService.getDeliverySite(this.customerIdUserSelected, "Inactive", this.countryCode).subscribe(
          response => {
            if (response["ResponseCode"] === "200") {
              for (let deliverySiteObject of response["DeliverySiteCollection"]) {
                this.deliverySiteObjectList.push(deliverySiteObject);
                this.delSite.push(deliverySiteObject["DeliverySite"]);
                this.delSiteUserSelected = '';
              }
              resolve();
            }
            else {
              reject();
            }
          });
      })

    }

    // return new Promise((resolve, reject) => {
    //   this.commonService.getDeliverySite(this.customerIdUserSelected, "Inactive", this.countryCode).subscribe(
    //     res => {
    //       if (res["ResponseCode"] === "200") {
    //         for (let index of res["DeliverySiteCollection"]) {
    //           this.delSite.push(index["DeliverySite"])
    //           this.delSiteUserSelected = ''
    //         }
    //         resolve();
    //       }
    //       else {
    //         reject();
    //       }
    //     });
    // })


  }

  getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart
    yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }

  changeOnClick() {
    this.weekUserSelected = this.forecastStatusForm.value.week;
    this.yearUserSelected = this.forecastStatusForm.value.year;
    // this.getForecastStatus()
  }

  async fetchOnClick() {
    this.submitted = true;
    if (!this.forecastStatusForm.valid) {
      return false;
    } else {
      try {
        this.forecastStatusData = [];
        this.forecastStatusData = await this.getForecastStatusWeekly();
      } catch (error) {
        this.forecastStatusData = null;
      }
      this.dataSource = new MatTableDataSource(this.forecastStatusData);
       this.dataSource.sort = this.WeeklyTableSort;

    }
  }

  getForecastStatusWeekly() {
    if (this.role == "U" && (this.countryCode == "SWE" || this.countryCode == "NOR")) {
      return new Promise(async (resolve, reject) => {
        let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelected);
        this.pendingForecastService.getWeeklyForecastStatusForUser(
          this.customerIdUserSelected,
          jdeAddressNumber,
          this.weekUserSelected,
          // this.currYear,
          this.yearUserSelected,
          this.countryCode,
          this.UserID,
          this.pendingStatusSelected)
          .subscribe(
            res => {
              if (res["ResponseCode"] === "200") {
                this.forecastStatusArr = [];
                this.forecastStatusArr = res["ForecastList"];
                this.displayTable = true;
                // this.dataSource = new MatTableDataSource(this.forecastStatusArr);
                //  this.dataSource.sort = this.WeeklyTableSort;
                resolve(this.forecastStatusArr)
                // resolve()
              }
              else {
                this.displayTable = false;
                reject();
              }
            }
          );
      })
    } else {
      return new Promise(async (resolve, reject) => {
        let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelected);
        this.pendingForecastService.getWeeklyForecastStatus(
          this.customerIdUserSelected,
          jdeAddressNumber,
          this.pendingStatusSelected,
          this.weekUserSelected,
          // this.currYear,
          this.yearUserSelected,
          this.countryCode)
          .subscribe(
            res => {
              if (res["ResponseCode"] === "200") {
                this.forecastStatusArr = [];
                this.forecastStatusArr = res["ForecastList"];
                this.displayTable = true;
                // this.dataSource = new MatTableDataSource(this.forecastStatusArr);
                // this.dataSource.sort = this.WeeklyTableSort;
                resolve(this.forecastStatusArr)
                // resolve()
              }
              else {
                this.displayTable = false;
                reject();
              }
            }
          );
      })
    }


  }

  getForecastStatusMonthly() {
    if(this.role == "U" && (this.countryCode == "SWE" || this.countryCode == "NOR")) {
      return new Promise(async (resolve, reject) => {
        this.pendingForecastService.GetMonthlyForecastStatusForUser(
          this.customerIdUserSelected,
          this.delSiteUserSelected,
          this.yearUserSelected,
          this.countryCode,
          this.UserID)
          .subscribe(
            res => {
              if (res["ResponseCode"] === "200") {
                let forecastStatusMontlyTable = [];
  
                this.forecastStatusMontly = res["MonthlyForecastList"];
              
                this.forecastStatusMontly.forEach(MonthlyForecast => {
                  let monthlyData = MonthlyForecast['DeliverySiteCollection'];
                  monthlyData.forEach(DeliverySite => {
                    DeliverySite['CustomerName'] = MonthlyForecast['CustomerName'];
                    forecastStatusMontlyTable.push(DeliverySite);
                  });
                });
                this.displayTable = true;
                this.dataSourceMontly = new MatTableDataSource(forecastStatusMontlyTable);
                this.downloadMonthlyData = forecastStatusMontlyTable;
               
                 this.dataSourceMontly.sort = this.MontlyTableSort;
                // resolve(forecastStatusArr)
                resolve();
              }
              else {
                this.displayTable = false;
                reject();
              }
            }
          );
      })
    } else {
      return new Promise(async (resolve, reject) => {
        let jdeAddressNumber = this.getJDEAddressNumberByDeliverySiteName(this.delSiteUserSelected);
        this.pendingForecastService.GetMonthlyForecastStatus(
          this.customerIdUserSelected,
          jdeAddressNumber,
          this.pendingStatusSelected,
          this.yearUserSelected,
          this.countryCode)
          .subscribe(
            res => {
              if (res["ResponseCode"] === "200") {
                let forecastStatusMontlyTable = [];

                this.forecastStatusMontly = res["MonthlyForecastList"];

                this.forecastStatusMontly.forEach(MonthlyForecast => {
                  let monthlyData = MonthlyForecast['DeliverySiteCollection'];
                  monthlyData.forEach(DeliverySite => {
                    DeliverySite['CustomerName'] = MonthlyForecast['CustomerName'];
                    forecastStatusMontlyTable.push(DeliverySite);
                  });
                });
                this.displayTable = true;
                this.dataSourceMontly = new MatTableDataSource(forecastStatusMontlyTable);
                this.downloadMonthlyData = forecastStatusMontlyTable;

                this.dataSourceMontly.sort = this.MontlyTableSort;
                // resolve(forecastStatusArr)
                resolve();
              }
              else {
                this.displayTable = false;
                reject();
              }
            }
          );
      })
    }


  }

  toggleButtonClick(toggleMenu: string) {
    // this.reset();
    this.currentToggleMenu = toggleMenu;
    // this.priceIndexForm.controls['priceIndexType'].setValue(toggleMenu);
    // this.getCurrentMonthAndYear();
    // this.getPriceIndexList(this.currentToggleMenu);    
  }


  setData(data: any) {
    // console.log(data)
    this.pendingForecastService.setPData(data)
    this.updateSelectedItem('Forecast entry');
    this.forecastEntryService.setWeek(data.Week)
    this.forecastEntryService.setCustomerName(data.CustomerName)
    this.forecastEntryService.setDeliverySite(data.DeliverySite)


    this.weekUserSelected = data.Week;
    this.customerUserSelected = data.CustomerName;
    this.delSiteUserSelected = data.DeliverySite;
    this.pendingRowSelectData = data;

    // Set Delivery site and Week in service to retrieve it, when user enters forecast from Forecast Status page
    this.pendingForecastService.setPWeek(data.Week);
    this.pendingForecastService.setPDelSite(data.DeliverySite);
    this.pendingForecastService.setPCustomerName(data.CustomerName)

    return this.pendingRowSelectData;
  }

  openDialog(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string, emailTo: string, extraParams?: any) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,
        emailTo: emailTo,
        extraParams: extraParams
      },
      // used auto for height, as pop up will display list of email ids vertically
      height : 'auto',
      // height: '35%',
      // width: (this.mobileQuery.matches ? '80%' : '25%'),
      width : 'auto',
    });
  }

  // Commented old code, kept for future reference
  /* downloadDefaulterList() {
    if(this.currentToggleMenu === "monthly")
    {
       this.pendingForecastService.exportAsExcelFileMonthly(this.downloadMonthlyData, "Forecast Status Monthly");   
    }
    else{
      this.pendingForecastService.exportAsExcelFile(this.forecastStatusArr, "Forecast Status");
    }
  
  } */  

  updateSelectedItem(selectedItem: string) {
    this.commonService.updateSelectedItem(selectedItem)
  }

  sendReminder(pendingForecast: any) {
    // console.log(environment)
    this.translate.get(['HOME.forecastReminderMail'])
      .subscribe(data => {
        this.translatedMail = data['HOME.forecastReminderMail']
      });
    let emailTo = pendingForecast.SubscriberEmail;
    let emailBody = "<html>"
      + "<head>"
      + "<title></title>"
      + "<style type='text/css'></style>"
      + "</head>"
      + "<body aria-readonly='false'>"
      + this.translatedMail.hello + "<b>" + pendingForecast.SubscriberEmail + "</b>,"
      + "<br>"
      + "<br>"
      + this.translatedMail.forecastPending + " "
      + this.translatedMail.week + "<b>" + pendingForecast.Week + "</b> "
      + this.translatedMail.deliverySite + "<b>" + pendingForecast.DeliverySite + "</b>"
      + "<br>"
      + this.translatedMail.stmt1
      + "<br>"
      + "<br>"
      + this.translatedMail.stmt1w
      + "<br>"
      + "<br>"
      + this.translatedMail.stmt2
      + "<br>"
      + "<br>"
      + this.translatedMail.wish
      + "<br>"
      + "<b>" + this.wishFrom + "</b>"
      + "<br>"
      + "<br>"
      + "<p style='color:Red'>"
      + "<i>" + this.translatedMail.sysMail + "</i>"
      + "</p>"
      + "</body>"
      + "</html>";
    let emailSubject = this.translatedMail.sub + this.translatedMail.week + " " + pendingForecast.Week + " " + this.translatedMail.deliverySite + " " + pendingForecast.DeliverySite;
    this.pendingForecastService.sendEmailNotification(emailTo, emailBody, emailSubject)
      .subscribe(
        response => {
          if (response['Status'] && response['Status'].toUpperCase() === 'SUCCESS') {
            this.openDialog("", "", true, 'pending-forecast-success', emailTo);
          } else {
            this.openDialog("", "", false, 'pending-forecast-fail', emailTo);
          }
        });
  }

  sendReminderMonthly(pendingForecast: any, month) {

    if (this.countryCode === 'FIN') {
      this.showReminderDialogForFinlandUsers(month, (pendingForecast['CustomerName'] ? pendingForecast['CustomerName'] : ''));
    } else {
      if (this.role !== 'U') {

        this.translate.get(['HOME.forecastReminderMail'])
          .subscribe(data => {
            this.translatedMail = data['HOME.forecastReminderMail']

          });
        let emailTo = pendingForecast.SubscriberEmail;
        let emailBody = "<html>"
          + "<head>"
          + "<title></title>"
          + "<style type='text/css'></style>"
          + "</head>"
          + "<body aria-readonly='false'>"
          + this.translatedMail.hello + "<b>" + pendingForecast.SubscriberEmail + "</b>,"
          + "<br>"
          + "<br>"
          + this.translatedMail.forecastPending + " "
          + this.translatedMail.month + "<b>" + month + "</b> "
          + this.translatedMail.deliverySite + "<b>" + pendingForecast.DeliverySite + "</b>"
          + "<br>"
          + this.translatedMail.stmt1
          + "<br>"
          + "<br>"
          + this.translatedMail.stmt1m
          + "<br>"
          + "<br>"
          + this.translatedMail.stmt2
          + "<br>"
          + "<br>"
          + this.translatedMail.wish
          + "<br>"
          + "<b>" + this.wishFrom + "</b>"
          + "<br>"
          + "<br>"
          + "<p style='color:Red'>"
          + "<i>" + this.translatedMail.sysMail + "</i>"
          + "</p>"
          + "</body>"
          + "</html>";
        let emailSubject = this.translatedMail.sub + this.translatedMail.month + " - " + month + " " + this.translatedMail.deliverySite + " - " + pendingForecast.DeliverySite;
        this.pendingForecastService.sendEmailNotification(emailTo, emailBody, emailSubject)
          .subscribe(
            response => {
              if (response['Status'] && response['Status'].toUpperCase() === 'SUCCESS') {
                this.openDialog("", "", true, 'pending-forecast-success', emailTo);
              } else {
                this.openDialog("", "", false, 'pending-forecast-fail', emailTo);
              }
            });

        // Code added temporarily, uncomment for testing until service is ready
        /* emailTo = [];
        emailTo.push('sayalia.chavan@lntinfotech.com');
        emailTo.push('shweta.kadav@lntinfotech.com');
        emailTo.push('sarang.khadilkar@nynas.com');
        emailTo.push('kapil.sharma@lntinfotech.com');
        emailTo.push('varad.mayekar@lntinfotech.com');
        this.openDialog("", "", true, 'finland-pending-monthly-forecast-success', emailTo, emailTo); */
      }
    }

    // console.log(month)
    // console.log(pendingForecast)
    // this.translate.get(['HOME'])
    //   .subscribe(data => {
    //     var translate = data['HOME'].month
    //     console.log(translate)
    //   });

  }

  checkboxLabel() {
    // added this function to fix ng build prod errors
  }

  masterToggle() {
    // added this function to fix ng build prod errors
  }

  isAllSelected() {
    // added this function to fix ng build prod errors
    return false;
  }

  showReminderDialogForFinlandUsers(month: string, customerName: string) {

    let selectedEmailIdList = [];
    let emailIdObject = {};
    emailIdObject['emailId'] = 'shweta.kadav@lntinfotech.com';
    emailIdObject['isSelected'] = true;
    selectedEmailIdList.push(emailIdObject);

    let emailIdObject1 = {};
    emailIdObject1['emailId'] = 'sayalia.chavan@lntinfotech.com';
    emailIdObject1['isSelected'] = true;
    selectedEmailIdList.push(emailIdObject1);

    let emailIdObject2 = {};
    emailIdObject2['emailId'] = 'varad.mayekar@lntinfotech.com';
    emailIdObject2['isSelected'] = true;
    selectedEmailIdList.push(emailIdObject2);

    let emailIdObject3 = {};
    emailIdObject3['emailId'] = 'divyansh.singh@lntinfotech.com';
    emailIdObject3['isSelected'] = true;
    selectedEmailIdList.push(emailIdObject3);

    let emailIdObject4 = {};
    emailIdObject4['emailId'] = 'sarang.khadilkar@nynas.com';
    emailIdObject4['isSelected'] = true;
    selectedEmailIdList.push(emailIdObject4);

    this.openPendingForecastDialog(selectedEmailIdList, month, customerName);

    /* let emailIdList = [];
    emailIdList.push('sayalia.chavan@lntinfotech.com');
    emailIdList.push('shweta.kadav@lntinfotech.com');
    emailIdList.push('sarang.khadilkar@nynas.com');
    emailIdList.push('kapil.sharma@lntinfotech.com');
    emailIdList.push('varad.mayekar@lntinfotech.com');
    this.openPendingForecastDialog(emailIdList); */

    /* if (customerID) {
      this.manageUsersService.getUsers('', this.countryCode, customerID)
        .subscribe(
          response => {
            if (response && response['Users'] && response['Users'].length) {
              let customerAdminRoleUserList = [];
              for (let user of response['Users']) {
                if (user['Role'] === 'A') {
                  let emailIdObject = {};
                  emailIdObject['emailId'] = user['EmailID'];
                  emailIdObject['isSelected'] = true;
                  customerAdminRoleUserList.push(emailIdObject);
                }
              }
              this.openPendingForecastDialog(customerAdminRoleUserList);
            }
          });
    } */
  }

  openPendingForecastDialog(emailIdList: any, month: string, customerName: string) {
    const dialogRef = this.dialog.open(PendingForecastDialogComponent, {
      data: {
        emailIdList: emailIdList,
        months: (month ? [month.toUpperCase()] : ''),
        customerName: customerName
      },
      height: 'auto',
      // width: 'auto',
      width: (this.mobileQuery.matches ? '80%' : '25%'),
    });
    /* dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.openDialog("", "", true, 'pending-forecast-success', '');
      }
    }); */
  }

  getJDEAddressNumberByDeliverySiteName(deliverySiteName: string): string {
    let jdeAddressNumber = '';
    if (this.deliverySiteObjectList && this.deliverySiteObjectList.length) {
      for (let deliverySiteObject of this.deliverySiteObjectList) {
        let deliverySiteObjectName = (deliverySiteObject['DeliverySite'] || deliverySiteObject['DeliverySiteName']);
        if (deliverySiteObject && deliverySiteObjectName && deliverySiteObjectName === deliverySiteName) {
          jdeAddressNumber = (deliverySiteObject['JdeAddressNumber'] || deliverySiteObject['JDE_Address_Number']);
          break;
        }
      }
    }
    return jdeAddressNumber;
  }

  async download() {

    let headerList: any, monthlyforecastStatusExcelDataList: any = [];

    if (this.currentToggleMenu === "monthly") {

      if (this.downloadMonthlyData && this.downloadMonthlyData.length) {
        for (let monthlyData of this.downloadMonthlyData) {
          let forecastStatusExcelDataObject = {};
          forecastStatusExcelDataObject['Customer name'] = monthlyData['CustomerName'];
          forecastStatusExcelDataObject['Delivery site'] = monthlyData['DeliverySite'];
          forecastStatusExcelDataObject['Customer contact'] = monthlyData['SubscriberEmail'];
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'Jan');
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'Feb');
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'Mar');
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'Apr');
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'May');
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'Jun');
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'Jul');
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'Aug');
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'Sep');
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'Oct');
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'Nov');
          this.getTranslatedStatusForExcelFile(forecastStatusExcelDataObject, monthlyData, 'Dec');
          monthlyforecastStatusExcelDataList.push(forecastStatusExcelDataObject);
        }
      }

      // Commented old code, kept for future reference
      // this.exportAsExcelFileMonthly(this.downloadMonthlyData, "Forecast Status Monthly");

      headerList = ["Customer name", "Delivery site", "Customer contact", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      this.sharedService.downloadDataInExcelFile(monthlyforecastStatusExcelDataList, 'Forecast Status Monthly', headerList);
    }
    else {

      let forecastStatusList: any = [], forecastStatusExcelDataList: any = [];
      forecastStatusList = await this.getForecastStatusWeekly();

      if (forecastStatusList && forecastStatusList.length) {
        for (let forecastStatus of forecastStatusList) {
          let forecastStatusExcelDataObject = {};
          forecastStatusExcelDataObject['Customer name'] = forecastStatus['CustomerName'];
          forecastStatusExcelDataObject['Delivery site'] = forecastStatus['DeliverySite'];
          forecastStatusExcelDataObject['Customer contact'] = forecastStatus['SubscriberEmail'];
          forecastStatusExcelDataObject['Week'] = forecastStatus['Week'];

          // Translate status for excel
          this.translate.get(['HOME.' + forecastStatus['Status']])
            .subscribe(data => {
              forecastStatusExcelDataObject['Status'] = ((data['HOME.' + forecastStatus['Status']]));
            });

          forecastStatusExcelDataList.push(forecastStatusExcelDataObject);
        }
      }

      // Commented old code, kept for future reference
      // this.exportAsExcelFile(forecastStatusExcelDataList, "Forecast Status");

      headerList = ["Customer name", "Delivery site", "Customer contact", "Week", "Status"];

      this.sharedService.downloadDataInExcelFile(forecastStatusExcelDataList, 'Forecast Status', headerList);

    }
  }

  exportAsExcelFileMonthly(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json),
        { header: ["CustomerName", "DeliverySite", "SubscriberEmail", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Total"] });
      const workbook: XLSX.WorkBook = { Sheets: { 'Data in excel': worksheet }, SheetNames: ['Data in excel'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json),
        { header: ["Customer name", "Delivery site", "Primary email address", "Week", "Status"] });
      const workbook: XLSX.WorkBook = { Sheets: { 'Data in excel': worksheet }, SheetNames: ['Data in excel'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const ForecastStatus: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(ForecastStatus, fileName + EXCEL_EXTENSION);
  }

  reinitializeGlobalSearchComponent() {
    this.globalSearchText = '';
    this.showGlobalSearchTextBox = false;
    this.changeDetectorRef.detectChanges();
    this.showGlobalSearchTextBox = true;
    this.changeDetectorRef.detectChanges();
  }

  subscribeToGlobalSearchServiceSearchTerm() {
    // this listens to the input value from the service and does something on change.
    this.globalSearchService.searchTerm.subscribe((newValue: string) => {
      // this is where you would apply your existing filtering.
      this.applyFilter((this.isFirstOnLoad ? '' : newValue));
      this.isFirstOnLoad = false;
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches

      if (this.currentToggleMenu && this.currentToggleMenu === "monthly") {
        this.dataSourceMontly.filter = filterValue;
      } else {
        this.dataSource.filter = filterValue;
      }

      // this.displayTable = (this.dataSource && this.dataSource.filteredData && this.dataSource.filteredData.length) ? true : false;
    }
  }

  getTranslatedStatusForExcelFile(forecastStatusExcelDataObject: any, monthlyData: any, monthName: string): void {
    if (monthlyData && monthlyData[monthName]) {
      let status: string = monthlyData[monthName];
      this.translate.get(['HOME.' + status])
        .subscribe(data => {
          forecastStatusExcelDataObject[monthName] = (data['HOME.' + status]);
        });
    }
  }

}
