<div class="container-fluid sidepadding-none">
    <div>

        <div class="row ">
            <div class="col-lg-12 col-md-12 div_graph div_ie">
                <div class='row no-gutters'>
                    <!-- <div class="col-lg-2 col-md-2"></div> -->

                    <div class="col-lg-12 col-md-12 testing">
                        <div class="row">
                            <label class="font-bold font-small customer_name_label_div_graph_mv"
                                for="customerName">{{'HOME.Customername'| translate}}</label>
                            <select class="selectDropdownYear cust_drop_graph_MV" [(ngModel)]="selectedCustomerIdForGraph"
                                [ngClass]="{'disabled-dropdown': !isLoggedInUserPortalAdmin}"
                                (change)="this.getDeliverySitesForGraph()">
                                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                                    {{customer.CustomerName}}
                                </option>
                            </select>
                            <label class="monthlyLabel3 delivery_Site_Label_graph_mv" for="deliverySite">
                                {{'HOME.Deliverysite'| translate}}
                            </label>
                            <select [(ngModel)]='delSiteUserSelectedForGraph'
                                class="forecastSelect form-control delivery_Site_Dropdown_graph_MV">
                                <option *ngFor="let delSiteGraph of delSitesGraph" [value]="delSiteGraph">
                                    {{delSiteGraph}}</option>
                            </select>

                            <label class="font-bold font-small year-label-width year_label"
                                for="productType">{{'HOME.Year'| translate}}</label>
                            <select class="selectDropdownYear" [(ngModel)]="yearToSearch">

                                <option *ngFor="let year of years" [value]="year">
                                    {{ year }}
                                </option>
                            </select>

                            <button type="button" class="btn filter-button" for="filter"
                                (click)='filterIndex(yearToSearch)'>
                                <label class="font-bold " for="filter">{{'HOME.Find'| translate}}</label></button>
                        </div>
                    </div>
                    <!-- <div class="col-lg-2 col-md-2"></div> -->
                </div>
            </div>
            <div class="col-lg-2 col-md-2">

            </div>
            <div class="col-lg-8 col-md-8">
                <div class="mt-5" [hidden]="!displayErrorMsg" class="errMsg">
                    <span>{{'HOME.Norecordsfound'| translate}}</span>
                </div>
                <div id="bar1" *ngIf="(userInfo.RoleID === 'U')">
                    <canvas baseChart [datasets]="barChartData1" [labels]="barChartLabels1" [options]="barChartOptions1"
                        [plugins]="barChartPlugins1" [legend]="barChartLegend1" [chartType]="barChartType1">
                    </canvas>
                </div>
                <div id="bar2" *ngIf="(userInfo.RoleID === 'PA' || userInfo.RoleID === 'A' )">
                    <canvas baseChart [datasets]="barChartData2" [labels]="barChartLabels2" [options]="barChartOptions2"
                        [plugins]="barChartPlugins2" [legend]="barChartLegend2" [chartType]="barChartType2">
                    </canvas>
                </div>
            </div>
            <div class="col-lg-2 col-md-2">

            </div>


        </div>
        <hr>
        <div class="row">
            <div class="col-lg-12" [hidden]='!displayTable'>
                <div class="downloadHead">
                    <i (click)="downloadData()" class="fas fa-download downloadIcon"><span
                            class="downloadText">{{'HOME.Download'| translate}}</span></i>
                </div>
            </div>
        </div>
        <div class="col-lg-12 div_style">

            <div class='row no-gutters'>
                <label class="font-bold font-small custname_label customer_name_label_div_mv"
                    for="customerName">{{'HOME.Customername'| translate}}</label>
                <select class="selectDropdown_table cust_drop_MV " [(ngModel)]="selectedCustomerIdForTable"
                    [ngClass]="{'disabled-dropdown': !isLoggedInUserPortalAdmin}"
                    (change)="this.getDeliverySitesForTable()">
                    <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                        {{customer.CustomerName}}
                    </option>
                </select>
                <!--delivery Site-->
                <label class="monthlyLabel3_table delivery_Site_Label_mv" for="deliverySite">
                    {{'HOME.Deliverysite'| translate}}
                </label>
                <select [(ngModel)]='delSiteUserSelectedForTable'
                    class="forecastSelect_table form-control delivery_Site_Dropdown_MV">
                    <option *ngFor="let delSiteTable of delSitesTable" [value]="delSiteTable">{{delSiteTable}}</option>
                </select>
                <!-- year -->
                <label class="font-bold font-small_table year2-margin-style"
                    for="yearForTable">{{'HOME.Year'| translate}}</label>
                <select class="selectDropdownYear_table dropdown-padding-style" [(ngModel)]="yearToSearchForTable">
                    <option *ngFor="let yearForTable of yearsForTable" [value]="yearForTable">
                        {{ yearForTable }}
                    </option>
                </select>
                <!--view by dropdown -->
                <label class="font-small view-by-width " for="reportsTable">{{'HOME.Viewby'| translate}}:</label>
                <select class="selectDropdownYear_table year_drop_margin dropdown-padding-style-MV"
                    [(ngModel)]="dataToSearch">
                    <option *ngFor="let param of filterParam" [value]="param">
                        {{ 'HOME.' + getStringWithoutSpaces(param) | translate }}
                    </option>
                </select>
                <!-- find button -->
                <button type="button" class="btn filter-button_table" for="filter"
                    (click)='filterIndexForTable(yearToSearchForTable , dataToSearch )'>
                    <label class="font-bold " for="filter">{{'HOME.Find'| translate}}</label></button>
            </div>

            <div class="row no-gutters div_year_table">
                <!-- year -->
                <!-- <label class="font-bold font-small_table year2-margin-style"
                    for="yearForTable">{{'HOME.Year'| translate}}</label>
                <select class="selectDropdownYear_table dropdown-padding-style" [(ngModel)]="yearToSearchForTable">
                    <option *ngFor="let yearForTable of yearsForTable" [value]="yearForTable">
                        {{ yearForTable }}
                    </option>
                </select> -->


            </div>
            <div class="col-lg-12">
                <div class="mt-5" [hidden]="displayTable" class="errMsg">
                    <span>{{'HOME.Norecordsfound'| translate}}</span>
                </div>

            </div>
            <!-- by ALL start-->
            <div class="col-12 mt-2 reportTable horizontal_scrollbar_div_MV" *ngIf="viewBy === 'All'"
                [hidden]='!displayTable'>
                <!--Angular 7 Impl-->
                <mat-table #table [dataSource]="dataSource" class="reportTable horizontal_scrollbar_mv" matSort>
                    <!-- DeliveryNumber Column -->

                    <!-- <ng-container matColumnDef="Type">
                        <mat-header-cell class="header-fonttype column_align column_align_MV" *matHeaderCellDef
                            mat-sort-header>
                           {{'HOME.Type'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-fonttype column_align column_align_MV"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.Type}}
                        </mat-cell>
                    </ng-container> -->

                    <ng-container matColumnDef="CustomerName">
                        <mat-header-cell class="header-font column_cname" *matHeaderCellDef>
                            {{'HOME.Customername'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font column_cname column_align_MV"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.CustomerName}}


                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="WeekNumber">
                        <mat-header-cell class="header-font column_align column_align_MV" *matHeaderCellDef>
                            {{'HOME.WeekNumber'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font column_align column_align_MV"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.WeekNumber}}


                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ForecastQuantity">
                        <mat-header-cell class="header-font mat_column_delSite" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Forecast'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.forecastquantity}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="ActualQuantity">
                        <mat-header-cell class="header-font mat_column_delSite" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Actual'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.actualquantity}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Difference">
                        <mat-header-cell class="header-font mat_column_delSite" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Difference'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.difference}}<span> % </span>
                        </mat-cell>
                    </ng-container>

                    <!-- <ng-container matColumnDef="Total">
                        <mat-header-cell class="header-font column_total" *matHeaderCellDef>
                          
                            {{'HOME.Total'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font column_total" *matCellDef="let fetchedDataForItemArray">
                           


                        </mat-cell>
                    </ng-container> -->


                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>


            </div>
            <!-- by all end -->

            <!-- bydepot start -->

            <div class="col-12 mt-2 reportTable horizontal_scrollbar_div_MV" *ngIf="viewBy === 'By depot'"
                [hidden]='!displayTable'>

                <mat-table #table [dataSource]="dataSource1" class="reportTable horizontal_scrollbar_mv" matSort>

                    <ng-container matColumnDef="CustomerName">
                        <mat-header-cell class="header-font column_cname mat_column_delSite" *matHeaderCellDef>
                            {{'HOME.Customername'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font column_cname column_align_MV mat_column_delSite"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.CustomerName}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Depot name">
                        <mat-header-cell class="header-fonttypeforDepo mat_column_depot column_align_MV"
                            *matHeaderCellDef mat-sort-header>{{'HOME.DepotName'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-fonttypeforDepo mat_column_depot " *matCellDef="let DepotData">
                            {{DepotData.DepotName}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="WeekNumber">
                        <mat-header-cell class="header-font mat_column_depot column_align_MV" *matHeaderCellDef>
                            {{'HOME.WeekNumber'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_depot column_align_MV"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.WeekNumber}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ForecastQuantity">
                        <mat-header-cell class="header-font mat_column_depot" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Forecast'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-fontmat_column_depot" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.forecastquantity}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ActualQuantity">
                        <mat-header-cell class="header-font mat_column_depot" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Actual'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_depot" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.actualquantity}}
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="Difference">
                        <mat-header-cell class="header-font mat_column_depot" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Difference'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_depot" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.difference}}<span> % </span>
                        </mat-cell>
                    </ng-container>




                    <!-- <ng-container matColumnDef="Total">
                        <mat-header-cell class="header-fontDepoMonth" *matHeaderCellDef>{{'HOME.Total'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-fontDepoMonth" *matCellDef="let DepotData">
                            {{DepotData.Total  | number: '1.0-0'}}<span *ngIf='DepotData.Type === "Difference" '> %
                            </span>
                        </mat-cell>
                    </ng-container> -->

                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnfordepot; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnfordepot;"></mat-row>
                </mat-table>
                <!-- <div class="row buttonGroup">
          <button class=" downloadButton" type="submit" (click)="downloadData()"> {{'HOME.Download'| translate}}</button>
      </div> -->

            </div>

            <!-- bydepot end -->

            <!-- by product start  -->

            <div class="col-12 mt-2 reportTable horizontal_scrollbar_div_MV" *ngIf="viewBy === 'By product'"
                [hidden]='!displayTable'>

                <mat-table #table [dataSource]="dataSource2" class="reportTable  horizontal_scrollbar_mv " matSort>

                    <ng-container matColumnDef="CustomerName">
                        <mat-header-cell class="header-font mat_column_delSite" *matHeaderCellDef>
                            {{'HOME.Customername'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite column_align_MV"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.CustomerName}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Product name">
                        <mat-header-cell class="header-fonttypeforDepo mat_column_depot" *matHeaderCellDef
                            mat-sort-header>{{'HOME.Pname'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-fonttypeforDepo mat_column_depot" *matCellDef="let ProductData">
                            {{ProductData.ProductName}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="WeekNumber">
                        <mat-header-cell class="header-font mat_column_depot" *matHeaderCellDef>
                            <!-- {{'HOME.WeekNumber'| translate}} -->
                            {{'HOME.WeekNumber'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_depot" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.WeekNumber}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ForecastQuantity">
                        <mat-header-cell class="header-font mat_column_delSite" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Forecast'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.forecastquantity}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ActualQuantity">
                        <mat-header-cell class="header-font mat_column_delSite" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Actual'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.actualquantity}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Difference">
                        <mat-header-cell class="header-font mat_column_delSite" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Difference'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.difference}}<span> % </span>
                        </mat-cell>
                    </ng-container>

                    <!-- {{'HOME.Quantity'| translate}} -->
                    <!-- <ng-container matColumnDef="Quantity">
                        <mat-header-cell class="header-font mat_column_depot" *matHeaderCellDef>                            
                            {{'HOME.Quantity'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_depot" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.Quantity}}
                        </mat-cell>
                    </ng-container> -->

                    <!-- {{ProductData.Type}} -->
                    <!-- <ng-container matColumnDef="Type">
                        <mat-header-cell class="header-fonttypeforDepo mat_column_depot" *matHeaderCellDef
                            mat-sort-header>
                            {{'HOME.Type'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-fonttypeforDepo  mat_column_depot" *matCellDef="let ProductData">
                            {{'HOME.' + ProductData.Type | translate}}
                        </mat-cell>
                    </ng-container> -->


                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnforproduct; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnforproduct;"></mat-row>
                </mat-table>



                <!-- <div class="row buttonGroup">
          <button class=" downloadButton" type="submit" (click)="downloadData()"> {{'HOME.Download'| translate}}</button>
      </div> -->

            </div>

            <!-- by product end -->

            <!-- by del site Start -->

            <div class="col-12 mt-2 reportTable horizontal_scrollbar_div_MV" *ngIf="viewBy === 'By delivery site'"
                [hidden]='!displayTable'>

                <mat-table #table [dataSource]="dataSource3" class=" reportTable horizontal_scrollbar_mv" matSort>



                    <ng-container matColumnDef="CustomerName">
                        <mat-header-cell class="header-font mat_column_delSite" *matHeaderCellDef>
                            {{'HOME.Customername'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite column_align_MV"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.CustomerName}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Delivery site">
                        <mat-header-cell class="header-fonttypeforDepo mat_column_delSite" *matHeaderCellDef
                            mat-sort-header>{{'HOME.Deliverysite'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-fonttypeforDepo mat_column_delSite" *matCellDef="let DelSiteData">
                            {{DelSiteData.DeliverySite}}
                        </mat-cell>
                    </ng-container>



                    <ng-container matColumnDef="WeekNumber">
                        <mat-header-cell class="header-font mat_column_delSite column_align_MV" *matHeaderCellDef>
                            {{'HOME.WeekNumber'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite column_align_MV"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.WeekNumber}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ForecastQuantity">
                        <mat-header-cell class="header-font mat_column_delSite" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Forecast'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.forecastquantity}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ActualQuantity">
                        <mat-header-cell class="header-font mat_column_delSite" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Actual'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.actualquantity}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Difference">
                        <mat-header-cell class="header-font mat_column_delSite" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Difference'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font mat_column_delSite" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.difference}}<span> % </span>
                        </mat-cell>
                    </ng-container>


                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnfordelsite; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnfordelsite;"></mat-row>
                </mat-table>
                <!-- <div class="row buttonGroup">
          <button class=" downloadButton" type="submit" (click)="downloadData()"> {{'HOME.Download'| translate}}</button>
      </div> -->

            </div>
            <!-- by del site End-->
            <!-- by product and depot start -->

            <div class="col-12 mt-2 reportTable horizontal_scrollbar_div_MV" *ngIf="viewBy === 'By product and depot'"
                [hidden]='!displayTable'>

                <mat-table #table [dataSource]="dataSource2" class="reportTable  horizontal_scrollbar_mv " matSort>

                    <ng-container matColumnDef="CustomerName">
                        <mat-header-cell class="header-font column_productDepot" *matHeaderCellDef>
                            {{'HOME.Customername'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font column_productDepot column_align_MV"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.CustomerName}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Product name">
                        <mat-header-cell class="header-fonttypeforDepo mat-column_productDepot" *matHeaderCellDef
                            mat-sort-header>
                            {{'HOME.Product'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-fonttypeforDepo mat-column_productDepot" *matCellDef="let ProductData">
                            {{ProductData.ProductName}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Depot name">
                        <mat-header-cell class="header-fonttypeforDepo mat-column_productDepot" *matHeaderCellDef
                            mat-sort-header>
                            {{'HOME.Depot'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-fonttypeforDepo mat-column_productDepot" *matCellDef="let depotData">
                            {{depotData.DepotName}}
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="WeekNumber">
                        <mat-header-cell class="header-font column_productDepot column_align_MV" *matHeaderCellDef>
                            {{'HOME.WeekNumber'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font column_productDepot column_align_MV"
                            *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.WeekNumber}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ForecastQuantity">
                        <mat-header-cell class="header-font column_productDepot" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Forecast'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font column_productDepot" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.forecastquantity}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ActualQuantity">
                        <mat-header-cell class="header-font column_productDepot" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Actual'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font column_productDepot" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.actualquantity}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Difference">
                        <mat-header-cell class="header-font column_productDepot" *matHeaderCellDef>
                            <!-- {{'HOME.Quantity'| translate}} -->
                            {{'HOME.Difference'| translate}}
                        </mat-header-cell>
                        <mat-cell class="header-font column_productDepot" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.difference}}<span> % </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row class="table-header"
                        *matHeaderRowDef="displayedColumnforproductanddepot; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnforproductanddepot;"></mat-row>
                </mat-table>

                <!-- <div class="row buttonGroup">
    <button class=" downloadButton" type="submit" (click)="downloadData()"> {{'HOME.Download'| translate}}</button>
</div> -->

            </div>

            <!-- by product and depot end -->
        </div>
    </div>