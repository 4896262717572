<div class="container-fluid sidepadding-none" style="padding: 0%;">
    <div class="row" style="display: flex; justify-content: center;">
        <div class="mt-3" [hidden]="!this.noDisp" style="font-size: larger;">
            {{'HOME.NoData'| translate}}
        </div>
    </div>
    <div [hidden]="this.noDisp">
        <div class="row ">
            <!-- toggle -->
            <div class="col-lg-6 mb-4 toggle_button_div_MV">
                <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                    #group="matButtonToggleGroup" value="weekly" [(ngModel)]="currentToggleMenu">
                    <mat-button-toggle value="weekly" ngDefaultControl (click)="toggleButtonClick('weekly')">
                        {{'HOME.Weekly'| translate}}
                    </mat-button-toggle>

                    <mat-button-toggle *ngIf="isLoggedInUserAdmin && this.countryCode == 'NOR'" value="monthly" ngDefaultControl
                        (click)="toggleButtonClick('monthly')">
                        {{'HOME.Monthly'| translate}}
                    </mat-button-toggle>

                </mat-button-toggle-group>
            </div>

            <!-- Commented old code, kept for future reference -->
            <!--  download -->
            <!-- <div class="col-lg-6 download_button_div_MV" *ngIf="!this.mobileQuery.matches">
                <div class="downloadHead">
                    <i (click)="downloadDefaulterList()" class="fas fa-download downloadIcon"><span
                            class="downloadText">{{'HOME.Download'| translate}}</span>
                    </i>
                </div>
            </div> -->

            <div class="col-lg-6 download_button_div_MV" *ngIf="!this.mobileQuery.matches">
                <div class="downloadHead">
                    <i (click)="download()" class="fas fa-download downloadIcon">
                        <span class="downloadText">{{'HOME.Download'| translate}}</span>
                    </i>
                </div>
            </div>

        </div>






        <div class="container">
            <!-- _______WEEKLY___FORM -->
            <div class="  row" [hidden]='currentToggleMenu === "monthly"' style="padding-left: 10px">
                <div class="col-auto customer_name_label_div_MV paddingForLabels" *ngIf="this.role !== 'U'">
                    <label for="CustomerId customer_name_label">
                        <span class="monthlyLabel"> {{'HOME.Customername'| translate}}</span>
                    </label>
                </div>
                <div class="col-auto customer_name_dropdown_div_MV noPadding">
                    <div *ngIf="this.role == 'PA'">
                        <select class="forecastSelect form-control" id="CustomerId" [(ngModel)]='customerIdUserSelected'
                            (change)="this.getDeliverySites()">
                            <!-- <option value="">All</option> -->
                            <option class="dropdown_data_border_color " value="">{{'HOME.All'| translate}}</option>
                            <option *ngFor="let customer of Customers" [ngValue]="customer.CustomerID">
                                {{customer.CustomerName}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="this.role == 'A'">
                        <input type="text" class="forecastInput_admin" [value]="customerUserSelected" disabled />
                    </div>
                </div>
                <div class="col-auto del_site_label_div_MV paddingForLabels" >
                    <label class="monthlyLabel customer_name_label del_site_label "> {{'HOME.Deliverysite'| translate}}
                    </label>
                </div>

                <div class="col-auto col-lg-2 col-md-2 del_site_dropdown_div_MV noPadding">
                    <select [(ngModel)]='delSiteUserSelected'
                        class="forecastSelectDeliverySIte del_site_dropdown_MV form-control ">
                        <!-- <option value="">All</option> -->
                        <option value="">{{'HOME.All'| translate}}</option>
                        <option *ngFor="let delSit of delSite" [value]="delSit">{{delSit}}</option>
                    </select>
                </div>

                <div class="col-auto status_label_div_MV paddingForLabels ">
                    <label class="monthlyLabel customer_name_label status_label_MV">{{'HOME.Status'| translate}}</label>
                </div>
                <div *ngIf="role === 'U'" class="col-auto status_input_div_MV noPadding">
                    <select [(ngModel)]='pendingStatusSelected' class="forecastSelect1 status_input_MV  form-control ">
                        <option *ngFor="let pendingStatus of dropDownStatus" [value]="pendingStatus">
                            {{'HOME.' + pendingStatus| translate}}
                        </option>
                    </select>
                </div>
                <div *ngIf="role !== 'U'" class="col-auto status_input_div_MV noPadding">
                    <select [(ngModel)]='pendingStatusSelected' class="forecastSelect1 status_input_MV  form-control ">
                        <option *ngFor="let pendingStatus of dropDownStatusForServicePayload" [value]="pendingStatus">
                            {{'HOME.' + pendingStatus| translate}}
                        </option>
                    </select>
                </div>

                <div class="col-auto year_label_MV paddingForLabels">
                    <label for="year ">
                        <span class="input-text-header monthlyLabel"> {{'HOME.Year'| translate}}</span>
                    </label>
                </div>
                <div class="col-auto year_input_div_MV noPadding">
                    <form [formGroup]="forecastStatusForm ">
                        <select formControlName="year" class="form-control input-text forecastSelectYearWeekly "
                            [ngClass]="{ 'is-invalid': submitted && fields.year.errors }" (change)="changeOnClick()" id="year"
                            [value]="this.yearUserSelected">
                            <option *ngFor="let year of dropDownYear" [value]="year">{{year}}</option>
                            <div *ngIf="submitted && fields.year.errors" class="invalid-feedback">
                                <div *ngIf="fields.year.errors"> {{'HOME.Enteryear'| translate}}
                                </div>
                            </div>
                        </select>
                    </form>
                </div>

                <div class="col-auto week_label_div_MV  paddingForLabels">
                    <label class="monthlyLabel customer_name_label week_label_MV"> {{'HOME.Week'| translate}}</label>
                </div>
                <div class="col-auto col-lg-1 col-md-1 week_input_div_MV noPadding">
                    <form [formGroup]="forecastStatusForm">
                        <input formControlName="week" type="number"
                            class="forecastInput form-control forecastInputWeek week_input_MV" min={{minWeek}}
                            max={{maxWeek}}
                            [ngClass]="{ 'is-invalid': submitted && fields.week.errors && (fields.week.errors.min || fields.week.errors.max || fields.week.errors.pattern)}"
                            (change)="changeOnClick()">
                        <div *ngIf="submitted && fields.week.errors && (fields.week.errors.min || fields.week.errors.max || fields.week.errors.pattern)"
                            class="invalid-feedback">

                            <!-- Commented old code, kept for future reference -->
                            <!-- <div *ngIf="fields.week.errors.min || fields.week.errors.max">
                                {{'HOME.Enterweekfrom'| translate}} {{minWeek}} {{'HOME.to'| translate}} {{maxWeek}}
                            </div> -->

                            <div *ngIf="fields.week.errors.min || fields.week.errors.max">
                                {{'HOME.Enterweekfromto'| translate: { minWeek: minWeek, maxWeek: maxWeek } }}
                            </div>

                            <div *ngIf="fields.week.errors.pattern">
                                <div>{{'HOME.NoDecimalNumber'| translate}}</div>
                            </div>
                        </div>
                    </form>
                   
                </div>
                <button class="goButton find_button_MV_weekly" type="submit"
                (click)="this.fetchOnClick()">{{'HOME.Find'| translate}}
            </button>
                <!-- <div class="col-auto find_button_div find_button_div_MV" style="padding-top: 0px; float: right;"> -->
                    <!-- <div class="row" style="float: right">
                        <button class="goButton find_button_MV" type="submit"
                            (click)="this.fetchOnClick()">{{'HOME.Find'| translate}}
                        </button>
                    </div> -->
                    <!-- <div class="col-auto" style="padding-top: 0px;">
                    <div class="row" style="float: right">
                        <button class="goButton" type="submit"
                            (click)="delSiteFilter(dataSource.data, delSiteUserSelected)">{{'HOME.Find'| translate}}</button>
                    </div>
                </div> -->
                <!-- </div> -->
            </div>
            <!-- _______MONTHLY___FORM -->
            <div class=" row" [hidden]='currentToggleMenu === "weekly"' style="padding-left: 10px">
                <div class="col-auto customer_name_label_div_MV paddingForLabels" *ngIf="this.role !== 'U'">
                    <label for="CustomerId">
                        <span class="monthlyLabel"> {{'HOME.Customername'| translate}}</span>
                    </label>
                </div>
                <div class="col-auto customer_name_dropdown_div_MV noPadding">
                    <div *ngIf="this.role == 'PA'">
                        <select class="forecastSelect form-control" id="CustomerId" [(ngModel)]='customerIdUserSelected'
                            (change)="this.getDeliverySites()">
                            <option value="">{{'HOME.All'| translate}}</option>
                            <option *ngFor="let customer of Customers" [ngValue]="customer.CustomerID">
                                {{customer.CustomerName}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="this.role == 'A'">
                        <input type="text" class="forecastInput" [value]="customerUserSelected" disabled />
                    </div>
                </div>

                <div class="col-auto del_site_label_div_MV paddingForLabels" >
                    <label class="monthlyLabel"> {{'HOME.Deliverysite'| translate}}
                    </label>
                </div>
                <div class="col-auto col-lg-2 col-md-2 del_site_dropdown_div_monthly_MV noPadding">
                    <select [(ngModel)]='delSiteUserSelected'
                        class="forecastSelectDeliverySIte del_site_dropdown_MV form-control ">
                        <option value="">{{'HOME.All'| translate}}</option>
                        <option *ngFor="let delSit of delSite" [value]="delSit">{{delSit}}</option>
                    </select>
                </div>

                <!-- <div class="col-auto status_label_div_MV status_label_div  ">
                    <label class="monthlyLabel customer_name_label status_label_MV">{{'HOME.Status'| translate}}</label>
                </div>
                <div class="col-auto status_input_div_MV status_input_div_monthly" style="padding: 0px;">
                    <select [(ngModel)]='pendingStatusSelected' class="forecastSelect1 status_input_MV  form-control ">
                        <option *ngFor="let pendingStatus of dropDownStatus" [value]="pendingStatus">
                            {{'HOME.' + pendingStatus| translate}}
                        </option>
                    </select>
                </div> -->

                <!-- <div class="col-auto Year_label_div Year_label_div_MV" style="">
                    <label for="year customer_name_label_div">
                        <span class="input-text-header monthlyLabel"> {{'HOME.Year'| translate}}</span>
                    </label>
                </div>
                <div class="col-auto col-lg-1 col-md-1" style="padding: 0px;">
                    <form [formGroup]="forecastStatusForm">
                        <select formControlName="year" class="form-control input-text forecastSelectYear "
                            [ngClass]="{ 'is-invalid': submitted && fields.Year.errors }" id="Year">
                            <option value=""> {{'HOME.SelectYear'| translate}}</option>
                            <option value="Year"> 2020 </option>
                        </select>
                        <div *ngIf="submitted && fields.Year.errors" class="invalid-feedback">
                            <div *ngIf="fields.Year.errors.required"> {{'HOME.Yearisreq'| translate}}
                            </div>
                        </div>
                    </form>
                </div> -->

                <div class="col-auto paddingForLabels paddingForLabels_MV  " >
                    <label for="year ">
                        <span class="input-text-header monthlyLabel"> {{'HOME.Year'| translate}}</span>
                    </label>
                </div>
                <div class="col-auto col-lg-1 col-md-1 noPadding">
                    <form [formGroup]="forecastStatusForm ">
                        <select formControlName="year" class="form-control input-text forecastSelectYear "
                            [ngClass]="{ 'is-invalid': submitted && fields.year.errors }" (change)="changeOnClick()"
                            id="year" [value]="this.yearUserSelected">
                            <option *ngFor="let year of dropDownYear" [value]="year">{{year}}</option>
                            <div *ngIf="submitted && fields.year.errors" class="invalid-feedback">
                                <div *ngIf="fields.year.errors"> {{'HOME.Enteryear'| translate}}
                                </div>
                            </div>
                        </select>

                    </form>
                </div>

                <!-- </div> -->

                <div class="col-auto find_button_div_monthly find_button_div_MV"
                    style="padding-top: 0px; float: right;">
                    <div class="row" style="float: right">
                        <button class="goButton find_button_MV" type="submit"
                            (click)="this.getForecastStatusMonthly()">{{'HOME.Find'| translate}}</button>
                    </div>
                </div>
            </div>
            
            <div class="global-search-text-box">
                <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
            </div>

            <!-- _______WEEKLY___TABLE -->
            <div class=" " [hidden]='currentToggleMenu !== "weekly"'>
                <div class=" col-lg-12 mt-10" style="">
                    <div class="row" style="display: flex; justify-content: center;">
                        <div class="mt-5" [hidden]="displayTable"
                            style="display: flex; justify-content: center; font-size: larger;">
                            {{'HOME.Norecordsfound'| translate}}
                        </div>
                        <div class="col-lg-12 mt-2" [hidden]='!displayTable' style="padding: 0px">
                            <!--Angular 7 Impl-->
                            <mat-table table #WeeklyTableSort="matSort" [dataSource]="dataSource"
                                class="forecastTableweekly  horizontal_scrollbar_mv " matSort>
                            

                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef [ngClass]="' mat-column-large '">
                                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                                            [aria-label]="checkboxLabel()">
                                        </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let row" [ngClass]="' mat-column-large '">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="$event ? selection.toggle(row) : null"
                                            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel()">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <!-- Item Column -->
                                <ng-container matColumnDef="CustomerName">
                                    <mat-header-cell class="header-font mat_column_customerName_monthly"
                                        *matHeaderCellDef mat-sort-header>
                                        {{'HOME.Customername'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class=" mat_column_customerName_monthly"
                                        *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.CustomerName}}
                                    </mat-cell>
                                </ng-container>

                                <!-- Delivery Site Column -->
                                <ng-container matColumnDef="DeliverySite">
                                    <mat-header-cell class="header-font  mat_column_Deliverysite" *matHeaderCellDef
                                        mat-sort-header>
                                        {{'HOME.Deliverysite'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Deliverysite" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.DeliverySite}}
                                    </mat-cell>
                                </ng-container>

                                <!-- Customer Contact Column -->
                                <ng-container matColumnDef="SubscriberEmail">
                                    <mat-header-cell class="header-font  mat_column_email" *matHeaderCellDef
                                        mat-sort-header>
                                        {{'HOME.Customercontact'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_email" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.SubscriberEmail}}</mat-cell>
                                </ng-container>

                                <!-- Week Column -->
                                <ng-container matColumnDef="Week">
                                    <mat-header-cell class="header-font mat_column_week" *matHeaderCellDef
                                        mat-sort-header>
                                        {{'HOME.Week'| translate}} </mat-header-cell>
                                    <mat-cell class=" mat_column_week" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.Week}} </mat-cell>
                                </ng-container>

                                <!-- Status Column -->
                                <ng-container matColumnDef="Status">
                                    <mat-header-cell class="header-font mat_column_status" *matHeaderCellDef
                                        mat-sort-header>
                                        {{'HOME.Status'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_status" *matCellDef="let fetchedDataForItemArray">
                                        {{'HOME.' + fetchedDataForItemArray.Status| translate}}
                                    </mat-cell>
                                    <!-- <mat-cell class="mat-column-small" *matCellDef="let fetchedDataForItemArray">
                                    {{fetchedDataForItemArray.Status}}
                                </mat-cell> -->
                                </ng-container>

                                <!-- Send Reminder Column Pending-->
                                <ng-container matColumnDef="SendReminder">
                                    <mat-header-cell [hidden]="this.role == 'U'" class="header-font mat_column_reminder"
                                        *matHeaderCellDef>
                                        {{'HOME.Sendreminder'| translate}}
                                    </mat-header-cell>
                                    <mat-cell [hidden]="this.role == 'U'" class="mat_column_reminder"
                                        *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Status == 'Completed'">
                                            <i class="far fa-envelope" aria-hidden="true" style="color:grey"></i>
                                        </span>
                                        <span (click)="sendReminder(fetchedDataForItemArray)"
                                            *ngIf="fetchedDataForItemArray.Status == 'Pending'"
                                            class="send-reminder-icon">
                                            <i class="far fa-envelope" aria-hidden="true"></i>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <!-- Enter Forecast Column Pending -->
                                <ng-container matColumnDef="EnterForecast">
                                    <mat-header-cell class="header-font mat_column_reminder" *matHeaderCellDef>
                                        {{'HOME.Enterforecast'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_reminder" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Status == 'Completed'"
                                            class="mat-table-actions-padding">
                                            <i class="far fa-edit" aria-hidden="true" style="color:grey"></i>
                                        </span>
                                        <span routerLink="../../order-management/forecast-entry"
                                            *ngIf="fetchedDataForItemArray.Status == 'Pending'"
                                            (click)="setData(fetchedDataForItemArray)"
                                            class="mat-table-actions-padding">
                                            <i class="far fa-edit" aria-hidden="true"></i>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            </mat-table>
                            <!-- <div class="row buttonGroup">
                            <button class=" forecastButton" type="submit"
                                (click)="downloadDefaulterList()">{{'HOME.Download'| translate}}</button>
                        </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class=" _______MONTHLY___TABLE" [hidden]='currentToggleMenu === "weekly"'> -->
            <div class=" " [hidden]='currentToggleMenu !== "monthly"'>
                <div class=" col-lg-12 mt-10" style="">
                    <div class="row" style="display: flex; justify-content: center;">
                        <div class="mt-5" [hidden]="displayTable"
                            style="display: flex; justify-content: center; font-size: larger;">
                            {{'HOME.Norecordsfound'| translate}}
                        </div>
                        <div class="col-lg-12 mt-3 horizontal_scrollbar_monthly_div" [hidden]='!displayTable'
                            style="padding: 0px">
                            <mat-table table #MontlyTableSort="matSort" [dataSource]="dataSourceMontly"
                            class="forecastTable horizontal_scrollbar_monthly horizontal_scrollbar_mv" matSort>
                        
                                <ng-container matColumnDef="CustomerName">
                                    <mat-header-cell
                                        class="header-font mat-column-large mat_column_customerName_monthly"
                                        *matHeaderCellDef mat-sort-header>
                                        {{'HOME.Customername'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large mat_column_customerName_monthly"
                                        *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.CustomerName}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="DeliverySite">
                                    <mat-header-cell
                                        class="header-font mat-column-large1 mat_column_DeliverySite_monthly"
                                        *matHeaderCellDef mat-sort-header
                                        [ngClass]="{'hide-table-column': (countryCode == 'FIN')}">
                                        {{'HOME.Deliverysite'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large1 mat_column_DeliverySite_monthly"
                                        *matCellDef="let fetchedDataForItemArray"
                                        [ngClass]="{'hide-table-column': (countryCode == 'FIN')}">
                                        {{fetchedDataForItemArray.DeliverySite}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="SubscriberEmail">
                                    <mat-header-cell
                                        class="header-font mat-column-large2 mat_column_Subscriber_Email_monthly"
                                        *matHeaderCellDef mat-sort-header
                                        [ngClass]="{'hide-table-column': (countryCode == 'FIN')}">
                                        {{'HOME.Customercontact'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat-column-large2 mat_column_Subscriber_Email_monthly"
                                        *matCellDef="let fetchedDataForItemArray"
                                        [ngClass]="{'hide-table-column': (countryCode == 'FIN')}">
                                        {{fetchedDataForItemArray.SubscriberEmail}}
                                    </mat-cell>
                                </ng-container>
                                <!-- <i class="fa fa-hourglass-half" aria-hidden="true"></i>
                                <i class="fas fa-hourglass-half"></i> -->
                                <!-- <ng-container matColumnDef="Jan">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Jan'| translate}}
                                    </mat-header-cell>
 
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        {{fetchedDataForItemArray.Jan}}
                                    </mat-cell>
                                </ng-container> -->

                                <!-- <ng-container matColumnDef="Jan">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Jan'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let element">
                                        <span (click)='status_click(element)' title="Completed">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                    </mat-cell>
                                </ng-container> -->

                                <ng-container matColumnDef="Jan">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Jan'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Jan == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Jan')"
                                            *ngIf=" fetchedDataForItemArray.Jan == 'Pending' " title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design "></i>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <!-- <ng-container matColumnDef="SendReminder">
                                    <mat-header-cell [hidden]="this.role == 'U'" class="header-font mat-column-small" *matHeaderCellDef>
                                        {{'HOME.Sendreminder'| translate}}
                                    </mat-header-cell>
                                    <mat-cell [hidden]="this.role == 'U'" class="mat-column-small" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Status == 'Completed'">
                                            <i class="far fa-envelope" aria-hidden="true" style="color:grey"></i>
                                        </span>
                                        <span (click)="sendReminder(fetchedDataForItemArray)" 
                                            *ngIf="fetchedDataForItemArray.Status == 'Pending'" class="send-reminder-icon">
                                            <i class="far fa-envelope" aria-hidden="true"></i>
                                        </span>
                                    </mat-cell>
                                </ng-container> -->

                                <ng-container matColumnDef="Feb">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Feb'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Feb == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Feb')"
                                            *ngIf="fetchedDataForItemArray.Feb == 'Pending'" title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Mar">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Mar'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Mar == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Mar')"
                                            *ngIf="fetchedDataForItemArray.Mar == 'Pending'" title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Apr">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Apr'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Apr == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Apr')"
                                            *ngIf="fetchedDataForItemArray.Apr == 'Pending'" title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="May">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.May'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.May == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'May')"
                                            *ngIf="fetchedDataForItemArray.May == 'Pending'" title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Jun">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Jun'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Jun == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Jun')"
                                            *ngIf="fetchedDataForItemArray.Jun == 'Pending'" title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="Jul">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Jul'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Jul == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Jul')"
                                            *ngIf="fetchedDataForItemArray.Jul == 'Pending'" title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>

                                </ng-container>
                                <ng-container matColumnDef="Aug">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Aug'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Aug == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Aug')"
                                            *ngIf="fetchedDataForItemArray.Aug == 'Pending'" title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Sep">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Sep'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Sep == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Sep')"
                                            *ngIf="fetchedDataForItemArray.Sep == 'Pending'" title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>

                                </ng-container>
                                <ng-container matColumnDef="Oct">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Oct'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Oct == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Oct')"
                                            *ngIf="fetchedDataForItemArray.Oct == 'Pending'" title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>

                                </ng-container>
                                <ng-container matColumnDef="Nov">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Nov'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Nov == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Nov')"
                                            *ngIf="fetchedDataForItemArray.Nov == 'Pending'" title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>

                                </ng-container>
                                <ng-container matColumnDef="Dec">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef>
                                        {{'HOME.Dec'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray">
                                        <span *ngIf="fetchedDataForItemArray.Dec == 'Completed'" title="{{'HOME.Completed'| translate}}">
                                            <i class="fas fa-check tick_design"></i>
                                        </span>
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Dec')"
                                            *ngIf="fetchedDataForItemArray.Dec == 'Pending'" title="{{'HOME.Pending'| translate}}">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>

                                </ng-container>
                                <!-- <ng-container matColumnDef="Total">
                                    <mat-header-cell class="header-font mat_column_Jan_monthly" *matHeaderCellDef
                                    [ngClass]="{'hide-table-column': (countryCode !== 'FIN')}">
                                        {{'HOME.Total'| translate}}
                                    </mat-header-cell>
                                    <mat-cell class="mat_column_Jan_monthly" *matCellDef="let fetchedDataForItemArray"
                                    [ngClass]="{'hide-table-column': (countryCode !== 'FIN')}">
                                        <span (click)="sendReminderMonthly(fetchedDataForItemArray, 'Total')"
                                            *ngIf="fetchedDataForItemArray.Dec == 'Pending'" title=" Pending">
                                            <i class="fas fa-hourglass-half hourglass_design"></i>
                                        </span>
                                    </mat-cell>
                                </ng-container> -->

                                <mat-header-row class="table-header"
                                    *matHeaderRowDef="displayedColumnsForMonthlyTable; sticky:true">
                                </mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumnsForMonthlyTable;">
                                </mat-row>
                            </mat-table>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>

</div>