<div class="container-fluid">
    <div class="row no-gutters">
        <div class="col-lg-1 customer-name-label-width">
            <label for="customerName">
                <span class="customer-name-label">{{'HOME.Customername'| translate}}
                </span>
            </label>
        </div>
        <div class="col-lg-4">
            <div class="customer_name_dropdown_div customer_name_dropdown_div_mv ">
                <select class="customer-name-dropdown customer_name_dropdown_width" id="customer-name"
                    [(ngModel)]='customerIdUserSelected'>
                    <option value="">{{'HOME.All'| translate}}</option>
                    <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                        {{customer.CustomerName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-lg-1 findButton_div_mv">
            <button class="findButton" (click)="getLockedOrders()">{{'HOME.Find'| translate}}</button>
        </div>
    </div>

    <div class="row no-gutters">
        <div class="col-lg-12">
            <div class="global-search-text-box">
                <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
            </div>
        </div>
    </div>

    <div class="row no-gutters pt-2">
        <div class="col-lg-12 locked-orders-table">
            <div *ngIf="!displayLockedOrdersTable && isFindButtonClicked">
                <div class="no-records-message pt-3">
                    <span>{{'HOME.Norecordsfound'| translate}}</span>
                </div>
            </div>
            <div class="locked-order-table-width" [hidden]='!displayLockedOrdersTable'>
                <mat-table #table [dataSource]="dataSource" #lockedOrdersTableSort="matSort" matSort>
                    <ng-container matColumnDef="Reference_ID">
                        <mat-header-cell class="column_medium4" *matHeaderCellDef mat-sort-header>
                            Reference#
                        </mat-header-cell>
                        <mat-cell class="column_medium4" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.Reference_ID }}
                        </mat-cell>
                    </ng-container>                    
                    <ng-container matColumnDef="DeliverySite">
                        <mat-header-cell class="column_medium2" *matHeaderCellDef mat-sort-header>
                            Delivery site
                        </mat-header-cell>
                        <mat-cell class="column_medium2" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.DeliverySite }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="LockedByUser">
                        <mat-header-cell class="column_large" *matHeaderCellDef mat-sort-header>
                            Locked by user
                        </mat-header-cell>
                        <!-- <mat-cell class="column_large" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.LockedByUser }}
                        </mat-cell> -->

                        <mat-cell class="column_large" *matCellDef="let fetchedDataForItemArray">
                            <div>
                                <div class="firstline">{{ fetchedDataForItemArray.UserFullName }}</div>
                                <div class="secondline">{{ fetchedDataForItemArray.LockedByUser }}</div>
                            </div>
                        </mat-cell>

                    </ng-container>
                    <ng-container matColumnDef="Lock_Time">
                        <mat-header-cell class="column_medium" *matHeaderCellDef mat-sort-header>
                            Locked since time
                        </mat-header-cell>
                        <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.Lock_Time }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="DeliverySite_Primary_Contact_Number">
                        <mat-header-cell class="column_medium3" *matHeaderCellDef>
                            Delivery site contact
                        </mat-header-cell>
                        <mat-cell class="column_medium3" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.DeliverySite_Primary_Contact_Number }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Primary_Contact_Number">
                        <mat-header-cell class="column_medium5" *matHeaderCellDef>
                            User contact
                        </mat-header-cell>
                        <mat-cell class="column_medium5" *matCellDef="let fetchedDataForItemArray">
                            {{ fetchedDataForItemArray.Primary_Contact_Number }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Action">
                        <mat-header-cell class="column_small" *matHeaderCellDef>
                        </mat-header-cell>
                        <mat-cell class="column_small" *matCellDef="let fetchedDataForItemArray">
                            <span (click)='showConfirmationPopUpToUnlockOrder(fetchedDataForItemArray)' title="Unlock order"
                                class="mat-table-actions-padding">
                                <span class='unlock-icon'>
                                    <i class="fas fa-unlock" aria-hidden="true"></i>
                                </span>
                            </span>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsLockedOrders; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsLockedOrders;">
                    </mat-row>
                </mat-table>
            </div>
        </div>
    </div>
</div>