import { MediaMatcher } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../shared-modules/services/shared.service';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { ManageUsersService } from '../user-management/manage-users/manage-users.service';
import { AddProductPriceService } from './add-product-price.service';

@Component({
  selector: 'app-add-product-price',
  templateUrl: './add-product-price.component.html',
  styleUrls: ['./add-product-price.component.css']
})
export class AddProductPriceComponent implements OnInit {

  priceValue: string = "product-price";
  yearList: number[] = [];
  yearUserSelected: number;
  monthUserSelected: string;
  customerIdUserSelected: string;
  customerNameUserSelected: string;
  Customers = [];
  userInfo: any;
  countryCode: string;
  productPrices: any[];
  isRequestCompleted: boolean;
  public dataSourceForPricesTable = new MatTableDataSource();
  @ViewChild('pricesTableSort', { static: true }) public pricesTableSort: MatSort;
  public displayedColumnsForPricesTable = ['depotName', 'productName', 'price', 'uom', 'selection'];
  public dataSourceForAdhesivePricesTable = new MatTableDataSource();
  @ViewChild('adhesivePricesTableSort', { static: true }) public adhesivePricesTableSort: MatSort;
  public displayedColumnsForAdhesivePricesTable = ['depotName', 'productName', 'price', 'adhesivePercent', 'uom', 'selection'];
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  loggedInUserName: string;
  isAllProductsSelected: boolean;
  isAllAdhesiveProductsSelected: boolean;
  adhesiveProductCollection: any[] = [];
  selectedLanguage: string;

  constructor(private manageUsersService: ManageUsersService,
    private sharedService: AppService,
    private addProductPriceService: AddProductPriceService,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private dialog: MatDialog,
    private media: MediaMatcher,
    private datePipe: DatePipe) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.countryCode = (this.userInfo ? (this.userInfo.CountryCode) : '');
    this.customerIdUserSelected = (this.userInfo ? this.userInfo.CustomerID : '');
    this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cdRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.translate.get(['HOME.selectedLanguageForDatePicker'])
      .subscribe(data => {
        this.selectedLanguage = data['HOME.selectedLanguageForDatePicker'];
      });
  }

  ngOnInit() {
    // Populate year dropdown
    let currentYear: number = this.getCurrentYear();
    this.getYearList(currentYear);

    this.yearUserSelected = currentYear;
    this.monthUserSelected = this.getCurrentMonth();

    // get customers names
    this.getCustomers();
  }

  ngAfterViewInit(): void {
    this.dataSourceForPricesTable.sort = this.pricesTableSort;
    this.cdRef.detectChanges();
    this.dataSourceForPricesTable.sortingDataAccessor = (item, property) => {
      return item[property]
    };
    this.dataSourceForAdhesivePricesTable.sort = this.adhesivePricesTableSort;
    this.cdRef.detectChanges();
    this.dataSourceForAdhesivePricesTable.sortingDataAccessor = (item, property) => {
      return item[property]
    };
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getYearList(currentYear: number) {
    this.yearList = [];
    this.yearList.push(currentYear - 1, currentYear);
  }

  getCurrentYear(): number {
    const date = this.sharedService.getCurrentDateTime();
    return date.getUTCFullYear();
  }

  getCurrentMonth(): string {
    const date = this.sharedService.getCurrentDateTime();
    let monthCode: string;
    monthCode = ((date.getMonth() + 1) < 10) ? ('0' + (date.getMonth() + 1).toString()) : (date.getMonth() + 1).toString();
    return monthCode;
  }

  getCustomers() {
    this.Customers = [];
    //get customer names
    this.manageUsersService.getCustomerList(this.countryCode)
      .subscribe(
        res => {
          if (res["ResponseCode"] == 200) {
            this.Customers = res["Customers"];
          }
        });
  }

  toggleButtonClick() {
    this.dataSourceForPricesTable.data = [];
    this.dataSourceForAdhesivePricesTable.data = [];
    this.adhesiveProductCollection = [];
  }

  findProductPrices() {
    this.dataSourceForPricesTable.data = [];
    this.isRequestCompleted = false;
    this.addProductPriceService.fetchJDEPricesForUser(this.monthUserSelected, this.yearUserSelected.toString(), this.customerIdUserSelected, this.countryCode)
      .subscribe(
        response => {
          if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            const customerCollection = 'CustomerCollection';
            const priceCollection = 'PriceCollection';
            let productPriceList = [];
            if (response[customerCollection] && response[customerCollection].length) {
              for (let customerObject of response[customerCollection]) {
                this.customerNameUserSelected = customerObject['customerName'];
                if (customerObject[priceCollection] && customerObject[priceCollection].length) {
                  for (let priceObject of customerObject[priceCollection]) {
                    productPriceList.push(priceObject);
                  }
                }
              }
              this.dataSourceForPricesTable.data = productPriceList;
              this.isRequestCompleted = true;
            }
            this.isRequestCompleted = true;
          }
          this.isRequestCompleted = true;
        });
  }

  findAdhesiveProductPrices() {
    this.dataSourceForAdhesivePricesTable.data = [];
    this.adhesiveProductCollection = [];
    this.isRequestCompleted = false;
    this.addProductPriceService.fetchJDEAdhesivePricesForUser(this.monthUserSelected, this.yearUserSelected.toString(), this.customerIdUserSelected, this.countryCode)
      .subscribe(
        response => {
          if (response && response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
            const customerCollection = 'CustomerCollection';
            const collection = 'Collection';
            const adhesivePriceCollection = 'AdhesivePriceCollection';
            let adhesiveProductPriceList = [];
            if (response[customerCollection] && response[customerCollection].length) {
              for (let customerObject of response[customerCollection]) {
                this.customerNameUserSelected = customerObject['customerName'];
                if (customerObject[collection] && customerObject[collection].length) {
                  for (let priceObject of customerObject[collection]) {
                    let depotName = priceObject['depotName'];
                    let productId = priceObject['productId'];
                    let productName = priceObject['productName'];
                    let effectiveDate = priceObject['effectiveDate'];

                    // adhesiveProductCollection will be needed for saving prices
                    let adhesiveProductObject = {};
                    adhesiveProductObject['productId'] = productId;
                    adhesiveProductObject['productName'] = productName;
                    adhesiveProductObject['effectiveDate'] = effectiveDate;
                    this.adhesiveProductCollection.push(adhesiveProductObject);

                    if (priceObject[adhesivePriceCollection] && priceObject[adhesivePriceCollection].length) {
                      for (let adhesivePriceObject of priceObject[adhesivePriceCollection]) {
                        let priceObjectToDisplay = {};
                        priceObjectToDisplay['depotName'] = depotName;
                        priceObjectToDisplay['productName'] = productName;
                        priceObjectToDisplay['productId'] = productId;
                        priceObjectToDisplay['effectiveDate'] = effectiveDate;
                        priceObjectToDisplay['price'] = adhesivePriceObject['price'];
                        priceObjectToDisplay['adhesivePercent'] = adhesivePriceObject['adhesivePercent'].toString();
                        priceObjectToDisplay['currency'] = adhesivePriceObject['currency'];
                        priceObjectToDisplay['uom'] = adhesivePriceObject['uom'];
                        adhesiveProductPriceList.push(priceObjectToDisplay);
                      }
                    }
                  }
                }
              }
              this.dataSourceForAdhesivePricesTable.data = adhesiveProductPriceList;
              this.isRequestCompleted = true;
            }
            this.isRequestCompleted = true;
          }
          this.isRequestCompleted = true;
        });
  }

  savePriceData() {
    let pricesToSave = [];
    for (let priceObject of this.dataSourceForPricesTable.data) {
      if (priceObject['isSelected']) {
        pricesToSave.push(priceObject);
      }
    }
    if (pricesToSave && pricesToSave.length) {
      let priceJSONData = {}, customerObject = {}, customerCollection = [], priceCollection = [];
      for (let priceObject of pricesToSave) {
        if (priceObject['isSelected']) {
          let priceDataToSave = {};
          priceDataToSave['productId'] = priceObject['productId'];
          priceDataToSave['productName'] = priceObject['productName'];
          priceDataToSave['depotId'] = priceObject['depotId'];
          priceDataToSave['depotName'] = priceObject['depotName'];
          priceDataToSave['effectiveDate'] = this.datePipe.transform(priceObject['effectiveDate'], "yyyy-MM-dd'T'HH:mm:ss");
          priceDataToSave['countryCode'] = priceObject['countryCode'];
          priceDataToSave['createdBy'] = priceObject['createdBy'];
          priceDataToSave['updatedBy'] = this.loggedInUserName;
          priceDataToSave['createdOn'] = priceObject['effectiveDate'];
          priceDataToSave['updatedOn'] = this.sharedService.getCurrentDateTime().toISOString();
          priceDataToSave['price'] = priceObject['price'];
          priceDataToSave['currency'] = priceObject['currency'];
          priceDataToSave['uom'] = priceObject['uom'];
          priceDataToSave['month'] = priceObject['month'];
          priceCollection.push(priceDataToSave);
        }
      }
      customerObject['customerId'] = this.customerIdUserSelected;
      customerObject['customerName'] = this.customerNameUserSelected;
      customerObject['PriceCollection'] = priceCollection;
      customerCollection.push(customerObject);
      priceJSONData['CustomerCollection'] = customerCollection;
      this.addProductPriceService.updateJDEPricesForUser(JSON.stringify(priceJSONData))
        .subscribe(
          response => {
            if (response) {
              if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
                this.resetAllSelectedProducts();
                this.showStatusMessage('Prices Details Saved Successfully', '', true, 'price-save-success');
              } else {
                this.showStatusMessage('Failed to Save Prices Details', '', false, 'price-save-fail');
              }
            } else {
              this.showStatusMessage('Failed to Save Prices Details', '', false, 'price-save-fail');
            }
          });

    } else {
      this.showStatusMessage('Select atleast 1 price to save', '', false, 'select-price-to-save');
    }
  }

  saveAdhesivePriceData() {
    let productCollectionToSave = [];
    for (let adhesiveProduct of this.adhesiveProductCollection) {
      let selectedAdhesivePrices = this.getSelectedAdhesivePricesByProductId(adhesiveProduct['productId']);
      if (selectedAdhesivePrices && selectedAdhesivePrices.length) {
        adhesiveProduct['AdhesivePriceCollection'] = selectedAdhesivePrices;
        productCollectionToSave.push(adhesiveProduct);
      }
    }
    if (productCollectionToSave && productCollectionToSave.length) {
      let customerObject = {}, customerCollection = [], adhesivePriceJSONData = {};
      customerObject['customerId'] = this.customerIdUserSelected;
      customerObject['customerName'] = this.customerNameUserSelected;
      customerObject['ProductCollection'] = productCollectionToSave;
      customerObject['CreatedBy'] = this.loggedInUserName;
      customerObject['UpdatedBy'] = this.loggedInUserName;
      customerObject['CreatedOn'] = this.sharedService.getCurrentDateTime().toISOString();
      customerObject['UpdatedOn'] = this.sharedService.getCurrentDateTime().toISOString();
      customerCollection.push(customerObject);
      adhesivePriceJSONData['CountryCode'] = this.countryCode;
      adhesivePriceJSONData['CustomerCollection'] = customerCollection;
      this.addProductPriceService.updateAdhesivePricesForUser(JSON.stringify(adhesivePriceJSONData))
        .subscribe(
          response => {
            if (response) {
              if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
                this.resetAllSelectedProducts();
                this.showStatusMessage('Prices Details Saved Successfully', '', true, 'price-save-success');
              } else {
                this.showStatusMessage('Failed to Save Prices Details', '', false, 'price-save-fail');
              }
            } else {
              this.showStatusMessage('Failed to Save Prices Details', '', false, 'price-save-fail');
            }
          });

    } else {
      this.showStatusMessage('Select atleast 1 price to save', '', false, 'select-price-to-save');
    }
  }

  resetPriceData() {
    for (let priceObject of this.dataSourceForPricesTable.data) {
      priceObject['isSelected'] = false;
    }
  }

  resetAdhesivePriceData() {
    for (let priceObject of this.dataSourceForPricesTable.data) {
      priceObject['isSelected'] = false;
    }
  }

  showStatusMessage(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber
      },
      height: '37%',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  selectAllProductsToSave() {
    for (let priceObject of this.dataSourceForPricesTable.data) {
      priceObject['isSelected'] = !this.isAllProductsSelected;
    }
  }

  resetAllSelectedProducts() {
    this.isAllProductsSelected = false;
    for (let priceObject of this.dataSourceForPricesTable.data) {
      priceObject['isSelected'] = false;
    }
  }

  selectAllAdhesiveProductsToSave() {
    for (let priceObject of this.dataSourceForAdhesivePricesTable.data) {
      priceObject['isSelected'] = !this.isAllAdhesiveProductsSelected;
    }
  }

  resetAllSelectedAdhesiveProducts() {
    this.isAllProductsSelected = false;
    for (let priceObject of this.dataSourceForAdhesivePricesTable.data) {
      priceObject['isSelected'] = false;
    }
  }

  getSelectedAdhesivePricesByProductId(productId: string): any[] {
    let adhesivePriceCollection = [];
    for (let priceObject of this.dataSourceForAdhesivePricesTable.data) {
      if (productId === priceObject['productId'] && priceObject['isSelected']) {
        let adhesivePriceObject = {};
        adhesivePriceObject['price'] = priceObject['price'];
        adhesivePriceObject['adhesivePercent'] = priceObject['adhesivePercent'];
        adhesivePriceObject['currency'] = priceObject['currency'];
        adhesivePriceObject['uom'] = priceObject['uom'];
        adhesivePriceCollection.push(adhesivePriceObject);
      }
    }
    return adhesivePriceCollection;
  }

}
