
import { Component, OnInit , ViewChild} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { ForecastActual } from '../reports';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { CommonService } from 'src/app/order-management/order-management/order-management.component.service';
// import { ForecastActualReportService } from './forecastactual.component.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import * as moment from 'moment'
import { Customer } from 'src/app/user-management/customer';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-forecastactualmonthlyfinland',
  templateUrl: './forecastactualmonthlyfinland.component.html',
  styleUrls: ['./forecastactualmonthlyfinland.component.css']
})
export class ForecastactualmonthlyfinlandComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
