<div>
<app-loading></app-loading>
<ng-container>
        <!-- <app-header *ngIf="showHead"></app-header> -->
</ng-container>
<div >
        
<router-outlet></router-outlet>

</div>
<!-- <app-footer></app-footer> -->
</div>
