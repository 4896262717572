// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// import { BitumenIndexService } from './bitumen-index.service';
// import { BitumenIndex } from '../../model/price';

import {SelectionModel} from '@angular/cdk/collections';
import { PendingForecastService } from 'src/app/order-management/pending-forecast/pending-forecast.component.service';
import { CommonService } from '../order-management/order-management/order-management.component.service';
import { BitumenIndexAdminService } from './bitumen-index-admin.service';
import { DialogBodyComponent } from '../user-management/dialog-body/dialog-body.component';
import { AppService } from '../shared-modules/services/shared.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-bitumen-index-admin',
  templateUrl: './bitumen-index-admin.component.html',
  styleUrls: ['./bitumen-index-admin.component.css']
})
export class BitumenIndexAdminComponent implements OnInit, OnDestroy {
  yearList: number[] = [];
  // displayedColumns: string[] = ['Date','month','Year', 'Index_2015', 'Index_2010', 'Index_2005','Index_2000','Index_1995','edit','delete'];
  displayedColumns: string[] = ['Date','month','Year', 'Index_2020', 'Index_2015', 'Index_2010', 'Index_2005','Index_2000','Index_1995', 'Actions'];
  // dataSource: any;
  public dataSource = new MatTableDataSource();
  displayTable: boolean = true;
  priceIndexForm: FormGroup;
  yearToSearch:any;
  Year = this.sharedService.getCurrentDateTime().getFullYear();
  monthList: string[] = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
  bitumeIndexData: any;
  bitumeIndexDataCopy:any;
  fetchedDataForItemArray = [];
  years:any;
  Forecast: any;
  excelData:any; 
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('sort', {static: true}) public sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatTable,{static:true}) table: MatTable<any>;
  submitted: boolean;
  isRateValueInvalid: boolean;
  isRateValueInvalid1995: boolean;
  isRateValueInvalid2000: boolean;
  isRateValueInvalid2005: boolean;
  isRateValueInvalid2010: boolean;
  isRateValueInvalid2015: boolean;
  isRateValueInvalid2020: boolean;

  isPriceIndexFormEdit: boolean = undefined;
  userInfo: any;
	loggedInUserName: string;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private bitumenIndexService: BitumenIndexAdminService,
    private pendingForecastService: PendingForecastService,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private sharedService: AppService,
    private media: MediaMatcher,
    private translate: TranslateService) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.loggedInUserName = (this.userInfo ? (this.userInfo.FirstName + " " + this.userInfo.LastName) : '');
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => cdRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }


  ngOnInit() {
    this.years=['All','Current Year','Last 2 Years','Last 3 Years'];
    this.yearToSearch=this.years[0];
    this.bitumeIndexData=[];

    this.getBitumenIndex();

    let currentYear: number = this.getCurrentYear();
    this.getYearList(currentYear);

    this.priceIndexForm = this.formBuilder.group({      
      month: ['', Validators.required],           
      Year: ['', Validators.required], 
      Index_2020: ['', Validators.required],     
      Index_2015: ['', Validators.required],
      Index_2010: ['', Validators.required],
      Index_2005: ['', Validators.required],
      Index_2000: ['', Validators.required],
      Index_1995: ['', Validators.required],
      UpdatedBy: [''],
      CreatedBy: [''],
      UpdatedOn: [''],
      CreatedOn:  ['']    
    });

    this.translate.get(['HOME.itemsPerPageLabel'])
      .subscribe(data => {
        this.paginator._intl.itemsPerPageLabel = data['HOME.itemsPerPageLabel'];
      });

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.cdRef.detectChanges();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'Date': {
          //console.log('Date' + new Date(item['month'] + ' ' + item['Year']));
          return new Date(item['month'] + ' ' + item['Year']);
        }
        case 'month': {
         // console.log('month' + new Date(item['month'] + ' ' + item['Year']));
          return new Date(item['month'] + ' ' + item['Year']);
        }
        case 'Year': {
         // console.log('Year' + new Date(item['month'] + ' ' + item['Year']));
          return new Date(item['month'] + ' ' + item['Year']);
        }
        default: return item[property];
      }
    };
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getDummyData() {
    // const bitumeIndexData: BitumenIndex[] = [
    //   { Date: "October 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143" },
    //   { Date: "September 2019", Index_2015: "155", Index_2010: "139", Index_2005: "190",Index_2000:'197',Index_1995:'170' },
    //   { Date: "August 2019",  Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "July 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143" },
    //   { Date: "June 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143" },
    //   { Date: "May 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "April 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143" },
    //   { Date: "March 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "Feburary 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "January 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "December 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143" },
    //   { Date: "November 2019", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"},
    //   { Date: "October 2018", Index_2015: "143", Index_2010: "143",  Index_2005: "143", Index_2000: "143", Index_1995: "143"}
    // ];
    // this.displayTable = true;
    // this.excelData=bitumeIndexData;
    // this.dataSource = new MatTableDataSource(bitumeIndexData);
    // console.log(bitumeIndexData, "Bitumen Index Information");
    // this.dataSource.sort = this.sort;
    // this.displayTable = true;
  }
  downloadBitumenIndexData(){
    this.pendingForecastService.exportAsExcelFile(this.excelData, "BitumenIndex");
  }

    // convenience getter for easy access to form fields
  get fields() {
    return this.priceIndexForm.controls;
  }

  save() {
    this.submitted = true;
    
    this.priceIndexForm.controls['UpdatedBy'].setValue(this.loggedInUserName);
    this.priceIndexForm.controls['CreatedBy'].setValue(this.loggedInUserName);
    this.priceIndexForm.controls['UpdatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());
    this.priceIndexForm.controls['CreatedOn'].setValue(this.sharedService.getCurrentDateTime().toISOString());

    this.isRateValueInvalid1995 = this.isInValidDecimalNumber(this.priceIndexForm.value.Index_1995);
    this.isRateValueInvalid2000 = this.isInValidDecimalNumber(this.priceIndexForm.value.Index_2000);
    this.isRateValueInvalid2005 = this.isInValidDecimalNumber(this.priceIndexForm.value.Index_2005);
    this.isRateValueInvalid2010 = this.isInValidDecimalNumber(this.priceIndexForm.value.Index_2010);
    this.isRateValueInvalid2015 = this.isInValidDecimalNumber(this.priceIndexForm.value.Index_2015);
    this.isRateValueInvalid2015 = this.isInValidDecimalNumber(this.priceIndexForm.value.Index_2020);

    if (!this.priceIndexForm.valid || this.isRateValueInvalid1995 || this.isRateValueInvalid2000 || this.isRateValueInvalid2005 || this.isRateValueInvalid2010 || this.isRateValueInvalid2015) {
      this.openSaveUserDialog('There are items that require your attention', 'Please complete all fields marked with *', false,'bitumen-index-message');
      return false;
    }
    // this.priceIndexForm.controls['month'].setValue(this.priceIndexForm.value['month'].substring(0, 3).toUpperCase());

    // Check if it is update or add bitumen index
    if (this.isPriceIndexFormEdit) {
      this.bitumenIndexService.updateBitumenIndex(this.priceIndexForm.value)
        .subscribe(
          (response: any) => {
            this.saveStatusDialog(response);
            this.getBitumenIndex();
          });
    } else {
      this.bitumenIndexService.addBitumenIndex(this.priceIndexForm.value)
        .subscribe(
          (response: any) => {
            this.saveStatusDialog(response);
            this.getBitumenIndex();
          });
    }
  }

  reset() {
    this.submitted = false;
    this.isPriceIndexFormEdit = undefined;
    this.isRateValueInvalid1995 = undefined;
    this.isRateValueInvalid2000 = undefined;
    this.isRateValueInvalid2005 = undefined;
    this.isRateValueInvalid2010 = undefined;
    this.isRateValueInvalid2015 = undefined;    
    this.priceIndexForm.reset();
   
    // this.getCurrentMonthAndYear();

    this.priceIndexForm.controls['month'].setValue('');
    this.priceIndexForm.controls['Year'].setValue('');
    this.priceIndexForm.controls['Index_2020'].setValue('');
    this.priceIndexForm.controls['Index_2015'].setValue('');
    this.priceIndexForm.controls['Index_2010'].setValue('');
    this.priceIndexForm.controls['Index_2005'].setValue('');
    this.priceIndexForm.controls['Index_2000'].setValue('');
    this.priceIndexForm.controls['Index_1995'].setValue('');
  }



  getBitumenIndex() {
    this.bitumenIndexService.getBitumenIndex()
			.subscribe(
				res => {
					if (res["ResponseCode"] == 200) {
            this.bitumeIndexData=res['BitumenIndex'];
            this.bitumeIndexDataCopy=this.bitumeIndexData;
            this.excelData=this.bitumeIndexData;
            this.dataSource.data = this.bitumeIndexData;
          
            /* this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator; */
            this.displayTable = true;
					} else {
            // console.log("response is: ", res['RequestStatus']);
            this.displayTable = false;
					}
				});
  }


  filterIndex(value) {
    let date = this.sharedService.getCurrentDateTime();
    let year = date.getFullYear();
    if (value == 'Current Year')
      this.bitumeIndexDataCopy = this.bitumeIndexData.filter(item => (item.Year == year));
    if (value == 'Last 2 Years')
      this.bitumeIndexDataCopy = this.bitumeIndexData.filter(item => (item.Year >= (year - 2) && item.Year <= year));
    if (value == 'Last 3 Years')
      this.bitumeIndexDataCopy = this.bitumeIndexData.filter(item => (item.Year >= (year - 3) && item.Year <= year));
    if (value == 'All')
      this.bitumeIndexDataCopy = this.bitumeIndexData;
    this.displayTable = true;
    this.excelData=this.bitumeIndexDataCopy;
    /* this.dataSource = new MatTableDataSource(this.bitumeIndexDataCopy);
  
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator; */
    this.displayTable = true;
  }

  editPriceIndex(priceIndex: any) {
    this.isPriceIndexFormEdit = true;

    // Set Month property in form    
    // priceIndex['Month'] = this.getFullMonthName(priceIndex.Month);

    this.priceIndexForm.patchValue(priceIndex);         
    // let date1 = new FormControl(new Date(priceIndex.IndexDate));
  }

  getCurrentMonthAndYear() {
    const date = this.sharedService.getCurrentDateTime();

    // Get current year
    let currentYear = date.getUTCFullYear();    

    // Get current month
    var dateArray = date.toISOString().split("-");
    var month_index =  parseInt(dateArray[1],10) - 1;

    this.getYearList(currentYear);
    this.priceIndexForm.controls['Month'].setValue(this.monthList[month_index]);
    this.priceIndexForm.controls['Year'].setValue(currentYear);
  }

  getCurrentYear(): number {
    const date = this.sharedService.getCurrentDateTime();
    return date.getUTCFullYear();
  }

  getYearList(currentYear: number) {
    this.yearList = [];
    // this.yearList.push(currYear - 5, currYear - 4, currYear - 3, currYear - 2, currYear - 1, currYear);
    this.yearList.push(currentYear - 1, currentYear);
  }

  saveStatusDialog(response: any) {
    if (response) {
      if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
        this.openSaveUserDialog('Bitumen Index Details Saved Successfully', null, true,'bitumen-index-success');
      } else if (response['Description'] && response['Description'].trim().toUpperCase() === 'DATA ALREADY PRESENT FOR THE GIVEN INPUT.') {
        this.openSaveUserDialog('Failed to Save Bitumen Index Details','', false,'data-already-present');
      } else if (response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'ERROR') {
        this.openSaveUserDialog('Failed to Save Bitumen Index Details', (response['Description'] ? response['Description'] : null), false,'bitumen-index-fail');
      } else {
        this.openSaveUserDialog('Failed to Save Bitumen Index Details', null, false,'bitumen-index-fail');
      }
    } else {
      this.openSaveUserDialog('Failed to Save Bitumen Index Details', null, false,'bitumen-index-fail');
    }
  }

  openSaveUserDialog(headerTitle: string, message: string, isSuccess: boolean,headerNumber:string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber:headerNumber
      },
			height: '37%',
			width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }
  
  getFullMonthName(shortMonthName: string): string {
    let fullMonthName: string;    
    for (let month of this.monthList) {
      if (shortMonthName && month && month.toUpperCase().startsWith(shortMonthName.toUpperCase())) {
        fullMonthName = month;
        break;
      }
    }
    return fullMonthName;
  }

  
  isInValidDecimalNumber(quantity: number): boolean {
    let regex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g); // regex to check if number contains more than 2 decimal places
    return (quantity && !String(quantity).match(regex))
  }


}
