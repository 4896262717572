<div class="">
    <div class="container container-align" *ngIf="isLoggedInUserPortalAdmin || isLoggedInUserDepotAdmin">
        <div class="row pt-1 pb-1 no-gutters">
            <div class="col-lg-2 ">
                <label class="company-name-label" for="companyName"> {{'HOME.Customername'| translate}}: </label>
            </div>
            <div class="col-lg-3">
                <select class="company-name-dropdown form-control" [(ngModel)]="selectedCompanyId"
                    title="Select Company Name to filter records">
                    <option value="">{{'HOME.All'| translate}}</option>
                    <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                        {{customer.CustomerName}}
                    </option>
                </select>
            </div>
            <div class="col-lg-1">
                <label class="company-name-label" for="status">{{'HOME.Stage'| translate}}: </label>
            </div>
            <div class="col-lg-1 stage_dropdown_div_mv">
                <select class="status-dropdown stage_dropdown_mv" [(ngModel)]="selectedStatus"
                    title="Select Status to filter records">
                    <option value="">{{'HOME.All'| translate}}</option>
                    <option *ngFor="let status of statusList" [value]="status">
                        {{'HOME.' + status | translate}}
                    </option>
                </select>
            </div>
            <div class="col-lg-1 pl-5 find_button_div_mv">
                <button type="button" class="btn filter-button find_button_mv" for="filter" (click)='fetchDeliverySites()'>
                    <label class="font-bold font-small" for="filter"> {{'HOME.Find'| translate}}
                    </label></button>
            </div>
            <div class="col-lg-4 download_btn right-aligned-button" *ngIf="!this.mobileQuery.matches">
                <i (click)="download()" class="fas fa-download downloadIcon no-left-margin">
                    <span class="downloadText">{{'HOME.DownloadAll'| translate}}</span>
                </i>
            </div>
        </div>
    </div>
            
    <div class="global-search-text-box">
        <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
    </div>
 
    <div class=" container container-align">
        <div class="row">
            <div class="col-sm">
                <div [hidden]="(!isRequestCompleted || (dataSourceDelSite.data && dataSourceDelSite.data.length))" class="no-records-message pt-3 pl-2"> {{'HOME.Norecordsfound'| translate}}
                </div>
                <div [hidden]="(!isRequestCompleted || (!dataSourceDelSite.data || (dataSourceDelSite.data && !dataSourceDelSite.data.length)))" class="">
                <!--Angular 7 Impl-->
                <mat-table #table [dataSource]="dataSourceDelSite" class="userTable horizontal_scrollbar" matSort>
 
                    <!-- DeliverySite Column -->
                    <ng-container matColumnDef="DeliverySite">
                        <mat-header-cell class="header-font mat-column-large" *matHeaderCellDef mat-sort-header>  {{'HOME.DeliverySiteName'| translate}}
 
                        </mat-header-cell>
                        <mat-cell class="mat-column-large" *matCellDef="let fetchedDataForItemArray">
                            {{fetchedDataForItemArray.DeliverySite}}
                        </mat-cell>
                    </ng-container>
 
                    <ng-container matColumnDef="BillingReference">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>
                            {{'HOME.BillingReference'| translate}}
 
                        </mat-header-cell>
                        <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray"> {{fetchedDataForItemArray.BillingReference}}
                        </mat-cell>
                    </ng-container>
 
                    <ng-container matColumnDef="PrimaryContact">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Primarycontact'| translate}}
 
                        </mat-header-cell>
                        <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray"> {{fetchedDataForItemArray.PrimaryContactWithAreaCode}}
                        </mat-cell>
                    </ng-container>
 
                    <ng-container matColumnDef="Address">
                        <mat-header-cell class="header-font" *matHeaderCellDef mat-sort-header
                            [ngClass]="{'mat-column-large': isLoggedInUserPortalAdmin, 'mat-column-xl': !isLoggedInUserPortalAdmin }">
                            {{'HOME.Address'| translate}}
 
                        </mat-header-cell>
                        <mat-cell *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="{'mat-column-large': isLoggedInUserPortalAdmin, 'mat-column-xl': !isLoggedInUserPortalAdmin }"> {{fetchedDataForItemArray.Address}}
                        </mat-cell>
                    </ng-container>
 
                    <ng-container matColumnDef="Status">
                        <mat-header-cell class="header-font mat-column-small" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Status'| translate}}
                        </mat-header-cell>
                        <mat-cell class="mat-column-small" *matCellDef="let fetchedDataForItemArray"> {{ getTranslatedText(fetchedDataForItemArray.Status) }}
                        </mat-cell>
                    </ng-container>
 
                    <ng-container matColumnDef="ApprovalStage">
                        <mat-header-cell class="header-font mat-column-medium" *matHeaderCellDef mat-sort-header
                            [ngClass]="{'hide-table-column': !isLoggedInUserPortalAdmin }">
                            {{'HOME.Stage'| translate}}
 
                        </mat-header-cell>
                        <mat-cell class="mat-column-medium" *matCellDef="let fetchedDataForItemArray"
                            [ngClass]="{'hide-table-column': !isLoggedInUserPortalAdmin }">

                            <!-- Commented below code, kept for future reference -->
                            <!-- <span
                                *ngIf="fetchedDataForItemArray.ApprovalStage === 'Active' || fetchedDataForItemArray.ApprovalStage === 'Inactive'">
                                {{'HOME.' + fetchedDataForItemArray.ApprovalStage | translate}}
                            </span>
                            <span
                                *ngIf="!(fetchedDataForItemArray.ApprovalStage === 'Active' || fetchedDataForItemArray.ApprovalStage === 'Inactive')">
                                {{ fetchedDataForItemArray.ApprovalStage }}
                            </span> -->

                            {{ getTranslatedText(fetchedDataForItemArray.ApprovalStage) }}

                        </mat-cell>
                    </ng-container>
                    
                    <ng-container matColumnDef="UpdatedOn">
                        <mat-header-cell class="header-font mat-column-small" *matHeaderCellDef mat-sort-header>
                            {{'HOME.Modified'| translate}}
                        </mat-header-cell>
                        <mat-cell class="mat-column-small" *matCellDef="let fetchedDataForItemArray"> {{fetchedDataForItemArray.UpdatedOn |
                            date: 'MM/dd/yyyy'}}
                        </mat-cell>
                    </ng-container>
                    
                    <ng-container matColumnDef="Actions">
                        <mat-header-cell class="header-font mat-column-small-xs" *matHeaderCellDef>
                        </mat-header-cell>
                        <th mat-header-cell *matHeaderCellDef [ngClass]="'mat-table-column-header mat-column-actions'">
                        </th>
                        <mat-cell class="mat-column-small-xs" *matCellDef="let element">

                            <span (click)='approveDeliverySite(element, false)' title="{{'HOME.AddDepotandJDEnumber'| translate}}"
                                *ngIf="isLoggedInUserAdmin || isLoggedInUserDepotAdmin">
                                <i class="far fa-edit" aria-hidden="true"></i>
                            </span>

                            <!-- Commented below code, kept for future reference -->
                            <!-- <span (click)='showProductPopUp(element)' title="{{'HOME.Updateproducts'| translate}}"
                                *ngIf="countryCode !== 'FIN' && (isLoggedInUserAdmin)" class="active-icon pl-1">
                                <i class="far fa-edit" aria-hidden="true"></i>
                            </span>
                            <span (click)='approveDeliverySite(element, true)' title="{{'HOME.Adddepotnumber'| translate}}"
                                *ngIf="countryCode === 'FIN' && (isLoggedInUserAdmin || isLoggedInUserDepotAdmin)" class="active-icon pl-1">
                                <i class="far fa-edit" aria-hidden="true"></i>
                            </span> -->

                            <span *ngIf="element.ApprovalStage === 'Active' && !isLoggedInUserDepotAdmin"
                                (click)='updateDeliverySiteStatus(element, "Inactive")' title="{{'HOME.Deactivatedeliverysite'| translate}}"
                                class="mat-table-actions-padding"
                                [ngClass]="{'active-icon': (element.Status && (element.Status.toUpperCase() === 'APPROVED' || element.Status.toUpperCase() === 'COMPLETED')), 'inactive-icon': ((element.Status && element.Status.toUpperCase()) === 'IN PROGRESS' || (element.Status && element.Status.toUpperCase()) === 'REJECTED')}">
                                <i class="fas fa-toggle-on"></i>
                            </span>
                            <span *ngIf="element.ApprovalStage  !== 'Active' && !isLoggedInUserDepotAdmin"
                                (click)='updateDeliverySiteStatus(element, "Active")' title="{{'HOME.Activatedeliverysite'| translate}}"
                                class="mat-table-actions-padding"
                                [ngClass]="{'active-icon': (element.Status && (element.Status.toUpperCase() === 'APPROVED' || element.Status.toUpperCase() === 'COMPLETED')), 'inactive-icon': ((element.Status && element.Status.toUpperCase()) === 'IN PROGRESS' || (element.Status && element.Status.toUpperCase()) === 'REJECTED')}">
                                <i class="fas fa-toggle-off"></i>
                            </span>

                            <!-- Commented below code, kept for future reference -->
                            <!-- <span (click)='editDeliverySite(element)' class='active-icon' title=" {{'HOME.Editdeliverysite'| translate}}"
                                *ngIf="!isLoggedInUserDepotAdmin">
                                <i class="fa fa-cog " aria-hidden="true"></i>
                            </span> -->

                            <span (click)='editDeliverySiteNew(element)' class='active-icon pl-1'
                                title=" {{'HOME.Editdeliverysite'| translate}}" *ngIf="!isLoggedInUserDepotAdmin">
                                <i class="fa fa-cog " aria-hidden="true"></i>
                            </span>
                            
                            <span *ngIf="!isLoggedInUserDepotAdmin"
                            (click)='deleteDelSite(element)' title="{{'HOME.Deletedeliverysite'| translate}}"
                            class="mat-table-actions-padding">
                            <i class="fas fa-trash active-icon" aria-hidden="true"></i>
                        </span>
                        </mat-cell>
                    </ng-container>
 
                    <mat-header-row class="table-header" *matHeaderRowDef="displayedColumnsDelSite; sticky:true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsDelSite;"></mat-row>
 
                </mat-table>
                <mat-paginator   [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]"   showFirstLastButtons></mat-paginator>
 
                </div>
 
            </div>
 
        </div>
    </div>
</div>