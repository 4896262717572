import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ForecastactualweeklyswedenService {

  apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient) {
  }

  getBarChartData(Year: string, CustomerID: string, DeliverySite: string, fromWeek: string, toWeek: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetPlannedVsActualWeeklyForAll';
    return this.httpClient
      .get(url, {
        params: {
          Year: Year,
          CustomerID: CustomerID,
          // DeliverySite: DeliverySite,
          Start_Week: fromWeek,
          End_Week: toWeek,
          JDE_AddressNumber: jdeAddressNumber
        }
      });
  }

  // GetWeeklyPlannedVsActualForAll_Table
  // for sweeden weekly reports 
  getTableDataForAll(Year: string, CustomerID: string, DeliverySite: string, jdeAddressNumber: string){
    let url = this.apiUrl + '/GetWeeklyPlannedVsActualForAll_Table';
    return this.httpClient
        .get(url, {
            params: {
              Year:Year, 
              CustomerID:CustomerID,
              DeliverySite:DeliverySite,
              JDEAddressNumber: jdeAddressNumber
              }
        });
  }

  getTableDataForDepot(Year: string, CustomerID: string, DeliverySite: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetPlannedVsActualWeeklyByDepot';
    return this.httpClient
      .get(url, {
        params: {
          CustomerID: CustomerID,
          Year: Year,
          DeliverySite: DeliverySite,
          JDEAddressNumber: jdeAddressNumber
        }
      });
  }

  getTableDataForProduct(Year: string, CustomerID: string, countryCode: any, DeliverySite: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetWeeklyPlannedVsActualForProduct';
    return this.httpClient
      .get(url, {
        params: {
          CustomerID: CustomerID,
          Year: Year,
          CountryCode: countryCode,
          DeliverySite: DeliverySite,
          JDEAddressNumber: jdeAddressNumber
        }
      });
  }

  getTableDataForDepotAndProduct(CustomerID: string, Year: string, countryCode: any, DeliverySite: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetWeeklyPlannedVsActualByDepotAndProduct';
    return this.httpClient
      .get(url, {
        params: {
          CustomerID: CustomerID,
          Year: Year,
          CountryCode: countryCode,
          DeliverySite: DeliverySite,
          JDEAddressNumber: jdeAddressNumber
        }
      });
  }

  
  getTableDataForDepotFinland(Year: string, CustomerID: string, DeliverySite: string, inStartWeek: string, inEndWeek: string, countryCode: any, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetPlannedVsActualWeeklyByDepot';
    return this.httpClient
      .get(url, {
        params: {
          CustomerID: CustomerID,
          Year: Year,
          DeliverySite: DeliverySite,
          IN_START_WEEK: inStartWeek,
          IN_END_WEEK: inEndWeek,
          COUNTRYCODE: countryCode,
          JDEAddressNumber: jdeAddressNumber
        }
      });
  }

  getTableDataForProductFinland(Year: string, CustomerID: string, countryCode: any, DeliverySite: string, inStartWeek: string, inEndWeek: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetWeeklyPlannedVsActualForProduct';
    return this.httpClient
      .get(url, {
        params: {
          CustomerID: CustomerID,
          Year: Year,
          CountryCode : countryCode,
          DeliverySite: DeliverySite,
          IN_START_WEEK: inStartWeek,
          IN_END_WEEK: inEndWeek,
          JDEAddressNumber: jdeAddressNumber
        }
      });
  }

  getTableDataForDepotAndProductFinland(CustomerID: string, Year: string, countryCode: any, DeliverySite: string, inStartWeek: string, inEndWeek: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetWeeklyPlannedVsActualByDepotAndProduct';
    return this.httpClient
      .get(url, {
        params: {
          CustomerID: CustomerID,
          Year: Year,
          CountryCode: countryCode,
          DeliverySite: DeliverySite,
          IN_START_WEEK: inStartWeek,
          IN_END_WEEK: inEndWeek,
          JDEAddressNumber: jdeAddressNumber
        }
      });
  }

  
  getWeeklyPlannedVsActualForAllTableFinland(Year: string, CustomerID: string, DeliverySite: string, countryCode: string, inStartWeek: string, inEndWeek: string, jdeAddressNumber: string) {
    let url = this.apiUrl + '/GetWeeklyPlannedVsActualForAll_Table';
    return this.httpClient
      .get(url, {
        params: {
          Year: Year,
          CustomerID: CustomerID,
          DeliverySite: DeliverySite,
          COUNTRYCODE: countryCode,
          IN_START_WEEK: inStartWeek,
          IN_END_WEEK: inEndWeek,
          JDEAddressNumber: jdeAddressNumber
        }
      });
  }


  getTableDataForDelSite(Year: string, CustomerID: string, countryCode: any, DeliverySite: string) {
    let url = this.apiUrl + '/GetWeeklyPlannedVsActualByDeliverySite';
    return this.httpClient
      .get(url, {
        params: {
          CustomerID: CustomerID,
          Year: Year,
          CountryCode: countryCode,
          DeliverySite: DeliverySite

        }
      });

  }

  

  

  getCustomerList(countryCode) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }
  // public exportAsExcelFile(json: any[], excelFileName: string): void {
  //   if (json) {
  //     const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  //     const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
  //     const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //     //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  //     this.saveAsExcelFile(excelBuffer, excelFileName);
  //   }
  // }
  // private saveAsExcelFile(buffer: any, fileName: string): void {
  //   const Report: Blob = new Blob([buffer], {
  //     type: EXCEL_TYPE
  //   });
  //   FileSaver.saveAs(Report, fileName + EXCEL_EXTENSION);
  // }

  getDeliverySite(CustomerId: string, Status: string, countryCode: string) {

    let deliveryUrl = this.apiUrl + '/FetchDeliverySiteService';
    return this.httpClient
      .get(deliveryUrl, {
        params: {
          CustomerId: CustomerId,
          ApprovalStage: Status,
          CountryCode: countryCode
          //Hardcoded Check @
          //CustomerName:  'YIT Kotka Ilarila'
        }
      });
  }

  // For SWEDEN and NORWAY
  getDeliverySiteForUser(UserID: string, CountryCode: any, MappingStatus: any, CustomerID: any) {
    let delSiteUrl = this.apiUrl + '/GetDeliverySiteForUser';

    return this.httpClient
      .get(delSiteUrl, {
        params: {
          UserID: UserID,
          CountryCode: CountryCode,
          MappingStatus: MappingStatus,
          CustomerID: CustomerID
        }
      });
  }

  getDeliverySiteForUserForTable(UserID: string, CountryCode: any, MappingStatus: any, CustomerID: any) {
    let delSiteUrl = this.apiUrl + '/GetDeliverySiteForUser';

    return this.httpClient
      .get(delSiteUrl, {
        params: {
          UserID: UserID,
          CountryCode: CountryCode,
          MappingStatus: MappingStatus,
          CustomerID: CustomerID
        }
      });
  }
  getDeliverySiteForTable(CustomerId: string, Status: string, countryCode: string) {

    let deliveryUrl = this.apiUrl + '/FetchDeliverySiteService';
    return this.httpClient
      .get(deliveryUrl, {
        params: {
          CustomerId: CustomerId,
          ApprovalStage: Status,
          CountryCode: countryCode
          //Hardcoded Check @
          //CustomerName:  'YIT Kotka Ilarila'
        }
      });
  }

  fetchWeeklySnapshotSummary(countryCode: string, customerId: string, jdeAddressNumber: string, productId: string, depotId: string, year: string, week: string, actualQuantity: string) {
    let url = this.apiUrl + '/FetchWeeklySnapshotSummery';
    return this.httpClient
      .get(url, {
        params: {
          COUNTRY_CODE: countryCode,
          CUSTOMER_ID: customerId,
          JDE_ADDRESS_NUMBER: jdeAddressNumber,
          PRODUCT_ID: productId,
          DEPOT_ID: depotId,
          YEAR: year,
          WEEK: week,
          ACTUAL_QUANTITY: actualQuantity
        }
      });
  }
 

  public exportAsExcelFileForAll(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json), { header: ["CustomerName", "WeekNumber", "forecastquantity", "actualquantity", "difference"] });;

      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  public exportAsExcelFileForProduct(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json), { header: ["CustomerName", "ProductName", "WeekNumber", "forecastquantity", "actualquantity", "difference"] });

      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  public exportAsExcelFileForDepot(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json), { header: ["CustomerName", "DepotName", "WeekNumber", "forecastquantity", "actualquantity", "difference"] });

      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  public exportAsExcelFileForDeliverySite(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json), { header: ["CustomerName", "DeliverySite", "WeekNumber", "forecastquantity", "actualquantity", "difference"] });

      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }

  public exportAsExcelFileForProductAndDepot(json: any[], excelFileName: string): void {
    if (json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((json), { header: ["CustomerName", "ProductName", "DepotName", "WeekNumber", "forecastquantity", "actualquantity", "difference"] });

      const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }



  private saveAsExcelFile(buffer: any, fileName: string): void {
    const Report: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(Report, fileName + EXCEL_EXTENSION);
  }

}




