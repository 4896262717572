import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { timeout } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    apiUrl = environment.apiUrl;
    getDeviceIPAddressUrl = environment.getDeviceIPAddressUrl;

    constructor(private httpClient: HttpClient) {
    }

    getLoginService(Username: string, Password: string, deviceIPAddress: string, browserName: string) {

        // Commented old code kept for future reference
        /* let url = this.apiUrl + '/LoginUser';
        return this.httpClient
            .get(url, {
                params: {
                    UserID: Username,
                    Password: Password,
                    DeviceIP: deviceIPAddress,
                    BrowserName: browserName
                }
            }); */

        let url = this.apiUrl + '/LoginUser';
        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
               'Accept': 'application/json',
                'Content-Type': 'application/json',
            })
        };
        let postData = JSON.stringify({
            "UserID": Username,
            "Password": Password,
            "DeviceIP": deviceIPAddress,
            "BrowserName": browserName
        });
        return this.httpClient.post(url, postData, httpOptions).pipe();
    }

    getDeviceIPAddress() {
        return this.httpClient.get(this.getDeviceIPAddressUrl).pipe(timeout(500));
    }

    getDisplayMessage(type: string) {
        let url = this.apiUrl + '/GetDisplayMessage';
        return this.httpClient.get(url, {
            params: {
                Type: type
            }
        });
    }

    fetchCountryAccessForUser(userId: string) {
        let url = this.apiUrl + '/FetchCountryAccessForUser/UserLoginCountry';
        return this.httpClient.get(url, {
            params: {
                USERID: userId
            }
        });
    }

}