import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../user';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ManageUsersService {

  apiUrl = environment.apiUrl;
  
  httpOptions = {
    headers: new HttpHeaders({
      // 'Access-Control-Allow-Origin': '*', 
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*' ,
      // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization',
      // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    })
  };

  constructor(
    private httpClient: HttpClient) { }

  getUsers(userID: string, country?: string, customerName?: string) {
    if (customerName) {
      const url = this.apiUrl + '/getAllUser';
      return this.httpClient
        .get(url, {
          params: {
            CustomerID: customerName,
            Country: country
          }
        });
    }
    const url = this.apiUrl + '/getAllUser';
    return this.httpClient
      .get(url, {
        params: {
          UserID: userID,
          Country: country,
          CustomerID: customerName
        }
      });
  }

  resetPassword(user: any): Observable<User> {
    let resetPasswordUrl = this.apiUrl + '/SetPassword';
    return this.httpClient.post<User>(resetPasswordUrl, JSON.stringify(user), this.httpOptions).pipe();
  }

  lockUnlockUser(user: any) {
    let lockUnlockUserUrl = this.apiUrl + '/UpdateUserStatus';

    // let user: User;

    /* var user = new Object();
    user['UserId'] = 'kapil.sharma@lntinfotech.com';
    user['Status'] = 'Active'; */

    var userObject = new Object();
    userObject['UserId'] = user.UserID;
    userObject['Password'] = user.Password;

    return this.httpClient.post<User>(lockUnlockUserUrl, JSON.stringify(user), this.httpOptions).pipe();
  }

  getCustomerList(countryCode) {
    let url = this.apiUrl + '/getCustomer';
    return this.httpClient.get(url, {
      params: {
        CountryCode: countryCode,
      }
    });
  }
}
