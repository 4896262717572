<div class="container-fluid pad-left-0">
    <div class="row">
        <div class="col-lg-6 toggle_button_div_mv">
            <mat-button-toggle-group class="button-group-style" name="fontStyle" aria-label="Font Style"
                #group="matButtonToggleGroup" [(ngModel)]="currentToggleMenu" value="reports">
                <mat-button-toggle value="reports" ngDefaultControl (click)="toggleButtonClick('reports')">
                    {{'HOME.Reports'| translate}}
                </mat-button-toggle>
                <mat-button-toggle value="graph" (click)="toggleButtonClick('graph')">
                    {{'HOME.graph'| translate}}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="col-lg-6 download_button_div_mv" *ngIf="!this.mobileQuery.matches">
            <div class="downloadDiv pt-2">
                <div *ngIf="currentToggleMenu === 'reports' && displayTable && isLoggedInUserPortalAdmin">
                    <i (click)="downloadAllReports()" class="fas fa-download downloadIcon "><span
                            class="downloadText">{{'HOME.DownloadAll'| translate}}</span></i>
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-1" *ngIf="currentToggleMenu === 'reports'">
        <div class="pl-3 ">
            <label for="customerName">
                <span class="customer-name-label">{{'HOME.Customername'| translate}}
                </span>
            </label>
        </div>
        <div class=" customer_name_dropdown_div customer_name_dropdown_div_mv " *ngIf="isLoggedInUserPortalAdmin">
            <select class="customer-name-dropdown customer_name_dropdown_width" id="customer-name"
                [(ngModel)]='customerIdUserSelected' (change)="this.getDeliverySites()">
                <option value="">{{'HOME.All'| translate}}</option>
                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                    {{customer.CustomerName}}
                </option>
            </select>
        </div>
        <div class="pl-1" *ngIf="!isLoggedInUserPortalAdmin">
            <input type="text" class="customer-name-textbox" [(ngModel)]='customerName' disabled />
        </div>

        <div class="delivery_site_label_div_mv">
            <label for="deliverysite">
                <span class="customer-name-label">{{'HOME.Deliverysite'| translate}}
                </span>
            </label>
        </div>
        <div class="delivery_site_dropdown_div delivery_site_dropdown_div_mv">
            <select class="customer-name-dropdown" id="delivery-site" [(ngModel)]='deliverySiteUserSelected'>
                <option value="">{{'HOME.All'| translate}}</option>
                <option *ngFor="let deliverySite of deliverySiteList" [value]="deliverySite">{{deliverySite}}
                </option>
            </select>
        </div>
    </div>
    <div class="row pt-3" *ngIf="currentToggleMenu === 'reports'">
        <div class="viewBy_label_div viewBy_label_div_mv">
            <label for="viewBy">
                <span class="customer-name-label">{{'HOME.Viewby'| translate}}
                </span>
            </label>
        </div>
        <div class="viewBy_input_div viewBy_input_div_mv">
            <select class="customer-name-dropdown viewBy_input_width viewBy_input_width_mv" id="viewBy"
                [(ngModel)]='viewUserSelected'>
                <option value="All">{{'HOME.All'| translate}}</option>
                <option value="By product"> {{'HOME.Byproduct'| translate}} </option>
                <option value="By depot"> {{'HOME.Bydepot'| translate}} </option>
                <option value="By product and depot"> {{'HOME.Byproductanddepot'| translate}} </option>
            </select>
        </div>
        <div class="year_label_div year_label_div_mv">
            <label for="year">
                <span class="customer-name-label">{{'HOME.Year'| translate}}
                </span>
            </label>
        </div>
        <div class="year_dropdown_div year_dropdown_div_mv">
            <select class="customer-name-dropdown" id="year" [(ngModel)]='yearUserSelected'>
                <option *ngFor="let year of yearList" [value]="year">{{year}}
                </option>
            </select>
        </div>
        <div class="fromWeek_label_div_mv">
            <label for="fromWeek">
                <span class="customer-name-label">{{'HOME.Fromweek'| translate}}
                </span>
            </label>
        </div>
        <div class="textbox-width textbox_width_mv fromWeek_input_div fromWeek_input_div_mv">
            <input [(ngModel)]="fromWeek" type="number" step="0.01" class="week-number-textbox">
        </div>
        <div class="toWeek_label_div_mv">
            <label for="toWeek">
                <span class="customer-name-label">{{'HOME.Toweek'| translate}}
                </span>
            </label>
        </div>
        <div class="textbox-width textbox_width_mv toWeek_input_div toWeek_input_div_mv">
            <input [(ngModel)]="toWeek" type="number" step="0.01" class="week-number-textbox">
        </div>
        <div class="findButton_div_mv">
            <button class="findButton" (click)="getReports()">{{'HOME.Find'| translate}}</button>
        </div>
            
        <!-- Commented below code, kept for future reference -->
        <div class="global-search-text-box">
            <app-global-search *ngIf="showGlobalSearchTextBox" [searchText]="globalSearchText"></app-global-search>
        </div>
        
    </div>
    <div class="row pt-3" *ngIf="currentToggleMenu === 'graph'">
        <div class="pl-3 ">
            <label for="customerName">
                <span class="customer-name-label">{{'HOME.Customername'| translate}}
                </span>
            </label>
        </div>
        <div class=" customer_name_dropdown_div customer_name_dropdown_div_mv " *ngIf="isLoggedInUserPortalAdmin">
            <select class="customer-name-dropdown customer_name_dropdown_width" id="customer-name"
                [(ngModel)]='customerIdUserSelected' (change)="this.getDeliverySites()">
                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                    {{customer.CustomerName}}
                </option>
            </select>
        </div>
        <div class="pl-1" *ngIf="!isLoggedInUserPortalAdmin">
            <input type="text" class="customer-name-textbox" [(ngModel)]='customerName' disabled />
        </div>

        <div class="deliverySite_label_graph_div_mv">
            <label for="deliverysite">
                <span class="customer-name-label">{{'HOME.Deliverysite'| translate}}
                </span>
            </label>
        </div>
        <div class="delivery_site_dropdown_div delivery_site_dropdown_div_mv">
            <select class="customer-name-dropdown" id="delivery-site" [(ngModel)]='deliverySiteUserSelected'>
                <option *ngFor="let deliverySite of deliverySiteList" [value]="deliverySite">{{deliverySite}}
                </option>
            </select>
        </div>
    </div>
    <div class="row pt-3" *ngIf="currentToggleMenu === 'graph'">
        <div class="year_label_graph_div year_label_graph_div_mv">
            <label for="year">
                <span class="customer-name-label">{{'HOME.Year'| translate}}
                </span>
            </label>
        </div>
        <div class="year_dropdown_graph_div year_dropdown_graph_div_mv">
            <select class="customer-name-dropdown" id="year" [(ngModel)]='yearUserSelected'>
                <option *ngFor="let year of yearList" [value]="year">{{year}}
                </option>
            </select>
        </div>
        <div class="fromWeek_label_graph_div_mv">
            <label for="fromWeek">
                <span class="customer-name-label">{{'HOME.Fromweek'| translate}}
                </span>
            </label>
        </div>
        <div class="textbox-width textbox_width_mv fromWeek_input_graph_div fromWeek_input_graph_div_mv">
            <input [(ngModel)]="fromWeek" type="number" step="0.01" class="week-number-textbox">
        </div>
        <div class="toWeek_label_graph_div_mv">
            <label for="toWeek">
                <span class="customer-name-label">{{'HOME.Toweek'| translate}}
                </span>
            </label>
        </div>
        <div class="textbox-width textbox_width_mv toWeek_input_graph_div toWeek_input_graph_div_mv">
            <input [(ngModel)]="toWeek" type="number" step="0.01" class="week-number-textbox">
        </div>
        <div>
            <button class="findButton" (click)="getReports()">{{'HOME.Find'| translate}}</button>
        </div>
    </div>
    <!-- <div class="row pt-3" *ngIf="currentToggleMenu === 'graph'">
        <div class="pl-3 customerName_label_graph_div_mv">
            <label for="customerName">
                <span class="customer-name-label">{{'HOME.Customername'| translate}}
                </span>
            </label>
        </div>
        <div class="pl-2 customerName_dropdown_graph_div_mv" *ngIf="isLoggedInUserPortalAdmin">
            <select class="customer-name-dropdown customer_name_dropdown_width_graph_mv" id="customer-name"
                [(ngModel)]='customerIdUserSelected' (change)="this.getDeliverySites()">
                <option *ngFor="let customer of customerList" [ngValue]="customer.CustomerID">
                    {{customer.CustomerName}}
                </option>
            </select>
        </div>
        <div class="pl-2" *ngIf="!isLoggedInUserPortalAdmin">
            <input type="text" class="customer-name-textbox" [(ngModel)]='customerName' disabled />
        </div>
        <div class="deliverySite_label_graph_div_mv">
            <label for="deliverysite">
                <span class="customer-name-label">{{'HOME.Deliverysite'| translate}}
                </span>
            </label>
        </div>
        <div class="pl-2">
            <select class="customer-name-dropdown deliverySite_dropdown_graph_div_mv" id="delivery-site"
                [(ngModel)]='deliverySiteUserSelected'>
                <option *ngFor="let deliverySite of deliverySiteList" [value]="deliverySite">{{deliverySite}}
                </option>
            </select>
        </div>
        <div class="year_label_graph_div_mv">
            <label for="year">
                <span class="customer-name-label">{{'HOME.Year'| translate}}
                </span>
            </label>
        </div>
        <div class="pl-2 ">
            <select class="customer-name-dropdown" id="year" [(ngModel)]='yearUserSelected'>
                <option *ngFor="let year of yearList" [value]="year">{{year}}
                </option>
            </select>
        </div>
        <div class="pl-4 findButton_label_graph_div_mv">
            <button class="findButton" (click)="getReports()">{{'HOME.Find'| translate}}</button>
        </div>
    </div> -->
    <div class="row pt-3" *ngIf="currentToggleMenu === 'reports' && !displayTable && isRequestCompleted">
        <div class="col-lg-12 mt-3">
            <div class="mt-5" class="no-records-message">
                <span>{{'HOME.Norecordsfound'| translate}}</span>
            </div>
        </div>
    </div>
    <div class="row pt-3" *ngIf="currentToggleMenu === 'graph' && !displayGraph && isRequestCompleted">
        <div class="col-lg-12 mt-3">
            <div class="mt-5" class="no-records-message">
                <span>{{'HOME.Norecordsfound'| translate}}</span>
            </div>
        </div>
    </div>
    <div class="row" [hidden]='currentToggleMenu === "graph" || !displayTable'>
        <div class="col-lg-12 mt-1 table_div_mv" [hidden]='countryCode !== "SWE"'>
            <mat-table #table [dataSource]="dataSource" class="horizontal_scrollbar" #weeklyReportTableSort="matSort"
                matSort (matSortChange)="sortData($event)">
                <ng-container matColumnDef="CustomerName">
                    <mat-header-cell class="customerNameColumn" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showDeliverySiteColumn)}" mat-sort-header>
                        {{'HOME.Customername'| translate}}
                    </mat-header-cell>
                    <mat-cell class="customerNameColumn" *matCellDef="let fetchedDataForItemArray"
                        [ngClass]="{'hide-table-column': !(showDeliverySiteColumn)}">
                        {{fetchedDataForItemArray.CustomerName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DeliverySiteName">
                    <mat-header-cell class="column_medium" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showDeliverySiteColumn)}" mat-sort-header>
                        {{'HOME.Deliverysite'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray"
                        [ngClass]="{'hide-table-column': !(showDeliverySiteColumn)}">
                        {{fetchedDataForItemArray.DeliverySiteName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ProductName">
                    <mat-header-cell class="column_medium" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showProductNameColumn)}" mat-sort-header>
                        {{'HOME.Pname'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray"
                        [ngClass]="{'hide-table-column': !(showProductNameColumn)}">
                        {{fetchedDataForItemArray.ProductName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DepotName">
                    <mat-header-cell class="column_medium" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showDepotNameColumn)}" mat-sort-header>
                        {{'HOME.depotname'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray"
                        [ngClass]="{'hide-table-column': !(showDepotNameColumn)}">
                        {{fetchedDataForItemArray.DepotName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="WeekNumber">
                    <mat-header-cell class="column_medium" *matHeaderCellDef mat-sort-header>
                        {{'HOME.WeekNumber'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        <span *ngIf="showWeekNumberHyperlink"><a href="javascript:void(0)"
                                (click)="showSnapshotDetails(fetchedDataForItemArray)"
                                title="{{'HOME.Forecastdetails'| translate}}">{{fetchedDataForItemArray.WeekNumber}}</a></span>
                        <span *ngIf="!showWeekNumberHyperlink">{{fetchedDataForItemArray.WeekNumber}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="FIRST_SNAPSHOT_QUANTITY">
                    <mat-header-cell class="firstSnapshotColumn" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showForecastFirstSnapshotColumn)}">
                        <div>
                            <div>{{'HOME.ForecastFirst'| translate}}</div>
                            <div>{{'HOME.FirstSnapshot'| translate}}</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell class="firstSnapshotColumn" *matCellDef="let fetchedDataForItemArray"
                        [ngClass]="{'hide-table-column': !(showForecastFirstSnapshotColumn)}">
                        {{fetchedDataForItemArray.FIRST_SNAPSHOT_QUANTITY}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ForecastQuantity">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        <div>
                            <div>{{'HOME.ForecastTitle'| translate}}</div>
                            <div>{{'HOME.LatestMT'| translate}}</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.forecastquantity}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ActualQuantity">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.ActualMT'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.actualquantity}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Difference">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        <div>
                            <div>{{'HOME.Difference'| translate}}</div>
                            <div>{{'HOME.ActualVsLatest'| translate}}</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.difference}}<span> % </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="FORECAST_ACCURACY">
                    <mat-header-cell class="column_medium" [ngClass]="{'hide-table-column': !(showForecastAccuracyColumn)}"
                        *matHeaderCellDef>
                        <div>
                            <div>{{'HOME.Forecastaccuracy'| translate}}</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell class="column_medium" [ngClass]="{'hide-table-column': !(showForecastAccuracyColumn)}"
                        *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.FORECAST_ACCURACY}}
                    </mat-cell>
                </ng-container>
                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"
                    [ngClass]="{'row-top-border': row.showRowTopBorder}">
                </mat-row>
            </mat-table>
        </div>
        <div class="col-lg-12 mt-1 table_div_mv" [hidden]='countryCode === "SWE"'>
            <mat-table #table [dataSource]="dataSource" class="horizontal_scrollbar" #weeklyReportTableSort="matSort"
                matSort (matSortChange)="sortData($event)">
                <ng-container matColumnDef="CustomerName">
                    <mat-header-cell class="customerNameColumn" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showDeliverySiteColumn)}" mat-sort-header>
                        {{'HOME.Customername'| translate}}
                    </mat-header-cell>
                    <mat-cell class="customerNameColumn" *matCellDef="let fetchedDataForItemArray"
                        [ngClass]="{'hide-table-column': !(showDeliverySiteColumn)}">
                        {{fetchedDataForItemArray.CustomerName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DeliverySiteName">
                    <mat-header-cell class="column_medium" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showDeliverySiteColumn)}" mat-sort-header>
                        {{'HOME.Deliverysite'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray"
                        [ngClass]="{'hide-table-column': !(showDeliverySiteColumn)}">
                        {{fetchedDataForItemArray.DeliverySiteName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ProductName">
                    <mat-header-cell class="column_medium" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showProductNameColumn)}" mat-sort-header>
                        {{'HOME.Pname'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray"
                        [ngClass]="{'hide-table-column': !(showProductNameColumn)}">
                        {{fetchedDataForItemArray.ProductName}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DepotName">
                    <mat-header-cell class="column_medium" *matHeaderCellDef
                        [ngClass]="{'hide-table-column': !(showDepotNameColumn)}" mat-sort-header>
                        {{'HOME.depotname'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray"
                        [ngClass]="{'hide-table-column': !(showDepotNameColumn)}">
                        {{fetchedDataForItemArray.DepotName}}
                    </mat-cell>
                </ng-container>

                <!-- Commented old code, kept for future reference -->
                <!-- <ng-container matColumnDef="WeekNumber">
                    <mat-header-cell class="column_medium" *matHeaderCellDef mat-sort-header>
                        {{'HOME.WeekNumber'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">{{fetchedDataForItemArray.WeekNumber}}
                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="WeekNumber">
                    <mat-header-cell class="column_medium" *matHeaderCellDef mat-sort-header>
                        {{'HOME.WeekNumber'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        <span *ngIf="showWeekNumberHyperlink"><a href="javascript:void(0)"
                                (click)="showSnapshotDetails(fetchedDataForItemArray)"
                                title="{{'HOME.Forecastdetails'| translate}}">{{fetchedDataForItemArray.WeekNumber}}</a></span>
                        <span *ngIf="!showWeekNumberHyperlink">{{fetchedDataForItemArray.WeekNumber}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ForecastQuantity">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.Forecast(metric_ton)'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.forecastquantity}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="FIRST_SNAPSHOT_QUANTITY">
                    <mat-header-cell class="firstSnapshotColumn hide-table-column" *matHeaderCellDef>
                        {{'HOME.Firstsnapshotquantity'| translate}}
                    </mat-header-cell>
                    <mat-cell class="firstSnapshotColumn hide-table-column" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.FIRST_SNAPSHOT_QUANTITY}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ActualQuantity">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.Actual(metric_ton)'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.actualquantity}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Difference">
                    <mat-header-cell class="column_medium" *matHeaderCellDef>
                        {{'HOME.Difference'| translate}}
                    </mat-header-cell>
                    <mat-cell class="column_medium" *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.difference}}<span> % </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="FORECAST_ACCURACY">
                    <mat-header-cell class="column_medium" [ngClass]="{'hide-table-column': !(showForecastAccuracyColumn)}"
                        *matHeaderCellDef>
                        <div>
                            <div>{{'HOME.Forecastaccuracy'| translate}}</div>
                        </div>
                    </mat-header-cell>
                    <mat-cell class="column_medium" [ngClass]="{'hide-table-column': !(showForecastAccuracyColumn)}"
                        *matCellDef="let fetchedDataForItemArray">
                        {{fetchedDataForItemArray.FORECAST_ACCURACY}}
                    </mat-cell>
                </ng-container>
                <mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky:true">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"
                    [ngClass]="{'row-top-border': row.showRowTopBorder}">
                </mat-row>
            </mat-table>
        </div>
    </div>
    <div class="row" *ngIf="currentToggleMenu === 'graph' && displayGraph && isRequestCompleted">
        <div class="col-lg-8 col-md-8 graph_div">
            <div id="bar1" *ngIf="(userInfo.RoleID === 'U')">
                <canvas baseChart [datasets]="barChartData1" [labels]="barChartLabels1" [options]="barChartOptions1"
                    [plugins]="barChartPlugins1" [legend]="barChartLegend1" [chartType]="barChartType1">
                </canvas>
            </div>
            <div id="bar2" *ngIf="(userInfo.RoleID === 'PA' || userInfo.RoleID === 'A' )">
                <canvas baseChart [datasets]="barChartData2" [labels]="barChartLabels2" [options]="barChartOptions2"
                    [plugins]="barChartPlugins2" [legend]="barChartLegend2" [chartType]="barChartType2">
                </canvas>
            </div>
        </div>
    </div>
</div>