import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/shared-modules/services/shared.service';
import { DialogBodyComponent } from 'src/app/user-management/dialog-body/dialog-body.component';
import { UpdateDeliverySiteProductsService } from './update-delivery-site-products.service';

@Component({
  selector: 'app-update-delivery-site-products',
  templateUrl: './update-delivery-site-products.component.html',
  styleUrls: ['./update-delivery-site-products.component.css']
})
export class UpdateDeliverySiteProductsComponent implements OnInit {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  dataSourceForDeliverySiteProducts: any;
  countryCode: string;
  isLoggedInUserPortalAdmin: boolean;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayTable: boolean;
  displayedColumns = ["DepotName", "ProductName", "Status"];
  selectedDeliverySite: any;
  userInfo: any;
  isAllProductsSelected: boolean;
  productList: any;
  globalSearchText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<UpdateDeliverySiteProductsComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private sharedService: AppService,
    private dialog: MatDialog,
    private updateDeliverySiteProductsService: UpdateDeliverySiteProductsService) {
    this.userInfo = this.sharedService.getLoginResponse();
    this.countryCode = this.userInfo.CountryCode;
    if (data) {
      if (data.selectedDeliverySite) {
        this.selectedDeliverySite = data.selectedDeliverySite;
      }
      if (data.productList) {
        this.productList = data.productList;
      }
    }
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    if (this.productList && this.productList.length) {
      this.displayTable = true;
      this.dataSourceForDeliverySiteProducts = new MatTableDataSource(this.productList);
      this.dataSourceForDeliverySiteProducts.sort = this.sort;
    } else {
      this.displayTable = false;
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  save(): void {

    // Set value for NotifyHaulier in payload
    let notifyHaulier: string = ((this.selectedDeliverySite['NotifyHaulier'] === 'TRUE') ? 'TRUE' : 'FALSE');

    // Save phone number with country code
    let primaryContactWithCountryCode: string = '';
    let primaryContactCountryCode: string = this.selectedDeliverySite['PrimaryContact'] ? this.selectedDeliverySite['PrimaryContact'].substr(0, 3) : '';
    let primaryContactNumber: string = this.selectedDeliverySite['PrimaryContact'] ? this.selectedDeliverySite['PrimaryContact'].substring(3) : '';
    primaryContactWithCountryCode = ((primaryContactCountryCode && primaryContactNumber) ? (primaryContactCountryCode + this.getCountryPhoneCodeByCountryId(primaryContactCountryCode) + ' ' + primaryContactNumber) : ""); // example, for Sweden, it will be: SWE+44 22222

    // Set value for SendReminderFlag in payload
    let sendReminderFlag: string = ((this.selectedDeliverySite['SendReminderFlag'] === 'TRUE') ? 'TRUE' : 'FALSE');

    this.updateDeliverySiteProductsService.updateDeliverySite(this.selectedDeliverySite, this.getUpdatedProductList(), notifyHaulier, primaryContactWithCountryCode, sendReminderFlag)
      .subscribe(
        (response: any) => {
          if (response) {
            if (response['ResponseCode'] === '200' && response['ResponseStatus'] && response['ResponseStatus'].toUpperCase() === 'SUCCESS') {
              this.showStatusPopUp('Delivery site products updated successfully', null, true, 'delivery-site-product-save-success');
            } else {
              this.showStatusPopUp('Failed to Save Delivery Site Details', null, false, 'add-delivery-site-save-fail');
            }
          } else {
            this.showStatusPopUp('Failed to Save Delivery Site Details', null, false, 'add-delivery-site-save-fail');
          }
        });

    // this.showStatusPopUp('Delivery site products updated successfully', null, true, 'delivery-site-product-save-success');

    // Show below pop up when error occurs while updating the delivery site products
    // this.showStatusPopUp('Failed to update delivery site products', null, false, 'delivery-site-product-save-failure');
  }

  showStatusPopUp(headerTitle: string, message: string, isSuccess: boolean, headerNumber: string) {
    this.dialog.open(DialogBodyComponent, {
      data: {
        header: headerTitle,
        message: message ? message : ' ',
        buttonText: {
          ok: 'Ok'
        },
        isSuccess: isSuccess,
        headerNumber: headerNumber,

      },
      height: 'auto',
      width: (this.mobileQuery.matches ? '50%' : '22%'),
    });
  }

  selectAllProductsToBeAdded() {
    for (let productObject of this.dataSourceForDeliverySiteProducts.data) {
      productObject['isStatusActive'] = !this.isAllProductsSelected;
    }
  }

  removeAllSelectedProducts() {
    this.isAllProductsSelected = false;
    for (let productObject of this.dataSourceForDeliverySiteProducts.data) {
      productObject['isStatusActive'] = false;
    }
  }

  applyFilter(filterValue: string) {
    if (filterValue || filterValue === "") {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSourceForDeliverySiteProducts.filter = filterValue;
      this.displayTable = (this.dataSourceForDeliverySiteProducts && this.dataSourceForDeliverySiteProducts.filteredData && this.dataSourceForDeliverySiteProducts.filteredData.length) ? true : false;
    }
  }
 
  getUpdatedProductList() {
    let updatedProductList = [];
    if (this.productList && this.productList.length) {
      for (let product of this.productList) {
        let updatedProduct = {};
        updatedProduct['DepotId'] = product['DepotId'] || product['DepotID'];
        updatedProduct['ProductId'] = product['ProductId'] || product['ProductID'];
        if (product['isStatusActive']) {
          updatedProduct['ProductStatus'] = 'ACTIVE';
        } else {
          updatedProduct['ProductStatus'] = 'INACTIVE';
        }
        updatedProductList.push(updatedProduct);
      }
    }
    return updatedProductList;
  }

  getCountryPhoneCodeByCountryId(countryId: string): string {
    var countryCode: string = "";
    if (countryId === 'FIN') {
      countryCode = "+358";
    } else if (countryId === 'SWE') {
      countryCode = "+46";
    } else if (countryId === 'NOR') {
      countryCode = "+47";
    } else if (countryId === 'EST') {
      countryCode = "+372";
    } else if (countryId === 'DNK') {
      countryCode = "+45";
    } else if (countryId === 'GBR') {
      countryCode = "+44";
    }
    return countryCode;
  }

}
